const LANGUAGES = {
  RU: {
    id: 'ru',
    title: 'crm.languages.russian'
  },
  EN: {
    id: 'en',
    title: 'crm.languages.english'
  }
};

export default LANGUAGES;
