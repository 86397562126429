import { get, find } from 'lodash';

export default (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    const equalValue = get(find(inputs, { modelName: input.isEqual }), 'modelValue', '');
    const hasValidator = typeof input.isValid === 'function';
    if (!input.modelValue) {
      input.error = input.emptyError;
      errors += 1;
    } else if (input.isEqual && equalValue !== input.modelValue) {
      input.error = input.equalError;
      errors += 1;
    } else if (hasValidator && !input.isValid(input.modelValue)) {
      input.error = input.validError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.modelValue;
    }
  });
  return errors === 0 ? body : false;
};
