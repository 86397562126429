import * as types from '@/constants/mutationTypes';

const state = {
  audioCtx: null,
  audioSource: null
};
const getters = {
  audioCtx: (state) => state.audioCtx,
  audioSource: (state) => state.audioSource
};
const actions = {
  setAudioCtx ({ commit }, data) {
    commit(types.SET_AUDIO_CTX, { data });
  },
  setAudioSource ({ commit }, data) {
    commit(types.SET_AUDIO_SOURCE, { data });
  }
};
const mutations = {
  [types.SET_AUDIO_CTX] (state, { data }) {
    state.audioCtx = data;
  },
  [types.SET_AUDIO_SOURCE] (state, { data }) {
    state.audioSource = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
