/**
 * Audio Engine module
 * JS Dependacies: worklet-loader
 */

import roundTripTest from '@/constants/_rtc/audioEngine/roundTripTest';

const audioEngineLatency = (function () {
  const audioCtx = new AudioContext();
  function start () {
    console.log('Audio context is created');
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }
  }

  function latencyTest () {
    console.log('Latency Test');
    roundTripTest.start();
  }

  function latencyTestStop () {
    roundTripTest.stop();
  }

  function setInputStream (_inputStream) {
    roundTripTest.init(audioCtx, _inputStream);
  }

  function setCalibrationCallbacks (tooLoud, tooSilent, success, stop) {
    roundTripTest.setCallbacks(tooLoud, tooSilent, success, stop);
  };

  function getStreamLatency () {
    return roundTripTest.getResult();
  };

  return {
    start,
    setInputStream,
    latencyTest,
    latencyTestStop,
    setCalibrationCallbacks,
    getStreamLatency
  };
})();

export default audioEngineLatency;
