<template>
  <Loader
    :block="setLoader"
  />
  <div
    class="lp-crm-groups"
    :class="{'lp-crm-groups_mobile': isMobile}"
  >
    <div
      v-if="isGroupListEmpty && !setLoader"
      class="lp-crm-groups__placeholder"
      :class="{'lp-crm-groups__placeholder_mobile': isMobile}"
    >
      <ListEmpty
        :imageUrl="groupsPlaceholder"
        widthImg="352"
        title="crm.students.table.emptyGroupsList"
      >
      </ListEmpty>
      <button
        class="lp-button lp-crm-groups__button"
        @click="openAddGroupForm"
      >
        {{ $t('crm.students.table.addGroup') }}
      </button>
    </div>
    <div
      v-else
      class="lp-crm-groups__wrapper"
    >
      <div
        v-for="group in groupsList"
        :key="group._id"
        class="lp-crm-groups__group"
        :class="{'lp-crm-groups__group_mobile': isMobile}"
        @click="openGroupCard(group)"
      >
        <div class="lp-crm-groups-header">
          <div class="lp-crm-groups__info">
            <div class="lp-crm-groups__title">
              {{ group.title }}
            </div>
            <div class="lp-crm-groups__count">
              {{ group.crmStudents.length }} {{ $t(studentCase(group.crmStudents.length)) }}
            </div>
          </div>
          <div
            class="lp-crm-groups__menu"
            :class="{'lp-crm-groups__menu_mobile': isMobile}"
          >
            <Toolbar
              v-if="true"
              class="lp-crm-groups__toolbar"
              :vertical="!isMobile"
              :column="{deleteName: ''}"
              @delete="deleteGroup(group._id)"
              @edit="editGroup(group._id, group)"
            />
          </div>
        </div>

        <div class="lp-crm-groups-list">
          <div
            v-for="student in studentsGroupLimited(group.crmStudents)"
            :key="student._id"
            class="lp-crm-groups__student"
            @mouseover="currentStudent = `${student._id}${group._id}`"
            @mouseout="currentStudent = null"
          >
            <img
              v-if="student.uploadPhotoUrl"
              :src="student.uploadPhotoUrl"
              class="lp-crm-groups__image"
              alt="student.fullName"
            >
            <userIcon
              v-else
              class="lp-crm-groups__image"
            />
            <Tooltip
              :open="showStudentName(student._id, group._id)"
              :text="student.fullName"
              :onMobile="true"
              :leftPosition="true"
              :customWidth="student.fullName.length > limitedSymbols"
              class="lp-crm-groups__tooltip"
            />
          </div>
          <div
            v-if="group.crmStudents.length > 10"
            class="lp-crm-groups__rest-students"
          >
            +{{ group.crmStudents.length - 10 }}
          </div>
        </div>
      </div>
    </div>
    <GroupForm
      v-model:open="openForm"
      :openForm="openForm"
      :group="currentGroup"
      :currentGroupObj="currentGroupObj"
      :currentGroupProp="currentGroupProp"
      :isMobile="isMobile"
      @close-form="closeForm"
      @save="saveForm"
      @set-group="setGroup"
    />
    <Modal
      v-if="openedGroup"
      :title="openedGroup.title"
      :hideFooter="true"
      :mobileVersion="isMobile"
      :editMode="true"
      :hideButtons="isMobile ? ['save'] : false"
      v-model:open="openModal"
      @close="closeForm"
    >
      <ModalQuestion
        v-if="openDeleteGroup"
        @accept="acceptDelete"
        :question="$t('crm.students.groups.deleteQuestion')"
        v-model:open="openDeleteGroup"
        class="lp-crm-groups-modal__question"
        :class="{'lp-crm-groups-modal__question_mobile': isMobile}"
        :style="{'top': `${questionModalTop}px`}"
      />
      <div
        class="lp-crm-groups-modal"
        :class="{'lp-crm-groups-modal_mobile': isMobile}"
      >
        <div class="lp-crm-groups-modal__header">
          <div>{{ $t('crm.students.groups.studentsCapital') }}</div>
          <div>{{ openedGroup.crmStudents.length }}</div>
        </div>
        <div
          class="lp-crm-groups-modal__list"
          :class="{'lp-crm-groups-modal__list_mobile': isMobile}"
        >
          <div
            v-for="student in openedGroup.crmStudents"
            :key="student._id"
            class="lp-crm-groups-modal__student"
            @click="openStudentCard(student._id)"
          >
            <div class="lp-crm-groups-modal__avatar">
              <img
                class="lp-crm-groups-modal__image"
                v-if="student.uploadPhotoUrl"
                :alt="student.uploadPhotoUrl"
                :src="student.uploadPhotoUrl"
              />
              <userIcon
                v-else
                class="lp-crm-groups-modal__image"
              />
            </div>
            <div>{{ student.fullName }}</div>
          </div>
        </div>
        <div class="lp-crm-groups-modal__menu">
          <div
            @click="editOpenedGroup"
            class="lp-crm-groups-modal__icon"
          >
            <editIcon />
          </div>
          <div
            @click="openDeleteGroup = true"
            class="lp-crm-groups-modal__icon"
          >
            <trashIcon />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import ListEmpty from '@/components/Main/ListEmpty/ListEmpty';
import groupsPlaceholder from '@/assets/images/empty-groups.png';
import GroupForm from '@/components/CRM/Groups/GroupForm/GroupForm';
import { computed, onBeforeMount, ref, watch } from 'vue';
import GroupsApi from '@/api/CRM/groups';
import { editIcon, trashIcon } from '@/constants/icons/crm';
import { userIcon } from '@/constants/icons';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import Loader from '@/components/Main/Loader/Loader';
import Modal from '@/components/Main/Modal/Modal';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import { useI18n } from 'vue-i18n';

export default {
  name: 'MyGroups',
  components: {
    ListEmpty,
    GroupForm,
    userIcon,
    Tooltip,
    Toolbar,
    Loader,
    Modal,
    editIcon,
    trashIcon,
    ModalQuestion
  },
  props: {
    openFormProp: Boolean,
    currentGroupProp: Object,
    isMobile: Boolean
  },
  emits: ['close-form', 'set-group', 'open-student-card'],
  setup (props, { emit }) {
    const openAddGroupForm = () => {
      openForm.value = true;
    };

    const openForm = ref(false);
    watch(() => props.openFormProp, () => {
      openForm.value = props.openFormProp;
    });
    const closeForm = () => {
      openForm.value = false;
      emit('close-form', openForm.value);
      currentGroup.value = null;
      currentGroupObj.value = null;
    };
    watch(openForm, (val) => {
      emit('close-form', val);
      if (!val) {
        currentGroup.value = null;
        currentGroupObj.value = null;
      }
    });

    const setLoader = ref(false);

    const getAllGroups = async () => {
      setLoader.value = true;
      const page = 1;
      const limit = 20;
      try {
        const { data } = await GroupsApi.getAllGroups(page, limit);
        groupsList.value = data.resource;
        setLoader.value = false;
      } catch (e) {
        console.error(e);
        setLoader.value = false;
      }
    };
    const groupsList = ref(null);
    const isGroupListEmpty = computed(() => {
      return !groupsList.value || (groupsList.value && !groupsList.value.length);
    });
    const saveForm = async () => {
      await getAllGroups();
    };

    const studentsGroupLimited = (students) => {
      return students.length <= 10 ? students : students.slice(0, 10);
    };

    const currentStudent = ref(null);

    const showStudentName = (studentId, groupId) => {
      return `${studentId}${groupId}` === currentStudent.value;
    };

    const currentGroupObj = ref(null);
    const currentGroup = ref(null);
    const editGroup = async (groupId, group) => {
      currentGroup.value = groupId;
      currentGroupObj.value = group ? group : null;
      openForm.value = true;
    };
    const deleteGroup = async (groupId) => {
      await GroupsApi.deleteGroup(groupId);
      await getAllGroups();
    };

    const setGroup = (data) => {
      emit('set-group', data);
    };

    const openedGroup = ref(null);
    const openModal = ref(false);
    const openGroupCard = (group) => {
      openModal.value = true;
      openedGroup.value = group;
    };
    watch(openModal, (val) => {
      if (!val) {
        openDeleteGroup.value = false;
        openModal.value = false;
      }
    });

    const questionModalTop = ref(null);

    const openStudentCard = (id) => {
      emit('open-student-card', id);
    };

    const openDeleteGroup = ref(false);
    watch(openDeleteGroup, (val) => {
      if (val) {
        const menu = document.querySelector('.lp-crm-groups-modal__menu');
        questionModalTop.value = menu ? menu.offsetTop + menu.clientHeight : 0;
      }
    });

    const acceptDelete = () => {
      deleteGroup(openedGroup.value._id);
      openModal.value = false;
      openedGroup.value = null;
    };

    const editOpenedGroup = () => {
      openModal.value = false;
      editGroup(openedGroup.value._id, openedGroup.value);
    };

    const { locale } = useI18n({ useScope: 'global' });
    const studentCase = (count) => {
      const digit = count <= 10 || (count > 10 && count <= 20) ? count : +(count.toString().slice(-1));

      if (locale.value !== 'ru') {
        return digit === 1 ? 'crm.students.groups.student' : 'crm.students.groups.students';
      } else {
        if (digit <= 10 || (digit > 20)) {
          if (digit === 1) {
            return 'crm.students.groups.student';
          } else if (digit === 2 || digit === 3 || digit === 4) {
            return 'crm.students.groups.students1';
          } else {
            return 'crm.students.groups.students';
          }
        } else {
          return 'crm.students.groups.students';
        }
      }
    };

    const limitedSymbols = computed(() => {
      return props.isMobile ? 16 : 23;
    });

    onBeforeMount(async () => {
      await getAllGroups();
    });

    return {
      groupsPlaceholder,
      openAddGroupForm,
      openForm,
      isGroupListEmpty,
      groupsList,
      closeForm,
      currentStudent,
      showStudentName,
      studentsGroupLimited,
      deleteGroup,
      saveForm,
      editGroup,
      currentGroup,
      setGroup,
      setLoader,
      openGroupCard,
      openModal,
      openedGroup,
      openStudentCard,
      openDeleteGroup,
      acceptDelete,
      questionModalTop,
      editOpenedGroup,
      studentCase,
      currentGroupObj,
      limitedSymbols
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/sass/style";

.lp-crm-groups {
  padding-top: 36px;

  &_mobile {
    padding: 0 12px;
  }

  &__icon {
    position: absolute;
    right: 30px;
    bottom: -20px;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_mobile {
      margin-top: 60px;
    }
  }

  &__button {
    margin-top: 20px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    min-height: 147px;
    margin: 0 20px 20px 0;
    padding: 20px;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    cursor: pointer;

    &_mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  &__title {
    max-width: 200px;
    word-break: break-word;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 800;
    color: $color-mine-shaft;
  }

  &__count {
    margin-top: 6px;
  }

  &__menu {
    cursor: pointer;

    &_mobile {
      margin: -10px -10px 0 0;
    }
  }

  &__student {
    position: relative;
    margin-left: -7px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__rest-students {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -7px;
    border: 2px solid $color-white;
    border-radius: 50%;
    font-size: 10px;
    font-weight: 700;
    background-color: $color-purple;
    color: $color-white;
    z-index: 2;
  }

  &__image {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid $color-white;
    background-color: $color-white;
    cursor: pointer;
  }

  &__tooltip {
    margin-top: 5px;
  }
}

.lp-crm-groups-list {
  display: flex;
  margin-top: 10px;
}

.lp-crm-groups-header {
  display: flex;
  justify-content: space-between;
}

.lp-crm-groups-modal {
  min-width: 450px;
  padding: 20px 20px 0 20px;

  &_mobile {
    min-width: 300px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
  }

  &__list {
    max-height: 400px;
    max-width: 500px;
    overflow: auto;

    &_mobile {
      height: calc(100vh - 220px);
      max-height: initial;
    }
  }

  &__student {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;

    div {
      word-break: break-word;
    }
  }

  &__image {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  &__avatar {
    margin-right: 6px;
  }

  &__icon {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 7px;
    border-radius: 50%;
    background-color: $color-moon-raker;
    cursor: pointer;
  }

  &__menu {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &__question {
    right: calc(50% - 450px);
    top: calc(50% + 50px);
    z-index: 100;

    &_mobile {
      right: calc(50% - 150px);
    }
  }
}

</style>