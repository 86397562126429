<template>
  <div v-if="isMobile">
    <div
      class="lp-crm-settings-price-header"
      :class="{'lp-crm-settings-price-header_mobile': isMobile}"
      v-if="editable"
    >
      <div>
        <div
          class="lp-crm-settings-price-header__icon"
          @click="$emit('close')"
        >
          <closeModalIcon />
        </div>
      </div>
      <div
        class="lp-crm-settings-price-header__title"
      >
        {{ $t('crm.settings.title') }}
      </div>
      <div
        class="lp-crm-settings-price-header__icon"
        @click="$emit('save')"
      >
        <checkIcon />
      </div>
    </div>
    <div
      class="lp-crm-settings-price-inputs lp-crm-settings-price-inputs_edit"
      :class="{
        'lp-crm-settings-price-inputs_mobile': isMobile,
        'lp-crm-settings-price-inputs_edit_mobile': isMobile
      }"
      v-if="editable"
    >
      <Form
        class="lp-profile-base-form__wrapper"
        :class="{
          'lp-profile-base-form__wrapper_mobile': isMobile
        }"
        @submit="$emit('save')"
        :inputs="profileBasicInputs"
      />
    </div>
  </div>

  <Form
    v-else
    class="lp-profile-base-form__wrapper"
    :class="{
      'lp-profile-base-form__wrapper_mobile': isMobile
    }"
    @submit="$emit('save')"
    :inputs="profileBasicInputs"
  />
</template>

<script>
import Form from '@/components/Main/Form/Form';
import { closeModalIcon } from '@/constants/icons';
import { checkIcon } from '@/constants/icons/crm';
import profileBasicInputs from '@/constants/inputs/profileBasicInputs';

export default {
  name: 'EditMainInformation',
  components: {
    Form,
    closeModalIcon,
    checkIcon
  },
  props: {
    isMobile: Boolean,
    editable: Boolean
  },
  emits: ['save'],
  setup () {
    return {
      profileBasicInputs
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-price {
  display: grid;
  grid-template-columns: 1fr;

  &-header {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: $color-white;
    border-bottom: 1px solid $color-alto;
    grid-template-columns: auto 1fr auto;
    z-index: 14;

    &_mobile {
      display: grid;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      text-align: center;

      &_alone {
        padding-left: 54px;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      padding: 17px;
    }
  }

  &-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 34px;

    &_mobile {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }

    &_edit {
      grid-gap: 28px 18px;

      &_mobile {
        display: flex;
        flex-direction: column;
      }
    }
    &_costBlock {
      display: flex;
      column-gap: 10px;
    }

    &-item {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 14px;
      align-items: center;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;

      &_mobile {
        align-items: start;
        grid-template-columns: 125px 1fr;
      }

      &__label {
        font-weight: 800;
        letter-spacing: 0.07em;
        white-space: nowrap;

        &_mobile {
          white-space: pre-wrap;
        }

        &:after {
          content: ':';
        }
      }

      &__value {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      &__icon {
        width: 18px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }

  &__footer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 170px);
    grid-gap: 0 4px;
    align-items: center;
    justify-content: end;
    margin-top: 38px;
  }

  &__button-save {
    grid-column: 2;
  }

}

.lp-profile-base {
  &-form {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 34px;

    &_mobile {
      grid-template-columns: 1fr;
      grid-gap: 28px;
    }

    &__settings {
      grid-template-columns: 1fr;
      grid-column: 1/3;

      &_mobile {
        grid-column: 1;
      }
    }

    &_editable {
      grid-template-columns: 1fr;
    }

    &-buttons {
      display: grid;
      grid-template-columns: repeat(2, 170px);
      grid-gap: 4px;
      align-items: center;
      justify-content: end;
      margin-top: 38px;

      &__error {
        left: 0;
        top: auto;
      }

      &__save {
        grid-column: 2;
      }
    }

    &__wrapper {
      grid-template-columns: 1fr 1fr;

      &_mobile {
        grid-template-columns: 1fr;
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: $color-alto;
    }

    &-item {
      display: grid;
      grid-template-columns: auto 1fr;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;

      &_hide {
        opacity: 0;

        &_mobile {
          display: none;
        }
      }

      &_mobile {
        align-items: start;
        grid-template-columns: 125px 1fr;
      }

      &__label {
        font-weight: 800;
        letter-spacing: 0.07em;
        user-select: none;

        &:after {
          content: ':';
        }
      }

      &__value {
        display: flex;
        align-items: center;
        margin-left: 14px;
        word-break: break-word;
      }

      &__country-flag {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        object-fit: cover;
      }

      &.hideItem {
        display: none;
      }
    }
  }
}

</style>
