<template>
  <div
    class="lp-student-past-lessons"
    :class="{'lp-student-past-lessons_mobile': isMobile}"
    v-if="columns.length && lessons.length"
  >
    <div
      class="lp-student-past-lessons__title"
      :class="{'lp-student-past-lessons__title_mobile': isMobile}"
    >
      {{ $t('crm.students.form.pastLessons') }}
    </div>
    <div
      class="lp-student-past-lessons__table"
      :class="{'lp-student-past-lessons__table_mobile': isMobile}"
    >
      <LPTable
        :sticky="0"
        :columns="columns"
        :rows="lessons"
      />
    </div>
  </div>
</template>

<script>
import LPTable from '@/components/Main/Table/LPTable';
import { computed, onBeforeMount, ref } from 'vue';
import upcomingLessonsColumns from '@/constants/tables/upcomingLessonsColumns';
import LessonsApi from '@/api/CRM/lessons';
import { get, cloneDeep } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentPastLessons',
  components: { LPTable },
  props: {
    student: Object
  },
  emits: ['update:loading'],
  setup (props, { emit }) {
    const columnsClone = computed(() => cloneDeep(upcomingLessonsColumns));
    const columns = computed(() => columnsClone.value.map(item => {
      if (item.checkDateLesson) item.checkDateLesson = false;
      return item;
    }));
    const lessons = ref([]);
    const studentId = computed(() => get(props, 'student._id', ''));

    const query = computed(() => ({ studentId: studentId.value, status: 'Complete' }));

    const getLessons = async () => {
      if (!studentId.value) return;
      emit('update:loading', true);
      try {
        const { data } = await LessonsApi.getLessonsByStudent(query.value);
        lessons.value = data.resource;
      } catch (err) {
        console.error(err);
      }
      emit('update:loading', false);
    };

    onBeforeMount(getLessons);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      lessons,
      columns
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-student-past-lessons {
  margin-bottom: 25px;

  &_mobile {
    margin: 0 -12px 25px;
  }

  &__title {
    position: sticky;
    top: 0;
    background-color: $color-white;
    padding-top: 26px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-black;
    text-transform: uppercase;
    z-index: 2;

    &_mobile {
      top: 55px;
      padding: 25px 12px 8px;
      z-index: 6;
    }
  }

  &__table {
    max-height: 188px;
    overflow: auto;

    &_mobile {
      max-height: 100%;
    }
  }
}

</style>
