<template>
  <transition name="slideUploadInfo">
    <div
      class="lp-file-upload-list"
      :class="{
        'lp-file-upload-list_mobile': mobile,
        'lp-file-upload-list_cancel': openCancelled && mobile
      }"
      v-if="fileQueue.length"
    >
      <FileUploadListHeader
        :files="fileQueue"
        :mobile="mobile"
        v-model:modal="openCancelled"
        @close="resetFiles"
      />
      <div
        class="lp-file-upload-list-body"
        :class="{'lp-file-upload-list-body_mobile': mobile}"
      >
        <FileUploadItem
          v-for="file of fileQueue"
          :key="file.id"
          :modal="modal"
          :file="file"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { forEach, get, last } from 'lodash';
import getFileBinary from '@/constants/utils/getFileBinary';
import FileUploadListHeader from '@/components/CRM/Files/FilesUploadList/FileUploadListHeader';
import FileUploadItem from '@/components/CRM/Files/FilesUploadList/FileUploadItem';
import FILE_STATUS from '@/constants/enums/fileStatus';
import FilesApi from '@/api/CRM/files';
import { getErrorText } from '@/constants/utils/setErrorText';
import timeout from '@/constants/utils/timeout';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FileUploadList',
  components: {
    FileUploadItem,
    FileUploadListHeader
  },
  props: {
    mobile: Boolean,
    modal: Boolean,
    files: [Array, FileList],
    dragIn: String,
    path: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:drag'],
  setup (props, { emit }) {
    const { t } = useI18n();
    const maxFileMB = 20;
    const maxFileSize = maxFileMB * 1e+6;

    const store = useStore();
    const rootFolder = computed(() => store.getters.rootFolder);
    const filesList = computed(() => store.getters.filesList);
    const setFilesList = (data) => store.dispatch('setFilesList', data);

    const fileQueue = reactive([]);

    const resetFiles = () => {
      fileQueue.splice(0, fileQueue.length);
    };

    const updateStore = (data) => {
      const currentPath = get(last(props.path), '_id', rootFolder.value);
      if (currentPath !== data.parentFolder) return;
      setFilesList(filesList.value.concat(data));
    };

    const uploadFile = async () => {
      yandexTracker.fileUpload();
      const { UPLOADING, WAIT, UPLOADED, PRE_UPLOADED, ERROR } = FILE_STATUS;
      await timeout(100);
      const currentFile = fileQueue.find(({ status }) => status === UPLOADING);
      if (currentFile) return;
      const file = fileQueue.find(({ status }) => status === WAIT);
      if (!file) {
        emit('update:drag', '');
        return;
      }
      file.status = UPLOADING;
      const lastFolderPath = get(last(props.path), '_id', rootFolder.value);
      const dragInFolder = props.dragIn || lastFolderPath;
      const query = {
        originalName: file.name,
        parentFolder: dragInFolder
      };
      try {
        const { data } = await FilesApi.uploadFile(file.fileBinary, query);
        yandexTracker.addFile();
        file.status = PRE_UPLOADED;
        file.data = data;
        await timeout(500);
        file.status = UPLOADED;
        updateStore(data);
      } catch (err) {
        console.error(err);
        file.error = getErrorText(err);
        file.status = ERROR;
      }
    };
    watch(fileQueue, uploadFile);

    const addFiles = (files) => {
      forEach(files, (file) => {
        const fileBinary = getFileBinary(file);
        const {
          name,
          type,
          size
        } = file;
        const format = get([...type.matchAll(/(.+)\/(.+)/gmi)], [0, 2], '');
        const {
          WAIT,
          ERROR
        } = FILE_STATUS;
        const validType = true; // fileTypes.find(({ types }) => types.includes(format));
        const validSize = size < maxFileSize;
        const icon = get(validType, 'miniIcon', '');
        const result = {
          id: Date.now(),
          icon,
          status: (validType && validSize) ? WAIT : ERROR,
          size,
          name,
          format,
          file,
          fileBinary
        };
        if (!validType) result.error = 'errors.unknownType';
        if (!validSize) result.error = t('errors.bigSize', { size: maxFileMB });
        fileQueue.push(result);
      });
    };
    watch(() => props.files, addFiles);
    watch(fileQueue, (files) => {
      const val = files.length;
      const el = document.querySelector('.mc-container');
      if (!el || !props.mobile) return;
      el.style.opacity = val ? 0 : 1;
      el.style.height = val ? '0' : '100%';
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
    });

    const openCancelled = ref(false);

    return {
      openCancelled,
      fileQueue,
      resetFiles
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-upload-list {
  @include global-font;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 423px;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  background-color: $color-white;
  padding-bottom: 18px;
  z-index: 9999999999;

  &_mobile {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    z-index: 101;
  }

  &_cancel {
    z-index: 99;
  }

  &-body {
    display: grid;
    padding: 25px 16px 0;
    background-color: $color-white;
    border-radius: 0 0 4px 4px;
    max-height: 50vh;
    min-height: 81px;
    overflow: auto;

    &_mobile {
      max-height: calc(100vh - 120px);
      max-height: calc((var(--vh, 1vh) * 100) - 120px);
    }
  }
}

</style>
