import { cloneDeep, uniqueId, get, set, map } from 'lodash';
import { reactive } from 'vue';
import CATEGORY_EXPENSES from '@/constants/enums/categoryExpenses';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';

const isMobile = checkMobileByScreen();

const options = map(CATEGORY_EXPENSES, (item) => ({ expenseCategory: item.title, ...item }));

const inputs = [
  {
    type: 'select',
    label: 'crm.expenses.form.select',
    mode: 'expenseCategory',
    modelName: 'expenseCategory',
    keyProp: 'expenseCategory',
    translate: true,
    hide: false,
    lessonStatus: '',
    modelValue: options[0],
    getValue: (val) => val.id,
    getFullObject: (val) => options.find(item => item.id === val) || options[0],
    options,
    style: {
      gridColumn: '1/4'
    },
    maxHeight: isMobile ? '376px' : ''
  },
  {
    type: 'date',
    label: 'crm.expenses.form.date',
    modelName: 'expenseDate',
    modelValue: { date: [new Date()] },
    fixed: true,
    maxDate: new Date(),
    format: 'DD MMMM, YYYY',
    style: {
      gridColumn: '1/2',
      minWidth: isMobile ? '170px' : '234px'
    }
  },
  {
    type: 'number',
    inputmode: 'decimal',
    required: true,
    autofocus: true,
    label: 'crm.expenses.form.price',
    modelName: 'amount',
    modelValue: '',
    emptyError: 'errors.correctAmount',
    noWrap: true,
    style: {
      maxWidth: '114px',
      gridColumn: '2/auto'
    }
  },
  {
    type: 'currency',
    disabled: true,
    modelName: 'currency',
    modelValue: '',
    visibility: false,
    style: {
      maxWidth: '106px',
      gridColumn: '2/4',
      marginLeft: '-5px'
    }
  },

  {
    type: 'textarea',
    label: 'crm.expenses.form.comment',
    modelName: 'comment',
    modelValue: '',
    emptyError: 'errors.emptyComment',
    style: {
      fontSize: '14px',
      minHeight: '142px'
    },
    maxlength: '200'
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('expenses-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  const gridColumn = get(item, 'style.gridColumn', '');
  if (!gridColumn) set(item, 'style.gridColumn', '1/4');
  return item;
}));
