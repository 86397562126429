import * as types from '@/constants/mutationTypes';

const state = {
  mp3File: {
    file: null,
    originalName: '',
    playing: false,
    timeTrack: 0,
    duration: 0
  }
};
const getters = {
  mp3File: (state) => state.mp3File
};
const actions = {
  setMp3File ({ commit }, data) {
    commit(types.SET_MP3_FILE, { data });
  }
};
const mutations = {
  [types.SET_MP3_FILE] (state, { data }) {
    state.mp3File = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
