<template>
  <div
    class="lp-form"
    :class="{'lp-form_sign-up': signUp}"
  >
    <transition-group
      name="fade-fast"
      mode="out-in"
    >
      <div
        v-show="input.visibility !== false || input.type === 'subscribe'"
        class="lp-form__item"
        :class="{
          'lp-form__item_checkbox': input.type === 'checkbox',
          'lp-form__item_hide': input.hide ||
            input.type === 'selectImage' && input.modelName === 'crmStudentId' && isGroupLesson ||
            input.type === 'multiselect' && input.modelName === 'crmStudentId' && !isGroupLesson ||
            input.modelName === 'crmLessonPass' && isGroupLesson ||
            (input.modelName === 'crmLessonCost' || input.modelName === 'currency') && !isGroupLesson && hasPass ||
            input.type === 'subscribe' && !hasPass && !isGroupLesson ||
            input.type === 'subscribe' && isTrialLesson ||
            input.type === 'groupSelect' && !isGroupLesson
          ,
          'lp-form__item_first': input.modelName === 'fullName',
          'lp-form__item_group-cost': input.modelName === 'crmLessonCost' && isGroupLesson,
          'lp-form__item_group-currency': input.modelName === 'currency' && isGroupLesson
        }"
        v-for="input of inputs"
        :key="input.id"
        :id="input.id"
        :style="input.style"
      >
        <component
          :is="getInputComponent(input)"
          v-model="input.modelValue"
          :input="input"
          @help="$emit('help', input)"
          @submit="$emit('submit')"
          @handlerChange="$emit('handler-change', $event)"
          @handleDataChange="(value) => handleDataChange(value, input)"
          @focus="handlerFocus(input, $event)"
          @set-group="setGroup"
          @set-student="setFirstStudent"
          :settings="input.settings"
          :lessonStatus="input.crmLessonStatus"
          :numberBusy="input.numberBusy"
          :timePickerSettings="input.timePickerSettings"
          :lesson="lesson"
          :isGroupLesson="isGroupLesson"
          v-bind="input"
          :newStudent="newStudent"
          :firstStudent="firstStudent"
          :inputs="inputs"
          :crmStudents="crmStudents"
          :isMobile="isMobile"
          :emptyList="emptyList"
        />
        <BirthdayNotify
          v-if="input.additionalComponent === 'BirthdayNotify'"
          :is="input.additionalComponent"
          v-model="input.birthdayNotify"
          v-bind="input"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import CustomInput from '@/components/Main/Inputs/CustomInput';
import CountrySelect from '@/components/Main/Inputs/CountrySelect';
import CustomRadio from '@/components/Main/Inputs/CustomRadio';
import StatusSelect from '@/components/Main/Inputs/StatusSelect';
import CustomTimePicker from '@/components/Main/Inputs/CustomTimePicker';
import DatePicker from '@/components/Main/Inputs/DatePicker/DatePicker';
import BirthdayNotify from '@/components/CRM/Students/StudentForm/BirthdayNotify';
import PhoneInput from '@/components/Main/Inputs/PhoneInput/PhoneInput';
import CustomCheckbox from '@/components/Main/Inputs/CustomCheckbox';
import CustomTextarea from '@/components/Main/Inputs/CustomTextarea';
import TimePicker from '@/components/Main/Inputs/TimePicker';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import CustomSelectImage from '@/components/Main/Inputs/CustomSelectImage';
import MultipleSelect from '@/components/Main/Inputs/MultipleSelect';
import GroupSelect from '@/components/Main/Inputs/GroupSelect';
import SocialNetworks from '@/components/Main/Inputs/SocialNetworks';
import CustomNote from '@/components/Main/Inputs/CustomNote';
import StudentsList from '@/components/CRM/Groups/GroupForm/StudentsList';
import { computed, watch, ref } from 'vue';
import CustomRange from '@/components/Main/Inputs/CustomRange';
import SubscribeInfo from '@/components/Main/Inputs/SubscribeInfo';
import MobileDetect from 'mobile-detect';
import 'vue3-timepicker/dist/VueTimepicker.css';
import { clockIcon } from '@/constants/icons';
import ColorPicker from '@/components/Main/Inputs/ColorPicker/ColorPicker';

export default {
  name: 'Form',
  components: { BirthdayNotify, clockIcon },
  props: {
    inputs: Array,
    item: Object,
    signUp: Boolean,
    lesson: Object,
    newStudent: Object,
    isTrialLesson: Boolean,
    crmStudents: Array,
    emptyList: Boolean
  },
  emits: ['help', 'submit', 'handler-change', 'filled', 'handle-data-change', 'set-group', 'check-lesson'],
  setup (props, { emit }) {
    const getInputComponent = (input) => {
      switch (input.type) {
      case 'checkbox':
        return CustomCheckbox;
      case 'textarea':
        return CustomTextarea;
      case 'time':
        return TimePicker;
      case 'timePicker':
        return CustomTimePicker;
      case 'radio':
        return CustomRadio;
      case 'selectStatus':
        return StatusSelect;
      case 'date':
        return DatePicker;
      case 'phone':
        return PhoneInput;
      case 'select':
        return CustomSelect;
      case 'countrySelect':
        return CountrySelect;
      case 'selectImage':
        return CustomSelectImage;
      case 'multiselect':
        return MultipleSelect;
      case 'groupSelect':
        return GroupSelect;
      case 'socialNetworks':
        return SocialNetworks;
      case 'note':
        return CustomNote;
      case 'range':
        return CustomRange;
      case 'subscribe':
        return SubscribeInfo;
      case 'studentsList':
        return StudentsList;
      case 'colorPicker':
        return ColorPicker;
      default:
        return CustomInput;
      }
    };

    const isGroupLesson = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const handlerFocus = (input, i) => {
      if (i) {
        const errorIndex = input.errorArray ? input.errorArray.findIndex((item) => item === i) : -1;
        if (errorIndex !== -1) {
          input.errorArray.splice(errorIndex, 1);
        }
      } else {
        props.inputs.forEach(item => item.error = false);
      }
    };

    const scrollToError = (inputs = []) => {
      const inputWithError = inputs.find(input => input.error);
      if (!inputWithError) return;
      const element = document.getElementById(inputWithError.id);
      if (!element) return;
      const scrollOptions = { block: 'center' };
      if (!isMobile.value) scrollOptions.behavior = 'smooth';
      element.scrollIntoView(scrollOptions);
    };

    watch(props.inputs, scrollToError);
    watch(props.inputs, () => {
      emit('filled', props.inputs);

      if (props.lesson) {
        const inputsList = Object.values(props.inputs);
        const lessonType = inputsList.find(item => item.modelName === 'lessonType');
        isGroupLesson.value = lessonType ? lessonType.modelValue !== 'single' : null;
        emit('check-lesson', isGroupLesson.value);
      }
    });

    const handleDataChange = (item, input) => {
      emit('handle-data-change', item, input);
    };

    const firstStudent = ref(null);

    const setFirstStudent = (val) => {
      firstStudent.value = val;
    };

    const hasPass = computed(() => {
      const passInput = props.inputs.find(input => input.modelName === 'crmStudentId');
      const passValue = passInput && passInput.modelValue && passInput.modelValue.crmLessonPass;
      const subscriptionValue = !!(props.lesson &&  props.lesson.crmLessons && props.lesson.crmLessons[0] &&
          props.lesson.crmLessons[0].crmLessonPassId);
      const lessonId = props.lesson && props.lesson._id;
      return !isGroupLesson.value && (subscriptionValue && lessonId && passValue) || (lessonId && passValue &&
              passInput.modelValue.crmLessonPass.availableToCreateLessonsCount > 0) || (!lessonId &&
          passValue && passInput.modelValue.crmLessonPass.availableToCreateLessonsCount > 0);
    });

    const setGroup = (data) => {
      emit('set-group', data);
    };

    return {
      getInputComponent,
      handlerFocus,
      handleDataChange,
      isGroupLesson,
      setFirstStudent,
      firstStudent,
      hasPass,
      setGroup,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-form {
  @include global-font;
  display: grid;
  grid-gap: 28px 18px;

  &_sign-up {
    grid-auto-columns: 1fr;
  }

  &__item {
    &_checkbox {
      margin-top: 19px;
    }

    &_hide {
      display: none;
    }

    &_first {
      order: -1;
    }

    &_group-cost {
      max-width: 200px !important;
      grid-column: 1 / 3 !important;
    }

    &_group-currency {
      grid-column: 3 / 4 !important;
      margin-left: 10px !important;

      @media (max-width: 450px) {
        margin-left: 65px !important;
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    &_doubled {
      width: calc(50% - 20px);
    }
  }

  &__group-note {
    margin-top: 5px;
    font-size: 12px;
  }

}

</style>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.vue__time-picker {

  input {
    border-radius: 4px;
    border-color: $color-silver-chalice-dark;
    height: 2.7em;

    &:hover {
      cursor: pointer;
    }
  }

  .has-custom-btn {
    position: relative;
    left: -8px;
  }

  .dropdown {
    left: 1px;
    top: 50px;
    width: 99%;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  }

  .hint {
    display: none;
  }

  .dropdown ul li {

    &:hover {
      background: rgba($color-accent, 0.08) !important;
    }
  }

  .active {
    background: $color-purple !important;
  }
}

.custom-time-picker__item {
  width: 100%;
}
</style>
