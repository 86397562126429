<template>
  <div class="lp-birthday-notify">
    <CustomCheckbox
      v-bind="settings"
      v-model="settings.modelValue"
    >
      <template #label>
        <span class="lp-birthday-notify__label">{{ $t(settings.label) }}</span>
      </template>
    </CustomCheckbox>
  </div>
</template>

<script>
import CustomCheckbox from '@/components/Main/Inputs/CustomCheckbox';
import { onBeforeMount, reactive, watch } from 'vue';

export default {
  name: 'BirthdayNotify',
  components: { CustomCheckbox },
  props: {
    birthdayNotify: Boolean
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const settings = reactive({
      label: 'crm.students.form.birthdayNotify',
      hasLabelSlot: true,
      modelValue: true
    });

    watch(() => props.birthdayNotify, () => settings.modelValue = props.birthdayNotify);
    onBeforeMount(() => settings.modelValue = props.birthdayNotify);

    watch(settings, () => {
      emit('update:modelValue', settings.modelValue);
    });

    return {
      settings
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-birthday-notify {
  margin-top: 12px;

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-text;
  }
}

</style>
