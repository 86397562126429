<template>
  <button
    class="lp-sharing-files__button"
    :disabled="uploadingFile.local"
    @click.stop="openModalFiles"
  >
    <sharingFilesIcon />
    <span>{{ $t('lessonCall.sharingFiles') }}</span>
  </button>
  <Modal
    v-model:open="openFiles"
    :removeBtnText="$t('buttons.cancel')"
    :saveBtnText="$t('buttons.demo')"
    :mobileVersion="isMobile"
    :editMode="isMobile"
    @remove="openFiles = false"
    @save="loadFile"
    title="myFiles.title"
  >
    <MyFiles
      :modal="true"
      :filter="'sharingFiles'"
      @save="loadFile"
      v-model:file="selectedFile"
    />
  </Modal>

  <Modal
    v-model:open="openWarning"
    :saveBtnText="'buttons.update'"
    :removeBtnText="'buttons.cancel'"
    :hideButtons="['save']"
    @remove="openWarning = false"
  >
    <div class="lp-sharing-files-modal-warning">
      <img
        class="lp-sharing-files-modal-warning__img"
        :src="errorPlaceholder"
        alt=""
      />
      <div class="lp-sharing-files-modal-warning__text">
        {{ $t('lessonCall.sharingMobileWarning') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import MyFiles from '@/components/CRM/Files/MyFiles';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { sharingFilesIcon } from '@/constants/icons';
import { fileTypes } from '@/constants/fileTypes';
import errorPlaceholder from '@/assets/images/calibrationPlaceholders/error.png';

export default {
  name: 'SharingFiles',
  components: { Modal, MyFiles, sharingFilesIcon },
  setup (props, { emit }) {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);
    const connection = computed(() => store.getters.rtcConnect);
    const mobileParticipant = computed(() => store.getters.mobileParticipant);
    const sharingFile = computed(() => store.getters.sharingFile);
    const setSharingFile = (data) => store.dispatch('setSharingFile', data);
    const sharingFileList = computed(() => store.getters.sharingFileList);
    const setSharingFileList = (data) => store.dispatch('setSharingFileList', data);

    const openWarning = ref(false);
    const openFiles = ref(false);
    const selectedFile = ref({});
    const uploadingFile = ref({ local: false, remote: false });

    watch(uploadingFile, () => {
      emit('update:loading', uploadingFile.value);
    });

    const openModalFiles = () => {
      console.log('mobileParticipant', mobileParticipant.value);
      if (mobileParticipant.value) {
        openWarning.value = true;
        return;
      }
      if (uploadingFile.value.local) return;
      openFiles.value = true;
    };

    const loadFile = async () => {
      const { url, _id, originalName, mimetype = '' } = selectedFile.value;
      if (!url || !_id || (sharingFileList.value.find(f => f.id === _id))) return;
      const type = mimetype.replace(/.+\//g, '');
      const { isImage, isPdf } = fileTypes.find((item) => item.types.includes(type)) || fileTypes[5];

      uploadingFile.value.local = true;
      uploadingFile.value.remote = true;
      openFiles.value = false;

      try {
        const file = { id: _id, url, isImage, isPdf, name: originalName };
        connection.value.send({ type: 'loadSharingFile', file: file });
        await setSharingFile(file);
        await setSharingFileList([...sharingFileList.value, file]);
      } catch (e) {
        console.error(e);
      } finally {
        uploadingFile.value.local = false;
        // yandexTracker.fileSelect();
      }
    };

    watch(mobileParticipant, () => {
      if (mobileParticipant.value && sharingFile.value) {
        openWarning.value = true;
        setSharingFile(null);
        setSharingFileList([]);
      }
    });

    return {
      isMobile,
      openFiles,
      selectedFile,
      uploadingFile,
      openWarning,
      errorPlaceholder,
      openModalFiles,
      loadFile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-sharing-files__button {
  display: grid;
  grid-template-columns: 12px auto;
  gap: 6px;
  align-items: center;
  justify-content: center;
  background-color: $color-outer-space;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: $global-font;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.1em;
  color: $color-white;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.lp-sharing-files-modal-warning {
  width: 675px;
  padding: 48px 20px;

  &__text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    white-space: pre-line;
  }

  &__img {
    width: 100%;
    height: auto;
    max-width: 300px;
    padding-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

</style>
