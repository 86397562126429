import * as types from '@/constants/mutationTypes';

const state = {
  settings: {},
  notifications: {}
};
const getters = {
  settings: (state) => state.settings,
  notifications: (state) => state.notifications
};
const actions = {
  setSettings ({ commit }, data) {
    commit(types.SET_SETTINGS, { data });
  },
  setNotifications ({ commit }, data) {
    commit(types.SET_NOTIFICATIONS, { data });
  }
};
const mutations = {
  [types.SET_SETTINGS] (state, { data }) {
    state.settings = data;
  },
  [types.SET_NOTIFICATIONS] (state, { data }) {
    state.notifications = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
