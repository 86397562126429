<template>
  <div
    class="lp-timetable-link"
    :class="{'lp-timetable-link_mobile': isMobile}"
  >
    <span
      class="lp-timetable-link__title"
      v-if="!isMobile"
    >
      {{ $t('timetable.personalLink') }}
    </span>
    <a
      class="lp-timetable-link__link"
      :class="{
        'lp-timetable-link__link_mobile': isMobile,
        'lp-timetable-link__link_copied': copiedLink
      }"
      :href="timetableLink"
      target="_blank"
    >
      {{ isMobile ? $t('timetable.linkTitle') : timetableLink }}
      <transition name="fade">
        <span
          class="lp-timetable-link__copied"
          :class="{'lp-timetable-link__copied_mobile': isMobile}"
          v-if="copiedLink"
        >
          {{ $t('lessonCall.copied') }}
        </span>
      </transition>
    </a>
    <LPTooltip
      :text="$t('lessonCall.copyLink')"
      :position="'right'"
    >
      <div
        class="lp-timetable-link-icon"
        :class="{'lp-timetable-link-icon_mobile': isMobile}"
        @click="copyUrl"
      >
        <copyIcon />
      </div>
    </LPTooltip>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { copyIcon } from '@/constants/icons/crm';
import { FRONTEND_URL } from '@/constants/domains';
import { useStore } from 'vuex';
import timeout from '@/constants/utils/timeout';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import MobileDetect from 'mobile-detect';

export default {
  name: 'PersonalLink',
  components: { LPTooltip, copyIcon },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);

    const timetableLink = computed(() => `${FRONTEND_URL}/${user.value._id}`);

    const copiedLink = ref(false);
    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(timetableLink.value);
        yandexTracker.copyTimetable();
        copiedLink.value = true;
        await timeout(2000);
        copiedLink.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      timetableLink,
      copiedLink,
      copyUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-link {
  @include global-font;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -19px;
  cursor: pointer;

  &_mobile {
    position: fixed;
    margin-top: 0;
    top: 10px;
    right: 8px;
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    color: $color-black;
    margin-right: 12px;
  }

  &__link-url {
    opacity: 0;
    pointer-events: none;
  }

  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-accent;
    margin-right: 16px;
    transition: color 0.5s linear;

    &_mobile {
      text-decoration: underline;
    }

    &_copied {
      color: transparent;
    }
  }

  &__copied {
    position: absolute;
    right: 44px;
    left: 0;
    top: auto;
    text-align: right;
    color: $color-chateau-green;
    background-color: $color-white;

    &_mobile {
      display: inline-flex;
      align-items: center;
      height: 29px;
      position: fixed;
      margin-top: 0;
      top: 10px;
      right: 45px;
      left: auto;
    }
  }

  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 50%;
    background: $color-moon-raker;
    max-width: 28px;
    max-height: 28px;

    &_mobile {
      max-width: 32px;
      max-height: 32px;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

</style>
