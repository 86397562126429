<template>
  <div>
    <UploadInput
      :maxSizeMB="1000"
      :multiple="true"
      :accept="validTypes"
      v-model:file="files"
    >
      <LPTooltip :text="$t('myFiles.upload')">
        <div
          class="lp-files-new-file"
        >
          <uploadIcon />
        </div>
      </LPTooltip>
    </UploadInput>
  </div>
</template>

<script>
import { uploadIcon } from '@/constants/icons/files';
import { ref, watch } from 'vue';
import UploadInput from '@/components/Main/Inputs/UploadInput';
import { validTypes } from '@/constants/fileTypes';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'ButtonNewFile',
  components: {
    LPTooltip,
    UploadInput,
    uploadIcon
  },
  emits: ['update:files'],
  setup (props, { emit }) {
    const files = ref(null);
    watch(files, () => {
      emit('update:files', files.value);
    });

    return {
      files,
      validTypes
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-files-new-file {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);

  &:hover {
    box-shadow: 0 0 15px rgba($color-black, 0.15);
  }

  svg {
    margin-bottom: 2px;
  }
}

</style>
