import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  authUser (user) {
    user.login = user.email;
    const instCred = axios.create({
      baseURL: MUSIO_SERVER
    });
    return instCred.post('auth/login', user);
  },
  registrationUser (user, lang) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        'Accept-Language': lang
      }
    });
    return instCred.post('auth/signup', user);
  },
  forgotPassword (user, lang) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        'Accept-Language': lang
      }
    });
    return instCred.post('auth/forgot-password', user);
  },
  resetForgotPassword (data, lang) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        'Accept-Language': lang
      }
    });
    return instCred.post('auth/reset-forgotten-password', data);
  },
  getUser () {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('users/me');
  },
  resetPassword (passwords) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('auth/email/reset-password', passwords);
  }
};
