import {
  FacebookIcon, PinterestIcon, TwitterIcon, YouTubeIcon,
  InstagramIcon, LinkedInIcon, VkIcon, OkIcon
} from '@/constants/icons/iconsSocialMedia';

const socialNetworks = [
  {
    socialMediaType: 1,
    title: 'Facebook',
    icon: FacebookIcon()
  },
  {
    socialMediaType: 2,
    title: 'Pinterest',
    icon: PinterestIcon()
  },
  {
    socialMediaType: 3,
    title: 'Twitter',
    icon: TwitterIcon()
  },
  {
    socialMediaType: 4,
    title: 'YouTube',
    icon: YouTubeIcon()
  },
  {
    socialMediaType: 5,
    title: 'Instagram',
    icon: InstagramIcon()
  },
  {
    socialMediaType: 6,
    title: 'LinkedIn',
    icon: LinkedInIcon()
  },
  {
    socialMediaType: 7,
    title: 'Вконтакте',
    icon: VkIcon()
  },
  {
    socialMediaType: 8,
    title: 'Одноклассники',
    icon: OkIcon()
  }
];

export default socialNetworks;