<template>
  <div class="common-copy-link">
    <transition
      name="fade"
      mode="out-in"
    >
      <span
        v-if="isCopied"
        class="common-copy-link-copied"
      >
        {{ $t('lessonCall.copied') }}
      </span>
      <span
        v-else
        class="common-copy-link-room"
        :class="{'common-copy-link-room__mobile' : isMobile}"
      >
        <a
          target="_blank"
          class="common-copy-link-room_link"
          :class="{'common-copy-link-room_link__fullWidth' : fullWidth}"
          :href="link"
        >
          {{ link }}
        </a>
        <LPTooltip
          @click="copyUrl"
          class="common-copy-link-room_icon"
          :class="{'common-copy-link-room_icon__mobile' : isMobile}"
          :text="$t('crm.lessons.tooltips.copy')"
        >
          <linkIcon />
        </LPTooltip>
      </span>
    </transition>
  </div>
</template>

<script>
import { linkIcon } from '@/constants/icons/crm';
import { ref } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'CopyLink',
  components: { linkIcon, LPTooltip },
  props: {
    link: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    },
    fullWidth: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const isCopied = ref(false);

    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(props.link);
        isCopied.value = true;
        await setTimeout(() => {
          isCopied.value = false;
        }, 2000);
      } catch (err) {
        console.error(err);
      }
    };

    return { isCopied, copyUrl };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.common-copy-link {
  position: relative;

  &-copied {
    color: $color-chateau-green;
  }

  &-room {
    display: flex;
    align-items: center;

    &__mobile {
      justify-content: space-between;
    }

    &_link {
      color: $color-purple;
      max-width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (max-width: 420px) {
        max-width: 320px;
      }

      @media screen and (max-width: 400px) {
        max-width: 280px;
      }

      &__fullWidth {
        max-width: 100%;
      }
    }

    &_icon {
      cursor: pointer;
      padding: 5px;
      background-color: $color-moon-raker;
      border-radius: 100%;
      margin-left: 5px;

      &__mobile {
        padding: 12px;

        & svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
</style>
