<template>
  <Modal
    v-model:open="showModal"
    title="delaySettings.title"
    :hideButtons="['remove', 'save']"
    :mobileVersion="isMobile"
    :hideFooter="true"
  >
    <div
      class="lp-delay-page"
      :class="{'lp-delay-page_mobile': isMobile}"
    >
      <DelayStop
        v-if="stopCalibration"
        @stop="() => handleStop(true)"
        @repeat="() => handleRepeat()"
      />
      <DelaySelectDevice
        v-else-if="!device"
        @selected="(deviceId) => device = deviceId"
      />
      <DelayProgress
        v-else-if="device"
        :device="device"
        :error="error"
        :progress="percent"
        :success="percent >= 100"
        @back="() => device = null"
        @start="handleStart"
        @repeat="handleRepeat"
        @stop="handleDisplayStop"
        @next="handleStop"
      />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { useStore } from 'vuex';
import { computed, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import audioEngineLatency from '@/constants/_rtc/audioEngine/audioEngineLatency';
import timeout from '@/constants/utils/timeout';
import { audioConstraints } from '@/constants/_rtc/utils/initConnection';
import hideJivoChat from '@/constants/utils/hideJivoChat';
import DelayStop from '@/components/VideoCall/VideCallDelayPage/DelayStop';
import DelaySelectDevice from '@/components/VideoCall/VideCallDelayPage/DelaySelectDevice';
import DelayProgress from '@/components/VideoCall/VideCallDelayPage/DelayProgress';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { useI18n } from 'vue-i18n';

export default {
  name: 'DelayPage',
  components: {
    DelayProgress,
    DelaySelectDevice,
    DelayStop,
    Modal
  },
  setup () {
    const { t } = useI18n();
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const showDelaySetting = computed(() => store.getters.showDelaySetting);
    const audioSettings = computed(() => store.getters.audioSettings);

    const roomId = localStorage.getItem('calibration_room_id');
    const roomHref = roomId ? `/room/${roomId}` : '/main';

    const device = ref(null);
    const showModal = ref(true);
    const inProgress = ref(false);
    const stopCalibration = ref(false);
    const interval = ref(null);
    const timeoutInterval = ref(null);
    const percent = ref(0);
    const error = ref('');

    const setProgress = () => {
      const time = 6.2; // seconds
      const step = (100 / (time - 1));
      if (percent.value >= 99) clearInterval(interval.value);
      percent.value += step / 100;
    };

    const resetValues = (withoutError) => {
      latencyTestStop();
      if (interval.value) clearInterval(interval.value);
      if (timeoutInterval.value) clearInterval(timeoutInterval.value);
      if (!withoutError) error.value = '';
      percent.value = 0;
      inProgress.value = false;
    };

    const handleStart = () => {
      resetValues();
      interval.value = setInterval(setProgress, 10);
      timeoutInterval.value = setTimeout(timeExpected, 10000);
      inProgress.value = true;
      audioEngineLatency.start();
      audioEngineLatency.latencyTest();
    };

    const handleStop = (force) => {
      if (force) localStorage.setItem('without_calibration', true);
      window.location.href = roomHref;
    };

    const handleRepeat = async () => {
      resetValues();
      await setLoaderRun(true);
      await timeout(500);
      stopCalibration.value = false;
      await setLoaderRun(false);
    };

    const handleDisplayStop = () => {
      resetValues();
      stopCalibration.value = true;
    };

    const timeExpected = () => {
      if (!inProgress.value) return;
      error.value = t('delaySettings.errors.noSignal');
      resetValues(true);
    };

    const tooLoud = () => {
      if (!inProgress.value) return;
      error.value = t('delaySettings.errors.tooLoud');
      yandexTracker.calibrationErrorNoise();
      resetValues(true);
    };

    const tooSilent = () => {
      if (!inProgress.value) return;
      error.value = t('delaySettings.errors.tooSilent');
      yandexTracker.calibrationErrorVolume();
      resetValues(true);
    };

    const success = async () => {
      const value = audioEngineLatency.getStreamLatency();
      let devId = String(audioSettings.value.deviceId);
      let latencyObj = JSON.parse(localStorage.getItem('audio_latency'));
      if (latencyObj === null || typeof latencyObj !== 'object') latencyObj = {};
      if (!devId) devId = 'default';
      latencyObj[devId] = value;
      localStorage.setItem('audio_latency', JSON.stringify(latencyObj));
      clearInterval(interval.value);
      clearTimeout(timeoutInterval.value);
      await timeout(10);
      percent.value = 100;
      yandexTracker.calibrationSuccess();
    };

    const getLocalStream = async () => {
      await setLoaderRun(true);
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: audioConstraints, video: false });
        const devices = await navigator.mediaDevices.enumerateDevices();
        const [{ deviceId }] = devices.filter(d => d.kind === 'audioinput');
        audioSettings.value.deviceId = deviceId;
        audioEngineLatency.setInputStream(stream);
        await setLoaderRun(false);
      } catch (err) {
        await setLoaderRun(false);
        console.error(err);
      }
    };

    const latencyTestStop = () => {
      try {
        audioEngineLatency.latencyTestStop();
      } catch (e) {
        console.warn(e);
      }
    };

    watch(showDelaySetting, (val) => {
      if (!val) latencyTestStop();
      showModal.value = val;
    });

    watch(showModal, (val) => {
      if (!showModal.value) {
        showModal.value = true;
        handleDisplayStop();
      }
      store.dispatch('setShowDelaySetting', val);
    });

    watch(device, () => {
      percent.value = 0;
      error.value = '';
    });

    onMounted(async () => {
      await getLocalStream({});
      hideJivoChat(true);
      audioEngineLatency.setCalibrationCallbacks(tooLoud, tooSilent, success);
    });

    onBeforeUnmount(() => {
      if (!isMobile.value) hideJivoChat(false);
    });

    return {
      showModal,
      isMobile,
      stopCalibration,
      device,
      error,
      percent,
      handleStop,
      handleRepeat,
      handleStart,
      handleDisplayStop
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-delay-page {
  position: relative;
  min-width: 1036px;

  &_mobile {
    min-width: 100%;

    .lp-modal-content-header__title {
      margin-right: 0;
    }
  }
}

</style>

<style lang="scss">

.lp-delay-page {
  &_mobile {
    .lp-modal-content-header__title {
      margin-right: 0;
    }
  }
}

</style>
