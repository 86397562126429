<template>
  <div
    class="lp-profile-base"
    :class="{
      'lp-profile-base_editable': editable,
      'lp-profile-base_editable_mobile': editable && isMobile,
      'lp-profile-base_mobile': isMobile
    }"
  >
    <div
      class="lp-profile-base-header"
      :class="{
        'lp-profile-base-header_editable': editable,
        'lp-profile-base-header_mobile': isMobile
      }"
    >
      <h2
        class="lp-profile-base-header__title"
        :class="{
          'lp-profile-base-header__title_mobile': isMobile
        }"
      >
        {{ headerTitle }}
      </h2>
      <transition name="bounce">
        <LPTooltip
          :text="$t('crm.students.table.toolbar.edit')"
          v-if="!editable"
        >
          <div
            class="lp-profile-base-header__icon-edit"
            @click="turnOnEditMode('main')"
          >
            <pencil-icon />
          </div>
        </LPTooltip>
      </transition>
    </div>

    <div
      class="lp-profile-base-form"
      :class="{
        'lp-profile-base-form_mobile': isMobile
      }"
      v-if="!editable"
    >
      <div
        v-for="input of profileBasicInputs"
        :key="input.id"
        class="lp-profile-base-form-item"
        :class="{
          'lp-profile-base-form-item_mobile': isMobile,
          'hideItem': !input.modelValue || !input.displayValue
        }"
      >
        <div
          class="lp-profile-base-form-item__label"
        >
          {{ $t(input.label) }}
        </div>
        <div
          class="lp-profile-base-form-item__value"
          v-if="input.type === 'countrySelect' && input.modelValue"
        >
          <img
            class="lp-profile-base-form-item__country-flag"
            v-if="input.modelValue.icon"
            :src="input.modelValue.icon"
            alt="flag"
          >
          {{ input.modelValue.name ? $t(input.modelValue.name) : '' }}
        </div>
        <div
          class="lp-profile-base-form-item__value"
          v-else
        >
          {{ input.displayValue }}
        </div>
      </div>
      <div
        v-if="isMobile"
        class="lp-profile-base-form__divider"
      />
      <div v-if="isMobile">
        <div
          class="lp-profile-base-header"
          :class="{
            'lp-profile-base-header_editable': editable,
            'lp-profile-base-header_mobile settingTitleWrapper': isMobile
          }"
        >
          <h2
            class="lp-profile-base-header__title"
            :class="{
              'lp-profile-base-header__title_mobile': isMobile
            }"
          >
            {{ $t('profile.settings') }}
          </h2>
          <transition
            name="bounce"
          >
            <LPTooltip
              :text="$t('crm.students.table.toolbar.edit')"
              v-if="!editable"
            >
              <div
                class="lp-profile-base-header__icon-edit"
                @click="turnOnEditMode('settings')"
              >
                <pencil-icon />
              </div>
            </LPTooltip>
          </transition>
        </div>
      </div>
      <SettingsPrice
        class="lp-profile-base-form__settings"
        :class="{
          'lp-profile-base-form__settings_mobile': isMobile
        }"
        @openEdit="$emit('update:editable', true)"
        v-model:settings="noSettings"
        :view="true"
      />
    </div>

    <div
      class="lp-profile-base-form lp-profile-base-form_editable"
      v-else
    >
      <EditMainInformation
        v-if="!isMobile || editableFields === 'main'"
        :isMobile="isMobile"
        :editable="editable"
        @save="saveForm"
        @close="closeForm"
      />
      <div
        v-if="!isMobile"
        class="lp-profile-base-form__divider"
      />
      <EditSettings
        v-if="!isMobile || editableFields === 'settings'"
        @save="saveForm"
        @close="closeForm"
        @openEdit="$emit('update:editable', true)"
        :noProfile="noProfile"
        :editable="editable"
      />
      <transition name="bounce">
        <span
          class="lp-profile-base-form-buttons__error lp-error"
          v-if="errorText"
        >
          <dangerIcon />
          {{ errorText }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import profileBasicInputs from '@/constants/inputs/profileBasicInputs';

import { computed, inject, onBeforeMount, ref } from 'vue';
import { get, isEmpty } from 'lodash';
import { dangerIcon, pencilIcon } from '@/constants/icons/crm';
import validateProfile from '@/constants/validatesForm/validateProfile';
import UserApi from '@/api/User/api';
import ROLE_TYPES from '@/constants/enums/roles';
import { useStore } from 'vuex';
import { getErrorText } from '@/constants/utils/setErrorText';
import getFileBinary from '@/constants/utils/getFileBinary';
import FilesApi from '@/api/CRM/files';
import SettingsPrice from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/SettingsPrice';
import country from '@/constants/country';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import MobileDetect from 'mobile-detect';
import { useRouter } from 'vue-router';
import EditMainInformation from '@/components/CRM/Settings/ProfileSettings/Profile/EditMainInformation';
import EditSettings from '@/components/CRM/Settings/ProfileSettings/Profile/EditSettings';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ProfileBase',
  components: {
    EditSettings,
    EditMainInformation,
    LPTooltip,
    SettingsPrice,
    pencilIcon,
    dangerIcon
  },
  props: {
    user: Object,
    editable: Boolean,
    avatar: Object,
    editableFields: {
      type: String,
      defaultValue: null
    }
  },
  emits: ['update:editable', 'show-modal', 'show-editable'],
  setup (props, { emit }) {
    const countryCode = inject('countryCode');
    const router = useRouter();
    const isFirst = computed(() => get(router, 'currentRoute.value.params.isFirst', ''));
    const store = useStore();
    const setUser = (data) => store.dispatch('setUser', data);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const noSettings = ref(false);

    const { t } = useI18n();

    const turnOnEditMode = (value) => {
      emit('show-editable', value);
      emit('update:editable', true);
    };

    const headerTitle = computed(() => {
      if (isMobile.value && props.editable) {
        return props.editableFields === 'settings' ? t('profile.settings') : t('profile.mainInfo');
      }

      if (noProfile.value || noSettings.value) {
        return t('profile.mainInfoRequired');
      }
      return t('profile.mainInfo');
    });

    const errorText = ref('');

    const uploadFile = async (file, body) => {
      if (!file) return;
      const fileBinary = getFileBinary(file);
      try {
        const { data } = await FilesApi.uploadFile(fileBinary);
        body.uploadFile = data._id;
        body.fullFile = data;
        return data;
      } catch (err) {
        console.error(err);
        errorText.value = getErrorText(err);
      }
    };

    const saveForm = async () => {
      const body = await validateProfile(profileBasicInputs);
      if (!body) return;
      await setLoaderRun(true);
      try {
        const oldAvatar = props.user.uploadFile;
        body._id = get(props.user, '_id', '');
        body.role = ROLE_TYPES.TEACHER;
        body.phone = isEmpty(body.phone) ? null : body.phone;
        if (props.avatar) await uploadFile(props.avatar, body);
        if (!body.uploadFile) body.uploadFile = oldAvatar;
        const { data } = await UserApi.updateUser(body);
        if (body.uploadFile) data.uploadFile = body.fullFile || oldAvatar;
        await setUser(data);
        emit('update:editable', false);
        if (isFirst.value) {
          emit('show-modal', isFirst.value);
        }
        setDefaultValues();
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        errorText.value = getErrorText(err);
        await setLoaderRun(false);
      }
    };

    const closeForm = () => {
      setDefaultValues();
      emit('update:editable', false);
    };

    const setDefaultValues = async () => {
      errorText.value = '';
      profileBasicInputs.forEach((input) => {
        if (input.modelName === 'country') {
          input.defaultValue = get(country.find((item => item.code === countryCode)), 'id', '');
        }
        const value = get(props.user, input.modelName, input.defaultValue) || input.defaultValue;
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
        input.displayValue = input.getDisplay ? input.getDisplay(value) : value;
        if (input.type === 'phone') {
          if (isEmpty(input.modelValue)) {
            input.modelValue = {
              number: '',
              country: ''
            };
          } else {
            const { country: code  } = input.modelValue;
            const placeholder = get(country.find((item => item.code === code)), 'mask', '');
            input.modelValue = {
              ...input.modelValue,
              placeholder
            };
          }
        }
      });
    };

    onBeforeMount(setDefaultValues);

    const user = computed(() => store.getters.user);
    const noProfile = computed(() => {
      const {
        firstName,
        lessonCost
      } = user.value;
      return !firstName || !lessonCost;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      errorText,
      profileBasicInputs,
      saveForm,
      closeForm,
      noProfile,
      noSettings,
      turnOnEditMode,
      headerTitle
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-profile-base {
  position: relative;
  padding: 0 35px 40px;
  display: grid;
  grid-template-columns: minmax(600px, 900px);
  justify-content: center;
  border-bottom: 1px solid $color-alto;
  max-width: 900px;
  margin: 0 auto;

  &_mobile {
    max-width: 100%;
    grid-template-columns: 1fr;
    padding: 0 20px 28px;
  }

  &_editable {
    border-bottom-color: transparent;

    &_mobile {
      padding: 0 12px 28px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    min-height: 42px;
    user-select: none;

    &_mobile {
      margin-bottom: 16px;

      &_editable {
        display: none;
      }

      &.settingTitleWrapper {
        margin-bottom: 0;
      }
    }

    &__title {
      font-weight: 800;
      font-size: 18px;
      line-height: 125%;
      letter-spacing: 0.07em;
      color: $color-mine-shaft-dark;

      &_mobile {
        font-weight: bold;
        font-size: 16px;
      }
    }

    &__icon-edit {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 13px;
      box-shadow: 0 0 17px rgba(0, 0, 0, 0.08);
      background-color: $color-white;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-in;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &-form {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 34px;

    &_mobile {
      grid-template-columns: 1fr;
      grid-gap: 28px;
    }

    &__settings {
      grid-template-columns: 1fr;
      grid-column: 1/3;

      &_mobile {
        grid-column: 1;
      }
    }

    &_editable {
      grid-template-columns: 1fr;
    }

    &-buttons {
      display: grid;
      grid-template-columns: repeat(2, 170px);
      grid-gap: 4px;
      align-items: center;
      justify-content: end;
      margin-top: 38px;

      &__error {
        left: 0;
        top: auto;
      }

      &__save {
        grid-column: 2;
      }
    }

    &__wrapper {
      grid-template-columns: 1fr 1fr;

      &_mobile {
        grid-template-columns: 1fr;
      }
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: $color-alto;
    }

    &-item {
      display: grid;
      grid-template-columns: auto 1fr;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;

      &_hide {
        opacity: 0;

        &_mobile {
          display: none;
        }
      }

      &_mobile {
        align-items: start;
        grid-template-columns: 125px 1fr;
      }

      &__label {
        font-weight: 800;
        letter-spacing: 0.07em;
        user-select: none;

        &:after {
          content: ':';
        }
      }

      &__value {
        display: flex;
        align-items: center;
        margin-left: 14px;
        word-break: break-word;
      }

      &__country-flag {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        object-fit: cover;
      }

      &.hideItem {
        display: none;
      }
    }
  }
}

</style>

<style lang="scss">
@import '~styles/_variables.scss';

.lp-profile-base-header__icon-edit {
  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    path {
      fill: $color-accent;
    }
  }
}
</style>
