<template>
  <component
    :is="isLink ? 'a' : 'div'"
    class="lp-table-column-item"
    :href="href"
    :class="[
      className(text),
      {
        'lp-table-column-item_link': isLink,
        'lp-table-column-item_amount': column.prop === 'amount' && isMobile
      }
    ]"
  >
    <template v-if="showValue">
      {{ text }}
      <span v-if="postfix"> {{ postfix }}</span>
    </template>
  </component>
  <div
    v-if="icon"
    :style="iconStyle"
  >
    <LPTooltip
      v-if="tooltipText"
      :text="$t(tooltipText)"
      :isComment="hasComment"
    >
      <component :is="icon" />
      <span class="lp-table-column-item__icon-text">
        {{ $t(tooltipText) }}
      </span>
    </LPTooltip>
    <component
      v-else
      :is="icon"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import numeral from 'numeral';
import getPhoneString from '@/constants/utils/getPhoneString';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import MobileDetect from 'mobile-detect';

export default {
  name: 'TextCell',
  components: {
    LPTooltip
  },
  props: {
    column: Object,
    row: Object
  },
  emits: ['delete'],
  setup (props) {
    const hasComment = computed(() => !!get(props.row, 'comment', false));

    const text = computed(() => {
      const value = get(props.row, props.column.prop, '');
      const type = get(props.column, 'type', '');
      const format = get(props.column, 'format', '0');
      if (type === 'number') {
        return value ? numeral(value).format(format) : 0;
      } else if (type === 'phone') {
        return getPhoneString(value);
      } else {
        return value;
      }
    });
    const iconProp = computed(() => get(props.column.iconProp, 'prop'));
    const icon = computed(() => {
      if (iconProp.value) {
        const isShowIcon = get(props.row, iconProp.value);
        return isShowIcon ? get(props.column, 'iconProp.icon') : false;
      }
      return false;
    });

    const iconStyle = computed(() => {
      if (icon.value) {
        return get(props.column, 'iconProp.style', undefined);
      }
      return false;
    });

    const tooltipText = computed(() => {
      if (icon.value) {
        if (iconProp.value === 'comment') {
          const isShowValue = get(props.row, iconProp.value);
          return isShowValue ? isShowValue : '';
        }
        return get(props.column, 'iconProp.tooltipText', '');
      }
      return '';
    });

    const showValue = computed(() => {
      if (iconProp.value) {
        const isShowValue = get(props.row, iconProp.value);
        return isShowValue
          ? get(props.column, 'iconProp.showValue', false)
          : true;
      }
      return true;
    });

    const postfix = computed(() =>
      get(props.row, props.column.postfixCell, props.column.postfixCell)
    );

    const className = (val) => {
      const getter = get(props.column, 'getClassName', () => {});
      return getter(val);
    };

    const isLink = computed(() => {
      const links = ['email', 'phone'];
      return links.includes(props.column.type);
    });

    const href = computed(() => {
      const { type } = props.column;
      if (type === 'email') {
        return `mailto:${text.value}`;
      } else if (type === 'phone') {
        return `tel:${text.value.replace(/\s|\(|\)|-/gim, '')}`;
      }
      return '#';
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      href,
      isLink,
      text,
      postfix,
      className,
      icon,
      showValue,
      iconStyle,
      tooltipText,
      hasComment,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-table-column-item {
  display: flex;
  align-items: center;

  &_amount {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  &_link {
    cursor: pointer;
    transition: 0.3s ease-in;
    word-break: break-word;

    &:hover {
      color: $color-accent;
      text-decoration: underline;
    }
  }

  &_negative {
    padding: 5px 12px;
    color: $color-white;
    background-color: $color-red;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
  }

  &__icon-text {
    display: none;
  }

  &_ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}
</style>
