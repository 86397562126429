<template>
  <div
    class="lp-calendar-lesson-card-link"
    v-if="url"
  >
    <div
      class="lp-calendar-lesson-card-link__label"
      v-if="!hideLabel"
    >
      {{ $t('calendar.lesson.link') }}
    </div>
    <div
      class="lp-calendar-lesson-card-link-value"
      :style="{fontSize}"
    >
      <VideoCallCreator :roomId="url.replace(/.+\//gmi, '')">
        <span
          class="lp-calendar-lesson-card-link-value__url"
        >
          {{ url }}
        </span>
      </VideoCallCreator>
      <LPTooltip :text="$t('crm.lessons.tooltips.copy')">
        <div
          class="lp-calendar-lesson-card-link-value__icon"
          :class="{'lp-calendar-lesson-card-link-value__icon_mobile': mobile}"
          @click="copyUrl"
        >
          <linkIcon :style="{width: fontSize, height: fontSize}" />
        </div>
      </LPTooltip>
    </div>
    <transition name="fade">
      <div
        class="lp-calendar-lesson-card-link-value lp-calendar-lesson-card-link-value_copied"
        :class="{'lp-calendar-lesson-card-link-value_mobile': mobile}"
        v-if="copiedLink"
      >
        <span class="lp-calendar-lesson-card-link__copy-text">{{ $t('lessonCall.copied') }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import timeout from '@/constants/utils/timeout';
import { get } from 'lodash';
import { linkIcon } from '@/constants/icons/crm';
import VideoCallCreator from '@/components/VideoCall/VideoCallCreator/VideoCallCreator';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'LinkLessonCard',
  components: {
    LPTooltip,
    VideoCallCreator,
    linkIcon
  },
  props: {
    hideLabel: Boolean,
    lesson: Object,
    mobile: Boolean,
    fontSize: String
  },
  setup (props) {
    const url = computed(() => get(props.lesson, 'virtualClassUrl', ''));

    const copiedLink = ref(false);
    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(url.value);
        copiedLink.value = true;
        await timeout(2000);
        copiedLink.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    return {
      url,
      copiedLink,
      copyUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-calendar-lesson-card-link {
  position: relative;
  display: grid;
  grid-gap: 4px;

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: $color-text
  }

  &-value {
    display: flex;
    align-items: center;
    color: $color-accent;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    cursor: pointer;
    background-color: $color-white;

    &__icon {
      position: relative;

      &_mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $color-moon-raker;
        width: 32px;
        height: 32px;
      }
    }

    &__url {
      max-width: 254px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 12px;
      cursor: pointer;
    }

    svg {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      z-index: 2;
    }

    &:hover &__url{
      opacity: 0.8;
    }

    &_copied {
      position: absolute;
      left: 0;
      bottom: 0;
      display: grid;
      width: 100%;
      max-width: 254px;
      grid-template-columns: 200px 1fr;
      align-items: center;
      justify-content: start;
      grid-gap: 28px;
      color: $color-chateau-green;
      pointer-events: none;
    }

    &_mobile {
      top: 19px;
      right: 0;
      z-index: 5;
      background-color: $color-white;
      grid-template-columns: 1fr;
      max-width: none;
    }
  }
}

</style>
