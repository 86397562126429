import store from '@/store/store';

const mp3Player = (function () {
  return (() => {
    /**
     * Public methods:
     * {@link init} - initialization store and variables
     * {@link play} - play/start/resume audio
     * {@link pause} - pause audio
     * {@link stop} - stop music and disconnect AudioBufferSourceNode
     * {@link loadFile} - upload to buffer audio file
     * {@link setVolume} - set volume for audio
     * {@link setPosition} - set position time for audio
     *
     * Private methods:
     * {@link getBlobFile} - download blob file and create new File()
     * {@link readAsArrayBuffer} - create AudioBufferSourceNode, set buffer and connect to audioCtx
     * {@link readFileAsync} - util add promise for readAsArrayBuffer
     * {@link endAudioSource} - handler event onended for AudioBufferSourceNode
     * {@link stopAudioSource} - stop and disconnect AudioBufferSourceNode
     * {@link rewind} - create new AudioBufferSourceNode for repeat audio
     * {@link startPositionUpdate} - start interval for calc time track
     * {@link pausePositionUpdate} - pause interval for calc time track
    */

    let audioCtx;
    let audioSource;
    let gainOutNode = null;
    let muteNode = null;
    let localGainNode = null;
    let gainStreamNode = null;
    let mp3File;
    let buffer;
    let sourceEnded = true;
    let playing = false;
    let intervalTimeTrack;
    let currentPlaybackDelay = 0;
    let playStartTime = 0;
    let currentGain = 1;
    let setMp3File;
    let playStartPosition = 0;
    let playheadPosition = 0;

    //TODO: Get volume lavel from store on init (also save to cookies)
    /** Public methods */
    const init = (context) => {
      audioCtx = context;
      if (!gainStreamNode) {
        localGainNode = audioCtx.createGain();
        gainOutNode = audioCtx.createGain();
        muteNode = audioCtx.createGain();
        gainStreamNode = audioCtx.createGain();
        gainOutNode.gain.value = 1;
        muteNode.gain.value = 1;
        gainStreamNode.gain.value = 1;
        localGainNode.gain.value = 1;
        localGainNode.connect(gainStreamNode);
        localGainNode.connect(gainOutNode);
        gainOutNode.connect(muteNode);
        muteNode.connect(audioCtx.destination);
      }

      // TODO надо найти нормальное решение прокинуть сюда store.
      setTimeout(() => {
        mp3File = store.getters.mp3File;
      }, 5000);
    };

    /** @param delay - delay playback in seconds */
    const play = (delay = 0) => {
      if (playing) {
        // pausePositionUpdate();
        stopAudioSource();
      }
      audioSource = audioCtx.createBufferSource();
      audioSource.connect(localGainNode);
      audioSource.onended = endAudioSource;
      audioSource.buffer = buffer;
      playStartTime = audioCtx.currentTime + delay;
      playheadPosition = audioCtx.currentTime - playStartTime + playStartPosition;
      audioSource.start(playStartTime, playStartPosition);
      // startPositionUpdate(delay, 0.05);
      currentPlaybackDelay = delay;
      sourceEnded = false;
      mp3File.playing = true;
      playing = true;
    };

    const pause = () => {
      if (mp3File) mp3File.playing = false;
      // pausePositionUpdate();
      playStartPosition = audioCtx.currentTime - playStartTime + playStartPosition;
      playheadPosition = playStartPosition;
      if (mp3File) mp3File.timeTrack = playStartPosition;
      playing = false;
      stopAudioSource();
    };

    const stop = () => {
      // pausePositionUpdate();
      stopAudioSource();
      playStartPosition = 0;
      playheadPosition = 0;
      playing = false;
      sourceEnded = true;
      if (mp3File) {
        mp3File.playing = false;
        mp3File.timeTrack = 0;
      }
    };

    /** @param value - volume level (0.0 - 2.0) */
    const setVolume = (value) => {
      currentGain = value;
      gainOutNode.gain.value = currentGain;
    };

    /** @param time - position in seconds */
    const setPosition = (time) => {
      console.log('postion time: ');
      console.log(time);
      // pausePositionUpdate();
      playStartPosition = time;
      if (playing) {
        play(currentPlaybackDelay);
      } else {
        playheadPosition = time;
      }
    };

    const getPosition = () => {
      if (playing) {
        playheadPosition = audioCtx.currentTime - playStartTime + playStartPosition;
        if (playheadPosition >= buffer.duration) {
          rewind();
          return -1;
        }
      }
      return playheadPosition;
    };

    /** @param file - object from db
     * (http://localhost:3001/documentation/#/Upload-file/UploadFileController_getAllFiles) */
    const loadFile = async (file) => {
      stop();
      try {
        if (!mp3File) mp3File = store.getters.mp3File;
        const buffer = await readAsArrayBuffer(file);
        return buffer;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    /** Private methods */
    const getBlobFile = async ({ url, mimetype, originalName }) => {
      const hasProtocol = (/^https:\/\//g).test(url);
      const fullUrl = hasProtocol ? url : `https://${url}`;
      const blob = await fetch(fullUrl).then(r => r.blob());
      const metadata = { type: mimetype };
      return new File([blob], originalName, metadata);
    };

    //TODO: Return promise
    const readAsArrayBuffer = async (args) => {
      const file = await getBlobFile(args);
      const arrayBuffer = await readFileAsync(file);
      //await setAudioSource(audioCtx.createBufferSource());
      buffer = await audioCtx.decodeAudioData(arrayBuffer);
      mp3File.duration = buffer.duration;
      return arrayBuffer;
    };

    const readFileAsync = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });

    const endAudioSource = () => {
      playheadPosition = audioCtx.currentTime - playStartTime + playStartPosition;
      if (playheadPosition >= buffer.duration) {
        rewind();
      }
    };

    const stopAudioSource = () => {
      if (!audioSource) return;
      audioSource.stop(0);
      audioSource.disconnect();
      audioSource = null;
      sourceEnded = true;
    };

    const rewind = () => {
      console.log('REWIND');
      stopAudioSource();
      // pausePositionUpdate();
      mp3File.playing = false;
      playing = false;
      mp3File.timeTrack = 0;
      playStartPosition = 0;
      playheadPosition = -1;
    };

    /** @param updateInterval - time track update interval in seconds */
    /** @param delay - start interaval after, seconds */
    const startPositionUpdate = (delay = 0, updateInterval = 0.25) => {
      setTimeout(() => {
        clearInterval(intervalTimeTrack);
        intervalTimeTrack = setInterval(() => {
          const { timeTrack = 0 } = mp3File;
          if (buffer) {
            const newTimeTrack = (audioCtx.currentTime - playStartTime + playStartPosition) / buffer.duration;
            if (newTimeTrack > audioSource.buffer.duration) {
              rewind();
              return;
            }
            mp3File.timeTrack = newTimeTrack;
          } else {
            clearInterval(intervalTimeTrack);
          }
        }, updateInterval * 1000);
      }, delay * 1000);
    };

    const pausePositionUpdate = () => {
      clearInterval(intervalTimeTrack);
    };

    const isPlaying = () => {
      return playing;
    };

    const isLoaded = () => {
      return !!(buffer);
    };

    const getStreamNode = () => {
      // const osc = audioCtx.createOscillator();
      // osc.type = 'square';
      // osc.frequency.setValueAtTime(440, audioCtx.currentTime); // value in hertz
      // osc.connect(mp3PlayerGain);
      // osc.start();
      if (!gainStreamNode) {
        localGainNode = audioCtx.createGain();
        gainOutNode = audioCtx.createGain();
        muteNode = audioCtx.createGain();
        gainStreamNode = audioCtx.createGain();
        gainOutNode.gain.value = 1;
        muteNode.gain.value = 1;
        gainStreamNode.gain.value = 1;
        localGainNode.gain.value = 1;
        localGainNode.connect(gainStreamNode);
        localGainNode.connect(gainOutNode);
        gainOutNode.connect(muteNode);
        muteNode.connect(audioCtx.destination);
      }
      return gainStreamNode;
    };

    const mute = () => {
      if (muteNode) muteNode.gain.value = 0;
    };
    const unmute = () => {
      if (muteNode) muteNode.gain.value = 1;
    };
    const muteStream = () => {
      if (gainStreamNode) gainStreamNode.gain.value = 0;
    };
    const unmuteStream = () => {
      if (gainStreamNode) gainStreamNode.gain.value = 1;
    };

    return {
      init,
      play,
      pause,
      stop,
      getPosition,
      loadFile,
      setVolume,
      setPosition,
      isPlaying,
      isLoaded,
      mute,
      unmute,
      getStreamNode,
      muteStream,
      unmuteStream
    };
  })();
}());

export default mp3Player;
