<template>
  <div>
    <a
      href="https://mooz.pro"
      class="lp-create-lesson-room__wrapper"
      target="_blank"
    >
      <slot>
        <div class="lp-create-lesson-room lp-button">
          {{ $t(label) }}
        </div>
      </slot>
    </a>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import uniqid from 'uniqid';

export default {
  name: 'VideoCallCreator',
  props: {
    roomId: {
      type: String
    },
    disabledSidebar: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'createRoom.createLesson'
    }
  },
  setup (props) {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const isMobile = computed(() => store.getters.isMobile);

    const openRoom = () => {
      // console.log(props);
      // if (props.disabledSidebar) return;
      // setLoaderRun(true);
      // yandexTracker.createRoom();
      // const roomId = props.roomId || uniqid();
      // if (isMobile.value) {
      //   createRoom({ roomId });
      // } else {
      //   const routeData = router.resolve({ name: 'VideoRoom', params: { roomId } });
      //   const windowReference = window.open(routeData.href, '_blank');
      //   createRoom({ roomId, windowReference });
      // }
    };

    const router = useRouter();
    const createRoom = ({ roomId, windowReference }) => {
      if (windowReference) {
        const routeData = router.resolve({ name: 'VideoRoom', params: { roomId: roomId } });
        windowReference.location = routeData.href;
      } else {
        router.push({ name: 'VideoRoom', params: { roomId: roomId } });
      }
      setLoaderRun(false);
    };

    return {
      openRoom
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-create-lesson-room {
  padding-left: 28px;
  padding-right: 28px;

  &__wrapper {
    display: block;
    width: 100%;
    color: inherit;
    font: inherit;
  }
}

</style>
