import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  createLessonPassTemplate (template) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/lesson-pass-template/', template);
  },
  getLessonsPassTemplate ({ teacherId }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`crm/lesson-pass-template/all/getbyteacher?teacherId=${teacherId}`);
  },
  updateLessonPassTemplate (template) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`crm/lesson-pass-template/${template._id}`, template);
  },
  deleteLessonPassTemplateById (lessonPassTemplateId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`crm/lesson-pass-template/${lessonPassTemplateId}`);
  }
};
