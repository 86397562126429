<template>
  <LPTooltip :text="$t('myFiles.addFolder')">
    <button
      class="lp-files-new-folder"
      @click.stop="$emit('update:status', true)"
    >
      <addFileIcon />
    </button>
  </LPTooltip>
</template>

<script>
import { addFileIcon } from '@/constants/icons/files';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'ButtonNewFolder',
  components: {
    LPTooltip,
    addFileIcon
  },
  emits: ['update:status'],
  setup () {}
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-files-new-folder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  background-color: $color-accent;

  svg {
    transform: scale(0.8);
  }

  &:hover {
    background-color: $color-accent-hover;
  }
}

</style>
