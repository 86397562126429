<template>
  <div class="lp-delete-cell">
    <LPTooltip
      :text="$t('crm.students.table.toolbar.delete')"
      v-if="displayIcon"
    >
      <div
        class="lp-delete-cell-icon"
        :class="{'lp-delete-cell-icon_disabled': displayAcceptDelete}"
        ref="deleteEl"
        @click.stop="displayAcceptDelete = !displayAcceptDelete"
      >
        <trashToolbarIcon />
      </div>
    </LPTooltip>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition name="bounce">
        <AcceptDelete
          :class="{
            'lp-delete-cell__popup': !isMobile
          }"
          v-if="displayAcceptDelete"
          v-model:status="displayAcceptDelete"
          :item="row"
          :center="isMobile"
          :background="isMobile"
          :name="$t('crm.students.form.payment')"
          :position="positionPopup"
          @accept="acceptDelete"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { trashToolbarIcon } from '@/constants/icons/crm';
import { computed, ref } from 'vue';
import AcceptDelete from '@/components/Main/Table/Toolbar/AcceptDelete';
import MobileDetect from 'mobile-detect';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { get } from 'lodash';

export default {
  name: 'DeleteCell',
  components: {
    LPTooltip,
    AcceptDelete,
    trashToolbarIcon
  },
  props: {
    row: Object,
    column: Object
  },
  emits: ['delete'],
  setup (props, { emit }) {
    const deleteEl = ref(null);
    const positionPopup = computed(() =>
      deleteEl.value ? deleteEl.value.getBoundingClientRect() : null
    );
    const displayAcceptDelete = ref(false);
    const displayIcon = computed(() => {
      const getValue = get(props.column, 'visible', () => true);
      return getValue(props.row);
    });

    const acceptDelete = () => {
      emit('delete', props.row);
      displayAcceptDelete.value = false;
    };
    const getStyleTooltip = () => {
      if (!deleteEl.value) return {};
      const position = deleteEl.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left - 25}px`,
        width: '70px'
      };
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      positionPopup,
      deleteEl,
      displayIcon,
      displayAcceptDelete,
      acceptDelete,
      getStyleTooltip
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-delete-cell {
  &__popup {
    width: 100px;
    transform: translate(calc(-100% + 28px), 28px);
  }

  &-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &_disabled {
      pointer-events: none;
    }

    svg {
      transition: 0.2s ease-in;
    }
  }
}
</style>
