import detectNeedZoom from '@/constants/utils/detectNeedZoom';

const addZoomClass = () => {
  if (detectNeedZoom()) {
    document.getElementById('app').classList.add('app-zoom');
  } else {
    document.getElementById('app').classList.remove('app-zoom');
  }
};

const removeZoomClass = () => {
  document.getElementById('app').classList.remove('app-zoom');
};

export {
  addZoomClass,
  removeZoomClass
};