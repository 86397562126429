<template>
  <div
    class="lp-widget"
    :class="{'lp-widget_mobile': isMobile}"
    v-if="teacher && !emptyTeacher"
  >
    <div class="lp-widget-teacher">
      <WidgetProfile :teacher="teacher" />
    </div>
    <div class="lp-widget-body">
      <WidgetTimetable :teacher="teacher" />
    </div>
  </div>
  <div
    class="lp-widget-empty"
    v-else-if="teacherNotFound || serverError"
  >
    <img
      class="lp-widget-empty__image"
      :src="warningImg"
      alt="warning"
    >
    <p class="lp-widget-empty__title">
      {{ $t(`crm.widget.teacherNotFound.${serverError ? 'server' : 'client'}.title`) }}
    </p>
    <p class="lp-widget-empty__description">
      {{ $t(`crm.widget.teacherNotFound.${serverError ? 'server' : 'client'}.text`) }}
    </p>
    <p class="lp-widget-empty__description">
      {{ $t(`crm.widget.teacherNotFound.${serverError ? 'server' : 'client'}.text2`) }}
    </p>
  </div>
</template>

<script>
import WidgetProfile from '@/components/CRM/Lessons/TimetableWidget/WidgetProfile';
import WidgetTimetable from '@/components/CRM/Lessons/TimetableWidget/WidgetTimetable';
import TeachersApi from '@/api/User/api';
import { useRouter } from 'vue-router';
import { computed, onBeforeMount, ref } from 'vue';
import { get } from 'lodash';
import warningImg from '@/assets/images/warning.png';
import { useI18n } from 'vue-i18n';
import setMomentLocale from '@/constants/utils/getMomentLocale';
import { useStore } from 'vuex';
import MobileDetect from 'mobile-detect';
import getLanguage from '@/constants/utils/getLanguage';

export default {
  name: 'Widget',
  components: { WidgetTimetable, WidgetProfile },
  setup () {
    const router = useRouter();
    const teacherId = computed(() => get(router, 'currentRoute.value.params.id', ''));
    const teacher = ref(null);
    const teacherNotFound = ref(false);
    const serverError = ref(false);

    const store = useStore();
    const countryCode = computed(() => store.getters.countryCode);
    const { locale } = useI18n({ useScope: 'global' });
    const setLocale = () => {
      const lang = getLanguage(countryCode.value);
      setMomentLocale(lang);
      locale.value = lang;
    };

    const isValidId = (id) => !!id.match(/^[0-9a-fA-F]{24}$/);

    const getTeacher = async () => {
      if (!isValidId(teacherId.value)) {
        await router.push({ name: 'Landing' });
        return;
      }
      try {
        const { data } = await TeachersApi.getUserById(teacherId.value);
        teacher.value = data;
        teacherNotFound.value = false;
        serverError.value = false;
        setLocale();
      } catch (e) {
        const { response = {} } = e;
        const { status } = response;
        teacherNotFound.value = !!/^4/.test(status);
        serverError.value = !!/^5/.test(status);
        console.error(e);
      }
    };

    onBeforeMount(getTeacher);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      warningImg,
      teacherNotFound,
      serverError,
      teacher
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-widget {
  @include global-font;
  display: grid;
  grid-template-columns: 252px 1fr;
  grid-gap: 24px;
  width: 100%;
  padding: 28px 24px;
  background-color: $color-alabaster;

  &_mobile {
    padding: 28px 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
    user-select: none;

    &__image {
      max-width: 424px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      margin-bottom: 14px;
    }

    &__description {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      max-width: 524px;
      margin-bottom: 4px;
    }
  }
}

</style>
