import { cloneDeep, uniqueId, get, set } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    type: 'selectImage',
    label: 'crm.lessons.form.student',
    fixed: true,
    modelName: 'crmStudentId',
    modelValue: {},
    keyProp: 'fullName',
    keyImg: 'uploadPhoto.url',
    options: [],
    searchable: true,
    getValue: (val) => val._id
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('subscription-apply-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  const gridColumn = get(item, 'style.gridColumn', '');
  if (!gridColumn) set(item, 'style.gridColumn', '1/4');
  return item;
}));
