<template>
  <div class="lp-status-select">
    <CustomSelect
      :options="statusList"
      :searchable="false"
      :modelValue="modelValue"
      :error="error"
      :fixed="fixed"
      v-model="selectedStatus"
      keyProp="title"
      :required="required"
      :label="label"
      @focus="$emit('focus')"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-status-select-status"
          :style="value.style || {}"
        >
          {{ getLocaleText(value[keyProp]) }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-status-select-status lp-status-select-status_list"
          :style="item.style"
        >
          {{ $t(item[keyProp]) }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import statusStudentList from '@/constants/crm/studentStatuses';
import { useI18n } from 'vue-i18n';

export default {
  name: 'StatusSelect',
  components: { CustomSelect },
  props: {
    modelValue: Object,
    required: Boolean,
    label: String,
    error: [Boolean, String],
    fixed: Boolean
  },
  emits: ['update:modelValue', 'focus'],
  setup (props, { emit }) {
    const selectedStatus = ref({});

    watch(selectedStatus, () => {
      emit('update:modelValue', selectedStatus);
    });
    const { t } = useI18n();

    const getLocaleText = (prop) => prop ? t(prop) : '';

    const statusList = computed(() => statusStudentList);

    return { statusList, selectedStatus, getLocaleText };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-status-select {
  @include global-font;

  &-status {
    width: max-content;
    padding: 5px 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    color: $color-white;
    border-radius: 4px;
    user-select: none;

    &_list {
      margin: -5px -12px;
    }
  }
}

</style>
