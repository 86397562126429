<template>
  <div class="lp-teacher-card-about">
    <p
      class="lp-teacher-card-about__text"
      :class="{'lp-teacher-card-about__text_open': openText}"
    >
      {{ id === 'work' ? t(works) : t(about) }}
    </p>
    <p
      class="lp-teacher-card-about__open"
      v-if="id !== 'work' || !hideNext"
      @click="openText = !openText"
    >
      {{ openText ? t('crm.students.form.lessMore') : t('crm.students.form.readMore') }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'TeacherCardAbout',
  props: {
    id: String,
    teacher: Object
  },
  setup (props) {
    const { t } = useI18n();

    const hideNext = computed(() => props.teacher.hideNext);
    const about = computed(() => props.teacher.about);
    const works = computed(() => props.teacher.workText);
    const openText = ref(false);

    return {
      openText,
      works,
      about,
      hideNext,
      t
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-teacher-card-about {
  padding: 28px 24px 0;
  max-width: 596px;

  &__text {
    display: block;
    max-height: 132px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: $color-text;
    overflow: hidden;
    white-space: pre-line;
    margin-bottom: 6px;
    transition: 0.4s ease;
    transform-origin: top;

    &_open {
      max-height: 1000px;
    }
  }

  &__open {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-accent;
    cursor: pointer;
    transition: 0.3s ease-in;

    &:hover {
      color: $color-accent-hover
    }
  }
}

</style>
