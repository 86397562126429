<template>
  <div
    class="systemcrm"
    id="systemcrm"
  >
    <div class="systemcrm__container">
      <div class="systemcrm__content">
        <div class="systemcrm__title">
          <span> {{ $t('landing.systemcrm.name.first') }} </span> <br />
          {{ $t('landing.systemcrm.name.second') }}
        </div>
        <div
          class="systemcrm-item"
          v-for="item of systemcrm"
          :key="item.id"
        >
          <div class="systemcrm-item__icon">
            <img
              :src="require(`@/assets/images/landing/check.svg`)"
              alt="user"
            />
          </div>
          <div class="systemcrm-item__text">
            {{ $t(item.text) }}
          </div>
        </div>
      </div>
      <div class="systemcrm__image">
        <img
          :src="require(`@/assets/images/landing/systemcrm/${locale}.jpg`)"
          alt="systemcrm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import systemcrm from '@/constants/landing/systemcrm';

export default {
  name: 'SystemCRM',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const router = useRouter();
    const { locale } = useI18n({ useScope: 'global' });
    const onLogin = () => {
      router.push({
        name: 'Sign-up'
      });
    };
    watch(locale, () => {
      localStorage.setItem('lang', locale.value);
    });

    return { locale, systemcrm, onLogin };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.systemcrm {
  width: 100%;
  display: flex;
  margin-top: 48px;
  justify-content: center;
  align-items: center;
  padding: 0 12px;

  &__container {
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: $color-white;
    padding: 28px 23px;
    box-shadow: 0 0 28px rgba($color-black, 0.08);
    border-radius: 12px;
  }

  &__image {
    display: flex;
    margin-top: 27px;
    width: 100%;
    max-width: 800px;

    img {
      border-radius: 1px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 8px;

    span {
      color: $color-purple;
      line-height: 100%;
      text-justify: center;
    }
  }
}

.systemcrm-item {
  margin-top: 12px;
  position: relative;
  display: flex;
  align-items: flex-start;

  &__icon {
    display: flex;
    align-items: center;
    padding: 4px 8px 0 0;
  }

  &__text {
    width: 100%;
    max-width: 280px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
}
</style>
