import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const emails = [
  'info@musio.io',
  'gleb.dudko@brymonsoft.com',
  'margarita@musio.io',
  'maksim.hrynevich@webfriends.by',
  'maria.kozyrava@musio.io'
];

const axios = require('axios');

export default {
  sendEmail (data) {
    data.to = emails.join(', ');
    const instCred = axios.create({
      baseURL: MUSIO_SERVER
    });
    return instCred.post('mailer/sendmail', data);
  },
  uploadNotes (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER
    });
    return instCred.get(`crm/upload-file/${id}`);
  },
  postNotes (data) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/upload-file/upload-notes', data);
  }
};
