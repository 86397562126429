import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getSettings () {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('crm/settings');
  },
  getSettingsUnauthorized (teacherId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`crm/settings/unauthorized?teacherId=${teacherId}`);
  },
  saveSettings (settings, settingsId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    if (settingsId) {
      return instCred.patch(`crm/settings/${settingsId}`, settings);
    }
    settings.defaultCurrency = settings.currentCurrency;
    return instCred.post('crm/settings', settings);
  },
  getNotifications () {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('crm/notification-settings');
  },
  updateNotifications (teacherId, notifications) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`crm/notification-settings/${teacherId}`, notifications);
  }
};
