const strengths = [
  {
    id: 1,
    image: 'strengths-image1',
    name: 'landing.strengths.firstItem.name'
  },
  {
    id: 2,
    image: 'strengths-image2',
    name: 'landing.strengths.secondItem.name'
  },
  {
    id: 3,
    image: 'strengths-image3',
    name: 'landing.strengths.thirdItem.name'
  },
  {
    id: 4,
    image: 'strengths-image4',
    name: 'landing.strengths.fourthItem.name'
  },
  {
    id: 5,
    image: 'strengths-image5',
    name: 'landing.strengths.fifthItem.name'
  },
  {
    id: 6,
    image: 'strengths-image6',
    name: 'landing.strengths.sixItem.name'
  }
];
export default strengths;
