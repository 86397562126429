import * as io from 'socket.io-client';
import * as RTCMultiConnection from 'rtcmulticonnection';
import { SOCKET_SERVER_TOOL } from '@/constants/domains';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';
import { customEventName } from '@/constants/_rtc/utils/connectionCustomEvents';

/** RTCMultiConnection docs - https://www.rtcmulticonnection.org/docs/ **/

const isMobile = checkMobileByScreen();

export const sampleRate = isMobile ? 44100 : 44100;
const defaultBitrateAudio = isMobile ? 256 : 256;
const defaultBitrateVideo = isMobile ? 1000 : 1500;

export const videoConstraints = {
  width: { min: 320, max: 1280 },
  height: { min: 240, max: 720 },
  frameRate: { min: 5, max: isMobile ? 25 : 30 }
};

export const audioConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: false,
  mozNoiseSuppression: true,
  mozAutoGainControl: false,
  disableLocalEcho: true
};

export default ({
  bitrateAudio = defaultBitrateAudio,
  bitrateVideo = defaultBitrateVideo,
  // maxplaybackrate = sampleRate,
  videoCodec = 'H264',
  audioCodec = 'opus',
  replacer = {},
  resolution = videoConstraints
}) => {
  window.io = io;
  const connection = new RTCMultiConnection();
  const BandwidthHandler = connection.BandwidthHandler;

  connection.dontCaptureUserMedia = true;
  connection.codecs.video = videoCodec;
  connection.codecs.audio = audioCodec;

  connection.optionalArgument = null;
  connection.enableLogs = false;
  connection.autoCreateMediaElement = false;
  // connection.maxParticipantsAllowed = 2;
  connection.direction = 'one-to-one';

  connection.socketURL = SOCKET_SERVER_TOOL;
  connection.socketMessageEvent = customEventName;

  connection.session = {
    audio: true,
    video: true,
    data: true
  };

  connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true
  };

  connection.mediaConstraints = {
    video: resolution,
    audio: true
  };

  connection.bandwidth = {
    audio: +bitrateAudio,
    video: +bitrateVideo,
    screen: +bitrateVideo
  };

  const CodecsHandler = connection.CodecsHandler;
  connection.processSdp = (sdp) => {
    sdp = CodecsHandler.setApplicationSpecificBandwidth(sdp, {
      audio: +bitrateAudio,
      video: +bitrateVideo,
      screen: +bitrateVideo
    });

    sdp = CodecsHandler.setVideoBitrates(sdp, {
      min: 500,
      max: 3000
    });
    sdp = CodecsHandler.disableNACK(sdp);

    sdp = BandwidthHandler.setApplicationSpecificBandwidth(sdp, {
      video: bitrateVideo,
      audio: bitrateAudio
    }, false);
    sdp = BandwidthHandler.setVideoBitrates(sdp, {
      min: 500,
      max: 3000
    });

    sdp = CodecsHandler.setOpusAttributes(sdp, {
      maxaveragebitrate: isMobile ? 256000 : 256000,
      // maxplaybackrate: maxplaybackrate,
      //TODO: отключить стерео в бесплатной версии
      stereo: 1,
      'sprop-stereo': 1,
      maxptime: isMobile ? 80 : 100,
      ptime: isMobile ? 40 : 60,
      minptime: isMobile ? 10 : 40
    });

    sdp = BandwidthHandler.setOpusAttributes(sdp);

    if (replacer.target) {
      const regexp = new RegExp(replacer.target, 'g');
      sdp = sdp.replace(regexp, replacer.expect);
    }

    return sdp;
  };

  connection.iceServers = [
    {
      urls: ['stun:165.227.136.67:3478']
    },
    {
      urls: 'turn:165.227.136.67:3478',
      credential: 'HB2247334v',
      username: 'yasinsky2015@gmail.com'
    }
  ];
  // Check with Google:
  // connection.iceServers.push({
  //   urls: 'stun:stun4.l.google.com:19302'
  // });
  // // last step, set TURN url (recommended)
  // connection.iceServers.push(
  //   {
  //     url: 'turn:numb.viagenie.ca',
  //     credential: 'muazkh',
  //     username: 'webrtc@live.com'
  //   });

  return connection;
};
