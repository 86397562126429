<template>
  <div
    class="lp-switch-mode"
    :class="{
      'lp-switch-mode_student': !isTeacher,
      'lp-switch-mode_mobile': isMobile,
      'lp-switch-mode_mobile_teacher': isMobile
    }"
  >
    <div
      class="lp-switch-mode-button"
      :class="{
        'lp-switch-mode-button_mobile': isMobile,
        'lp-switch-mode-button_active': singingStudent,
        'lp-switch-mode-button_mobile_active': singingStudent && isMobile,
        'lp-switch-mode-button_compact': compact
      }"
      @click="setStudentSing"
    >
      <studentIcon />
      <span
        class="lp-switch-mode-button__text"
        v-if="!compact"
      >
        {{ $t('lessonCall.studentSings') }}
      </span>
    </div>
    <div
      class="lp-switch-mode-button"
      :class="{
        'lp-switch-mode-button_mobile': isMobile,
        'lp-switch-mode-button_active': !singingStudent,
        'lp-switch-mode-button_mobile_active': !singingStudent && isMobile,
        'lp-switch-mode-button_compact': compact
      }"
      @click="setTeacherSing"
    >
      <teacherIcon />
      <span
        class="lp-switch-mode-button__text"
        v-if="!compact"
      >
        {{ $t('lessonCall.teacherSings') }}
      </span>
    </div>
    <div
      class="lp-switch-mode-background"
      :class="{
        'lp-switch-mode-background_mobile': isMobile,
        'lp-switch-mode-background_student': singingStudent,
        'lp-switch-mode-background_student_mobile': singingStudent && isMobile
      }"
    />
  </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { studentIcon, teacherIcon } from '@/constants/icons';

export default {
  name: 'SwitchAudioMode',
  components: {
    studentIcon,
    teacherIcon
  },
  props: {
    isTeacher: Boolean,
    compact: Boolean
  },
  setup (props) {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    const singingStudent = computed(() => store.getters.singingStudent);
    const setSingingStudent = (data) => store.dispatch('setSingingStudent', data);
    const audioEngine = inject('audioEngine');
    const rtcDataConnection = inject('rtcDataConnection');

    const setStudentSing = () => {
      if (singingStudent.value || !props.isTeacher) return;
      setSingingStudent(true);
      audioEngine.mp3Player.mute();
      audioEngine.mp3Player.muteStream();
      rtcDataConnection.participant.mp3Player.unmute();
      yandexTracker.callStudentOn();
    };

    const setTeacherSing = () => {
      if (!singingStudent.value || !props.isTeacher) return;
      setSingingStudent(false);
      audioEngine.mp3Player.unmute();
      audioEngine.mp3Player.unmuteStream();
      rtcDataConnection.participant.mp3Player.mute();
      yandexTracker.callStudentOff();
    };

    return {
      singingStudent,
      setStudentSing,
      setTeacherSing,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-switch-mode {
  position: relative;
  display: grid;
  gap: 4px;
  justify-content: center;
  background: $color-outer-space;
  border-radius: 8px;
  padding: 4px;
  user-select: none;

  &_mobile {
    grid-template-columns: 1fr 1fr;

    &_teacher {

    }
  }

  &_student {
    background-color: transparent;
  }

  &-background {
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc((100% - 12px) / 2);
    background-color: $color-white;
    transition: background-color 300ms ease-in, bottom 300ms ease-in-out, left 300ms ease-in-out;
    z-index: 1;
    border-radius: 8px;

    &_mobile {
      bottom: 4px;
      left: calc(8px + ((100% - 12px) / 2));
      height: calc(100% - 8px);
      width: calc((100% - 12px) / 2);
    }

    &_student {
      bottom: calc(8px + ((100% - 12px) / 2));

      &_mobile {
        left: 4px;
        bottom: 4px;
      }
    }
  }

  &-button {
    position: relative;
    display: grid;
    grid-template-columns: 16px auto;
    gap: 6px;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;
    padding: 15px;
    transition: background-color 200ms ease, box-shadow 200ms ease;
    z-index: 2;
    color: $color-white;
    white-space: nowrap;

    &_active {
      color: $color-emerald;
    }

    svg {
      width: 100%;
      height: auto;
    }

    &_mobile {
      grid-template-columns: 16px auto;
      padding: 13px 6px;
      color: $color-white;

      &_active {
        color: $color-emerald;
      }
    }

    &_compact {
      grid-template-columns: 16px;
      gap: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      min-height: 36px;
    }

    &__text {
      font-weight: 700;
      font-size: 11px;
      line-height: 13px;
      color: $color-white;
      text-transform: uppercase;
      transition: color 200ms ease, font-weight 200ms ease;;
    }

    &:hover {
      background-color: rgba($color-white, 0.05);
    }

    &_active &__text {
      color: $color-outer-space;
    }

    &_active &__circle {
      background-color: $color-emerald;
    }
  }

  &_student &-background {
    background-color: transparent;
    border: 1px solid $color-emerald;
  }

  &_student &-button {
    border: 1px solid transparent;
    cursor: default;
    pointer-events: none;
    padding: 11px 14px;

    &_active {
      .lp-switch-mode-button__text {
        color: $color-emerald;
      }
    }
  }
}

</style>
