<template>
  <Modal
    :title="changePassword ? 'profile.changedPassword.title' : 'profile.changedPassword.titleChange'"
    :saveBtnText="changePassword ? 'buttons.continue' : ''"
    :removeBtnText="'buttons.cancel'"
    :hideButtons="changePassword ? ['remove'] : []"
    @save="saveForm"
    @remove="openModal = false"
    v-model:open="openModal"
    :mobileVersion="isMobile && !changePassword"
    :editMode="true"
  >
    <div
      class="lp-profile-changed-password"
      :class="{'lp-profile-changed-password_mobile': isMobile}"
      v-if="changePassword"
    >
      <img
        class="lp-profile-changed-password__image"
        alt="success"
        src="@/assets/images/changePassword.png"
      />
      <h3 class="lp-profile-changed-password__title">
        {{ $t('profile.changedPassword.congratulation') }}
      </h3>
      <p class="lp-profile-changed-password__text">
        {{ $t('profile.changedPassword.description') }}
      </p>
    </div>
    <Form
      class="lp-profile-form-password"
      :class="{'lp-profile-form-password_mobile': isMobile}"
      @submit="saveForm"
      :inputs="profilePasswordInputs"
      v-else
    />
  </Modal>
</template>

<script>
import { ref, computed, watch } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import profilePasswordInputs from '@/constants/inputs/profilePasswordInputs';
import { useStore } from 'vuex';
import validatePassword from '@/constants/utils/validatePassword';
import AuthApi from '@/api/Auth/api';
import setErrorText from '@/constants/utils/setErrorText';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ProfileFormPassword',
  components: {
    Modal,
    Form
  },
  props: {
    user: Object,
    open: Boolean
  },
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.user);

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const changePassword = ref(false);

    const openModal = ref(false);
    watch(openModal, () => {
      changePassword.value = false;
      emit('update:open', openModal.value);
    });

    const closeModal = () => {
      openModal.value = false;
      changePassword.value = false;
    };

    const setDefaultValues = () => {
      profilePasswordInputs.forEach((input) => {
        input.modelValue = '';
      });
    };

    watch(props, () => {
      if (props.open) setDefaultValues();
      openModal.value = props.open;
    });

    const saveForm = () => {
      if (changePassword.value) {
        closeModal();
        return;
      }
      const passwords = validatePassword(profilePasswordInputs);
      if (!passwords) return;
      passwords.email = activeUser.value.email;
      passwords.login = activeUser.value.login;
      setLoaderRun(true);
      AuthApi.resetPassword(passwords).
        then(() => {
          setLoaderRun(false);
          changePassword.value = true;
        }).
        catch((err) => {
          console.error(err);
          setLoaderRun(false);
          setErrorText({
            err,
            target: profilePasswordInputs,
            index: 0
          });
        });
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      profilePasswordInputs,
      openModal,
      changePassword,
      saveForm,
      closeModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';
@import '~styles/_mixins.scss';

.lp-profile-changed-password,
.lp-profile-form-password {
  @include global-font;
  display: grid;
  padding: 32px 32px 0;
  width: 708px;

  &_mobile {
    width: 100%;
    padding: 28px 12px 0;
  }
}

.lp-profile-changed-password {
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  color: $color-text;

  &_mobile {
    width: calc(100vw - 24px);
  }

  &__image {
    width: 246px;
    height: auto;
    object-fit: contain;
    margin-top: 36px;
    margin-bottom: 24px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    margin-bottom: 4px;
  }

  &__text {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    margin-bottom: 16px;
  }
}

</style>
