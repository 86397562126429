<template>
  <teleport to="#app">
    <transition name="fade">
      <TimetableWeekPlaceholder
        v-if="openTimetable && isMobile && openPlaceholder"
        v-model:open="openPlaceholder"
      />
      <div
        class="lp-timetable-week"
        :class="{'lp-timetable-week_mobile': isMobile}"
        v-else-if="openTimetable"
      >
        <div
          class="lp-timetable-week-content"
          :class="{'lp-timetable-week-content_mobile': isMobile}"
        >
          <div
            class="lp-timetable-week-header"
            id="timetableHeader"
            ref="headerNode"
          >
            <div
              class="lp-timetable-week-header-title-wrapper"
              :class="{'lp-timetable-week-header-title-wrapper_mobile': isMobile}"
            >
              <div
                class="lp-timetable-week-header__close"
                v-if="isMobile"
                @click="openPlaceholder = true"
              >
                <closeModalIcon />
              </div>
              <h1
                class="lp-timetable-week-header__title"
                :class="{'lp-timetable-week-header__title_mobile': isMobile}"
              >
                {{ $t('timetable.template.title') }}
              </h1>
              <transition name="bounce">
                <checkIcon
                  class="lp-timetable-week-header__check"
                  v-if="template.length"
                  @click="saveTemplate"
                />
              </transition>
            </div>
            <h2
              class="lp-timetable-week-header__subtitle"
              :class="{'lp-timetable-week-header__subtitle_mobile': isMobile}"
            >
              {{ $t('timetable.template.subtitle') }}
            </h2>
            <p
              class="lp-timetable-week-header__text"
              v-if="!isMobile"
            >
              {{ $t(`timetable.template.text1`) }}
              {{ $t('timetable.template.text2') }}
              {{ $t(`timetable.template.text3`) }}
            </p>
            <div
              class="lp-timetable-week-header-help"
              v-else
            >
              <div
                class="lp-timetable-week-header-help__switch"
                :class="{'lp-timetable-week-header-help__switch_open': !hideHelp}"
                @click="switchDisplayHelp"
              >
                <arrowCircleIcon />
                <span>{{ $t('timetable.template.help') }}</span>
              </div>
              <div
                class="lp-timetable-week-header-help__text"
                :class="{'lp-timetable-week-header-help__text_open': !hideHelp}"
              >
                <p class="lp-timetable-week-header__text">
                  {{ $t(`timetable.template.text1Mobile`) }}
                  {{ $t('timetable.template.text2') }}
                  {{ $t(`timetable.template.text3Mobile`) }}
                </p>
                <p class="lp-timetable-week-header__text">
                  {{ $t('timetable.template.text4') }}
                </p>
              </div>
            </div>
            <TimetableWeekFooter
              class="lp-timetable-week-header__legends"
              :class="{'lp-timetable-week-header__legends_mobile': isMobile}"
              @save="saveTemplate"
              :short="hideHelp"
              :template="template"
            />
          </div>
          <div
            class="lp-timetable-week-body"
            :class="{'lp-timetable-week-body_mobile': isMobile}"
            :style="isMobile ? {height: bodyHeight} : {}"
          >
            <div
              class="lp-timetable-week-body-layout lp-timetable-week-body-columns"
              :class="{'lp-timetable-week-body-layout_mobile lp-timetable-week-body-columns_mobile': isMobile}"
            >
              <div
                class="lp-timetable-week-body-columns__item"
                v-for="day of weekDays"
                :key="day.date"
              >
                {{ day.title }}
              </div>
            </div>

            <div
              class="lp-timetable-week-body-layout lp-timetable-week-body-layout_times"
              :class="{'lp-timetable-week-body-layout_mobile lp-timetable-week-body-layout_times_mobile': isMobile}"
            >
              <div
                class="lp-timetable-week-body-times"
                v-for="day of weekDays"
                :key="day.date"
              >
                <div
                  class="lp-timetable-week-body-times__item"
                  :class="{'lp-timetable-week-body-times__item_selected': template.includes(item.fullDate)}"
                  :id="item.fullDate"
                  :data-time="true"
                  v-for="item of day.times"
                  :key="item.fullDate"
                  @mousedown="setWorkTimes(item.fullDate)"
                >
                  {{ item.time }}
                </div>
              </div>
            </div>
          </div>
          <TimetableWeekFooter
            class="lp-timetable-week__footer"
            :class="{'lp-timetable-week__footer_mobile': isMobile}"
            @save="saveTemplate"
            :short="hideHelp"
            :template="template"
          />
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import { fill } from 'lodash';
import moment from 'moment';
import { useStore } from 'vuex';
import TimetableApi from '@/api/CRM/timetable';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { useRouter } from 'vue-router';
import { checkIcon, arrowCircleIcon } from '@/constants/icons/crm';
import { closeModalIcon } from '@/constants/icons';
import MobileDetect from 'mobile-detect';
import timeout from '@/constants/utils/timeout';
import TimetableWeekFooter from '@/components/CRM/Lessons/Timetable/TimetableWeek/TimetableWeekFooter';
import TimetableWeekPlaceholder from '@/components/CRM/Lessons/Timetable/TimetableWeek/TimetableWeekPlaceholder';
import { ResizeObserver } from 'resize-observer';

export default {
  name: 'TimetableWeek',
  components: {
    TimetableWeekPlaceholder,
    TimetableWeekFooter,
    checkIcon,
    closeModalIcon,
    arrowCircleIcon
  },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const router = useRouter();
    const currentRoute = computed(() => router.currentRoute.value);
    const isLessons = computed(() => currentRoute.value.name === 'CRMLessons');

    const openTimetable = ref(false);
    watch(openTimetable, (value) => {
      hideContainerForMobile(value);
      if (value) {
        mount();
      } else {
        unmount();
      }
    });

    const isoDate = 'YYYY-MM-DD';

    const setTimes = (el, i) => moment().set('minute', 0).set('hour', 6).add(i, 'hour').format('HH:mm');
    const times = computed(() => fill(new Array(18), '').map(setTimes));

    const setWeeks = (el, i) => {
      const momentDate = moment().startOf('week').add(i, 'day');
      const title = momentDate.format('ddd');
      const date = momentDate.format(isoDate);
      const time = times.value.map(item => ({
        time: item,
        fullDate: moment(`${date}T${item}`, 'YYYY-MM-DDTHH:mm' ).format()
      }));
      return { title, date, times: time };
    };
    const weekDays = computed(() => fill(new Array(7), '').map(setWeeks));

    const template = ref([]);
    const startSelectTimes = ref(false);
    const onMouseup = () => startSelectTimes.value = false;
    const onMouseMove = ({ target }) => {
      if (!startSelectTimes.value) return;
      if (!target.dataset.time && !target.id) return;
      setWorkTimes(target.id);
    };

    const setWorkTimes = (date) => {
      startSelectTimes.value = true;
      const index = template.value.indexOf(date);
      if (index !== -1) {
        template.value.splice(index, 1);
      } else {
        template.value.push(date);
      }
    };
    const mouseMoveOnTime = (date) => {
      if (!startSelectTimes.value) return;
      setWorkTimes(date);
    };

    const setTemplate = (data) => store.dispatch('setTemplate', data);
    // const getTemplate = async () => {
    //   if (!user.value._id || !isLessons.value) {
    //     openTimetable.value = false;
    //     return;
    //   }
    //   try {
    //     const { data } = await TimetableApi.getTemplate(user.value._id);
    //     await setTemplate(data);
    //     openTimetable.value = !data.length;
    //   } catch (e) {
    //     return e;
    //   }
    // };

    const resetTimetable = (e) => {
      if (e.shiftKey && e.code === 'KeyR') template.value = [];
    };

    // watch([user, currentRoute], getTemplate);

    const getBody = () => {
      return template.value.map(date => ({
        teacherId: user.value._id,
        ruleStartDate: moment(date).format(),
        ruleEndDate: moment(date).add(1, 'hour').format()
      }));
    };

    const saveTemplate = async () => {
      await setLoaderRun(true);
      try {
        const { data } = await TimetableApi.createTemplate(getBody());
        yandexTracker.timetable();
        await setTemplate(data);
        openTimetable.value = false;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
      }
    };

    const mount = () => {
      getTemplate();
      document.addEventListener('keyup', resetTimetable, false);
      document.addEventListener('mouseup', onMouseup, false);
      document.addEventListener('mouseover', onMouseMove, false);
    };

    const unmount = () => {
      hideContainerForMobile(false);
      document.removeEventListener('keyup', resetTimetable, false);
      document.removeEventListener('mouseup', onMouseup, false);
      document.removeEventListener('mouseover', onMouseMove, false);
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    const hideContainerForMobile = async (val) => {
      if (!isMobile.value) return;
      const el = document.querySelector('.mc-container');
      if (!el || !isMobile.value) return;
      await timeout(500);
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
      el.style.height = val ? '0' : '100%';
    };

    const headerNode = ref(null);
    const bodyHeight = ref('auto');
    const setBodyHeight = () => {
      const vh = window.innerHeight * 0.01;
      const timetableHeader = headerNode.value;
      if (!timetableHeader) return;
      const fullHeight = vh * 100;
      bodyHeight.value = `${fullHeight - timetableHeader.offsetHeight - 30}px`;
    };
    watch(headerNode, (node) => {
      if (!node) return;
      observeHeight(node);
      setBodyHeight();
    });

    const openPlaceholder = ref(true);
    watch(openPlaceholder, () => {
      setBodyHeight();
    });

    onBeforeUnmount(unmount);

    const hideHelp = ref(isMobile.value);
    const switchDisplayHelp = () => {
      hideHelp.value = !hideHelp.value;
    };

    const observeHeight = (node) => {
      const resizeObserver = new ResizeObserver(setBodyHeight);
      resizeObserver.observe(node);
    };

    return {
      switchDisplayHelp,
      hideHelp,
      openPlaceholder,
      headerNode,
      bodyHeight,
      isMobile,
      openTimetable,
      weekDays,
      times,
      template,
      setWorkTimes,
      mouseMoveOnTime,
      saveTemplate
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-week {
  @include global-font;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color-black, 0.5);
  z-index: 100;
  user-select: none;

  &_mobile {
    position: absolute;
    overflow: visible;
    display: grid;
    align-items: start;
    justify-content: start;
  }

  &-content {
    display: grid;
    grid-template-rows: auto auto auto;
    background: $color-white;
    border-radius: 4px;
    padding: 0 20px;
    max-height: 90vh;
    max-width: 680px;
    overflow: auto;

    &_mobile {
      max-height: none;
      overflow: visible;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      padding-bottom: 30px;
    }
  }

  &-header {
    position: sticky;
    top: 0;
    padding: 20px 0 20px;
    background-color: $color-white;
    z-index: 3;
    min-height: 180px;

    &-title-wrapper {
      display: grid;
      grid-template-columns: 1fr 20px;
      align-items: center;
      padding: 0 20px 18px;
      margin: 0 -20px;
      border-bottom: 1px solid $color-alto;
      margin-bottom: 14px;

      &_mobile {
        grid-template-columns: 20px 1fr 20px;
      }
    }

    &__check {
      margin-left: auto;
      margin-right: 0;
    }

    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.07em;
      color: $color-sine-shaft;

      &_mobile {
        text-align: center;
      }
    }

    &__subtitle {
      margin-bottom: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;

      &_mobile {
        margin-bottom: 16px;
      }
    }

    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;
    }

    &__legends {
      display: none;

      &_mobile {
        display: grid;
      }
    }

    &-help {
     &__switch {
       display: grid;
       align-items: center;
       grid-template-columns: 20px auto;
       grid-gap: 8px;
       font-weight: bold;
       font-size: 14px;
       line-height: 125%;

       svg {
         transform: rotate(-90deg);
         transition: transform 0.3s ease-in-out;
       }

       &_open {
         svg {
           transform: rotate(0);
         }
       }
     }

      &__text {
        max-height: 0;
        padding-top: 0;
        overflow: hidden;
        transition: max-height 0.2s linear, padding-top 0.3s linear;

        &_open {
          padding-top: 13px;
          max-height: 450px;
          transition: max-height 0.4s linear , padding-top 0.2s linear;
        }
      }
    }
  }

  &-body {
    z-index: 1;
    padding: 0 0 10px;
    user-select: none;

    &_mobile {
      max-height: none;
      height: auto;
      padding-bottom: 0;
      overflow: auto;
      max-width: 100vw;
      overflow-scrolling: touch;
      -webkit-overflow-scrolling: touch;
      overflow: auto;
    }

    &-layout {
      display: grid;
      grid-template-columns: repeat(7, minmax(84px, 1fr));
      grid-gap: 7px;
      align-items: center;
      justify-content: center;

      &_mobile {
        grid-template-columns: repeat(7, minmax(31.9%, 1fr));
        align-items: start;
        justify-content: start;
      }

      &_times {
         &_mobile {
          transition: none;
        }
      }
    }

    &-columns {
      position: sticky;
      top: 182px;
      padding: 6px 0;
      margin-bottom: 6px;

      &_mobile {
        top: 0;

        &:after {
          width: calc((31.9% * 7) + 42px);
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-moon-raker;
        border: 1px solid $color-alto;
        box-sizing: border-box;
        border-radius: 4px;
        z-index: 1;
      }

      &__item {
        position: relative;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-accent;
        z-index: 2;
      }
    }

    &-times {
      display: grid;
      grid-gap: 2px;

      &__item {
        padding: 6px 0;
        box-sizing: border-box;
        border-radius: 4px;
        text-align: center;
        font-size: 12px;
        line-height: 125%;
        background-color: $color-white;
        border: 1px solid $color-alto;
        color: $color-gray;
        font-weight: 500;
        cursor: pointer;
        transition: border-color 0.2s ease-in, color 0.2s ease-in;

        &:hover {
          border-color: $color-emerald;
          color: $color-emerald;
        }

        &_selected {
          background-color: $color-emerald;
          border-color: $color-emerald;
          color: $color-white;
          font-weight: bold;

          &:hover {
            color: $color-white
          }
        }
      }
    }
  }

    &__footer {
      &_mobile {
        display: none;
      }
    }
  }

</style>
