<template>
  <div class="lp-sign-up">
    <div class="lp-sign-up__images">
      <img
        class="lp-sign-up__image"
        :src="require(`@/assets/images/login-image.jpg`)"
        alt="login-image"
      />
      <router-link :to="{name: 'Landing'}">
        <loginLogoIcon />
      </router-link>
    </div>
    <div class="lp-sign-up__container">
      <h2 class="lp-sign-up__title">
        {{ $t('signUp.title') }}
      </h2>
      <div class="lp-sign-up-form">
        <CustomInput
          v-for="input of registrationInputs"
          :key="input.label"
          v-bind="input"
          @submit="register()"
          @focus="input.error = false"
          v-model="input.model"
        />
      </div>
      <button
        class="lp-button lp-sign-up__enter"
        @click="register()"
      >
        {{ $t('signUp.title') }}
      </button>

      <p class="lp-sign-up-footer">
        {{ $t('signUp.questionRegistration') }}
        <router-link
          class="lp-sign-up-footer__link lp-link"
          :to="toLogin()"
        >
          {{ $t('login.button') }}
        </router-link>
      </p>
    </div>
    <RegistrationInfo
      :open="registered"
      @close="closeAfterRegistration"
    />
  </div>
</template>

<script>
import registrationInputs from '@/constants/inputs/registrationInputs';
import validateRegistration from '@/constants/validatesForm/validateRegistration';
import CustomInput from '@/components/LoginPage/CustomInput';
import generator from 'generate-password';
import AuthApi from '@/api/Auth/api';
import { useStore } from 'vuex';
import setErrorText from '@/constants/utils/setErrorText';
import checkCountries from '@/constants/countries/checkCountries';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import RegistrationInfo from '@/components/LoginPage/RegistrationInfo';
import { loginLogoIcon } from '@/constants/icons/landing';
import { set } from 'lodash';

export default {
  name: 'LoginPage',
  components: { RegistrationInfo, CustomInput, loginLogoIcon },
  setup () {
    const store = useStore();
    const currentCountryCode = computed(() => store.getters.countryCode);
    const currentLanguage = checkCountries(currentCountryCode.value)
      ? 'ru'
      : 'en';

    const setActiveUser = (data) => store.dispatch('setUser', data);
    const setLoaderRun = (data) => {
      store.dispatch('setLoaderRun', data);
    };

    const router = useRouter();
    const toLogin = () => ({
      name: 'Login'
    });

    const registered = ref(false);
    const closeAfterRegistration = () => {
      router.push({ name: 'Main' });
    };

    const register = async () => {
      const validateBody = validateRegistration(registrationInputs);
      if (!validateBody) return;
      const pass = generator.generate({ length: 8, numbers: true });
      set(validateBody, 'password', pass);
      set(validateBody, 'role', 'Teacher');
      set(validateBody, 'login', validateBody.email);
      try {
        await setLoaderRun(true);
        const minimal = await AuthApi.registrationUser(
          validateBody,
          currentLanguage
        );
        yandexTracker.register();
        VueCookieNext.setCookie('token', minimal.data.token.accessToken, {
          expire: '1m'
        });
        const { data } = await AuthApi.getUser();
        await setActiveUser(data);
        registered.value = true;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        setErrorText({
          err,
          target: registrationInputs,
          index: 0,
          all: true
        });
      }
    };

    return {
      register,
      toLogin,
      registrationInputs,
      registered,
      closeAfterRegistration
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-sign-up {
  @include global-font;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: $color-white;

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__container {
    display: flex;
    width: 100%;
    max-width: 528px;
    padding: 60px;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    background: $color-white;

    @media (max-width: 767px) {
      max-width: 400px;
      margin: 0;
      padding: 40px 24px 0 24px;
    }
  }

  &__images {
    display: flex;
    height: 100%;

    a {
      position: absolute;
      margin: 20px;
      color: $color-white;
    }

    @media (max-width: 767px) {
      height: auto;
      padding-top: 55px;

      a {
        position: relative;
        margin: 0px;
        color: $color-purple;
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 704px;
    height: inherit;
    border-radius: 8px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    color: $color-black;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__enter {
    margin-top: 62px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 125%;

    @media (max-width: 767px) {
      margin-top: 32px;
    }
  }
}

.lp-sign-up-form {
  margin-top: 60px;
  display: grid;
  grid-gap: 16px;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
}

.lp-sign-up-footer {
  margin-top: 40px;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: $color-black;

  &__link {
    color: $color-azure-radiance;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>

<style lang="scss">
.app-zoom {
  .lp-sign-up {
    height: 125vh;
  }
}
</style>

<style lang="scss">
.app-zoom {
  .lp-sign-up {
    height: 125vh;
  }
}
</style>
