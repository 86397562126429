const TIME_TABLE_MODAL_REMINDER = {
  NO_REPEAT: {
    id: 'NO_REPEAT',
    title: 'timetable.modal.select.noRepeat'
  },
  EVERY_DAY: {
    id: 'EVERY_DAY',
    title: 'timetable.modal.select.everyDay'
  },
  EVERY_WEEK: {
    id: 'EVERY_WEEK',
    title: 'timetable.modal.select.everyWeek'
  }
};

export default TIME_TABLE_MODAL_REMINDER;