<template>
  <Modal
    v-model:open="isShowModal"
    :title="item._id ? 'crm.subscriptions.modal.editTitle' : 'crm.subscriptions.modal.createTitle'"
    :removeBtnText="'buttons.cancel'"
    :saveBtnText="item._id ? 'buttons.save' : 'buttons.add'"
    @save="saveForm"
    @remove="closeForm"
    :editMode="isMobile"
    :mobileVersion="isMobile"
  >
    <div
      class="lp-crm-subscription"
      :class="{'lp-crm-subscription_mobile': isMobile}"
    >
      <Form
        @submit="saveForm"
        :inputs="subscriptionInputs"
      />
      <transition name="bounce">
        <div
          class="lp-crm-subscription__error lp-error"
          v-if="error"
        >
          <dangerIcon />
          {{ $t(error) }}
        </div>
      </transition>
    </div>
  </Modal>
  <transition name="fade">
    <teleport
      to="#app"
      v-if="isLoader"
    >
      <div class="lp-student-card__placeholder">
        <Loader :block="true" />
      </div>
    </teleport>
  </transition>
</template>

<script>
import { useStore } from 'vuex';
import Modal from '@/components/Main/Modal/Modal';
import Loader from '@/components/Main/Loader/Loader';
import { computed, ref, watch } from 'vue';
import inputs from '@/constants/inputs/subscriptionInputs';
import Form from '@/components/Main/Form/Form';
import { get } from 'lodash';
import validateProfile from '@/constants/validatesForm/validateProfile';
import LessonsPassTemplateApi from '@/api/CRM/lessonsPassTemplate';
import { getErrorText } from '@/constants/utils/setErrorText';
import { dangerIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Subscription',
  components: {
    Modal,
    Loader,
    Form,
    dangerIcon
  },
  props: {
    openSubscription: Boolean,
    item: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:openSubscription'],
  setup (props, { emit }) {
    const isLoader = ref(false);
    const isShowModal = ref(false);

    watch(isShowModal, (value) => {
      emit('update:openSubscription', value);
    });
    watch(() => props.openSubscription, (value) => {
      error.value = '';
      isShowModal.value = value;
      !value ? setDefaultValues() : setInputs();
    });

    const closeForm = () => {
      isShowModal.value = false;
    };

    const setInputs = async () => {
      subscriptionInputs.value.forEach((input) => {
        input.modelValue = get(props.item, input.modelName, input.defaultValue);
      });
    };

    const setDefaultValues = async () => {
      emit('update:item', {});
      subscriptionInputs.value.forEach((input) => {
        const value = input.defaultValue;
        input.modelValue = value;
      });
    };

    const error = ref('');

    const store = useStore();
    const subscriptions = computed(() => store.getters.subscriptions);
    const user = computed(() => store.getters.user);

    const settings = computed(() => store.getters.settings);
    const subscriptionInputs = computed(() => {
      const { currentCurrency, defaultCurrency } = settings.value;
      const currency = inputs.find(({ modelName }) => modelName === 'currency');
      if (currency) {
        currency.modelValue = currentCurrency || defaultCurrency;
        currency.defaultValue = currentCurrency || defaultCurrency;
      }
      return inputs;
    });

    const updateSubscribe = async (body) => {
      try {
        await store.dispatch('setLoaderRun', true);
        const { data } = await LessonsPassTemplateApi.updateLessonPassTemplate(body);
        await store.dispatch('setLoaderRun', false);
        const index = subscriptions.value.findIndex(item => item._id === data._id);
        if (index !== -1) subscriptions.value.splice(index, 1, data);
        closeForm();
      } catch (err) {
        console.error(err);
        await store.dispatch('setLoaderRun', false);
        error.value = getErrorText(err);
      }
    };

    const createSubscribe = async (body) => {
      try {
        await store.dispatch('setLoaderRun', true);
        const { data } = await LessonsPassTemplateApi.createLessonPassTemplate(body);
        await store.dispatch('setLoaderRun', false);
        // yandexTracker.addLesson();
        subscriptions.value.push(data);
        closeForm();
      } catch (err) {
        console.error(err);
        store.dispatch('setLoaderRun', false);
        error.value = getErrorText(err);
      }
    };

    const saveForm = async () => {
      const subscribeId = get(props.item, '_id', '');
      const body = await validateProfile(subscriptionInputs.value);
      if (!body) return;
      body._id = subscribeId;
      body.teacherId = user.value._id;
      await store.dispatch('setLoaderRun', true);
      subscribeId ? await updateSubscribe(body) : await createSubscribe(body);
      await store.dispatch('setLoaderRun', false);
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isLoader,
      isShowModal,
      subscriptionInputs,
      saveForm,
      error,
      isMobile,
      closeForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-subscription {
  position: relative;
  padding: 28px 18px;
  min-width: 490px;

  &_mobile {
    max-width: 100vw;
    min-width: auto;
    padding: 28px 12px;
  }

  &__error {
    top: auto;
    left: 20px;
    bottom: -16px;
  }

}
</style>
<!-- Created by zas on 05.07.2021  -->
