<template>
  <div class="payment">
    <div class="payment-header">
      <div class="payment-header__logo">
        <router-link
          :to="{name: 'Landing'}"
          class="lp-header-logo"
        >
          <logoIcon />
        </router-link>
      </div>
    </div>
    <div class="payment-wrapper">
      <div class="payment-body">
        <form
          v-if="activePaymentModal === 'info'"
          ref="info"
          class="payment-body__order"
        >
          <h2 class="payment-body__header">
            {{ $t('paymentTrialModal.order').toUpperCase() }}
          </h2>
          <div class="payment-body__details">
            <div class="payment-body__details-wrapper">
              <div class="payment-body__details-info">
                {{ service }}
              </div>
              <div class="payment-body__details-sum">
                {{ currency }}{{ price }}
              </div>
            </div>
            <div
              v-if="discount"
              class="payment-body__details-wrapper"
            >
              <div class="payment-body__details-info discount">
                <div>{{ $t('paymentTrialModal.discount') }}</div>
                <div class="payment-body__discount">
                  {{ discountPercent }}%
                </div>
              </div>
              <div class="payment-body__details-sum">
                -{{ currency }}{{ discount }}
              </div>
            </div>
          </div>
          <div class="payment-body__details-wrapper payment-body__details-wrapper-mt">
            <div class="payment-body__details-sum">
              {{ $t('paymentTrialModal.payment') }}
            </div>
            <div class="payment-body__details-sum">
              {{ currency }}{{ paymentAmount }}
            </div>
          </div>
          <div class="payment-body__receipt">
            <div class="payment-body__receipt-title">
              {{ $t('paymentTrialModal.receipt') }}
            </div>
            <CustomRadio
              v-model:modelValue="receiptValue"
              :options="isReceiptRequired"
            ></CustomRadio>
          </div>
          <div
            v-if="receiptValue"
            class="payment-body__field"
          >
            <CustomInput
              id="emailInput"
              v-model:modelValue="email"
              :autofocus="true"
              :customBorder="true"
              :customError="emailError"
              :label="$t('paymentTrialModal.enterEmail')"
              @blur="emailErrorCheck"
              @focus="emailError = false"
            ></CustomInput>
            <div
              :class="{'error-margin': emailError}"
              class="payment-body__field-block"
            >
              <img
                :src="require('@/assets/images/info.svg')"
                alt="lock"
              >
              <p>
                {{ $t('paymentTrialModal.email') }}
              </p>
            </div>
          </div>
          <div class="payment-body__button">
            <div
              class="lp-button"
              @click="goToPayment"
            >
              {{ $t('buttons.goToPayment') }}
            </div>
          </div>
        </form>
        <div
          v-if="activePaymentModal === 'payment'"
          ref="info"
          class="payment-body__order"
        >
          <h2 class="payment-body__header">
            {{ $t('paymentTrialModal.order').toUpperCase() }}
          </h2>
          <div class="payment-body__details">
            <div class="payment-body__details-wrapper">
              <div class="payment-body__details-info">
                {{ service }}
              </div>
              <div class="payment-body__details-sum">
                {{ currency }}{{ price }}
              </div>
            </div>
            <div
              v-if="discount"
              class="payment-body__details-wrapper"
            >
              <div class="payment-body__details-info discount">
                <div>{{ $t('paymentTrialModal.discount') }}</div>
                <div class="payment-body__discount">
                  {{ discountPercent }}%
                </div>
              </div>
              <div class="payment-body__details-sum">
                -{{ currency }}{{ discount }}
              </div>
            </div>
          </div>
          <form id="payment-form-stripe">
            <div class="payment-body__details-wrapper payment-body__details-wrapper-mt">
              <div class="payment-body__details-sum">
                {{ $t('paymentTrialModal.payment').toUpperCase() }}
              </div>
              <div class="payment-body__details-sum">
                {{ currency }}{{ paymentAmount }}
              </div>
            </div>
            <div class="payment-body__details-label">
              <label
                :for="'card-element-stripe'"
                class="lp-label"
              >
                {{ $t('paymentTrialModal.bankCardDetails') }}
              </label>
            </div>
            <div class="lp-form__item">
              <div
                id="card-element-stripe"
                :class="{'lp-input_error': invalidField}"
              >
                <!--Stripe.js injects the Card Element-->
              </div>
            </div>
            <p
              id="card-error"
              class="stripe-error-message"
              role="alert"
            >
            </p>
            <div class="payment-body__field">
              <div
                class="payment-body__field-block"
              >
                <img
                  :src="require('@/assets/images/lock.svg')"
                  alt="lock"
                >
                <p>
                  {{ $t('paymentTrialModal.security') }}
                </p>
              </div>
            </div>
            <div class="payment-body__button">
              <button
                id="submit-stripe"
                class="lp-button"
              >
                <Loader />
                <span id="button-text-stripe">{{ $t('buttons.payButton') }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <PaymentModal
      v-if="paymentSuccess"
      v-model:openModal="paymentSuccess"
      :currency="currency"
      :modalContent="successContent"
      :receiptValue="receiptValue"
      :sum="paymentAmount"
      @modal-close="onModalClose"
    />
    <PaymentModal
      v-if="paymentError"
      v-model:openModal="paymentError"
      :modalContent="errorContent"
      :sum="paymentAmount"
    />
  </div>
</template>

<script>
import { logoIcon } from '@/constants/icons';
import paymentErrorImg from '@/assets/images/payment-error.png';
import paymentSuccessImg from '@/assets/images/payment-success.png';
import CustomInput from '@/components/Main/Inputs/CustomInput';
import CustomRadio from '@/components/Main/Inputs/CustomRadio';
import PaymentModal from '@/components/StripePayment/Modals/PaymentModal';
import STRIPE_PUBLIC_KEY from '@/constants/stripe/stripePublicKey';
import Stripe from '@/api/Stripe/api';
import { useI18n } from 'vue-i18n';
import { onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Loader from '@/components/Main/Loader/Loader';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';

export default {
  name: 'StripePaymentForm',
  components: {
    logoIcon,
    CustomInput,
    CustomRadio,
    PaymentModal,
    Loader
  },

  setup () {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const EMAIL_REGEXP = ref(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

    const service = ref('');
    const price = ref('');
    const paymentAmount = ref('');
    const discount = ref(null);
    const discountPercent = ref('');
    const currency = ref('$');
    const invalidField = ref(false);
    const email = ref('');
    const activePaymentModal = ref('info');
    const bankCardDetails = ref(true);
    const receiptValue = ref(false);
    const paymentSuccess = ref(false);
    const paymentError = ref(false);
    const clientSecret = ref('');
    const emailError = ref(false);
    const maxDiscount = ref(30);
    const bankCardDetailsError = ref(null);

    const isReceiptRequired = reactive([
      { id: true, value: false, name: 'receipt', label: t('global.yesCapital') },
      { id: false, value: true, name: 'receipt', label: t('global.noCapital') }
    ]);

    const successContent = reactive({
      image: paymentSuccessImg,
      text: '',
      status: true
    });

    const errorContent = reactive({
      image: paymentErrorImg,
      text: '',
      status: false
    });

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const emailErrorCheck = () => {
      const isEmail = EMAIL_REGEXP.value.test(email.value);
      if (!isEmail && (receiptValue.value || receiptValue.value === '')) {
        emailError.value = t('errors.emptyEmail');
      }
      if (email.value === '' && (receiptValue.value === '' || receiptValue.value)) {
        emailError.value = t('errors.emptyLogin');
      }
    };

    const goToPayment = () => {
      if (receiptValue.value) emailErrorCheck();
      if (emailError.value) return;
      payment();
    };

    const onModalClose = () => {
      activePaymentModal.value = 'info';
      email.value = '';
    };

    const creatPaymentIntent = async () => {
      const body = {
        paymentAmount: paymentAmount.value * 100,
        currency: 'usd',
        description: service.value
      };

      if (email.value) {
        body.receipt_email = email.value;
      }

      try {
        const { data } = await Stripe.createPaymentIntent(body);
        clientSecret.value = data.client_secret;
        activePaymentModal.value = 'payment';
      } catch (e) {
        console.error(e);
      }
    };

    const payment = async () => {
      const publicKey = process.env.VUE_APP_STRIPE_PUBLICK_KEY || STRIPE_PUBLIC_KEY;
      const stripe = window.Stripe(publicKey);

      // Disable the button until we have Stripe set up on the page
      await creatPaymentIntent();
      const elements = stripe.elements();

      const style = {
        base: {
          fontFamily: '"Uni Neue", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", sans-serif',
          width: '100%',
          padding: '10px 11px',
          borderRadius: '4px',
          border: '1px solid #E0E0E0',
          backgroundColor: '#FFFFFF',
          color: '#2C2C2C',
          fontWeight: 500,
          fontSize: '14px',
          boxSizing: 'border-box',
          transition: '0.3s ease',
          '::placeholder': {
            color: '#858585'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };

      const card = elements.create('card', { style: style });
      // Stripe injects an iframe into the DOM
      card.mount('#card-element-stripe');

      card.on('ready', function () {
        card.focus();
        document.querySelector('#submit-stripe').disabled = true;
      });

      card.on('change', function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector('#submit-stripe').disabled = event.empty || (event.error && event.error.message);
        document.querySelector('#card-error').textContent = event.error ? event.error.message : '';
        bankCardDetails.value = event.empty;
        bankCardDetailsError.value = event.error ? event.error.message : null;
      });

      card.on('focus', function () {
        document.querySelector('#card-element-stripe').classList.add('outline-focused');
      });

      card.on('blur', function () {
        document.querySelector('#card-element-stripe').classList.remove('outline-focused');
      });

      const form = document.querySelector('#payment-form-stripe');
      form.addEventListener('submit', function (event) {
        event.preventDefault();
        if (bankCardDetailsError.value || bankCardDetails.value) return;
        // Complete payment when the submit button is clicked
        payWithCard(stripe, card, clientSecret.value);
      });

      // Calls stripe.confirmCardPayment
      // If the card requires authentication Stripe shows a pop-up modal to
      // prompt the user to enter authentication details without leaving your page.
      const payWithCard = function (stripe, card, clientSecret) {
        loading(true);
        stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: card
          }
        }).then(function (result) {
          if (result.error) {
            // Show error to your customer
            showError(result.error.message);
          } else {
            // The payment succeeded!
            orderComplete(result.paymentIntent.id);
          }
        });
      };

      /* ------- UI helpers ------- */

      // Shows a success message when the payment is complete
      var orderComplete = function () {
        /** have argument - paymentIntentId */
        loading(false);
        card.clear();
        paymentSuccess.value = true;
      };

      // Show the customer the error from Stripe if their card fails to charge
      var showError = function (errorMsgText) {
        loading(false);
        paymentError.value = true;
        errorContent.text = errorMsgText;
      };

      // Show a spinner on payment submission
      var loading = function (isLoading) {
        if (isLoading) {
          // Disable the button and show a spinner
          document.querySelector('button').disabled = true;
          setLoaderRun(true);
        } else {
          document.querySelector('button').disabled = false;
          setLoaderRun(false);
        }
      };
    };

    const removeZoom = () => {
      document.getElementById('app').classList.remove('app-zoom');
    };

    const getDataFromRoute = () => {
      if (route.query.discount > maxDiscount.value) {
        router.replace({
          path: route.path,
          query:
              {
                service: route.query.service,
                amount: route.query.amount,
                discount: maxDiscount.value
              }
        });
      }
      ;

      let discountValid;
      if (route.query.discount > maxDiscount.value) {
        discountValid = maxDiscount.value;
      } else if (route.query.discount < 0) {
        discountValid = 0;
      } else discountValid = route.query.discount;

      service.value = route.query.service;
      price.value = route.query.amount ? Number(route.query.amount) : 0;
      discountPercent.value = route.query.discount ? Number(discountValid) : 0;
      discount.value = discountPercent.value ? (price.value / 100 * discountPercent.value).toFixed(2) : null;
      paymentAmount.value = Number((Number(price.value) - Number(discount.value)).toFixed(2));
    };

    watch(receiptValue, () => {
      emailError.value = false;
    });

    onBeforeMount(() => {
      const stripeScript = document.createElement('script');
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(stripeScript);
      getDataFromRoute();
      removeZoom();
      window.addEventListener('resize', removeZoom);
      document.body.classList.add('hide-chat');
    });

    onBeforeUnmount(() => {
      if (detectNeedZoom()) {
        document.getElementById('app').classList.add('app-zoom');
      }
    });

    return {
      price,
      discount,
      paymentAmount,
      discountPercent,
      email,
      emailError,
      bankCardDetails,
      invalidField,
      receiptValue,
      activePaymentModal,
      currency,
      service,
      paymentSuccess,
      paymentError,
      isReceiptRequired,
      successContent,
      errorContent,
      goToPayment,
      payment,
      onModalClose,
      setLoaderRun,
      emailErrorCheck
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-input_error {
  border: 1px solid red !important;
}

.stripe-error-message {
  margin-top: 2px !important;
  font-family: "Uni Neue", "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", sans-serif !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #FF0000 !important;
}

.payment {
  width: 100%;

  &-header {
    min-height: 80px;
    padding: 26px 32px;
    background-color: #ffffff;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    height: calc(100% - 85px);
    background-image: url('~@/assets/images/landing/payment-bg.png');
    background-repeat: no-repeat;
    background-size: auto, cover;
    background-position: center center;
  }

  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-mine-shaft-dark;

    &__order {
      width: 555px;
      padding: 40px;
      border-radius: 8px;
      background-color: #ffffff;
    }

    &__header {
      font-size: 16px;
      font-weight: 800;
    }

    &__details {
      margin-top: 24px;
      border-bottom: 1px solid $color-alto;

      &-wrapper {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;

        &-mt {
          margin-top: 20px;
        }
      }

      &-sum {
        font-size: 14px;
        font-weight: 800;
      }

      &-label {
        margin-bottom: 2px;
        margin-top: 40px;
      }
    }

    &__button {
      margin-top: 60px;
      display: flex;
      justify-content: center
    }

    &__discount {
      width: 38px;
      display: flex;
      justify-content: center;
      align-content: center;
      margin-left: 8px;
      padding: 4px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 800;
      color: $color-emerald;
      background: rgba(166, 237, 173, 0.35);
    }

    &__field {
      margin-top: 20px;
      position: relative;

      &-icon {
        position: absolute;
        top: 33px;
        left: 12px;
      }

      &-placeholder {
        position: absolute;
        top: 33px;
        right: 12px;
        font-size: 14px;
        color: $color-gray;
      }

      &-block {
        display: flex;
        align-items: flex-start;
        margin-top: 12px;
        font-size: 12px;
        line-height: 125%;

        img {
          margin-right: 10px;
        }
      }
    }

    &__receipt {
      margin-top: 20px;

      &-title {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

.discount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-margin {
  margin-top: 22px;
}

.outline-focused {
  border: 1px solid $color-purple !important;
  box-shadow: 0 0 10px 1px rgb(117 0 186 / 10%);
}

@media (max-width: 1460px) {

}

@media (max-width: 600px) {
  .payment {
    &-body {
      width: 100%;

      &__order {
        width: 375px;
      }
    }

    &-wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 499px) {
  .payment {
    &-wrapper {
      height: 100%;
      min-height: -webkit-fill-available;
      background-image: none;
    }

    &-header {
      display: none;
    }

    &-body {
      height: 100%;

      &__order {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }

      &__button {
        margin-top: 40px;
      }
    }
  }
}
</style>
