import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getFile (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`upload-file/${id}`);
  },
  getFilesByParent (parentFolder) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [];
    if (parentFolder) query.push(`parentFolder=${parentFolder}`);
    return instCred.get(`upload-file?${query.join('&')}`);
  },
  renameFile ({ id, name }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post(`upload-file/rename/${id}?newFileName=${name}`);
  },
  updateParentFolder (file) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('upload-file/updateparent/', file);
  },
  uploadFile (file, options = {}) {
    const { parentFolder, originalName } = options;
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [];
    if (parentFolder) query.push(`parentFolder=${parentFolder}`);
    if (originalName) query.push(`originalName=${originalName}`);
    return instCred.post(`upload-file?${query.join('&')}`, file);
  },
  removeFile (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`upload-file/${id}`);
  }
};
