import SOCKET_EVENTS from '@/constants/enums/events';
import { customEventName } from '@/constants/_rtc/utils/connectionCustomEvents';
import EventsApi from '@/api/VideoCall/api';
import { VueCookieNext } from 'vue-cookie-next';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

const setParticipants = ({ store, count }) => {
  const connection = store.getters.rtcConnect;
  const getAllParticipants = connection?.getAllParticipants;
  const length = getAllParticipants ? getAllParticipants().length : 0;
  store.dispatch('setParticipants', count ?? length);
};

const sendVideoSettings = (store) => {
  const { mirror, muteLocal } = store.getters.videoSettings;
  emitRtcEvent({ event: SOCKET_EVENTS.VIDEO_MIRROR, value: mirror, store });
  emitRtcEvent({ event: SOCKET_EVENTS.VIDEO_MUTE, value: muteLocal, store });
};

const emitRtcEvent = ({ store, event, value, type }) => {
  const connection = store.getters.rtcConnect;
  if (!connection?.socket?.emit) return;
  const socketId = connection.socketMessageEvent;
  connection.socket.emit(customEventName, { socketId, event, value, type });
};

const setVideoSettings = ({ store }) => {
  const videoSettings = store.getters.videoSettings;
  const settingsFromStorage = JSON.parse(localStorage.getItem('video_settings')) || {};
  Object.assign(videoSettings, settingsFromStorage);
};

const getDeviceError = ({ microphone, webcam }) => {
  if (microphone && webcam) {
    return '';
  } else if (!microphone && webcam) {
    yandexTracker.deviceNoMicro();
    return 'lessonCall.noConnectMic';
  } else if (!webcam && microphone) {
    yandexTracker.deviceNoCam();
    return 'lessonCall.noConnectCam';
  } else {
    yandexTracker.deviceNoMicro();
    yandexTracker.deviceNoCam();
    return 'lessonCall.noConnectMicCam';
  }
};

const stopAllTracks = (store) => {
  const connection = store.getters.rtcConnect;
  const streams = store.getters.streams;

  if (connection.attachStreams && connection.attachStreams.length) {
    connection.attachStreams.forEach((stream) => {
      connection.removeStream(stream.id);
    });
  }

  if (streams && streams.length) {
    streams.forEach(({ srcObject: stream }) => {
      connection.removeStream(stream.id);
    });
  }
};

const endVideoStream = (store) => {
  const connection = store.getters.rtcConnect;
  if (store.getters.localStream && connection?.removeStream) {
    connection.removeStream(store.getters.localStream.id);
  }

  store.dispatch('setClosedRoom', true);
  setParticipants({ store, count: 0 });
  VueCookieNext.removeCookie('owner_room');
  VueCookieNext.removeCookie('initiator');
  if (connection?.getAllParticipants) connection.getAllParticipants().forEach((pid) => connection.disconnectWith(pid));
  if (connection?.closeSocket) connection.closeSocket();
};

const closeRoomDatabase = async () => {
  const id = localStorage.getItem('active_room');
  if (id.length !== 24) return;
  try {
    await EventsApi.closeRoom(id);
  } catch (err) {
    return err;
  }
};

export {
  getDeviceError,
  emitRtcEvent,
  setParticipants,
  sendVideoSettings,
  setVideoSettings,
  stopAllTracks,
  closeRoomDatabase,
  endVideoStream
};
