<template>
  <div>
    <span
      class="colored-label"
      :class="{'colored-label__mobile':isMobile}"
    >
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ColoredLabel',
  props: {
    text: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.colored-label {
  background-color: $color-moon-raker !important;
  color: $color-purple !important;
  padding: 3px 12px !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 125% !important;

  &__mobile {
    padding: 4px 6px !important;
  }
}

</style>