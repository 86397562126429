<template>
  <div
    class="lp-local-video"
    :class="{
      'lp-local-video_toolbar': (!videoStream || !participants) && !sharingFile,
      'lp-local-video_mobile': isMobile,
      'lp-local-video_mobile_teacher': isMobile && isTeacher,
      'lp-local-video_no-stream': !remoteVideoStream && !sharingFile,
      'lp-local-video_vertical': displayRemoteStream && mobileParticipant
    }"
    @contextmenu.stop.prevent
    v-if="!isMobile || (videoStream && participants) || (!participants && sharingFile)"
    :style="{top: isMobile ? '' : `${localVideoTopPosition}px`}"
    @click="$emit('update:switchVideo', !switchVideo)"
  >
    <div
      class="lp-local-video__container"
      v-if="videoStream && participants || (!participants && sharingFile)"
    >
      <transition name="fade">
        <div
          class="lp-local-video__output lp-local-video__output_mute"
          v-if="videoSettings.muteLocal"
        />
      </transition>
      <video
        @contextmenu.stop.prevent
        class="lp-local-video__output"
        :class="{
          'lp-local-video__output_mirror': displayRemoteStream ? videoSettings.mirrorRemote : videoSettings.mirror,
          'lp-local-video__output_mobile': isMobile,
          'lp-local-video__output_mobile_teacher': isMobile && isTeacher

        }"
        ref="videoEl"
        autoplay
        playsinline
        :srcObject="displayRemoteStream ? remoteVideoStream : videoStream"
        :muted="true"
        :id="videoStream.streamid"
      />
    </div>
    <template v-if="!isMobile && remoteVideoStream">
      <div class="lp-local-video__switch-video-icon">
        <SwitchVideoIcon />
      </div>
      <div class="lp-local-video__hover-border" />
    </template>
    <VideoCallToolbarLocal
      :openMobileSlider="openMobileSlider"
      :background="(videoStream && participants) || sharingFile"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, nextTick, ref, watch } from 'vue';
import VideoCallToolbarLocal from '@/components/VideoCall/VideoCallToolbarLocal';
import { find } from 'lodash';
import { ResizeObserver } from 'resize-observer';
import { SwitchVideoIcon } from '@/constants/icons';

export default {
  name: 'VideoLocalStream',
  components: { VideoCallToolbarLocal, SwitchVideoIcon },
  props: {
    switchVideo: Boolean,
    isTeacher: Boolean,
    openMobileSlider: Boolean
  },
  setup (props) {
    const isMobile = computed(() => store.getters.isMobile);
    const mobileParticipant = computed(() => store.getters.mobileParticipant);

    const store = useStore();
    const participants = computed(() => store.getters.participants);

    const videoStream = computed(() => store.getters.localStream);
    const videoSettings = computed(() => store.getters.videoSettings);

    const sharingFile = computed(() => store.getters.sharingFile);
    const streams = computed(() => store.getters.streams);
    const remoteVideoStream = computed(() => {
      const targetStream = find(streams.value, stream => {
        return stream.type === 'remote' && stream.srcObject.active;
      }) || {};
      return targetStream.srcObject;
    });

    const localVideoTopPosition = ref(props.isTeacher ? 40 : 0);
    const resizeObserver = ref(null);

    const setBodyHeight = (nodes) => {
      if (!nodes[0]) return;
      const removeVideoEl = nodes[0].target;
      localVideoTopPosition.value = removeVideoEl.clientHeight + (props.isTeacher ? 40 : 0);
    };

    const setTopPosition = () => {
      if (resizeObserver.value) resizeObserver.value.disconnect();
      nextTick(() => {
        const removeVideoEl = document.getElementById(`video_${remoteVideoStream.value?.streamid}`);
        if (!removeVideoEl || !sharingFile.value) {
          localVideoTopPosition.value = props.isTeacher ? 40 : 0;
          return;
        }
        localVideoTopPosition.value = removeVideoEl.clientHeight + (props.isTeacher ? 40 : 0);
        resizeObserver.value = new ResizeObserver(setBodyHeight);
        resizeObserver.value.observe(removeVideoEl);
      });
    };

    watch(sharingFile, setTopPosition);
    watch(participants, setTopPosition);

    const displayRemoteStream = computed(() => props.switchVideo && remoteVideoStream.value);

    return {
      isMobile,
      participants,
      videoStream,
      videoSettings,
      localVideoTopPosition,
      remoteVideoStream,
      displayRemoteStream,
      mobileParticipant,
      sharingFile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-local-video {
  position: absolute;
  top: 40px;
  right: 0;
  max-height: calc(50vh - 180px);
  max-width: 325px;
  box-shadow: 0 0 15px rgba($color-black, 0.05);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $background-video-call;
  transition: top 200ms ease;
  cursor: pointer;

  &__container {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:hover ~ .lp-local-video__switch-video-icon {
      transform: scale(1) translateY(50%);
    }

    &:hover ~ .lp-local-video__hover-border {
      border-color: $color-outer-space;
    }
  }

  &_vertical {
    max-width: 249px;
    min-width: 160px;
    max-height: calc(80vh - 140px);
  }

  &_vertical &__output {
    max-height: calc(80vh - 140px);
  }

  @media (max-width: 1143px) {
    max-width: 33.333vw;
    max-height: 80%;
    min-width: 100px;
  }

  &__hover-border {
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid transparent;
    width: calc(100%);
    height: calc(100% - 60px);
    z-index: 2;
    transition: border-color 200ms ease;
    pointer-events: none;
  }

  &_no-stream {
    transform: translateY(10px);
  }

  &_mobile,
  &_no-stream {
    &:after {
      display: none;
    }
  }

  &_toolbar {
    top: 10px;
    right: 10px;
    width: 133px;
    height: 60px;
    padding: 10px;
    background-color: $color-shark;
    border-radius: 12px;
  }

  &_mobile {
    top: 46px;
    min-width: 80px;
    background-color: transparent;
    box-shadow: none;
    z-index: 99;

    &_teacher {
      top: 97px;
    }
  }

  &_hide {
    overflow: hidden;
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    max-height: 32px;
    max-width: 32px;
  }

  &__switch-video-icon {
    position: absolute;
    top: calc(50% - 64px);
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0) translateY(50%);
    transition: transform 200ms ease;
    z-index: 3;
    pointer-events: none;
    transform-origin: bottom;
  }

  &__output {
    width: 100%;
    height: 100%;
    padding-bottom: 58px;
    object-fit: contain;
    max-height: calc(50vh - 180px);

    &_mute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $background-video-call;
      z-index: 2;
    }

    &_mirror {
      transform: scaleX(-1);
    }

    &_mobile {
      width: calc(100vw / 3);
      height: calc(100vh / 3);
      object-fit: cover;
      object-position: center top;
      padding-bottom: 48px;
      max-height: 100vh;

      @media (orientation: landscape) {
        width: calc(100vh / 3);
        height: calc(100vw / 3);
      }

      &_teacher {
        padding-bottom: 38px;
      }
    }
  }

  &__audio {
    position: fixed;
    top: -100vh;
    left: -100vw;
    opacity: 0;
    z-index: -1;
  }

  &__toggle {
    position: absolute;
    bottom: 0;
    left: -1px;
    width: calc(100% + 1px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color-white, 0.5);
    opacity: 0;
    transition: 0.3s;

    svg {
      transition: 0.3s;
    }

    &_hide {
      opacity: 1;
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &:hover &__toggle {
    opacity: 1;
  }
}

</style>
