import { cloneDeep, uniqueId, get, set, map } from 'lodash';
import { reactive } from 'vue';
import TIME_TABLE_MODAL_REMINDER from '@/constants/enums/timeTable';

import MobileDetect from 'mobile-detect';
import moment from 'moment';
const md = new MobileDetect(window.navigator.userAgent);
const isMobile = !!md.mobile();
const options = map(TIME_TABLE_MODAL_REMINDER, (item) => ({ repeatRate: item.title, ...item }));
const lessonTypes = [
  { label: 'crm.lessons.form.lessonType.single', id: 'single', value: true, name: 'lessonType' },
  { label: 'crm.lessons.form.lessonType.group', id: 'group', value: false, name: 'lessonType' }
];

const inputs = [
  {
    type: 'radio',
    label: 'crm.lessons.form.lessonType.type',
    options: lessonTypes,
    modelName: 'lessonType',
    modelValue: 'single'
  },
  {
    type: 'checkbox',
    label: 'crm.lessons.form.trial',
    modelName: 'isTrial',
    keyProp: 'isTrial',
    modelValue: false,
    style: {
      fontSize: '12px',
      marginTop: 0
    }
  },
  {
    type: 'selectImage',
    label: 'crm.lessons.form.student',
    modelName: 'crmStudentId',
    modelValue: [],
    keyProp: 'fullName',
    keyImg: 'uploadPhoto.url',
    options: [],
    searchable: true,
    lessonStatus: '',
    tooltip: false,
    getValue: (val) => val._id
  },
  {
    type: 'groupSelect',
    label: 'crm.sidebar.students',
    modelName: 'crmStudentId',
    modelValue: [],
    keyProp: 'fullName',
    options: [],
    lessonStatus: '',
    tooltip: false,
    searchable: true,
    getValue: (val) => val._id
  },
  {
    type: 'date',
    label: 'crm.lessons.form.date',
    modelName: 'dates',
    modelValue: {},
    fixed: true,
    style: {
      gridColumn: '1/4',
      minWidth: '234px'
    }
  },
  {
    type: 'timePicker',
    label: 'crm.lessons.form.timeStart',
    modelName: 'crmLessonStart',
    modelValue: '13:00',
    timePickerSettings: {
      hoursRange: [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      minuteRangeArr: [0,5,10,15,20,25,30,35,40,45,50,55],
      minuteInterval: 5,
      inputWidth: isMobile ? '100%' : '13em',
      showDash: true
    },
    style: {
      width: isMobile ? 'calc(50vw - 20px)' : null,
      gridColumn: isMobile ? '1/3' : '1/3'
    }
  },
  {
    type: 'timePicker',
    label: 'crm.lessons.form.timeEnd',
    modelName: 'crmLessonEnd',
    modelValue: '13:00',
    validError: 'errors.correctDate',
    timePickerSettings: {
      hoursRange: [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      minuteRangeArr: [0,5,10,15,20,25,30,35,40,45,50,55],
      minuteInterval: 5,
      inputWidth: isMobile ? '100%' : '13em',
      showDash: false
    },
    style: {
      width: isMobile ? 'calc(50vw - 20px)' : null,
      gridColumn: isMobile ? '3/3' : '3/3'
    },
    checkDateValid: (val, arr) => {
      const startTime = arr.find(el => el.modelName === 'crmLessonStart');
      const newStartTime = moment(startTime.modelValue, 'HH:mm').add(10, 'minutes').format('HH:mm');
      return (startTime.modelValue >= val) || (val > startTime.modelValue && val <= newStartTime);
    }
  },
  {
    type: 'select',
    label: 'timetable.modal.timeSelectFrequency',
    mode: 'repeatRate',
    modelName: 'repeatRate',
    keyProp: 'repeatRate',
    translate: true,
    hide: false,
    lessonStatus: '',
    modelValue: options[0],
    getValue: (val) => val.id,
    options,
    style: {
      gridColumn: '1/4'
    }
  },
  {
    type: 'date',
    label: 'timetable.modal.timeDataPicker',
    modelName: 'repeatEnd',
    modelValue: {},
    fixed: true,
    hide: true,
    style: {
      gridColumn: '1/4',
      minWidth: '234px'
    }
  },
  {
    type: 'number',
    inputmode: 'decimal',
    autofocus: true,
    required: true,
    label: 'crm.lessons.form.price',
    modelName: 'crmLessonCost',
    modelValue: '',
    emptyError: 'errors.correctAmount',
    noWrap: true,
    visibility: false,
    style: {
      maxWidth: '114px',
      gridColumn: '1/auto'
    }
  },
  {
    type: 'currency',
    disabled: true,
    modelName: 'currency',
    modelValue: '',
    visibility: false,
    style: {
      maxWidth: '106px',
      gridColumn: '2/4',
      marginLeft: '-5px'
    }
  },
  {
    type: 'subscribe',
    disabled: true,
    visibility: false,
    modelName: 'crmLessonPass',
    modelValue: {},
    defaultValue: {}
  },
  {
    type: 'textarea',
    label: 'crm.lessons.form.comment',
    modelName: 'comment',
    modelValue: '',
    style: {
      fontSize: '14px',
      minHeight: '94px'
    }
  },
  {
    type: 'checkbox',
    label: 'crm.lessons.form.email',
    modelName: 'notifyStudent',
    modelValue: false,
    style: {
      fontSize: '12px',
      marginTop: 0
    }
  },
  {
    type: 'colorPicker',
    label: 'crm.lessons.form.color',
    modelName: 'color',
    modelValue: '#7500ba',
    style: {
      fontSize: '12px',
      marginTop: 0
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('lesson-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  const gridColumn = get(item, 'style.gridColumn', '');
  if (!gridColumn) set(item, 'style.gridColumn', '1/4');
  return item;
}));
