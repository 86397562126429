<template>
  <div
    class="lp-upload-audio-core-loader"
    :class="{'lp-upload-audio-core-loader_large': large}"
  >
    <div
      class="lp-upload-audio-core-loader__blade"
      :class="{'lp-upload-audio-core-loader__blade_large': large}"
      v-for="(el, i) of loaderBlades"
      :key="i"
    />
  </div>
</template>

<script>
import { fill } from 'lodash';

export default {
  name: 'MiniLoader',
  props: {
    large: Boolean
  },
  setup () {
    const loaderBlades = fill(new Array(12), 0);

    return {
      loaderBlades
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

$spinner-size: 30px;
$spinner-color: $color-white;

$spinner-size-medium: 100px;

.lp-upload-audio-core-loader {
  width: $spinner-size;
  height: $spinner-size;
  font-size: $spinner-size;
  position: relative;

  &_large {
    width: $spinner-size-medium;
    height: $spinner-size-medium;
    font-size: $spinner-size-medium;
  }

  &__blade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: 2px;
    height: 7px;
    border-radius: 2px;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinner-fade 1s infinite linear;

    &_large {
      width: 4px;
      height: 14px;
    }

    $animation-delay: 0s;
    $blade-rotation: 0deg;

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + 30;
        $animation-delay: $animation-delay + .083;
      }
    }
  }
}

@keyframes spinner-fade {
  0% {
    background-color: $spinner-color;
  }

  100% {
    background-color: transparent;
  }
}

</style>
