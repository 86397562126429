<template>
  <div class="lp-calendar-days">
    <div
      class="lp-calendar-days-header"
      :class="{'lp-calendar-days-header_past': isPastDate}"
      v-if="!mobile"
    >
      {{ formatDate }}
    </div>
    <div
      class="lp-calendar-days-table"
      :class="{
        'lp-calendar-days-table_zoomed': isZoomed,
        'lp-calendar-days-table_mobile': mobile
      }"
    >
      <div class="lp-calendar-days-table-hours">
        <div
          v-for="(hour, index) in hoursTable"
          :key="hour"
          class="lp-calendar-days-table-hours__item"
        >
          {{ hoursName(hour, index) }}
        </div>
      </div>
      <div class="lp-calendar-days-table-body">
        <div
          class="lp-calendar-days-table-body-row"
          v-for="(item, index) of day"
          :key="item.time"
        >
          <div
            v-if="!mobile && item.lessonStart === undefined && index !== day.length - 1"
            class="lp-calendar-days-table-body-row__free-time"
            @click="addLesson(item.time)"
          />
          <CalendarLesson
            v-if="item.lessonStart"
            :style="{height: `${item.height}px`}"
            :height="item.height"
            mode="day"
            :fontSize="item.height <= 35 ? `${item.height > 15 ? 14 : item.height * 0.8 }px` : ''"
            :mobile="mobile"
            :key="item.lesson._id"
            :lesson="item.lesson"
            :isZoomed="isZoomed"
            @get-lessons="$emit('get-lessons')"
          />
          <div
            v-if="mobile && index === 0"
            class="lp-calendar-days-table-body__plus"
            @click="addLesson('13:00')"
          >
            <addLessonIcon />
          </div>
        </div>
      </div>
    </div>
    <LessonForm
      :openForm="openForm"
      :date="date"
      :startTimeOnDay="startTimeOnDay"
      v-model:openForm="openForm"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import moment from 'moment';
import { fill } from 'lodash';
import LessonForm from '@/components/CRM/Lessons/Calendar/CalendarLessonForm/LessonForm';
import CalendarLesson from '@/components/CRM/Lessons/Calendar/CalendarLesson/CalendarLesson';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';
import { addLessonIcon } from '@/constants/icons/crm';

export default {
  name: 'CalendarDays',
  components: {
    CalendarLesson,
    LessonForm,
    addLessonIcon
  },
  props: {
    lessons: Object,
    date: String,
    mobile: Boolean
  },
  emits: ['get-lessons'],
  setup (props) {
    const hoursTable = computed(() => fill(new Array (20), 5));
    const hoursName = (hour, index) => {
      if (index > 4 && (index + 1) !== hoursTable.value.length) {
        return `${hour + index}:00`;
      } else if ((index + 1) === hoursTable.value.length) {
        return '00:00';
      } else {
        return `0${hour + index}:00`;
      }
    };
    const today = computed(() => moment(props.date).format('YYYY-MM-DD'));

    const formatDate = computed(() => moment(props.date).format('DD dddd'));

    const isPastDate = computed(() => moment().subtract(1, 'day').isAfter(props.date));

    const setTimes = (el, i) => {
      return moment().set('minute', 0).set('hour', 5).add(i * 5, 'minutes').format('HH:mm');
    };
    const times = computed(() => fill(new Array(19 * 12), '').map(setTimes));
    const day = computed(() => {
      const updateArray = times.value.map((item, index, array) => {
        const fullDate = moment(`${today.value}T${item}`, 'YYYY-MM-DDTHH:mm' ).format();

        return {
          time: item,
          fullDate,
          ...orderedLessonTime(item, fullDate, index, array)
        };
      });
      return  [...updateArray];
    });
    const lessonsCurrentDate = computed(() => (props.lessons[props.date] || []));

    const orderedLessonTime = (time, fullDate, index, array) => {
      let orderedLessonTime = {};

      lessonsCurrentDate.value.forEach((lesson) => {
        const endDate = moment(lesson.crmLessonEnd).subtract(5, 'minutes').toDate();
        if (new Date(fullDate) >= new Date(lesson.crmLessonStart) && new Date(fullDate) <= new Date(endDate)) {
          const endTime = moment(lesson.crmLessonEnd).format('HH:mm');
          const startTime = moment(lesson.crmLessonStart).format('HH:mm');
          const lessonStart = time === startTime;

          orderedLessonTime = {
            lessonStart,
            lessonEnd: time === endTime,
            lesson
          };

          if (lessonStart) {
            const heightRow = 5;
            const endItemIndex = array.findIndex(el => el === endTime);
            const lessonLength = endItemIndex - index;
            orderedLessonTime.height = lessonLength * heightRow;
          }
        }
      });
      return orderedLessonTime;
    };

    const openForm = ref(false);
    const startTimeOnDay = ref(null);

    const addLesson = (time) => {
      openForm.value = true;
      startTimeOnDay.value = time;
    };

    const isZoomed = detectNeedZoom();

    return {
      hoursTable,
      formatDate,
      day,
      isPastDate,
      isZoomed,
      openForm,
      startTimeOnDay,
      addLesson,
      hoursName
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-days {
  @include global-font;

  &-header {
    position: sticky;
    top: 0;
    padding: 10px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    z-index: 1;

    &_past {
      background: $color-alabaster;
      color: $color-gray;
    }
  }

  &-table {
    display: grid;
    padding: 20px;
    border: 1px solid $color-alto;
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;
    overflow: auto;
    height: calc(100vh - 255px);
    grid-template-columns: 50px 1fr;

    &_mobile {
      overflow: visible;
      height: auto;
      border: none;
      padding: 0;
    }

    &-hours {

      &__item {
        display: flex;
        align-items: flex-start;
        height: 60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.5px;
        color: $color-gray
      }
    }

    &_zoomed {
      height: calc(120vh - 255px);
    }

    &-body {
      margin: 9px 0 0 0;

      &-row {
        height: 5px;
        cursor: pointer;
        position: relative;

        &:nth-child(1) {
          border-top: 1px solid $color-alto;
        }

        &:nth-child(12n +12) {
          border-bottom: 1px solid $color-alto;
        }

        @media not all and (hover: none) {
          &:hover {
            z-index: 2;
          }
        }

        &__free-time {
          width: 100%;
          border-radius: 4px;
          height: 5px;
          position: relative;

          &:hover {
            background: $color-purple;
          }
          &:hover:after {
            content: "+";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 18px;
            width: 18px;
            color: #FFFFFF;
            font-size: 19px;
            font-weight: 500;
            top: -3px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(to top, $color-purple 83%, transparent 17%);
          }
        }
      }

    }
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-calendar-days-table-body__plus {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: $color-accent;
  position: fixed;
  left: auto;
  top: auto;
  right: 16px;
  bottom: 26px;
  z-index: 10;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $color-white
    }
  }
}
</style>