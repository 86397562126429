import CATEGORY_EXPENSES from '@/constants/enums/categoryExpenses';

export default [
  {
    id: CATEGORY_EXPENSES.RENT.id,
    title: 'crm.expenses.category.modal.select.rent'
  },
  {
    id: CATEGORY_EXPENSES.SALARY.id,
    title: 'crm.expenses.category.modal.select.wage'
  },
  {
    id: CATEGORY_EXPENSES.UTILITIES.id,
    title: 'crm.expenses.category.modal.select.communalPayments'
  },
  {
    id: CATEGORY_EXPENSES.ADVERTISING.id,
    title: 'crm.expenses.category.modal.select.advertising'
  },
  {
    id: CATEGORY_EXPENSES.EQUIPMENT.id,
    title: 'crm.expenses.category.modal.select.equipment'
  },
  {
    id: CATEGORY_EXPENSES.MATERIALS.id,
    title: 'crm.expenses.category.modal.select.educationalMaterials'
  },
  {
    id: CATEGORY_EXPENSES.TAXES.id,
    title: 'crm.expenses.category.modal.select.tax'
  },
  {
    id: CATEGORY_EXPENSES.OTHER.id,
    title: 'crm.expenses.category.modal.select.otherExpenses'
  }
];
