<template>
  <div
    class="lp-timetable-edit-lesson"
    :class="{
      'lp-timetable-edit-lesson_loading': loading,
      'lp-timetable-edit-lesson_mobile': isMobile,
      'lp-timetable-edit-lesson_small': isMobile && isSmall,
      'lp-timetable-edit-lesson_small_column': isMobile && height > 45,
      'lp-timetable-edit-lesson_show-group': showAllStudents === item.id
    }"
    :style="style"
    @mouseleave="showAllStudents = null"
  >
    <div
      class="lp-timetable-edit-lesson__divider"
      :class="{
        'lp-timetable-edit-lesson__divider_mobile': isMobile,
        'lp-timetable-edit-lesson__divider_trial': isTrial
      }"
      :style="{backgroundColor: item.color}"
      :id="createRef"
    >
      <div
        v-if="item.time === item.lessonStart"
        class="lp-timetable-edit-lesson__content"
        :class="{
          'lp-timetable-edit-lesson__content_mobile': isMobile,
          'lp-timetable-edit-lesson__content_deleted': item.crmStudentDeleted,
          'lp-timetable-edit-lesson__content_sm':
            height <= 50 || (isTrial && !isMobile && height > 50),
          'lp-timetable-edit-lesson__content_sm_trial': height <= 25 && isTrial,
          'lp-timetable-edit-lesson__content_lg':
            height > 50 || (!isTrial && !isMobile && height > 50)
        }"
        :style="{height: isMobile ? `${height}px` : null}"
        ref="parentElement"
      >
        <div
          class="lp-timetable-edit-lesson__text"
          :class="{
            'lp-timetable-edit-lesson__text_default': isMobile && height < 60,
            'lp-timetable-edit-lesson__text_trial': isTrial && !isMobile,
            'lp-timetable-edit-lesson__text_trial_sm': isTrial && height <= 25
          }"
          @mouseover="showAllStudents = null"
        >
          <span ref="timeElement">
            {{ `${item.lessonStart}-${item.lessonEnd}` }}
          </span>
          <span
            class="lp-timetable-edit-lesson-trial-icon"
            :class="{'lp-timetable-edit-lesson-trial-icon_xs': height <= 20}"
            v-if="isTrial && !isMobile"
          >
            <TrialIcon />
          </span>
        </div>
        <div
          v-if="height > 20"
          class="lp-timetable-edit-lesson__wrapper"
        >
          <div
            v-for="student in studentsListLimited(item)"
            :key="student._id"
            class="lp-timetable-edit-lesson-student"
            :class="{
              'lp-timetable-edit-lesson-student_mb0': !isGroupLesson,
              'lp-timetable-edit-lesson-student_group': isGroupLesson
            }"
            @click="
              () =>
                student.crmStudentDeleted ? false : goToCardStudent(student)
            "
            @mouseover="hoverOnStudent(student.crmStudentFullName)"
            @mouseleave.stop="showTooltip = null"
          >
            <Tooltip
              v-if="isGroupLesson"
              :open="showTooltip === student.crmStudentFullName"
              :text="student.crmStudentFullName"
              :topPosition="true"
              :leftPosition="true"
              :onMobile="true"
              :customWidth="student.crmStudentFullName.length > limitedSymbols"
              :class="'lp-timetable-edit-lesson-student__tooltip'"
            />
            <template v-if="height >= 15">
              <img
                v-if="student.uploadPhotoUrl"
                class="lp-timetable-edit-lesson-student__image"
                :class="{
                  'lp-timetable-edit-lesson-student__image_sm':
                    (isMobile && isSmall && height <= 60) ||
                    (isMobile && !isSmall && height <= 30) ||
                    (!isMobile && height <= 30)
                }"
                :src="student.uploadPhotoUrl"
                alt="avatar"
              />
              <userIcon
                v-else
                class="lp-timetable-edit-lesson-student__image"
                :class="{
                  'lp-timetable-edit-lesson-student__image_sm':
                    (isMobile && isSmall && height <= 60) ||
                    (isMobile && !isSmall && height <= 30) ||
                    (!isMobile && height <= 30)
                }"
              />
            </template>
            <div
              v-if="item.crmLessons.length === 1"
              class="lp-timetable-edit-lesson__text lp-timetable-edit-lesson__text_name"
              :class="{
                'lp-timetable-edit-lesson__text_less': isMobile && height < 15,
                'lp-timetable-edit-lesson__text_mobile': isMobile
              }"
              :style="styleStudentName"
            >
              {{ studentNameLimited }}
            </div>
          </div>
          <div
            v-if="item.crmLessons.length > lessonsLimited"
            class="lp-timetable-edit-lesson-students"
            :class="{'lp-timetable-edit-lesson-students_mobile': isMobile}"
          >
            <div
              class="lp-timetable-edit-lesson__more"
              :class="{
                'lp-timetable-edit-lesson__more_sm':
                  (isSmall && height < 60) || (!isSmall && height <= 30)
              }"
              @click="showAllStudents = item.id"
            >
              +{{ item.crmLessons.length - lessonsLimited }}
            </div>
            <AllStudents
              v-if="showAllStudents === item.id"
              :item="item"
              :isMobile="isMobile"
              :showAllStudents="showAllStudents"
            />
          </div>
        </div>
        <div
          v-else-if="height <= 20 && isGroupLesson"
          class="lp-timetable-edit-lesson-group"
          :class="{'lp-timetable-edit-lesson-group_mobile': isMobile}"
        >
          <groupLessonIcon @click="showAllStudents = item.id" />
          <AllStudents
            v-if="showAllStudents === item.id"
            :item="item"
            :isMobile="isMobile"
            :showAllStudents="showAllStudents"
          />
        </div>
        <div
          v-else-if="height <= 20 && !isGroupLesson"
          class="lp-timetable-edit-lesson__wrapper"
          @click="
            () =>
              item.crmLessons[0].crmStudentDeleted
                ? false
                : goToCardStudent(item.crmLessons[0])
          "
        >
          <img
            v-if="studentPhoto && height > 15"
            :src="studentPhoto"
            class="lp-timetable-edit-lesson-student__image lp-timetable-edit-lesson-student__image_sm"
            alt="avatar"
          />
          <userIcon
            v-else-if="height > 15"
            class="lp-timetable-edit-lesson-student__image lp-timetable-edit-lesson-student__image_sm"
          />
          <div
            class="lp-timetable-edit-lesson__text lp-timetable-edit-lesson__text_name"
          >
            {{ studentNameLimited }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { get } from 'lodash';
import { userIcon, groupLessonIcon } from '@/constants/icons';
import { useRouter } from 'vue-router';
import { TrialIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';
import AllStudents from '@/components/CRM/Lessons/AllStudents';
import Tooltip from '@/components/Main/Tooltip/Tooltip';

export default {
  name: 'TimetableEditableLesson',
  components: {
    userIcon,
    TrialIcon,
    groupLessonIcon,
    AllStudents,
    Tooltip
  },
  props: {
    loading: Boolean,
    item: Object,
    isMobile: Boolean,
    dayDate: String,
    firstItem: Boolean,
    times: Array
  },
  setup (props) {
    const isTrial = computed(() => get(props.item, 'isTrial', false));

    const studentsListLimited = (item) => {
      return item.crmLessons.length > lessonsLimited.value
        ? item.crmLessons.slice(0, lessonsLimited.value)
        : item.crmLessons;
    };

    const lessonsLimited = computed(() => {
      const ifSmall = isSmall.value ? 4 : 5;
      const ifTrial = isTrial.value ? 3 : ifSmall;
      return height.value >= 55 ? 7 : ifTrial;
    });

    const showTooltip = ref(null);

    const hoverOnStudent = (name) => {
      showAllStudents.value = null;
      showTooltip.value = name;
    };

    const showAllStudents = ref(null);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const studentPhoto = computed(() => {
      if (isGroupLesson.value) return false;
      return props.item.crmLessons[0].uploadPhotoUrl;
    });

    const limitedSymbols = computed(() => {
      const isSmallWidth = isSmall.value && height.value < 50 ? 10 : 24;
      const isLessonSmall = height.value < 30 ? 10 : 16;
      return isMobile.value ? isSmallWidth : isLessonSmall;
    });

    const studentNameLimited = computed(() => {
      if (isGroupLesson.value) return false;
      const studentName = props.item.crmLessons[0].crmStudentFullName;
      return studentName.length > +limitedSymbols.value
        ? studentName.slice(0, limitedSymbols.value) + '...'
        : studentName;
    });

    const createRef = computed(() => {
      const {
        time,
        lessonStart,
        lessonIndex,
        lessonEndFiveMinutes
      } = props.item;

      if (time === lessonStart) {
        return `lesson-${props.dayDate}-${lessonIndex}-start`;
      }
      if (time === lessonEndFiveMinutes) {
        return `lesson-${props.dayDate}-${lessonIndex}-end`;
      }
      return false;
    });

    const height = ref(undefined);
    const setHeight = () => {
      const start = document.getElementById(
        `lesson-${props.dayDate}-${props.item.lessonIndex}-start`
      );
      const end = document.getElementById(
        `lesson-${props.dayDate}-${props.item.lessonIndex}-end`
      );
      if (!start || !end) {
        return;
      }
      height.value =
        end.getBoundingClientRect().top - start.getBoundingClientRect().top + 5;
    };

    onMounted(() => {
      setHeight();
    });

    const style = computed(() => {
      const { firstItem, item } = props;
      const { time, lessonStart } = item;
      return {
        top: firstItem ? '-2px' : null,
        height: time === lessonStart ? `${height.value}px` : 0
      };
    });

    const isGroupLesson = computed(() => {
      return props.item.crmLessons.length > 1;
    });

    const timeElement = ref(null);
    const parentElement = ref(null);
    const router = useRouter();

    const goToCardStudent = (student) => {
      router.push({
        name: 'CRMStudents',
        query: { crmStudentId: student.crmStudentId }
      });
    };
    const isSmall = computed(() => {
      const { lessonStartCheckTimetable, lessonStart } = props.item;
      if (lessonStartCheckTimetable && props.isMobile) {
        return props.times.some(
          (el) =>
            lessonStartCheckTimetable >= el.ruleStartDate &&
            lessonStart < el.ruleStartDate
        );
      }
      return false;
    });

    const styleStudentName = computed(() => {
      let number;
      switch (true) {
      case height.value <= 25:
        number = 1;
        break;
      case height.value <= 45 && height.value > 25:
        number = 2;
        break;
      case height.value <= 65 && height.value > 45:
        number = 3;
        break;
      default:
        number = 5;
        break;
      }
      return {
        webkitLineClamp: number,
        width: isSmall.value ? `${cropText.value}px` : '100%'
      };
    });

    const cropText = computed(() => {
      if (timeElement.value && parentElement.value) {
        const { width: timeWidth } = timeElement.value.getBoundingClientRect();
        const {
          width: parentWidth
        } = parentElement.value.getBoundingClientRect();
        const margin = 16;
        const headerTime = 114;
        const icon = height.value > 20 ? 25 : 0;
        const widthWithoutName =
          (height.value > 55 ? 0 : timeWidth + headerTime) + margin + icon;
        const widthBlockText = parentWidth - widthWithoutName;
        return widthBlockText;
      }
      return false;
    });
    return {
      styleStudentName,
      isSmall,
      timeElement,
      parentElement,
      height,
      createRef,
      style,
      isTrial,
      goToCardStudent,
      isGroupLesson,
      studentsListLimited,
      lessonsLimited,
      studentNameLimited,
      studentPhoto,
      showAllStudents,
      showTooltip,
      hoverOnStudent,
      limitedSymbols
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-timetable-edit-lesson {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: default;
  height: 5px;
  width: 8px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  top: 0;
  color: $color-black;

  &-trial-icon {
    margin-top: 2px;
    &_xs {
      svg {
        height: 8px;
      }
    }
  }

  &_show-group {
    z-index: 10;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__more {
    width: 24px;
    height: 24px;
    margin-left: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid $color-white;
    font-size: 10px;
    font-weight: 700;
    background-color: $color-purple;
    color: $color-white;

    &_sm {
      width: 16px;
      height: 16px;
      border: 1px solid $color-white;
    }
  }

  &-group {
    display: flex;
    position: relative;

    &_mobile {
      position: initial;
    }
  }

  &-students {
    position: relative;

    &_mobile {
      position: initial;
      z-index: 2;
    }
  }

  &_loading {
    opacity: 0;
  }
  &:hover {
    width: 100%;
  }

  &_mobile {
    width: calc(100% - 13px);
    left: -4px;

    &:hover {
      width: calc(100% - 13px);
    }
  }

  &_small {
    width: calc(100% - 114px);

    &_column {
      .lp-timetable-edit-lesson__content_mobile {
        flex-direction: column;
        align-items: flex-start;

        .lp-timetable-edit-lesson__text {
          margin-bottom: 3px;
        }
      }
    }

    &:hover {
      width: calc(100% - 114px);
    }
  }

  &__divider {
    width: 8px;
    height: 100%;
    background: $color-accent;
    cursor: pointer;
    border-radius: 4px;

    &_trial {
      background: $color-supernova;

      &:hover {
        background: $color-selective-yellow;
      }
    }

    &:hover .lp-timetable-edit-lesson__content {
      width: calc(100% - 13px);
      transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &_mobile {
      width: 4px;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      &:hover .lp-timetable-edit-lesson__content {
        width: 100%;
      }
    }
  }

  &__text {
    font-size: 12px;
    font-weight: 700;
    margin: 0 15px 0 0;

    &_default {
      margin: 0 8px 0 0;
      white-space: nowrap;
    }

    &_trial {
      display: flex;
      align-items: center;
      grid-gap: 8px;

      &_sm {
        margin-right: 5px;

        span {
          font-size: 10px;
        }
      }
    }

    &_name {
      font-weight: 400;
      margin: 0 0 0 2px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      word-wrap: break-word;
      width: auto !important;
      max-width: 160px;
    }

    &_less {
      margin: 0;
    }

    &_mobile {
      max-width: 100%;
    }
  }

  &__content {
    width: 0;
    height: 100%;
    position: absolute;
    left: 8px;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: $color-white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    padding: 9px 8px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &_deleted {
      cursor: default;
    }

    &_mobile {
      left: 4px;
      width: 100%;
      opacity: 1;
      visibility: visible;

      &:hover {
        width: calc(100% - 13px);
      }
    }

    &_sm {
      flex-direction: row;
      align-items: center;
      padding: 4px 8px;

      &_trial {
        padding: 0 3px 2px 3px;
      }
    }

    &_lg {
      flex-direction: column;
      align-items: flex-start;

      .lp-timetable-edit-lesson__text {
        margin-bottom: 6px !important;
      }
    }
  }

  &-student {
    display: flex;
    align-items: center;
    margin: 0 7px 7px 0;

    &_mb0 {
      margin-bottom: 0;
    }

    &_group {
      margin: 0 0 0 -7px;
      position: relative;

      &:first-child {
        margin-left: 0;
      }
    }

    &__image {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid $color-white;

      &_sm {
        width: 15px;
        height: 15px;
        border: 1px solid $color-white;
      }
    }

    &__tooltip {
      bottom: 30px;
    }
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-timetable-edit-lesson {
  &_order-calendar {
    svg path {
      fill: $color-accent !important;
    }
  }
}
</style>
