<template>
  <div
    class="notifications"
    :class="{mobileNotifications: isMobile}"
  >
    <h3 class="notifications__title">
      {{ $t('crm.settings.notifications') }}
    </h3>
    <p class="notifications__text">
      {{ $t('crm.settings.notificationsText.text') }}
    </p>
    <div class="notifications__options">
      <h3 class="notifications__subtitle">
        {{ $t('crm.settings.notificationsText.subTitle') }}
      </h3>
      <div class="notifications__checkboxes">
        <AppCheckbox
          v-for="checkbox in notificationsOptions"
          :key="checkbox.value"
          :isChecked="checkbox.isChecked"
          :text="checkbox.text"
          :value="checkbox.value"
          @toggle-checkbox="changeNotifications"
        />
      </div>
    </div>
    <div>
      <AppButton
        :text="`${$t('buttons.save')}`"
        :isDisabled="wasNotChangeNotifications"
        @callback="sendChangedNotifications"
      />
    </div>
  </div>
</template>

<script>
import AppCheckbox from '@/components/CRM/Settings/ui/AppCheckbox';
import AppButton from '@/components/CRM/Settings/ui/AppButton';
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import SettingsApi from '@/api/CRM/settings';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';
import {
  NEGATIVE_STUDENT_BALANCE,
  NO_LESSONS_PER_MONTH, ONE_HOUR_BEFORE_LESSON,
  REPORTS_FOR_STUDENTS,
  SCHEDULE_WEEKLY_REMINDER,
  SIGN_FOR_LESSONS_FROM_WIDGET, TOTAL_EARNED_PER_MONTH
} from '@/constants/notifications';

export default {
  name: 'NotificationsSettings',
  components: { AppCheckbox, AppButton },
  setup () {
    const notificationsOptions = ref([]);
    const changedNotifications = ref([]);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const wasNotChangeNotifications = ref(true);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const { t } = useI18n();

    const store = useStore();
    const notificationsData = computed(() => store.getters.notifications);

    const changeNotifications = (value) => {
      const findIndexSearchItem =  notificationsOptions.value.findIndex(item => item.value === value);

      notificationsOptions.value[findIndexSearchItem] = { ...notificationsOptions.value[findIndexSearchItem],
        isChecked: !notificationsOptions.value[findIndexSearchItem].isChecked };

      wasNotChangeNotifications.value = false;

      changedNotifications.value = notificationsData.value.options.filter((item) => {
        const currentItem = notificationsOptions.value.find(({ value }) => value === item.value);
        if (currentItem.isChecked !== item.isChecked) {
          return item;
        }
      });

      if (!!changedNotifications.value.length) {
        wasNotChangeNotifications.value = false;
        return;
      }
      wasNotChangeNotifications.value = true;
    };

    const sendChangedNotifications = async () => {
      const changedNotificationsValues = {};

      for (const notification of changedNotifications.value) {
        changedNotificationsValues[notification.value] = !notification.isChecked;
      }

      await setLoaderRun(true);
      try {
        await SettingsApi.updateNotifications(notificationsData.value._id, changedNotificationsValues);

        await store.dispatch('setNotifications',{ teacherId: notificationsData.value.teacherId,
          _id: notificationsData.value._id, options: [...notificationsOptions.value] });

        wasNotChangeNotifications.value = true;
        changedNotifications.value = [];
      } catch (e) {
        console.error(e.message);
      } finally {
        await setLoaderRun(false);
      }
    };

    onBeforeMount(async () => {
      try {
        const { data } = await SettingsApi.getNotifications();
        const { teacherId, _id, ...restData } = data;

        const addFieldText = (text) => {
          switch (text) {
          case REPORTS_FOR_STUDENTS: return t('crm.settings.notificationsText.optionEight');
          case NO_LESSONS_PER_MONTH: return t('crm.settings.notificationsText.optionSeven');
          case SCHEDULE_WEEKLY_REMINDER: return t('crm.settings.notificationsText.optionSix');
          case SIGN_FOR_LESSONS_FROM_WIDGET: return t('crm.settings.notificationsText.optionOne');
          case NEGATIVE_STUDENT_BALANCE: return t('crm.settings.notificationsText.optionTwo');
          case TOTAL_EARNED_PER_MONTH: return t('crm.settings.notificationsText.optionThree');
          case ONE_HOUR_BEFORE_LESSON: return t('crm.settings.notificationsText.optionFour');
          default: return t('crm.settings.notificationsText.optionFive');
          }
        };

        const options = [];

        Object.keys(restData).forEach(item => {
          options.push({
            value: item,
            isChecked: restData[item],
            text: addFieldText(item)
          });
        });
        await store.dispatch('setNotifications',{ teacherId, _id, options });
        notificationsOptions.value = [...options];
      } catch (e) {
        console.error(e.message);
      }
    });

    return {
      wasNotChangeNotifications,
      changeNotifications,
      sendChangedNotifications,
      notificationsOptions,
      isMobile
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.notifications {
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  max-width: 612px;
  margin: 0 auto;

  &__title {
    font-size: $title-h3-font-size;
    line-height: 22.5px;
    font-weight: 800;
    color: $color-mine-shaft;
    margin-bottom: 28px;
  }

  &__subtitle {
    font-size: $text-font-size;
    line-height: 22.5px;
    font-weight: 700;
    color: $color-mine-shaft;
    margin-bottom: 24px;
  }

  &__text {
    font-size: $text-font-size;
    line-height: 20px;
    color: $color-mine-shaft;
    margin-bottom: 28px;
  }

  &__options {
    margin-bottom: 40px;
  }

  &__checkboxes {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    width: 100%;
  }
}

.notifications.mobileNotifications {
  padding: 35px 16px;

  .notifications__title {
    font-size: $title-mobile-h3-font-size;
    line-height: 20px;
    font-weight: 800;
    color: $color-mine-shaft;
    margin-bottom: 20px;
  }

  .notifications__subtitle {
    font-size: $text-mobile-font-size;
    line-height: 17.5px;
    margin-bottom: 24px;
  }

  .notifications__options {
    margin-bottom: 36px;
  }
}

</style>
