<template>
  <div
    class="lp-close-room"
    :class="{'lp-close-room_mobile': isMobile}"
  >
    <div
      class="lp-close-room__button"
      @click="isTeacher ? (openConfirm = !openConfirm) : endVideoCall()"
    >
      <endLesson />
      <span>
        {{ isTeacher
          ? $t(`lessonCall.${isMobile ? 'endClassMobile' : 'endClass'}`)
          : $t(`lessonCall.${isMobile ? 'endClassMobileStudent' : 'endClassStudent'}`) }}
      </span>
    </div>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition :name="isMobile ? 'fade' : 'bounce'">
        <ModalQuestion
          :class="{'lp-close-room__modal': !isMobile}"
          v-if="openConfirm"
          :mobile="isMobile"
          :question="$t('lessonCall.endClassSure')"
          @accept="endVideoCall"
          v-model:open="openConfirm"
          theme="dark"
          :textCancel="$t('buttons.cancel')"
          :textAccept="$t('lessonCall.endClassMobile')"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { endLesson } from '@/constants/icons';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { closeRoomDatabase, emitRtcEvent, endVideoStream } from '@/constants/_rtc/utils/helpers';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import ROLE_TYPES from '@/constants/enums/roles';
import SOCKET_EVENTS from '@/constants/enums/events';

export default {
  name: 'VideoCallEnd',
  components: {
    ModalQuestion,
    endLesson
  },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);
    const openConfirm = ref(false);

    const endVideoCall = async () => {
      if (isTeacher.value) {
        emitRtcEvent({ event: SOCKET_EVENTS.CLOSE_ROOM, store });
        yandexTracker.callEnd();
        await closeRoomDatabase();
      }
      location.replace('/main');
      try {
        await endVideoStream(store);
        window.close();
      } catch (e) {
        return e;
      }
    };

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      openConfirm,
      endVideoCall,
      isTeacher
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-close-room {
  position: relative;

  &__button {
    display: grid;
    grid-template-columns: 12px auto;
    gap: 6px;
    align-items: center;
    justify-content: center;
    background-color: $color-crimson;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: $color-white;
    white-space: nowrap;

    &:hover {
      opacity: 0.9;
    }

    svg {
      width: 100%;
      height: auto;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_mobile &__button {
    padding: 11px 18px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 11px;
    line-height: 125%;
  }

  &__modal {
    top: auto;
    bottom: 53px;
    left: auto;
    right: 0;
    white-space: nowrap;
  }
}

</style>
