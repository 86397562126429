<template>
  <div
    class="lp-room-toolbar-local"
    :class="{
      'lp-room-toolbar-local_background': !background,
      'lp-room-toolbar-local_mobile': isMobile && !preview,
      'lp-room-toolbar-local_mobile_left': isMobile && preview,
      'lp-room-toolbar-local_mobile_teacher': isMobile && !preview && isTeacher
    }"
    @click.stop
  >
    <div
      class="lp-room-toolbar-local-settings"
      :class="{
        'lp-room-toolbar-local-settings_mobile_teacher': isMobile && !preview && isTeacher,
        'lp-room-toolbar-local-settings_mobile': isMobile && !preview
      }"
    >
      <!-- camera -->
      <WebcamSettings
        :preview="preview"
        v-if="!isMobile || preview || !isTeacher"
      />
      <!-- microphone -->
      <MicrophoneSettings v-if="!isMobile || preview || !isTeacher" />
      <!-- sing mode -->
      <transition name="slideX">
        <SwitchAudioMode
          v-if="isTeacher && !preview && !liteVersion && isMobile && !openMobileSlider"
          :isTeacher="isTeacher"
          :compact="true"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import WebcamSettings from '@/components/VideoCall/VideoCallSettings/WebcamSettings/WebcamSettings';
import MicrophoneSettings from '@/components/VideoCall/VideoCallSettings/MicrophoneSettings/MicrophoneSettings';
import SwitchAudioMode from '@/components/VideoCall/VideoCallTools/SwitchAudioMode/SwitchAudioMode';
import { useStore } from 'vuex';
import { computed } from 'vue';
import ROLE_TYPES from '@/constants/enums/roles';

export default {
  name: 'VideoCallToolbarLocal',
  components: {
    SwitchAudioMode,
    MicrophoneSettings,
    WebcamSettings
  },
  props: {
    preview: Boolean,
    background: Boolean,
    openMobileSlider: Boolean
  },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);

    const liteVersion = computed(() => store.getters.liteVersion);
    const hideSettingsDevices = true;

    const isMobile = computed(() => store.getters.isMobile);

    return {
      hideSettingsDevices,
      isMobile,
      liteVersion,
      isTeacher
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-room-toolbar-local {
  @include global-font;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  z-index: 99;
  padding: 10px;
  color: $color-accent;
  background: $color-shark;

  &_background {
    background: none;
  }

  &_mobile {
    background: $color-shark;
    min-height: 44px;

    &_left {
      @media (orientation: landscape) {
        grid-template-columns: auto auto 1fr;
        justify-content: flex-start;
      }
    }

    &_teacher {
      background-color: transparent;
      padding: 0;
    }
  }

  &-record {

  }

  &-settings {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    align-items: center;
    justify-content: center;

    &_left {
      @media (orientation: landscape) {
        justify-content: flex-start;
      }
    }

    &_mobile {
      padding: 0;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-around;
      gap: 4px;

      &_teacher {
        grid-template-columns: 1fr;
      }
    }
  }

  &-upload {
    display: flex;
    justify-content: flex-end;
  }
}

</style>
