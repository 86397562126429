<template>
  <div class="note-field">
    <div
      class="note-field__header"
      v-if="label"
    >
      <label
        class="lp-label"
        :for="label"
      >
        {{ $t(label) }}
      </label>
    </div>
    <QuillEditor
      class="note-field__input"
      :class="{'note-field__input_mobile': isMobile}"
      v-model:content="inputValue"
      contentType="html"
      theme="snow"
      :toolbar="toolbar"
      @update:content="handlerQuillUpdate"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { QuillEditor, Quill } from '@vueup/vue-quill';

const fontSizeArr = ['10px', '11px', '12px', false, '14px', '16px', '18px', '20px', '22px', '24px'];
const ColorStyle = Quill.import('attributors/style/color');
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = fontSizeArr;
const AlignStyle = Quill.import('attributors/style/align');
const BackgroundStyle = Quill.import('attributors/style/background');
const DirectionStyle = Quill.import('attributors/style/direction');
const FontStyle = Quill.import('attributors/style/font');

Quill.register(ColorStyle, true);
Quill.register(SizeStyle, true);
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import MobileDetect from 'mobile-detect';

export default {
  name: 'CustomNote',
  components: {
    QuillEditor
  },
  props: {
    modelValue: String,
    label: String
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const inputValue = ref('');
    const handlerQuillUpdate = (value) => {
      emit('update:modelValue', value);
    };

    const toolbar = ref([
      [{ size: fontSizeArr }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }]
    ]);

    onBeforeMount(() => {
      inputValue.value = props.modelValue;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      inputValue,
      handlerQuillUpdate,
      toolbar
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.note-field {
  &__header {
    margin-bottom: 4px;
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';
.ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  background: $color-moon-raker;
  color: $color-accent;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: $color-accent;
}

.ql-toolbar.ql-snow  {
  border: 1px solid $color-border-input;
  box-sizing: border-box;
  border-radius: 4px 4px 0 0;
}

.ql-container.ql-snow {
  border: 1px solid $color-border-input !important;
  margin-top: -1px;
}

.note-field {
  &__input {
    width: 100%;
    max-width: 550px;
    height: 250px;
    min-height: 250px;
    max-height: 250px;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px;

    &_mobile {
      width: calc(100vw - 24px);
      max-width: calc(100vw - 24px);
    }
  }
}

.ql-snow .ql-picker.ql-size {
  @for $i from 9 through 12 {
    .ql-picker-item[data-value="#{$i}px"]::before {
      content: '#{$i}';
      font-size: #{$i}px !important;
    }

    .ql-picker-label[data-value="#{$i}px"]::before {
      content: '#{$i}';
    }
  }

  $i: 14;
  @while $i < 25 {
    .ql-picker-item[data-value="#{$i}px"]::before {
      content: '#{$i}';
      font-size: #{$i}px !important;
    }

    .ql-picker-label[data-value="#{$i}px"]::before {
      content: '#{$i}';
    }
    $i: $i + 2;
  }

  $i: 30;
  @while $i < 41 {
    .ql-picker-item[data-value="#{$i}px"]::before {
      content: '#{$i}';
      font-size: #{$i}px !important;
    }
    .ql-picker-label[data-value="#{$i}px"]::before {
      content: '#{$i}';
    }
    $i: $i + 4;
  }
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 170px;
  overflow: auto;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '13';
  font-size: 13px !important;
}

</style>
