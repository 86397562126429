<template>
  <div class="lp-tooltip">
    <div
      class="lp-tooltip-content"
      ref="parent"
      @mouseenter="handleOpenTooltip"
      @mouseleave="handleCloseTooltip"
    >
      <slot></slot>
    </div>
    <transition name="bounce">
      <div
        class="lp-tooltip__text"
        :class="{'lp-tooltip__text_comment': isComment}"
        ref="tooltip"
        :style="getPosition()"
        v-if="openTooltip && !isMobile || openTooltip && isComment && isMobile"
      >
        <transition name="fade">
          <span
            class="lp-tooltip__arrow"
            :class="{'lp-tooltip__arrow_hide': !displayArrow}"
            v-if="displayArrow"
            :style="getPositionArrow()"
          />
        </transition>
        <span>{{ text }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import timeout from '@/constants/utils/timeout';
import MobileDetect from 'mobile-detect';

export default {
  name: 'LPTooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top'
    },
    isComment: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const openTooltip = ref(false);
    const parent = ref(null);
    const tooltip = ref(null);

    const displayArrow = ref(false);
    watch(openTooltip, async (val) => {
      await timeout(250);
      displayArrow.value = val;
    });

    const getPosition = () => {
      if (!parent.value || !tooltip.value) return {};
      const { offsetWidth, offsetHeight } = tooltip.value;
      const position = parent.value.getBoundingClientRect();
      const { left, top, width } = position;
      const style = {
        position: 'fixed',
        top: `${top - offsetHeight - 6}px`,
        left: `${left + (width / 2) - (offsetWidth / 2)}px`
      };
      if (props.position === 'right') {
        style.left = `${left - offsetWidth + width}px`;
      }
      if (props.position === 'left') {
        style.left = `${left}px`;
      }
      if (props.isComment) {
        style.left = `${left + (width / 2) - (offsetWidth / 2)}px`;
        style.top = `${top + 20}px`;
      }
      if (props.isComment && offsetWidth > window.innerWidth) {
        style['white-space'] = 'normal';
        style['overflow'] = 'hidden';
        style['text-overflow'] = 'ellipsis';
        style['display'] = 'block';
        style['text-align'] = 'left';
        style['word-wrap'] = 'break-word';
        style['width'] = `${window.innerWidth - 100}px`;

        style.left = '50px';
        style.top = `${top + 20}px`;
      }

      if (+style.top.replace('px', '') < 0) {
        style.top = `${top + 20 + offsetHeight}px`;
      }

      return style;
    };

    const getPositionArrow = () => {
      if (!parent.value) return {};
      const position = parent.value.getBoundingClientRect();
      const { left, top, width } = position;
      const style = {
        position: 'fixed',
        top: `${top - 7}px`,
        left: `${left + (width / 2) - 4}px`
      };
      if (props.isComment) {
        style.top = `${top + 12}px`;
        style['transform'] = 'rotate(180deg)';
      }
      return style;
    };

    const handleScroll = () => handleCloseTooltip();

    const handleOpenTooltip = () => {
      window.addEventListener('scroll', handleScroll);
      openTooltip.value = true;
    };

    const handleCloseTooltip = () => {
      window.removeEventListener('scroll', handleScroll);
      openTooltip.value = false;
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      parent,
      tooltip,
      openTooltip,
      displayArrow,
      handleOpenTooltip,
      handleCloseTooltip,
      getPosition,
      getPositionArrow
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-tooltip {
  @include global-font;
  position: relative;

  &-content {
    display: flex;
    align-items: center;
  }

  &__text {
    @include global-font;
    position: absolute;
    top: auto;
    left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    background: rgba($color-mine-shaft, 0.9);
    border-radius: 2px;
    pointer-events: none;
    user-select: none;
    z-index: 10;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-white;
    white-space: nowrap;
    text-transform: lowercase;

    span {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &__arrow {
    content:'';
    position:absolute;
    left: auto;
    top: auto;
    right: auto;
    bottom: -8px;
    display:block;
    width:0;
    height:0;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-top:4px solid rgba($color-mine-shaft, 0.9);

    &_hide {
      opacity: 0;
      transform: scale(0);
    }
  }
}

</style>
