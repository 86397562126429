const streamAudioMixer = (() => {
  let audioCtx;
  let source;
  let streamDestination;
  let microphoneGain;
  let mp3PlayerGain;
  let delayNode;
  let currentMicGain = 1;
  let currentDelay = 0;
  let mp3Player;

  /**
   * @param {*} _audioCtx - Web Audio API Context
   * @param {*} mp3Player - Web Audio mp3Player module
  */
  function init (_audioCtx, _mp3Player) {
    audioCtx = _audioCtx;
    mp3Player = _mp3Player;
    streamDestination = audioCtx.createMediaStreamDestination();
    microphoneGain = audioCtx.createGain();
    mp3PlayerGain = audioCtx.createGain();
    delayNode = audioCtx.createDelay(0.7);
    delayNode.delayTime.value = 0;
    microphoneGain.gain.value = 1;
    mp3PlayerGain.gain.value = 1;
    //Test osc
    // const osc = audioCtx.createOscillator();
    // osc.type = 'square';
    // osc.frequency.setValueAtTime(440, audioCtx.currentTime); // value in hertz
    // osc.connect(mp3PlayerGain);
    // osc.start();
    microphoneGain.connect(streamDestination);
    mp3PlayerGain.connect(streamDestination);
    delayNode.connect(mp3PlayerGain);
    mp3Player.getStreamNode().connect(delayNode);
  };

  function createAudioGraph (stream) {
    if (streamDestination) {
      streamDestination.disconnect();
      microphoneGain.disconnect();
      mp3PlayerGain.disconnect();
      delayNode.disconnect();
      mp3Player.getStreamNode().disconnect();
      streamDestination = null;
      microphoneGain = null;
      mp3PlayerGain = null;
      delayNode = null;
    }
    if (source) {
      source.disconnect();
      source = null;
    }
    source = audioCtx.createMediaStreamSource(stream);
    streamDestination = audioCtx.createMediaStreamDestination();
    microphoneGain = audioCtx.createGain();
    mp3PlayerGain = audioCtx.createGain();
    delayNode = audioCtx.createDelay(0.7);
    microphoneGain.gain.value = 1;
    mp3PlayerGain.gain.value = 1;
    delayNode.delayTime.value = currentDelay;
    source.connect(microphoneGain);
    microphoneGain.connect(streamDestination);
    mp3PlayerGain.connect(streamDestination);
    delayNode.connect(mp3PlayerGain);
    mp3Player.getStreamNode().connect(delayNode);
    return streamDestination.stream;
  }

  function setInputStream (stream) {
    console.log('new stream');
    console.log(stream);
    if (source) {
      source.disconnect();
      source = null;
    }
    source = audioCtx.createMediaStreamSource(stream);
    source.connect(microphoneGain);
  }

  function setMicrophoneGain (value) {
    currentMicGain = value;
    microphoneGain.gain.value = value;
  };

  function setMp3PlayerGain (value) {
    mp3PlayerGain.gain.value = value;
  };

  function getOutStream () {
    // setInputStream(stream);
    // if (streamDestination) {
    //   microphoneGain.disconnect(streamDestination);
    //   mp3PlayerGain.disconnect(streamDestination);
    //   microphoneGain.gain.value = 1;
    //   mp3PlayerGain.gain.value = 1;
    //   streamDestination.disconnect();
    //   streamDestination = null;
    //   streamDestination = audioCtx.createMediaStreamDestination();
    //   microphoneGain.connect(streamDestination);
    //   mp3PlayerGain.connect(streamDestination);
    // }
    return streamDestination.stream;
  }

  function getMicrohponeGainNode () {
    return microphoneGain;
  }

  function setDelay (value) {
    delayNode.delayTime.value = value;
    currentDelay = value;
    console.log(delayNode.delayTime);
  }

  function muteOutStream () {
    microphoneGain.gain.value = 0;
  }

  function unmuteOutStream () {
    microphoneGain.gain.value = currentMicGain;
  }

  return {
    init,
    createAudioGraph,
    setInputStream,
    setMicrophoneGain,
    setMp3PlayerGain,
    getOutStream,
    getMicrohponeGainNode,
    setDelay,
    muteOutStream,
    unmuteOutStream
  };
})();

export default streamAudioMixer;