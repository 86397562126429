<template>
  <div class="landing-page">
    <Header :languageEn="!languageRus" />
    <Welcome />
    <Contingent />
    <Opportunities />
    <SystemCRM />
    <Strengths />
    <StrengthsCRM />
    <Reviews />
    <Footer />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import checkCountries from '@/constants/countries/checkCountries';
import Header from '@/components/Landing/LandingMobile/Header';
import Welcome from '@/components/Landing/LandingMobile/Welcome';
import Contingent from '@/components/Landing/LandingMobile/Contingent';
import Opportunities from '@/components/Landing/LandingMobile/Opportunities';
import SystemCRM from '@/components/Landing/LandingMobile/SystemCRM';
import Strengths from '@/components/Landing/LandingMobile/Strengths';
import StrengthsCRM from '@/components/Landing/LandingMobile/StrengthsCRM';
import Reviews from '@/components/Landing/LandingMobile/Reviews';
import Footer from '@/components/Landing/LandingMobile/Footer';

export default {
  name: 'LandingMobile',
  components: {
    Header,
    Welcome,
    Contingent,
    Opportunities,
    SystemCRM,
    Strengths,
    StrengthsCRM,
    Reviews,
    Footer
  },

  setup () {
    const store = useStore();

    const countryCode = computed(() => store.getters.countryCode);
    const languageRus = computed(() => checkCountries(countryCode.value));
    const { locale } = useI18n({ useScope: 'global' });

    const isOpenMenu = ref(false);

    const toggleMenu = (val) => {
      isOpenMenu.value = val;
    };

    onMounted(() => {
      document.body.classList.add('hide-chat');
    });

    return {
      languageRus,
      isOpenMenu,
      toggleMenu,
      locale
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.landing-page {
  width: 100%;
  font-family: $global-font-manrope;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-white;
}
</style>

<style lang="scss">
.landing-page {
  .lp-button {
    padding-top: 17px;
    padding-bottom: 15px;
  }
}
</style>
