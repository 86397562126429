<template>
  <div
    class="lp-hints-first-step"
    :class="{'lp-hints-first-step_mobile': isMobile}"
  >
    <div
      class="lp-hints-first-step__img"
      :class="{'lp-hints-first-step__img_mobile': isMobile}"
    >
      <img
        src="@/assets/images/partyPopper.png"
        alt=""
      >
    </div>
    <div
      class="lp-hints-first-step__title"
      :class="{'lp-hints-first-step__title_mobile': isMobile}"
    >
      {{ $t('hints.firstStep.title') }}
    </div>
    <div
      class="lp-hints-first-step__description"
      :class="{'lp-hints-first-step__description_mobile': isMobile}"
    >
      {{ $t('hints.firstStep.description') }}
    </div>
    <div
      class="lp-hints-first-step__button lp-button"
      :class="{'lp-hints-first-step__button_mobile': isMobile}"
      @click="onButtonClick(true)"
    >
      {{ $t('hints.firstStep.buttonOne') }}
    </div>
    <div
      class="lp-hints-first-step__button lp-hints-first-step__button_two lp-button lp-button_white"
      :class="{'lp-hints-first-step__button_mobile': isMobile}"
      @click="onButtonClick(false)"
    >
      {{ $t('hints.firstStep.buttonTwo') }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'HintsFirstStep',
  props: {
    isMobile: Boolean
  },
  emits: ['on-button-click'],
  setup (props, { emit }) {
    const onButtonClick = (status) => {
      emit('on-button-click', status);
    };
    return {
      onButtonClick
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

  .lp-hints-first-step {
    width: 502px;
    height: 578px;
    border-radius: 4px;
    padding: 78px 40px 60px 40px;
    background: $color-white;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: $color-sine-shaft;
    box-shadow: 0 0 15px rgba($color-black, 0.02);

    &_mobile {
      width: 100%;
      height: 100%;
      padding: 104px 40px 60px 40px;
      border-radius: 0;
      box-shadow: none;
    }

    &__img {
      margin: 0 0 24px 0;

      &_mobile {
        margin: 0 0 46px 0;
      }
    }

    &__title {
      font-size: 28px;
      font-weight: 700;
      line-height: 35px;
      margin: 0 0 24px 0;
      font-family: $global-font-bold;

      &_mobile {
        font-size: 24px;
        line-height: 30px;
      }
    }

    &__description {
      padding: 0 20px 0 20px;
      margin: 0 0 50px 0;
      font-size: 18px;
      line-height: 27px;
      font-weight: 500;

      &_mobile {
        margin: 0 0 59px 0;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__button {
      width: 240px;
      padding: 16px 0;

      &_mobile {
        width: 100%;
      }

      & + & {
        margin: 15px 0 0 0;
      }

      &_two {
        box-shadow: none;
        font-size: 12px;
      }
    }
  }
</style>