import * as types from '@/constants/mutationTypes';

const state = {
  rootFolder: '',
  folderList: [],
  filesList: []
};
const getters = {
  rootFolder: (state) => state.rootFolder,
  folderList: (state) => state.folderList,
  filesList: (state) => state.filesList
};
const actions = {
  setRootFolder ({ commit }, data) {
    commit(types.SET_ROOT_FOLDER, { data });
  },
  setFolderList ({ commit }, data) {
    commit(types.SET_FOLDER_LIST, { data });
  },
  setFilesList ({ commit }, data) {
    commit(types.SET_FILES_LIST, { data });
  }
};
const mutations = {
  [types.SET_ROOT_FOLDER] (state, { data }) {
    state.rootFolder = data;
  },
  [types.SET_FOLDER_LIST] (state, { data }) {
    state.folderList = data;
  },
  [types.SET_FILES_LIST] (state, { data }) {
    state.filesList = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
