<template>
  <div>
    <Modal
      :title="student || students.length ? 'crm.students.payment.title' : ''"
      :mobileVersion="isMobile"
      :editMode="true"
      @save="saveForm"
      @remove="closeForm"
      :saveBtnText="'crm.students.payment.add'"
      :removeBtnText="'buttons.cancel'"
      :hideButtons="student || students.length ? [] : (isMobile ? ['save', 'remove'] : ['remove'])"
      v-model:open="openModal"
    >
      <div
        class="lp-payment-form"
        :class="{'lp-payment-form_mobile': isMobile}"
        v-if="student || students.length"
      >
        <Form
          @submit="saveForm"
          :inputs="paymentInputs"
        />
      </div>
      <div
        class="lp-payment-form-empty"
        v-else
      >
        <img
          class="lp-payment-form-empty__image"
          :class="{'lp-payment-form-empty__image_mobile': isMobile}"
          :src="imgPlaceholder"
          alt="students"
        >
        <p class="lp-payment-form-empty__title">
          {{ $t('calendar.empty.emptyStudents') }}
        </p>
        <p class="lp-payment-form-empty__description">
          {{ $t('calendar.empty.emptyInfo') }}
        </p>
        <button
          class="lp-button"
          @click="saveForm"
          v-if="isMobile"
        >
          {{ $t('crm.students.table.addStudent') }}
        </button>
      </div>
      <div
        class="lp-payment-form__placeholder"
        v-if="progressSaving"
      >
        <Loader :block="true" />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { computed, onBeforeMount, ref, watch } from 'vue';
import inputs from '@/constants/inputs/paymentInputs';
import validateProfile from '@/constants/validatesForm/validateProfile';
import { useStore } from 'vuex';
import { get, set, sortBy } from 'lodash';
import SettingsApi from '@/api/CRM/settings';
import PaymentsApi from '@/api/CRM/payments';
import StudentApi from '@/api/CRM/students';
import moment from 'moment';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import imgPlaceholder from '@/assets/images/tablePlaceholder/students.png';
import { useRouter } from 'vue-router';
import STUDENT_STATUS from '@/constants/enums/studentStatus';
import Loader from '@/components/Main/Loader/Loader';
import MobileDetect from 'mobile-detect';

export default {
  name: 'PaymentForm',
  components: { Loader, Modal, Form },
  props: {
    openForm: Boolean,
    student: Object
  },
  emits: ['update:openForm', 'update'],
  setup (props, { emit }) {
    const store = useStore();
    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsTable', data);

    const openModal = ref(false);

    const paymentInputs = computed(() => {
      console.log('props.student', props.student);
      return props.student ? inputs.filter(item => item.modelName !== 'crmStudentId') : inputs;
    });

    const closeForm = () => {
      openModal.value = false;
      setDefaultValues();
    };

    const setDate = (body) => {
      const paymentDate = get(body, ['paymentDate', 'date', 0], '');
      const time = moment().format('HH:mm:ss');
      const date = moment(paymentDate).format('YYYY-MM-DD');
      const dateMoment = moment(`${date}T${time}`, 'YYYY-MM-DDTHH:mm:ss');
      if (dateMoment.isValid()) {
        const date = dateMoment.toISOString();
        set(body, 'paymentDate', date);
      }
    };

    const router = useRouter();
    const progressSaving = ref(false);

    const saveForm = async () => {
      if (progressSaving.value) return;
      if (!students.value.length) {
        await router.push({ name: 'CRMStudents', params: { openForm: true } });
        return;
      }
      progressSaving.value = true;
      const body = await validateProfile(paymentInputs.value);
      if (!body) {
        progressSaving.value = false;
        return;
      }
      setDate(body);
      if (props.student) body.crmStudentId = get(props, 'student._id', '');
      const student = students.value.find(item => item._id === body.crmStudentId);
      try {
        await PaymentsApi.createPayment(body);
        yandexTracker.addPayment();
        if (student) student.balance = await getBalance(body.crmStudentId);
        progressSaving.value = false;
        emit('update');
      } catch (err) {
        console.error(err);
      }
      closeForm();
      progressSaving.value = false;
    };

    const getBalance = async (id) => {
      try {
        const { data } = await StudentApi.getBalance(id);
        return data.balance || 0;
      } catch (err) {
        console.error(err);
      }
    };

    watch(() => props.openForm, (value) => {
      if (value) getSettings();
      openModal.value = value;
    });
    watch(openModal, (value) => {
      emit('update:openForm', value);
    });

    const setCurrency = (data) => {
      inputs.forEach(input => {
        if (input.modelName === 'currency') input.modelValue = data.currentCurrency;
      });
    };

    const settings = computed(() => store.getters.settings);
    const getSettings = async () => {
      if (!props.student) await getStudentsList();
      setInputs();
      if (settings.value._id) {
        setCurrency(settings.value);
        return;
      }
      try {
        const { data } = await SettingsApi.getSettings();
        setCurrency(data);
      } catch (e) {
        console.error(e);
      }
    };

    const setDefaultValues = async () => {
      paymentInputs.value.forEach((input) => {
        if (input.modelName === 'currency') return;
        const value = input.defaultValue;
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
      });
    };

    const setInputs = () => {
      const studentsSort = sortBy(students.value || [], 'fullName');
      inputs.forEach((input) => {
        if (input.modelName === 'crmStudentId') {
          input.options = studentsSort.filter(item => item.status !== STUDENT_STATUS.INACTIVE );
          input.modelValue = input.options.find(item => item._id === studentsSort[0]) || input.options[0] || {};
        } else {
          input.modelValue = input.defaultValue;
        }
      });
    };

    const getStudentsList = async () => {
      try {
        const { data } = await StudentApi.getStudents({ });
        const { resource } = data;
        await setStudents(resource);
      } catch (err) {
        console.error(err);
      }
    };

    onBeforeMount(getSettings);
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      openModal,
      closeForm,
      saveForm,
      paymentInputs,
      students,
      imgPlaceholder,
      progressSaving
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-payment-form {
  min-width: 450px;
  padding: 28px 20px 0;

  &_mobile {
    min-width: auto;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 20px;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;

    &__image {
      width: 385px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;

      &_mobile {
        max-width: calc(100vw - 60px);
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }

  &__placeholder {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba($color-white, 0.8);
    z-index: 105;
  }
}

</style>
