<template>
  <div
    v-if="date !== 'Invalid date'"
    class="lp-date"
  >
    {{ dateRange || date }}
    <LessonNotComplete
      class="lp-date__past"
      v-if="displayPastIcon"
    />
    <FinishedByStudentReason
      class="lp-date__past"
      v-if="isFinishedByStudentReason"
    />
    <div
      v-if="column.showTime"
      class="lp-date__time"
    >
      {{ time }}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import moment from 'moment';
import LessonNotComplete from '@/components/Main/LessonNotComplete/LessonNotComplete';
import FinishedByStudentReason from '@/components/Main/FinishedByStudentReason/FinishedByStudentReason';

export default {
  name: 'DateCell',
  components: { LessonNotComplete, FinishedByStudentReason },
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const formatDate = computed(
      () => props.column.formatDate || 'DD MMM, YYYY'
    );
    const formatTime = computed(() => props.column.formatTime || 'HH:mm');

    const fullDate = computed(() => get(props.row, props.column.prop, ''));
    const date = computed(() =>
      moment(fullDate.value).format(formatDate.value)
    );
    const time = computed(() =>
      moment(fullDate.value).format(formatTime.value)
    );

    const dateRange = computed(() => {
      const prop = get(props.column, 'propRange', false);
      if (!prop) return false;
      const { start = '', end = '' } = prop;
      const dateStart = get(props.row, start, '');
      const dateEnd = get(props.row, end, '');
      const formatStart = moment(dateStart).format(formatTime.value);
      const formatEnd = moment(dateEnd).format(formatTime.value);
      return `${formatStart} - ${formatEnd}`;
    });

    const displayPastIcon = computed(() => {
      const needCheck = get(props.column, 'checkDateLesson', false);
      if (!needCheck) return;
      return moment().isAfter(moment(fullDate.value));
    });

    const isFinishedByStudentReason = computed(() => {
      const needCheck = get(props.column, 'studentReasonProp', '');
      if (!needCheck) return;
      return get(props.row, needCheck, false);
    });

    return {
      date,
      time,
      dateRange,
      displayPastIcon,
      isFinishedByStudentReason
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-date {
  display: flex;
  text-transform: capitalize;

  &__past {
    align-self: center;
    width: 18px;
    height: 18px;
    margin-left: 8px;
  }

  &__time {
    color: $color-gray;
  }
}
</style>
