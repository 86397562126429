<template>
  <Modal
    v-model:open="statusModal"
    :cantClose="true"
    :mobileVersion="isMobile"
    :hideButtons="['remove', 'save']"
  >
    <div
      class="lp-end-lesson"
      :class="{'lp-end-lesson_mobile': isMobile}"
    >
      <img
        class="lp-end-lesson__img"
        :src="image"
        alt="close lesson"
      />
      <div class="lp-end-lesson__text">
        {{ $t('global.closeRoom') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import { useStore } from 'vuex';

export default {
  name: 'VideoCallClosed',
  components: { Modal },
  props: {
    image: String,
    open: Boolean
  },
  emits: ['update:open'],
  setup (props, { emit }) {
    const statusModal = ref(false);
    watch(() => props.open, (newVal) => statusModal.value = newVal);
    watch(statusModal, (newVal) => {
      emit('update:open', newVal);
    });

    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      statusModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-end-lesson {
  padding: 0 0 41px;

  &_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
  }

  &__img {
    max-width: 675px;
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 40px 0 24px;
  }

  &__text {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
  }
}

</style>
