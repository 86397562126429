import { uniqueId } from 'lodash';
import FORMATS from '@/constants/formats';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';

const isMobile = checkMobileByScreen();

const columns = [
  {
    type: 'date',
    prop: 'paymentDate',
    formatDate: isMobile ? 'DD MMM, YY' : 'DD MMM, YYYY',
    title: 'crm.students.payment.date',
    width: isMobile ? '80px' : '1fr'
  },
  {
    type: 'user',
    prop: 'crmStudentFullName',
    photo: 'uploadPhotoUrl',
    title: 'crm.students.table.columns.name'
  },
  {
    type: 'number',
    prop: 'value',
    format: FORMATS.FINANCE,
    needCurrency: true,
    title: 'crm.students.payment.amount',
    justifyContent: isMobile ? 'flex-end' : 'center',
    textAlign: isMobile ? 'right' : 'center'
  }
];

const paymentsColumns = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default paymentsColumns;
