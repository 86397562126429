const COLORS_LIST = [
  {
    id: 'purple',
    color: '#7500BA'
  },
  {
    id: 'mineShaft',
    color: '#2C2C2C'
  },
  {
    id: 'supernova',
    color: '#FFCD00'
  },
  {
    id: 'red',
    color: '#FF0000'
  },{
    id: 'emerald',
    color: '#48D16E'
  },
  {
    id: 'dodgerBlue',
    color: '#3275F7'
  },
  {
    id: 'cerulean',
    color: '#04A9F4'
  },
  {
    id: 'mountainMeadow',
    color: '#1BBC9C'
  },
  {
    id: 'flushOrange',
    color: '#FF7800'
  },
  {
    id: 'tango',
    color: '#E96821'
  },
  {
    id: 'alto',
    color: '#E0E0E0'
  },
  {
    id: 'nobel',
    color: '#B3B3B3'
  },
  {
    id: 'gray',
    color: '#858585'
  },
  {
    id: 'edit'
  }

];

export default COLORS_LIST;