import { get, set } from 'lodash';
import store from '@/store/store';
import checkCountries from '@/constants/countries/checkCountries';

const getErrorText = ({ response }, locale) => {
  const defaultError = get(response, 'data.exception.message', 'errors.unique');
  const locales = {
    en: get(response, 'data.exception.response.eng', defaultError),
    ru: get(response, 'data.exception.response.rus', defaultError)
  };
  const countryCode = store.getters.countryCode;
  const lang = localStorage.getItem('lang');
  const currentLocale = checkCountries(countryCode) ? 'ru' : 'en';
  const currentLang = !!lang && lang !== 'undefined' ? lang : currentLocale;
  const language = locale || currentLang;
  return locales[language] || defaultError;
};

const highlightAllBorders = (target, indexText) => {
  target.forEach((input, i) => {
    if (i !== indexText) set(input, 'error', true);
  });
};

export default ({
  err, target, index, all, locale
}) => {
  const errorText = getErrorText(err, locale);
  const valIndex = typeof index === 'number' ? index : target.length - 1;
  set(target, [valIndex, 'error'], errorText);
  if (all) highlightAllBorders(target, valIndex);
};

export { getErrorText };
