<template>
  <template v-if="isMobile">
    <LandingMobile />
  </template>
  <template v-else>
    <LandingDesktop />
  </template>
</template>

<script>
import { computed, onMounted } from 'vue';
import MobileDetect from 'mobile-detect';
import LandingMobile from '@/components/Landing/LandingMobile/LandingMobile';
import LandingDesktop from '@/components/Landing/LandingDesktop/LandingDesktop';

export default {
  name: 'Landing',
  components: {
    LandingMobile,
    LandingDesktop
  },

  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    // onMounted(() => {
    //   document.body.classList.add('hide-chat');
    // });

    return {
      isMobile
    };
  }
};
</script>
