<template>
  <div
    class="lp-expenses-table-empty"
    :class="{
      'lp-expenses-table-empty_mobile': isMobile
    }"
  >
    <img
      class="lp-expenses-table-empty__image"
      :src="imgPlaceholder"
      alt="students"
    >
    <p class="lp-expenses-table-empty__title">
      {{ $t('crm.expenses.table.emptyList') }}
    </p>
    <button
      class="lp-button"
      @click="$emit('update:openExpense', true)"
    >
      {{ $t('crm.expenses.button') }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue';
import imgPlaceholder from '@/assets/images/tablePlaceholder/expenses.png';
import MobileDetect from 'mobile-detect';

export default {
  emits: ['update:openExpense'],
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      imgPlaceholder,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-expenses-table {
  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;
    padding-top: 50px;

    &_mobile {
      height: calc(100vh - 168px);
      height: calc((var(--vh, 1vh) * 100) - 168px);
    }

    &__image {
      width: 303px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;

      @media (max-width: 480px) {
        width: calc(100vw - 30px);
        max-width: 200px;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }
}
</style>