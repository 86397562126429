<template>
  <LPTooltip
    :text="$t('timetable.lessonStudentReason')"
    :position="'right'"
  >
    <div
      class="lp-lesson-student-reason"
      :style="style"
    >
      <warningIcon
        class="lp-lesson-student-reason__icon"
      />
    </div>
  </LPTooltip>
</template>

<script>
import { warningIcon } from '@/constants/icons/crm';
import { ref } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'FinishedByStudentReason',
  components: {
    LPTooltip,
    warningIcon
  },
  props: {
    style: Object
  },
  setup () {
    const openTooltip = ref(false);

    return {
      openTooltip
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-lesson-student-reason {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__icon {
    width: 100%;
    height: 100%;
  }
}

</style>
