<template>
  <div
    class="lp-profile-password"
    :class="{'lp-profile-password_mobile': isMobile}"
  >
    <div class="lp-profile-password-header">
      <h3
        class="lp-profile-password-header__title"
        :class="{'lp-profile-password-header__title_mobile': isMobile}"
      >
        {{ $t('profile.password') }}
      </h3>
      <LPTooltip :text="$t('crm.students.table.toolbar.edit')">
        <div
          class="lp-profile-password-header__edit"
          @click.stop="openPasswordForm = true"
        >
          <pencil-icon />
        </div>
      </LPTooltip>
    </div>
  </div>
  <ProfileFormPassword
    :user="user"
    v-model:open="openPasswordForm"
  />
</template>

<script>
import { computed, ref } from 'vue';
import ProfileFormPassword from '@/components/CRM/Settings/ProfileSettings/Profile/ProfileFormPassword';
import { useStore } from 'vuex';
import { pencilIcon } from '@/constants/icons/crm';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ProfilePassword',
  components: { LPTooltip, ProfileFormPassword, pencilIcon },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);

    const openPasswordForm = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      user,
      isMobile,
      openPasswordForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-profile-password {
  position: relative;
  display: grid;
  grid-template-columns: minmax(600px, 900px);
  justify-content: center;
  padding: 30px 35px;
  max-width: 900px;
  margin: 0 auto;

  &_mobile {
    max-width: 100%;
    grid-template-columns: 1fr;
    padding: 12px 20px 20px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-weight: 800;
      font-size: 18px;
      line-height: 125%;
      letter-spacing: 0.07em;
      color: $color-mine-shaft-dark;
      text-transform: capitalize;

      &_mobile {
        font-weight: bold;
        font-size: 16px;
      }
    }

    &__edit {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 13px;
      box-shadow: 0 0 17px rgba(0, 0, 0, 0.08);
      background-color: $color-white;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.3s ease-in;
      z-index: 2;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

</style>

<style lang="scss">
@import '~styles/_variables.scss';

.lp-profile-password-header__edit {
  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;

    path {
      fill: $color-accent;
    }
  }
}
</style>
