<template>
  <div
    class="section contingent"
    id="contingent"
  >
    <div class="contingent__container">
      <div class="contingent__title">
        <span>{{ $t('landing.contingent.name.first') }}</span>
        <span class="contingent__title_text">{{
          $t('landing.contingent.name.second')
        }}</span>
      </div>
      <div class="contingent__items">
        <Carousel :settings="settingsSlider">
          <Slide
            v-for="(item, index) of contingent"
            :key="item.id"
          >
            <div class="contingent-item">
              <div class="contingent-item__image">
                <img
                  :src="
                    require(`@/assets/images/landing/contingent-mobile/${item.image}.jpg`)
                  "
                  alt="user"
                />
              </div>
              <div
                class="contingent-item__text"
                :class="{
                  'contingent-item__last-text': index === contingent.length - 1
                }"
              >
                {{ $t(item.name) }}
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import contingent from '@/constants/landing/contingent';

export default {
  name: 'Contingent',
  components: {
    Carousel,
    Slide
  },
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const settingsSlider = reactive({
      itemsToShow: 1.1,
      snapAlign: 'center',
      wrapAround: false
    });

    return {
      contingent,
      settingsSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.contingent {
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: 100%;
    max-width: 1600px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    width: 100%;
    padding-left: 12px;
    display: flex;
    align-items: center;
    align-self: flex-start;
    flex-wrap: wrap;
    text-align: start;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;

    span {
      padding-right: 5px;
    }

    &_text {
      color: $color-purple;
      line-height: 100%;
    }
  }

  &__content {
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    margin-top: 10px;
    width: 100%;
  }
}

.contingent-item {
  width: calc(100% - 12px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.08);
  border-radius: 8px;
  margin: 15px 0;

  &__image {
    padding: 0;
    border-radius: 8px;
    width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: inherit;
      border-radius: 8px;
      background-position: center;
      background-size: 100%;
    }
  }

  &__text {
    position: absolute;
    bottom: 24px;
    left: 24px;
    color: $color-white;
    background-color: $color-purple;
    padding: 4px 10px;
    border-radius: 4px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }

  &__last-text {
    position: absolute;
    width: 100%;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -25%);
    color: $color-black;
    background-color: transparent;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
  }
}
</style>
