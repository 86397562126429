<template>
  <div
    class="lp-files"
    :class="{
      'lp-files_drag': fileDragIn && !fileDragInFolder._id,
      'lp-files_modal': modal,
      'lp-files_modal-mobile': modal && isMobile
    }"
    @drop="handleFileDrop"
    @dragend="onDragEnd"
    @dragleave.stop.prevent="onDragLeave"
    @dragenter.stop.prevent="onDragEnter"
    @contextmenu.stop.prevent="onContextMenu"
    @click="hideAllMenu"
  >
    <teleport
      to="#app"
      v-if="isMobile"
    >
      <button
        class="lp-files-add-button"
        v-if="!displayContextMenu"
        @click="displayContextMenu = true"
      >
        <plusIcon />
      </button>
    </teleport>
    <div
      class="lp-files-header"
      :class="{
        'lp-files-header_mobile': isMobile,
        'lp-files-header_mobile-modal': isMobile && modal
      }"
    >
      <MyFilesHeaderNav
        :path="pathFolders"
        :mobile="isMobile"
        :modal="modal"
        v-model:path="pathFolders"
      />
      <div
        class="lp-files-header-current-folder"
        @click="goBackFolder"
        v-if="isMobile && pathFolders.length"
      >
        <arrowBack />
        <span>{{ currentFolder.name }}</span>
      </div>
      <div
        class="lp-files-header-buttons"
        v-if="!hideUploads && !isMobile"
      >
        <ButtonNewFolder v-model:status="openNewFileForm" />
        <ButtonNewFile v-model:files="files" />
      </div>
    </div>
    <div
      class="lp-files-body"
      :class="{
        'lp-files-body_zoomed': isZoomed,
        'lp-files-body_empty': filesEmpty,
        'lp-files-body_modal': modal,
        'lp-files-body_mobile': isMobile,
        'lp-files-body_mobile-modal': isMobile && modal,
        'lp-files-body_empty-mobile': filesEmpty && isMobile
      }"
    >
      <ListEmpty
        v-if="filesEmpty"
        :imageUrl="filesPlaceholder"
        widthImg="352"
        title="myFiles.empty"
      />
      <div
        class="lp-files-body__item"
        v-for="folder of folderList.concat(filesList)"
        :key="folder._id"
        @click="saveParentFolder(folder)"
        @dblclick="onDoubleClick(folder)"
        v-touch:tap="() => onTap(folder)"
        @dragleave.prevent="onDragLeaveFile"
        @dragenter.prevent="onDragEnterFile($event, folder)"
      >
        <FilesItem
          :item="folder"
          :drag="folder._id === fileDragInFolder._id"
          :currentMenu="childContextMenuOpen"
          :selected="isMobile && !modal ? '' : selectedFile"
          :mobile="isMobile"
          :modal="modal"
          v-model:menu="childContextMenuOpen"
          v-model:name="folder.name"
          v-model:selected="selectedFile"
        />
      </div>
    </div>
    <FileContextMenu
      v-if="displayContextMenu"
      v-model:status="displayContextMenu"
      v-model:files="files"
      :position="contextMenuPosition"
      :options="['new', 'upload']"
      :mobile="isMobile"
      @new="openNewFileForm = true"
    />
    <NewFileForm
      :path="pathFolders"
      :open="openNewFileForm"
      v-model:open="openNewFileForm"
    />
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <FileUploadList
        :mobile="isMobile"
        :modal="modal"
        :files="files"
        :dragIn="dragIn"
        :path="pathFolders"
        v-model:drag="dragIn"
      />
    </teleport>
    <transition name="slideUploadInfo">
      <FileDroppedPopup
        v-if="fileDragIn"
        :folder="fileDragInFolder"
      />
    </transition>
  </div>
</template>

<script>
import ButtonNewFolder from '@/components/CRM/Files/Buttons/ButtonNewFolder';
import ButtonNewFile from '@/components/CRM/Files/Buttons/ButtonNewFile';
import filesPlaceholder from '@/assets/images/empty-files.png';
import ListEmpty from '@/components/Main/ListEmpty/ListEmpty';
import { computed, onBeforeMount, onBeforeUnmount, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import FilesItem from '@/components/CRM/Files/FilesItem';
import FileContextMenu from '@/components/CRM/Files/Menus/FileContextMenu';
import NewFileForm from '@/components/CRM/Files/NewFileForm';
import FileUploadList from '@/components/CRM/Files/FilesUploadList/FileUploadList';
import FileDroppedPopup from '@/components/CRM/Files/Menus/FileDroppedPopup';
import MyFilesHeaderNav from '@/components/CRM/Files/MyFilesHeaderNav';
import { useRouter } from 'vue-router';
import { get, last } from 'lodash';
import FolderApi from '@/api/CRM/folders';
import FilesApi from '@/api/CRM/files';
import timeout from '@/constants/utils/timeout';
import FOLDER_TYPES from '@/constants/enums/folders';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';
import { fileTypes } from '@/constants/fileTypes';
import MobileDetect from 'mobile-detect';
import { arrowBack } from '@/constants/icons/files';
import { plusIcon } from '@/constants/icons/crm';

export default {
  name: 'MyFiles',
  components: {
    MyFilesHeaderNav,
    FileDroppedPopup,
    FileUploadList,
    NewFileForm,
    FileContextMenu,
    FilesItem,
    ListEmpty,
    ButtonNewFile,
    ButtonNewFolder,
    plusIcon,
    arrowBack
  },
  props: {
    modal: Boolean,
    file: Object,
    filter: String
  },
  emits: ['update:file', 'save'],
  setup (props, { emit }) {
    const store = useStore();
    const activeUser = computed(() => store.getters.user);
    const rootFolder = computed(() => store.getters.rootFolder);
    const setRootFolder = (data) => store.dispatch('setRootFolder', data);
    const folderList = computed(() => store.getters.folderList);
    const setFolderList = (data) => store.dispatch('setFolderList', data);
    const filesList = computed(() => store.getters.filesList);
    const setFilesList = (data) => {
      switch (props.filter) {
      case 'audio':
        store.dispatch('setFilesList', getAudioFiles(data));
        break;
      case 'sharingFiles':
        store.dispatch('setFilesList', getSharingFiles(data));
        break;
      default:
        store.dispatch('setFilesList', data);
        break;
      }
    };

    const getAudioFiles = (data) => {
      return data.filter(file => {
        const { mimetype = '' } = file;
        if (!mimetype) return fileTypes[0];
        const type = mimetype.replace(/.+\//g, '');
        const fileType = fileTypes.find((item) => item.types.includes(type)) || fileTypes[5];
        return fileType.isAudio;
      });
    };

    const getSharingFiles = (data) => {
      return data.filter(file => {
        const { mimetype = '' } = file;
        if (!mimetype) return fileTypes[0];
        const type = mimetype.replace(/.+\//g, '');
        const fileType = fileTypes.find((item) => item.types.includes(type)) || fileTypes[5];
        return fileType.isSharingFiles;
      });
    };

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const uploaded = ref(false);
    const filesEmpty = computed(() => uploaded.value && !folderList.value.length && !filesList.value.length);

    const pathFolders = ref([]);
    const currentFolder = computed(() => last(pathFolders.value) || {});
    watch(currentFolder, ({ _id }) => getFolders(_id));

    const folderStopUpload = (type) => type === FOLDER_TYPES.RECORDS_FOLDER;

    const hideUploads = computed(() => {
      const { folderType } = currentFolder.value;
      return folderStopUpload(folderType);
    });

    const getRootFolder = async () => {
      if (rootFolder.value) return;
      try {
        const { data: rootFolderId } = await FolderApi.getRootFolder();
        await setRootFolder(rootFolderId);
      } catch (err) {
        console.error(err);
      }
    };

    const getFolders = async (parentFolder) => {
      await setLoaderRun(true);
      uploaded.value = false;
      await setFolderList([]);
      await setFilesList([]);
      await getRootFolder();
      try {
        const { data: folders } = await FolderApi.getFolderByParent(parentFolder);
        const { data: files } = await FilesApi.getFilesByParent(parentFolder);
        await setFolderList(folders.filter(({ folderType }) => folderType !== FOLDER_TYPES.RECORDS_FOLDER));
        await setFilesList(files);
        uploaded.value = true;
        await setLoaderRun(false);
      } catch (e) {
        uploaded.value = false;
        await setLoaderRun(false);
        console.error(e);
      }
    };

    watch(activeUser, (newUser = {}, oldUser = {}) => {
      if (newUser._id === oldUser._id) return;
      getFolders();
    });

    const displayContextMenu = ref(false);
    const contextMenuPosition = reactive({});
    const childContextMenuOpen = ref(null);
    watch(childContextMenuOpen, (newVal) => {
      if (newVal) displayContextMenu.value = null;
    });
    const onContextMenu = (e) => {
      const noAccess = folderStopUpload(currentFolder.value.folderType);
      if (noAccess) return;
      childContextMenuOpen.value = null;
      const {
        pageX,
        pageY
      } = e;
      displayContextMenu.value = !displayContextMenu.value;
      contextMenuPosition.left = pageX;
      contextMenuPosition.top = pageY;
    };

    const openNewFileForm = ref(false);
    watch(openNewFileForm, () => {
      childContextMenuOpen.value = null;
      displayContextMenu.value = false;
    });

    const files = ref([]);
    const fileDragIn = ref(false);
    const lastEventTarget = ref(null);
    const onDragEnter = (e) => {
      if (hideUploads.value) return;
      fileDragIn.value = true;
      lastEventTarget.value = e.target;
    };
    const onDragLeave = (e) => {
      if (lastEventTarget.value === e.target) {
        fileDragIn.value = false;
      }
    };

    const dragIn = ref('');
    const fileDragInFolder = ref({});
    const lastFolderTarget = ref(null);
    const onDragEnterFile = (e, folder) => {
      const noAccess = folderStopUpload(folder.folderType);
      if (folder.mimetype || hideUploads.value || noAccess) return;
      fileDragInFolder.value = folder;
      lastFolderTarget.value = e.target;
    };
    const onDragLeaveFile = (e) => {
      if (hideUploads.value) return;
      if (lastFolderTarget.value === e.target) {
        fileDragInFolder.value = {};
      }
    };

    const onDragEnd = () => {
      fileDragIn.value = false;
      fileDragInFolder.value = {};
    };

    const handleFileDrop = async (e) => {
      if (hideUploads.value) return;
      const { files: draggedFiles } = e.dataTransfer;
      if (!draggedFiles) return;
      files.value = draggedFiles;
      dragIn.value = fileDragInFolder.value._id;
      await timeout(500);
      onDragEnd();
    };

    const hideAllMenu = () => {
      childContextMenuOpen.value = null;
      displayContextMenu.value = false;
    };

    const router = useRouter();
    const replaceRouterId = () => {
      const folderId = get(currentFolder.value, '_id', rootFolder.value);
      router.replace({ params: { folderId } });
    };

    const selectedFile = ref('');
    watch(selectedFile, (id) => {
      emit('update:file', filesList.value.find(item => item._id === id) || {});
    });
    const saveParentFolder = (item) => {
      selectedFile.value = '';
      if (item.mimetype) {
        selectedFile.value = item._id;
      } else if (!pathFolders.value.includes(item._id)){
        pathFolders.value.push(item);
        replaceRouterId();
      }
    };
    const onDoubleClick = (item) => {
      if (item.mimetype && props.modal) {
        selectedFile.value = item._id;
        emit('save');
      }
    };
    watch([pathFolders], replaceRouterId);

    const onTapCounter = ref(0);
    const onTapTimeout = ref(null);
    const onTap = (item) => {
      if (onTapTimeout.value) clearTimeout(onTapTimeout.value);
      onTapTimeout.value = setTimeout(() => {
        onTapCounter.value = 0;
      }, 400);
      onTapCounter.value = onTapCounter.value + 1;
      if (onTapCounter.value < 2) return;
      console.log('onTap', onTapCounter.value);
      onDoubleClick(item);
    };

    const dragoverStop = (e) => {
      e.preventDefault();
    };

    onBeforeMount(() => {
      window.addEventListener('dragover', dragoverStop, false);
      window.addEventListener('drop', dragoverStop, false);
      router.replace({ params: { folderId: 'home' } });
      if (!activeUser.value._id) return;
      getFolders();
    });

    onBeforeUnmount(() => {
      window.removeEventListener('dragover', dragoverStop, false);
      window.removeEventListener('drop', dragoverStop, false);
    });

    const isZoomed = detectNeedZoom();

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const goBackFolder = () => {
      pathFolders.value.splice(-1, 1);
    };

    return {
      goBackFolder,
      currentFolder,
      isMobile,
      isZoomed,
      selectedFile,
      hideAllMenu,
      hideUploads,
      filesEmpty,
      filesPlaceholder,
      folderList,
      filesList,
      onContextMenu,
      childContextMenuOpen,
      displayContextMenu,
      contextMenuPosition,
      openNewFileForm,
      files,
      onDragEnd,
      handleFileDrop,
      fileDragIn,
      onDragEnter,
      onDragLeave,
      fileDragInFolder,
      onDragLeaveFile,
      onDragEnterFile,
      pathFolders,
      saveParentFolder,
      onDoubleClick,
      dragIn,
      onTap
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-files {
  @include global-font;
  position: relative;
  background-color: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 115px);
  border: 2px dashed transparent;
  box-sizing: border-box;
  transition: 0.3s ease-out;

  &_modal {
    width: 80vw;
    max-width: 829px;
    min-width: 220px;
    height: calc(85vh - 152px);
    min-height: 300px;
    margin: 28px 20px 0 20px;
    padding: 17px 20px 0 20px;
    border: 1px solid $color-alto;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &_modal-mobile {
    width: 100vw;
    margin: 0;
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    max-width: 100%;
    padding: 0;
    border: none;
  }

  &-back {
    position: absolute;
    top: 12px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  &_drag {
    background: rgba($color-accent, 0.05);
    border: 2px dashed $color-accent;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-bottom: 24px;

    &_mobile {
      position: sticky;
      top: 0;
      margin-bottom: 0;
      padding: 8px 0 8px 56px;
      grid-template-columns: 1fr;
      background-color: $color-white;
      z-index: 3;
    }

    &_mobile-modal {
      padding: 8px 0 8px 56px;
    }

    &-current-folder {
      margin-left: -56px;
      padding-left: 16px;
      padding-top: 18px;
      display: grid;
      grid-template-columns: 16px auto;
      align-items: center;
      grid-gap: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
    }

    &-buttons {
      display: flex;
      grid-gap: 15px;
      align-items: flex-start;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-template-rows: repeat(auto-fit, 142px);
    align-items: start;
    justify-content: center;
    grid-gap: 15px;
    padding-right: 20px;
    margin-right: -20px;
    height: calc(100vh - 183px);
    overflow: auto;

    &_mobile {
      height: auto;
      overflow: visible;
      margin-right: 0;
      padding: 20px 16px 72px;
    }

    &_zoomed {
      height: calc(120vh - 183px);
    }

    &_empty {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;
      user-select: none;
    }

    &_modal {
      min-height: 170px;
      height: calc(75vh - 146px);
    }

    &_mobile-modal {
      height: auto;
    }

    &_empty-mobile {
      height: calc(100vh - 52px);
      height: calc((var(--vh, 1vh) * 100) - 52px);
      padding: 0;
    }

    &__item {
      position: relative;
    }
  }

  &-add-button {
    position: fixed;
    bottom: 16px;
    right: 12px;
    background-color: $color-accent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    z-index: 999;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

</style>
