<template>
  <div
    class="lp-crm-expenses-header"
    :class="{'lp-crm-expenses-header_mobile': isMobile}"
  >
    <teleport
      to=".lp-crm"
      :disabled="!isMobile"
    >
      <h1
        class="lp-crm-expenses-header__title lp-page-title"
        :class="{'lp-crm-expenses-header__title_mobile': isMobile}"
      >
        {{ $t('crm.pages.expenses') }}
      </h1>
    </teleport>
    <teleport
      to="#app"
      v-if="isMobile"
    >
      <button
        class="lp-crm-expenses-header__button-mobile"
        @click="$emit('update:openExpense', true)"
      >
        <plusIcon />
      </button>
    </teleport>
    <div
      class="lp-crm-expenses-header-picker"
      :class="{'lp-crm-expenses-header-picker_mobile': isMobile}"
      v-if="!empty"
    >
      <DateSelect
        v-model:modelValue="selectedDate"
        :stringStyle="isMobile"
        :allTime="allTime"
      />
      <DatePicker
        :manualEditableDate="false"
        :hideTime="true"
        :rangePicker="true"
        :format="isMobile ? 'DD MMM, YY' : 'DD MMM, YYYY'"
        :formatStart="isMobile ? 'DD MMM' : 'DD MMM, YYYY'"
        :modelValue="dateRange"
        v-model:modelValue="dateRange"
      />
      <button
        class="lp-button lp-crm-expenses-header__button"
        @click="$emit('update:openExpense', true)"
        v-if="!isMobile"
      >
        {{ $t('crm.expenses.button') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted, watch } from 'vue';
import DatePicker from '@/components/Main/Inputs/DatePicker/DatePicker';
import DateSelect from '@/components/Main/Inputs/DateSelect/DateSelect';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import { plusIcon } from '@/constants/icons/crm';

export default {
  name: 'ExpensesHeader',
  components: { DateSelect, DatePicker, plusIcon },
  props: {
    empty: Boolean,
    fistDate: String,
    lastDate: String
  },
  emits: ['update:date', 'update:openExpense'],
  setup (props, { emit }) {
    const allTime = computed(() => {
      const startWithoutProp = moment().
        subtract(50, 'year').
        startOf('month').
        utc().
        format();
      const startWithProp = moment(props.fistDate).
        startOf('month').
        utc().
        format();
      const startDay = props.fistDate ? startWithProp : startWithoutProp;

      const endWithoutProp = moment().
        add(50, 'year').
        endOf('month').
        utc().
        format();
      const endWithProp = moment(props.lastDate).
        endOf('month').
        utc().
        format();
      const endDay = props.lastDate ? endWithProp : endWithoutProp;
      return [startDay, endDay];
    });

    const selectedDate = ref([]);
    const dateRange = ref({ time: [], date: selectedDate.value });
    watch(dateRange, (val) => {
      emit('update:date', val.date);
    });

    watch(selectedDate, (val) => (dateRange.value = { time: [], date: val }));

    onMounted(() => {
      dateRange.value = { time: [], date: selectedDate.value };
      if (!dateRange.value.date.length) return;
      emit('update:date', dateRange.value.date);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      allTime,
      selectedDate,
      dateRange
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-expenses-header {
  position: sticky;
  top: -35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 24px 30px;
  background-color: $color-white;
  z-index: 6;

  &_mobile {
    position: relative;
    padding: 76px 12px 16px;
    top: auto;
  }

  &__title {
    &_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;
      background-color: $color-white;
      padding: 16px 56px;
      z-index: 9;
    }
  }

  &-picker {
    display: grid;
    grid-template-columns: 208px minmax(285px, 1fr) auto;
    align-items: center;
    justify-content: end;
    grid-gap: 12px;
    max-width: 505px;
    flex-grow: 1;

    &_mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: none;
    }
  }

  &__button {
    padding: 12px 28px;
    font-size: 12px;
    white-space: nowrap;
  }

  &__button-mobile {
    position: fixed;
    bottom: 12px;
    right: 12px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-accent;
    border-radius: 50%;
    z-index: 14;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

</style>