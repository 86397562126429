<template>
  <div class="lp-students-list-card">
    <div class="lp-students-list-card-header">
      <img
        class="lp-students-list-card-header__img"
        @click="openStudentCard"
        v-if="photo"
        :src="photo"
        alt="photo"
      />
      <userIcon
        class="lp-students-list-card-header__img"
        @click="openStudentCard"
        v-else
      />
      <div
        class="lp-students-list-card-header-info"
        @click="openStudentCard"
      >
        <div class="lp-students-list-card-header__name">
          {{ student.fullName }}
        </div>
        <div
          class="lp-students-list-card-header__status"
          :style="status.style"
        >
          {{ $t(status.title) }}
        </div>
      </div>
      <div class="lp-students-list-card-header__menu">
        <Toolbar
          :row="student"
          :column="toolbarSettings"
          :hover="'true'"
          @delete="$emit('delete', $event)"
          @edit="$emit('edit', $event)"
        />
      </div>
    </div>
    <div
      class="lp-students-list-card-contacts"
      v-if=" student.email || phone"
    >
      <a
        class="lp-students-list-card-contacts-item lp-button lp-button_border"
        :href="`mailto:${student.email}`"
        v-if=" student.email"
      >
        <span class="lp-students-list-card-contacts-item__icon">
          <mail-icon />
        </span>
        <span class="lp-students-list-card-contacts-item__value">
          {{ $t('crm.students.table.contacts.mail') }}
        </span>
      </a>
      <a
        class="lp-students-list-card-contacts-item lp-button lp-button_border"
        :href="`tel:${phone.replace(/\s|\(|\)|-/gmi, '')}`"
        v-if="phone"
      >
        <span class="lp-students-list-card-contacts-item__icon">
          <phone-icon />
        </span>
        <span class="lp-students-list-card-contacts-item__value">
          {{ $t('crm.students.table.contacts.call') }}
        </span>
      </a>
    </div>
    <div class="lp-students-list-card-stats">
      <div class="lp-students-list-card-stats-item">
        <div class="lp-students-list-card-stats-item__title">
          {{ $t('crm.students.table.columns.balance') }}, {{ currency }}
        </div>
        <div
          class="lp-students-list-card-stats-item__value lp-students-list-card-stats-item__value_balance"
          :class="{'lp-students-list-card-stats-item__value_negative': balance < 0}"
        >
          <dangerIcon
            class="lp-students-list-card-stats-item__icon"
            v-if="balance < 0"
          />
          {{ balance }}
          <subscriptionsIcon
            class="lp-students-list-card-stats-item__subscribe"
            v-if="hasSubscribe"
          />
        </div>
      </div>
      <div class="lp-students-list-card-stats-item">
        <div class="lp-students-list-card-stats-item__title">
          {{ $t('crm.students.table.columns.lessons') }}
        </div>
        <div class="lp-students-list-card-stats-item__value">
          {{ student.finishedLessons || 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userIcon } from '@/constants/icons';
import { mailIcon, phoneIcon, dangerIcon } from '@/constants/icons/crm';
import { computed } from 'vue';
import { get } from 'lodash';
import getPhoneString from '@/constants/utils/getPhoneString';
import statusStudentList from '@/constants/crm/studentStatuses';
import numeral from 'numeral';
import FORMATS from '@/constants/formats';
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import { subscriptionsIcon } from '@/constants/icons/crm';

export default {
  name: 'StudentsListCard',
  components: {
    Toolbar,
    dangerIcon,
    mailIcon,
    phoneIcon,
    userIcon,
    subscriptionsIcon
  },
  props: {
    student: Object,
    currency: String
  },
  emits: ['delete', 'edit', 'open-card'],
  setup (props, { emit }) {
    const toolbarSettings =  {
      toolbar: true,
      title: '',
      width: '18px',
      deleteName: 'fullName'
    };

    const phone = computed(() => {
      const phoneObj = get(props.student, 'phone', {});
      return getPhoneString(phoneObj);
    });

    const status = computed(() => {
      const value = get(props.student, 'status', '');
      return statusStudentList.find(item => item.id === value) || {};
    });

    const balance = computed(() => {
      const value = get(props.student, 'balance', '');
      return numeral(value).format(FORMATS.FINANCE);
    });

    const photo = computed(() => get(props.student, 'uploadPhoto.url', ''));

    const openStudentCard = () => {
      emit('open-card');
    };

    const hasSubscribe = computed(() => get(props.student, 'crmLessonPass._id', ''));

    return {
      toolbarSettings,
      status,
      phone,
      balance,
      hasSubscribe,
      photo,
      openStudentCard
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-students-list-card {
  padding: 16px 18px 20px 12px;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.05);
  border-radius: 4px;

  &-header {
    position: relative;
    display: grid;
    grid-template-columns: 81px 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;

    &__menu {
      position: absolute;
      top: 0;
      right: 0;
      margin: -10px -10px 0 0;
    }

    &-info {
      display: inline-grid;
      grid-gap: 8px;
    }

    &__img {
      grid-row: 1/3;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: cover;
      width: 81px;
      height: 81px;
      border-radius: 50%;
    }

    &__name {
      display: inline-block;
      margin-left: 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 125%;
      color: $color-text;
      margin-right: 30px;
      word-break: break-word;
    }

    &__status {
      justify-self: start;
      display: inline-block;
      border-radius: 4px;
      padding: 5px 12px;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
    }
  }

  &-contacts {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 8px;
    align-items: center;
    margin-bottom: 18px;

    &-item {
      padding: 12px 13px;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-template-columns: 12px auto;
      grid-gap: 4px;

      &__value {
        font-weight: bold;
        font-size: 12px;
        line-height: 12px;
        color: $color-accent;
      }

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 16px;
        padding-bottom: 2px;
      }
    }
  }

  &-stats {
    display: grid;
    grid-gap: 8px;
    margin: 0 -12px;
    padding: 16px 12px 0;
    border-top: 1px solid $color-alto;

    &-item {
      display: grid;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      align-items: center;

      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        color: $color-black;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &__icon {
        width: 18px;
        height: 18px;
      }

      &__subscribe {
        margin-left: 7px;
      }

      &__value {
        text-align: right;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        color: $color-text;

        &_negative {
          display: grid;
          grid-gap: 4px;
          grid-template-columns: 20px auto;
          align-items: center;
          color: $color-red;
        }

        &_balance {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-students-list-card-contacts-item__icon {
  svg {
    path {
      fill: $color-accent;
    }
  }
}

</style>
