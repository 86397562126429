<template>
  <div
    class="lp-crm-sidebar__burger"
    :class="{'lp-crm-sidebar__burger_mobile': isMobile}"
    @click.stop="openSidebar = !openSidebar"
  >
    <burgerIcon />
  </div>
  <transition name="slide-sidebar">
    <div
      class="lp-crm-sidebar"
      :class="{'lp-crm-sidebar_mobile': isMobile}"
      @click="closeSidebar"
      v-if="openSidebar"
    >
      <div
        class="lp-crm-sidebar-menu"
        :class="{'lp-crm-sidebar-menu_mobile': isMobile}"
      >
        <div
          class="lp-crm-sidebar-button"
          :class="{
            'lp-crm-sidebar-button_mobile': isMobile,
            'lp-crm-sidebar-button_disable': disabledSidebar
          }"
        >
          <VideoCallCreator
            :disabledSidebar="disabledSidebar"
            label="createRoom.virtual"
          />
          <div class="lp-crm-sidebar-button__label">
            {{ $t('createRoom.newVersion') }}
          </div>
          <div class="lp-crm-sidebar-button__label-top">
            {{ $t('createRoom.tryItNow') }}
          </div>
        </div>
        <component
          :is="disabledSidebar ? 'div' : 'router-link'"
          class="lp-crm-sidebar-item"
          :class="{
            'lp-crm-sidebar-item_active': item.route ? currentRoute === item.route.name : false,
            'lp-crm-sidebar-item_disabled': disabledSidebar
          }"
          v-for="(item, index) of menu"
          :key="index"
          :to="item.route"
        >
          <div class="lp-crm-sidebar-item__icon">
            <component :is="item.icon" />
          </div>
          <div class="lp-crm-sidebar-item__title">
            {{ $t(item.title) }}
          </div>
        </component>
      </div>
      <div
        class="lp-crm-sidebar-footer"
        :class="{'lp-crm-sidebar-footer_mobile': isMobile}"
      >
        <a
          v-if="false"
          class="lp-crm-sidebar-item"
          :href="linkMobileApp.href"
          :target="linkMobileApp.target"
        >
          <span class="lp-crm-sidebar-item__icon">
            <component :is="linkMobileApp.icon" />
          </span>
          <span class="lp-crm-sidebar-item__title">
            {{ $t(linkMobileApp.title) }}
          </span>
        </a>
        <ImproveServiceButton />
        <LogoutButton />
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div
      class="lp-crm-sidebar__background"
      :class="{'lp-crm-sidebar__background_mobile': isMobile}"
      v-if="openSidebar"
      @click="closeSidebar"
    />
  </transition>
</template>

<script>
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { get } from 'lodash';
import { useStore } from 'vuex';
import { accompanistMenuIcon, burgerIcon } from '@/constants/icons/crm';
import sidebarMenu from '@/constants/crm/sidebarMenu';
import VideoCallCreator from '@/components/VideoCall/VideoCallCreator/VideoCallCreator';
import LogoutButton from '@/components/CRM/Sidebar/Buttons/LogoutButton';
import ImproveServiceButton from '@/components/CRM/Sidebar/Buttons/ImproveServiceButton';
import MobileDetect from 'mobile-detect';

export default {
  name: 'SidebarCRM',
  components: {
    ImproveServiceButton,
    LogoutButton,
    VideoCallCreator,
    burgerIcon
  },
  setup () {
    const router = useRouter();
    const currentRoute = computed(() => get(router, 'currentRoute.value.name', ''));

    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const user = computed(() => store.getters.user);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const menu = sidebarMenu;

    const disabledSidebar = computed(() => {
      const { firstName, lessonCost } = user.value;
      const noSettings = !settings.value._id;
      const noProfile = !firstName || !lessonCost;
      return noSettings || noProfile;
    });

    const linkMobileApp = {
      title: 'crm.sidebar.accompanist',
      icon: accompanistMenuIcon,
      href: 'https://apps.apple.com/ru/app/sing-learn-song-with-piano/id1437134125',
      target: '_blank'
    };

    const openSidebar = ref(true);
    const handleOpen = (val) => {
      const body = document.querySelector('body');
      if (!body) return;
      body.style.overflow = val ? 'hidden' : 'visible';
      body.style.position = val ? 'fixed' : 'relative';
    };
    watch(openSidebar, (val) => handleOpen(val));
    const closeSidebar = () => {
      if (!isMobile.value) return;
      openSidebar.value = false;
    };

    let touchstartX;
    let touchendX;
    const handleTouchStart = ({ touches = [] }) => {
      if (!openSidebar.value) return;
      const [{ screenX }] = touches;
      touchstartX = screenX;
    };
    const handleTouchMove = ({ touches = [] }) => {
      if (!openSidebar.value) return;
      const [{ screenX }] = touches;
      touchendX = screenX;
    };
    const handleTouchEnd = () => {
      if (!openSidebar.value) return;
      if (touchendX - touchstartX < -50) {
        closeSidebar();
      }
    };

    const setTouchEvents = () => {
      if (!isMobile.value) return;
      document.addEventListener('touchstart', handleTouchStart, false);
      document.addEventListener('touchmove', handleTouchMove, false);
      document.addEventListener('touchend', handleTouchEnd, false);
    };

    onBeforeMount(() => {
      openSidebar.value = !isMobile.value;
      setTouchEvents();
    });

    onBeforeUnmount(() => {
      handleOpen(false);
      document.removeEventListener('touchstart', handleTouchStart, false);
      document.removeEventListener('touchmove', handleTouchMove, false);
      document.removeEventListener('touchend', handleTouchEnd, false);
    });

    return {
      isMobile,
      openSidebar,
      closeSidebar,
      linkMobileApp,
      menu,
      settings,
      currentRoute,
      disabledSidebar
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 12px;
  max-height: 100%;
  overflow: auto;
  user-select: none;

  &_mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    background-color: $color-alabaster;
    z-index: 14;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 16px 8px;
  }

  &__burger {
    display: none;
    position: fixed;
    z-index: 13;
    top: 0;
    left: 0;
    padding: 16px;

    &_mobile {
      display: flex;
    }
  }

  &__background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba($color-black, 0.5);

    &_mobile {
      display: block;
    }
  }

  &-button {
    position: relative;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    width: 100%;
    background: $color-white;
    padding: 15px 14px 0;
    box-shadow: 0 0 20px rgba($color-black, 0.11);
    border-radius: 8px;
    margin: 0 auto 6px;

    &__label {
      margin: 0 -14px;
      padding: 4px 15px;
      text-transform: uppercase;
      font: 800 10px $global-font;
      line-height: 1.25;
      color: $color-white;
      text-align: center;
      background: $color-rose;
      border-radius: 0 0 8px 8px;
    }

    &__label-top {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 6px;
      right: 6px;
      padding: 6px 6px;
      border-radius: 3px;
      background: $color-supernova;
      color: $color-mine-shaft;
      text-transform: uppercase;
      font: 800 9px $global-font;
      line-height: 10px;
      z-index: 2;
      user-select: none;
      pointer-events: none;
    }

    &_disable {
      pointer-events: none;
    }

    &_mobile {
      grid-gap: 8px;
    }
  }

  &-menu {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: 106px repeat(auto-fit, minmax(50px, auto));
    align-items: start;

    &_mobile {
      grid-gap: 0;
    }
  }

  &-footer {
    display: grid;
    grid-gap: 10px;

    &_mobile {
      grid-gap: 0;
    }
  }

  &-item {
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 10px;
    width: 100%;
    padding: 15px 18px;
    background: transparent;
    border-radius: 4px;
    color: $color-mine-shaft;
    cursor: pointer;
    transition: 0.3s ease-in;

    &_disabled {
      pointer-events: none;
    }

    &:hover {
      background: $color-moon-raker;
    }

    &_active {
      color: $color-accent;
      background: $color-moon-raker;

      svg path {
        fill: $color-accent;
        transition: 0.3s ease-out;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;

    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      transition: 0.4s ease-in-out;

      &::first-letter {
        text-transform: capitalize;
        user-select: none;
      }
    }

  }
}

</style>
