const contingent = [
  {
    id: 1,
    image: 'contingent-image1',
    name: 'landing.contingent.firstItem.name'
  },
  {
    id: 2,
    image: 'contingent-image2',
    name: 'landing.contingent.secondItem.name'
  },
  {
    id: 3,
    image: 'contingent-image3',
    name: 'landing.contingent.thirdItem.name'
  },
  {
    id: 4,
    image: 'contingent-image4',
    name: 'landing.contingent.fourthItem.name'
  },
  {
    id: 5,
    image: 'contingent-image5',
    name: 'landing.contingent.fifthItem.name'
  },
  {
    id: 6,
    image: 'contingent-image6',
    name: 'landing.contingent.sixItem.name'
  }
];
export default contingent;
