<template>
  <div class="group-select">
    <div
      v-if="!showAddStudents && !showAddGroup"
      class="group-select__wrapper"
    >
      <div class="group-select__label">
        {{ $t('crm.students.groups.studentsCapital') }}/{{ $t('crm.students.groups.group') }}
      </div>
      <div
        @click="onAddGroup"
        class="group-select__add"
      >
        <addIcon />
        <div>
          {{ $t('crm.students.table.addGroup') }}
        </div>
      </div>
      <div class="group-select__label group-select__label_lg">
        {{ $t('global.orCapital') }}
      </div>
      <div
        @click="onAddStudents"
        class="group-select__add"
      >
        <addIcon />
        <div>
          {{ $t('crm.students.table.addStudents') }}
        </div>
      </div>
    </div>
    <MultipleSelect
      v-if="showAddStudents"
      :options="options"
      :keyProp="keyProp"
      :modelValue="modelValue"
      :modelName="modelName"
      :required="required"
      :label="label"
      :fixed="fixed"
      :tooltip="tooltip"
      :error="error"
      :lesson="groupValue"
      :keyImg="keyImg"
      :lessonStatus="lessonStatus"
      :newStudent="newStudent"
      :clearAll="true"
      :notHideList="true"
      :showList="true"
      @clear="showAddStudents = false"
      @focus="$emit('focus')"
      @handlerChange="$emit('handler-change', $event)"
      @update="updateValue"
    />
    <CustomGroupSelect
      v-if="showAddGroup"
      :options="groupsList"
      :keyProp="'title'"
      :modelValue="groupsList"
      :required="required"
      :label="'crm.students.groups.groupCapital'"
      :fixed="fixed"
      :error="error"
      :searchable="searchable"
      :notHideList="true"
      :showList="true"
      @clear="showAddGroup = false"
      @focus="$emit('focus')"
      @handlerChange="$emit('handler-change', $event)"
      @update="updateValue"
      @clear-group-input="showAddGroup = false"
    />
    <div
      v-if="emptyList"
      class="group-select__error"
    >
      <dangerIcon />
      {{ $t('crm.lessons.form.studentsError') }}
    </div>
  </div>
</template>

<script>
import MultipleSelect from '@/components/Main/Inputs/MultipleSelect';
import { addIcon } from '@/constants/icons/crm';
import { dangerIcon } from '@/constants/icons';
import { computed, onBeforeMount, ref, watch } from 'vue';
import GroupsApi from '@/api/CRM/groups';
import CustomGroupSelect from '@/components/Main/Inputs/CustomGroupSelect';

export default {
  name: 'GroupSelect',
  components: {
    MultipleSelect,
    addIcon,
    dangerIcon,
    CustomGroupSelect
  },
  props: {
    options: Array,
    keyProp: String,
    modelValue: [Array, Object],
    modelName: String,
    required: Boolean,
    label: String,
    fixed: Boolean,
    tooltip: Boolean,
    error: [Boolean, String],
    lesson: Object,
    keyImg: String,
    lessonStatus: String,
    newStudent: Object,
    searchable: Boolean,
    inputs: Boolean,
    emptyList: Boolean
  },
  emits: ['update:modelValue', 'handler-change'],
  setup (props, { emit }) {
    const showAddStudents = ref(false);
    const showAddGroup = ref(false);

    const onAddStudents = () => {
      showAddStudents.value = true;
      showAddGroup.value = false;
    };

    const onAddGroup = () => {
      getAllGroups();
      showAddGroup.value = true;
      showAddStudents.value = false;
    };

    const groupValue = computed(() => {
      return !isStudentsCleared.value ? props.lesson : null;
    });

    const isStudentsCleared = ref(false);

    watch(showAddStudents, (val) => {
      if (!val) isStudentsCleared.value = true;
    });

    const groupsList = ref(null);
    const getAllGroups = async () => {
      const page = 1;
      const limit = 20;
      try {
        const { data } = await GroupsApi.getAllGroups(page, limit);
        groupsList.value = data.resource;
      } catch (e) {
        console.error(e);
      }
    };
    watch(groupsList, (val) => {
      emit('update:modelValue', val);
    });

    const updateValue = (modelValue) => {
      emit('update:modelValue', modelValue);
    };

    onBeforeMount(() => {
      if (props.lesson && props.lesson._id) {
        showAddStudents.value = true;
      }
    });

    return {
      showAddStudents,
      showAddGroup,
      updateValue,
      onAddStudents,
      onAddGroup,
      getAllGroups,
      groupsList,
      groupValue
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.group-select {
  &__add {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
    color: $color-purple;
    cursor: pointer;

    div {
      margin-left: 8px;
    }
  }

  &__label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: $color-mine-shaft;

    &_lg {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__error {
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: $color-red;

    svg {
      margin-right: 5px;
    }
  }
}
</style>