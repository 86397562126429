<template>
  <div class="lp-time-picker">
    <div
      class="lp-time-picker__label lp-label"
      v-if="label"
    >
      {{ $t(label) }}
    </div>
    <div
      class="lp-time-picker-fields lp-input"
      :class="{
        'lp-input_error': error,
        'lp-input_disabled lp-time-picker-fields_disabled': disabled
      }"
      v-click-outside="closeSelect"
      @click.stop="setDisplayHours('left')"
    >
      <label
        class="lp-time-picker-fields__label"
        ref="leftInput"
        v-if="!isMobile"
      >
        <input
          class="lp-time-picker-fields__input"
          :class="{'lp-time-picker-fields__input_mobile': isMobile}"
          :disabled="disabled || isMobile"
          v-model="time[0]"
          @focus="onlyStart ? () => {} : setDisplayHours('left')"
          @change="validateInputs"
          type="text"
          @keyup.enter="$emit('submit')"
        />
      </label>
      <div
        class="lp-time-picker-fields__label"
        v-else
      >
        {{ time[0] }}
      </div>
      <span>
        -
      </span>
      <label
        class="lp-time-picker-fields__label"
        :class="{
          'lp-time-picker-fields__label_disabled': onlyStart
        }"
        ref="rightInput"
        v-if="!isMobile"
      >
        <input
          class="lp-time-picker-fields__input"
          :class="{'lp-time-picker-fields__input_mobile': isMobile}"
          :disabled="disabled || isMobile"
          v-model="time[1]"
          @focus="setDisplayHours('right')"
          @change="validateInputs"
          type="text"
          @keyup.enter="$emit('submit')"
        />
      </label>
      <div
        class="lp-time-picker-fields__label"
        v-else
      >
        {{ time[1] }}
      </div>
      <span class="lp-time-picker-fields__clock">
        <clockIcon />
      </span>
      <teleport
        to="#app"
        v-if="isMobile && displayHours"
      >
        <div class="lp-time-picker-fields__background" />
      </teleport>
      <teleport
        to="#app"
        :disabled="!isMobile"
      >
        <transition :name="isMobile ? 'slide-select-mobile' : 'slide-select'">
          <div
            @click.stop
            class="lp-time-picker-fields-hours"
            :class="{
              'lp-time-picker-fields-hours_right': displayHours === 'right',
              'lp-time-picker-fields-hours_full' : onlyStart,
              'lp-time-picker-fields-hours_mobile' : isMobile
            }"
            :style="fixed && !isMobile ? getStylePopup() : {}"
            :options="{wheelPropagation: false}"
            v-if="displayHours"
          >
            <div
              class="lp-time-picker-fields-hours__item"
              :class="{
                'lp-time-picker-fields-hours__item_mobile': isMobile
              }"
              v-for="hour of times"
              :key="hour"
              @click.stop="setTime(hour)"
            >
              {{ hour }}
            </div>
          </div>
        </transition>
      </teleport>
    </div>
    <transition name="bounce">
      <span
        class="lp-time-picker__error lp-error"
        v-if="error && typeof error === 'string'"
      >
        <dangerIcon />
        {{ $t(error) }}
      </span>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import numeral from 'numeral';
import { forEach, cloneDeep, fill } from 'lodash';
import { clockIcon, dangerIcon } from '@/constants/icons';
import {
  ref,
  reactive,
  watch,
  computed,
  onMounted
} from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'TimePicker',
  components: { clockIcon, dangerIcon },
  props: {
    error: [Boolean, String],
    disabled: Boolean,
    label: String,
    onlyStart: Boolean,
    modelValue: Array,
    fixed: Boolean,
    step: Number,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 23
    }
  },
  emits: ['focus', 'update:modelValue'],
  setup (props, { emit }) {
    const timesLength = computed(() => {
      const addTimeLength = (!props.max || !props.min) ? 1 : 0;
      return (props.max - props.min) + addTimeLength;
    });
    const minTime = computed(() => numeral(props.min).format('00'));
    const arrayTimes = fill(new Array(timesLength.value), `2020-01-01T${minTime.value}:00`);
    const times = arrayTimes.map((el, i) => moment(el).add(i, 'hour').format('HH:mm'));
    const time = reactive(['00:00', '00:00']);
    watch([time], ([time]) => {
      const [startTime] = time;
      const momentDate = moment(new Date(`2020-01-01T${startTime}:00`));
      if (momentDate.isValid()) time[1] = momentDate.add(props.step, 'hour').format('HH:mm');
    });

    const displayHours = ref('');
    const setDisplayHours = (val) => {
      if (props.disabled) return;
      emit('focus');
      if (props.onlyStart) {
        displayHours.value = displayHours.value ? '' : 'left';
        return;
      }
      displayHours.value = '';
      if (val !== 'left' && val !== 'right') return;
      setTimeout(() => {
        displayHours.value = val;
      }, 150);
    };

    const closeSelect = () => displayHours.value = '';

    const updateTime = () => {
      const sort = ((a, b) => +a.match(/\d{2}/) - +b.match(/\d{2}/));
      const sorted = cloneDeep(time).sort(sort);
      emit('update:modelValue', props.onlyStart ? time : sorted);
    };

    const formatToHour = (val) => numeral(val).format('00') + ':00';

    const checkRange = (val, key) => {
      const number = Number(val.match(/\d{2}/));
      const isMax = number > props.max;
      const isMin = number < props.min;
      if (isNaN(number)) return;
      if (isMin) time[key] = formatToHour(props.min);
      if (isMax) time[key] = formatToHour(props.max);
    };

    const validateInputs = () => {
      forEach(time, (input, key) => {
        const momentDate = moment(new Date(`2020-01-01T${input}:00`)).set(0, 'minutes');
        const valid = momentDate.isValid();
        time[key] = momentDate.format('HH') + ':00';
        if (!valid) {
          const hour = input.match(/\d{2}/);
          time[key] = hour ? `${hour[0]}:00` : '00:00';
        }
        checkRange(input, key);
      });
      updateTime();
    };

    const setTime = (value) => {
      const index = displayHours.value === 'right' ? 1 : 0;
      time[index] = value;
      updateTime();
      setDisplayHours('');
      if (isMobile.value) displayHours.value = '';
    };

    const setDefault = () => {
      if (!props.modelValue) return;
      const [start, end] = props.modelValue;
      time[0] = start;
      time[1] = end;
    };

    watch(() => props.modelValue, setDefault);

    onMounted(setDefault);

    const leftInput = ref(null);
    const rightInput = ref(null);
    const getStylePopup = () => {
      if (!leftInput.value || !rightInput.value) return {};
      const positionLeft = leftInput.value.getBoundingClientRect();
      const positionRight = rightInput.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + positionLeft.top + positionLeft.height}px`,
        left: displayHours.value === 'right' ? `${positionRight.left}px` : `${positionLeft.left}px`,
        width: `${positionLeft.width}px`
      };
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      times,
      time,
      displayHours,
      setDisplayHours,
      closeSelect,
      validateInputs,
      setTime,
      leftInput,
      rightInput,
      getStylePopup,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-time-picker {
  @include global-font;
  position: relative;
  color: $color-text;

  &__error {
    top: 65px
  }

  &__label {
    margin-bottom: 4px;
  }

  &-fields {
    position: relative;
    display: inline-grid;
    grid-template-columns: 45px auto 45px 1fr;
    align-items: center;
    justify-content: space-between;
    grid-gap: 5px;
    height: 42px;

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text;

      &_disabled {
        pointer-events: none;
      }
    }

    &_disabled {
      color: $color-gray;
    }

    &__input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-width: 50px;
      text-align: center;
      border: none;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      padding: 1px;
      color: $color-text;
      background-color: transparent;

      &:disabled {
        color: $color-black;
        opacity: 1;
      }

      &_mobile {}
    }

    &__clock {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 2px;
    }

    &__background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: rgba($color-black, 0.5);
    }

    &-hours {
      @include global-font;
      position: absolute;
      top: 45px;
      left: 0;
      display: grid;
      grid-gap: 8px;
      width: 100%;
      height: auto;
      max-height: 150px;
      max-width: 130px;
      padding: 4px 0;
      background: $color-white;
      box-shadow: 0 0 15px rgba($color-black, 0.05);
      border-radius: 6px;
      border: 1px solid $color-wild-sand;
      z-index: 100;
      overflow: auto;

      &_right {
        left: auto;
        right: 0;
      }

      &_full {
        max-width: 100%;
      }

      &_mobile {
        position: fixed;
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        z-index: 103;
        border-radius: 0;
        max-height: 250px;
      }

      &__item {
        color: $color-text;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        padding: 8px 16px;
        box-sizing: border-box;
        border-radius: 3px;
        margin: 0 8px;
        background-color: $color-white;
        transition: 0.3s ease-in;
        cursor: pointer;

        &:hover {
          color: $color-accent;
          background-color: $color-moon-raker;
        }

        &_mobile {
          padding: 12px 16px;
        }
      }
    }
  }
}

</style>
