<template>
  <div
    class="lp-date-timetable-controls"
    :class="{'lp-date-timetable-controls_mini': mini}"
  >
    <div
      class="lp-date-timetable-controls__today"
      :class="{'lp-date-timetable-controls__today_disabled': isToday}"
      v-if="!mini"
      @click="setDate(0)"
    >
      {{ $t('calendar.today') }}
    </div>
    <div
      class="lp-date-timetable-controls-week"
      v-if="!mini"
    >
      <div
        class="lp-date-timetable-controls-week-navigate lp-date-timetable-controls-week-navigate_prev"
        @click="setDate(-1)"
      >
        <LPTooltip :text="prevText">
          <arrowCalendarIcon class="lp-date-timetable-controls-week-navigate__icon" />
        </LPTooltip>
      </div>
      <div class="lp-date-timetable-controls-week__current">
        {{ formatDate }}
      </div>

      <div
        class="lp-date-timetable-controls-week-navigate lp-date-timetable-controls-week-navigate_next"
        @click="setDate(1)"
      >
        <LPTooltip :text="nextText">
          <arrowCalendarIcon class="lp-date-timetable-controls-week-navigate__icon" />
        </LPTooltip>
      </div>
    </div>
    <DatePicker
      :manualEditableDate="true"
      :hideTime="true"
      :hideInput="true"
      :minDate="minDate"
      :modelValue="selectedDate"
      v-model:modelValue="selectedDate"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import moment from 'moment';
import DatePicker from '@/components/Main/Inputs/DatePicker/DatePicker';
import { arrowCalendarIcon } from '@/constants/icons';
import { useI18n } from 'vue-i18n';
import { capitalize, get } from 'lodash';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'DateTimetableControls',
  components: {
    LPTooltip,
    arrowCalendarIcon,
    DatePicker
  },
  props: {
    minDate: Date,
    date: String,
    mini: Boolean
  },
  emits: ['update:tab', 'update:date'],
  setup (props, { emit }) {
    const { t, tm } = useI18n();

    const isoDate = 'YYYY-MM-DD';
    const defaultDate = computed(() => {
      const date = moment(props.date);
      const startDate = date.format(isoDate);
      return [startDate];
    });

    watch(() => props.date, (val) => {
      const date = moment(val);
      const startDate = date.format(isoDate);
      selectedDate.value = { date: [startDate] };
    });

    const selectedDate = ref({ date: defaultDate.value });
    watch(selectedDate, (val) => {
      const date = get(val, ['date', 0], '');
      emit('update:date', moment(date).format(isoDate));
    });

    const formatDate = computed(() => {
      const date = moment(props.date);
      const startDate = date.format('DD MMMM');
      const endDate = date.add(6, 'day').format('DD MMMM, YYYY');
      return `${startDate} - ${endDate}`;
    });

    const isToday = computed(() => {
      const today = moment().format(isoDate);
      const currentDay = moment(props.date).format(isoDate);
      return today === currentDay;
    });

    const setDate = (val) => {
      const timeUnit = 'week';
      const momentDate = val ? moment(props.date ) : moment();
      if (val < 0) momentDate.subtract(1, timeUnit);
      if (val > 0) momentDate.add(1, timeUnit);
      emit('update:date', momentDate.format(isoDate));
    };

    const prevText = `${capitalize(tm('calendar.prev')[1])} ${t('calendar.week').toLowerCase()}`;
    const nextText = `${capitalize(tm('calendar.next')[1])} ${t('calendar.week').toLowerCase()}`;

    return {
      isToday,
      setDate,
      selectedDate,
      prevText,
      nextText,
      formatDate
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-date-timetable-controls {
  @include global-font;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 8px;
  align-items: center;
  justify-content: end;
  user-select: none;

  &_mini {
    grid-template-columns: auto;
  }

  &__today {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid $color-border-input;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $color-text;
    transition: color 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: $color-accent;
    }

    &_disabled {
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        color: $color-text;
      }
    }
  }

  &-week {
    display: grid;
    min-width: 300px;
    grid-template-columns: 12px auto 12px;
    grid-gap: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 7px 12px;
    border: 1px solid $color-border-input;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;

    &__current {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      text-transform: capitalize;
    }

    &-navigate {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &_next &__icon {
        transform: scale(-1);
      }

      &__icon {
        width: 12px;
        height: 12px;
        fill: $color-accent;
      }

      &:hover &__icon {
        fill: $color-accent-hover;
      }
    }
  }
}

</style>
