import * as types from '@/constants/mutationTypes';

const state = {
  subscriptions: []
};
const getters = {
  subscriptions: (state) => state.subscriptions
};
const actions = {
  setSubscriptions ({ commit }, data) {
    commit(types.SET_SUBSCRIPTIONS, { data });
  }
};
const mutations = {
  [types.SET_SUBSCRIPTIONS] (state, { data }) {
    state.subscriptions = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
