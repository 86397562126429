<template>
  <div class="lp-crm-settings-content">
    <Profile
      v-model:editable="editProfile"
      @showModal="openModal"
    />
  </div>
  <ReformingTimetableModal
    v-model:open="showModal"
    :isFirst="true"
    :description1="'timetable.modal.first.description1'"
    :description2="'timetable.modal.first.description2'"
  />
</template>

<script>
import Profile from '@/components/CRM/Settings/ProfileSettings/Profile/Profile';
import ReformingTimetableModal
  from '@/components/CRM/Lessons/Timetable/TimetableEditable/ReformingTimetableModal/ReformingTimetableModal';
import { computed, ref } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ProfileSettings',
  components: { Profile, ReformingTimetableModal },
  setup () {
    const editProfile = ref(false);
    const showModal = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    const openModal = (status) => {
      showModal.value = status;
    };

    return {
      openModal,
      isMobile,
      showModal,
      editProfile
    };
  }
};
</script>

<style lang="scss" scoped>
.lp-crm-settings-content {
  padding: 35px 0;
  min-height: calc(100vh - 118px);
  margin: auto;
}

</style>
