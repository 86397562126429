import chartTooltip from '@/constants/utils/CRM/chartTooltip';
import { reactive } from 'vue';
import FORMATS from '@/constants/formats';
import numeral from 'numeral';
import chartTooltipPosition from '@/constants/utils/CRM/chartTooltipPosition';

export default reactive({
  chart: {
    id: 'chart-profit',
    toolbar: { show: false },
    zoom: { enabled: false }
  },
  states: {
    active: {
      filter: {
        type: 'none' /* none, lighten, darken */
      }
    }
  },
  yaxis: {
    labels: {
      style: { cssClass: 'chart-label' },
      formatter: (value = 0) => {
        return (value !== Infinity && value !== -Infinity) ? (value ? numeral(value).format(FORMATS.FINANCE) : 0) : 1;
      }
    },
    tooltip: { enabled: false }
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    colors: ['#7500BA']
  },
  fill: {
    type: 'solid',
    colors: ['#7500BA'],
    opacity: 1
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    custom: chartTooltip,
    theme: false,
    x: { show: false },
    marker: { show: false }
  },
  markers: {
    size: 0,
    colors: '#FFFFFF',
    strokeColors: '#7500BA',
    strokeWidth: 4,
    strokeOpacity: 1,
    strokeDashArray: 14,
    fillOpacity: 1,
    discrete: [],
    hover: {
      sizeOffset: 6
    }
  },
  dataLabels: { enabled: false }
});
