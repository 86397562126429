import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getExpensesById (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`crm/expenses/${id}`);
  },
  getExpensesByDate ({ page = 1, limit = 999, startDate, endDate }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`page=${page}`, `limit=${limit}`, `periodStart=${startDate}`, `periodEnd=${endDate}`];
    return instCred.get(`crm/expenses/all/filterbydate/?${query.join('&')}`);
  },
  createExpenses (expense) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/expenses', expense);
  },
  deleteExpense (expenseId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`crm/expenses/${expenseId}`);
  },
  updateExpense (expense) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`crm/expenses/${expense._id}`, expense);
  },
  getAnalytics ([start, end]) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`periodStart=${start}`, `periodEnd=${end}`];
    return instCred.get(`crm/expenses/all/analytics/?${query.join('&')}`);
  },
  getTotalsByCategory ([start, end]) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`periodStart=${start}`, `periodEnd=${end}`];
    return instCred.get(`crm/expenses/all/totalsbycategory/?${query.join('&')}`);
  }
};
