/**
 * Module for RTC Data Communication
 * API:
 * @init (connection, audioEngine)
 * connection - rtc peer connection object
 * audioEngine - App audioEngine.js object
 *
 * @handleMessage (event)
 * event - connection.onmessage event handler
 *
 * @participant - participant control object:
 * participant.setMicrophoneGain (value) - уровень микрофона оппонента
 * participant.mp3Player.setGain (value) - уровень фонограммы оппонента
 * participant.mp3Player.play (value) - воспроизведение фонограммы оппонента
 * participant.mp3Player.stop (value) - остановка фонограммы оппонента
 * participant.mp3Player.setPosition (value) - перемотка плеера оппонента
 */
import { computed } from 'vue';
import { useStore } from 'vuex';

const rtcDataConnection = (() => {
  let connection;
  let mp3File;
  let audioEngine;
  let singingStudent;
  let currentPosition = 0;
  let dispatch;

  let studentOnline = false;
  let studentLoadedCallback = function () {};
  let studentConnectedCallback = function () {};
  let studentPlayCallback = function () {};
  let studentStopCallback = function () {};
  const setStudentPlayCallback = (playCallback) => {
    studentPlayCallback = playCallback;
  };
  const setStudentStopCallback = (stopCallback) => {
    studentStopCallback = stopCallback;
  };
  const setLoadedCallback = (loadedCallback) => {
    studentLoadedCallback = loadedCallback;
  };
  const setConnectedCallback = (connectedCallback) => {
    studentConnectedCallback = connectedCallback;
  };
  const studentConnected = () => {
    studentOnline = true;
    studentConnectedCallback();
  };
  const studentDisconnected = () => {
    audioEngine.mp3Player.stop();
    // mp3File.value.file = null;
    studentOnline = false;
  };
  const isStudentConnected = () => {
    return studentOnline;
  };

  const init = (_audioEngie) => {
    const store = useStore();
    connection = computed(() => store.getters.rtcConnect);
    mp3File = computed(() => store.getters.mp3File);
    singingStudent = computed(() => store.getters.singingStudent);
    dispatch = store.dispatch;
    audioEngine = _audioEngie;
  };

  const handleMessage = (event) => {
    console.log(event);
    if (event.data.player === 'play') {
      audioEngine.mp3Player.play();
      studentPlayCallback();
      return;
    }
    if (event.data.player === 'pause') {
      audioEngine.mp3Player.pause();
      studentStopCallback();
      return;
    }
    if (event.data.player === 'stop') {
      audioEngine.mp3Player.stop();
      studentStopCallback();
      return;
    }
    if (event.data.player === 'position') {
      audioEngine.mp3Player.setPosition(event.data.value);
      return;
    }
    if (event.data.player === 'getPosition') {
      currentPosition = audioEngine.mp3Player.getPosition();
      connection.value.send({ player: 'studentPosition', value: currentPosition });
      return;
    }
    if (event.data.player === 'studentPosition') {
      currentPosition = event.data.value;
      return;
    }
    if (event.data.player === 'streamMp3Volume') {
      audioEngine.streamAudioMixer.setMp3PlayerGain(event.data.value);
      return;
    }
    if (event.data.player === 'streamMicrophoneVolume') {
      audioEngine.streamAudioMixer.setMicrophoneGain(event.data.value);
      return;
    }
    if (event.data.player === 'mute') {
      audioEngine.mp3Player.mute();
      audioEngine.mp3Player.muteStream();
      dispatch('setSingingStudent', false);
      return;
    }
    if (event.data.player === 'unmute') {
      audioEngine.mp3Player.unmute();
      audioEngine.mp3Player.unmuteStream();
      dispatch('setSingingStudent', true);
      return;
    }
    if (event.data.player === 'loadFile') {
      if (event.data.file) {
        studentLoad(event.data.file);
      }
      return;
    }
    if (event.data.player === 'studentLoaded') {
      if (event.data.file) {
        studentLoadedCallback(event.data.file);
      }
      return;
    }

    if (event.data.type === 'loadSharingFile') {
      dispatch('setSharingFile', event.data.file);
    }
    if (event.data.type === 'scrollSharingFile') {
      dispatch('setScrollSharingFile', event.data.value);
    }
  };

  const participant = {
    setMicrophoneGain: (value) => {
      const mes = {
        player: 'streamMicrophoneVolume',
        value: value
      };
      connection.value.send(mes);
    },
    mp3Player: {
      loadFile: (file) => {
        connection.value.send({ player: 'loadFile', file: file });
      },
      setVolume: (value) => {
        const mes = {
          player: 'streamMp3Volume',
          value: value
        };
        connection.value.send(mes);
      },
      mute: () => {
        const mes = {
          player: 'mute'
        };
        connection.value.send(mes);
      },
      unmute: () => {
        const mes = {
          player: 'unmute'
        };
        connection.value.send(mes);
      },
      play: () => {
        const mes = {
          player: 'play'
        };
        connection.value.send(mes);
      },
      pause: () => {
        const mes = {
          player: 'pause'
        };
        connection.value.send(mes);
      },
      stop: () => {
        const mes = {
          player: 'stop'
        };
        connection.value.send(mes);
      },
      setPosition: (value) => {
        currentPosition = value;
        const mes = {
          player: 'position',
          value: value
        };
        connection.value.send(mes);
      },
      getPosition: (value) => {
        const mes = {
          player: 'getPosition'
        };
        connection.value.send(mes);
        return currentPosition;
      }
    }
  };

  const studentLoad = async (file) => {
    await audioEngine.mp3Player.loadFile(file);
    mp3File.value.file = file;
    connection.value.send({ player: 'studentLoaded', file: mp3File.value.file });
  };

  return {
    init,
    setLoadedCallback,
    setConnectedCallback,
    studentConnected,
    studentDisconnected,
    handleMessage,
    isStudentConnected,
    setStudentPlayCallback,
    setStudentStopCallback,
    participant
  };
})();

export default rtcDataConnection;
