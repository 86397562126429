const SOCKET_EVENTS = {
  SINGING_STUDENT: 'singingStudent',
  CLOSE_ROOM: 'closeRoom',
  VIDEO_MIRROR: 'videoMirror',
  VIDEO_MUTE: 'videMute',
  SET_LITE_CALL: 'setLiteCall',
  CHANGE_PARTICIPANTS: 'changeParticipants',
  SET_MOBILE: 'setMobile',
  ON_CONNECTED: 'onConnected'
};

export default SOCKET_EVENTS;
