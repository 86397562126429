<template>
  <div class="lp-file-upload-modal">
    <Modal
      title="myFiles.cancelUploadTitle"
      v-model:open="openModal"
    >
      <template #default>
        <div
          class="lp-file-upload-modal__modal"
          :class="{'lp-file-upload-modal__modal_mobile': isMobile}"
        >
          {{ $t('myFiles.cancelUploadText') }}
        </div>
      </template>
      <template #footer>
        <div class="lp-file-upload-modal__footer">
          <button
            class="lp-button lp-button_small lp-button_thin"
            @click="$emit('close');"
          >
            {{ $t('myFiles.cancelUpload') }}
          </button>
          <button
            class="lp-button lp-button_small"
            @click="openModal = false"
          >
            {{ $t('myFiles.continueUpload') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'FileUploadModal',
  components: { Modal },
  props: {
    open: Boolean
  },
  emits: ['update:open', 'close'],
  setup (props, { emit }) {
    const openModal = ref(false);
    watch(() => props.open, () => {
      openModal.value = props.open;
    });
    watch(openModal, () => emit('update:open', openModal.value));

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return { openModal, isMobile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-upload-modal {
  &__modal {
    display: flex;
    align-items: center;
    padding: 20px 100px 0 20px;

    &_mobile {
      padding: 20px 20px 0;
      max-width: calc(100vw - 40px);
      margin: 0 auto;
      text-align: center;
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: auto auto;
    align-items: end;
    grid-gap: 20px;
  }
}

</style>
