import chartTooltip from '@/constants/utils/CRM/chartTooltip';
import { reactive } from 'vue';

export default reactive({
  chart: {
    id: 'chart-expense',
    toolbar: { show: false },
    zoom: { enabled: false }
  },
  states: {
    active: {
      filter: {
        type: 'none' /* none, lighten, darken */
      }
    },
    hover: {
      filter: {
        type: 'darken',
        value: 0.75
      }
    }
  },
  stroke: {
    curve: 'smooth',
    width: 0
  },
  fill: {
    type: 'solid',
    opacity: 1
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    custom: chartTooltip,
    theme: false,
    x: { show: false },
    marker: { show: false }
  },
  dataLabels: { enabled: false }
});
