<template>
  <div class="lp-microphone-settings-popup">
    <div
      class="lp-microphone-settings-popup-body"
      :class="{'lp-microphone-settings-popup-body_mobile': mobile}"
    >
      <div class="lp-microphone-settings-popup-body-item lp-microphone-settings-popup-body-item_volume">
        <div
          class="lp-microphone-settings-popup-body-item__title"
          :class="{'lp-microphone-settings-popup-body-item__title_mobile': isMobile}"
        >
          {{ $t('roomSettings.sound.volumeVoice') }}
        </div>
        <label
          class="lp-microphone-settings-popup-body-item__range vue-slider-gray"
          :class="{'vue-slider-gray_mobile': isMobile}"
        >
          <VueSlider
            v-model="remoteVolume"
            :min="gainRange.min"
            :max="gainRange.max"
            :interval="gainRange.step"
            :tooltip="'none'"
            :dotSize="isMobile ? 24 : 16"
            :height="isMobile ? 24 : 15"
            :duration="0.2"
            :lazy="true"
            dragOnClick
            @change="changeRemoteVolume"
          />
        </label>
        <div
          class="lp-microphone-settings-popup-body-item__value"
          :class="{'lp-microphone-settings-popup-body-item__value_mobile': isMobile}"
        >
          {{ Math.round((remoteVolume / 1) * 100) }}%
        </div>
      </div>
      <div
        class="lp-microphone-settings-popup-body-item lp-microphone-settings-popup-body-item_volume"
        v-if="!liteVersion"
      >
        <div
          class="lp-microphone-settings-popup-body-item__title"
          :class="{'lp-microphone-settings-popup-body-item__title_mobile': isMobile}"
        >
          {{ $t('roomSettings.sound.volumePlayer') }}
        </div>
        <label
          class="lp-microphone-settings-popup-body-item__range vue-slider-gray"
          :class="{'vue-slider-gray_mobile': isMobile}"
        >
          <VueSlider
            v-model="audioSettings.playerVolume"
            :min="gainRange.min"
            :max="gainRange.max"
            :interval="gainRange.step"
            :tooltip="'none'"
            :dotSize="isMobile ? 24 : 16"
            :height="isMobile ? 24 : 15"
            :duration="0.2"
            :lazy="true"
            dragOnClick
            @change="changePlayerVolume"
          />
        </label>
        <div
          class="lp-microphone-settings-popup-body-item__value"
          :class="{'lp-microphone-settings-popup-body-item__value_mobile': isMobile}"
        >
          {{ Math.round((audioSettings.playerVolume / 1) * 100) }}%
        </div>
      </div>

      <!-- open calibration -->
      <div
        class="lp-microphone-settings-popup-body-item__open-delay"
        :class="{'lp-microphone-settings-popup-body-item__open-delay_mobile': isMobile}"
        v-if="isTeacher"
      >
        <MicrophoneOpenCalibration />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, onBeforeMount, ref, watch, watchEffect } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import MicrophoneOpenCalibration
  from '@/components/VideoCall/VideoCallSettings/MicrophoneSettings/MicrophoneOpenCalibration';
import ROLE_TYPES from '@/constants/enums/roles';

export default {
  name: 'MicrophoneSettingsPopup',
  components: {
    MicrophoneOpenCalibration,
    VueSlider
  },
  props: {
    open: Boolean,
    mobile: Boolean
  },
  setup (props) {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);
    const audioSettings = computed(() => store.getters.audioSettings);
    const liteVersion = computed(() => store.getters.liteVersion);
    const audioEngine = inject('audioEngine');
    const rtcDataConnection = inject('rtcDataConnection');

    watch(props, () => {
      if (!props.open) showDevices.value = false;
    });

    // for device
    const showDevices = ref(false);
    const microphoneDevices = ref([]);
    const currentMicrophone = ref(null);
    const setDefaultMicrophone = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      microphoneDevices.value = devices.filter(d => d.kind === 'audioinput');
      currentMicrophone.value = microphoneDevices.value[0];
    };

    onBeforeMount(() => {
      navigator.mediaDevices.addEventListener('devicechange', setDefaultMicrophone);
      setDefaultMicrophone();
    });

    const setDevice = () => {
      if (!currentMicrophone.value) return;
      // TODO выбор микрофона (изменить устройство)
      audioSettings.value.deviceId = currentMicrophone.value.deviceId;
    };
    watchEffect(setDevice);

    // for volumes
    const gainRange = {
      min: 0,
      max: 1.0,
      step: 0.01
    };

    const remoteVolume = ref(1);
    const changeRemoteVolume = (value) => {
      console.log('NEW Remote volume: ' + value);
      audioSettings.value.microphoneRemoteVolume = value;
      // TODO изменить громкость голоса собеседника
      rtcDataConnection.participant.setMicrophoneGain(value);
    };

    const remoteMusic = ref(1);
    const changeRemoteMusic = (value) => {
      console.log('NEW Remote Music volume: ' + value);
      audioSettings.value.musicRemoteVolume = value;
      rtcDataConnection.participant.mp3Player.setVolume(value);
    };

    const changePlayerVolume = (val) => {
      console.log('NEW Player Volume: ' + val);
      // audioSettings.playerVolume = val;
      // audioEngine.mp3Player.setVolume(val);
      audioSettings.value.playerVolume = val;
      rtcDataConnection.participant.mp3Player.setVolume(val);
      audioEngine.mp3Player.setVolume(val);
    };

    const route = useRoute();
    const openDelaySetting = () => {
      store.dispatch('setShowDelaySetting', true);
      localStorage.setItem('calibration_room_id', route.params.roomId);
    };

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      liteVersion,
      openDelaySetting,
      gainRange,
      changeRemoteVolume,
      remoteVolume,
      showDevices,
      currentMicrophone,
      microphoneDevices,
      remoteMusic,
      changeRemoteMusic,
      audioSettings,
      changePlayerVolume,
      isTeacher
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-microphone-settings-popup {
  box-sizing: border-box;
  color: $color-text;

  &-body {
    display: grid;
    justify-content: end;
    white-space: nowrap;

    &_mobile {
      grid-gap: 8px;
      justify-content: stretch;
    }

    &_mobile &-item {
      grid-template-columns: 1fr auto;
    }

    &-item {
      display: grid;
      grid-gap: 0 16px;
      grid-template-columns: 145px auto;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;
      border-radius: 3px;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-oslo-gray;
      transition: 0.3s ease-in-out;
      user-select: none;
      border-bottom: 1px solid $color-alto;

      &_volume {
        padding-bottom: 0;
        border-bottom: none;
      }

      &:last-child {
        border-bottom: none;
      }

      &__title {
        &_mobile {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
        }
      }

      &__range {
        grid-row: 2;
        grid-column: 1;
      }

      &__value {
        grid-row: 2;
        grid-column: 2;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        min-width: 30px;

        &_mobile {
          padding-top: 8px;
          font-size: 14px;
          line-height: 19px;
        }
      }

      &__open-delay {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $color-white;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        transition: opacity 300ms ease-in-out;

        &_mobile {
          font-size: 14px;
          line-height: 19px;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &-select {
        position: relative;
        display: grid;
        grid-template-columns: auto 20px;
        align-items: center;
        cursor: pointer;
        margin-right: -6px;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          width: 20px;
          height: 20px;
          transform: rotate(180deg);
          transition: transform 0.2s ease-in-out;

          &_open {
            transform: rotate(0);
          }
        }
      }
    }
  }

  &__background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-microphone-settings-popup-body-item__range {
  .vue-slider-dot-handle {
    background-color: $color-outer-space;
    box-shadow: none;
    margin: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
}

</style>
