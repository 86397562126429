<template>
  <Modal
    :title="''"
    @save="closeForm"
    :saveBtnText="'buttons.ok'"
    :hideButtons="['remove']"
    v-model:open="openModal"
    :editMode="true"
    :mobileVersion="isMobile"
  >
    <div
      class="lp-reforming-timetable-modal"
      :class="{'lp-reforming-timetable-modal_mobile': isMobile}"
    >
      <img
        class="lp-reforming-timetable-modal__img"
        :class="{'lp-reforming-timetable-modal__img_mobile': isMobile}"
        :src="mobileCalendar"
        alt="placeholder"
      />
      <div class="lp-reforming-timetable-modal__title">
        {{ $t(description1) }}
      </div>
      <div class="lp-reforming-timetable-modal__title">
        {{ $t(description2) }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import mobileCalendar from '@/assets/images/mobile-CRM-calendar.png';
import { computed, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';
import { useRouter } from 'vue-router';

export default {
  name: 'ReformingTimetableModal',
  components: { Modal },
  props: {
    open: Boolean,
    isFirst: Boolean,
    description1: String,
    description2: String
  },
  emits: ['close'],
  setup (props, { emit }) {
    const router = useRouter();

    const openModal = ref(false);
    watch(() => props.open, (val) => {
      openModal.value = val;
    });

    const closeForm = () => {
      openModal.value = false;
      emit('close');
      if (props.isFirst) {
        router.push({ name: 'CRMLessons', params: { unit: 'month', isFirst: true } });
      }
    };
    watch(openModal, (val) => {
      if (!val) closeForm();
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      closeForm,
      mobileCalendar,
      openModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-reforming-timetable-modal {
  padding: 50px 200px 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-text;

  &_mobile {
    padding: 0 20px;
    margin: calc(50vh - 250px ) 0 0;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 355px;
    margin-bottom: 32px;

    &_mobile {
      max-width: 247px;
      margin: 0 auto 32px;
    }
  }

  &__title {
    margin: 0 auto 10px;
    max-width: 355px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
  }
}

</style>
