<template>
  <div
    class="lp-calendar-add-lesson"
    :class="[
      'lp-calendar-add-lesson_' + type,
      {
        'lp-calendar-add-lesson_compact': compact,
        'lp-calendar-add-lesson_fixed': fixed
      }
    ]"
    @click.stop="openForm = true"
  >
    <button class="lp-calendar-add-lesson-button">
      <template v-if="type === 'month'">
        <div class="lp-calendar-add-lesson-button__plus">
          <addLessonIcon />
        </div>
      </template>
      <template v-else>
        <span class="lp-calendar-add-lesson-button__icon">
          <addLessonIcon />
        </span>
        <span>
          {{ $t('crm.lessons.addLessons') }}
        </span>
      </template>
    </button>
    <LessonForm
      :openForm="openForm"
      :date="date"
      v-model:openForm="openForm"
    />
  </div>
</template>

<script>
import { addLessonIcon } from '@/constants/icons/crm';
import LessonForm from '@/components/CRM/Lessons/Calendar/CalendarLessonForm/LessonForm';
import { ref } from 'vue';

export default {
  name: 'LessonAddButton',
  components: {
    LessonForm,
    addLessonIcon
  },
  props: {
    compact: Boolean,
    date: String,
    type: String,
    fixed: Boolean
  },
  emits: ['get-lessons'],
  setup () {
    const openForm = ref(false);

    return {
      openForm
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-add-lesson {
  @include global-font;
  $this: &;
  width: 100%;
  height: 100%;

  &_week {
    #{$this}-button {
      padding: 48px 0;
    }
  }

  &_day {
    #{$this}-button {
      padding: 11px 0;
    }
  }

  &_month {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    #{$this}-button {
      width: 100%;
      height: 100%;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: $color-accent;
    border: 3px solid $color-french-lilac;
    box-sizing: border-box;
    cursor: pointer;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }

    &__plus {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 38px;
      height: 38px;
      background-color: $color-accent;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: $color-white
        }
      }
    }
  }

  &_compact {
    position: absolute;
    top: auto;
    bottom: -16px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 0);
    width: 32px;
    height: 32px;
    z-index: 9;

    #{$this}-button {
      height: auto;
      width: auto;
      border: none;

      &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: $color-accent;

        svg {
          width: 12px;
          height: 12px;

          path {
            fill: $color-white
          }
        }
      }
    }
  }

  &_fixed {
    position: fixed;
    left: auto;
    top: auto;
    right: 16px;
    bottom: 26px;
    width: 56px;
    height: 56px;
    transform: none;

    #{$this}-button {
      &__plus {
        width: 56px;
        height: 56px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

</style>
