<template>
  <div class="lp-chart-lessons">
    <div
      class="lp-chart-lessons-header"
      :class="{'lp-chart-lessons-header_mobile': isMobile}"
    >
      <div class="lp-chart-lessons-header__title">
        {{ $t('crm.analytics.lessons') }}
      </div>
      <div class="lp-chart-lessons-header__counter">
        <Vue3Autocounter
          :startAmount="0"
          :endAmount="total"
          :duration="1"
          :decimals="0"
        />
      </div>
    </div>
    <div
      class="lp-chart-lessons-body"
      :class="{'lp-chart-lessons-body_mobile': isMobile}"
    >
      <VueApexCharts
        type="line"
        :height="mobile ? heightChartMobile : 330"
        :width="widthChart"
        :options="chartOptions"
        :series="[{name: 'lessons', data: lessons}]"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import chartLessons from '@/constants/crm/chartLessons';
import { computed } from 'vue';
import Vue3Autocounter from 'vue3-autocounter';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ChartLessons',
  components: { VueApexCharts, Vue3Autocounter },
  props: {
    mobile: Boolean,
    total: Number,
    dates: Array,
    lessons: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const oneColumn = computed(() => window.innerWidth < 1800);
    const hasTable = computed(() => window.innerWidth <= 1440);
    const hasMobile = computed(() => window.innerWidth <= 768);
    const chartOptions = computed(() => ({
      ...chartLessons,
      xaxis: {
        categories: props.dates,
        labels: {
          style: { cssClass: props.dates.length > 7 ? 'chart-label_short' : 'chart-label' },
          rotate: props.mobile && props.dates.length > 7 ? -45 : 0,
          rotateAlways: props.mobile && props.dates.length > 7
        },
        tooltip: { enabled: false },
        crosshairs: {
          show: true,
          width: 1,
          position: 'back',
          opacity: 1,
          stroke: {
            color: '#E0E0E0',
            width: 1,
            dashArray: 0
          }
        }
      }
    }));

    const heightChartMobile = computed(() => {
      const vh = window.innerHeight * 0.01;
      return ((vh * 100) - 250) / 2;
    });

    const widthChart = computed(() => {
      const vw = window.innerWidth * 0.01;
      if (oneColumn.value && !hasTable.value) {
        return ((vw * 100) - 2 * 226);
      } else if (oneColumn.value && hasTable.value && !hasMobile.value) {
        return ((vw * 100) - 113);
      } else if (oneColumn.value && hasTable.value && hasMobile.value) {
        return ((vw * 100) - 32);
      } else {
        return (((vw * 100) / 2) - 226);
      }
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      widthChart,
      heightChartMobile,
      chartOptions
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-chart-lessons {
  width: 50%;
  margin-bottom: 30px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;

    &_mobile {
      padding: 0 16px;
    }

    &__title {
      margin-right: 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-mine-shaft-dark;
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 12px;
      border-radius: 5px;
      background-color: $color-moon-raker;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-accent;
    }
  }

  &-body {
    &_mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px 0 6px;
    }
  }

  @media screen and (max-width: 1800px) {
    width: auto;
  }
}

</style>
