<template>
  <div class="lp-delay-select-device">
    <div
      class="lp-delay-select-device__title"
      :class="{'lp-delay-select-device__title_mobile': isMobile}"
    >
      {{ $t('delaySettings.selectDevice') }}
    </div>
    <div
      class="lp-delay-select-device-list"
      :class="{'lp-delay-select-device-list_mobile': isMobile}"
    >
      <div
        class="lp-delay-select-device-list-item"
        :class="{'lp-delay-select-device-list-item__active': device.id === selectDevice}"
        v-for="device of devices"
        :key="device.id"
        @click="selectDevice = device.id"
      >
        <img
          class="lp-delay-select-device-list-item__img"
          :src="device.icon"
          alt=""
        />
        <div class="lp-delay-select-device-list-item__title">
          {{ device.title }}
        </div>
      </div>
    </div>
    <div class="lp-delay-select-device__footer">
      <button
        class="lp-button"
        @click="$emit('selected', selectDevice)"
      >
        {{ $t('buttons.continue') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import speakersIcon from '@/assets/images/calibrationPlaceholders/devicesShort/speakers.svg';
import headphonesIcon from '@/assets/images/calibrationPlaceholders/devicesShort/headphones.svg';
import headphonesWirelessIcon from '@/assets/images/calibrationPlaceholders/devicesShort/headphonesWireless.svg';
import headphonesMobileIcon from '@/assets/images/calibrationPlaceholders/devicesShort/headphonesMobile.svg';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'DelaySelectDevice',
  setup () {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    const { t } = useI18n();
    const devices = [
      {
        id: 'speakers',
        title: t('delaySettings.devices.speakers'),
        icon: speakersIcon
      },
      {
        id: 'headphones',
        title: t('delaySettings.devices.headphones'),
        icon: headphonesIcon
      },
      {
        id: 'headphonesWireless',
        title: t('delaySettings.devices.headphonesWireless'),
        icon: headphonesWirelessIcon
      },
      {
        id: 'headphonesMobile',
        title: t('delaySettings.devices.headphonesMobile'),
        icon: headphonesMobileIcon
      }
    ];

    const selectDevice = ref(devices[0].id);

    return {
      devices,
      selectDevice,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-delay-select-device {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
    margin: 48px auto 32px;
    text-align: center;

    &_mobile {
      font-size: 18px;
      margin: 24px auto 18px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(4, 216px);
    gap: 24px;
    align-items: center;
    justify-content: center;
    margin-bottom: 48px;

    &_mobile {
      grid-template-columns: repeat(auto-fill, 168px);
      gap: 8px;
      margin-bottom: 24px;
    }

    &_mobile &-item {
      min-width: 168px;
      min-height: 193px;

      &__title {
        font-size: 14px;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 216px;
      min-height: 271px;
      border: 3px solid $color-alabaster;
      border-radius: 8px;
      background-color: $color-white;
      cursor: pointer;

      &__active {
        border-color: $color-fog;
      }

      &:hover &__img,
      &__active &__img {
        mix-blend-mode: normal;
      }

      &:hover &__title,
      &__active &__title {
        color: $color-black;
      }

      &__img {
        width: 120px;
        height: auto;
        object-fit: contain;
        margin: 0 auto 24px;
        mix-blend-mode: luminosity;
        transition: mix-blend-mode 200ms ease;
      }

      &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 125%;
        text-align: center;
        color: $color-gray;
        transition: color 200ms ease;
      }
    }
  }

  &__footer {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
