<template>
  <div
    class="lp-timetable-students__all"
    :class="{'lp-timetable-edit-lesson__all_mobile': isMobile}"
  >
    <div
      v-for="student in item.crmLessons"
      :key="student._id"
      class="lp-timetable-students__student"
      @click="() => student.crmStudentDeleted ? false : goToCardStudent(student)"
    >
      <img
        v-if="student.uploadPhotoUrl"
        class="lp-timetable-students__image"
        :src="student.uploadPhotoUrl"
        alt="avatar"
      >
      <userIcon
        v-else
        class="lp-timetable-students__image"
      />
      <div class="lp-timetable-students__name">
        {{ student.crmStudentFullName }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { userIcon } from '@/constants/icons';

export default {
  name: 'AllStudents',
  components: {
    userIcon
  },
  props: {
    item: Object,
    isMobile: Boolean
  },
  setup () {
    const router = useRouter();

    const goToCardStudent = (student) => {
      router.push({ name: 'CRMStudents', query: { crmStudentId: student.crmStudentId } });
    };

    return {
      goToCardStudent
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-students {
  &__all {
    position: absolute;
    top: -30px;
    left: 15px;
    width: 250px;
    padding: 10px;
    max-height: 300px;
    overflow: auto;
    border-radius: 4px;
    color: $color-black;
    background-color: $color-white;
    box-shadow: 0 0 20px rgb(0 0 0 / 9%);
    z-index: 2;

    &_mobile {
      width: 220px;
      left: -115px;
      top: -60px;
    }
  }

  &__student {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 7px;
  }

  &__name {
    margin-left: 6px;
    font-size: 14px;
    font-weight: normal;
    word-break: break-word;
  }

  &__image {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid $color-white;

    &_sm {
      width: 18px;
      height: 18px;
      border: none;
    }
  }
}

</style>