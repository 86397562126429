<template>
  <div
    class="lp-file-upload-status"
    ref="statusEl"
  >
    <uploadedIcon v-if="status === FILE_STATUS.UPLOADED" />
    <LPTooltip
      :text="$t(error)"
      :position="'right'"
      v-if="status === FILE_STATUS.ERROR"
    >
      <errorUploadedIcon />
    </LPTooltip>
    <div
      class="lp-file-upload-status-uploading"
      v-if="status === FILE_STATUS.UPLOADING || status === FILE_STATUS.PRE_UPLOADED"
    >
      <span
        class="lp-file-upload-status-uploading__progress"
        :style="{width: `${progress}%`}"
      />
    </div>
  </div>
</template>

<script>
import { errorUploadedIcon, uploadedIcon } from '@/constants/icons/files';
import FILE_STATUS from '@/constants/enums/fileStatus';
import { ref, watch } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'FileUploadStatus',
  components: {
    LPTooltip,
    uploadedIcon,
    errorUploadedIcon
  },
  props: {
    modal: Boolean,
    error: String,
    status: Number,
    size: Number
  },
  setup (props) {
    const progress = ref(0);
    const changeProgress = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnetion;
      const downlink = Math.round(Number(connection ? connection.downlink : 10));
      const size = Math.round(Number(props.size / 125));
      const timeForUpload = Math.round(size / downlink);
      const step = 10000 / timeForUpload;
      const timerId = setInterval(() => {
        if (progress.value >= 85) {
          clearInterval(timerId);
        } else if (progress.value >= 65) {
          progress.value += step / 2;
        } else {
          progress.value += step;
        }
      }, 100);
    };
    watch(() => props.status, () => {
      const {
        UPLOADING,
        PRE_UPLOADED
      } = FILE_STATUS;
      const { status } = props;
      if (status === UPLOADING) {
        changeProgress();
      } else if (status === PRE_UPLOADED) {
        progress.value = 100;
      }
    });

    const statusEl = ref(null);
    const getPosition = () => {
      if (!statusEl.value) return {};
      const position = statusEl.value.getBoundingClientRect();
      const rightModal = `${window.innerWidth - position.right - 22}px`;
      return {
        position: 'fixed',
        top: `${5 + position.top + position.height}px`,
        right: props.modal ? rightModal : '30px'
      };
    };

    return {
      statusEl,
      progress,
      getPosition,
      FILE_STATUS
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-upload-status {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 17px;

  &-uploading {
    position: relative;
    width: 105px;
    height: 6px;
    background: $color-moon-raker;
    border-radius: 50px;
    overflow: hidden;

    &__progress {
      position: absolute;
      height: 100%;
      width: 50%;
      background: $color-accent;
      border-radius: 52px;
      transition: 0.3s;
    }
  }
}

</style>
