<template>
  <div class="lp-teacher-card-work">
    <div
      class="lp-teacher-card-work-item"
      v-for="work of works"
      :key="work.id"
    >
      <div class="lp-teacher-card-work-item__date">
        {{ work.date }}
      </div>
      <div class="lp-teacher-card-work-item__position">
        {{ work.position }}
      </div>
      <div class="lp-teacher-card-work-item__company">
        {{ work.company }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'TeacherCardWork',
  props: {
    teacher: Object
  },
  setup (props) {
    const works = computed(() => props.teacher.works);

    return {
      works
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-teacher-card-work {
  padding: 28px 48px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &-item {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-gap: 4px 25px;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    &__date {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
    }

    &__position {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__company {
      grid-column: 2;
      grid-row: 2;
      font-weight: bold;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;
      text-transform: capitalize;
    }

  }
}

</style>
