<template>
  <label
    class="label"
    :class="{mobileLabel: isMobile}"
    @click.prevent="$emit('toggle-checkbox', value)"
  >
    <input
      type="checkbox"
      class="checkbox"
      :checked="isChecked"
    >
    <svg
      :width="widthSvg"
      :height="heightSvg"
      class="svgIcon"
      :class="{visible: isChecked}"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.00012L4 6.00012L9.00024 1"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>

    <span
      class="fake"
      :class="{mobileCheckbox: isMobile, active: isChecked}"
    ></span>
    <span class="text">{{ text }}</span>
  </label>
</template>

<script>
import { computed } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'AppCheckbox',
  props: {
    text: {
      type: String
    },
    value: {
      type: String
    },
    isChecked: {
      type: Boolean
    }
  },
  emits: ['toggle-checkbox'],
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const widthSvg = computed(() => isMobile.value ? '12' : '10');
    const heightSvg = computed(() => isMobile.value ? '10' : '8');

    return {
      widthSvg,
      heightSvg,
      isMobile
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.label {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  position: relative;
  cursor: pointer;

  .svgIcon {
    position: absolute;
    top: 6px;
    left: 3px;
    transition: opacity 0.2s ease-in;
    opacity: 0;

    &.visible {
      transition: opacity 0.2s ease-in;
      opacity: 1;
    }
  }
}
.label.mobileLabel {
  align-items: flex-start;

  .svgIcon {
    left: 4px;
  }
}

.checkbox {
  display: none;
}

.fake {
  flex: 0 0 16px;
  display: inline-block;
  border: 1px solid $color-purple;
  height: 16px;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color 0.2s ease-in;

  &.active {
    background-color: $color-purple;
    transition: background-color 0.2s ease-in;
  }
}
.fake.mobileCheckbox {
  flex: 0 0 20px;
  height: 20px;
}

</style>
