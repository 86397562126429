<template>
  <div
    class="lp-delay-process"
    :class="{'lp-delay-process_mobile': isMobile}"
  >
    <div
      class="lp-delay-process-instruction"
      v-if="!error && !progress"
    >
      <img
        class="lp-delay-process-instruction__img"
        :src="deviceIcon"
        alt=""
      />
      <div class="lp-delay-process-instruction-text">
        <div class="lp-delay-process-instruction-text__title">
          {{ $t('delaySettings.device') }}: {{ $t(`delaySettings.devices.${device}`) }}
        </div>
        <div class="lp-delay-process-instruction-text-list">
          <div
            class="lp-delay-process-instruction-text-list-item"
            v-for="(step, index) of $tm(`delaySettings.instruction`)[device]"
            :key="step"
          >
            <div class="lp-delay-process-instruction-text-list-item__index">
              0{{ index + 1 }}
            </div>
            <div class="lp-delay-process-instruction-text-list-item__text">
              {{ step }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="lp-delay-process-content"
      v-else
    >
      <img
        class="lp-delay-process-content__img"
        :src="deviceIcon"
        alt=""
      />
      <div
        class="lp-delay-process-content-error"
        v-if="error"
      >
        <div class="lp-delay-process-content-error__title">
          <circleCross />
          {{ $t('delaySettings.errors.title') }}
        </div>
        <span
          class="lp-delay-process-content-error__text"
          v-html="error"
        />
      </div>
      <div
        class="lp-delay-process-content-progress"
        v-else-if="progress"
      >
        <div class="lp-delay-process-content-progress-title">
          <transition name="bounce">
            <div
              class="lp-delay-process-content-progress-title__icon"
              v-if="success"
            >
              <circleCheck />
            </div>
          </transition>
          {{ $t(success ? 'delaySettings.success' : 'delaySettings.process') }}
        </div>
        <div class="lp-delay-process-content-progress-track">
          <div
            class="lp-delay-process-content-progress-track__bar"
            :style="{width: `${progress}%`}"
          />
        </div>
        <div class="lp-delay-process-content-progress__number">
          {{ numeral(progress).format('00') }} %
        </div>
      </div>
    </div>
    <div
      class="lp-delay-process-footer"
      :class="{
        'lp-delay-process-footer_single': (progress || success) && !error,
        'lp-delay-process-footer_error': error
      }"
    >
      <template v-if="!progress && !error && !success">
        <button
          class="lp-button lp-button_thin"
          @click="$emit('back')"
        >
          {{ $t('delaySettings.back') }}
        </button>
        <button
          class="lp-button"
          @click="$emit('start')"
        >
          {{ $t('delaySettings.start') }}
        </button>
      </template>
      <template v-else-if="!error && !success && progress">
        <button
          class="lp-button lp-button_border"
          @click="$emit('stop')"
        >
          {{ $t('delaySettings.stop.button') }}
        </button>
      </template>
      <template v-else-if="error">
        <button
          class="lp-button lp-button_thin"
          @click="$emit('stop')"
        >
          {{ $t('delaySettings.errors.stop') }}
        </button>
        <button
          class="lp-button"
          @click="$emit('repeat')"
        >
          <div class="lp-delay-process-footer__icon">
            <repeatDelayIcon />
          </div>
          {{ $t('delaySettings.errors.again') }}
        </button>
      </template>
      <template v-else-if="success">
        <button
          class="lp-button"
          @click="$emit('next', false)"
        >
          {{ $t('buttons.continue') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import speakersIcon from '@/assets/images/calibrationPlaceholders/devicesFull/speakers.svg';
import headphonesIcon from '@/assets/images/calibrationPlaceholders/devicesFull/headphones.svg';
import headphonesWirelessIcon from '@/assets/images/calibrationPlaceholders/devicesFull/headphonesWireless.svg';
import headphonesMobileIcon from '@/assets/images/calibrationPlaceholders/devicesFull/headphonesMobile.svg';
import { computed } from 'vue';
import { circleCheck, circleCross, repeatDelayIcon } from '@/constants/icons/delaySettings';
import numeral from 'numeral';
import { useStore } from 'vuex';

export default {
  name: 'DelayProgress',
  components: {
    circleCross,
    circleCheck,
    repeatDelayIcon
  },
  props: {
    device: String,
    error: String,
    progress: Number,
    success: Boolean
  },
  setup (props) {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    const devices = {
      speakers: {
        icon: speakersIcon
      },
      headphones: {
        icon: headphonesIcon
      },
      headphonesWireless: {
        icon: headphonesWirelessIcon
      },
      headphonesMobile: {
        icon: headphonesMobileIcon
      }
    };

    const deviceIcon = computed(() => devices[props.device].icon);

    return {
      numeral,
      isMobile,
      deviceIcon
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/style';

.lp-delay-process {

  &_mobile &-instruction {
    margin: 20px 0 36px;
    flex-direction: column;

    &__img {
      width: calc(100vw - 46px);
      margin: 0 auto 36px;
    }

    &-text {
      &__title {
        margin-bottom: 20px;
        text-align: center;
      }

      &-list {
        gap: 20px;
        max-width: calc(100vw - 40px);

        &-item {
          max-width: 100%;

          &__text {
            font-size: 16px;
          }
        }
      }
    }
  }

  &_mobile &-content {
    &-error {
      max-width: calc(100vw - 40px);
      text-align: center;

      &__title {
        font-size: 17px;
      }
    }

    &-progress {
      padding: 0 20px;
      margin-bottom: 40px;

      &-title {
        font-size: 17px;
      }
    }
  }

  &_mobile &-footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    flex-wrap: wrap;
    max-width: calc(100vw - 40px);
    gap: 20px;
    margin: 0 auto 28px;
  }

  &-instruction {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 73px 0;

    &__img {
      width: 400px;
      height: auto;
      object-fit: contain;
      margin-right: 40px;
    }

    &-text {
      &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        margin-bottom: 32px;
      }

      &-list {
        display: grid;
        gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;

        &-item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 16px;
          align-items: flex-start;
          max-width: 358px;

          &__index {
            color: $color-accent;
            font-weight: 700;
            font-size: 18px;
            line-height: 125%;
          }

          &__text {
            font-weight: 500;
            font-size: 18px;
            line-height: 125%;
            color: $color-mine-shaft;
          }
        }
      }
    }
  }

  &-content {
    display: grid;
    align-items: center;
    justify-content: stretch;

    &__img {
      width: 340px;
      height: auto;
      object-fit: contain;
      margin: 47px auto 35px;
    }

    &-error {
      display: grid;
      gap: 16px;
      align-items: center;
      justify-content: center;
      max-width: 577px;
      margin: 0 auto 30px;

      &__title {
        display: grid;
        grid-template-columns: 28px auto;
        gap: 8px;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        color: $color-mine-shaft;
      }

      &__text {
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
      }
    }

    &-progress {
      display: grid;
      grid-template-columns: 1fr 45px;
      grid-gap: 8px;
      align-items: center;
      padding: 0 50px;
      margin-bottom: 46px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 1/span 2;
        text-align: center;
        margin-bottom: 28px;
        font-weight: 700;
        font-size: 20px;
        line-height: 125%;
        color: $color-mine-shaft;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
      }

      &-track {
        position: relative;
        background: $color-moon-raker;
        border-radius: 50px;
        height: 12px;
        width: 100%;
        overflow: hidden;

        &__bar {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $color-accent;
          border-radius: 50px;
          height: 100%;
          transition: width 10ms linear;
        }
      }

      &__number {
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        color: $color-mine-shaft;
      }
    }
  }

  &-footer {
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 23px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 28px;

    &_single {
      grid-template-columns: auto;
    }

    &_error {
      .lp-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }

    &__icon {
      display: inline-grid;
      height: 16px;
      width: 16px;
      margin-right: 4px;
      margin-bottom: 2px;

      svg {
        width: auto;
        height: 16px;
      }
    }
  }
}
</style>
