import axios from 'axios';
import { MUSIO_SERVER } from '@/constants/domains';

const instance = axios.create({
  baseURL: `${MUSIO_SERVER}crm`
});

export default {
  getBalance (studentId) {
    return instance.get(`students/balance/${studentId}`);
  },
  getStudentById (studentId) {
    return instance.get(`students/getone/${studentId}`);
  },
  getCompletedLessonsByStudent (studentId) {
    const query = [
      'crmLessonStatus=Complete',
      `sort=${JSON.stringify({ crmLessonStart: -1 })}`
    ].join('&');
    return instance.get(`lessons/getall/status/${studentId}?${query}`);
  },
  getPendingLessonsByStudent (studentId, startDate = undefined) {
    const query = [
      'crmLessonStatus=Pending',
      `sort=${JSON.stringify({ crmLessonStart: 1 })}`,
      `crmLessonStart=${startDate}`
    ].join('&');
    return instance.get(`lessons/getall/status/${studentId}?${query}`);
  },
  getLessonsForCalendar (params) {
    return instance.post('group-lessons/getall/student/filterbydate', params);
  },
  getPaymentsByStudentId (studentId) {
    return instance.get(`payments/student/${studentId}`);
  },
  getTeacherSettingUnauthorized (teacherId) {
    return instance.get('settings/unauthorized', { params: { teacherId } });
  }
};
