<template>
  <div class="lp-connect-room">
    <div
      class="lp-connect-room__text"
      :class="{'lp-connect-room__text_mobile': isMobile}"
    >
      {{ $t(!waitingTeacher ? 'lessonCall.ready' : 'lessonCall.waiting') }}
    </div>
    <button
      @click="connect"
      :disabled="waitingTeacher"
      class="lp-button lp-connect-room__button"
      :class="{'lp-connect-room__button_mobile': mobile}"
    >
      {{ $t('lessonCall.connect') }}
    </button>
  </div>
</template>

<script>
import { computed } from 'vue';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { useStore } from 'vuex';

export default {
  name: 'WaitingRoomConnectButton',
  props: {
    mobile: Boolean,
    waitingTeacher: Boolean
  },
  emits: ['connect'],
  setup (props, { emit }) {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    const connect = () => {
      if (props.waitingTeacher) return;
      emit('connect');
      yandexTracker.callConnection();
    };

    return {
      connect,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-connect-room {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &__text {
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;

    &_mobile {
      @media (orientation: landscape) {
        display: none;
      }
    }
  }

  &__button {
    margin: 18px 28px 0;

    &_mobile {
      margin: 18px auto 0;

      @media (orientation: landscape) {
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
      }
    }
  }
}

</style>
