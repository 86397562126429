<template>
  <div v-if="!loading">
    <Modal
      :title="modalTitle"
      @save="saveForm"
      @remove="closeForm"
      :hideButtons="students.length ? [] : (isMobile ? ['save', 'remove'] : ['remove'])"
      :saveBtnText="lesson._id ? 'crm.lessons.form.edit' : 'crm.lessons.form.save'"
      :removeBtnText="'crm.lessons.form.cancel'"
      v-model:open="openModal"
      :mobileVersion="isMobile"
      :editMode="true"
    >
      <div
        class="lp-crm-lessons-form"
        :class="{'lp-crm-lessons-form_mobile': isMobile}"
        v-if="students.length && openModal"
      >
        <Form
          class="lp-crm-lessons-form__body"
          @submit="saveForm"
          @handlerChange="handlerChange"
          @handleDataChange="handleDataChange"
          @check-lesson="checkGroupLesson"
          :inputs="inputs"
          :lesson="lesson"
          :newStudent="newStudent"
          :isTrialLesson="isTrialLesson"
          :emptyList="emptyStudentsList"
        />
      </div>
      <div
        class="lp-crm-lessons-form-empty"
        v-else
      >
        <img
          class="lp-crm-lessons-form-empty__image"
          :class="{'lp-crm-lessons-form-empty__image_mobile': isMobile}"
          :src="imgPlaceholder"
          alt="students"
        >
        <p class="lp-crm-lessons-form-empty__title">
          {{ $t('calendar.empty.emptyStudents') }}
        </p>
        <p class="lp-crm-lessons-form-empty__description">
          {{ $t('calendar.empty.emptyInfo') }}
        </p>
        <button
          class="lp-button"
          @click="saveForm"
          v-if="isMobile"
        >
          {{ $t('crm.lessons.form.save') }}
        </button>
      </div>
    </Modal>
  </div>
  <teleport
    to="#app"
    v-else
  >
    <div class="lp-crm-lessons-form__placeholder">
      <Loader :block="true" />
    </div>
  </teleport>
  <StudentForm
    v-model:open="openStudentForm"
    :student="editableStudent"
    :openForm="openStudentForm"
    @updateStudentsList="updateStudentsList"
    :zIndex="104"
  />
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { computed, onBeforeMount, ref, watch } from 'vue';
import lessonInputs from '@/constants/inputs/lessonInputs';
import { useStore } from 'vuex';
import LessonsApi from '@/api/CRM/lessons';
import StudentApi from '@/api/CRM/students';
import { get, sortBy } from 'lodash';
import moment from 'moment';
import { getErrorText } from '@/constants/utils/setErrorText';
import imgPlaceholder from '@/assets/images/tablePlaceholder/students.png';
import { useRouter } from 'vue-router';
import STUDENT_STATUS from '@/constants/enums/studentStatus';
import Loader from '@/components/Main/Loader/Loader';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import MobileDetect from 'mobile-detect';
import TIME_TABLE_MODAL_REMINDER from '@/constants/enums/timeTable';
import StudentForm from '@/components/CRM/Students/StudentForm/StudentForm';
import { addLessonIcon } from '@/constants/icons/crm';
import { useI18n } from 'vue-i18n';
import validateLesson from '@/constants/validatesForm/validateLesson';

export default {
  name: 'LessonForm',
  components: { Loader, Modal, Form, StudentForm },
  props: {
    date: String,
    openForm: Boolean,
    startTimeOnDay: {
      type: String,
      default: '13:00'
    },
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:openForm'],
  setup (props, { emit }) {
    const store = useStore();
    const error = ref('');
    const isTrialLesson = ref(false);

    const lessons = computed(() => store.getters.lessons);
    const user = computed(() => store.getters.user);

    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsTable', data);

    const loading = ref(false);
    const setLoaderRun = (data) => loading.value = data;

    const openModal = ref(false);
    watch(openModal, (value) => {
      emit('update:openForm', value);
    });
    watch(() => props.openForm, (value) => {
      error.value = '';
      openModal.value = value;
      !value ? setDefaultValues() : getStudentsList();
      newStudent.value = null;

      if (value && props.lesson.crmLessons) {
        isGroupLesson.value = props.lesson.crmLessons.length > 1;
      }
    });

    watch(lessonInputs, (inputs) => {
      const students = inputs.find(input => input.type === 'groupSelect');
      if (students && students.modelValue && students.modelValue.length) {
        emptyStudentsList.value = false;
      }
    });

    const checkGroupLesson = (val) => {
      isGroupLesson.value = val;
    };

    const isGroupLesson = ref(false);

    const updateBodyLesson = (body) => {
      const { dates, repeatEnd, repeatRate, crmLessonEnd, crmLessonStart, crmStudentId, ...all  } = body;
      const newBody = {
        crmStudents: crmStudentId,
        crmLessonStart: moment(`${dates.date[0]}T${crmLessonStart}`, 'YYYY-MM-DDTHH:mm' ).utc().format(),
        crmLessonEnd: moment(`${dates.date[0]}T${crmLessonEnd}`, 'YYYY-MM-DDTHH:mm' ).utc().format(),
        repeatEndDate: `${repeatEnd.date[0]}T00:00:00Z`,
        repeatRate,
        ...all
      };
      const { repeatEndDate, ...noRepeatBody } = newBody;
      return repeatRate === TIME_TABLE_MODAL_REMINDER.NO_REPEAT.id ? noRepeatBody : newBody;
    };

    const router = useRouter();

    const handlerChange = (data) => {
      if (data && data.repeatRate)  {
        hideRepeatLesson(data);
      } else {
        setDefaultPrice(data);
      }
    };

    const hideRepeatLesson = ({ id }) => {
      lessonInputs.forEach(input => {
        const lessonId = get(props.lesson, '_id', '');
        if (input.hasOwnProperty('hide') ) {
          input.hide = !!lessonId || isTrialLesson.value;
          if (!!lessonId) {
            return;
          }
        }
        if (input.type === 'date' && input.hasOwnProperty('hide')) {
          input.hide = isTrialLesson.value || id === TIME_TABLE_MODAL_REMINDER.NO_REPEAT.id;
        }
      });
    };

    const emptyStudentsList = ref(false);

    const saveForm = async () => {
      error.value = '';
      if (!students.value.length) {
        router.push({ name: 'CRMStudents', params: { openForm: true } });
        return;
      }
      const lessonId = get(props.lesson, '_id', '');
      const body = await validateLesson(lessonInputs, isGroupLesson.value);
      const studentsList = body.crmStudents;
      if (!studentsList || !studentsList.length) {
        emptyStudentsList.value = true;
      } else {
        emptyStudentsList.value = false;
      }

      if (!body || body.crmStudents.length < 1) return;
      const bodyUpd = updateBodyLesson(body, lessonId);

      if (lessonId) bodyUpd._id = lessonId;
      if (user.value._id) bodyUpd.teacherId = user.value._id;
      const resp = lessonId ? await updateLesson(bodyUpd) : await createLesson(bodyUpd);

      if (resp) {
        await store.dispatch('setLoaderRun', true);
        await updateStudents();
        await store.dispatch('setLoaderRun', false);
      }
    };

    const newStudent = ref(null);
    const updateStudents = async () => {
      try {
        const { data } = await StudentApi.getStudents({ });
        const { resource } = data;
        await setStudents(resource);
        await getStudentsList();
      } catch (e) {
        console.error(e);
      }
    };

    const onShowError = () => {
      lessonInputs.forEach(el => {
        if (el.modelName === 'crmLessonEnd') {
          el.error = 'errors.lessonExists';
        }
      });
    };

    const updateLesson = async (body) => {
      try {
        const { data } = await LessonsApi.updateGroupLesson(body);
        const index = lessons.value.findIndex(item => item._id === data._id);
        if (index !== -1) lessons.value.splice(index, 1, data);
        closeForm();
      } catch (err) {
        onShowError();
        console.error(err);
        error.value = getErrorText(err);
      }
    };

    const createLesson = async (body) => {
      try {
        const { data } = await LessonsApi.createGroupLesson(body);
        yandexTracker.addLesson();
        lessons.value.push(...data);
        closeForm();
        return data;
      } catch (err) {
        onShowError();
        console.error(err);
        error.value = getErrorText(err);
      }
    };

    const closeForm = () => {
      openModal.value = false;
      lessonCostSaved.value = null;
    };

    const settings = computed(() => store.getters.settings);

    const setDefaultPrice = (data) => {
      if (data && data.id === TIME_TABLE_MODAL_REMINDER.NO_REPEAT.id) return;
      if (data) {
        const { crmLessonPass = {} } = data;
        const crmLessonCost = lessonInputs.find((input) => input.modelName === 'crmLessonCost');
        if (crmLessonCost) {
          const { crmLessonCost: cost, crmStudentId  } = props.lesson;
          const { individualLessonPrice, _id  } = data;
          const lessonCost = crmStudentId === _id ? cost : individualLessonPrice;
          const defaultCost = get(settings.value, 'defaultLessonCost', 0);
          const costFromLesson = get(props.lesson, 'crmLessonCost', 0);
          crmLessonCost.modelValue = costFromLesson || lessonCost || individualLessonPrice || lessonCostSaved.value ||
              defaultCost;
        }
        const crmLessonPassField = lessonInputs.find((input) => input.modelName === 'crmLessonPass');
        if (crmLessonPassField) {
          const { crmLessonPassId = {}, crmStudentId, _id: lessonId, isTrial = false } = props.lesson;
          const { _id: id } = data;
          const isCurrentUser = crmStudentId === id;
          const subscriptionForCurrentLesson = lessonId && isCurrentUser && !isTrial ? crmLessonPassId : crmLessonPass;
          const availableToCreateLessonsCount = subscriptionForCurrentLesson
            ? subscriptionForCurrentLesson.availableToCreateLessonsCount
            : 0;
          const visibilityCrmLessonPassField = !!availableToCreateLessonsCount;
          const isShowLessonPassField = lessonId && isCurrentUser
            ? !!subscriptionForCurrentLesson && visibilityCrmLessonPassField
            : visibilityCrmLessonPassField;
          crmLessonPassField.modelValue = subscriptionForCurrentLesson;
          crmLessonPassField.visibility = isTrialLesson.value ? false : isShowLessonPassField;
          if (crmLessonCost) {
            crmLessonCost.visibility = !isTrialLesson.value;
          }
          const crmLessonCurrency = lessonInputs.find((input) => input.modelName === 'currency');
          if (crmLessonCurrency) {
            crmLessonCurrency.visibility = !isTrialLesson.value;
          }
        }
      }
    };

    const totalStudents = ref(0);
    const checkStudentList = async () => {
      try {
        const { data } = await StudentApi.getStudents({ page: 1, limit: 1 });
        totalStudents.value = data.totalDocs;
      } catch (err) {
        console.error(err);
      }
    };

    const getStudentsList = async () => {
      await setLoaderRun(true);
      if (!openModal.value || totalStudents.value) {
        await setInputs();
        return;
      }
      await checkStudentList();
      if (totalStudents.value && (totalStudents.value === students.value.length)) {
        await setInputs();
        return;
      }
      try {
        const { data } = await StudentApi.getStudents({ });
        const { resource } = data;
        await setStudents(resource);
        await setInputs();
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
      }
    };

    const { t } = useI18n();

    const updateStudentsList = (student) => {
      const studentsList = [...students.value];
      // const { crmStudentId, crmStudentFullName: fullName, uploadPhotoUrl: url } = props.lesson;
      // const currentStudent = studentsList.find(({ _id: id }) => id === crmStudentId);
      // console.log('currentStudent', currentStudent);
      // // if (!currentStudent) {
      // //   studentsList.push({
      // //     _id: crmStudentId,
      // //     fullName,
      // //     uploadPhoto: {
      // //       url
      // //     }
      // //   });
      // // }
      const studentsSort = sortBy(studentsList || [], 'fullName');
      const studentInput = lessonInputs.find((input) => input.modelName === 'crmStudentId');
      if (studentInput) {
        const optionsList = [
          {
            fullName: t('crm.students.table.addStudent'),
            _id: 'createUser',
            icon: addLessonIcon(),
            handlerChange: openFormStudent,
            style: { color: '#7500BA' }
          },
          ...studentsSort.filter(item => item.status !== STUDENT_STATUS.INACTIVE )
        ];
        studentInput.options = optionsList;
        const activeStudent = student || studentInput.options.find(item => item._id === crmStudentId);
        const firstStudent = studentInput.options.find(item => item._id !== 'createUser');
        newStudent.value = activeStudent;
        studentInput.modelValue = activeStudent || firstStudent || {};
      }
      setDefaultPrice(student);
    };

    const setInputs = async (student) => {
      const defaultStudent = get(props.lesson, 'crmStudentId', '');
      const defaultCost = get(props.lesson, 'crmLessonCost', 0);
      const defaultCurrency = get(props.lesson, 'currency', '');
      const { crmStudentId, crmStudentFullName: fullName, uploadPhotoUrl: url } = props.lesson;
      const studentsList = [...students.value];
      if (crmStudentId) {
        const currentStudent = studentsList.find(({ _id: id }) => id === crmStudentId);
        if (!currentStudent) {
          studentsList.push({
            _id: crmStudentId,
            fullName,
            uploadPhoto: {
              url
            }
          });
        }
      }

      const studentsSort = sortBy(studentsList || [], 'fullName');

      lessonInputs.forEach((input) => {
        if (input.modelName === 'crmStudentId') {
          input.options = studentsSort.filter(item => item.status !== STUDENT_STATUS.INACTIVE );
          input.modelValue = input.options.find(item => item._id === defaultStudent) || activeStudent ||
              input.options[0] || [];
          const optionsList = [
            {
              fullName: t('crm.students.table.addNewStudent'),
              _id: 'createUser',
              icon: addLessonIcon(),
              handlerChange: openFormStudent,
              style: { color: '#7500BA' }
            },
            ...studentsSort.filter(item => item.status !== STUDENT_STATUS.INACTIVE )
          ];
          input.options = optionsList;
          const activeStudent = student || input.options.find(item => item._id === defaultStudent);
          const firstStudent = input.options.find(item => item._id !== 'createUser');
          input.modelValue = activeStudent || firstStudent || {};
        } else if (input.modelName === 'lessonType') {
          isGroupLesson.value ? input.modelValue = 'group' : 'single';
        } else if (input.modelName === 'crmLessonStart') {
          const crmLessonStart = get(props.lesson, 'crmLessonStart', '');
          input.modelValue = crmLessonStart ? moment(crmLessonStart).format('HH:mm') : props.startTimeOnDay;
        } else if (input.modelName === 'crmLessonEnd') {
          const { defaultLessonLength = 60 } = settings.value;
          const crmLessonEnd = get(props.lesson, 'crmLessonEnd', '');
          let newValue = moment(props.startTimeOnDay, 'HH:mm' ).
            add(defaultLessonLength, 'minutes').format('HH:mm');
          if (newValue.includes('00:')) newValue = '23:55';
          input.modelValue = crmLessonEnd ? moment(crmLessonEnd).format('HH:mm') : newValue;
        } else if (input.modelName === 'dates') {
          const date = moment(props.date || '').format('YYYY-MM-DD');
          input.modelValue = { date: [date], time: [] };
        } else if (input.modelName === 'repeatRate') {
          const rate = get(props.lesson, 'repeatRate', TIME_TABLE_MODAL_REMINDER.NO_REPEAT.id);
          const { id, title } = TIME_TABLE_MODAL_REMINDER[rate];
          input.modelValue = { id, repeatRate: title, title };
        } else if (input.modelName === 'repeatEnd') {
          const repeatEndDate = get(props.lesson, 'repeatEndDate', '');
          const date = moment(repeatEndDate || props.date).
            add(repeatEndDate ? 0 : 7, 'days').
            format('YYYY-MM-DD');
          input.modelValue = { date: [date], time: [] };
          input.minDate = date;
        } else if (input.modelName === 'crmLessonCost') {
          const crmStudentId = lessonInputs.find(({ modelName }) => modelName === 'crmStudentId') || {};
          const { modelValue = {} } = crmStudentId;
          const { individualLessonPrice = settings.value.defaultLessonCost } = modelValue;
          input.modelValue = props.lesson.crmLessonCost || defaultCost || individualLessonPrice;
        } else if (input.modelName === 'currency') {
          input.modelValue = defaultCurrency || settings.value.currentCurrency;
        } else if (input.modelName === 'isTrial') {
          const isTrial = get(props.lesson, 'isTrial', false);
          isTrialLesson.value = isTrial;
          input.modelValue = isTrial;
          input.handlerChange = handlerChangeTrialCheckbox;
        } else {
          input.modelValue = get(props.lesson, input.modelName, input.defaultValue);
        }
      });
      await setLoaderRun(false);
    };

    const handlerChangeTrialCheckbox = (value) => {
      const userInput = lessonInputs.find((input) => input.modelName === 'crmStudentId') || {};
      const user = userInput.modelValue || {};
      isTrialLesson.value = value;
      const repeatRateInput = lessonInputs.find((input) => input.modelName === 'repeatRate') || {};
      const { modelValue: repeatRate } = repeatRateInput;
      hideRepeatLesson(repeatRate);
      setDefaultPrice(user);
    };

    const setDefaultValues = async () => {
      const stopReset = ['currency', 'dates', 'crmStudentId'];
      lessonInputs.forEach((input) => {
        if (input.modelName === 'crmStudentId') {
          input.lessonStatus = '';
        } else if (input.modelName === 'isTrial' || input.modelName === 'lessonType') {
          input.disabled = false;
        } else if (input.modelName === 'repeatRate') {
          input.lessonStatus = '';
          input.hide = false;
        }
        if (stopReset.includes(input.modelName)) return;
        const value = input.defaultValue;
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
      });
    };

    const modalTitle = computed(() => {
      if (!students.value.length) return '';
      return props.lesson._id ? 'crm.lessons.form.titleEdit' : 'crm.lessons.form.title';
    });

    const inputs = computed(() => {
      const { _id: lessonId, crmLessonStatus } = props.lesson;
      if (lessonId) {
        lessonInputs.forEach(el => {
          if (el.modelName === 'isTrial' || el.modelName === 'lessonType') {
            el.disabled = crmLessonStatus === 'Complete';
          } else if (el.lessonStatus !== undefined) {
            el.lessonStatus = crmLessonStatus;
          }
        });

        return lessonInputs.filter(item => item.modelName !== 'repeatable');
      }
      return lessonInputs;
    });

    const handleDataChange = (item, input) => {
      const { modelName } = input;
      if (modelName !== 'repeatEnd') {
        const repeatEndInput = inputs.value.find(({ modelName }) => modelName === 'repeatEnd');
        if (repeatEndInput) {
          const [minDate] = item.date;
          repeatEndInput.minDate = minDate;
          const { modelValue } = repeatEndInput;
          const { date } = modelValue;
          const isAfter = moment(minDate).isSameOrAfter(date[0]);
          if (isAfter) {
            const newEndDate = moment(minDate).add(7, 'days');
            repeatEndInput.modelValue = {
              ...item,
              date: [newEndDate]
            };
          }
        }
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const openStudentForm = ref(false);
    const editableStudent = ref(null);

    const lessonCostSaved = ref(null);
    const openFormStudent = () => {
      const cost = lessonInputs.find(item => item.modelName === 'crmLessonCost');
      lessonCostSaved.value = cost.modelValue;
      openStudentForm.value = true;
    };

    onBeforeMount(getStudentsList);

    return {
      isMobile,
      lessonInputs,
      openModal,
      saveForm,
      closeForm,
      error,
      students,
      loading,
      modalTitle,
      imgPlaceholder,
      inputs,
      handlerChange,
      handleDataChange,
      openStudentForm,
      editableStudent,
      openFormStudent,
      setInputs,
      checkGroupLesson,
      updateStudentsList,
      updateStudents,
      newStudent,
      isTrialLesson,
      emptyStudentsList
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-lessons-form {
  position: relative;
  padding: 20px 20px 10px;
  max-width: 497px;

  &_mobile {
    padding: 28px 12px;
  }

  &__body {
    grid-template-columns: auto auto 1fr;
  }

  &__placeholder {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba($color-white, 0.2);
    z-index: 100;
  }

  &__error {
    top: auto;
    left: 20px;
    bottom: -16px;

    &_mobile {
      bottom: auto;
      left: auto;
      right: 12px;
      top: 12px;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 20px;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;

    &__image {
      width: 385px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;

      &_mobile {
        width: 90vw;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }
}

</style>
