<template>
  <div
    class="section strengths"
    id="strengths"
  >
    <div class="strengths__container">
      <div class="strengths__title">
        {{ $t('landing.strengths.name.first') }}
        <span>{{ $t('landing.strengths.name.second') }}</span>
      </div>
      <div class="strengths__list">
        <Carousel :settings="settingsSlider">
          <Slide
            v-for="(item, index) of strengths"
            :key="item.id"
          >
            <div
              class="strengths-item"
              :class="{
                'strengths-first-item': index === 0,
                'strengths-last-item': index === strengths.length - 1
              }"
            >
              <div class="strengths-item__icon">
                <img
                  :src="
                    require(`@/assets/images/landing/strengths/${item.image}.svg`)
                  "
                  alt="user"
                />
              </div>
              <div class="strengths-item__text">
                {{ $t(item.name) }}
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import strengths from '@/constants/landing/strengths';

export default {
  name: 'Strengths',
  components: {
    Carousel,
    Slide
  },
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const settingsSlider = reactive({
      itemsToShow: 1.2,
      snapAlign: 'center',
      wrapAround: false
    });
    return {
      strengths,
      settingsSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.strengths {
  width: 100%;
  display: flex;
  margin-top: 44px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: 100%;
    margin: 0 12px;
    max-width: 1600px;
    height: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 28px rgba($color-black, 0.08);
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('~@/assets/images/landing/strengths/strengths-mobile.jpg');

    @media (max-width: 375px) {
      background-size: auto 100%;
    }
  }

  &__title {
    width: 100%;
    max-width: 320px;
    margin-top: 32px;
    padding-left: 24px;
    align-self: flex-start;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    text-align: start;
    color: $color-white;
  }

  &__list {
    margin: 20px 0;
    width: 100%;
  }
}

.strengths-item {
  width: calc(100% - 16px);
  height: 160px;
  margin-bottom: 25px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.08);
  border-radius: 8px;

  &__icon {
    padding: 0;
  }

  &__text {
    margin-top: 12px;
    width: 240px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: start;
  }
}

.strengths-first-item {
  margin-left: 24px;
  margin-right: 5px;
}

.strengths-last-item {
  margin-right: 24px;
  margin-left: 5px;
}
</style>
