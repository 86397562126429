<template>
  <div>
    <Modal
      :title="modalTitleText"
      v-model:open="openModal"
      :saveBtnText="saveBtnText"
      :removeBtnText="'buttons.cancel'"
      :mobileVersion="isMobile"
      :editMode="true"
      :forceClose="true"
      @save="saveForm"
    >
      <div class="lp-crm-group-form">
        <Form
          :inputs="groupInputs"
          :crmStudents="currentGroupObj ? currentGroupObj.crmStudents : null"
          @set-group="setGroup"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import groupInputs from '@/constants/inputs/groupInputs';
import { computed, onBeforeMount, ref, watch } from 'vue';
import GroupsApi from '@/api/CRM/groups';

export default {
  name: 'StudentForm',
  components: {
    Modal,
    Form
  },
  props: {
    openForm: Boolean,
    group: String,
    currentGroupProp: Object,
    currentGroupObj: Object,
    isMobile: Boolean
  },
  emits: ['close-form', 'update:open', 'set-group'],
  setup (props, { emit }) {
    const openModal = ref(false);
    const closeModal = () => {
      openModal.value = false;
      emit('close-form');
    };

    const saveBtnText = computed(() => {
      return props.group ? 'crm.lessons.form.edit' : 'buttons.add';
    });

    const modalTitleText = computed(() => {
      return props.group ? 'crm.students.table.editGroup' : 'crm.students.table.addGroup';
    });

    const saveForm = async () => {
      const formValidation = validateGroupInputs(groupInputs);

      if (formValidation) {
        const title = groupInputs.find(item => item.modelName === 'crmGroup');
        const group = {
          'crmStudents': [],
          'title': title.modelValue
        };
        selectedGroup.value.forEach(item => {
          group.crmStudents.push(item._id);
        });

        props.group ? await GroupsApi.updateGroup(group, props.group) : await GroupsApi.createGroup(group);
        openModal.value = false;
        emit('save');
      }
    };

    const validateGroupInputs = (inputs) => {
      let errors = 0;
      inputs.forEach(input => {
        if ((input.modelName === 'crmGroup' || input.modelName === 'allStudentsList') &&
            (!input.modelValue || input.modelValue === [] || !input.modelValue.length || input.modelValue === '')) {
          input.error = input.emptyError;
          errors++;
        }
      });
      return errors === 0;
    };

    const selectedGroup = computed(() => {
      return props.currentGroupProp.value;
    });

    watch(openModal, async (value) => {
      emit('update:open', value);

      if (value && props.group) {
        const { data } = await GroupsApi.getGroupById(props.group);
        groupInputs.forEach(item => {
          if (item.modelName === 'crmGroup') {
            item.modelValue = data.title;
          }
          if (item.modelName === 'allStudentsList') {
            item.modelValue = data.crmStudents;
          }
        });
      } else if (!value) {
        clearInputs();
      }
    });

    const clearInputs = () => {
      groupInputs.forEach(item => {
        item.modelValue = null;
        item.error = null;
      });
    };

    const clearError = () => {
      groupInputs.forEach(item => {
        item.error = null;
      });
    };

    const setGroup = (data) => {
      if (data) {
        clearError();
        emit('set-group', data);
        const students = groupInputs.find(item => item.modelName === 'allStudentsList');
        students.modelValue = [];
        data.forEach(student => {
          students.modelValue.push(student);
        });
      }
    };

    watch(() => props.openForm, (value) => {
      openModal.value = value;
      emit('update:open', value);
    });

    onBeforeMount(() => {
      clearError();
    });

    return {
      groupInputs,
      openModal,
      saveForm,
      closeModal,
      setGroup,
      saveBtnText,
      modalTitleText
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/sass/style";

.lp-crm-group-form {
  padding: 20px 20px 0 20px;
  min-height: 450px;
}
</style>