import { reactive } from 'vue';
import { isEmail } from '@/constants/utils/validationRegex';

export default reactive([
  {
    label: 'login.inputs.login',
    type: 'text',
    inputmode: 'email',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyEmail',
    isValid: isEmail,
    autofocus: true
  }
]);
