<template>
  <div
    class="lp-expenses-table"
    :class="{
      'lp-expenses-table_full': total,
      'lp-expenses-table_mobile': isMobile,
      'lp-expenses-table_empty': !expenses.length
    }"
  >
    <LPTable
      :sticky="isMobile ? 52 : 65"
      :loading="loading"
      :columns="columns"
      :rows="expenses"
      @delete="deleteExpense"
      @edit="editExpense"
    >
    </LPTable>
    <div
      class="lp-expenses-table-pagination"
      v-if="pagination.totalPages > 1"
    >
      <Pagination
        v-bind="pagination"
        @change="$emit('get', $event)"
      />
    </div>
  </div>
</template>

<script>
import LPTable from '@/components/Main/Table/LPTable';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { get } from 'lodash';
import Pagination from '@/components/Main/Table/Pagination';
import expensesColumns from '@/constants/tables/expensesColumns';
import ExpensesApi from '@/api/CRM/expenses';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ExpensesTable',
  components: { Pagination, LPTable },
  props: {
    loading: Boolean,
    total: Number,
    pagination: {
      type: Object,
      default: () => ({})
    },
    expenses: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['get', 'update:openExpense', 'edit'],
  setup (props, { emit }) {
    const store = useStore();
    const settings = computed(() => store.getters.settings);

    const columns = computed(() => expensesColumns.map(item => {
      if (item.needCurrency && settings.value) {
        const currency = get(props.expenses, [0, 'currency'], '');
        item.postfix = settings.value.currentCurrency || currency;
      }
      return item;
    }));

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const deleteExpense = async (row = {}) => {
      try {
        await ExpensesApi.deleteExpense(row._id);
        emit('get');
      } catch (err) {
        console.error(err);
      }
    };

    const editExpense = async (row = {}) => {
      emit('update:openExpense', true);
      emit('edit', row);
    };

    return {
      isMobile,
      columns,
      deleteExpense,
      editExpense
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-expenses-table {
  @include global-font;
  padding: 0 24px;
  height: 100%;

  &_mobile {
     background-color: $color-white;
     padding: 0 0 70px;
     height: auto;
   }

  &_empty {
    padding: 0;
  }

  &_full {
     height: auto;
   }

  &__title {
     padding-bottom: 28px;
     background-color: $color-white;

  &_mobile {
     padding: 0 12px 8px;
   }
  }

  &-empty {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     flex-grow: 1;
     width: 100%;
     height: 100%;
     color: $color-text;
     text-align: center;
     user-select: none;

  &_mobile {
     height: calc(100vh - 168px);
     height: calc((var(--vh, 1vh) * 100) - 168px);
   }

  &__image {
     width: 303px;
     height: auto;
     object-fit: cover;
     margin-bottom: 30px;

  @media (max-width: 480px) {
      width: calc(100vw - 30px);
      max-width: 200px;
    }
  }

  &__title {
     font-weight: bold;
     font-size: 16px;
     line-height: 125%;
     margin-bottom: 8px;
   }

  &__description {
     font-weight: 500;
     font-size: 14px;
     line-height: 125%;
     max-width: 290px;
     margin-bottom: 28px;
   }
  }

  &-pagination {
     display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 8px;
     padding: 8px 0 16px;
     background-color: $color-white;
   }
}
</style>