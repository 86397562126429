<template>
  <div
    class="social-networks"
    ref="socialNetworksBlock"
  >
    <div
      class="social-networks__header"
      v-if="label"
    >
      <label
        class="lp-label"
        :for="label"
      >
        {{ $t(label) }}
      </label>
    </div>
    <div class="social-networks-fields">
      <transition-group name="fade">
        <div
          v-for="(field, index) in modelValue"
          class="social-networks-fields-item"
          :key="field._id"
        >
          <div
            class="social-networks-fields-item-wrapper lp-input"
            :class="{
              'social-networks-fields-item-wrapper_focus': indexInputFocus === index,
              'social-networks-fields-item-wrapper_error':
                error && typeof error === 'string' && errorArray.includes(index)
            }"
          >
            <div class="social-networks-fields-item-wrapper__icon">
              <component
                :is="getSocialIcon(field.socialMediaType)"
              />
            </div>
            <input
              class="social-networks-fields-item-wrapper__input"
              type="text"
              inputmode="url"
              v-focus="!field.socialMediaLink"
              v-model="field.socialMediaLink"
              @focus="handlerFocus(index)"
              @blur="handlerBlur"
            >
          </div>
          <div
            class="social-networks-fields-item__remove lp-cross-button"
            @click="removeSocialMedia(index)"
          >
            <closeModalIcon />
          </div>
          <transition name="bounce">
            <span
              class="lp-error"
              v-if="error && typeof error === 'string' && errorArray.includes(index)"
            >
              <dangerIcon />
              {{ $t(error) }}
            </span>
          </transition>
        </div>
      </transition-group>
    </div>
    <div
      class="social-networks-btn"
      @click="showSocialDropList = !showSocialDropList"
      ref="socialNetworksBtn"
      v-click-outside="closeSocialDropList"
    >
      <div class="social-networks-btn__icon">
        <addLessonIcon />
      </div>
      {{ $t('crm.students.form.socialNetworks.button') }}
    </div>

    <teleport to="#app">
      <transition name="fade">
        <div
          class="social-networks-drop-list__background"
          v-if="isMobile && showSocialDropList"
          @click="closeSocialDropList"
        />
      </transition>
    </teleport>

    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition name="slide-select">
        <div
          class="social-networks-drop-list"
          :class="{
            'social-networks-drop-list_mobile': isMobile
          }"
          v-if="showSocialDropList"
          :style="getStyleDropList()"
          ref="socialNetworksDropList"
        >
          <div
            class="social-networks-drop-list-item"
            v-for="item in socialNetworks"
            :key="item.socialMediaType"
            @click="addedSocialMedia(item.socialMediaType)"
          >
            <div class="social-networks-drop-list-item__icon">
              <component :is="item.icon" />
            </div>
            <div class="social-networks-drop-list-item__title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { addLessonIcon } from '@/constants/icons/crm';
import socialNetworks from '@/constants/crm/socialNetworks';
import {
  FacebookIcon, PinterestIcon, TwitterIcon, YouTubeIcon,
  InstagramIcon, LinkedInIcon, VkIcon, OkIcon
} from '@/constants/icons/iconsSocialMedia';
import { closeModalIcon, dangerIcon } from '@/constants/icons';
import MobileDetect from 'mobile-detect';

export default {
  name: 'SocialNetworks',
  components: {
    addLessonIcon,
    closeModalIcon,
    dangerIcon
  },
  props: {
    label: String,
    modelValue: Object,
    customError: [Boolean, String],
    error: [Boolean, String],
    errorArray: {
      type: Array,
      default: () => ([])
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'focus', 'blur', 'submit'],
  setup (props, { emit }) {
    const showSocialDropList = ref(false);
    watch(showSocialDropList, (val) => {
      const el = document.querySelector('#app');
      const body = document.querySelector('body');
      if (!el || !body || !isMobile.value) return;
      el.style.overflow = val ? 'hidden' : 'visible';
      body.style.overflow = val ? 'hidden' : 'visible';
      el.style.height = val ? '90vh' : '';
    });
    const closeSocialDropList = () => {
      showSocialDropList.value = false;
    };

    const addedSocialMedia = (socialMediaType) => {
      const addedSocialMedia = {
        socialMediaLink: '',
        socialMediaType,
        _id: Date.now()
      };
      const socialMediaList = [...props.modelValue, addedSocialMedia];
      emit('update:modelValue', socialMediaList);
    };
    const removeSocialMedia = (i) => {
      const socialMediaList = [...props.modelValue];
      socialMediaList.splice(i, 1);
      emit('update:modelValue', socialMediaList);
    };

    const getSocialIcon = (type) => {
      switch (type) {
      case 1:
        return FacebookIcon();
      case 2:
        return PinterestIcon();
      case 3:
        return TwitterIcon();
      case 4:
        return YouTubeIcon();
      case 5:
        return InstagramIcon();
      case 6:
        return LinkedInIcon();
      case 7:
        return VkIcon();
      case 8:
        return OkIcon();
      }
    };

    const indexInputFocus = ref(-1);
    const handlerFocus = (i) => {
      emit('focus', i);
      indexInputFocus.value = i;
    };
    const handlerBlur = () => {
      emit('blur');
      indexInputFocus.value = -1;
    };

    const socialNetworksBtn = ref(null);
    const socialNetworksBlock = ref(null);
    const socialNetworksDropList = ref(null);
    const getStyleDropList = () => {
      if (!socialNetworksBtn.value || !socialNetworksDropList.value || isMobile) return {};
      const position = socialNetworksBtn.value.getBoundingClientRect();
      const { height } =  socialNetworksDropList.value.getBoundingClientRect();
      const { innerHeight } = window;
      const topPosition = 4 + position.top + position.height;
      const top = topPosition + height + 10  > innerHeight
        ? `${position.top - height - 10 }px`
        : `${topPosition}px`;
      return {
        position: 'fixed',
        top,
        left: `${position.left - 12}px`,
        width: '300px'
      };
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      socialNetworks,
      addedSocialMedia,
      removeSocialMedia,
      getSocialIcon,
      showSocialDropList,
      closeSocialDropList,
      indexInputFocus,
      handlerFocus,
      handlerBlur,
      getStyleDropList,
      socialNetworksBtn,
      socialNetworksBlock,
      socialNetworksDropList
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.social-networks {

  &__header {
    margin-bottom: 4px;
  }
}

.social-networks-btn {
  @include global-font;
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: $color-accent;
  font-weight: bold;
  transition: color .2s ease-in;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: $color-accent-hover;

    .social-networks-btn__icon {
      background: $color-accent-hover;
    }

  }

  &__icon {
    width: 28px;
    height: 28px;
    padding: 8px;
    margin-right: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    background: $color-accent;
    transition: background .2s ease-in;
  }

}

.social-networks-drop-list__background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba($color-black, 0.5);
}

.social-networks-drop-list {
  padding: 8px;
  background: $color-white;
  box-shadow: 0 0 17px rgba($color-black, 0.08);
  border-radius: 4px;
  z-index: 5;

  &_mobile {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: auto;
    width: 100%;
    border-radius: 0;
    z-index: 102;
  }
}

.social-networks-drop-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
  @include global-font;
  font-weight: bold;
  font-size: 14px;
  color: $color-text;
  transition: background-color .2s ease-in;
  border-radius: 4px;
  cursor: pointer;

  & + & {
    margin-top: 8px;
  }

  &:hover {
    background: $color-moon-raker;
  }

  &__icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 8px;
  }

}

.social-networks-fields {
  display: grid;
  grid-gap: 20px;
}

.social-networks-fields-item {
  position: relative;
  display: grid;
  grid-template-columns: auto 35px;
  align-items: center;

  &__remove {
    width: auto;
    cursor: pointer;
  }

}

.social-networks-fields-item-wrapper {
  display: grid;
  grid-template-columns: 58px auto;
  grid-gap: 4px;
  height: 42px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  transition: 0.3s ease;
  padding: 0;

  &_focus {
    border-color: $color-accent;
    box-shadow: 0 0 10px 1px rgba($color-accent, 0.1);
  }

  &_error {
    border-color: $color-red;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $color-border-input;
    padding: 0 16px;
  }

  &__input {
    height: 100%;
    border: none;
    padding: 0 12px;
  }

}

</style>

<style lang="scss">
.social-networks-btn {
  path {
    fill: currentColor;
  }
}

.social-networks-fields-item-wrapper__icon,
.social-networks-drop-list-item__icon {
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>

<!-- Created by zas on 28.06.2021  -->
