<template>
  <div class="lp-profile">
    <ProfileAvatar
      v-if="!editableFields || editableFields === 'main'"
      v-model:editable="editableMode"
      v-model:file="avatar"
      :editable="editableMode"
      :user="user"
    />
    <ProfileBase
      v-model:editable="editableMode"
      :avatar="avatar"
      :editable="editableMode"
      :user="user"
      :editableFields="editableFields"
      @show-editable="showEditableFields"
      @showModal="(val) => $emit('show-modal', val)"
    />
    <ProfilePassword v-if="!editableMode" />
    <!--    <PaymentStripe />-->
  </div>
</template>

<script>
import ProfileAvatar from '@/components/CRM/Settings/ProfileSettings/Profile/ProfileAvatar';
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ProfileBase from '@/components/CRM/Settings/ProfileSettings/Profile/ProfileBase';
import ProfilePassword from '@/components/CRM/Settings/ProfileSettings/Profile/ProfilePassword';
import { get } from 'lodash';
// import PaymentStripe from '@/components/Main/PaymentStripe/PaymentStripe';

export default {
  name: 'Profile',
  components: { ProfilePassword, ProfileBase, ProfileAvatar },
  emits: ['update:editable', 'show-modal'],
  setup (props, { emit }) {
    const avatar = ref(null);
    const editableMode = ref(false);
    const editableFields = ref(null);

    watch(editableMode, (val) => {
      emit('update:editable', val);

      if (!val) {
        editableFields.value = null;
      }

      avatar.value = null;
    });

    const showEditableFields = (fields) => {
      editableFields.value = fields;
    };

    const store = useStore();
    const user = computed(() => store.getters.user);
    const noProfile = computed(() => {
      const { firstName, lessonCost } = user.value;
      return !firstName || !lessonCost;
    });

    const checkProfile = () => {
      if (noProfile.value) editableMode.value = true;
    };

    watch(() => user.value, checkProfile);
    onBeforeMount(checkProfile);

    return {
      user,
      editableMode,
      avatar,
      editableFields,
      showEditableFields
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-profile {

}

</style>
