import { uniqueId } from 'lodash';
import FORMATS from '@/constants/formats';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';
import { commentIcon } from '@/constants/icons/crm';

const isMobile = checkMobileByScreen();

const columns = [
  {
    type: 'date',
    prop: 'expenseDate',
    formatDate: isMobile ? 'DD MMM, YY' : 'DD MMM, YYYY',
    title: 'crm.expenses.table.date',
    width: isMobile ? '74px' : '150px',
    gap: '1px'
  },
  {
    type: 'category',
    prop: 'expenseCategory',
    title: 'crm.expenses.table.category',
    width: isMobile ? '.6fr' : '320px'
  },
  {
    type: 'comment',
    prop: 'comment',
    title: 'crm.expenses.table.comment',
    titleMobile: 'crm.expenses.table.mobileComment',
    width: isMobile ? '.3fr' : '.9fr',
    overflow: 'hidden',
    justifyContent: isMobile ? 'center' : 'flex-start',
    getClassName: () => {
      return 'lp-table-column-item_ellipsis';
    },
    iconProp: {
      showValue: !isMobile,
      prop: isMobile ? 'comment' : '',
      icon: commentIcon,
      style: {
        marginLeft: '4px'
      }
    }
  },
  {
    type: 'number',
    prop: 'amount',
    format: FORMATS.FINANCE,
    needCurrency: true,
    title: 'crm.students.payment.amount',
    titleMobile: 'crm.students.payment.amount',
    justifyContent: isMobile ? 'flex-end' : 'center',
    textAlign: isMobile ? 'right' : 'center',
    width: isMobile ? '1fr' : '135px',
    overflow: isMobile ? 'hidden' : ''
  },
  {
    toolbar: true,
    title: '',
    width: isMobile ? '38px' : '0.1fr',
    height: isMobile ? '38px' : '',
    deleteName: 'fullName',
    customClass: 'lp-toolbar-options-up'
  }
];

const paymentsColumns = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default paymentsColumns;
