import IatsLogo from '@/assets/images/discounts/logo-iats.png';
import PopLogo from '@/assets/images/discounts/logo-pop-music.png';
import SoundLogo from '@/assets/images/discounts/logo-sound-master.png';
import FoxLogo from '@/assets/images/discounts/logo-fox.png';
import JazzLogo from '@/assets/images/discounts/logo-jazz.png';
import MusioLogo from '@/assets/images/discounts/logo-musio.png';
import BrymonsoftLogo from '@/assets/images/discounts/logo-br.png';
import ProftyLogo from '@/assets/images/landing/partners/partners-image8.png';
// import GolubtsovaLogo from '@/assets/images/discounts/logo-golubtsova.png';
// import CvtLogo from '@/assets/images/discounts/logo-cvt.png';
import { uniqueId } from 'lodash';

const discountsList = [
  {
    discountName: 'crm.discounts.discount1.discountName',
    logo: IatsLogo,
    companyName: 'crm.discounts.discount1.companyName',
    link: 'http://iats-edu.com/',
    promoCode: 'MUSIO-CRM',
    percentage: 20,
    id: uniqueId()
  },
  {
    discountName: 'crm.discounts.discount2.discountName',
    logo: PopLogo,
    companyName: 'crm.discounts.discount2.companyName',
    link: 'https://pop-music.ru/',
    promoCode: 'MUSIO-POP',
    percentage: 10,
    id: uniqueId()
  },
  {
    discountName: 'crm.discounts.discount3.discountName',
    logo: SoundLogo,
    companyName: 'crm.discounts.discount3.companyName',
    link: 'https://soundmaster.ua/',
    promoCode: 'MUSIO-SOUND',
    percentage: 10,
    id: uniqueId()
  },
  {
    discountName: 'crm.discounts.discount8.discountName',
    logo: MusioLogo,
    companyName: 'crm.discounts.discount8.companyName',
    link: 'https://crm.musio.io/',
    promoCode: 'MUSIO-TRACK',
    percentage: 20,
    id: uniqueId()
  },
  {
    discountName: 'crm.discounts.discount9.discountName',
    logo: BrymonsoftLogo,
    companyName: 'crm.discounts.discount9.companyName',
    link: 'https://brymonsoft.com/',
    promoCode: 'МUSIO-WEB',
    percentage: 30,
    id: uniqueId()
  },
  {
    discountName: 'crm.discounts.discount4.discountName',
    logo: FoxLogo,
    companyName: 'crm.discounts.discount4.companyName',
    details: 'crm.discounts.discount4.discountDetails',
    link: 'https://foxsound-vocal.ru/',
    promoCode: 'MUSIO-FOX',
    percentage: 20,
    id: uniqueId()
  }
  // {
  //   discountName: 'crm.discounts.discount6.discountName',
  //   logo: JazzLogo,
  //   companyName: 'crm.discounts.discount6.companyName',
  //   link: 'https://jazz-birds.com/',
  //   promoCode: 'MUSIO-BIRDS',
  //   percentage: 15,
  //   id: uniqueId()
  // },
  // {
  //   discountName: 'crm.discounts.discount7.discountName',
  //   logo: JazzLogo,
  //   companyName: 'crm.discounts.discount7.companyName',
  //   link: 'https://jazz-birds.com/',
  //   promoCode: 'MUSIO-BIRDS',
  //   percentage: 10,
  //   id: uniqueId()
  // },
  // {
  //   discountName: 'crm.discounts.discount10.discountName',
  //   logo: ProftyLogo,
  //   companyName: 'crm.discounts.discount10.companyName',
  //   link: 'https://profty.com/ru/conference',
  //   promoCode: 'MUSIOPROFTY',
  //   percentage: 10,
  //   id: uniqueId()
  // }
];

export default discountsList;