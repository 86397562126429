import { reactive } from 'vue';
import { isEmail } from '@/constants/utils/validationRegex';
import { uniqueId } from 'lodash';

const forgotPassword = [
  {
    label: 'login.inputs.login',
    type: 'text',
    inputmode: 'email',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyEmail',
    isValid: isEmail,
    autofocus: true
  }
];

const forgotResetPassword = [
  {
    autofocus: true,
    type: 'password',
    label: 'profile.changedPassword.newPassword',
    modelName: 'newPassword',
    modelValue: '',
    emptyError: 'errors.emptyPassword',
    validError: 'errors.isShortPassword',
    canShowPass: true,
    isValid: (val) => String(val).length >= 8
  },
  {
    type: 'password',
    label: 'profile.changedPassword.newPasswordConfirm',
    modelName: 'newPasswordConfirm',
    modelValue: '',
    emptyError: 'errors.emptyPassword',
    equalError: 'errors.notConfirmPassword',
    isEqual: 'newPassword',
    canShowPass: true
  }
];

export default reactive([
  {
    label: 'login.inputs.login',
    type: 'text',
    inputmode: 'email',
    model: '',
    modelName: 'email',
    emptyError: 'errors.emptyEmail',
    isValid: isEmail,
    autofocus: true
  }
]);

export const forgotPasswordInputs = reactive(forgotPassword.map((item) => {
  item.id = uniqueId('forgot-password-input-');
  item.autocomplete = 'off';
  return item;
}));

export const forgotResetPasswordInputs = reactive(forgotResetPassword.map((item) => {
  item.id = uniqueId('forgot-reset-password-input-');
  item.autocomplete = 'off';
  return item;
}));
