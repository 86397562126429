import { find, get } from 'lodash';
import LANGUAGES from '@/constants/enums/languages';
import checkCountries from '@/constants/countries/checkCountries';
import { VueCookieNext } from 'vue-cookie-next';

export default (countryCode) => {
  const langByLocation = checkCountries(countryCode) ? 'ru' : 'en';
  const langBySettings = get(find(LANGUAGES, { id: localStorage.getItem('lang') }), 'id', langByLocation);
  const language = langBySettings || langByLocation;
  return get(find(LANGUAGES, { id: language }), 'id', langByLocation);
};