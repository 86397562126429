<template>
  <div
    class="lp-timetable"
    :class="{'lp-timetable_mobile': isMobile}"
  >
    <div
      class="lp-timetable-header"
      :class="{'lp-timetable-header_mobile': isMobile}"
      id="timetableDateHeader"
    >
      <div
        class="lp-timetable-header__today"
        :class="{'lp-timetable-header__today_mobile': isMobile}"
      >
        {{ calendarDateFormat }}
      </div>
      <div
        class="lp-timetable-header__controls"
        id="timetableDatePicker"
      >
        <DateTimetableControls
          v-model:date="calendarDateDesktop"
          v-if="!isMobile"
        />
        <DateTimetableControls
          v-model:date="calendarDateMobile"
          v-else-if="isMobile && !templateChange"
          :mini="isMobile"
        />
      </div>
    </div>
    <DateControlsMobile
      v-if="isMobile"
      class="lp-calendar-header__week-controls"
      id="timetableDateControlMobile"
      :date="calendarDateMobile"
      v-model:date="calendarDateMobile"
    />
    <TimetableEditable
      :currentDate="calendarDate"
      v-model:change="templateChange"
      :save="save"
      @update="$emit('update:saved', false)"
      @save="$emit('update:saved', true)"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import moment from 'moment';
import DateTimetableControls from '@/components/CRM/Lessons/Timetable/DateTimetableControls/DateTimetableControls';
import TimetableEditable from '@/components/CRM/Lessons/Timetable/TimetableEditable/TimetableEditable';
import MobileDetect from 'mobile-detect';
import DateControlsMobile from '@/components/CRM/Lessons/Calendar/DateControls/DateControlsMobile';
import LessonsApi from '@/api/CRM/lessons';
import { useStore } from 'vuex';

export default {
  name: 'Timetable',
  components: {
    TimetableEditable,
    DateTimetableControls,
    DateControlsMobile
  },
  props: {
    save: Boolean,
    showHints: Boolean,
    lastLessons: Boolean,
    date: String
  },
  emits: ['update:saved'],
  setup (props, { emit }) {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const calendarDateDesktop = ref(moment().format('YYYY-MM-DD'));
    const calendarDateMobile = computed({
      get () {
        return props.date;
      },
      set (data) {
        emit('update:date', data);
      }
    });
    const calendarDate = computed(() => isMobile.value ? calendarDateMobile.value : calendarDateDesktop.value);

    const calendarDateFormat = computed(() => moment(calendarDate.value).format('MMMM YYYY'));

    const getRangeDate = computed(() => {
      const date = moment(calendarDate.value);
      return {
        startDate: date.utc().format(),
        endDate: date.add(isMobile.value ? 1 : 7, 'day').utc().format()
      };
    });

    const store = useStore();
    const setLessons = (data) => store.dispatch('setLessons', data);

    const getLessons = async () => {
      if (props.showHints) return;
      const { startDate, endDate } = getRangeDate.value;
      const query = {
        lost: props.lastLessons,
        start: startDate,
        end: endDate
      };
      try {
        const { data } = await LessonsApi.getGroupLessonsByDate(query);
        await setLessons(data);
      } catch (err) {
        console.error(err);
      }
    };

    onMounted(() => {
      getLessons();
    });

    watch([calendarDate, props], getLessons);
    const templateChange = ref(false);

    return {
      templateChange,
      isMobile,
      calendarDate,
      calendarDateDesktop,
      calendarDateMobile,
      calendarDateFormat
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable {
  @include global-font;
  padding: 20px 24px 30px;
  width: 100%;

  &_mobile {
    width: 100vw;
    padding: 24px 8px 0;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 12px;
    min-height: 37px;

    &_mobile {
      position: sticky;
      top: 89px;
      margin-bottom: 17px;
      background-color: $color-white;
      z-index: 2;
    }

    &__today {
      color: $color-text;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      user-select: none;

      &_mobile {
        font-size: 16px;
      }

    }

    &__controls {
      position: relative;
      min-height: 36px;
    }
  }
}

</style>
