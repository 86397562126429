<template>
  <div class="lp-date-select">
    <CustomSelect
      :class="{'lp-date-select__input': !stringStyle}"
      :options="options"
      :error="error"
      :fixed="fixed"
      :required="true"
      :withoutLabel="true"
      :searchable="false"
      :modelValue="selectedDate"
      v-model="selectedDate"
      keyProp="title"
      maxHeight="300px"
      @focus="$emit('focus')"
      :stringStyle="stringStyle"
    />
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { onBeforeMount, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default {
  name: 'DateSelect',
  components: { CustomSelect },
  props: {
    stringStyle: Boolean,
    allTime: Array,
    modelValue: Object,
    required: Boolean,
    error: [Boolean, String],
    fixed: Boolean
  },
  emits: ['update:modelValue', 'focus'],
  setup (props, { emit }) {
    const { t } = useI18n();

    const thisWeek = () => {
      const startDay = moment().locale('ru').startOf('week').utc().format();
      const endDay = moment().locale('ru').endOf('week').utc().format();
      return [startDay, endDay];
    };

    const lastWeek = () => {
      const startDay = moment().locale('ru').subtract(1, 'week').startOf('week').utc().format();
      const endDay = moment().locale('ru').subtract(1, 'week').endOf('week').utc().format();
      return [startDay, endDay];
    };

    const thisMonth = () => {
      const startDay = moment().startOf('month').utc().format();
      const endDay = moment().endOf('month').utc().format();
      return [startDay, endDay];
    };

    const lastMonth = () => {
      const startDay = moment().subtract(1, 'month').startOf('month').utc().format();
      const endDay = moment().subtract(1, 'month').endOf('month').utc().format();
      return [startDay, endDay];
    };

    const allTime = () => {
      const startDay = moment().subtract(50, 'year').startOf('month').utc().format();
      const endDay = moment().add(50, 'year').endOf('month').utc().format();
      return [startDay, endDay];
    };

    const options = reactive([
      { title: t('dateSelect.thisWeek'), date: thisWeek() },
      { title: t('dateSelect.lastWeek'), date: lastWeek() },
      { title: t('dateSelect.thisMonth'), date: thisMonth() },
      { title: t('dateSelect.lastMonth'), date: lastMonth() },
      { id: 'all', title: t('dateSelect.allTime'), date: allTime() }
    ]);

    watch(() => props.allTime, () => {
      const all = options.find(item => item.id === 'all');
      all.date = props.allTime;
    });

    const selectedDate = ref(options[0]);
    watch(selectedDate, () => {
      const val = props.allTime && selectedDate.value.id === 'all' ? props.allTime : selectedDate.value.date;
      emit('update:modelValue', val);
    });
    onBeforeMount(() => {
      emit('update:modelValue', options[0].date);
    });

    return {
      options,
      selectedDate
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-date-select {
  @include global-font;
  user-select: none;

  &__input {
    position: relative;
    top: -19px;
    height: 42px;
  }
}

</style>
