<template>
  <div
    class="lp-crm"
    :class="{'lp-crm_mobile': isMobile}"
  >
    <SidebarCRM />
    <div
      class="lp-crm-main"
      :class="{
        'lp-crm-main_zoomed': isZoomed,
        'lp-crm-main_mobile': isMobile,
        'lp-crm-main_accompaniment ': currentRoute === crmRoutes.accompaniment
      }"
    >
      <router-view v-if="!checkingRoute"></router-view>
    </div>
    <TimetableWeek />
  </div>
</template>

<script>
import SidebarCRM from '@/components/CRM/Sidebar/SidebarCRM';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';
import SettingsApi from '@/api/CRM/settings';
import { useStore } from 'vuex';
import { get } from 'lodash';
import TimetableWeek from '@/components/CRM/Lessons/Timetable/TimetableWeek/TimetableWeek';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';
import AuthApi from '@/api/Auth/api';
import setMomentLocale from '@/constants/utils/getMomentLocale';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';
import crmRoutes from '@/constants/crm/crmRoutes';

export default {
  name: 'CRM',
  components: {
    TimetableWeek,
    SidebarCRM
  },
  setup () {
    const router = useRouter();
    const currentRoute = computed(() => get(router, 'currentRoute.value.name', ''));
    const checkingRoute = ref(true);
    const setLoaderRun = (data) => {
      const { _id } = user.value;
      if (currentRoute.value !== 'CRMSettings' || !_id) {
        checkingRoute.value = data;
      } else {
        checkingRoute.value = false;
      }
      store.dispatch('setLoaderRun', data);
    };

    const store = useStore();

    const user = computed(() => store.getters.user);
    const setActiveUser = (data) => store.dispatch('setUser', data);

    const { locale } = useI18n({ useScope: 'global' });
    const settings = computed(() => store.getters.settings);
    const setSettings = (data) => store.dispatch('setSettings', data);
    watch(settings, (newVal = {}, oldVal = {}) => {
      const { language } = newVal;
      if (language === oldVal.language) return;
      localStorage.setItem('lang', language);
      setMomentLocale(language);
      locale.value = language;
    });

    const goHomeCRM = () => {
      const currentRouteName = router.currentRoute.value.name;
      if (currentRouteName !== 'CRM' && currentRouteName !== 'Main') return;
      router.push({ name: 'CRMLessons', params: { unit: 'month' } });
    };

    const getSettings = async () => {
      try {
        const { data } = await SettingsApi.getSettings();
        if (data._id) {
          await setSettings(data);
          goHomeCRM();
        } else {
          await router.push({ name: 'CRMLessons', params: { unit: 'month', isFirst: true } });
        }
        await setLoaderRun(false);
      } catch (e) {
        await setLoaderRun(false);
        return e;
      }
    };

    const gotUser = ref(false);
    const getUser = async () => {
      if (gotUser.value) return;
      await setLoaderRun(true);
      try {
        const { data } = await AuthApi.getUser();
        await setActiveUser(data);
        await setLoaderRun(false);
        gotUser.value = true;
      } catch (err) {
        await setLoaderRun(false);
      }
    };

    const checkRoute = async (route) => {
      await getUser();
      if (route === 'Main') goHomeCRM();
      if (!user.value._id) return;
      await setLoaderRun(true);
      const settingsId = get(settings.value, '_id', null);
      const token = VueCookieNext.getCookie('token');
      if (!token) {
        await setLoaderRun(false);
        await router.push({ name: 'Login' });
      } else if (!settingsId) {
        // await setLoaderRun(false);
        await getSettings();
      } else {
        goHomeCRM();
        await setLoaderRun(false);
      }
    };

    const isZoomed = detectNeedZoom();

    watch(currentRoute, checkRoute);
    watch(user, checkRoute);

    onBeforeMount(checkRoute);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      isZoomed,
      checkingRoute,
      currentRoute,
      crmRoutes
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm {
  @include global-font;
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-gap: 20px;
  padding: 24px;
  width: 100%;

  &_mobile {
    padding: 0;
    grid-template-columns: 1fr;
  }

  &-main {
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, 0.02);
    border-radius: 4px;
    padding: 35px 24px 24px;
    height: calc(100vh - 48px);
    min-height: 309px;
    overflow: auto;
    //overflow: hidden;

    &_zoomed {
      height: calc(120vh - 20px);
    }

    &_mobile {
      height: auto;
      padding: 0;
      overflow: visible;
    }

    &_accompaniment {
      background: url('~@/assets/images/pianists/accompaniment-bg.png') $color-white;
      background-size: cover;
      box-shadow: $color-shadow-grey;
    }
  }
}

</style>
