<template>
  <label
    :for="label"
    class="lp-checkbox"
    :class="{
      'lp-checkbox_checked': modelValue,
      'lp-checkbox_disabled': isDisabled
    }"
  >
    <input
      class="lp-checkbox__input"
      :id="label"
      :checked="modelValue"
      @input="handlerChange"
      type="checkbox"
    >
    <slot
      v-if="hasLabelSlot"
      name="label"
    ></slot>
    <span
      v-else
      class="lp-checkbox__label"
    >
      {{ $t(label) }}
    </span>
  </label>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CustomCheckbox',
  props: {
    label: String,
    modelValue: Boolean,
    hasLabelSlot: Boolean,
    input: Object
  },
  setup (props, { emit }) {
    const isDisabled = computed(() => {
      const checkBox = props.input || {};
      const { disabled = false } = checkBox;
      return disabled;
    });

    const handlerChange = (e) => {
      if (!isDisabled.value) {
        const isChecked = e.target.checked;
        emit('update:modelValue', isChecked);
        const { handlerChange } = props.input;
        if (handlerChange) {
          handlerChange(isChecked);
        }
      }
    };
    return {
      handlerChange,
      isDisabled
    };
  }

};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-checkbox {
  display: grid;
  grid-template-columns: 16px auto;
  grid-gap: 8px;
  user-select: none;
  cursor: pointer;

  &:hover {
    &:before {
      border-color: $color-accent-hover;
    }
  }

  &:before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $color-accent;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center 3px;
    background-size: 0;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in;
  }

  &_checked {
    &:before {
      background-color: $color-accent;
      background-size: 15px 15px;
      background-position: center center;
      background-image: url("~@/assets/images/checkmark.png")
    }

    &:hover {
      &:before {
        background-color: $color-accent-hover;
      }
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: .8;

    &.lp-checkbox_checked {
      &:before {
        background-color: $color-silver-chalice;
      }
    }

    &:before {
      border-color: $color-silver-chalice;
    }

  }

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__label {
    @include global-font;
    font-weight: inherit;
    font-size: inherit;
    line-height: 16px;
  }
}

</style>
