const CURRENCY = {
  USD: 'USD',
  EUR: 'EUR',
  RUB: 'RUB',
  BYN: 'BYN',
  UAH: 'UAH',
  AED: 'AED',
  CHF: 'CHF',
  KGS: 'KGS',
  CAD: 'CAD',
  GBP: 'GBP',
  MDL: 'MDL'
};

export default CURRENCY;
