<template>
  <div
    class="lp-crm-subscriptions-item"
    :class="{
      'lp-crm-subscriptions-item_student-card': studentCard,
      'lp-crm-subscriptions-item_student-card_mobile': isMobile && studentCard,
      'lp-crm-subscriptions-item_active': selectedSubscription === item._id
    }"
    @click="updateSelectedSubscription"
  >
    <transition name="fade">
      <div
        v-if="selectedSubscription === item._id"
        class="lp-crm-subscriptions-item__icon"
        :class="{'lp-crm-subscriptions-item__icon_mobile': isMobile}"
      >
        <checkmarkIcon />
      </div>
    </transition>
    <div class="lp-crm-subscriptions-item-content">
      <div
        class="lp-crm-subscriptions-item__title"
        :class="{'lp-crm-subscriptions-item__title_mobile': isMobile}"
        :title="item.title"
      >
        {{ item.title }}
      </div>
      <div
        class="lp-crm-subscriptions-item-row"
        :class="{'lp-crm-subscriptions-item-row_mobile': isMobile}"
      >
        <div class="lp-crm-subscriptions-item__label">
          {{ $t('crm.subscriptions.item.numberLessons') }}
        </div>
        <div class="lp-crm-subscriptions-item__value">
          {{ item.amountOfLessons }}
        </div>
      </div>
      <div
        class="lp-crm-subscriptions-item-row"
        :class="{'lp-crm-subscriptions-item-row_mobile': isMobile}"
      >
        <div class="lp-crm-subscriptions-item__label">
          {{ $t('crm.subscriptions.item.price') }}
        </div>
        <div class="lp-crm-subscriptions-item__value">
          {{ passCost }} {{ item.currency }}
        </div>
      </div>
    </div>
    <div
      class="lp-crm-subscriptions-item-footer"
      v-if="!studentCard"
    >
      <button
        class="lp-button lp-button_xxs"
        @click="$emit('subscription-apply', item)"
      >
        {{ $t('crm.subscriptions.item.button') }}
      </button>
      <transition name="bounce">
        <Toolbar
          v-if="subscriptionHoverId === item._id || isMobile"
          class="lp-crm-subscriptions-item-footer__toolbar"
          :row="item"
          :column="{deleteName: 'title'}"
          :hover="subscriptionHoverId"
          @delete="$emit('delete', $event)"
          @edit="$emit('edit', $event)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import { checkmarkIcon } from '@/constants/icons/crm';
import { computed } from 'vue';
import numeral from 'numeral';
import FORMATS from '@/constants/formats';
import MobileDetect from 'mobile-detect';

export default {
  name: 'SubscriptionItem',
  components: {
    Toolbar,
    checkmarkIcon
  },
  props: {
    item: Object,
    subscriptionHoverId: String,
    studentCard: Boolean,
    selectedSubscription: String
  },
  emits: ['delete', 'edit', 'subscription-apply', 'update:selectedSubscription'],
  setup (props, { emit }) {
    const updateSelectedSubscription = () => {
      if (props.studentCard) {
        emit('update:selectedSubscription', props.item._id);
      }
    };

    const passCost = computed(() => {
      const passCost = props.item.passCost;
      return passCost ? numeral(passCost).format(FORMATS.FINANCE) : 0;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      updateSelectedSubscription,
      passCost
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-subscriptions-item {
  @include global-font;
  position: relative;
  padding: 28px 18px 18px;
  border: 1px solid $color-alto;
  box-sizing: border-box;
  border-radius: 4px;
  background: url('~@/assets/images/subscriptions-item-bg.svg');
  font-size: 14px;
  line-height: 1.2;
  color: $color-text;
  min-height: 189px;
  user-select: none;

  &_student-card {
    min-height: auto;
    cursor: pointer;
    padding: 44px 12px 28px;
    transition: border-color .2s ease;
    font-size: 12px;

    &_mobile {
      padding-top: 79px;
    }

    .lp-crm-subscriptions-item__title {
      font-size: 10px;
      margin-bottom: 12px;

     &_mobile {
        font-size: 14px;
        margin-bottom: 18px;
      }
    }

    &:hover {
      border-color: $color-accent;
    }

  }

  &_active {
    border-color: $color-accent;
  }

  &__icon {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 12px;
    height: 12px;
    color: $color-accent;

    &_mobile {
      width: 18px;
      height: 18px;
    }
  }

  &__title {
    margin-bottom: 21px;
    font-weight: 800;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__label {
    font-weight: bold;
  }

  &__value {
    font-weight: bold;
    color: $color-accent;
  }

}

.lp-crm-subscriptions-item-row {
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }

  &_mobile {
    font-size: 14px;
  }
}

.lp-crm-subscriptions-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;

  &__toolbar {
    margin: -10px;
  }
}
</style>

<style lang="scss">
.lp-crm-subscriptions-item {

  &__icon {
    path {
      fill: currentColor
    }
  }

}
</style>

<!-- Created by zas on 05.07.2021  -->
