import numeral from 'numeral';

export default ({
  series,
  seriesIndex,
  dataPointIndex
}, currency, format) => {
  const value = series[seriesIndex][dataPointIndex];
  const postfix = currency || '';
  const number = format ? numeral(value).format(format) : value;
  return `<div class="chart-tooltip">${number} ${postfix}</div>`;
};
