<template>
  <div
    class="lp-display-file"
  >
    <div
      class="lp-display-file-body"
      :class="{
        'lp-display-file-body_scrolling': isTeacher
      }"
      ref="sharingFileRef"
      @scroll="handleScroll"
      :style="{maxWidth: `${videoWidth - 16 - videoWidthLocaleWithZoom}px`}"
    >
      <template v-if="file.isImage">
        <img
          class="lp-display-file-body__img"
          :src="file.url"
          alt=""
        />
      </template>
      <template v-if="file.isPdf">
        <VuePdfEmbed
          :source="file.url"
          :width="videoWidth - 32 - videoWidthLocaleWithZoom"
        />
      </template>
    </div>

    <button
      class="lp-display-file__end-button"
      :style="{right: `${8 + videoWidthLocale}px`}"
      @click.stop="handleEndSharing"
      v-if="isTeacher"
    >
      {{ $t('lessonCall.stopSharing') }}
    </button>

    <div class="lp-display-file-tabs__container">
      <span
        class="lp-display-file-tabs__nav lp-display-file-tabs__nav_prev"
        v-if="displayBackNav"
        @click="handleScrollTabs(-1)"
      >
        <arrowLeftIcon />
      </span>

      <div
        class="lp-display-file-tabs"
        :class="{
          'lp-display-file-tabs_border-radius': !displayNextNav
        }"
        ref="tabsRef"
      >
        <div
          class="lp-display-file-tabs-tab"
          :class="{'lp-display-file-tabs-tab_active': file.id === sharingFile.id}"
          v-for="file of sharingFileList"
          :key="file.id"
          @click="handleSetFile(file)"
        >
          <span class="lp-display-file-tabs-tab__text">
            {{ file.name }}
          </span>
          <span
            class="lp-display-file-tabs-tab__close"
            @click.stop="handleCloseFile(file)"
          >
            <closeModalIcon />
          </span>
        </div>
      </div>

      <span
        class="lp-display-file-tabs__nav lp-display-file-tabs__nav_next"
        v-if="displayNextNav"
        @click="handleScrollTabs(1)"
      >
        <arrowLeftIcon />
      </span>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, watch, ref, computed, nextTick, onMounted } from 'vue';
import VuePdfEmbed from 'vue-pdf-embed';
import ROLE_TYPES from '@/constants/enums/roles';
import { useStore } from 'vuex';
import { throttle, uniqueId } from 'lodash';
import { arrowLeftIcon, closeModalIcon } from '@/constants/icons';

export default {
  name: 'DisplaySharingFile',
  components: {
    VuePdfEmbed,
    closeModalIcon,
    arrowLeftIcon
  },
  props: {
    file: Object,
    videoWidth: Number,
    localVideoStreamId: String,
    remoteVideoStreamId: String
  },
  setup (props) {
    const store = useStore();

    const connection = computed(() => store.getters.rtcConnect);
    const participants = computed(() => store.getters.participants);
    const sharingFile = computed(() => store.getters.sharingFile);
    const sharingFileList = computed(() => store.getters.sharingFileList);
    const setSharingFile = (data) => store.dispatch('setSharingFile', data);
    const setSharingFileList = (data) => store.dispatch('setSharingFileList', data);
    const activeFileIndex = computed(() => sharingFileList.value.findIndex(f => f.id === sharingFile.value.id));
    const displayBackNav = computed(() => sharingFileList.value.length > 3);
    const displayNextNav = computed(() => sharingFileList.value.length > 3);

    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);

    const tabsRef = ref(null);
    const videoWidthLocale = ref(0);
    const sharingFileRef = ref(null);
    const lastScroll = ref(0);
    const fileScroll = computed(() => props.file?.scroll);

    const sendScrollPosition = (value) => connection.value.send({ type: 'scrollSharingFile', value });
    const sendScrollPositionDebounced = computed(() => throttle(sendScrollPosition, 1000));

    const zoom = computed(() => {
      const { innerWidth } = window;
      if (innerWidth >= 1921 && innerWidth < 2160) {
        return 1.25;
      } else if (innerWidth >= 2160 && innerWidth < 2560) {
        return 1.5;
      } else if (innerWidth >= 2560) {
        return 2;
      } else {
        return 1;
      }
    });
    const videoWidthLocaleWithZoom = computed(() => videoWidthLocale.value * zoom.value);

    const handleScroll = (e) => {
      const { scrollTop } = e.target;
      sendScrollPositionDebounced.value(scrollTop);
      lastScroll.value = scrollTop;
    };

    const handleCloseFile = async (file) => {
      const activeIndex = sharingFileList.value.findIndex(f => f.id === file.id);
      await setSharingFileList(sharingFileList.value.filter(f => f.id !== file.id));

      if (file.id === sharingFile.value.id) {
        const pendingFile = sharingFileList.value[activeIndex - 1]
          || sharingFileList.value[activeIndex + 1]
          || sharingFileList.value[0]
          || null;
        handleSetFile(pendingFile);
      }
    };

    const handleEndSharing = async () => {
      await setSharingFileList([]);
      handleSetFile(null);
    };

    const handleSetWidth = () => {
      const { localVideoStreamId, remoteVideoStreamId } = props;
      const ids = [localVideoStreamId, remoteVideoStreamId, `video_${localVideoStreamId}`];
      const nodes = ids.map(id => document.getElementById(id)).filter(item => item);
      const widths = nodes.map(node => node.clientWidth || 0);
      const maxWidth = Math.max(...widths);
      videoWidthLocale.value = isNaN(maxWidth) || !widths.length ? 0 : maxWidth;
    };

    const setScroll = () => {
      if (!sharingFileRef.value || isTeacher.value) return;
      sharingFileRef.value.scrollTo({
        top: fileScroll.value,
        behavior: 'smooth'
      });
    };

    const handleSetFile = (file) => {
      setSharingFile(file);
      connection.value.send({ type: 'loadSharingFile', file: file });
    };

    const handleScrollTabs = (factor) => {
      if (!tabsRef.value) return;
      tabsRef.value.scrollTo({
        left: (tabsRef.value.scrollLeft) + (factor * 256),
        behavior: 'smooth'
      });
    };

    watch(props, handleSetWidth);
    watch(fileScroll, setScroll);

    watch(participants, () => {
      if (!props.file) return;
      connection.value.send({ type: 'loadSharingFile', file: { ...props.file, scroll: lastScroll.value } });
      handleSetWidth();
    });

    watch(activeFileIndex, () => {
      if (!tabsRef.value) return;
      tabsRef.value.scrollTo({
        left: (activeFileIndex.value - 1) * 126,
        behavior: 'smooth'
      });
    });

    onBeforeMount(() => nextTick(handleSetWidth));
    onMounted(() => nextTick(setScroll));

    return {
      isTeacher,
      sharingFileList,
      sharingFile,
      sharingFileRef,
      videoWidthLocaleWithZoom,
      videoWidthLocale,
      tabsRef,
      activeFileIndex,
      displayBackNav,
      displayNextNav,
      handleScroll,
      handleSetFile,
      handleCloseFile,
      handleEndSharing,
      uniqueId,
      handleScrollTabs
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

@function calcHeight($zoom: 1) {
  $toolbar: #{140 * $zoom}px;
  @return calc(100vh - #{$toolbar} - 16px);
}

.lp-display-file {
  height: 100%;

  &-body {
    position: relative;
    display: flex;
    align-items: flex-start;
    background-color: $color-white;
    margin: 8px;
    border-radius: 7px;
    width: 100%;
    height: calcHeight();
    overflow: hidden;

    @media (min-width: 1921px) {
      height: calcHeight(1.25);
    }

    @media (min-width: 2160px) {
      height: calcHeight(1.5);
    }

    @media (min-width: 2560px) {
      height: calcHeight(2);
    }

    &_scrolling {
      overflow: auto;
    }

    &__img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__end-button {
    position: absolute;
    bottom: 148px;
    right: 0;
    padding: 10px;
    background: $color-crimson;
    border-radius: 4px 0 0;
    color: $color-white;
    font-weight: 700;
    font-size: 9px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.02em;
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover {
      background-color: $color-guardsman-red;
    }

    @media (min-width: 1921px) {
      zoom: 1.25;
      transform: translateX(4px);
      bottom: 146px;
    }

    @media (min-width: 2160px) {
      zoom: 1.5;
      bottom: 145px;
    }

    @media (min-width: 2560px) {
      zoom: 2;
      transform: translateX(5px);
      bottom: 143px;
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 378px;
    overflow: hidden;
    user-select: none;

    &_border-radius {
      border-radius: 0 6px 0 0;
    }

    &__container {
      position: absolute;
      bottom: 148px;
      left: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      z-index: 2;

      @media (min-width: 1921px) {
        zoom: 1.25;
        left: 5px;
        bottom: 146px;
      }

      @media (min-width: 2160px) {
        zoom: 1.5;
        left: 4px;
        bottom: 144px;
      }

      @media (min-width: 2560px) {
        zoom: 2;
        left: 3px;
        bottom: 143px;
      }
    }

    &__nav {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      min-width: 36px;
      z-index: 3;
      background-color: $color-outer-space;
      cursor: pointer;
      color: $color-white;
      transition: background-color 200ms ease;

      &:hover {
        background-color: darken($color-outer-space, 3%);
      }

      &_prev {
        margin-right: -1px;
        transform: scale(-1);
        border-left: 1px solid $color-shark;
      }

      &_next {
        margin-left: -1px;
        border-radius: 0 6px 0 0;
        border-left: 1px solid $color-shark;
      }
    }

    &-tab {
      position: relative;
      display: grid;
      gap: 8px;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: space-between;
      padding: 12px 15px 12px 12px;
      background-color: $color-outer-space;
      transition: background-color 200ms ease;
      border-right: 1px solid $color-shark;
      cursor: pointer;

      &:hover {
        background-color: darken($color-outer-space, 3%);
      }

      &:last-child {
        border-right: none;
      }

      &_active {
        background-color: $color-shark;
      }

      &__text {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: $color-white;
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__close {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6px;
        height: 6px;
        padding: 8px;
        box-sizing: content-box;
        transition: opacity 200ms ease;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

</style>
