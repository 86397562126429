import { uniqueId } from 'lodash';
import numeral from 'numeral';
import FORMATS from '@/constants/formats';
import { subscriptionsIcon } from '@/constants/icons/crm';

const columns = [
  {
    type: 'user',
    prop: 'fullName',
    photo: 'uploadPhoto.url',
    title: 'crm.students.table.columns.name',
    width: 'minmax(150px, 1fr)'
  },
  {
    type: 'status',
    prop: 'status',
    title: 'crm.students.table.columns.status',
    justifyContent: 'center',
    textAlign: 'center',
    width: '155px'
  },
  {
    type: 'number',
    prop: 'finishedLessons',
    format: '0',
    title: 'crm.students.table.columns.lessons',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'minmax(100px, 1fr)'
  },
  {
    type: 'number',
    format: FORMATS.FINANCE,
    prop: 'balance',
    needCurrency: true,
    title: 'crm.students.table.columns.balance',
    getClassName: (val) => {
      const num = numeral(val).value();
      if (num < 0) return 'lp-table-column-item_negative';
      return '';
    },
    justifyContent: 'center',
    textAlign: 'center',
    width: 'minmax(140px, 1fr)',
    iconProp: {
      showValue: true,
      prop: 'crmLessonPass',
      icon: subscriptionsIcon,
      tooltipText: 'crm.subscriptions.tooltip',
      style: {
        marginLeft: '15px'
      }
    }
  },
  {
    type: 'email',
    prop: 'email',
    title: 'crm.students.table.columns.email',
    width: 'minmax(200px, 1fr)'
  },
  {
    type: 'phone',
    prop: 'phone',
    title: 'crm.students.table.columns.phone',
    width: 'minmax(150px, 1fr)'
  },
  {
    toolbar: true,
    title: '',
    width: '38px',
    deleteName: 'fullName'
  }
];

const studentsColumns = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default studentsColumns;
