<template>
  <div class="stp-card">
    <div
      class="stp-header"
      :class="{'stp-header__mobile' : isMobile}"
    >
      <div class="stp-header-container">
        <studentCardLogoIcon />
      </div>
    </div>
    <div
      v-if="student"
      class="stp-content"
      :class="{'stp-content__mobile' : isMobile}"
    >
      <div class="stp-block">
        <div class="stp-profile">
          <div class="stp-profile-main-info">
            <div
              class="stp-profile-main-info-cover"
            >
              <img
                :src="isMobile ? coverMobile : cover"
                class="stp-profile-main-info-cover_image"
                alt="Profile cover"
              >
            </div>
            <div
              class="stp-profile-main-info-common"
              :class="{'stp-profile-main-info-common__mobile' : isMobile}"
            >
              <div class="stp-profile-main-info-common-avatar">
                <img
                  class="stp-profile-main-info-common-avatar_photo"
                  :class="{'stp-profile-main-info-common-avatar_photo__mobile' : isMobile}"
                  v-if="student.uploadPhoto.url"
                  :src="student.uploadPhoto.url"
                  alt="Student photo"
                >
                <user-icon
                  :isMobile="isMobile"
                  v-else
                />
                <LPTooltip
                  class="stp-profile-main-info-common-avatar_status"
                  isComment
                  :text="getStatusText()"
                >
                  <div
                    class="stp-profile-main-info-common-avatar_status__bullet"
                    :style="{backgroundColor: getColorByStatus()}"
                  />
                </LPTooltip>
              </div>
              <div
                class="stp-profile-main-info-common-credentials"
                :class="{'stp-profile-main-info-common-credentials__mobile' : isMobile}"
              >
                <span
                  class="stp-profile-main-info-common-credentials_name"
                  :class="{'stp-profile-main-info-common-credentials_name__mobile' : isMobile}"
                >
                  {{ student.fullName }}
                </span>
                <ColoredLabel
                  :isMobile="isMobile"
                  class="stp-profile-main-info-common-credentials_credits"
                  :text="`${$t('student.profile.cashLeft')}: ${student.balance} ${student.currency}`"
                />
              </div>
            </div>
          </div>
          <div
            class="stp-profile-common"
            :class="{'stp-profile-common__mobile' : isMobile}"
          >
            <span class="stp-profile-common-title">
              {{ `${$t('student.profile.mainInfo')}:` }}
            </span>
            <div
              class="stp-profile-common-info"
              :class="{'stp-profile-common-info__mobile' : isMobile}"
            >
              <template v-if="!isMobile">
                <span class="stp-profile-common-info_title-column">
                  {{ `${$t('student.profile.virtualClass')}:` }}
                </span>
                <CopyLink
                  :fullWidth="true"
                  :link="student.virtualClassUrl"
                />
              </template>
              <span class="stp-profile-common-info_title-column">
                {{ `${$t('student.profile.lessonCost')}:` }}
              </span>
              <span>{{ `${student.individualLessonPrice || settings.defaultLessonCost} ${student.currency}` }}</span>
              <template v-if="student.birthDate">
                <span class="stp-profile-common-info_title-column">
                  {{ `${$t('student.profile.birthday')}:` }}
                </span>
                <span>{{ student.birthDate }}</span>
              </template>
              <span class="stp-profile-common-info_title-column">
                {{ `${$t('student.profile.phone')}:` }}
              </span>
              <span>{{ student.phone }}</span>
              <template v-if="student.email">
                <span class="stp-profile-common-info_title-column">
                  {{ `${$t('student.profile.email')}:` }}
                </span>
                <span>{{ student.email }}</span>
              </template>
              <span
                class="stp-profile-common-info_title-column"
                v-if="student.socialMedia.length"
              >
                {{ `${$t('student.profile.social')}:` }}
              </span>
              <div
                class="stp-profile-common-info_links"
                v-if="student.socialMedia.length"
              >
                <a
                  class="stp-profile-common-info_links__link"
                  v-for="socialMedia in student.socialMedia"
                  :key="socialMedia.socialMediaType"
                  :href="socialMedia.socialMediaLink"
                  target="_blank"
                >
                  <component :is="getSocialIcon(socialMedia.socialMediaType)" />
                </a>
              </div>
            </div>
            <div
              v-if="isMobile"
              class="stp-profile-common-virtual__mobile"
            >
              <span class="stp-profile-common-info_title-column">
                {{ $t('student.profile.virtualClass') }}
              </span>
              <CopyLink
                :isMobile="isMobile"
                :link="student.virtualClassUrl"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="student.crmLessonPass"
        class="stp-block"
      >
        <div
          class="stp-lesson-pass"
          :class="{'stp-lesson-pass__mobile' : isMobile}"
        >
          <span class="stp-lesson-pass-title">
            {{ student.crmLessonPass.title.toUpperCase() }}
          </span>
          <div
            class="stp-lesson-pass-data"
            :class="{'stp-lesson-pass-data__mobile' : isMobile}"
          >
            <div
              class="stp-lesson-pass-data-total"
              :class="{'stp-lesson-pass-data-total__mobile' : isMobile}"
            >
              <span>{{ `${$t('student.lessonPass.amount')}:` }}</span>
              <ColoredLabel :text="student.crmLessonPass.amountOfLessons.toString()" />
              <span>{{ `${$t('student.lessonPass.cost')}:` }}</span>
              <ColoredLabel :text="`${student.crmLessonPass.passCost} ${student.crmLessonPass.currency}`" />
            </div>
            <div
              class="stp-lesson-pass-data-left"
              :class="{'stp-lesson-pass-data-left__mobile' : isMobile}"
            >
              <div
                v-for="lesson in student.crmLessonPass.amountOfLessons"
                class="stp-lesson-pass-data-left_item"
                :key="lesson"
              >
                <check-icon v-if="lesson <= student.crmLessonPass.finishedLessonsCount" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="studentPayments.length || pendingLessons.length"
        class="stp-activities"
        :class="[
          {'stp-activities__mobile' : isMobile},
          {'stp-activities__empty' : !studentPayments.length || !pendingLessons.length}
        ]"
      >
        <div
          v-if="studentPayments.length"
          class="stp-block"
        >
          <PaymentHistory
            :payments="studentPayments"
            :isMobile="isMobile"
            :lang="lang"
            :currency="student.currency"
          />
        </div>
        <div
          v-if="pendingLessons.length"
          class="stp-block"
        >
          <PendingLessons
            :pendingLessons="pendingLessons"
            :isMobile="isMobile"
            :lang="lang"
          />
        </div>
      </div>
      <div
        v-if="!isMobile"
        class="stp-block"
      >
        <StudentCalendar :teacherId="student.teacherId" />
      </div>
      <div
        v-if="completedLessons.length"
        class="stp-block"
      >
        <CompletedLessons
          :completedLessons="completedLessons"
          :isMobile="isMobile"
          :lang="lang"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { studentCardLogoIcon, userIcon } from '@/constants/icons';
import { useRoute } from 'vue-router';
import { computed, onBeforeMount, ref } from 'vue';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  OkIcon,
  PinterestIcon,
  TwitterIcon,
  VkIcon,
  YouTubeIcon
} from '@/constants/icons/iconsSocialMedia';
import CopyLink from '@/components/StudentProfile/CopyLink';
import ColoredLabel from '@/components/StudentProfile/ColoredLabel';
import { checkIcon } from '@/constants/icons/crm';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { useI18n } from 'vue-i18n';
import getPhoneString from '@/constants/utils/getPhoneString';
import cover from '@/assets/images/studentProfile/img-min.jpg';
import coverMobile from '@/assets/images/studentProfile/img-small-min.jpg';
import StudentCalendar from '@/components/StudentProfile/StudentCalendar/StudentCalendar';
import CustomToggle from '@/components/Main/Inputs/CustomToggle';
import moment from 'moment';
import getLanguage from '@/constants/utils/getLanguage';
import StudentProfileAPI from '@/api/CRM/studentProfile';
import MobileDetect from 'mobile-detect';
import CompletedLessons from '@/components/StudentProfile/StudentProfileTables/CompletedLessons';
import PendingLessons from '@/components/StudentProfile/StudentProfileTables/PendingLessons';
import PaymentHistory from '@/components/StudentProfile/StudentProfileTables/PaymentHistory';
import { useStore } from 'vuex';
import formatDateToTimePeriod from '@/constants/utils/formatDateToTimePeriod';

export default {
  name: 'StudentAccount',

  components: {
    studentCardLogoIcon,
    userIcon,
    CopyLink,
    ColoredLabel,
    checkIcon,
    LPTooltip,
    StudentCalendar,
    CustomToggle,
    CompletedLessons,
    PendingLessons,
    PaymentHistory
  },

  setup () {
    const { params: { id: studentId } } = useRoute();

    const lang = ref('');

    const md = new MobileDetect(window.navigator.userAgent);

    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const student = ref(null);

    const settings = ref(null);

    const { t } = useI18n();

    const store = useStore();

    const getStatusText = () => {
      switch (student.value.status) {
      case 'Active':
        return t('crm.students.status.active');
      case 'Payment_Required':
        return t('crm.students.status.notPaid');
      default:
        return t('crm.students.status.notActive');
      }
    };

    const getColorByStatus = () => {
      switch (student.value.status) {
      case 'Active':
        return '#48D16E';
      case 'Payment_Required':
        return '#FF0000';
      default:
        return '#858585';
      }
    };

    const getSocialIcon = (type) => {
      switch (type) {
      case 1:
        return FacebookIcon();
      case 2:
        return PinterestIcon();
      case 3:
        return TwitterIcon();
      case 4:
        return YouTubeIcon();
      case 5:
        return InstagramIcon();
      case 6:
        return LinkedInIcon();
      case 7:
        return VkIcon();
      case 8:
        return OkIcon();
      }
    };

    const setStudentsPayments = async () => {
      try {
        const { data: { resource } } = await StudentProfileAPI.getPaymentsByStudentId(studentId);
        const payments = resource.map(({ crmLessonStart, value, currency, crmLessonPassId }) => ({
          date: moment(crmLessonStart).
            locale(lang.value.toLowerCase()).
            format('DD MMM, YYYY'),
          value: Number(value.toFixed(2)),
          currency,
          isSubscription: !!crmLessonPassId
        }));
        await store.dispatch('setStudentPayments', payments);
      } catch (e) {
        console.log(e.message);
      }
    };

    const studentPayments = computed(() => store.getters.studentPayments);

    const setPendingLessons = async () => {
      try {
        const today = moment().startOf('day');
        const { data: { resource } } = await StudentProfileAPI.getPendingLessonsByStudent(studentId, today);
        const pendingLessons = resource.map(({
          crmLessonStart, crmLessonEnd, crmLessonCost, currency, color, crmLessonPassId, isTrial
        }) => ({
          date: moment(crmLessonStart).
            locale(lang.value.toLowerCase()).
            format('DD MMMM YYYY'),
          timePeriod: formatDateToTimePeriod(crmLessonStart, crmLessonEnd, lang.value),
          cost: crmLessonPassId ? null : Number(crmLessonCost.toFixed(2)),
          currency,
          color,
          isTrial
        }));
        await store.dispatch('setPendingLessons', pendingLessons);
      } catch (e) {
        console.log(e.message);
      }
    };

    const pendingLessons = computed(() => store.getters.pendingLessons);

    const setCompletedLessons = async () => {
      try {
        const { data: { resource } } = await StudentProfileAPI.getCompletedLessonsByStudent(studentId);
        const completedLessons = resource.map(({
          crmLessonStart, crmLessonEnd, crmLessonCost, currency, crmLessonPassId, isTrial
        }) => ({
          date: moment(crmLessonStart).
            locale(lang.value.toLowerCase()).
            format('DD MMM, YYYY'),
          timePeriod: formatDateToTimePeriod(crmLessonStart, crmLessonEnd, lang.value),
          cost: crmLessonPassId ? null : Number(crmLessonCost.toFixed(2)),
          currency,
          isTrial
        }));
        await store.dispatch('setCompletedLessons', completedLessons);
      } catch (e) {
        console.log(e.message);
      }
    };

    const completedLessons = computed(() => store.getters.completedLessons);

    onBeforeMount(async () => {
      await setStudentsPayments();

      await setPendingLessons();

      await setCompletedLessons();

      const { data } = await StudentProfileAPI.getStudentById(studentId);

      const { data: teacherSettings } = await StudentProfileAPI.
        getTeacherSettingUnauthorized(data.teacherId);

      settings.value = teacherSettings;

      const { data: { balance } } = await StudentProfileAPI.getBalance(studentId);

      const phone = getPhoneString(data.phone);

      lang.value = getLanguage(data.countryCode);

      const birthDate = data.birthDate
        ? moment(data.birthDate).
          locale(lang.value.toLowerCase()).
          format('DD MMMM YYYY')
        : null;

      student.value = { ...data, phone, birthDate, balance };
    });

    return {
      student,
      getSocialIcon,
      getColorByStatus,
      getStatusText,
      cover,
      lang,
      coverMobile,
      isMobile,
      completedLessons,
      pendingLessons,
      studentPayments,
      settings
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/_variables.scss";

:first-child {
  margin-top: 0 !important;
}

.stp-card {
  width: 100%;
  padding-bottom: 67px;
}

.stp-header {
  width: 100%;
  height: 80px;
  padding-left: 32px;
  background: $color-white;

  &__mobile {
    padding-left: 20px;
    height: 60px;
  }

  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.stp-content {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__mobile {
    margin-top: unset;
  }
}

.stp-block {
  width: 100%;
  margin-top: 20px;
  background-color: $color-white;
  box-shadow: $color-shadow-gray-container;
  border-radius: 4px;

  &:first-child {
    border-radius: 0 0 4px 4px;
    box-shadow: unset;
    margin-top: unset;
  }
}

.stp-profile {

  &-main-info {
    position: relative;
    display: flex;
    flex-direction: column;

    &-cover {
      width: 100%;

      &_image {
        width: 100%;
        height: 100%;
      }
    }

    &-common {
      margin-top: -42px;
      margin-left: 5%;
      display: flex;

      &__mobile {
        margin-left: 12px;
      }

      &-avatar {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        border: 4px solid $color-white;
        border-radius: 100%;
        box-shadow: $color-shadow-grey;
        background-color: $color-white;

        &_photo {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 100%;

          &__mobile {
            width: 120px;
            height: 120px;
          }
        }

        &_status {
          position: absolute;
          bottom: 8.5%;
          right: 8.5%;

          &__bullet {
            width: 16px;
            height: 16px;
            border: 2px solid $color-white;
            border-radius: 100%;
          }
        }
      }

      &-credentials {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin: 20px 0 0 31px;

        &__mobile {
          margin: 16px 0 0 16px;
        }

        &_credits {
          margin-top: 8px;
        }

        &_name {
          font-size: 20px;
          line-height: 125%;
          font-weight: 700;

          &__mobile {
            font-size: 16px;
          }
        }
      }
    }
  }

  &-common {
    padding: 28px 40px 40px;

    &__mobile {
      padding: 28px 12px 24px;
    }

    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    &-info {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 35% auto;
      grid-gap: 32px;
      grid-auto-rows: 25px;
      align-items: center;

      &__mobile {
        grid-gap: 24px;
      }

      span {
        font-size: 16px;
        line-height: 20px;
      }

      &_title-column {
        font-weight: 700;
      }

      &_links {
        display: flex;
        align-items: center;

        &__link {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;

          & + a {
            margin-left: 10px;
          }
        }
      }
    }

    &-virtual__mobile {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      grid-gap: 5px;

      &-copy__mobile span {
        display: block;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.stp-lesson-pass {
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;

  & span {
    font-weight: 700;
    line-height: 120%;
    font-size: 16px;
  }

  &__mobile {
    padding: 24px 12px;
  }

  &-data {
    margin-top: 26px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    &-total {
      width: 100%;
      display: grid;
      grid-gap: 12px;
      grid-template-columns: 35% auto;

      &__mobile {
        grid-template-columns: 55% auto;
        grid-gap: 16px;
      }
    }

    &-left {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(10, 32px);

      &__mobile {
        margin-top: 24px;
      }

      &_item {
        height: 32px;
        width: 32px;
        border: 1px solid $color-alto;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.stp-activities {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;

  & > div {
    margin-top: unset;
  }

  &__empty {
    grid-template-columns: 1fr;
  }

  &__mobile {
    display: flex;
    flex-direction: column-reverse;
  }
}

</style>
