<template>
  <div
    class="lp-webcam-devices"
    :class="{'lp-webcam-devices_mobile': isMobile}"
  >
    <div
      class="lp-webcam-devices-head"
      @click.stop="$emit('update:open', false)"
    >
      <div class="lp-webcam-devices-head__icon">
        <arrowLeftIcon />
      </div>
      <div class="lp-webcam-devices-head__title">
        {{ $t('roomSettings.video.camera') }}
      </div>
    </div>
    <div class="lp-webcam-devices-body">
      <div
        class="lp-webcam-devices-item"
        :class="{'lp-webcam-devices-item_active': current === item.deviceId}"
        @click.stop="setDevice(item)"
        v-for="item of devices"
        :key="item.deviceId"
      >
        <div class="lp-webcam-devices-item__value">
          {{ item.label }}
        </div>
        <div class="lp-webcam-devices-item__checked">
          <checkmarkIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { isEqual, get } from 'lodash';
import { arrowLeftIcon, checkmarkIcon } from '@/constants/icons';

export default {
  name: 'WebcamDevices',
  components: {
    arrowLeftIcon,
    checkmarkIcon
  },
  props: {
    isMobile: Boolean,
    device: Object,
    devices: Array
  },
  emits: ['update:open', 'update:device'],
  setup (props, { emit }) {
    const current = computed(() => get(props.device, 'deviceId', 0));

    const setDevice = (device) => {
      if (isEqual(device, props.device)) {
        emit('update:open', false);
        return;
      }
      emit('update:device', device);
    };

    return {
      current,
      setDevice
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-webcam-devices {
  position: absolute;
  background-color: $color-white;
  right: -224px;
  bottom: 0;
  width: 214px;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba($color-black, 0.05);
  border-radius: 4px;
  color: $color-text;
  z-index: 10;

  &_mobile {
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    height: auto;
    width: 100vw;
    z-index: 101;
  }

  &-head {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: start;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    color: $color-text;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    padding: 16px 12px;
    border-bottom: 1px solid $color-alto;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    user-select: none;

    &__icon {
      transform: scale(-1);
    }
  }

  &-body {
    display: grid;
    grid-gap: 8px;
    padding: 8px 6px;
    white-space: nowrap;
  }

  &-item {
    display: grid;
    grid-gap: 21px;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    border-radius: 3px;
    background-color: $color-white;
    white-space: nowrap;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    color: $color-text;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;

    &_active &__checked {
      transform: scale(1);
    }

    &__checked {
      transform: scale(0);
      transition: 0.3s ease-in;
    }

    &:hover {
      background-color: $color-moon-raker;
    }

    &__value {
      font-weight: 500;
      font-size: 13px;
      line-height: 125%;
      color: $color-text;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

</style>
