const RU = 'RU';
const BY = 'BY';
const KZ = 'KZ';
const UA = 'UA';
const MD = 'MD';

const countriesSng = [
  RU,
  BY,
  KZ,
  UA,
  MD
];

export default countriesSng;