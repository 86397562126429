<template>
  <div
    class="lp-toolbar"
    ref="toolbarEl"
    v-click-outside="closeOptions"
  >
    <div
      class="lp-toolbar__more"
      :class="{'lp-toolbar__icon_pencil': icon === 'pencil'}"
      :style="style"
      @click.stop="openOptions = !openOptions"
    >
      <pencilIcon
        v-if="icon === 'pencil'"
      />
      <dotsMenu v-else-if="vertical" />
      <moreIcon
        v-else
        :style="moreStyle"
      />
    </div>
    <teleport to="#app">
      <transition name="fade">
        <div
          class="lp-toolbar-options__wrapper"
          v-if="isMobile && openOptions"
          @click="closeOptions"
        />
      </transition>
    </teleport>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition :name="isMobile ? 'slide-select-mobile' : 'bounce'">
        <div
          class="lp-toolbar-options"
          :class="[customClass]"
          v-show="openOptions"
          @click.stop
        >
          <div
            v-for="item of defaultButtons"
            :key="item.type"
          >
            <div
              class="lp-toolbar-options-item"
              :class="{'lp-toolbar-options-item_mobile': isMobile}"
              @click.stop="onButtonCLick(item, $event)"
            >
              <component
                class="lp-toolbar-options-item__icon"
                :is="item.icon"
              />
              <div class="lp-toolbar-options-item__title">
                {{ $t(item.name) }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
    <teleport
      to="#app"
      :disabled="calendarDay ? false : !isMobile"
    >
      <transition name="bounce">
        <AcceptDelete
          v-if="displayTwoSteps"
          :class="[customClass, {'lp-toolbar__two-steps': calendarDay}]"
          v-model:status="displayTwoSteps"
          :item="row"
          :name="deleteName"
          :isZoomed="isZoomed"
          :position="position"
          :center="isMobile"
          :background="isMobile"
          :question="currentItem.question || question"
          @accept="acceptDelete"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { moreIcon, pencilToolbarIcon, trashToolbarIcon, pencilIcon } from '@/constants/icons/crm';
import { dotsMenu } from '@/constants/icons';
import { computed, onBeforeMount, ref, watch } from 'vue';
import AcceptDelete from '@/components/Main/Table/Toolbar/AcceptDelete';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Toolbar',
  components: {
    AcceptDelete,
    moreIcon,
    pencilIcon,
    dotsMenu
  },
  props: {
    timetable: Boolean,
    calendarDay: Boolean,
    isZoomed: Boolean,
    hover: String,
    row: Object,
    column: Object,
    style: String,
    moreStyle: Object,
    customClass: String,
    icon: {
      type: String,
      default: ''
    },
    question: String,
    arrayButtons: {
      type: Array,
      default: () => ([])
    },
    vertical: Boolean
  },
  emits: ['delete', 'edit', 'confirm'],
  setup (props, { emit }) {
    const defaultButtons = ref( [
      { id: 1, name: 'crm.students.table.toolbar.edit', icon: pencilToolbarIcon, type: 'edit' },
      { id: 2, name: 'crm.students.table.toolbar.delete', icon: trashToolbarIcon, type: 'delete' }
    ]);

    watch(() => props.arrayButtons, (value) => {
      defaultButtons.value = value;
    });

    const deleteName = computed(() => get(props.row, props.column.deleteName, ''));
    const openOptions = ref(false);
    const closeOptions = () => openOptions.value = false;

    const toolbarEl = ref(null);
    const displayTwoSteps = ref(false);
    const acceptDelete = () => {
      emit(currentItem.value.type, props.row);
      closeOptions();
    };

    watch(() => props.hover, () => {
      displayTwoSteps.value = false;
      closeOptions();
    });

    const position = ref(null);
    const currentItem = ref(null);
    const onButtonCLick = (item, e) => {
      const { twoSteps, type } = item;
      if (type === 'delete' || twoSteps) {
        currentItem.value = item;
        onOpenTwoSteps(e);
      } else {
        emit(item.type, props.row);
        closeOptions();
      }
    };

    const onOpenTwoSteps = (e) => {
      displayTwoSteps.value = !displayTwoSteps.value;
      openOptions.value = false;

      if (props.timetable) {
        const { clientX, clientY }  = e;
        position.value = {
          left: props.isZoomed ? (clientX * 1.2) - 160 : clientX - 200,
          top: props.isZoomed ? (clientY * 1.2) - 20 : clientY - 50
        };
      }
      if (props.calendarDay) {
        const { clientX }  = e;
        const { top } = e.target.getBoundingClientRect();
        const index = props.arrayButtons.findIndex(el => el.type === currentItem.value.type);
        position.value = {
          left: props.isZoomed ? (clientX * 1.2) - 160 : clientX - 200,
          top: props.isZoomed ? top - (index * 50) : top - (index * 50)
        };
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    let body = null;
    watch(openOptions, (val) => {
      if (!isMobile.value) return;
      if (val && body) {
        body.style.overflow = 'hidden';
      } else if (!val && body) {
        body.style.overflow = 'visible';
      }
    });

    onBeforeMount(() => {
      body = document.querySelector('body');
      if (props.arrayButtons.length) defaultButtons.value = props.arrayButtons;
    });

    return {
      defaultButtons,
      position,
      isMobile,
      toolbarEl,
      openOptions,
      closeOptions,
      deleteName,
      displayTwoSteps,
      onButtonCLick,
      acceptDelete,
      onOpenTwoSteps,
      currentItem
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-toolbar {
  @include global-font;
  position: relative;

  &__more {
    color: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: 0.15s ease-out;

    img {
      width: 18px;
      object-fit: cover;
    }

    svg path {
      transition: 0.25s ease-in-out;
    }

    &:hover {
      transform: scale(1.1);
      svg path {
        fill: $color-dove-gray;
      }
    }
  }

  &__icon {
    &_pencil {
      padding: 0;

      svg {
        height: 12px;
        width: 12px;

        path {
          fill: currentColor;
        }
      }

      &:hover {
        transform: scale(1.1);
        svg path {
          fill: currentColor;
        }
      }
    }
  }

  &-options {
    display: grid;
    grid-gap: 8px;
    background: $color-white;
    box-shadow: 0 0 17px rgba($color-black, 0.08);
    border-radius: 4px;
    padding: 8px;
    z-index: 5;
    position: absolute;
    left: -135px;

    &-up {
      top: -80px;
      z-index: 700000;
    }

    &_min-width {
      min-width: 180px;
    }

    @media (max-width: 481px), (max-height: 481px) and (orientation: landscape) {
      min-width: 0;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      border-radius: 0;
      z-index: 103;
    }

    &__wrapper {
      @include global-font;
      position: absolute;
      top: 38px;
      right: 0;

      @media (max-width: 481px), (max-height: 481px) and (orientation: landscape) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        height: calc(var(--vh, 1vh) * 100);
        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        display: grid;
        align-items: end;
        z-index: 102;
        background-color: rgba($color-black, 0.5);
        transition: 0.2s ease-in;
        overflow: auto;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: 16px auto;
      grid-gap: 16px;
      padding: 8px 16px;
      background: $color-white;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.25s ease-in;
      user-select: none;

      &__icon {
        position: relative;
        top: 3px;
      }

      &:hover {
        background: $color-moon-raker;
      }

      &_mobile {
        padding: 12px 16px;
      }

        &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        color: $color-text;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }

  &__two-steps {
    min-width: 272px;
    max-width: 272px;
  }
}

</style>
