<template>
  <div
    class="lp-student-avatar"
    :class="{'lp-student-avatar_mobile': isMobile}"
  >
    <transition-group
      name="fade"
      appear
      mode="out-in"
    >
      <img
        class="lp-student-avatar__image"
        v-if="temporaryBlob || originalAvatar"
        :key="'avatar'"
        :src="temporaryBlob || originalAvatar"
        alt="avatar"
      >
      <userIcon
        v-else
        :key="'placeholder'"
      />
    </transition-group>
    <UploadInput
      class="lp-student-avatar__editable"
      accept="image"
      :maxSizeMB="10"
      v-model:file="file"
      v-model:limit="fileErrors.limitExceeded"
      v-model:format="fileErrors.notImage"
    >
      <pencilIcon />
    </UploadInput>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition name="fade">
        <CropperImage
          v-if="openCropper"
          :image="fileUrl"
          :file="file"
          :temporaryBlob="temporaryBlob"
          v-model:file="file"
          v-model:open="openCropper"
          v-model:temporaryBlob="temporaryBlob"
        />
      </transition>
    </teleport>
    <transition name="bounce">
      <div
        class="lp-student-avatar-error lp-error"
        v-if="fileErrors.limitExceeded || fileErrors.notImage"
      >
        <dangerIcon />
        <span class="lp-student-avatar-error__text">
          {{ $t(fileErrors.limitExceeded ? 'errors.limitImage' : 'errors.notImage') }}
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import { userIcon } from '@/constants/icons';
import { pencilIcon, dangerIcon } from '@/constants/icons/crm';
import { computed, reactive, ref, watch } from 'vue';
import UploadInput from '@/components/Main/Inputs/UploadInput';
import CropperImage from '@/components/Main/CropperImage/CropperImage';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentAvatar',
  components: {
    CropperImage,
    UploadInput,
    userIcon,
    pencilIcon,
    dangerIcon
  },
  props: {
    student: Object
  },
  emits: ['update:file'],
  setup (props, { emit }) {
    const fileErrors = reactive({
      limitExceeded: false,
      notImage: false
    });

    const originalAvatar = computed(() => get(props.student, 'uploadPhoto.url', null));

    const openCropper = ref(false);

    const temporaryBlob = ref(null);
    const file = ref(null);
    const fileUrl = computed(() => (file.value ? URL.createObjectURL(file.value) : ''));

    watch(file, (newFile) => {
      openCropper.value = openCropper.value ? false : !!newFile;
      emit('update:file', newFile);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      originalAvatar,
      file,
      fileErrors,
      openCropper,
      fileUrl,
      temporaryBlob
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-student-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
  box-shadow: 0 0 20px rgba($color-black, 0.05);

  &_mobile {
    width: 100px;
    height: 100px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__editable {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba($color-sine-shaft, 0.5);
    cursor: pointer;
    transition: 0.3s ease-out;

    svg {
      transition: 0.2s ease-in;
    }

    &:hover {
      background: rgba($color-sine-shaft, 0.8) ;

      svg {
        transform: scale(1.2);
      }
    }
  }

  &-error {
    position: absolute;
    top: 156px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    color: $color-red;
    white-space: nowrap;
  }
}

</style>
