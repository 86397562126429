<template>
  <div
    class="systemcrm"
    id="systemcrm"
  >
    <div class="systemcrm__container">
      <div class="systemcrm__content">
        <div class="systemcrm__title">
          <span> {{ $t('landing.systemcrm.name.first') }} </span> <br />
          {{ $t('landing.systemcrm.name.second') }}
        </div>
        <div
          class="systemcrm-item"
          v-for="item of systemcrm"
          :key="item.id"
        >
          <div class="systemcrm-item__icon">
            <img
              :src="require(`@/assets/images/landing/check.svg`)"
              alt="user"
            />
          </div>
          <div class="systemcrm-item__text">
            {{ $t(item.text) }}
          </div>
        </div>
        <button
          class="systemcrm-btn lp-button"
          @click.stop="onLogin"
        >
          {{ $t('landing.welcomeBtn') }}
        </button>
      </div>
      <div class="systemcrm__image">
        <img
          :src="require(`@/assets/images/landing/systemcrm/${locale}.jpg`)"
          alt="systemcrm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import systemcrm from '@/constants/landing/systemcrm';

export default {
  name: 'SystemCRM',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const router = useRouter();
    const { locale } = useI18n({ useScope: 'global' });
    const onLogin = () => {
      router.push({
        name: 'Sign-up'
      });
    };
    watch(locale, () => {
      localStorage.setItem('lang', locale.value);
    });

    return { locale, systemcrm, onLogin };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.systemcrm {
  width: 100%;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: calc(100% - 10px);
    max-width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $color-white;
    padding: 50px;
    box-shadow: 0 0 28px rgba($color-black, 0.04);
    border-radius: 8px;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 30px;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    text-align: start;
    margin-bottom: 8px;

    span {
      color: $color-purple;
      line-height: 100%;
      text-justify: center;
    }
  }

  &__image {
    display: flex;
    width: 100%;
    max-width: 800px;

    img {
      border-radius: 12px;
    }
  }
}

.systemcrm-item {
    margin-top: 27px;
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      align-items: center;
      padding: 0 15px 0 0;
    }

    &__text {
      width: 500px;
      font-family: $global-font-manrope;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
    }
  }

.systemcrm-btn {
  margin-top: 42px;
  height: 56px;
  padding: 0 30px;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.04em;
}
</style>
