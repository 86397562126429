<template>
  <div class="lp-file-context-menu__wrapper">
    <teleport
      to="#app"
      v-if="mobile"
    >
      <transition name="fade">
        <div
          class="lp-file-context-menu__background"
          @click="closeMenu"
        />
      </transition>
    </teleport>
    <teleport to="#app">
      <div
        class="lp-file-context-menu"
        :class="{
          'lp-file-context-menu_fixed': position,
          'lp-file-context-menu_mobile': mobile
        }"
        :style="checkedPosition"
        v-click-outside="mobile ? () => {} : closeMenu"
      >
        <div
          class="lp-file-context-menu-item"
          :class="{
            'lp-file-context-menu-item_input': item.upload,
            'lp-file-context-menu-item_input_mobile': item.upload && isMobile,
            'lp-file-context-menu-item_mobile': isMobile
          }"
          @click.stop="clickByItem(item)"
          v-for="item of menuItems"
          :key="item.title"
        >
          <template v-if="item.upload">
            <UploadInput
              class="lp-file-context-menu-item lp-file-context-menu-item_upload"
              :maxSizeMB="1000"
              :multiple="true"
              :accept="validTypes"
              v-model:file="files"
            >
              <div class="lp-file-context-menu-item__icon">
                <component :is="item.icon" />
              </div>
              <div class="lp-file-context-menu-item__title">
                {{ $t(item.title) }}
              </div>
            </UploadInput>
          </template>
          <template v-else>
            <div class="lp-file-context-menu-item__icon">
              <component :is="item.icon" />
            </div>
            <div class="lp-file-context-menu-item__title">
              {{ $t(item.title) }}
            </div>
          </template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import {
  deleteIcon,
  downloadIcon,
  addIcon,
  renameIcon,
  uploadContextIcon
} from '@/constants/icons/files';
import { computed, ref, watch } from 'vue';
import { validTypes } from '@/constants/fileTypes';
import UploadInput from '@/components/Main/Inputs/UploadInput';
import { saveAs } from 'file-saver';
import MobileDetect from 'mobile-detect';

export default {
  name: 'FileContextMenu',
  components: { UploadInput },
  props: {
    mobile: Boolean,
    options: {
      type: Array,
      default: () => [
        'rename',
        'new',
        'upload',
        'download',
        'delete'
      ]
    },
    position: Object,
    item: Object
  },
  emits: ['update:status', 'rename', 'new', 'upload', 'download', 'delete'],
  setup (props, { emit }) {
    const fullList = [
      {
        id: 'rename',
        title: 'myFiles.rename',
        icon: renameIcon
      },
      {
        id: 'new',
        title: 'myFiles.addFolder',
        icon: addIcon
      },
      {
        upload: true,
        id: 'upload',
        title: 'myFiles.uploadFiles',
        icon: uploadContextIcon
      },
      {
        id: 'download',
        title: 'myFiles.downloadFile',
        icon: downloadIcon,
        link: true
      },
      {
        id: 'delete',
        title: 'myFiles.delete',
        icon: deleteIcon
      }
    ];
    const menuItems = computed(() => fullList.filter((item) => props.options.includes(item.id)));

    const checkedPosition = computed(() => {
      if (!props.position || props.mobile) return { top: 'auto' };
      const offset = 300;
      const { left, top } = props.position;
      const { innerWidth, innerHeight } = window;
      const style = {
        top: `${top}px`,
        left: `${left}px`
      };
      if (innerWidth < (left + offset)) {
        style.left = `${left - offset}px`;
      }
      if (innerHeight < (top + offset)) {
        style.top = `${top - offset}px`;
      }
      return style;
    });

    const closeMenu = () => {
      emit('update:status', false);
    };

    const downloadFile = async () => {
      const { url, mimetype, originalName } = props.item;
      if (!url || !mimetype) return;
      const hasProtocol = (/^https:\/\//g).test(url);
      const fullUrl = hasProtocol ? url : `https://${url}`;
      const blob = await fetch(fullUrl).then(r => r.blob());
      saveAs(blob, originalName);
    };

    const clickByItem = (item) => {
      if (item.link) {
        downloadFile();
        closeMenu();
      } else {
        emit(item.id);
      }
    };

    const files = ref(null);
    watch(files, () => {
      closeMenu();
      emit('update:files', files.value);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      validTypes,
      menuItems,
      closeMenu,
      checkedPosition,
      clickByItem,
      files
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-context-menu {
  @include global-font;
  position: absolute;
  padding: 8px;
  display: grid;
  background: $color-white;
  border: 1px solid $color-wild-sand;
  box-sizing: border-box;
  box-shadow: 0 0 15px rgba($color-black, 0.05);
  border-radius: 6px;
  grid-gap: 8px;
  z-index: 10;

  &_fixed {
    position: fixed;
  }

  &_mobile {
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    border-radius: 0;
    z-index: 102;
    transition: 0.1s;
  }

  &__wrapper {
    position: absolute;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color-black, 0.5);
  }

  &-item {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    grid-gap: 12px;
    padding: 8px 16px;
    white-space: nowrap;
    background: transparent;
    border-radius: 3px;
    transition: 0.3s;
    cursor: pointer;
    user-select: none;

    &_mobile {
      padding: 12px 16px;
    }

    &_input {
      &_mobile {
        grid-template-columns: 1fr;
      }
    }

    &_upload {
      margin: -8px -16px;
    }

    &:hover {
      background-color: $color-moon-raker;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
    }
  }
}

</style>
