import * as types from '@/constants/mutationTypes';

const state = {
  rtcConnect: {}, /** instance of RTCMultiConnection */
  participants: 0, /** counter connected users */
  singingStudent: false, /** the teacher sings (false), the student sings (true) */
  rtcSettings: {}, /** setting for init RTCMultiConnection */
  closedRoom: false, /** the room was closed by the teacher */
  showDelaySetting: false, /** audio delay calibration display */
  liteVersion: true, /** basic (true) or professional (false) version of the call */
  limitRoom: '', /** limit by teacher/student */
  checkedLimit: false, /** true if the limit check was successful */
  sharingFileList: [],
  sharingFile: null,
  mobileParticipant: false
};
const getters = {
  rtcConnect: (state) => state.rtcConnect,
  participants: (state) => state.participants,
  singingStudent: (state) => state.singingStudent,
  rtcSettings: (state) => state.rtcSettings,
  closedRoom: (state) => state.closedRoom,
  showDelaySetting: (state) => state.showDelaySetting,
  liteVersion: (state) => state.liteVersion,
  limitRoom: (state) => state.limitRoom,
  checkedLimit: (state) => state.checkedLimit,
  sharingFileList: (state) => state.sharingFileList,
  sharingFile: (state) => state.sharingFile,
  mobileParticipant: (state) => state.mobileParticipant
};
const actions = {
  setRtcConnect ({ commit }, data) {
    commit(types.SET_RTC_CONNECT, { data });
  },
  setParticipants ({ commit }, data) {
    commit(types.SET_PARTICIPANTS, { data });
  },
  setSingingStudent ({ commit }, data) {
    commit(types.SET_SINGING_STUDENT, { data });
  },
  setRtcSettings ({ commit }, data) {
    commit(types.SET_RTC_SETTINGS, { data });
  },
  setClosedRoom ({ commit }, data) {
    commit(types.SET_CLOSED_ROOM, { data });
  },
  setShowDelaySetting ({ commit }, data) {
    commit(types.SET_SHOW_DELAY_SETTING, { data });
  },
  setLiteVersion ({ commit }, data) {
    commit(types.SET_LITE_VERSION, { data });
  },
  setLimitRoom ({ commit }, data) {
    commit(types.SET_LIMIT_ROOM, { data });
  },
  setCheckedLimit ({ commit }, data) {
    commit(types.SET_CHECKED_LIMIT, { data });
  },
  setSharingFileList ({ commit }, data) {
    commit(types.SET_SHARING_FILE_LIST, { data });
  },
  setSharingFile ({ commit }, data) {
    commit(types.SET_SHARING_FILE, { data });
  },
  setScrollSharingFile ({ commit }, data) {
    commit(types.SET_SCROLL_SHARING_FILE, { data });
  },
  setMobileParticipant ({ commit }, data) {
    commit(types.SET_MOBILE_PARTICIPANT, { data });
  }
};
const mutations = {
  [types.SET_RTC_CONNECT] (state, { data }) {
    state.rtcConnect = data;
  },
  [types.SET_PARTICIPANTS] (state, { data }) {
    state.participants = data;
  },
  [types.SET_SINGING_STUDENT] (state, { data }) {
    state.singingStudent = data;
  },
  [types.SET_RTC_SETTINGS] (state, { data }) {
    state.rtcSettings = data;
  },
  [types.SET_CLOSED_ROOM] (state, { data }) {
    state.closedRoom = data;
  },
  [types.SET_SHOW_DELAY_SETTING] (state, { data }) {
    state.showDelaySetting = data;
  },
  [types.SET_LITE_VERSION] (state, { data }) {
    state.liteVersion = data;
  },
  [types.SET_LIMIT_ROOM] (state, { data }) {
    state.limitRoom = data;
  },
  [types.SET_CHECKED_LIMIT] (state, { data }) {
    state.checkedLimit = data;
  },
  [types.SET_SHARING_FILE_LIST] (state, { data }) {
    state.sharingFileList = data;
  },
  [types.SET_SHARING_FILE] (state, { data }) {
    state.sharingFile = data;
  },
  [types.SET_SCROLL_SHARING_FILE] (state, { data }) {
    state.sharingFile = { ...state.sharingFile, scroll: data };
  },
  [types.SET_MOBILE_PARTICIPANT] (state, { data }) {
    state.mobileParticipant = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
