<template>
  <div
    class="lp-crm-settings"
    :class="{
      'lp-crm-settings_editable': editProfile,
      'lp-crm-settings_mobile': isMobile
    }"
  >
    <div
      class="lp-crm-settings-header"
      :class="{'lp-crm-settings-header_mobile': isMobile}"
    >
      <h1 class="lp-page-title">
        <AppTabs
          :tabs="tabs"
          :activeTab="checkedSettings"
          :isMobile="isMobile"
          @checked="checkedTab"
        />
      </h1>
    </div>

    <div
      v-if="!isMobile"
      class="lp-crm-settings-tabs"
    >
      <AppTabs
        :tabs="tabs"
        :activeTab="checkedSettings"
        :isMobile="isMobile"
        @checked="checkedTab"
      />
    </div>

    <component
      :is="getCurrentTab"
    />
  </div>
</template>

<script>
import { computed, ref, provide, watch } from 'vue';
import MobileDetect from 'mobile-detect';
import ProfileSettings from '@/components/CRM/Settings/ProfileSettings/ProfileSettings';
import NotificationsSettings from '@/components/CRM/Settings/NotificationsSettings/NotificationsSettings';
import AppTabs from '@/components/CRM/Settings/ui/AppTabs';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Settings',
  components: { ProfileSettings, NotificationsSettings, AppTabs },
  setup () {
    const editProfile = ref(false);
    const showModal = ref(false);
    const checkedSettings = ref('profile');
    const languageOfInterface = ref(localStorage.getItem('lang'));
    const { t } = useI18n();
    const tabs = ref([
      {
        id: '1',
        title: t('crm.settings.profile'),
        value: 'profile'
      },
      {
        id: '2',
        title: t('crm.settings.notifications'),
        value: 'notifications'
      }
    ]);

    const changeLanguageCurrent = (value) => languageOfInterface.value = value;
    provide('changeLanguageCurrent', changeLanguageCurrent);

    watch(languageOfInterface, (newValue) => {
      const newTabs = [
        {
          id: '1',
          title: newValue === 'ru' ? 'Профиль' : 'Profile',
          value: 'profile'
        },
        {
          id: '2',
          title: newValue === 'ru' ? 'Уведомления' : 'Notifications',
          value: 'notifications'
        }
      ];
      tabs.value = [...newTabs];
    });

    const getCurrentTab = computed(() => {
      switch (checkedSettings.value) {
      case 'notifications': return 'NotificationsSettings';
      default: return 'ProfileSettings';
      }
    });

    const checkedTab = (value) => checkedSettings.value = value;

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      showModal,
      editProfile,
      getCurrentTab,
      tabs,
      checkedSettings,
      checkedTab,
      languageOfInterface
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-settings {
  margin: -35px -24px -24px;
  padding: 35px 0;

  &_mobile {
    margin: 0;
    height: 100%;
    background-color: $color-white;
  }

  &_editable {
    align-items: flex-start;
  }

  &-header {
    display: none;

    &_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;
      background-color: $color-white;
      padding: 16px 56px;
      display: block;
      z-index: 2;
    }
  }

  &-tabs {
    padding: 0 35px;
  }
}

</style>
