<template>
  <div class="lp-new-file">
    <Modal
      title="myFiles.titleNewFolder"
      customClass="lp-new-file__modal"
      v-model:open="openModal"
    >
      <template #default>
        <div
          class="lp-new-file-body"
          :class="{'lp-new-file-body_mobile': isMobile}"
        >
          <CustomInput
            @focus="input.error = false"
            @submit="createFolder"
            v-bind="input"
            v-model="input.modelValue"
          />
        </div>
      </template>
      <template #footer>
        <div
          class="lp-new-file-footer"
          :class="{'lp-new-file-footer_mobile': isMobile}"
        >
          <button
            class="lp-button lp-button_thin"
            v-if="!isMobile"
            @click="openModal = false"
          >
            {{ $t('buttons.cancel') }}
          </button>
          <button
            class="lp-button"
            @click="createFolder"
          >
            {{ $t('buttons.create') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, reactive, ref, watch } from 'vue';
import CustomInput from '@/components/Main/Inputs/CustomInput';
import { get, last, trim } from 'lodash';
import FolderApi from '@/api/CRM/folders';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import MobileDetect from 'mobile-detect';

export default {
  name: 'NewFileForm',
  components: {
    CustomInput,
    Modal
  },
  props: {
    path: {
      type: Array,
      default: () => ([])
    },
    open: Boolean
  },
  emits: ['update:open'],
  setup (props, { emit }) {
    const store = useStore();
    const user = computed(() => store.getters.user);

    const creatingFolder = ref(false);
    const setLoaderRun = (data) => {
      creatingFolder.value = data;
      store.dispatch('setLoaderRun', data);
    };

    const openModal = ref(false);
    watch(() => props.open, () => {
      openModal.value = props.open;
    });
    watch(openModal, () => {
      if (!openModal.value) input.modelValue = '';
      emit('update:open', openModal.value);
    });

    const input = reactive({
      type: 'text',
      modelValue: '',
      modelName: 'name',
      placeholder: 'myFiles.inputPlaceholder',
      emptyError: 'errors.emptyNameFolder',
      label: '',
      autofocus: true,
      error: false
    });

    const folderList = computed(() => store.getters.folderList);
    const setFolderList = (data) => store.dispatch('setFolderList', data);
    const rootFolder = computed(() => store.getters.rootFolder);

    const createFolder = async () => {
      if (!input.modelValue) {
        input.error = input.emptyError;
        return;
      }
      if (creatingFolder.value) return;
      const folder = {
        name: trim(input.modelValue),
        parentFolder: get(last(props.path), '_id', rootFolder.value),
        userCreatedFolder: user.value._id
      };
      try {
        await setLoaderRun(true);
        const { data } = await FolderApi.createFolder(folder);
        yandexTracker.addFolder();
        const newFolderList = folderList.value.concat(data);
        await setFolderList(newFolderList);
        openModal.value = false;
        await setLoaderRun(false);
      } catch (e) {
        console.error(e);
        await setLoaderRun(false);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      input,
      openModal,
      isMobile,
      createFolder
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-new-file {

  &-body {
    padding: 9px 20px 0;

    &_mobile {
      width: 100%;
    }
  }

  &-footer {
    display: grid;
    grid-template-columns: auto auto;
    align-items: end;
    grid-gap: 20px;

    &_mobile {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }
}

</style>

<style lang="scss">

.lp-modal.lp-new-file__modal {
  padding-bottom: calc(var(--vh, 1vh) * 10);
}
</style>
