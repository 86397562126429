import teacher1Img from '@/assets/images/pianists/pianist2.jpg';
import achievement1 from '@/assets/images/pianists/achievements/achievement1.jpg';
import achievement2 from '@/assets/images/pianists/achievements/achievement2.jpg';
import { uniqueId } from 'lodash';

export default {
  id: uniqueId,
  image: teacher1Img,
  name: 'crm.teachers.pianist2.name',
  about: 'crm.teachers.pianist2.about',
  works: [
    {
      date: '2012 - по настоящее время',
      position: 'концертмейстер и педагог',
      id: uniqueId()
    }
  ],
  achievements: [
    { item: achievement1, id: uniqueId() },
    { item: achievement2, id: uniqueId() }
  ],
  workText: 'crm.teachers.pianist2.experience',
  hideNext: true
};
