<template>
  <div class="menu">
    <div class="menu__wrapper">
      <div class="menu-auth">
        <button
          @click="goToPage('Sign-up')"
          class="lp-button menu__button"
        >
          {{ $t('signUp.button') }}
        </button>
        <button
          @click="goToPage('Login')"
          class="lp-button menu__button menu__button_sign-up"
        >
          {{ $t('login.button') }}
        </button>
      </div>
      <SelectLang
        v-model:currency="locale"
        :defaultValue="locale"
        :selectNoLabel="selectNoLabel"
        class="menu__lang"
      />
      <ul class="menu__list">
        <template v-for="item in landingMenu">
          <li
            v-if="!item.showLang || item.showLang === locale"
            :key="item.id"
            class="menu__list-item"
            @click="handleClickMenu(item.transition)"
          >
            {{ $t(item.text) }}
          </li>
        </template>
        <li class="menu__list-icons">
          <a
            href="https://www.youtube.com/channel/UC1X3lRIe5H2N3HU6EIFoZRw"
            class="menu__list-icons__icon"
            target="_blank"
          >
            <youtubeIcon />
          </a>
          <a
            href="https://instagram.com/musio.io?utm_medium=copy_link"
            class="menu__list-icons__icon"
            target="_blank"
          >
            <instaIcon />
          </a>
          <a
            href="https://t.me/joinchat/yV7IcfisXiEwMjMy"
            class="menu__list-icons__icon"
            target="_blank"
          >
            <telegramIcon />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import landingMenu from '@/constants/landing/landingMenu';
import scrollTo from '@/constants/utils/scrollTo';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import SelectLang from '@/components/Landing/LandingMobile/SelectLang';
import {
  youtubeIcon,
  instaIcon,
  telegramIcon
} from '@/constants/icons/landing';

export default {
  name: 'Menu',
  components: {
    SelectLang,
    youtubeIcon,
    instaIcon,
    telegramIcon
  },
  emits: ['close-menu'],
  setup (props, { emit }) {
    const router = useRouter();

    const { locale } = useI18n({ useScope: 'global' });

    const isOpenMenu = ref(false);
    const selectNoLabel = ref(true);

    const toggleMenu = (val) => {
      emit('close-menu', val);
      if (!val) {
        isOpenMenu.value = false;
      }
    };

    const goToPage = (name) => {
      router.push({
        name
      });
      if (name === 'Sign-up') return;
      yandexTracker.goToSignUp();
    };

    const handleClickMenu = (id) => {
      const el = document.getElementById(id);
      const { top } = el.getBoundingClientRect();
      const scaleEl = document.getElementsByClassName('app-zoom');
      const scale = scaleEl.length ? 0.8 : 1;
      document.body.classList.remove('menu-open');
      emit('close-menu', false);
      scrollTo(window, top * scale + window.scrollY - 80, 500, 'clear');
    };

    return {
      selectNoLabel,
      landingMenu,
      scrollTo,
      goToPage,
      isOpenMenu,
      toggleMenu,
      locale,
      handleClickMenu
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.menu {
  min-height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  overflow: auto;

  &__wrapper {
    position: relative;
    display: flex;
    min-height: 100%;
    margin: 0 36px;
    background: $color-white;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    height: 56px;
    width: 100%;
    padding: 14px 0;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 125%;

    &_sign-up {
      margin-top: 12px;
      background-color: transparent;
      box-shadow: none;
      color: $color-accent;
    }
  }

  &__lang {
    margin-top: 20px;

    &:hover {
      color: $color-purple-dark;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
  }
}

.menu-auth {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu__list-item {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
}

.menu__list-icons {
  margin-top: 30px;
  width: 132px;
  display: flex;
  justify-content: space-between;

  &__icon {
    color: $color-purple;
    width: 32px;
    height: 32px;

    &:hover {
      color: $color-purple-dark;
    }
  }
}
</style>
