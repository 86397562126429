<template>
  <div
    class="section strengths"
    id="strengths"
  >
    <div class="strengths__container">
      <div class="strengths__title">
        {{ $t('landing.strengths.name.first') }}
        <span> {{ $t('landing.strengths.name.second') }} </span>
      </div>
      <div class="strengths__content">
        <div class="strengths__image">
          <img
            :src="require(`@/assets/images/landing/strengths/strengths.jpg`)"
            alt="user"
          />
        </div>
        <div>
          <div class="strengths__list">
            <div
              class="strengths-item"
              v-for="strength of strengths"
              :key="strength.id"
            >
              <div class="strengths-item__icon">
                <img
                  :src="
                    require(`@/assets/images/landing/strengths/${strength.image}.svg`)
                  "
                  alt="user"
                />
              </div>
              <div class="strengths-item__text">
                {{ $t(strength.name) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strengths from '@/constants/landing/strengths';

export default {
  name: 'Strengths',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    return {
      strengths
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.strengths {
  width: 100%;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: calc(100% - 10px);
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-white;
    padding: 80px 50px;
    box-shadow: 0 0 28px rgba($color-black, 0.04);
    border-radius: 8px;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    line-height: 130%;
    text-align: center;

    span {
      color: $color-purple;
      line-height: 100%;
      text-justify: center;
    }
  }

  &__content {
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__list {
    position: relative;
    margin-left: 66px;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 70px;
  }
}

.strengths-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__icon {
    padding: 0 5px;
  }

  &__text {
    margin-top: 16px;
    width: 295px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  }
}
</style>
