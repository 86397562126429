<template>
  <teleport to="body">
    <transition name="fade">
      <div
        class="lp-loader"
        :class="{
          'lp-loader_block': block,
          'lp-loader_block_mobile': block && isMobile
        }"
        @click.stop.prevent
        v-if="(block && !loaderRun) || (!block && loaderRun)"
      >
        <div class="lp-loader-spinner">
          <div
            class="lp-loader-spinner-blade"
            v-for="(el, i) of blades"
            :key="i"
          />
        </div>
      </div>
    </transition>
    <span class="lp-loader__reactive">{{ loaderRun }}</span>
  </teleport>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { fill } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Loader',
  props: {
    block: Boolean
  },
  setup () {
    const store = useStore();
    const loaderRun = computed(() => store.getters.loaderRun);

    const countBlades = 12;
    const blades = fill(new Array(countBlades), 0);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return { loaderRun, blades, isMobile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

$spinner-size: 100px;
$spinner-color: $color-accent;

.lp-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color-white, 0.8);
  z-index: 999;
  user-select: none;

  &_block {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    background-color: transparent;

    &_mobile {
      position: fixed;
    }
  }

  &-spinner {
    width: 88px;
    height: 72px;
    font-size: $spinner-size;
    position: relative;

    &-blade {
      position: absolute;
      left: .4629em;
      bottom: 0;
      width: 4px;
      height: 14px;
      border-radius: 2px;
      background-color: transparent;
      transform-origin: center -.2222em;
      animation: spinner-fade 1s infinite linear;

      $animation-delay: 0s;
      $blade-rotation: 0deg;

      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: $animation-delay;
          transform: rotate($blade-rotation);
          $blade-rotation: $blade-rotation + 30;
          $animation-delay: $animation-delay + .083;
        }
      }
    }
  }

  &__reactive {
    position: fixed;
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes spinner-fade {
  0% {
    background-color: $spinner-color;
  }

  100% {
    background-color: transparent;
  }
}

</style>
