import MobileDetect from 'mobile-detect';

export default () => {
  const md = new MobileDetect(window.navigator.userAgent);
  const mobile = md.mobile();
  const tablet = md.tablet();
  const { innerWidth } = window;
  if (mobile !== null && tablet !== null) return;
  return innerWidth <= 1460 && innerWidth > 768;
};
