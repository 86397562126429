<template>
  <div
    class="lp-calendar-popup"
    ref="popup"
    :style="getStylePopup()"
    v-click-outside="closePopup"
  >
    <div class="lp-calendar-popup-header">
      <div class="lp-calendar-popup-header__title">
        {{ day }}
      </div>
      <div
        class="lp-calendar-popup-header__cross lp-cross-button"
        @click="closePopup"
      >
        <crossIcon />
      </div>
    </div>
    <div
      class="lp-calendar-popup-body"
      :options="{wheelPropagation: false}"
    >
      <CalendarLesson
        v-for="lesson of lessons"
        :mode="'week'"
        :key="lesson._id"
        :lesson="lesson"
        @get-lessons="$emit('get-lessons')"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import moment from 'moment';
import { crossIcon } from '@/constants/icons';
import CalendarLesson from '@/components/CRM/Lessons/Calendar/CalendarLesson/CalendarLesson';
import { get } from 'lodash';

export default {
  name: 'CalendarLessonsPopup',
  components: {
    CalendarLesson,
    crossIcon
  },
  props: {
    date: String,
    lessons: {
      type: Array,
      required: false
    }
  },
  emits: ['get-lessons'],
  setup (props, { emit }) {
    const day = computed(() => moment(props.date).format('DD ddd'));
    const closePopup = (e) => {
      const isTargetCross = e.target.closest('.lp-calendar-popup-header__cross');
      if (isTargetCross) emit('update:open', '');
      else {
        const path = get(e, 'path', []);
        const pathLength = get(path, 'length', 0);
        if (!pathLength) return;
        const classes = path.map(el => el ? el.className : '');
        const isLpModal = classes.find((item) => item && typeof item === 'string' &&
            item.includes('lp-modal'));
        const isLpModalQuestion = classes.find((item) => item && typeof item === 'string' &&
            item.includes('lp-modal-question'));
        const isModal = isLpModal || isLpModalQuestion;
        if (!isModal) emit('update:open', '');
      }
    };

    watch(() => props.lessons, (val) => {
      if (!val) emit('update:open', '');
    });

    const popup = ref(null);

    const getStylePopup = () => {
      const parent = document.getElementById(`date${props.date}`);
      if (!parent || !popup.value) return {};
      const { offsetHeight, offsetWidth } = popup.value;
      const { innerWidth, innerHeight } = window;
      const position = parent.getBoundingClientRect();
      const { left, top } = position;
      const style = {
        position: 'fixed',
        top: `${top - 22}px`,
        left: `${left - 66.5}px`
      };
      const comesLeft = innerWidth < (left + offsetWidth);
      const comesTop = innerHeight < (top + offsetHeight);
      if (comesLeft) style.left = `${position.left - 17 - offsetWidth}px`;
      if (comesTop) {
        style.bottom = '30px';
        style.top = 'auto';
      }
      return style;
    };

    return {
      day,
      popup,
      closePopup,
      getStylePopup
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-popup {
  @include global-font;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  width: 266px;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    padding: 10px;

    &__title {
      color: $color-accent;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
    }

    &__cross {
      position: absolute;
      width: 42px;
      height: 42px;
      top: auto;
      right: 0;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    padding: 21px 18px;
    overflow: auto;
    max-height: 50vh;
  }
}

</style>
