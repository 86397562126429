<template>
  <div
    class="section reviews"
    id="reviews"
  >
    <div class="reviews__wrapper">
      <div class="reviews__title">
        {{ $t('landing.reviewsTitle.firstItem') }}
        {{ $t('landing.reviewsTitle.secondItem') }}
      </div>
      <div class="reviews-slider">
        <Carousel :settings="settingsSlider">
          <Slide
            v-for="(review, index) of reviewsList"
            :key="review.id"
          >
            <div
              class="reviews-card"
              :class="{
                'reviews-first-card': index === 0,
                'reviews-last-card': index === reviewsList.length - 1
              }"
            >
              <div class="reviews-card__image">
                <img
                  :src="
                    require(`@/assets/images/landing/reviews-mobile/${review.image}.jpg`)
                  "
                  alt="user"
                />
              </div>
              <div class="reviews-card__content">
                <div class="reviews-card__info">
                  <div class="reviews-card__title">
                    {{ $t(review.name) }}
                  </div>
                  <div class="reviews-card__occupation">
                    {{ $t(review.occupation) }}
                  </div>
                </div>
                <div class="reviews-card__review">
                  <ReviewsReadMore :review="review" />
                </div>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import reviewsList from '@/constants/landing/reviewsList';
import ReviewsReadMore from '@/components/Landing/LandingMobile/ReviewsReadMore';

export default {
  name: 'Reviews',
  components: {
    Carousel,
    Slide,
    ReviewsReadMore
  },
  setup () {
    const openText = ref(false);
    const settingsSlider = reactive({
      itemsToShow: 1.1,
      snapAlign: 'center',
      wrapAround: false
    });

    return { openText, reviewsList, settingsSlider };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.reviews {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    max-width: 1656px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    overflow: hidden;
  }

  &__title {
    padding: 0 5px;
    line-height: 57.6px;
    width: 100%;
    max-width: 800px;
    padding: 0 10px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
  }
}

.reviews-slider {
  margin-top: 24px;
  width: 100%;
  margin-bottom: 8px;
}

.reviews-card {
  width: calc(100% - 12px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: $color-white;

  &__image {
    display: flex;
    width: 100%;
    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  &__content {
    margin-top: 18px;
    height: 100%;
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__review {
    margin-top: 4px;
  }

  &__info {
    text-align: left;
    font-size: 24px;
    line-height: 180%;
    color: $color-mine-shaft;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
  }

  &__occupation {
    color: $color-gray;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
  }
}

.reviews-first-card {
  margin-left: 12px;
  margin-right: 6px;
}

.reviews-last-card {
  margin-right: 12px;
  margin-left: 6px;
}
</style>

<style lang="scss">
@import '~@/sass/variables';
.reviews {
  .carousel {
    width: 100%;

    &__slide {
      display: flex;
      align-items: flex-start;
    }

    &__prev,
    &__next {
      position: absolute;
      width: 70px;
      height: 70px;
      background: $color-white;
      box-shadow: 0 0 20px rgba($color-black, 0.1);
      border-radius: 14px;
      top: -60px;

      &:hover {
        svg {
          fill: $color-purple-dark;
        }
      }

      svg {
        fill: $color-purple;
        font-size: 32px;
      }
    }

    &__prev {
      right: 85px !important;
      left: auto !important;
    }

    &__next {
      right: 65px !important;
      left: auto !important;
    }
  }
}
</style>
