<template>
  <div>
    <Modal
      v-model:open="openModal"
      :saveBtnText="'buttons.update'"
      :removeBtnText="'buttons.cancel'"
      :hideButtons="['remove', 'save']"
      :mobileVersion="isMobile"
      @remove="closeForm"
    >
      <div
        class="lp-support-browser"
        :class="{'lp-support-browser_mobile': isMobile}"
      >
        <img
          class="lp-support-browser__img"
          :src="placeholder"
          alt="close lesson"
        />
        <div class="lp-support-browser__text">
          {{ $t(text) }}
        </div>
        <div
          class="lp-support-browser__button lp-button"
          @click="closeForm"
        >
          {{ $t('buttons.ok') }}
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, onBeforeMount, ref } from 'vue';
import placeholder from '@/assets/images/supportBrowser.png';
import { useRouter } from 'vue-router';
import { unsupportedIOS, unsupportedMacOs } from '@/constants/_rtc/utils/isUnsupportedBrowser';
import { useStore } from 'vuex';

export default {
  name: 'SupportBrowser',
  components: {
    Modal
  },
  setup () {
    const router = useRouter();

    const openModal = ref(false);
    const closeForm = () => {
      openModal.value = false;
      try {
        window.close();
      } finally {
        router.go(-1);
      }
    };

    const text = ref('supportsBrowsers.chrome');

    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    onBeforeMount(() => {
      if (unsupportedMacOs) text.value = 'supportsBrowsers.chrome';
      if (unsupportedIOS) text.value = 'supportsBrowsers.safari';
      openModal.value = true;
    });

    return {
      placeholder,
      isMobile,
      closeForm,
      openModal,
      text
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-support-browser {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 120px 41px;

  &_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 80vh;
    padding: 0 40px 41px;
  }

  &__img {
    max-width: 279px;
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 40px 0 24px;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
    margin-bottom: 40px;
  }

  &__button {
    margin: 0 auto;
    max-width: max-content;
  }
}

</style>
