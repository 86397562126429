<template>
  <div
    class="lp-file-upload-item"
  >
    <div class="lp-file-upload-item__icon">
      <component
        :is="file.icon"
        v-if="file.icon"
      />
    </div>
    <div
      class="lp-file-upload-item__name"
      ref="nameEl"
    >
      <LPTooltip
        :text="file.name"
        :position="'left'"
      >
        <span>
          {{ file.name }}
        </span>
      </LPTooltip>
    </div>
    <div class="lp-file-upload-item__status">
      <FileUploadStatus
        v-bind="file"
        :modal="modal"
      />
    </div>
  </div>
</template>

<script>
import FileUploadStatus from '@/components/CRM/Files/FilesUploadList/FileUploadStatus';
import { ref } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'FileUploadItem',
  components: {
    LPTooltip,
    FileUploadStatus
  },
  props: {
    modal: Boolean,
    file: Object
  },
  setup () {
    const nameEl = ref(null);
    const getPosition = () => {
      if (!nameEl.value) return {};
      const position = nameEl.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${8 + position.top + position.height}px`,
        left: 'auto'
      };
    };

    return {
      nameEl,
      getPosition
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-upload-item {
  display: grid;
  grid-template-columns: 18px 1fr auto;
  align-items: center;
  grid-gap: 8px;
  padding: 8px 0;
  min-height: 40px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__name {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    color: $color-black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 0.3s;
  }
}
</style>
