<template>
  <div class="lp-login">
    <div class="lp-login__images">
      <img
        class="lp-login__image"
        :src="require(`@/assets/images/login-image.jpg`)"
        alt="login-image"
      />
      <router-link :to="{name: 'Landing'}">
        <loginLogoIcon />
      </router-link>
    </div>
    <div class="lp-login__container">
      <h2 class="lp-login__title">
        {{ $t('login.title') }}
      </h2>
      <div
        class="lp-login-form"
        @submit.prevent="$emit('submit')"
      >
        <CustomInput
          v-for="input of loginInputs"
          :key="input.label"
          v-bind="input"
          @submit="authUser()"
          @focus="input.error = false"
          v-model="input.model"
        >
          <template
            #header
            v-if="input.header"
          >
            <span
              class="lp-login-form__link"
              @click="goToRoute('ForgotPassword')"
            >
              {{ $t('forgotPassword.title') }}
            </span>
          </template>
        </CustomInput>
      </div>
      <button
        class="lp-button lp-login__enter"
        @click="authUser()"
      >
        {{ $t('login.button') }}
      </button>
      <div class="lp-login-footer">
        {{ $t('login.subtitle') }}
        <router-link
          class="lp-login-footer__link"
          :to="{name: 'Sign-up'}"
        >
          {{ $t('login.link') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import setErrorText from '@/constants/utils/setErrorText';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import loginInputs from '@/constants/inputs/loginInputs';
import CustomInput from '@/components/LoginPage/CustomInput';
import { loginLogoIcon } from '@/constants/icons/landing';
import AuthApi from '@/api/Auth/api';

export default {
  name: 'LoginPage',
  components: { CustomInput, loginLogoIcon },
  setup () {
    const store = useStore();
    const setActiveUser = (data) => store.dispatch('setUser', data);
    const setLoaderRun = (data) => {
      store.dispatch('setLoaderRun', data);
    };

    const validateLogin = (inputs) => {
      const body = {};
      let errors = 0;
      inputs.forEach((input) => {
        if (!input.model) {
          input.error = input.emptyError;
          errors += 1;
        } else {
          input.error = false;
          body[input.modelName] = input.model;
        }
      });
      return errors === 0 ? body : false;
    };

    const router = useRouter();

    const { locale } = useI18n({ useScope: 'global' });

    const authUser = async () => {
      const validateBody = validateLogin(loginInputs);
      if (!validateBody) return;
      try {
        await setLoaderRun(true);
        const minimal = await AuthApi.authUser(validateBody);
        VueCookieNext.setCookie('token', minimal.data.token.accessToken, {
          expire: '1m'
        });
        const { data } = await AuthApi.getUser();
        await setActiveUser(data);
        await setLoaderRun(false);
        await router.push({ name: 'Main' });
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        setErrorText({
          err,
          target: loginInputs,
          index: 1,
          all: true,
          locale: locale.value
        });
      }
    };

    const goToRoute = (name) => {
      router.push({ name });
    };

    return { authUser, loginInputs, goToRoute };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-login {
  @include global-font;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: $color-white;

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__container {
    display: flex;
    width: 100%;
    max-width: 528px;
    padding: 60px;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    background: $color-white;

    @media (max-width: 767px) {
      max-width: 400px;
      margin: 0;
      padding: 40px 24px 0 24px;
    }
  }

  &__images {
    display: flex;
    height: 100%;

    a {
      position: absolute;
      margin: 20px;
      color: $color-white;
    }

    @media (max-width: 767px) {
      height: auto;
      padding-top: 55px;

      a {
        position: relative;
        margin: 0px;

        color: $color-purple;
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 704px;
    height: inherit;
    border-radius: 8px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    color: $color-black;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  &__enter {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    min-width: 100%;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 125%;

    @media (max-width: 767px) {
      margin-top: 54px;
    }
  }
}

.lp-login-form {
  margin-top: 60px;
  display: grid;
  align-items: start;
  grid-template-rows: repeat(2, 80px);
  grid-gap: 16px;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 32px;
    grid-gap: 30px;
  }

  &__link {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: $color-accent;
    transition: color 0.2s ease-in;
    cursor: pointer;

    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 13px;
    }

    &:hover {
      color: $color-accent-hover;
    }
  }
}

.lp-login-footer {
  margin-top: 40px;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: $color-black;

  &__link {
    color: $color-azure-radiance;

    &:hover {
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>

<style lang="scss">
.app-zoom {
  .lp-login {
    height: 125vh;
  }
}
</style>

<style lang="scss">
.app-zoom {
  .lp-login {
    height: 125vh;
  }
}
</style>
