import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    autofocus: true,
    type: 'textarea',
    label: 'improveForm.wish',
    modelName: 'wish',
    modelValue: '',
    emptyError: 'errors.emptyWish',
    style: {
      minHeight: '312px'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('improve-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  item.required = true;
  return item;
}));
