<template>
  <div
    class="lp-crm-students-list"
    v-if="students.length || !uploadedData"
  >
    <StudentsListCard
      v-for="student of students"
      :key="student._id"
      :currency="settings ? settings.currentCurrency : ''"
      :student="student"
      @openCard="$emit('update:openCard', student)"
      @edit="$emit('edit', $event)"
      @delete="deleteStudent"
    />
    <div ref="intersectionTrigger" />
  </div>
  <div
    class="lp-crm-students-list-empty"
    v-else
  >
    <img
      class="lp-crm-students-list-empty__image"
      :src="imgPlaceholder"
      alt="students"
    >
    <p class="lp-crm-students-list-empty__title">
      {{ $t('crm.students.table.emptyList') }}
    </p>
    <p class="lp-crm-students-list-empty__description">
      {{ $t('crm.students.table.emptyListInfo') }}
    </p>
    <button
      class="lp-button lp-crm-students-list-empty__button"
      @click.stop="openFormStudent"
    >
      {{ $t('crm.students.table.addStudent') }}
    </button>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref, watch } from 'vue';
import studentsPlaceholder from '@/assets/images/tablePlaceholder/students.png';
import StudentApi from '@/api/CRM/students';
import getStudentObject from '@/constants/utils/CRM/getStudentObject';
import SettingsApi from '@/api/CRM/settings';
import StudentsListCard from '@/components/CRM/Students/StudentsList/StudentsListCard';
import { makeUseInfiniteScroll } from 'vue-use-infinite-scroll';

export default {
  name: 'StudentsList',
  components: { StudentsListCard },
  emits: ['update:openForm', 'update:openCard', 'edit', 'delete'],
  setup (props, { emit }) {
    const store = useStore();
    const imgPlaceholder = studentsPlaceholder;

    const pagination = ref({});

    const uploadedData = ref(false);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const settings = ref(null);
    const getSettings = async () => {
      if (settings.value) return;
      try {
        const { data: dataSettings } = await SettingsApi.getSettings();
        settings.value = dataSettings;
      } catch (e) {
        console.error(e);
      }
    };

    const useInfiniteScroll = makeUseInfiniteScroll({});
    const intersectionTrigger = ref(null);
    const pageRef = useInfiniteScroll(intersectionTrigger);
    const uploadPages = ref([]);

    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsList', data);
    const getStudentsList = async ({ page = 1 }) => {
      if (page === 1) {
        setStudents([]);
        uploadPages.value = [];
      }
      if (uploadPages.value.includes(page)) return;
      uploadedData.value = false;
      setLoaderRun(true);
      await getSettings();
      try {
        const limit = 25;
        const { data } = await StudentApi.getStudents({ limit, page });
        uploadPages.value.push(page);
        const { resource, ...paginationInfo } = data;
        pagination.value = paginationInfo;
        const studentsResp = resource.map(getStudentObject);
        setStudents(studentsResp);
        setLoaderRun(false);
        uploadedData.value = true;
      } catch (err) {
        console.error(err);
        setLoaderRun(false);
        uploadedData.value = true;
      }
    };

    watch(pageRef, (page) => {
      if (pagination.value.totalPages && pagination.value.totalPages < page) return;
      getStudentsList({ page });
    }, { immediate: true });

    onBeforeMount(() => getStudentsList({}));

    const deleteStudent = async ({ _id }) => {
      const index = students.value.findIndex(item => item._id === _id);
      const deletedStudent = index !== -1 ? students.value.splice(index, 1) : null;
      try {
        await StudentApi.deleteStudent(_id);
      } catch (err) {
        console.error(err);
        if (deletedStudent && index !== -1) students.value.splice(index, 0 , ...deletedStudent);
      }
    };

    const openFormStudent = () => {
      emit('update:openForm', true);
    };

    return {
      openFormStudent,
      intersectionTrigger,
      uploadedData,
      deleteStudent,
      imgPlaceholder,
      settings,
      students
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-students-list {
  @include global-font;
  padding: 0 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 12px;

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 0;
    height: calc(100vh - 158px);
    height: calc((var(--vh, 1vh) * 100) - 158px);
    color: $color-text;
    text-align: center;

    &__image {
      width: calc(100vw - 60px);
      max-width: 300px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }

    &__button {
      max-width: 300px;
      width: 100%;
    }
  }
}

</style>
