import * as types from '@/constants/mutationTypes';

const state = {
  template: [],
  timetable: []
};
const getters = {
  template: (state) => state.template,
  timetable: (state) => state.timetable
};
const actions = {
  setTemplate ({ commit }, data) {
    commit(types.SET_TEMPLATE, { data });
  },
  setTimetable ({ commit }, data) {
    commit(types.SET_TIMETABLE, { data });
  }
};
const mutations = {
  [types.SET_TEMPLATE] (state, { data }) {
    state.template = data;
  },
  [types.SET_TIMETABLE] (state, { data }) {
    state.timetable = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
