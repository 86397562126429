import { find, get } from 'lodash';
import country from '@/constants/country';

export default (phone) => {
  const modelCountry = get(phone, 'country', '');
  if (!modelCountry) return '';
  const number = get(phone, 'number', '');
  const { phone: countryCode } = find(country, { code: modelCountry }) || {};
  return countryCode && number ? `+${countryCode} ${number.replace(/-/gmi, ' ').replace(')', ') ')}` : '';
};
