<template>
  <div class="lp-calendar-lesson-card-toolbar">
    <ConfirmLesson
      :lesson="lesson"
      :mobile="mobile"
      :hover="hover"
      :buttons="buttons"
      @get-lessons="$emit('get-lessons')"
      @close-calendar-card="$emit('close-calendar-card')"
    />
    <DeleteLesson
      v-if="isCanBeDeleted"
      :buttons="buttons"
      :mobile="mobile"
      :lesson="lesson"
      :hover="hover"
      @delete="$emit('delete')"
    />
    <UpdateLesson
      v-if="!buttons || !mobile"
      :lesson="lesson"
      :mobile="mobile"
    />
  </div>
</template>

<script>
import ConfirmLesson from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard/ConfirmLesson';
import DeleteLesson from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard/DeleteLesson';
import UpdateLesson from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard/UpdateLesson';
import { computed } from 'vue';

export default {
  name: 'ToolbarLessonCard',
  components: {
    UpdateLesson,
    DeleteLesson,
    ConfirmLesson
  },
  props: {
    lesson: Object,
    mobile: Boolean,
    buttons: Boolean,
    hover: String
  },
  emits: ['delete', 'get-lessons', 'close-calendar-card'],
  setup (props) {
    const isCanBeDeleted = computed(() => {
      const { crmLessonStatus, crmLessons } = props.lesson;
      const hasPass = crmLessons.find(item => item.crmLessonPassId);
      return !(crmLessonStatus === 'Complete' && hasPass);
    });

    return {
      isCanBeDeleted
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-calendar-lesson-card-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  div + div {
    margin-left: 10px;

    @media (max-width: 480px) {
      margin-left: 8px;
    }
  }

}

</style>
