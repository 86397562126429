<template>
  <Modal
    :title="''"
    @save="closeForm"
    @remove="closeForm"
    :saveBtnText="'buttons.ok'"
    :hideButtons="['remove']"
    v-model:open="openModal"
    :editMode="true"
    :mobileVersion="isMobile"
  >
    <div
      class="lp-registration-modal"
      :class="{'lp-registration-modal_mobile': isMobile}"
    >
      <img
        class="lp-registration-modal__img"
        :class="{'lp-registration-modal__img_mobile': isMobile}"
        :src="registrationPlaceholder"
        alt="placeholder"
      />
      <p class="lp-registration-modal__title">
        {{ $t('signUp.registered.title') }}
      </p>
      <p class="lp-registration-modal__info">
        {{ $t('signUp.registered.info') }}
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import registrationPlaceholder from '@/assets/images/CRM-registration.png';
import { computed, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'RegistrationInfo',
  components: { Modal },
  props: {
    open: Boolean
  },
  emits: ['close'],
  setup (props, { emit }) {
    const openModal = ref(false);
    watch(() => props.open, (val) => openModal.value = val);

    const closeForm = () => {
      emit('close');
    };
    watch(openModal, (val) => {
      if (!val) closeForm();
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      closeForm,
      registrationPlaceholder,
      openModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-registration-modal {
  padding: 50px 245px 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-text;

  &_mobile {
    padding: 0 20px;
    margin: calc(50vh - 200px ) 0 0;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 309px;
    margin-bottom: 32px;

    &_mobile {
      max-width: 247px;
      margin: 0 auto 32px;
    }
  }

  &__title {
    margin: 0 auto 10px;
    max-width: 289px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
  }

  &__info {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
  }
}

</style>
