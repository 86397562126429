import * as types from '@/constants/mutationTypes';
import MobileDetect from 'mobile-detect';
const md = new MobileDetect(window.navigator.userAgent);

const state = {
  user: {},
  connectUserId: '',
  isMobile: !!md.mobile() || !!md.tablet() || window.innerWidth < 825
};
const getters = {
  user: (state) => state.user,
  connectUserId: (state) => state.connectUserId,
  isMobile: (state) => state.isMobile
};
const actions = {
  setUser ({ commit }, data) {
    commit(types.SET_USER, { data });
  },
  setConnectUserId ({ commit }, data) {
    commit(types.SET_CONNECT_USER_ID, { data });
  },
  setIsMobile ({ commit }, data) {
    commit(types.SET_IS_MOBILE, { data });
  }
};
const mutations = {
  [types.SET_USER] (state, { data }) {
    state.user = data;
  },
  [types.SET_CONNECT_USER_ID] (state, { data }) {
    state.connectUserId = data;
  },
  [types.SET_IS_MOBILE] (state) {
    state.isMobile = !!md.mobile() || window.innerWidth < 825;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
