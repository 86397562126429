<template>
  <div class="lp-crm-settings-lang">
    <CustomSelect
      :options="options"
      :fixed="!selectWidthAuto"
      :searchable="true"
      :label="'crm.settings.timeZones'"
      :keyProp="'key'"
      :selectNoLabel="selectNoLabel"
      :widthAuto="false"
      maxHeight="300px"
      v-model="timezone"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-crm-settings-lang-country"
          :class="{'lp-crm-settings-lang-country_text': !value.icon}"
        >
          <img
            class="lp-crm-settings-lang-country__flag"
            v-if="value.icon"
            :alt="value[keyProp]"
            :src="value.icon"
          />
          {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-crm-settings-lang-country"
          :class="{'lp-crm-settings-lang-country_text': !item.icon}"
        >
          <img
            class="lp-crm-settings-lang-country__flag"
            v-if="item.icon"
            :alt="item[keyProp]"
            :src="item.icon"
          />
          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { onBeforeMount, ref, watch } from 'vue';
import { sortBy } from 'lodash';
import moment from 'moment-timezone';

export default {
  name: 'SelectTimeZones',
  components: { CustomSelect },
  props: {
    defaultValue: String,
    stringStyle: Boolean,
    selectNoLabel: Boolean
  },
  emits: ['update:timezone'],
  setup (props, { emit }) {
    const selectWidthAuto = ref(true);

    const formatterInUTCFormat = (zone) => {
      const value = +(moment().tz(zone).utcOffset()) / 60;
      const znak = value < 0 ? '-' : '+';
      return {
        key: Math.abs(value) < 10 ? `${znak}0${Math.abs(value)}` : `${znak}${Math.abs(value)}`,
        offset: value
      };
    };
    const optionsList = moment.tz.names().map(zone => {
      const { key, offset } = formatterInUTCFormat(zone);
      return {
        title: zone,
        id: zone,
        key: `(UTC${key}) ${zone}`,
        offset
      };
    });

    const options = sortBy(optionsList, 'offset');

    const timezone = ref('');

    const emitValue = () => emit('update:timezone', timezone.value.title);

    const getTimeZone = (val) => {
      return options.find(zone => zone.title === val);
    };

    watch(timezone, emitValue);

    const setDefault = () => {
      const defaultValue = getTimeZone(props.defaultValue);
      if (defaultValue) timezone.value = defaultValue;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    return {
      options,
      timezone,
      selectWidthAuto
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-lang {
  &-country {
    display: grid;
    grid-template-columns: 22px auto;
    grid-gap: 8px;
    align-items: center;

    &_text {
      grid-template-columns: auto;
    }

    &__flag {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

</style>
