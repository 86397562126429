<template>
  <div class="lp-calendar-lesson-card-date">
    <div class="lp-calendar-lesson-card-date__label">
      {{ $t('calendar.lesson.dates') }}
    </div>
    <div class="lp-calendar-lesson-card-date__value">
      {{ date }}
      <LPTooltip :text="$t('crm.lessons.tooltips.repeat')">
        <div class="lp-calendar-lesson-card-date__repeatable">
          <repeatableIcon
            v-if="repeatable"
          />
        </div>
      </LPTooltip>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import moment from 'moment';
import { repeatableIcon } from '@/constants/icons/crm';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'DateLessonCard',
  components: {
    LPTooltip,
    repeatableIcon
  },
  props: {
    lesson: Object
  },
  setup (props) {
    const date = computed(() => {
      const start = get(props.lesson, 'crmLessonStart', '');
      const end = get(props.lesson, 'crmLessonEnd', '');
      return `${moment(start).format('dddd, DD MMMM, HH:mm')}-${moment(end).format('HH:mm')}`;
    });

    const repeatable = computed(() => get(props.lesson, 'repeatable', false));

    return {
      repeatable,
      date
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-calendar-lesson-card-date {
  display: grid;
  grid-gap: 5px;

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: $color-text
  }

  &__value {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: $color-sine-shaft;
  }

  &__repeatable {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }
}

</style>
