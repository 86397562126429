import * as types from '@/constants/mutationTypes';

const state = {
  focusSearch: false
};
const getters = {
  focusSearch: (state) => state.focusSearch
};
const actions = {
  setFocusSearch ({ commit }, data) {
    commit(types.SET_PLAYER, { data });
  }
};
const mutations = {
  [types.SET_PLAYER] (state, { data }) {
    state.focusSearch = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
