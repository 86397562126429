<template>
  <div class="lp-widget-timetable">
    <div
      class="lp-widget-timetable-title"
      :class="{'lp-widget-timetable-title_mobile': isMobile}"
    >
      {{ $t('titlePages.widget') }}
    </div>
    <div
      class="lp-widget-timetable-header"
      :class="{'lp-widget-timetable-header_mobile': isMobile}"
    >
      <div
        class="lp-widget-timetable-header__today"
        :class="{'lp-widget-timetable-header__today_mobile': isMobile}"
      >
        {{ calendarDateFormat }}
      </div>
      <div
        class="lp-widget-timetable-header__wrapper"
        :class="{'lp-widget-timetable-header__wrapper_mobile': isMobile}"
      >
        <div
          class="lp-widget-timetable-hints"
          :class="{'lp-widget-timetable-hints_mobile': isMobile}"
        >
          <div
            class="lp-widget-timetable-hints__hint"
            :class="{'lp-widget-timetable-hints__hint_mobile': isMobile}"
          >
            <div
              class="lp-widget-timetable-hints__icon lp-widget-timetable-hints__icon_free"
            ></div>
            - {{ $t('timetable.availableTime') }}
          </div>
          <div
            class="lp-widget-timetable-hints__hint"
            :class="{'lp-widget-timetable-hints__hint_mobile': isMobile}"
          >
            <div class="lp-widget-timetable-hints__icon lp-widget-timetable-hints__icon_busy"></div>
            - {{ $t('timetable.unavailableTime') }}
          </div>
          <div
            class="lp-widget-timetable-hints__hint"
            :class="{'lp-widget-timetable-hints__hint_mobile': isMobile}"
          >
            <div class="lp-widget-timetable-hints__icon lp-widget-timetable-hints__icon_booked">
              <profileIcon />
            </div>
            - {{ $t('timetable.bookedByMe') }}
          </div>
        </div>
        <div
          class="lp-widget-timetable-header__controls"
          :class="{'lp-widget-timetable-header__controls_mobile': isMobile}"
        >
          <DateControls
            v-model:date="calendarDate"
            :unit="'day'"
            :widget="true"
          />
          <DateTimetableControls
            v-model:date="calendarDate"
            :minDate="new Date()"
            :mini="true"
          />
        </div>
      </div>
      <div
        v-if="isMobile"
        class="lp-widget-calendar-header-timetable"
      >
        {{ formatDate }}
      </div>
    </div>
    <div class="lp-widget-timetable-body">
      <transition name="slide-calendar">
        <WidgetCalendar
          class="lp-widget-timetable-body__calendar"
          :class="{'lp-widget-timetable-body__calendar_mobile': isMobile}"
          v-show="!loading"
          :teacher="teacher"
          :rules="schedule"
          :key="calendarDate"
          :date="calendarDate"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import DateControls from '@/components/CRM/Lessons/Calendar/DateControls/DateControls';
import { watch, ref, computed, onBeforeMount } from 'vue';
import moment from 'moment';
import WidgetCalendar from '@/components/CRM/Lessons/TimetableWidget/WidgetCalendar';
import TimetableApi from '@/api/CRM/timetable';
import DateTimetableControls from '@/components/CRM/Lessons/Timetable/DateTimetableControls/DateTimetableControls';
import MobileDetect from 'mobile-detect';
import { profileIcon } from '@/constants/icons/crm';

export default {
  name: 'WidgetTimetable',
  components: { DateTimetableControls, WidgetCalendar, DateControls, profileIcon },
  props: {
    teacher: Object
  },
  setup (props) {
    const loading = ref(false);
    const setLoaderRun = (data) => loading.value = data;

    const calendarDate = ref(moment().format('YYYY-MM-DD'));
    const calendarDateFormat = computed(() => moment(calendarDate.value).format('MMMM YYYY'));
    const schedule = ref([]);

    const formatDate = computed(() => moment(calendarDate.value).format('DD dddd'));

    const getRangeDate = () => {
      const date = moment(calendarDate.value);
      return {
        startDate: date.utc().format(),
        endDate: date.add(1, 'day').utc().format()
      };
    };

    const getTimetable = async () => {
      const { startDate, endDate } = getRangeDate();
      const query = {
        teacherId: props.teacher._id,
        start: startDate,
        end: endDate
      };
      await setLoaderRun(true);
      try {
        const { data } = await TimetableApi.getTimetable(query);
        schedule.value = data;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        await setLoaderRun(false);
      }
    };

    watch([calendarDate, props], getTimetable);
    onBeforeMount(getTimetable);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      loading,
      calendarDate,
      calendarDateFormat,
      schedule,
      formatDate
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-widget-timetable {
  @include global-font;
  background: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  border-radius: 4px;

  &-title {
    padding: 24px 19px;
    font-weight: 800;
    font-size: 22px;
    line-height: 125%;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-text;
    border-bottom: 1px solid $color-alto;

    &_mobile {
      padding: 24px 12px;
      text-align: center;
    }
  }

  &-header {
    position: sticky;
    top: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
    min-height: 37px;
    padding: 20px 24px 30px;
    background-color: $color-white;
    z-index: 3;

    &_mobile {
      padding: 20px 12px 0;
      display: flex;
      flex-direction: column;
    }

    &__today {
      color: $color-text;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;

      &_mobile {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }

    &__controls {
      display: grid;
      grid-template-columns: auto 36px;
      grid-gap: 8px;

      &_mobile {
        margin-top: 20px;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;

      &_mobile {
        flex-direction: column;
      }
    }
  }

  &-hints {
    display: flex;

    &__hint {
      display: flex;
      margin-right: 20px;

      &_mobile {
        margin-bottom: 10px;
      }
    }

    &__icon {
      width: 18px;
      height: 18px;
      margin-right: 6px;
      border-radius: 3px;

      &_free {
        background: $color-emerald;
      }

      &_busy {
        border: 1px solid $color-alto;
      }

      &_booked {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-purple;
      }
    }

    &_mobile {
      flex-wrap: wrap;
      justify-content: center;

      @media (max-width: 420px) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  &-body {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 220px);
    background-color: $color-white;

    &__calendar {
      padding: 0 24px 24px;
      left: 0;
      right: 0;

      &_mobile {
        padding: 0 12px 24px;
      }
    }
  }
}

.lp-widget-calendar {
  @include global-font;

  &-header-timetable {
    position: sticky;
    top: 86px;
    margin-top: 20px;
    padding: 10px 80px 10px 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    z-index: 3;
  }
}
</style>
