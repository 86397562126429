<template>
  <div
    class="lp-call-select-type"
    :class="{'lp-call-select-type_mobile': isMobile}"
  >
    <div
      class="lp-call-select-type-title"
      :class="{'lp-call-select-type-title_mobile': isMobile}"
    >
      {{ $t('videoCallType.title') }}
    </div>
    <div
      class="lp-call-select-type-body"
      :class="{'lp-call-select-type-body_mobile': isMobile}"
    >
      <div
        class="lp-call-select-type-body-card lp-call-select-type-body-card_base"
        :class="{'lp-call-select-type-body-card_base_mobile lp-call-select-type-body-card_mobile': isMobile}"
      >
        <img
          :src="basePlaceholder"
          class="lp-call-select-type-body-card__image"
          :class="{'lp-call-select-type-body-card__image_mobile': isMobile}"
          alt="free"
        />
        <button
          class="lp-call-select-type-body-card__button lp-button"
          :class="{'lp-call-select-type-body-card__button_mobile': isMobile}"
          @click="selectType(true)"
        >
          {{ $t('videoCallType.base.button') }}
        </button>
        <div class="lp-call-select-type-body-card-list">
          <div
            class="lp-call-select-type-body-card-list__item"
            v-for="plus of $tm('videoCallType.base.list')"
            :key="plus"
          >
            <checkIcon />
            <span>{{ plus }}</span>
          </div>
        </div>
      </div>
      <div
        class="lp-call-select-type-body-card lp-call-select-type-body-card_prof"
        :class="{'lp-call-select-type-body-card_prof_mobile lp-call-select-type-body-card_mobile': isMobile}"
      >
        <goldMedal class="lp-call-select-type-body-card__medal" />
        <div class="lp-call-select-type-body-card__line" />
        <img
          :src="profPlaceholder"
          class="lp-call-select-type-body-card__image"
          :class="{'lp-call-select-type-body-card__image_mobile': isMobile}"
          alt="professional"
        />
        <button
          class="lp-call-select-type-body-card__button lp-button"
          :class="{'lp-call-select-type-body-card__button_mobile': isMobile}"
          @click="selectType(false)"
        >
          {{ $t('videoCallType.prof.button') }}
        </button>
        <div class="lp-call-select-type-body-card-list">
          <div
            class="lp-call-select-type-body-card-list__item"
            v-for="plus of $tm('videoCallType.prof.list')"
            :key="plus"
          >
            <checkIcon />
            <span>{{ plus }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkIcon,  goldMedal } from '@/constants/icons/videoCallTypes';
import basePlaceholder from '@/assets/images/videoTypes/free-lesson.png';
import profPlaceholder from '@/assets/images/videoTypes/prof-lesson.png';
import { computed, inject, onMounted } from 'vue';
import { useStore } from 'vuex';
import CALL_TYPES from '@/constants/enums/callTypes';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

export default {
  name: 'VideoCallSelectType',
  components: {
    checkIcon,
    goldMedal
  },
  props: {
    room: String
  },
  setup (props, { emit }) {
    const socket = inject('socket');
    const store = useStore();
    const setLiteVersion = (data) => store.dispatch('setLiteVersion', data);
    const selectType = (lite) => {
      socket.emit('requestcalltype', {
        roomId: props.room,
        callType: lite ? CALL_TYPES.BASIC : CALL_TYPES.PREMIUM
      });
      setLiteVersion(lite);
      emit('update:select', true);
      lite ? yandexTracker.callSelectStd() : yandexTracker.callSelectPro();
    };

    const isMobile = computed(() => store.getters.isMobile);

    onMounted(() => {
      selectType(false);
      console.log('onMounted');
    });

    return {
      selectType,
      isMobile,
      basePlaceholder,
      profPlaceholder
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-call-select-type {
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 57px 40px 54px;
  background-color: $color-white;

  &_mobile {
    padding: 25px 6px;
  }

  &-title {
    margin-bottom: 36px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    text-align: center;
    font-weight: 800;
    font-size: 22px;
    line-height: 125%;
    color: $color-text;

    &_mobile {
      font-size: 18px;
      margin-bottom: 25px;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 510px));
    justify-content: center;
    grid-gap: 40px;

    &_mobile {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      height: auto;
      min-height: calc(100vh - 100px);
      min-height: calc((var(--vh, 1vh) * 100) - 100px);
      grid-gap: 30px;
    }

    &-card {
      position: relative;
      height: auto;
      display: grid;
      grid-template-rows: 216px 50px auto;
      align-items: start;
      border-radius: 4px;
      background-color: $color-white;
      padding: 27px 46px 118px;
      overflow: hidden;

      &_mobile {
        grid-template-rows: 50px auto;
        padding: 25px 23px 40px;
      }

      &_base {
        border: 1px solid $color-alto;
        box-sizing: border-box;

        &_mobile {
          grid-row: 2;
        }
      }

      &_prof {
        box-shadow: 0 0 38px rgba($color-black, 0.09);
      }

      &__image {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;

        &_mobile {
          display: none;
        }
      }

      &__button {
        &_mobile {
          font-size: 12px;
        }
      }

      &__medal {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 2;
        pointer-events: none;
        user-select: none;
      }

      &__line {
        position: absolute;
        top: -30px;
        right: 30px;
        width: 20px;
        height: 140px;
        background: $color-selective-yellow;
        transform-origin: center center;
        transform: rotate(-45deg);
        z-index: 1;
        pointer-events: none;
        user-select: none;
      }

      &-list {
        display: grid;
        grid-gap: 24px;
        padding-top: 32px;

        &__item {
          display: grid;
          grid-template-columns: 15px auto;
          grid-gap: 13px;
          font-weight: 500;
          font-size: 14px;
          line-height: 125%;
        }
      }
    }
  }
}

</style>
