import russia from '@/assets/images/countryFlags/russia.png';
import unitedKingdom from '@/assets/images/countryFlags/united-kingdom.png';

const LANGUAGES = {
  RU: {
    id: 'ru',
    title: 'crm.languages.russian',
    icon: russia
  },
  EN: {
    id: 'en',
    title: 'crm.languages.english',
    icon: unitedKingdom
  }
};

export default LANGUAGES;