<template>
  <div class="lp-pagination">
    <div
      class="lp-pagination-nav lp-pagination-nav_prev"
      :class="{'lp-pagination-nav_disabled': page === 1}"
      @click="addPage(-1)"
    >
      <paginationArrow />
    </div>
    <div
      class="lp-pagination-item"
      :class="{
        'lp-pagination-item_active': 1 === page,
        'lp-pagination-item_last': 1 === totalPages
      }"
      @click="setPage(1)"
    >
      {{ 1 }}
    </div>
    <div
      class="lp-pagination-item lp-pagination-item_placeholder"
      v-if="page > 3"
    >
      ...
    </div>
    <div
      class="lp-pagination-item"
      :class="{
        'lp-pagination-item_active': num === page,
        'lp-pagination-item_last': num === totalPages
      }"
      v-for="(num) of pages"
      v-show="(page - 1 === num || page + 1 === num || page === num)
        || (page === 1 && page + 2 === num)
        || (page === totalPages && page - 2 === num)"
      :key="num"
      @click="setPage(num)"
    >
      {{ num }}
    </div>
    <div
      class="lp-pagination-item lp-pagination-item_placeholder"
      v-if="(totalPages - page) >= 3"
    >
      ...
    </div>
    <div
      class="lp-pagination-item lp-pagination-item_last"
      :class="{
        'lp-pagination-item_active': page === totalPages
      }"
      @click="setPage(totalPages)"
    >
      {{ totalPages }}
    </div>
    <div
      class="lp-pagination-nav lp-pagination-nav_next"
      :class="{'lp-pagination-nav_disabled': page === totalPages}"
      @click="addPage(1)"
    >
      <paginationArrow />
    </div>
  </div>
</template>

<script>
import { paginationArrow } from '@/constants/icons/crm';
import { computed } from 'vue';
import { fill } from 'lodash';

export default {
  name: 'Pagination',
  components: {
    paginationArrow
  },
  props: {
    limit: Number,
    page: Number,
    totalDocs: Number,
    totalPages: Number,
    nextPage: Number,
    prevPage: Number
  },
  emits: ['change'],
  setup (props, { emit }) {
    const pages = computed(() => fill(new Array((props.totalPages || 2) - 2), 1).map((page, i) => i + 2));

    const setPage = (page) => {
      emit('change', { page });
    };

    const addPage = (page) => {
      emit('change', { page: props.page + page });
    };

    return {
      pages,
      addPage,
      setPage
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-pagination {
  @include global-font;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 0 17px rgba($color-black, 0.08);

  &-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s ease-in;

    &:hover {
      background-color: $color-moon-raker;
    }

    &_next {
      margin-left: 10px;
      color: $color-purple
    }

    &_prev {
      margin-right: 10px;
      transform: scale(-1);
      color: $color-purple
    }

    &_disabled {
      color: $color-gray;
      pointer-events: none;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $color-gray;
    cursor: pointer;
    transition: 0.3s ease-in;
    user-select: none;

    &:hover {
      background-color: $color-moon-raker;
    }

    &_last {
      margin-right: 0;
    }

    &_active {
      font-weight: bold;
      color: $color-accent;
      background-color: $color-moon-raker;
      pointer-events: none;
    }

    &_placeholder {
      font-size: 12px;
      line-height: 17px;
      pointer-events: none;
    }
  }
}

</style>
