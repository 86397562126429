<template>
  <div
    class="lp-files-item"
    :class="{
      'lp-files-item_selected': selected === item._id,
      'lp-files-item_editable': openRename || selected === item._id,
      'lp-files-item_drag': drag,
      'lp-files-item_active': displayContextMenu,
      'lp-files-item_file': !currentType.isFolder,
      'lp-files-item_mobile': mobile && !modal
    }"
    @click="clickByItem"
    v-touch:hold="openContextMenu"
    @contextmenu.stop="openContextMenu"
  >
    <div class="lp-files-item__icon">
      <component :is="currentType.icon" />
    </div>
    <div
      class="lp-files-item-name"
      :class="{
        'lp-files-item-name_editable': openRename,
        'lp-files-item-name_selected': selected === item._id
      }"
      :contenteditable="openRename && !modal ? 'true' : false"
      @input="inputName"
      @keyup.enter="saveName"
      @keyup.esc="resetName"
      @dblclick="onRename"
      ref="nameEl"
    >
      {{ item.name || item.originalName }}
    </div>
    <FileContextMenu
      v-if="displayContextMenu"
      v-model:status="displayContextMenu"
      :position="contextMenuPosition"
      :options="currentType.options"
      :item="item"
      :mobile="mobile"
      @rename="onRename"
      @delete="onDelete"
    />
    <teleport
      to="#app"
      :disabled="!mobile"
    >
      <transition name="bounce">
        <FileAcceptDelete
          v-if="displayAcceptDelete"
          v-model:status="displayAcceptDelete"
          :position="contextMenuPosition"
          :mobile="mobile"
          :file="item"
          @accept="acceptDeleteFile"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { fileTypes } from '@/constants/fileTypes';
import FileContextMenu from '@/components/CRM/Files/Menus/FileContextMenu';
import { computed, nextTick, reactive, ref, watch } from 'vue';
import { trim } from 'lodash';
import FileAcceptDelete from '@/components/CRM/Files/Menus/FileAcceptDelete';
import FolderApi from '@/api/CRM/folders';
import FilesApi from '@/api/CRM/files';
import { useStore } from 'vuex';
import FOLDER_TYPES from '@/constants/enums/folders';

export default {
  name: 'FilesItem',
  components: {
    FileAcceptDelete,
    FileContextMenu
  },
  props: {
    modal: Boolean,
    mobile: Boolean,
    selected: String,
    item: Object,
    drag: Boolean,
    currentMenu: String
  },
  emits: ['update:menu', 'update:name', 'update:selected'],
  setup (props, { emit }) {
    const formatFile = computed(() => {
      const { originalName = '' } = props.item;
      return originalName.replace(/.+\./g, '');
    });

    const displayContextMenu = ref(false);
    const contextMenuPosition = reactive({});
    const openContextMenu = (e) => {
      const { folderType } = props.item;
      e.stopPropagation();
      if (e.cancelable) e.preventDefault();
      if (folderType === FOLDER_TYPES.RECORDS_FOLDER) return;
      const { pageX, pageY } = e;
      displayContextMenu.value = !displayContextMenu.value;
      contextMenuPosition.left = pageX;
      contextMenuPosition.top = pageY;
      emit('update:menu', displayContextMenu.value ? props.item._id : null);
    };

    const store = useStore();
    const folderList = computed(() => store.getters.folderList);
    const setFolderList = (data) => store.dispatch('setFolderList', data);
    const filesList = computed(() => store.getters.filesList);
    const setFilesList = (data) => store.dispatch('setFilesList', data);

    const removeFormStore = (id, mimetype) => {
      if (mimetype) {
        setFilesList(filesList.value.filter(({ _id }) => _id !== id));
      } else {
        setFolderList(folderList.value.filter(({ _id }) => _id !== id));
      }
    };

    const recoverToStore = (item, mimetype) => {
      if (mimetype) {
        setFilesList(filesList.value.concat(item));
      } else {
        setFolderList(folderList.value.concat(item));
      }
    };

    const displayAcceptDelete = ref(false);
    const acceptDeleteFile = async () => {
      const { _id: targetId, mimetype } = props.item;
      const removeTarget = mimetype ? FilesApi.removeFile : FolderApi.removeFolder;
      if (!targetId) return;
      removeFormStore(targetId, mimetype);
      try {
        await removeTarget(targetId);
      } catch (e) {
        recoverToStore(props.item, mimetype);
        console.error(e);
      }
    };

    const currentType = computed(() => {
      const { mimetype = '' } = props.item;
      if (!mimetype) return fileTypes[0];
      const type = mimetype.replace(/.+\//g, '');
      return fileTypes.find((item) => item.types.includes(type)) || fileTypes[5];
    });

    const nameEl = ref(null);
    const setFocus = () => {
      const el = nameEl.value;
      el.focus();
      const { originalName = '', mimetype } = props.item;
      const type = originalName.replace(/.+\./g, '');

      const selection = window.getSelection();
      selection.removeAllRanges();

      if (el.lastChild && el.lastChild.nodeType === 3) {
        const offset = el.lastChild.length - (mimetype ? type.length + 1 : 0);
        selection.setBaseAndExtent(el.lastChild, 0, el.lastChild, offset);
      } else {
        selection.setBaseAndExtent(el, 0, el, el.childNodes.length);
      }
    };

    const openRename = ref(false);
    const newName = ref('');
    const inputName = (e) => {
      const { target, inputType } = e;
      if (inputType === 'insertParagraph') target.innerHTML = newName.value;
      newName.value = target.innerText;
    };

    const renameFile = async (file, name) => {
      const type = file.originalName.replace(/.+\./g, '');
      if (!name.includes(type)) name += `.${type}`;
      try {
        const { data } = await FilesApi.renameFile({ id: file._id, name });
        emit('update:name', data.originalName);
      } catch (e) {
        console.error(e);
      }
    };

    const renameFolder = async (folder, name) => {
      folder.name = name;
      try {
        await FolderApi.updateFolder(folder);
      } catch (e) {
        console.error(e);
      }
    };

    const saveName = () => {
      if (!openRename.value) return;
      const { item } = props;
      const name = item.name || item.originalName;
      const value = newName.value ? trim(newName.value) : name;
      if (nameEl.value) nameEl.value.innerHTML = value;
      emit('update:name', value);
      openRename.value = false;
      if (item.mimetype) {
        renameFile(item, value);
      } else {
        renameFolder(item, value);
      }
    };
    const resetName = (e) => {
      if (!openRename.value) return;
      const { target } = e;
      target.innerHTML = props.item.name || props.item.originalName;
      openRename.value = false;
    };
    const onRename = () => {
      if (openRename.value) return;
      newName.value = props.item.name || props.item.originalName;
      openRename.value = true;
      displayContextMenu.value = false;
      if (nameEl.value) nextTick(setFocus);
    };

    const onDelete = () => {
      displayContextMenu.value = false;
      displayAcceptDelete.value = true;
    };

    const closeContextMenu = () => {
      emit('update:selected', '');
      displayContextMenu.value = false;
      displayAcceptDelete.value = false;
      saveName();
    };
    watch(() => props.currentMenu, () => {
      if (props.currentMenu !== props.item._id) closeContextMenu();
    });

    const clickByItem = (e) => {
      if (props.modal && props.item.mimetype) {
        emit('update:selected', props.item._id);
        return;
      }
      if (props.item.mimetype) {
        e.stopPropagation();
        emit('update:selected', props.item._id);
      }
      if (props.mobile && props.item.mimetype) {
        displayContextMenu.value = true;
      }
    };

    return {
      formatFile,
      currentType,
      displayContextMenu,
      contextMenuPosition,
      closeContextMenu,
      openContextMenu,
      onRename,
      onDelete,
      openRename,
      nameEl,
      inputName,
      saveName,
      resetName,
      displayAcceptDelete,
      acceptDeleteFile,
      clickByItem
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-files-item {
  @include global-font;
  position: relative;
  display: grid;
  grid-template-rows: 68px auto;
  align-items: start;
  justify-content: center;
  grid-gap: 12px;
  background-color: transparent;
  border-radius: 4px;
  padding: 14px 12px;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  transition: 0.3s;
  cursor: pointer;

  &_editable {
    z-index: 2;
    background-color: $color-moon-raker;
    cursor: default;
  }

  &_drag {
    background: rgba($color-accent, 0.05);
    border: 2px dashed $color-accent;
  }

  &_file {
    grid-template-rows: 60px auto;
  }

  &_active,
  &:hover {
    background-color: $color-moon-raker;
  }

  &_selected {
    border: 1px solid $color-accent;
  }

  &_mobile {
    background-color: transparent !important;
    border: 1px solid transparent !important;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    text-align: center;
    user-select: none;

    &_editable {
      display: block;
      cursor: text;
    }

    &_selected {
      display: block;
    }
  }
}

</style>
