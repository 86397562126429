import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store/store';
import router from '@/router/index';
import { VueCookieNext } from 'vue-cookie-next';
import focus from '@/directives/focus';
import clickOutside from '@/directives/clickOutside';
import installSocket from '@/constants/socket.io';
import CountryApi from '@/api/Country/api';
import i18nLib from '@/i18n';
import Maska from 'maska';
import setMomentLocale from '@/constants/utils/getMomentLocale';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import Vue3TouchEvents from 'vue3-touch-events';
import { dragscrollNext } from 'vue-dragscroll';
import getLanguage from '@/constants/utils/getLanguage';

const isProduction = process.env.NODE_ENV !== 'development';

if (isProduction) {
  Sentry.init({
    dsn: 'https://e847aa04cc6f4e15aaeb23f5ae1b3c4b@o136879.ingest.sentry.io/5720849',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

async function mainCreateApp () {
  let currentCountryCode = 'RU';
  try {
    const { data: { country_code: countryCodeApi = 'EN' } } = await CountryApi.getCountry();
    const countryCode = countryCodeApi || 'RU';
    currentCountryCode = window.location.hostname === 'localhost' && !countryCode ? 'RU' : countryCode;
  } finally {
    const currentLanguage = getLanguage(currentCountryCode);
    setMomentLocale(currentLanguage);
    store.dispatch('setCountryCode', currentCountryCode);
    const i18n = i18nLib.createI18nLang(currentLanguage);
    i18n.global.locale = currentLanguage;
    const app = createApp(App);
    const routers = router.createRouters(i18n);

    app.directive('focus', focus);
    app.directive('click-outside', clickOutside);
    app.directive('dragscroll', dragscrollNext);

    app.provide('countryCode', currentCountryCode);
    app.provide('isDesktop', window.innerWidth > 768);

    // TODO надо найти нормальное решение прокинуть store в src/constants/_rtc/audioEngine/mp3Player.js
    // window.$vuexstore = store;

    app.use(Vue3TouchEvents);
    app.use(Maska);
    app.use(i18n);
    app.use(store);
    app.use(routers);
    app.use(installSocket);
    app.use(VueCookieNext);

    if (isProduction) {
      app.config.errorHandler = (err) => {
        Sentry.captureException(err);
      };
      window.addEventListener('error', (event) => {
        Sentry.captureException(event);
      });
      window.addEventListener('unhandledrejection', (event) => {
        Sentry.captureException(event);
      });
    }

    app.mount('#app');
  }
}

mainCreateApp();
