<template>
  <div class="lp-country-select">
    <CustomSelect
      :options="countryList"
      :searchable="true"
      :modelValue="modelValue"
      :error="error"
      :fixed="fixed"
      v-model="selectedCountry"
      keyProp="name"
      :required="required"
      :label="label"
      :maxHeight="isMobile ? '290px' : '190px'"
      :minHeight="isMobile ? '290px' : 'auto'"
      :placeholder="'placeholders.country'"
      @focus="$emit('focus')"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-country-select-country"
          :class="{'lp-country-select-country_text': !value.icon}"
        >
          <img
            class="lp-country-select-country__flag"
            v-if="value.icon"
            :alt="value[keyProp]"
            :src="value.icon"
          />
          {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-country-select-country"
          :class="{'lp-country-select-country_text': !item.icon}"
        >
          <img
            class="lp-country-select-country__flag"
            v-if="item.icon"
            :alt="item[keyProp]"
            :src="item.icon"
          />
          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import country from '@/constants/country';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';

export default {
  name: 'CountrySelect',
  components: { CustomSelect },
  props: {
    modelValue: Object,
    required: Boolean,
    label: String,
    listFilter: Array,
    error: [Boolean, String],
    fixed: Boolean,
    displayAll: Boolean
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const selectedCountry = ref({});
    const { t } = useI18n();
    const allValue = {
      name: t('country.all'),
      id: ''
    };

    watch(selectedCountry, () => {
      emit('update:modelValue', selectedCountry);
    });
    country.forEach((item) => {
      const { name } = item;
      item.name = t(name);
    });
    country.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA === nameB) {
        return 0;
      }
      return nameA > nameB ? 1 : -1;
    });
    const countryList = computed(() => {
      const list = props.listFilter ? country.filter((item) => props.listFilter.includes(item.id)) : country;
      return props.displayAll ? [allValue].concat(list) : list;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return { countryList, allValue, selectedCountry, isMobile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-country-select {
  @include global-font;

  &-country {
    display: grid;
    grid-template-columns: 22px auto;
    grid-gap: 8px;
    align-items: center;

    &_text {
      grid-template-columns: auto;
    }

    &__flag {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

</style>
