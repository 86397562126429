<template>
  <div
    class="lp-improve-button"
    @click.prevent.stop="openForm"
  >
    <chatIcon />
    {{ $t('buttons.improve') }}
    <ImproveServiceForm
      :openForm="openModal"
      v-model:openForm="openModal"
    />
  </div>
</template>

<script>
import { chatIcon } from '@/constants/icons';
import { ref } from 'vue';
import ImproveServiceForm from '@/components/CRM/Sidebar/ImproveServiceForm/ImproveServiceForm';

export default {
  name: 'ImproveServiceButton',
  components: {
    ImproveServiceForm,
    chatIcon
  },
  setup () {
    const openModal = ref(false);
    const openForm = () => {
      openModal.value = true;
    };

    return {
      openForm,
      openModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-improve-button {
  @include global-font;
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  grid-gap: 0 10px;
  padding: 15px 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: $color-text;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in;

  &::first-letter {
    text-transform: capitalize;
  }

  &:hover {
    background-color: $color-moon-raker;
    color: $color-accent;
  }
}

</style>
