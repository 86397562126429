<template>
  <div class="lp-crm-settings-lang">
    <CustomSelect
      :options="options"
      :fixed="!selectWidthAuto"
      :keyProp="'key'"
      :selectNoLabel="selectNoLabel"
      :widthAuto="selectWidthAuto"
      maxHeight="300px"
      v-model="currency"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-crm-settings-lang-country lp-crm-settings-lang-country_text"
        >
          <langIcon /> {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-crm-settings-lang-country lp-crm-settings-lang-country_text-select"
        >
          {{ item[keyProp] }}
          <img
            v-if="currency[keyProp] === item[keyProp]"
            :src="require(`@/assets/images/landing/lang-check.svg`)"
            alt="check"
          />
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { map } from 'lodash';
import { onBeforeMount, ref, watch } from 'vue';
import LANGUAGES from '@/constants/landing/languages';
import { langIcon } from '@/constants/icons/landing';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SelectLang',
  components: { CustomSelect, langIcon },
  props: {
    defaultValue: String,
    stringStyle: Boolean,
    selectNoLabel: Boolean
  },
  emits: ['update:currency'],
  setup (props, { emit }) {
    const { t } = useI18n();

    const selectWidthAuto = ref(true);

    const options = ref(
      map(LANGUAGES, (item) => ({ key: t(item.title), ...item }))
    );
    const currency = ref(options.value[0]);
    const emitValue = () => emit('update:currency', currency.value.id);

    const getCurrency = (val) => {
      const options = map(LANGUAGES, (item) => ({
        key: t(item.title),
        ...item
      }));
      return options.find((currency) => currency.id === val);
    };

    const getOptions = () =>
      map(LANGUAGES, (item) => ({
        key: t(item.title),
        ...item
      }));

    watch(currency, emitValue);

    const setDefault = () => {
      console.log('tes');
      const defaultValue = getCurrency(props.defaultValue);
      const defaultOptions = getOptions();
      if (defaultValue) currency.value = defaultValue;
      if (defaultOptions) options.value = defaultOptions;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    return {
      options,
      currency,
      selectWidthAuto
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-settings-lang-country {
  display: grid;
  grid-template-columns: 22px auto;
  grid-gap: 8px;
  align-items: flex-start;

  &_text {
    display: flex;
    align-items: center;
    grid-template-columns: auto;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
  }

  &_text-select {
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-template-columns: auto;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    padding: 8px 16px;
  }

  &__flag {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
