<template>
  <div
    class="lp-student-profile"
    :class="{'lp-student-profile_mobile': isMobile}"
  >
    <div
      class="lp-student-profile-photo"
      :class="{'lp-student-profile-photo_placeholder': !studentPhoto,
               'lp-student-profile-photo_mobile': isMobile}"
    >
      <img
        v-if="studentPhoto"
        :src="studentPhoto"
        alt="avatar"
      >
      <userIcon
        v-else
      />
    </div>
    <div
      class="lp-student-profile-info"
      :class="{'lp-student-profile-info_mobile': isMobile}"
    >
      <div class="lp-student-profile-info-item lp-student-profile-info-item_header">
        <div class="lp-student-profile-info-item__title lp-student-profile-info-item__title_header">
          {{ $t('crm.students.form.titleCard') }}
        </div>
        <div
          class="lp-student-profile-info-item__icon lp-student-profile-info-item__icon_export"
          v-show="!isMobile"
          @click.stop="generatePdf"
        >
          <LPTooltip :text="$t('crm.students.form.export')">
            <exportIcon />
          </LPTooltip>
        </div>
      </div>
      <!--name-->
      <div class="lp-student-profile-info-item">
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.name') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          {{ student.fullName }}
        </div>
      </div>
      <!--url-->
      <div
        class="lp-student-profile-info-item lp-student-profile-info-item_virtual-class"
        :class="{'lp-student-profile-info-item_virtual-class_mobile': isMobile}"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.url') }}
        </div>
        <div
          class="lp-student-profile-info-item__value lp-student-profile-info-item__value_link"
          :class="{'lp-student-profile-info-item__value_link_mobile': isMobile}"
        >
          <div
            class="lp-student-profile-info-item__url"
            v-if="student.virtualClassUrl"
          >
            <VideoCallCreator
              :roomId="student.virtualClassUrl.replace(/.+\//gmi, '')"
            >
              {{ student.virtualClassUrl }}
            </VideoCallCreator>
          </div>
          <div
            class="lp-student-profile-info-item__icon lp-student-profile-info-item__icon_link"
            :class="{'lp-student-profile-info-item__icon_link_mobile': isMobile}"
            @click="copyUrl"
          >
            <LPTooltip :text="$t('crm.lessons.tooltips.copy')">
              <linkIcon />
            </LPTooltip>
          </div>
        </div>
        <transition name="fade">
          <div
            class="lp-student-profile-info-item__value lp-student-profile-info-item__value_copied"
            :class="{'lp-student-profile-info-item__value_copied_mobile': isMobile}"
            v-if="copiedLink"
          >
            <span
              class="lp-student-profile-info-item__copy-text"
              :class="{'lp-student-profile-info-item__copy-text_mobile': isMobile}"
            >
              {{ $t('lessonCall.copied') }}
            </span>
          </div>
        </transition>
      </div>
      <!--      Student link-->
      <div
        class="lp-student-profile-info-item lp-student-profile-info-item_student"
        :class="{'lp-student-profile-info-item_student__mobile' : isMobile}"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.profileLink') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          <CopyLink
            :link="studentLink"
            :isMobile="isMobile"
          />
        </div>
      </div>
      <!--lessonCost-->
      <div
        class="lp-student-profile-info-item"
        v-if="student.individualLessonPrice"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.lessonCost') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          {{ studentLessonCost }} {{ settings.currentCurrency }}
        </div>
      </div>
      <!--status-->
      <div class="lp-student-profile-info-item">
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.status') }}
        </div>
        <div
          class="lp-student-profile-info-item__value lp-student-profile-info-item__value_status"
          :style="studentStatus.style"
        >
          <template v-if="studentStatus.title">
            {{ $t(studentStatus.title) }}
          </template>
        </div>
      </div>
      <!--birthday-->
      <div
        class="lp-student-profile-info-item"
        v-if="studentBirthday"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.birthday') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          {{ studentBirthday }}
        </div>
      </div>
      <!--phone-->
      <div
        class="lp-student-profile-info-item"
        v-if="studentPhone"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.phone') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          {{ studentPhone }}
        </div>
      </div>
      <!--email-->
      <div
        class="lp-student-profile-info-item"
        v-if="student.email"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t(isMobile ? 'crm.students.form.emailShort' : 'crm.students.form.email') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          {{ student.email }}
        </div>
      </div>
      <!--social media-->
      <div
        class="lp-student-profile-info-item lp-student-profile-info-item_social-media"
        v-if="student.socialMedia && student.socialMedia && student.socialMedia.length"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.socialNetworks.label') }}
        </div>
        <div class="lp-student-profile-info-item__value">
          <div class="social-media-list">
            <a
              v-for="soc in student.socialMedia"
              :key="soc._id"
              :href="soc.socialMediaLink"
              target="_blank"
            >
              <component
                :is="getSocialIcon(soc.socialMediaType)"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--notes-->
    <div
      class="lp-student-profile-note"
      v-if="isShowNote"
    >
      <div
        class="lp-student-profile-info-item lp-student-profile-info-item_note"
        :class="{'lp-student-profile-info-item_note_mobile': isMobile}"
      >
        <div class="lp-student-profile-info-item__title">
          {{ $t('crm.students.form.note') }}
        </div>
        <div
          class="lp-student-profile-info-item__value note-value"
        >
          <div
            ref="noteValueContent"
            class="note-value__content"
            :class="{'note-value__content_full': isShowFullContent}"
            v-html="student.note"
          ></div>
          <div
            v-if="isShowMoreLink"
            class="note-value__read-more"
            @click="isShowFullContent = !isShowFullContent"
          >
            {{ !isShowFullContent ? $t('crm.students.form.readMore') : $t('crm.students.form.lessMore') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from 'vue';
import { get } from 'lodash';
import getPhoneString from '@/constants/utils/getPhoneString';
import moment from 'moment';
import { userIcon, exportIcon } from '@/constants/icons';
import { linkIcon } from '@/constants/icons/crm';
import statusStudentList from '@/constants/crm/studentStatuses';
import timeout from '@/constants/utils/timeout';
import VideoCallCreator from '@/components/VideoCall/VideoCallCreator/VideoCallCreator';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon, OkIcon,
  PinterestIcon,
  TwitterIcon, VkIcon,
  YouTubeIcon
} from '@/constants/icons/iconsSocialMedia';
import MobileDetect from 'mobile-detect';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { useStore } from 'vuex';
import SettingsApi from '@/api/CRM/settings';
import FORMATS from '@/constants/formats';
import numeral from 'numeral';
import { FRONTEND_URL } from '@/constants/domains';
import CopyLink from '@/components/StudentProfile/CopyLink';

export default {
  name: 'StudentProfile',
  components: {
    LPTooltip,
    VideoCallCreator,
    userIcon,
    exportIcon,
    linkIcon,
    CopyLink
  },
  props: {
    mobileVersion: Boolean,
    student: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['generate-pdf'],
  setup (props, { emit }) {
    const studentPhoto = computed(() => get(props.student, 'uploadPhoto.url', ''));
    const studentLessonCost = computed(() => {
      const cost = get(props.student, 'individualLessonPrice', '');
      return cost ? numeral(cost).format(FORMATS.FINANCE) : '';
    });
    const studentPhone = computed(() => {
      const phoneObj = get(props.student, 'phone', '') || {};
      return getPhoneString(phoneObj) || '';
    });
    const studentBirthday = computed(() => {
      const date = get(props.student, 'birthDate', '');
      if (!date) return '';
      return moment(date).format('DD MMM, YYYY');
    });
    const studentStatus = computed(() => {
      const value = get(props.student, 'status', '');
      return statusStudentList.find(item => item.id === value) || {};
    });

    const studentLink = computed(() => `${FRONTEND_URL}/student/${props.student._id}`);

    const copiedLink = ref(false);
    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(props.student.virtualClassUrl);
        copiedLink.value = true;
        await timeout(2000);
        copiedLink.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    const generatePdf = () => {
      emit('generate-pdf');
    };

    const getSocialIcon = (type) => {
      switch (type) {
      case 1:
        return FacebookIcon();
      case 2:
        return PinterestIcon();
      case 3:
        return TwitterIcon();
      case 4:
        return YouTubeIcon();
      case 5:
        return InstagramIcon();
      case 6:
        return LinkedInIcon();
      case 7:
        return VkIcon();
      case 8:
        return OkIcon();
      }
    };

    const isShowFullContent = ref(false);
    const isShowNote = computed(() => {
      const contentDiv = document.createElement('div');
      contentDiv.innerHTML = props.student.note;
      const contentText = contentDiv.innerText;
      return contentText.length;
    });

    const noteValueContent = ref(null);
    const isShowMoreLink = computed(() => {
      if (noteValueContent.value) {
        const { offsetHeight, scrollHeight } = noteValueContent.value;
        return scrollHeight > (offsetHeight + 10);
      }
      return false;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const setSettings = (data) => store.dispatch('setSettings', data);
    const getSettings = async () => {
      if (settings.value._id) return;
      try {
        const { data } = await SettingsApi.getSettings();
        await setSettings(data);
      } catch (e) {
        console.error(e);
      }
    };
    onBeforeMount(getSettings);

    return {
      settings,
      isMobile,
      studentPhoto,
      studentPhone,
      studentStatus,
      studentLink,
      studentBirthday,
      studentLessonCost,
      copyUrl,
      copiedLink,
      generatePdf,
      getSocialIcon,
      isShowFullContent,
      isShowNote,
      noteValueContent,
      isShowMoreLink
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-student-profile {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 40px 53px;
  padding-bottom: 52px;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &_mobile {
    width: 100%;
    justify-content: space-between;
    grid-gap: 22px 30px;
  }

  &-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    overflow: hidden;

    &_mobile {
      margin: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &-note {
    grid-column: 1/3;
  }

  &-info {
    display: grid;
    grid-gap: 18px;
    grid-template-rows: repeat(auto-fill, 1fr);

    &_mobile {
      width: 100%;
      grid-gap: 22px;
    }

    &-item {
      position: relative;
      display: grid;
      grid-template-columns: 200px 1fr;
      align-items: center;
      grid-gap: 28px;

      @media (max-width: 481px) {
        grid-template-columns: 120px 1fr;
      }

      &_note {
        grid-template-columns: auto 1fr;
        align-items: start;

        &_mobile {
          grid-gap: 8px;
          grid-template-columns: 1fr;
        }
      }

      &_virtual-class {
        &_mobile {
          grid-row: 8;
          display: grid;
          grid-gap: 4px;
          grid-template-columns: 1fr;
        }
      }

      &_student {
        &__mobile {
          grid-row: 9;
          display: grid;
          grid-gap: 4px;
          grid-template-columns: 1fr;
        }
      }

      &_header {
        margin-bottom: 6px;
        grid-template-columns: 1fr auto;
      }

      &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        color: $color-black;
        user-select: none;

        @media (max-width: 481px) {
          font-size: 14px;
        }

        &:after {
          content: ':';
        }

        &_header {
          text-transform: uppercase;

          @media (max-width: 481px) {
            font-size: 16px;
          }

          &:after {
            content: '';
          }
        }
      }

      &__icon {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &_export {
          width: 28px;
          height: 28px;
          background-color: $color-moon-raker;
          border-radius: 50%;
        }

        &_link {
          margin-left: 12px;

          &_mobile {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-moon-raker;
            border-radius: 50%;
          }
        }
      }

      &__copy-text {
        grid-column: 2;
        color: $color-chateau-green;
        background-color: $color-white;

        &_mobile {
          grid-column: 1;
          line-height: 32px;
        }
      }

      &__url {
        margin-right: 12px;
      }

      &__value {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 125%;
        color: $color-text;
        word-break: break-word;

        @media (max-width: 481px) {
          font-size: 14px;
        }

        &_link {
          display: flex;
          align-items: center;
          color: $color-accent;
          cursor: pointer;
          transition: 0.3s ease-in;
          white-space: nowrap;

          &_mobile {
            justify-content: space-between;
          }

          &:hover {
            color: $color-accent-hover;
          }
        }

        &_copied {
          position: absolute;
          right: 0;
          display: grid;
          width: 100%;
          grid-template-columns: 200px 1fr;
          align-items: center;
          justify-content: center;
          grid-gap: 28px;

          &_mobile {
            left: 0;
            right: auto;
            top: 21px;
            height: 32px;
            bottom: 0;
            grid-template-columns: 1fr 32px;
          }
        }

        &_status {
          width: max-content;
          padding: 5px 12px;
          border-radius: 4px;
          font-weight: bold;
          font-size: 14px;
          line-height: 125%;
        }
      }
    }
  }
}

.social-media-list {
  display: flex;

  a {
    width: 20px;
    height: 20px;
  }

  a + a {
    margin-left: 8px;
  }

}

.note-value {
  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &_full {
      display: block;
    }
  }

  &__read-more {
    margin-top: 5px;
    color: $color-accent;
    transition: color .3s ease;
    cursor: pointer;

    &:hover {
      color: $color-accent-hover;
    }

  }
}

</style>

<style lang="scss">
.social-media-list {
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
