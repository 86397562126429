<template>
  <div
    class="lp-logout-button"
    @click="logOut"
  >
    <myExit />
    {{ $t('createRoom.logout') }}
  </div>
</template>

<script>
import { VueCookieNext } from 'vue-cookie-next';
import { camelCase, forEach } from 'lodash';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { myExit } from '@/constants/icons';

export default {
  name: 'LogoutButton',
  components: {
    myExit
  },
  setup () {
    const router = useRouter();
    const store = useStore();

    const logOut = () => {
      VueCookieNext.removeCookie('token');
      forEach(store.getters, (value, key) => {
        let data = '';
        switch (typeof value) {
        case 'boolean':
          data = false;
          break;
        case 'number':
          data = 0;
          break;
        case 'object':
          data = Array.isArray(value) ? [] : {};
          break;
        }
        if (key === 'countryCode') return;
        const commit = camelCase(`set ${key}`);
        store.dispatch(commit, data);
      });
      router.push({ name: 'Login' });
      // localStorage.removeItem('lang');
    };

    return {
      logOut
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-logout-button {
  @include global-font;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto;
  grid-gap: 10px;
  padding: 15px 18px;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: $color-text;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in;

  &:hover {
    background-color: $color-moon-raker;
    color: $color-accent;
  }
}

</style>
