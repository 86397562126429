<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__left">
        <div class="header__logo">
          <router-link :to="{name: 'Landing'}">
            <newLogoIcon />
          </router-link>
        </div>
      </div>
      <div class="header__right">
        <div
          v-if="!isOpenMenu"
          class="header-icons"
        >
          <a
            href="https://www.youtube.com/channel/UC1X3lRIe5H2N3HU6EIFoZRw"
            class="header-icons__icon"
            target="_blank"
          >
            <youtubeIcon />
          </a>
          <a
            href="https://t.me/joinchat/yV7IcfisXiEwMjMy"
            class="header-icons__icon"
            target="_blank"
          >
            <telegramIcon />
          </a>
          <a
            href="https://instagram.com/musio.io?utm_medium=copy_link"
            class="header-icons__icon"
            target="_blank"
          >
            <instaIcon />
          </a>
        </div>
        <button
          @click="onMenuClick"
          class="header__btn"
        >
          <menuIcon
            class="header__icon"
            v-if="!isOpenMenu"
          />
          <menuClose v-else />
        </button>
        <transition name="fade-fast">
          <Menu
            v-if="isOpenMenu"
            @close-menu="onMenuClose"
            class="menu-wrapper"
          />
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  newLogoIcon,
  youtubeIcon,
  instaIcon,
  telegramIcon,
  menuIcon,
  menuClose
} from '@/constants/icons/landing';
import Menu from '@/components/Landing/LandingMobile/Menu';

export default {
  name: 'Header',
  components: {
    Menu,
    newLogoIcon,
    youtubeIcon,
    instaIcon,
    telegramIcon,
    menuIcon,
    menuClose
  },
  props: {
    languageEn: Boolean
  },
  emits: ['open-menu'],
  setup (props, { emit }) {
    const { locale } = useI18n({ useScope: 'global' });

    const isOpenMenu = ref(false);
    const onMenuClick = () => {
      isOpenMenu.value = !isOpenMenu.value;
      emit('open-menu', isOpenMenu.value);
      if (isOpenMenu.value) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    };

    const onMenuClose = () => {
      isOpenMenu.value = false;
    };

    const closeMenu = (e) => {
      if (e.key === 'Escape' && isOpenMenu.value) {
        isOpenMenu.value = false;
        document.body.classList.remove('menu-open');
      }
    };

    onMounted(() => {
      window.addEventListener('keydown', closeMenu);
    });

    watch(locale, () => {
      localStorage.setItem('lang', locale.value);
    });

    return {
      isOpenMenu,
      locale,
      onMenuClick,
      onMenuClose
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.header {
  height: 66px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.08);

  &__wrapper {
    width: 100%;
    max-width: 1600px;
    margin: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  &__left {
    width: 100%;
    max-width: 872px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    width: 100%;
    max-width: 647px;
    margin-left: 100px;
    justify-content: space-between;
  }

  &__btn {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-white;
    box-shadow: 0 0 9px rgba($color-black, 0.11);
    border-radius: 8px;
  }

  &__icon {
    color: $color-purple;
    width: 18px;
    height: 18px;

    &:hover {
      color: $color-purple-dark;
    }
  }

  &__lang {
    margin-left: 15px;

    &:hover {
      color: $color-purple-dark;
    }
  }
}

.header-icons {
  width: 104px;
  margin: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    color: $color-purple;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    height: 24px;

    &:hover {
      color: $color-purple-dark;
    }
  }
}

.header__list-item {
  list-style-type: none;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    color: $color-purple;
  }
}

.header__list__active-item {
  color: $color-purple;
}
</style>
