import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    autofocus: true,
    required: true,
    type: 'text',
    label: 'crm.subscriptions.form.title',
    modelName: 'title',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyTitle',
    isValid: (val) => val,
    style: {
      gridColumn: '1/5'
    },
    display: true
  },
  {
    type: 'number',
    inputmode: 'decimal',
    required: true,
    label: 'crm.subscriptions.form.price',
    modelName: 'passCost',
    modelValue: '',
    emptyError: 'errors.correctAmount',
    noWrap: true,
    isValid: (val) => val,
    style: {
      maxWidth: '230px',
      gridColumn: '1'
    },
    display: true
  },
  {
    type: 'currency',
    disabled: true,
    modelName: 'currency',
    modelValue: '',
    style: {
      maxWidth: '106px',
      gridColumn: '2'
    },
    display: true
  },
  {
    type: 'range',
    label: 'crm.subscriptions.form.numberLessons',
    modelName: 'amountOfLessons',
    modelValue: 10,
    style: {
      gridColumn: '1/4'
    },
    settings: {
      min: 1,
      max: 60,
      marks: [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
      tooltip: 'none'
    },
    display: window.innerWidth > 768
  },
  {
    type: 'number',
    inputmode: 'numeric',
    required: true,
    label: 'crm.subscriptions.form.numberLessons',
    modelName: 'amountOfLessons',
    modelValue: 10,
    emptyError: 'errors.emptyLessonCount',
    validError: 'errors.correctLessonCount',
    isValid: (val) => val && val % 1 === 0 && val >= 1 && val <= 60,
    style: {
      gridColumn: '1/2'
    },
    display: window.innerWidth < 768
  }
];

export default reactive(inputs.filter(item => item.display).map((item) => {
  item.id = uniqueId('subscription-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
