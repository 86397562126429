<template>
  <div class="lp-crm-students-table">
    <LPTable
      v-if="uploadedData"
      :sticky="59"
      :loading="loaderRun"
      :columns="columns"
      :rows="students"
      @edit="$emit('edit', $event)"
      @delete="deleteStudent"
      @click-cell="onClickCell"
    >
      <template #empty>
        <div class="lp-crm-students-table-empty">
          <img
            class="lp-crm-students-table-empty__image"
            :src="imgPlaceholder"
            alt="students"
          >
          <p class="lp-crm-students-table-empty__title">
            {{ $t('crm.students.table.emptyList') }}
          </p>
          <p class="lp-crm-students-table-empty__description">
            {{ $t('crm.students.table.emptyListInfo') }}
          </p>
          <button
            class="lp-button"
            @click.stop="openFormStudent"
          >
            {{ $t('crm.students.table.addStudent') }}
          </button>
        </div>
      </template>
    </LPTable>
    <div
      class="lp-crm-students-table-pagination"
      v-if="pagination.totalPages > 1"
    >
      <Pagination
        v-bind="pagination"
        @change="getStudentsList"
      />
    </div>
  </div>
</template>

<script>
import studentsPlaceholder from '@/assets/images/tablePlaceholder/students.png';
import LPTable from '@/components/Main/Table/LPTable';
import { computed, onBeforeMount, ref } from 'vue';
import studentsColumns from '@/constants/tables/studentsColumns';
import { useStore } from 'vuex';
import StudentApi from '@/api/CRM/students';
import getStudentObject from '@/constants/utils/CRM/getStudentObject';
import SettingsApi from '@/api/CRM/settings';
import Pagination from '@/components/Main/Table/Pagination';

export default {
  name: 'StudentsTable',
  components: {
    Pagination,
    LPTable
  },
  emits: ['update:openForm', 'update:openCard', 'edit'],
  setup (props, { emit }) {
    const imgPlaceholder = studentsPlaceholder;
    const settings = ref(null);

    const columns = computed(() => studentsColumns.map(item => {
      if (item.needCurrency && settings.value) {
        item.postfix = settings.value.currentCurrency;
      }
      return item;
    }));

    const openFormStudent = () => {
      emit('update:openForm', true);
    };

    const openCardStudent = (row) => {
      emit('update:openCard', row);
    };

    const store = useStore();

    const uploadedData = ref(false);
    const loaderRun = computed(() => store.getters.loaderRun);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const pagination = ref({});
    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsTable', data);

    const getSettings = async () => {
      if (settings.value) return;
      try {
        const { data: dataSettings } = await SettingsApi.getSettings();
        settings.value = dataSettings;
      } catch (e) {
        console.error(e);
      }
    };

    const scrollUp = () => {
      const ps = document.querySelector('.lp-crm-main');
      if (!ps) return;
      ps.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    const getStudentsList = async ({ page }) => {
      uploadedData.value = false;
      scrollUp();
      setLoaderRun(true);
      await getSettings();
      try {
        const limit = 25;
        const { data } = await StudentApi.getStudents({ limit, page });
        const { resource, ...paginationInfo } = data;
        pagination.value = paginationInfo;
        const studentsResp = resource.map(getStudentObject);
        setStudents(studentsResp);
        setLoaderRun(false);
        uploadedData.value = true;
      } catch (err) {
        console.error(err);
        setLoaderRun(false);
        uploadedData.value = true;
      }
    };

    const deleteStudent = async ({ _id }) => {
      const index = students.value.findIndex(item => item._id === _id);
      const deletedStudent = index !== -1 ? students.value.splice(index, 1) : null;
      try {
        await StudentApi.deleteStudent(_id);
      } catch (err) {
        console.error(err);
        if (deletedStudent && index !== -1) students.value.splice(index, 0 , ...deletedStudent);
      }
    };

    const onClickCell = ({ row, column }) => {
      if (column.prop === 'fullName') openCardStudent(row);
    };

    onBeforeMount(() => getStudentsList({}));

    return {
      loaderRun,
      students,
      pagination,
      columns,
      openFormStudent,
      imgPlaceholder,
      deleteStudent,
      onClickCell,
      getStudentsList,
      uploadedData
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-students-table {
  height: 100%;

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20vh;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;

    &__image {
      width: 385px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    padding: 8px 0 16px;
    background-color: $color-white;
  }
}

</style>
