import * as types from '@/constants/mutationTypes';

const state = {
  completedLessons: [],
  pendingLessons: [],
  calendarLessons: [],
  studentPayments: []
};

const getters = {
  completedLessons: (state) => state.completedLessons,
  pendingLessons: (state) => state.pendingLessons,
  calendarLessons: (state) => state.calendarLessons,
  studentPayments: (state) => state.studentPayments
};

const actions = {
  setCompletedLessons ({ commit }, data) {
    commit(types.SET_COMPLETED_STUDENT_LESSONS, { data });
  },
  setPendingLessons ({ commit }, data) {
    commit(types.SET_PENDING_STUDENT_LESSONS, { data });
  },
  setCalendarLessons ({ commit }, data) {
    commit(types.SET_CALENDAR_STUDENT_LESSONS, { data });
  },
  setStudentPayments ({ commit }, data) {
    commit(types.SET_STUDENT_PAYMENTS, { data });
  }
};

const mutations = {
  [types.SET_COMPLETED_STUDENT_LESSONS] (state, { data }) {
    state.completedLessons = data;
  },
  [types.SET_PENDING_STUDENT_LESSONS] (state, { data }) {
    state.pendingLessons = data;
  },
  [types.SET_CALENDAR_STUDENT_LESSONS] (state, { data }) {
    state.calendarLessons = data;
  },
  [types.SET_STUDENT_PAYMENTS] (state, { data }) {
    state.studentPayments = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};