export const SET_MODAL_IDS = 'SET_MODAL_IDS';
export const SET_RTC_CONNECT = 'SET_RTC_CONNECT';
export const SET_LOADER_RUN = 'SET_LOADER_RUN';
export const SET_STREAMS = 'SET_STREAMS';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_USER = 'SET_USER';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_AUDIO_SETTINGS = 'SET_AUDIO_SETTINGS';
export const SET_PLAYER = 'SET_PLAYER';
export const SET_CONNECT_USER_ID = 'SET_CONNECT_USER_ID';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_SINGING_STUDENT = 'SET_SINGING_STUDENT';
export const SET_ROOT_FOLDER = 'SET_ROOT_FOLDER';
export const SET_FILES_LIST = 'SET_FILES_LIST';
export const SET_FOLDER_LIST = 'SET_FOLDER_LIST';
export const SET_RTC_SETTINGS = 'SET_RTC_SETTINGS';
export const SET_CLOSED_ROOM = 'SET_CLOSED_ROOM';
export const SET_SHOW_DELAY_SETTING = 'SET_SHOW_DELAY_SETTING';
export const SET_LITE_VERSION = 'SET_LITE_VERSION';
export const SET_STUDENTS = 'SET_STUDENTS';
export const SET_GROUP = 'SET_GROUP';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_LESSONS = 'SET_LESSONS';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_TIMETABLE = 'SET_TIMETABLE';
export const SET_VIDEO_SETTINGS = 'SET_VIDEO_SETTINGS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_MP3_FILE = 'SET_MP3_FILE';
export const SET_AUDIO_CTX = 'SET_AUDIO_CTX';
export const SET_AUDIO_SOURCE = 'SET_AUDIO_SOURCE';
export const SET_LIMIT_ROOM = 'SET_LIMIT_ROOM';
export const SET_CHECKED_LIMIT = 'SET_CHECKED_LIMIT';
export const SET_SHARING_FILE = 'SET_SHARING_FILE';
export const SET_SHARING_FILE_LIST = 'SET_SHARING_FILE_LIST';
export const SET_SCROLL_SHARING_FILE = 'SET_SCROLL_SHARING_FILE';
export const SET_MOBILE_PARTICIPANT = 'SET_MOBILE_PARTICIPANT';
export const SET_COMPLETED_STUDENT_LESSONS = 'SET_COMPLETED_STUDENT_LESSONS';
export const SET_CALENDAR_STUDENT_LESSONS = 'SET_CALENDAR_STUDENT_LESSONS';
export const SET_PENDING_STUDENT_LESSONS = 'SET_PENDING_STUDENT_LESSONS';
export const SET_STUDENT_PAYMENTS = 'SET_STUDENT_PAYMENTS';
