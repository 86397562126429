<template>
  <div>
    <Modal
      :title="'crm.expenses.modal.title'"
      :mobileVersion="isMobile"
      :editMode="true"
      @save="saveForm"
      @remove="closeForm"
      :saveBtnText="'crm.expenses.modal.add'"
      :removeBtnText="'buttons.cancel'"
      :hideButtons="[]"
      v-model:open="openModal"
    >
      <div
        class="lp-expenses-form"
        :class="{'lp-expenses-form_mobile': isMobile}"
      >
        <Form
          @submit="saveForm"
          :inputs="expenseInputs"
        />
        <div
          class="lp-expenses-form__limit"
        >
          {{ $t('crm.expenses.modal.textMaxLength') }}
        </div>
      </div>
      <div
        class="lp-expense-form__placeholder"
        v-if="progressSaving"
      >
        <Loader :block="true" />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { computed, ref, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import inputs from '@/constants/inputs/expensesInputs';
import MobileDetect from 'mobile-detect';
import validateProfile from '@/constants/validatesForm/validateProfile';
import { get, set } from 'lodash';
import SettingsApi from '@/api/CRM/settings';
import ExpensesApi from '@/api/CRM/expenses';
import moment from 'moment';
import Loader from '@/components/Main/Loader/Loader';

export default {
  name: 'ExpensesForm',
  components: { Loader, Modal, Form },
  props: {
    openForm: Boolean,
    expense: Object
  },
  emits: ['update:openForm', 'update', 'clear'],
  setup (props, { emit }) {
    const store = useStore();

    const openModal = ref(false);

    const expenseInputs = computed(() => {
      return inputs;
    });

    const closeForm = () => {
      openModal.value = false;
      setDefaultValues();
    };

    const setDate = (body) => {
      const expenseDate = get(body, ['expenseDate', 'date', 0], '');
      const time = moment().format('HH:mm:ss');
      const date = moment(expenseDate).format('YYYY-MM-DD');
      const dateMoment = moment(`${date}T${time}`, 'YYYY-MM-DDTHH:mm:ss');
      if (dateMoment.isValid()) {
        const date = dateMoment.toISOString();
        set(body, 'expenseDate', date);
      }
    };

    const progressSaving = ref(false);

    const addNewExpenses = async (body) => {
      try {
        await ExpensesApi.createExpenses(body);
        progressSaving.value = false;
        emit('update');
      } catch (err) {
        console.error(err);
      }
    };

    const updateExpenses = async (body) => {
      try {
        await ExpensesApi.updateExpense(body);
        progressSaving.value = false;
        emit('update');
      } catch (err) {
        console.error(err);
      }
    };

    const saveForm = async () => {
      if (progressSaving.value) return;
      progressSaving.value = true;
      const body = await validateProfile(expenseInputs.value);
      if (!body) {
        progressSaving.value = false;
        return;
      }
      setDate(body);

      body._id = get(props, 'expense._id', false);
      body._id ? await updateExpenses(body) : await addNewExpenses(body);

      closeForm();
      progressSaving.value = false;
    };

    watch(() => props.openForm, (value) => {
      openModal.value = value;
    });
    watch(openModal, (value) => {
      emit('update:openForm', value);
      if (!value) {
        emit('clear');
      }
    });

    const setCurrency = (data) => {
      inputs.forEach(input => {
        if (input.modelName === 'currency') input.modelValue = data.currentCurrency;
      });
    };

    const settings = computed(() => store.getters.settings);
    const getSettings = async () => {
      setInputs();
      if (settings.value._id) {
        setCurrency(settings.value);
        return;
      }
      try {
        const { data } = await SettingsApi.getSettings();
        setCurrency(data);
      } catch (e) {
        console.error(e);
      }
    };

    const setDefaultValues = async () => {
      const isEdit = get(props, 'expense._id', false);
      const target = isEdit ? props.expense : {};

      expenseInputs.value.forEach((input) => {
        if (input.modelName === 'currency') return;
        const value = get(target, input.modelName, input.defaultValue);
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
        if (isEdit && input.type === 'date') {
          const date = moment(value || '').format('YYYY-MM-DD');
          input.modelValue = { date: [date], time: [] };
        }
      });
    };

    const setInputs = () => {
      inputs.forEach((input) => {
        input.modelValue = input.defaultValue;
      });
    };

    watch(() => props.expense, () => setDefaultValues());

    onBeforeMount(getSettings);
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      openModal,
      closeForm,
      saveForm,
      expenseInputs,
      progressSaving
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-expenses-form {
  min-width: 450px;
  padding: 28px 20px 5px;

  &_mobile {
    min-width: auto;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 40px 20px;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;

    &__image {
      width: 385px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;

      &_mobile {
        max-width: calc(100vw - 60px);
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }

  &__placeholder {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba($color-white, 0.8);
    z-index: 105;
  }

  &__limit {
    margin-top: 4px;
    font-weight: 500;
    color: $color-gray;
    font-size: 12px;
    line-height: 125%;
    text-align: right;
  }
}

</style>
