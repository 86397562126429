<template>
  <div
    class="lp-calendar-lesson"
    :class="[
      `lp-calendar-lesson_${mode}`,
      {
        'lp-calendar-lesson_past': isComplete,
        'lp-calendar-lesson_student-reason': isFinishedByStudentReason,
        'lp-calendar-lesson_mobile': mobile,
        'lp-calendar-lesson_all-students': showAllStudents === lesson._id
      }
    ]"
    ref="lessonEl"
    @click.stop="openLessonCard"
    v-click-outside="closeInfo"
    @mouseenter="handlerHoverDay(lesson._id)"
    @mouseleave="handlerHoverDay('')"
  >
    <div
      class="lp-calendar-lesson__indicator"
      :class="{
        'lp-calendar-lesson__indicator_past': isComplete,
        'lp-calendar-lesson__indicator_trial': isTrialLesson
      }"
      :style="{backgroundColor: lesson.color}"
    />
    <div
      class="lp-calendar-lesson-header"
      :class="[`lp-calendar-lesson-header_${mode}`]"
      v-if="!mobile"
    >
      <div
        class="lp-calendar-lesson__time"
        :class="[`lp-calendar-lesson__time_${mode}`]"
        :style="{fontSize}"
      >
        <div :class="{'lp-calendar-lesson__time_small': fontSize}">
          {{ startTime }} - {{ endTime }}
          <span :class="{'lp-calendar-lesson__time_completed': fontSize}">
            {{
              isComplete && !isFinishedByStudentReason
                ? `(${$t('crm.lessons.completed')})`
                : ''
            }}
          </span>
        </div>
        <LPTooltip
          class="lp-calendar-lesson__trial-icon"
          :text="$t('crm.lessons.form.trial')"
          v-if="isTrialLesson && mode === 'month'"
        >
          <TrialIconGray v-if="isComplete" />
          <TrialIcon v-else />
        </LPTooltip>
        <FinishedByStudentReason
          v-if="isFinishedByStudentReason"
          class="lp-calendar-lesson__student-reason"
          :class="[`lp-calendar-lesson__student-reason_${mode}`]"
          :style="{width: fontSize, height: fontSize}"
        />
        <LessonNotComplete
          v-if="!isComplete && isPast"
          class="lp-calendar-lesson__not-complete"
          :class="[`lp-calendar-lesson__not-complete_${mode}`]"
          :style="{width: fontSize, height: fontSize}"
        />
      </div>
      <div
        class="lp-calendar-lesson__approve"
        :class="[`lp-calendar-lesson__approve_${mode}`]"
        @click.stop
      >
        <ConfirmLesson
          :lesson="lesson"
          @get-lessons="$emit('get-lessons')"
        />
      </div>
    </div>
    <div
      class="lp-calendar-lesson-price-subscribe"
      :class="[`lp-calendar-lesson-price-subscribe_${mode}`]"
      v-if="subscription && mode !== 'month' && !mobile"
    >
      <div class="lp-calendar-lesson-price-subscribe__icon">
        <LPTooltip :text="$t('crm.subscriptions.tooltip')">
          <subscriptionsIcon :style="{width: fontSize, height: fontSize}" />
        </LPTooltip>
      </div>
      <div
        v-if="mode === 'day'"
        class="lp-calendar-lesson-price-subscribe-list"
        :class="{
          'lp-calendar-lesson-price-subscribe-list_sm': lessonDuration <= 20
        }"
      >
        <div
          class="lp-calendar-lesson-price-subscribe-list__item"
          :class="[`lp-calendar-lesson-price-subscribe-list__item_${mode}`]"
          :style="{width: fontSize, height: fontSize}"
          v-for="item in subscriptionDay"
          :key="item"
        >
          <checkIcon v-if="item <= subscription.finishedLessonsCount" />
          <div
            v-if="subscription.amountOfLessons > 10"
            class="lp-calendar-lesson-price-subscribe-list__modal"
          >
            <div class="lp-calendar-lesson-price-subscribe-list">
              <div
                class="lp-calendar-lesson-price-subscribe-list__item"
                :class="[
                  `lp-calendar-lesson-price-subscribe-list__item_${mode}`
                ]"
                v-for="item in subscription.amountOfLessons"
                :key="item"
              >
                <checkIcon v-if="item <= subscription.finishedLessonsCount" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isTrialLesson && !mobile"
      class="lp-calendar-lesson-price"
      :class="[
        `lp-calendar-lesson-price_${mode}`,
        {'lp-calendar-lesson-price_past': isComplete}
      ]"
    >
      <span
        class="lp-calendar-lesson-price__text"
        :class="[`lp-calendar-lesson-price__text_${mode}`]"
        :style="{fontSize}"
      >
        {{
          isGroupLesson
            ? $t('crm.lessons.costForEachStudent')
            : $t('calendar.lesson.cost')
        }}:
      </span>
      <span
        :style="{fontSize}"
        :class="{
          'lp-calendar-lesson-price-trial': isTrialLesson,
          'lp-calendar-lesson-price-trial_xs':
            lessonDuration === 15 && mode === 'day' && isTrialLesson
        }"
      >
        <LPTooltip
          :text="$t('crm.lessons.form.trial')"
          v-if="isTrialLesson"
        >
          <TrialIconGray v-if="isComplete" />
          <TrialIcon v-else />
        </LPTooltip>
        <template v-else>
          {{ price }}
        </template>
      </span>
    </div>
    <div
      class="lp-calendar-lesson-wrapper"
      :class="{
        'lp-calendar-lesson-wrapper_sm': lessonDuration <= 20 && mode === 'day'
      }"
    >
      <div
        v-for="student in studentsListLimited"
        :key="student.crmStudentId"
      >
        <div
          class="lp-calendar-lesson-user"
          :class="{
            'lp-calendar-lesson-user_sm':
              mobile || (lessonDuration <= 20 && mode === 'day'),
            'lp-calendar-lesson-user_md': isGroupLesson
          }"
          @click="
            () => (student.crmStudentDeleted ? false : goToCardStudent(student))
          "
          @mouseleave.stop="showTooltip = null"
          @mouseover="hoverStudentName(student.crmStudentFullName)"
          v-if="student._id && mode !== 'month'"
        >
          <img
            v-if="student.uploadPhotoUrl"
            alt="photo"
            class="lp-calendar-lesson-user__avatar"
            :style="{width: fontSize, height: fontSize}"
            :src="student.uploadPhotoUrl"
          />
          <userIcon
            v-else
            class="lp-calendar-lesson-user__avatar"
            :style="{width: fontSize, height: fontSize}"
          />
          <div
            v-if="!isGroupLesson"
            class="lp-calendar-lesson-user-name"
            :class="{
              'lp-calendar-lesson-user-name_mobile': mobile,
              'lp-calendar-lesson-user-name_mt':
                studentName.length > limitedSymbols &&
                !isMobile &&
                mode === 'week'
            }"
          >
            {{
              studentName.length > limitedSymbols
                ? studentName.slice(0, limitedSymbols) + '...'
                : studentName
            }}
          </div>
          <Tooltip
            v-if="isGroupLesson"
            :open="showTooltip === student.crmStudentFullName"
            :text="student.crmStudentFullName"
            :topPosition="true"
            :leftPosition="true"
            :onMobile="true"
            :customWidth="student.crmStudentFullName.length > limitedSymbols"
            class="lp-calendar-lesson-user-name__tooltip"
          />
        </div>
      </div>
      <div
        v-if="studentsList.length > limitedStudents && mode !== 'month'"
        class="lp-calendar-lesson-user_last"
        :class="{
          'lp-calendar-lesson-user_last_sm':
            lessonDuration <= 35 && mode === 'day',
          'lp-calendar-lesson-user_last_mobile': isMobile
        }"
        @click.stop="showAllStudents = lesson._id"
      >
        +{{ studentsList.length - limitedStudents }}
        <AllStudents
          v-if="showAllStudents === lesson._id && !openInfo"
          :item="lesson"
          :isMobile="mobile"
          :showAllStudents="showAllStudents"
          :style="mobile || mode === 'day' ? {} : getStyleInfo().style"
          :class="{'lp-calendar-all-students_mobile': mobile}"
        />
      </div>
    </div>
    <div
      class="lp-calendar-lesson-link"
      :class="[`lp-calendar-lesson-link_${mode}`]"
      v-if="!mobile"
    >
      <LinkLessonCard
        :lesson="lesson"
        :hideLabel="true"
        :fontSize="fontSize"
      />
    </div>
    <div
      class="lp-calendar-lesson-info"
      :class="[`lp-calendar-lesson-info_${mode}`]"
      v-if="!mobile"
    >
      <div
        class="lp-calendar-lesson-info-comment"
        :class="{'lp-calendar-lesson-info-comment_disabled': !lesson.comment}"
        :style="{fontSize}"
      >
        <commentIcon :style="{width: fontSize, height: fontSize}" />
        <div
          class="lp-calendar-lesson-info-comment__popup"
          :class="{
            'lp-calendar-lesson-info-comment__popup_short':
              lesson.comment && lesson.comment.length <= 20
          }"
          :style="{fontSize}"
        >
          {{ lesson.comment }}
        </div>
      </div>
      <LPTooltip :text="$t('crm.lessons.tooltips.repeat')">
        <div class="lp-calendar-lesson-info__repeat">
          <repeatableIcon
            v-if="lesson.repeatable"
            :style="{width: fontSize, height: fontSize}"
          />
        </div>
      </LPTooltip>
    </div>
    <div
      class="lp-calendar-lesson-toolbar"
      :class="[`lp-calendar-lesson-toolbar_${mode}`]"
    >
      <div
        v-if="fontSize"
        :style="{fontSize}"
      >
        <Toolbar
          :calendarDay="true"
          :row="lesson"
          :isZoomed="isZoomed"
          :column="{deleteName: 'crmStudentFullName'}"
          :hover="dayHoverId"
          :question="'calendar.lesson.deleteLesson'"
          :style="'padding: 0'"
          :moreStyle="{width: fontSize, height: fontSize}"
          :arrayButtons="arrayButtons"
          :customClass="'lp-toolbar-options_min-width'"
          @delete="deletedLesson"
          @edit="onEdit"
          @failed="onFailed"
          @confirm="acceptConfirm"
        />
      </div>
      <ToolbarLessonCard
        v-else
        :lesson="lesson"
        :mobile="mobile"
        :hover="dayHoverId"
        @get-lessons="$emit('get-lessons')"
      />
    </div>
    <transition name="bounce">
      <teleport
        to="#app"
        :disabled="!mobile"
      >
        <CalendarLessonCard
          :class="[
            {'lp-calendar-lesson__info': !mobile},
            'lp-calendar-lesson__info_' +
              (mobile ? '' : getStyleInfo().className)
          ]"
          :style="mobile ? {} : getStyleInfo().style"
          v-model:open="openInfo"
          v-model:form="openForm"
          @delete="deletedLesson"
          :mobile="mobile"
          :lesson="lesson"
          v-if="openInfo"
          @get-lessons="$emit('get-lessons')"
        />
      </teleport>
    </transition>
    <transition name="bounce">
      <teleport to="#app">
        <LessonForm
          :openForm="openForm"
          :date="lessonDate"
          :lesson="lesson"
          v-model:openForm="openForm"
        />
      </teleport>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { get } from 'lodash';
import { userIcon } from '@/constants/icons';
import CalendarLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/CalendarLessonCard';
import ConfirmLesson from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard/ConfirmLesson';
import LinkLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/LinkLessonCard';
import ToolbarLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard';

import {
  repeatableIcon,
  commentIcon,
  subscriptionsIcon,
  checkIcon,
  failedIcon,
  completeMarkIcon,
  pencilToolbarIcon,
  trashToolbarIcon,
  TrialIcon,
  TrialIconGray
} from '@/constants/icons/crm';
import FORMATS from '@/constants/formats';
import numeral from 'numeral';
import LessonNotComplete from '@/components/Main/LessonNotComplete/LessonNotComplete';
import FinishedByStudentReason from '@/components/Main/FinishedByStudentReason/FinishedByStudentReason';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import LessonForm from '@/components/CRM/Lessons/Calendar/CalendarLessonForm/LessonForm';
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import LessonsApi from '@/api/CRM/lessons';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import MobileDetect from 'mobile-detect';
import AllStudents from '@/components/CRM/Lessons/AllStudents';
import { useRouter } from 'vue-router';

export default {
  name: 'CalendarLesson',
  components: {
    LessonForm,
    LPTooltip,
    LessonNotComplete,
    ToolbarLessonCard,
    LinkLessonCard,
    ConfirmLesson,
    CalendarLessonCard,
    FinishedByStudentReason,
    Toolbar,
    userIcon,
    repeatableIcon,
    commentIcon,
    subscriptionsIcon,
    checkIcon,
    Tooltip,
    TrialIcon,
    TrialIconGray,
    AllStudents
  },
  props: {
    lesson: Object,
    mode: String,
    height: Number,
    mobile: Boolean,
    fontSize: String,
    isZoomed: Boolean
  },
  emits: ['update:open', 'get-lessons'],
  setup (props, { emit }) {
    const store = useStore();
    const lessons = computed(() => store.getters.lessons);
    const setLessons = (data) => store.dispatch('setLessons', data);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const showTooltip = ref(null);
    const hoverStudentName = (item) => {
      showTooltip.value = item;
    };

    const startTime = computed(() =>
      moment(props.lesson.crmLessonStart).format('HH:mm')
    );
    const endTime = computed(() =>
      moment(props.lesson.crmLessonEnd).format('HH:mm')
    );
    const price = computed(() => {
      const value = numeral(props.lesson.crmLessonCost).format(FORMATS.FINANCE);
      return `${value} ${props.lesson.currency}`;
    });
    const isTrialLesson = computed(() => {
      const { isTrial } = props.lesson;
      return isTrial;
    });
    const isComplete = computed(
      () => get(props.lesson, 'crmLessonStatus', '') === 'Complete'
    );
    const isFinishedByStudentReason = computed(() =>
      get(props.lesson, 'finishedByStudentReason', false)
    );

    const showAllStudents = ref(null);

    const studentsList = computed(() => {
      return Object.values(props.lesson.crmLessons);
    });

    const limitedSymbols = computed(() => {
      return isMobile.value ? 16 : 23;
    });

    const limitedStudents = computed(() => {
      return isMobile.value ? 5 : 7;
    });

    const studentsListLimited = computed(() => {
      const lessonsLimited = Object.values(studentsList.value).slice(
        0,
        limitedStudents.value
      );
      return Object.values(studentsList.value).length > limitedStudents.value
        ? lessonsLimited
        : Object.values(studentsList.value);
    });

    const isPast = computed(() =>
      moment().isAfter(moment(props.lesson.crmLessonStart))
    );

    const isGroupLesson = computed(() => {
      return props.lesson.crmLessons.length > 1;
    });

    const studentName = computed(() => {
      if (!isGroupLesson.value)
        return props.lesson.crmLessons[0].crmStudentFullName;
      else return null;
    });

    const openForm = ref(false);

    const router = useRouter();

    const goToCardStudent = async (student) => {
      router.push({
        name: 'CRMStudents',
        query: { crmStudentId: student.crmStudentId }
      });
    };

    const openLessonCard = () => {
      openInfo.value = openInfo.value
        ? false
        : props.mode === 'month' || props.mode === 'week' || props.mobile;
      showAllStudents.value = null;
    };

    const openInfo = ref(false);
    const closeInfo = (e) => {
      showAllStudents.value = null;
      if (!openInfo.value || props.mobile) return;
      const path = get(e, 'path', []);
      const pathLength = get(path, 'length', 0);
      if (!pathLength) return;
      const classes = path.map((el) => (el ? el.className : ''));
      const isModal =
        classes.find(
          (item) => typeof item === 'string' && item.includes('lp-modal')
        ) ||
        classes.find(
          (item) =>
            typeof item === 'string' && item.includes('lp-modal-question')
        );
      if (isModal) return;
      openInfo.value = false;
    };
    watch(openInfo, (val) => {
      emit('update:open', val);
    });

    const finishedByStudentReason = ref(false);

    const arrayButtons = ref([
      {
        name: 'crm.lessons.confirmLessonDropDown.confirm',
        icon: completeMarkIcon,
        type: 'confirm',
        twoSteps: true,
        question: 'calendar.lesson.confirmLessonByStudentReason'
      },
      {
        name: 'crm.lessons.confirmLessonDropDown.studentReason',
        icon: failedIcon,
        type: 'failed',
        twoSteps: true,
        question: 'calendar.lesson.confirmLesson'
      },
      {
        name: 'crm.students.table.toolbar.edit',
        icon: pencilToolbarIcon,
        type: 'edit'
      },
      {
        name: 'crm.students.table.toolbar.delete',
        icon: trashToolbarIcon,
        type: 'delete',
        twoSteps: true
      }
    ]);

    const deletedLesson = async (lesson) => {
      emit('update:open', false);
      if (lesson) {
        const index = lessons.value.findIndex(
          (item) => item._id === lesson._id
        );
        const deletedLesson =
          index !== -1 ? lessons.value.splice(index, 1) : null;
        try {
          await LessonsApi.deleteGroupLesson(lesson._id);
        } catch (err) {
          if (deletedLesson && index !== -1)
            lessons.value.splice(index, 0, ...deletedLesson);
          console.error(err);
        }
        emit('update:open', false);
        openInfo.value = false;
        return;
      }
      emit('update:open', false);
      openInfo.value = false;
      // emit('update:open', false);
    };

    const updateStatusLesson = (complete, finishedByStudent) => {
      if (complete) {
        arrayButtons.value = arrayButtons.value.filter(
          (el) => el.type !== 'failed' && el.type !== 'confirm'
        );
      }

      if (finishedByStudent) {
        arrayButtons.value = arrayButtons.value.filter(
          (el) => el.type === 'edit'
        );
      }
    };

    const acceptConfirm = async () => {
      const lesson = {
        ...props.lesson,
        finishedByStudentReason: finishedByStudentReason.value
      };
      lesson.crmLessonStatus = 'Complete';
      try {
        const { data } = await LessonsApi.updateGroupLesson(lesson);
        const newArray = lessons.value.map((el) => {
          if (el._id === data._id) {
            el = data;
            const { crmLessonStatus, finishedByStudentReason } = data;
            updateStatusLesson(
              crmLessonStatus === 'Complete',
              finishedByStudentReason
            );
          }
          return el;
        });
        await setLessons(newArray);
        yandexTracker.endLesson();
      } catch (err) {
        console.error(err);
      }
    };

    const onFailed = () => {
      finishedByStudentReason.value = true;
      acceptConfirm();
    };

    const onEdit = () => {
      openForm.value = !openForm.value;
    };

    const lessonEl = ref(null);
    const getStyleInfo = () => {
      if (!lessonEl.value) return {};
      const comment = get(props.lesson, 'comment', '');
      const subscribe = get(props.lesson, 'crmLessonPassId.amountOfLessons', 0);
      let infoSizeHeight = 349;
      if (comment) infoSizeHeight += 113;
      if (subscribe) infoSizeHeight += (subscribe / 10) * 25;
      const height = subscription.value ? infoSizeHeight + 165 : infoSizeHeight;
      const infoSize = { width: 327, height };
      const documentWidth = window.innerWidth;
      const documentHeight = window.innerHeight;
      const position = lessonEl.value.getBoundingClientRect();
      const marginLeft = 9;
      const marginTop = 0;
      const top =
        marginTop + position.top <= 400 ? marginTop + position.top : 400;
      const left = position.left + position.width + marginLeft;
      let className = '';
      const style = {
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`,
        zIndex: 2147483649 //taller than jiro
      };
      if (left + infoSize.width > documentWidth) {
        className = 'right';
        style.left = `${position.left - marginLeft - infoSize.width}px`;
      }

      if (top + infoSize.height + 50 > documentHeight) {
        className += 'bot';
        const positionDelta = documentHeight - top - infoSize.height - 50;
        style.top = `${top + positionDelta}px`;
      }
      return {
        style,
        className
      };
    };

    const subscription = ref(null);

    const subscriptionDay = computed(() => {
      if (!subscription.value) return;
      return subscription.value.amountOfLessons > 10
        ? 10
        : subscription.value.amountOfLessons;
    });

    const lessonDate = computed(() => get(props.lesson, 'crmLessonStart', ''));

    const dayHoverId = ref('');

    const handlerHoverDay = (id) => {
      dayHoverId.value = id;
    };

    const lessonDuration = computed(() => {
      const lessonStart = props.lesson.crmLessonStart
        ? moment(props.lesson.crmLessonStart).valueOf()
        : null;
      const lessonEnd = props.lesson.crmLessonEnd
        ? moment(props.lesson.crmLessonEnd).valueOf()
        : null;
      const timeDiff = lessonEnd - lessonStart;
      const diffInMinutes = moment.duration(timeDiff)._milliseconds / 60000;
      return diffInMinutes;
    });

    const styleName = computed(() => {
      return {
        fontSize: props.fontSize,
        webkitLineClamp: props.height > 35 ? (props.height > 70 ? 3 : 2) : 1,
        maxWidth: props.mobile
          ? `calc(100vw - ${props.height > 35 ? 240 : 140}px)`
          : null
      };
    });
    onBeforeMount(async () => {
      updateStatusLesson(isComplete.value, isFinishedByStudentReason.value);
      const { crmLessons = [] } = props.lesson;
      if (crmLessons.length === 1) {
        const [currentLesson] = crmLessons;
        const { crmLessonPassId } = currentLesson;
        subscription.value = crmLessonPassId;
      }
    });

    watch(
      () => props.lesson,
      async () => {
        const { crmLessons = [] } = props.lesson;
        if (crmLessons.length === 1) {
          const [currentLesson] = crmLessons;
          const { crmLessonPassId } = currentLesson;
          subscription.value = crmLessonPassId;
        } else {
          subscription.value = null;
          // const studentsWithoutPass = crmLessons.find(item => !item.crmLessonPassId);
          // console.log('studentsWithoutPass', studentsWithoutPass);
          // subscription.value = !studentsWithoutPass;
        }
      }
    );

    return {
      styleName,
      startTime,
      endTime,
      price,
      lessonEl,
      openInfo,
      openForm,
      isComplete,
      isPast,
      isFinishedByStudentReason,
      closeInfo,
      getStyleInfo,
      deletedLesson,
      acceptConfirm,
      onFailed,
      subscription,
      lessonDate,
      arrayButtons,
      subscriptionDay,
      onEdit,
      dayHoverId,
      handlerHoverDay,
      isTrialLesson,
      showTooltip,
      hoverStudentName,
      studentsList,
      isGroupLesson,
      studentName,
      lessonDuration,
      studentsListLimited,
      isMobile,
      limitedSymbols,
      limitedStudents,
      showAllStudents,
      openLessonCard,
      goToCardStudent
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-lesson {
  @include global-font;
  display: grid !important;
  position: relative;
  padding: 3px 16px 3px 14px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 2px;
  color: $color-text;
  text-align: left;
  transition: background-color 0.3s ease-in;
  cursor: pointer;

  &:hover:not(&_past) &__indicator {
    background-color: rgba($color-accent, 0.8);
  }

  &:hover:not(&_past) &__indicator_trial {
    background-color: $color-selective-yellow;
  }

  &_past {
    color: $color-gray;
  }

  &_week {
    display: flex;
    flex-direction: column;
    padding: 10px 16px 10px 20px;
  }

  &_day {
    padding: 0 16px 0 20px;
    align-items: center;
    grid-template-columns:
      minmax(170px, 170px)
      minmax(280px, 400px)
      minmax(260px, 2fr)
      minmax(270px, 386px)
      minmax(48px, 120px)
      minmax(104px, 1fr);
    grid-gap: 12px;
    cursor: default;
    top: -1px;
    z-index: 1;
  }

  &_mobile {
    grid-template-columns: 1fr auto;
    padding: 0 8px 0;
  }

  &_all-students {
    z-index: 10;
  }

  &__info {
    position: absolute;
    left: calc(100% + 9px);
    padding-bottom: 40px;
    top: 0;
    z-index: 3;

    &_right {
      &:before {
        border-right: 6px solid $color-white;
        border-left: 6px solid $color-white;
        left: auto;
        right: -6px;
      }
    }

    &_bot {
      &:before {
        top: auto;
        bottom: 8px;
      }
    }
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 2px 0 0 2px;
    background-color: $color-accent;
    transition: background-color 0.3s;

    &_trial {
      background-color: $color-supernova;
    }

    &_past {
      background-color: $color-gray !important;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_week {
      margin-bottom: 14px;
    }
  }

  &__approve {
    display: none;

    &_week {
      display: block;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;

    &_small {
      display: flex;
    }

    &_completed {
      margin: 0 0 0 5px;
    }

    &_day {
      line-height: 1;
    }

    &_week {
      font-size: 16px;
    }

    &_month,
    &_week {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__trial-icon {
    display: flex;
    align-items: center;
    align-self: center;
    margin-left: 6px;
    margin-right: 3px;
  }

  &__not-complete,
  &__student-reason {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-left: auto;
    margin-right: 0;

    &_week {
      margin-left: 10px;
      margin-right: auto;
    }

    &_day {
      margin-left: 10px;
      margin-right: auto;
    }
  }

  &__student-reason {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;

    &_day {
      height: 100%;
      min-height: 100%;
    }
  }

  &-price {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-sine-shaft;
    display: none;

    &_past {
      color: $color-gray;
    }

    &_week {
      margin-bottom: 10px;
      display: block;
    }

    &_day {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;
    }

    &__text {
      display: none;
      font-weight: normal;
      font-size: 14px;
      line-height: 125%;
      align-items: center;
      color: $color-text;

      &_day {
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
      }
    }
  }

  &-link {
    display: none;

    &_day {
      display: block;
    }
  }

  &-info {
    display: none;
    grid-template-columns: 18px 18px;
    grid-gap: 12px;
    align-items: center;

    &_day {
      display: grid;
    }

    &-comment {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &_disabled {
        filter: invert(77%) sepia(12%) saturate(0%) hue-rotate(180deg)
          brightness(106%) contrast(72%);
        pointer-events: none;
      }

      &:hover:not(&_disabled) &__popup {
        transform: scale(1);
      }

      &__popup {
        position: absolute;
        top: 25px;
        right: 0;
        padding: 16px 18px;
        background: $color-white;
        box-shadow: 0 0 17px rgba($color-black, 0.08);
        border-radius: 4px;
        max-height: 150px;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
        color: $color-text;
        z-index: 10;
        overflow: auto;
        transform: scale(0);
        transition: max-content 0.2s ease-in;
        min-width: 250px;

        &_short {
          min-width: max-content;
        }
      }
    }

    &__repeat {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-toolbar {
    display: none;

    &_day {
      display: block;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    padding-left: 17px;

    &_sm {
      max-height: 15px;
    }
  }

  &-user {
    $this: &;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: -15px;
    background-color: $color-white;
    border: 2px solid $color-white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &_md {
      svg,
      img {
        width: 24px;
        height: 24px;
      }
    }

    &_sm {
      font-size: 12px;
      border-top: 0;
      border-bottom: 0;
      margin-left: -12px;

      svg,
      img {
        width: 24px;
        height: 24px;
      }
    }

    &-name {
      margin-left: 6px;
      word-break: break-word;

      &_mobile {
        font-size: 12px;
      }

      &_mt {
        margin-top: 7px;
      }

      &__tooltip {
        bottom: 40px;
      }
    }

    &_last {
      width: 26px;
      height: 26px;
      margin-left: -14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid $color-white;
      font-size: 10px;
      font-weight: 700;
      background-color: $color-purple;
      color: $color-white;
      z-index: 1;
      cursor: pointer;
      position: relative;

      &_sm {
        width: 15px;
        height: 15px;
        margin-left: -12px;
        font-size: 8px;
      }

      &_mobile {
        position: initial;
      }
    }

    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      word-wrap: break-word;
      width: calc(100% - 40px);
    }

    &_mobile {
      flex-wrap: nowrap;
      margin-bottom: 0;

      #{$this}__avatar {
        width: 32px;
        height: 32px;
      }
    }
  }

  &-font-size-small {
    font-size: 70%;
    line-height: 100%;
  }
}

.lp-calendar-lesson-price-subscribe {
  display: grid;
  grid-template-columns: 23px auto;
  grid-gap: 15px;
  padding: 5px 0;

  &_day {
    padding: 0;
    grid-gap: 3px;
  }

  &_week {
    padding: 0;
    margin-top: -7px;
    margin-bottom: 15px;
  }

  &__icon {
    display: flex;
    align-items: center;
  }
}

.lp-calendar-lesson-price-subscribe-list {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(10, 25px);
  position: relative;

  &:hover .lp-calendar-lesson-price-subscribe-list__modal {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
    transition: opacity 0.3s ease-in;
  }

  &__modal {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: $color-white;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.015);
  }

  &__item {
    width: 25px;
    height: 25px;
    border: 1px solid $color-alto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_day {
      border-radius: 0;
    }
  }

  &_sm {
    grid-template-columns: repeat(10, 14px);
  }
}

.lp-calendar-lesson-price-trial {
  position: relative;
  display: inline-flex;

  &_xs {
    svg {
      height: 11px;
    }
  }
}
</style>

<style lang="scss">
.lp-calendar-lesson-price-subscribe-list {
  svg {
    width: 12px;
    height: 12px;
  }
}

.lp-calendar-lesson-font-size-small {
  svg {
    width: 12px;
    height: 12px;
  }
}
</style>
