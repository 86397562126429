<template>
  <div class="lp-widget-calendar">
    <div
      class="lp-widget-calendar-header"
      :class="{
        'lp-widget-calendar-header_past': isPastDate,
        'lp-widget-calendar-header_mobile': isMobile
      }"
    >
      {{ formatDate }}
    </div>
    <div class="lp-widget-edit-content">
      <div
        class="lp-widget-edit-body-hours"
        :class="{'lp-widget-edit-body-hours_mobile': isMobile}"
      >
        <div
          v-for="(hour, index) in hoursTable"
          :key="hour"
          class="lp-widget-edit-body-hours__item"
        >
          {{ calcHours(hour, index) }}
        </div>
      </div>
      <div>
        <div
          class="lp-widget-edit-body-layout"
          :class="{'lp-widget-edit-body-layout_mobile': isMobile}"
        >
          <div
            v-for="(item, i) in setTime"
            :key="item.fullDate"
            :id="item.id"
            @click="onOpenForm($event, i, item)"
            @mouseover="onTimeHover($event, i, item)"
            @mouseout="onTimeMouseOut($event, i, item)"
            class="lp-widget-edit-body-times-item"
            :class="{
              'lp-widget-edit-body-times-item__schedule': item.isTimeFree && !item.hasLesson,
              'lp-widget-edit-body-times-item__schedule_mobile': isMobile && item.isTimeFree && !item.hasLesson,
              'lp-widget-edit-body-times-item__schedule-busy': item.isTimeFree && item.hasLesson,
              'lp-widget-edit-body-times-item__schedule-booked': item.hasLesson && item.showToStudent &&
                hidePassedLesson(item),
              'lp-widget-edit-body-times-item__schedule-booked_first': item.hasLesson && item.showToStudent &&
                lessonsStartTime.includes(item.time) && hidePassedLesson(item),
              'lp-widget-edit-body-times-item__schedule-booked_last': item.hasLesson && item.showToStudent &&
                item.lessonEndFiveMinutes === item.time && hidePassedLesson(item),
              'thin-border': +lessonDuration < 20 && item.isTimeFree,
              'lp-widget-edit-body-times-item__schedule_hover': showHoveredTime(i, item) && currentTimeIndex &&
                item.isTimeFree && !item.hasLesson && !isMobile,
              'lp-widget-edit-body-times-item__schedule_hover_first': +currentTimeIndex - upperItems === +i &&
                item.isTimeFree && !item.hasLesson && currentTimeIndex && !isMobile,
              'lp-widget-edit-body-times-item__schedule_hover_last': +currentTimeIndex + lowerItems - 1 === +i &&
                item.isTimeFree && !item.hasLesson && currentTimeIndex && !isMobile
            }"
          >
            <div
              class="lp-widget-edit-body-book"
              :class="{'lp-widget-edit-body-book_show': +currentTimeIndex + 1 === +i &&
                item.isTimeFree && !item.hasLesson}"
            >
              <div
                v-if="!isMobile && currentTimeIndex"
                class="lp-widget-edit-body-book__sign"
                :style="{transform: `translateY(${textTopOffset}px)`}"
              >
                <plusIcon class="lp-widget-edit-body-book__icon" />
                <div>
                  {{ $t('timetable.canSingUp') }}
                </div>
              </div>
            </div>
            <div
              v-if="item.isTimeFree && !item.hasLesson && !isMobile"
              class="lp-widget-edit-body-lesson"
              :class="{
                'lp-widget-edit-body-lesson_show': +i === +chosenTimeIndex && +lessonDuration % 10 === 0 &&
                  chosenTimeIndex,
                'lp-widget-edit-body-lesson_show-odd': +i === +chosenTimeIndex && +lessonDuration % 10 !== 0 &&
                  chosenTimeIndex
              }"
            >
              <div class="lp-widget-edit-body-book__icon">
                <profileIcon />
              </div>
              {{ currentStartTime }} - {{ currentEndTime }}
            </div>
            <div
              v-if="item.hasLesson && item.showToStudent && lessonsStartTime.includes(item.time) &&
                hidePassedLesson(item)"
              class="lp-widget-edit-body-book__text"
              :class="{'lp-widget-edit-body-book__text_mobile': isMobile}"
              :style="{transform: `translateY(${item.duration / 3 + textTopOffsetBooked(item.duration)}px)`}"
            >
              <profileIcon />
              {{ $t('timetable.signedUp.youLesson') }}.
              {{ checkSmallDuration(item.duration) && isMobile ? '' : $t('timetable.signedUp.description') }}
              {{ checkSmallDuration(item.duration) && isMobile ? '' : dayMonth(item.date) }}
              {{ checkSmallDuration(item.duration) && isMobile ? '' : $t('global.in') }}
              {{ checkSmallDuration(item.duration) && isMobile ? '' : item.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <WidgetSignUp
      class="lp-widget-edit-sign"
      :teacher="teacher"
      :item="currentItem"
      :setTime="setTime"
      :startTime="currentStartItem"
      :endTime="currentEndItem"
      :isOpenModal="isOpenModal"
      :noTitle="true"
      :lessonDuration="lessonDuration"
      :openError="openError"
      @sent="bookTime"
      @signed="chosenTimeIndex = null"
      @close="closeModal"
      @time-changed="onStartTimeChanged"
    />
  </div>
</template>

<script>
import { computed, ref, onBeforeMount, reactive } from 'vue';
import moment from 'moment';
import { fill, get } from 'lodash';
import MobileDetect from 'mobile-detect';
import SettingsApi from '@/api/CRM/settings';
import LessonsApi from '@/api/CRM/lessons';
import WidgetSignUp from '@/components/CRM/Lessons/TimetableWidget/WidgetSignUp';
import { uniqueId } from 'lodash';
import { plusIcon, profileIcon } from '@/constants/icons/crm';
import { useRouter } from 'vue-router';

export default {
  name: 'WidgetCalendar',
  components: {
    plusIcon,
    profileIcon,
    WidgetSignUp
  },
  props: {
    teacher: Object,
    rules: Object,
    date: String
  },
  setup (props) {
    const isOpenModal = ref(false);
    const onOpenForm = (event, i, item) => {
      const checkAvailableTime = event.target.classList.contains('lp-widget-edit-body-times-item__schedule_hover');
      if (!checkAvailableTime && !isMobile.value) return;

      const checkStartTime = moment(item.startTime, 'HH:mm').hours() >= 5;
      const checkEndTime = moment(item.endTime, 'HH:mm').hours() >= 5;
      if (!checkStartTime || !checkEndTime) {
        chosenTimeIndex.value = null;
        isOpenModal.value = true;
        openError.value = true;
        return;
      }

      lessonDuration.value % 10 === 0 ? chosenTimeIndex.value = i : chosenTimeIndex.value = +i + 1;

      if (item.isTimeFree && !item.hasLesson) {
        let prevSibling = event.target;
        let nextSibling = event.target;
        for (let j = 0; j < upperItems.value; j++) {
          if (prevSibling) prevSibling = prevSibling.previousSibling;
        }
        for (let l = 0; l < lowerItems.value; l++) {
          if (nextSibling) nextSibling = nextSibling.nextSibling;
        }

        currentItem.value = item;
        currentStartItem.value = prevSibling.classList.contains('lp-widget-edit-body-times-item__schedule');
        currentEndItem.value = nextSibling.classList.contains('lp-widget-edit-body-times-item__schedule');
        currentStartTime.value = item.startTime;
        currentEndTime.value = moment(item.endTime, 'HH:mm').hours() === 0 ? '23.55' : item.endTime;
        isOpenModal.value = !isOpenModal.value;
      }
    };
    const closeModal = () => {
      chosenTimeIndex.value = null;
      openError.value = false;
      isOpenModal.value = false;
      currentTimeIndex.value = null;
    };
    const openError = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const checkSmallDuration = (duration) => {
      return duration < 30;
    };

    const currentStudentId = ref('');

    const currentTimeIndex = ref(null);
    const showHoveredTime = (i, item) => {
      let isLessonTimeUpper = +i === +currentTimeIndex.value;
      let isLessonTimeLower = +i === +currentTimeIndex.value;

      if (lessonDuration.value % 10 === 0) {
        let itemLimits = (+i === +currentTimeIndex.value + +upperItems.value - 1 ||
            +i === +currentTimeIndex.value - +lowerItems.value) && !isMobile.value;
        if ((itemLimits && !item.isTimeFree) || (itemLimits && item.hasLesson)) {
          currentTimeIndex.value = null;
        }

        for (let j = 0; j < upperItems.value; j++) {
          isLessonTimeUpper = isLessonTimeUpper || +i === +currentTimeIndex.value + j && i !== 0;
        }
        for (let l = 0; l <= lowerItems.value; l++) {
          isLessonTimeLower = isLessonTimeLower || +i === +currentTimeIndex.value - l && i !== 0;
        }
      } else {
        let itemLimits = (+i === +currentTimeIndex.value + +upperItems.value ||
            +i === +currentTimeIndex.value - +lowerItems.value + 1) && !isMobile.value;
        if ((itemLimits && !item.isTimeFree) || (itemLimits && item.hasLesson)) {
          currentTimeIndex.value = null;
        }

        for (let j = 0; j < upperItems.value + 1; j++) {
          isLessonTimeUpper = isLessonTimeUpper || +i === +currentTimeIndex.value + j;
        }
        for (let l = 0; l < lowerItems.value; l++) {
          isLessonTimeLower = isLessonTimeLower || +i === +currentTimeIndex.value - l;
        }
      }

      return isLessonTimeUpper || isLessonTimeLower;
    };

    const chosenTimeIndex = ref(null);

    const currentStartItem = ref(null);
    const currentEndItem = ref(null);
    const currentItem = ref(null);

    const currentStartTime = ref('');
    const currentEndTime = ref('');
    const onStartTimeChanged = (time) => {
      if (time !== currentStartTime.value) {
        currentStartTime.value = time;
        currentEndTime.value = moment(time,'HH:mm').add(lessonDuration.value, 'minutes').format('HH:mm');
      }
    };

    const times = computed(() => fill(new Array(19 * 12), '').map(setTimes));
    const hoursTable = computed(() => fill(new Array (20), 5));
    const today = computed(() => moment(props.date).format('YYYY-MM-DD'));
    const formatDate = computed(() => moment(props.date).format('DD dddd'));
    const isPastDate = computed(() => moment().subtract(1, 'day').isAfter(props.date));
    const dayMonth = (item) => {
      return moment(item).format('D MMMM');
    };

    const router = useRouter();
    const teacherId = computed(() => get(router, 'currentRoute.value.params.id', ''));

    const textTopOffset = computed(() => {
      return +lessonDuration.value % 10 !== 0 || +lessonDuration.value === 20 ? 0 : -3;
    });
    const textTopOffsetBooked = (item) => {
      if (!isMobile.value) {
        if (item >= 90) return 4;
        else if (item >= 60 && item < 90) return -2;
        else if (item < 60 && item > 20) return -5;
        else if (item <= 20) return -9;
      } else if (isMobile.value && window.innerWidth < 481) {
        if (item >= 100) return 0;
        else if (item >= 45 && item < 100) return -8;
        else if (item < 45 && item >= 30) return -10;
        else if (item < 30) return -7;
      } else if (isMobile.value && window.innerWidth >= 481) {
        if (item >= 90) return 8;
        else if (item > 45 && item < 90) return 0;
        else if (item <= 45 && item >= 30) return -3;
        else if (item < 30) return -6;
      }
    };

    const setTime = computed(() => {
      const date = moment(today.value).format('YYYY-MM-DD');

      const time = times.value.map((item) => {
        const fiveMinutesItems = lessonDuration.value / 5;
        const subtractMinutes = Math.floor(fiveMinutesItems / 2) * 5;
        const startTime = moment(item, 'HH:mm' ).subtract(subtractMinutes, 'minutes').format('HH:mm');
        const endTime = moment(startTime, 'HH:mm' ).add(lessonDuration.value, 'minutes').format('HH:mm');
        const fullDate = moment(`${date}T${item}`, 'YYYY-MM-DDTHH:mm' ).format();

        return {
          time: item,
          ...teacherTime(fullDate),
          ...lessonTime(fullDate),
          fullDate,
          startTime,
          endTime
        };
      });

      return { ...time };
    });

    const fiveMinutesItemCount = computed(() => {
      return lessonDuration.value / 5;
    });
    const upperItems = computed(() => {
      return Math.floor(fiveMinutesItemCount.value / 2);
    });
    const lowerItems = computed(() => {
      return fiveMinutesItemCount.value - upperItems.value;
    });

    const lessonsStartTime = reactive([]);
    const lessonsEndTime = reactive([]);
    const lessonDuration = ref('');
    const lessonsFromSchedule = ref(null);
    const getLessonsFromSchedule = async () => {
      const date = moment(props.date).format('YYYY-MM-DD');
      const start = moment(date).utc().format();
      const end = moment(date).add(1, 'day').utc().format();
      try {
        const query = {
          start: start,
          end: end
        };
        const { data } = await LessonsApi.getGroupLessonsUnauthorized(query, teacherId.value);
        lessonsFromSchedule.value = data;
      } catch (err) {
        console.error(err);
      }
    };

    const countTime = (time) => {
      return moment(time).format('HH:mm');
    };
    const setTimes = (el, i) => {
      return moment().set('minute', 0).set('hour', 5).add(i * 5, 'minutes').format('HH:mm');
    };
    const calcHours = (hour, index) => {
      if (index === 19) return '00:00';
      if (index > 4) return `${hour + index}:00`;
      else return `0${hour + index}:00`;
    };

    const getSettings = async () => {
      try {
        const { data } = await SettingsApi.getSettingsUnauthorized(teacherId.value);
        lessonDuration.value = data.defaultLessonLength || 45;
      } catch (e) {
        return e;
      }
    };

    const saveLocalStorage = (id) => {
      localStorage.setItem('student_id', JSON.stringify(id));
    };
    const getLocalStorage = () => {
      return JSON.parse(localStorage.getItem('student_id'));
    };

    const teacherTime = (fullDate) => {
      let teacherTime = {};
      props.rules.forEach((el) => {
        const endDate = moment(el.ruleEndDate).subtract(5, 'minutes').toDate();
        const now = new Date;
        if (new Date(fullDate) >= new Date(el.ruleStartDate) && new Date(fullDate) <= new Date(endDate) &&
            new Date(fullDate) > now) {
          teacherTime.isTimeFree = true;
        }
      });

      return teacherTime;
    };
    const lessonTime = (fullDate) => {
      let lessonTime = {};
      lessonTime.date = props.date;

      if (!lessonsFromSchedule.value) return;
      lessonsFromSchedule.value.forEach((item, i) => {
        if (new Date(fullDate) >= new Date(item.crmLessonStart) && new Date(fullDate) < new Date(item.crmLessonEnd)) {
          const endDate = moment(item.crmLessonEnd).subtract(5, 'minutes').toDate();

          lessonTime.hasLesson = true;
          lessonTime.lessonIndex = i + 1;
          lessonTime.id = uniqueId();
          lessonTime.startLesson = countTime(item.crmLessonStart);
          lessonTime.endLesson = countTime(item.crmLessonEnd);
          lessonTime.lessonEndFiveMinutes = moment(endDate).format('HH:mm');

          const hours = moment(lessonTime.endLesson, 'HH:mm').subtract(lessonTime.startLesson, 'HH:mm').hours() * 60;
          const minutes = moment(lessonTime.endLesson, 'HH:mm').subtract(lessonTime.startLesson, 'HH:mm').minutes();
          lessonTime.duration = hours + minutes;

          if (!lessonsStartTime.includes(lessonTime.startLesson)) {
            lessonsStartTime.push(lessonTime.startLesson);
          }

          if (!lessonsEndTime.includes(lessonTime.endLesson)) {
            lessonsEndTime.push(lessonTime.endLesson);
          }

          const studentsIds = Object.values(item.crmStudents);
          const currentStudent = studentsIds.find(id => id === currentStudentId.value);
          if (currentStudent) lessonTime.showToStudent = true;
        }
      });

      return lessonTime;
    };

    const bookTime = (body) => {
      saveLocalStorage(body.studentId);
      getLessonsFromSchedule();
      currentStudentId.value = getLocalStorage();
    };

    const hidePassedLesson = (lesson) => {
      if (lesson.date === moment().format('YYYY-MM-DD') &&
          moment(lesson.lessonEndFiveMinutes, 'hh:mm').hours() === moment().hours()) {
        return moment(lesson.lessonEndFiveMinutes, 'hh:mm').minutes() > moment().minutes();
      } else if (lesson.date === moment().format('YYYY-MM-DD')) {
        return moment(lesson.lessonEndFiveMinutes, 'hh:mm').hours() > moment().hours();
      } else return true;
    };

    const onTimeHover = (e, i, item) => {
      const prevItem = e.target.previousElementSibling ? e.target.previousElementSibling.classList : null;
      const prevItemCheck = prevItem && (prevItem.contains('lp-widget-edit-body-times-item__schedule-busy') ||
          !prevItem.contains('lp-widget-edit-body-times-item__schedule'));
      const nextItem = e.target.nextElementSibling ? e.target.nextElementSibling.classList : null;
      const nextItemCheck = nextItem && (nextItem.contains('lp-widget-edit-body-times-item__schedule-busy') ||
          !nextItem.contains('lp-widget-edit-body-times-item__schedule'));
      const hasBusySibling = nextItemCheck || prevItemCheck;
      if (item.hasLesson || !item.isTimeFree || hasBusySibling ||
          +i < +upperItems.value || +i > 227 - +lowerItems.value) {
        currentTimeIndex.value = null;
      } else {
        currentTimeIndex.value = i;
      }
    };
    const onTimeMouseOut = () => {
      if (!isOpenModal.value) {
        currentTimeIndex.value = null;
      }
    };

    onBeforeMount(() => {
      getSettings();
      getLessonsFromSchedule();
      currentStudentId.value = getLocalStorage();
    });

    return {
      isMobile,
      formatDate,
      isPastDate,
      hoursTable,
      times,
      setTime,
      lessonsFromSchedule,
      countTime,
      teacherTime,
      today,
      onTimeHover,
      onTimeMouseOut,
      lessonDuration,
      bookTime,
      currentItem,
      isOpenModal,
      onOpenForm,
      currentStudentId,
      lessonsStartTime,
      lessonsEndTime,
      dayMonth,
      closeModal,
      currentStartTime,
      currentEndTime,
      textTopOffset,
      textTopOffsetBooked,
      onStartTimeChanged,
      calcHours,
      hidePassedLesson,
      checkSmallDuration,
      openError,
      currentTimeIndex,
      showHoveredTime,
      upperItems,
      lowerItems,
      chosenTimeIndex,
      currentStartItem,
      currentEndItem
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-widget-calendar {
  @include global-font;

  &-header {
    position: sticky;
    top: 86px;
    padding: 10px 80px 10px 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-moon-raker;
    border: 1px solid $color-alto;
    border-radius: 4px 4px 0 0;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    z-index: 11;

    &_mobile {
      //padding: 10px 0;
      display: none;
    }

    &_past {
      background: $color-alabaster;
      color: $color-gray;
    }
  }

  &-table {
    display: grid;
    padding: 24px 20px 35px 24px;
    border: 1px solid $color-alto;
    border-radius: 0 0 4px 4px;
    border-top-color: transparent;

    &-row {
      display: grid;
      grid-template-columns: 39px 1fr;
      grid-gap: 37px;

      &_mobile {
        align-items: center;
      }

      &__add-lesson {
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease-in;
      }

      &:hover &__add-lesson {
        opacity: 1;
        pointer-events: auto;
      }

      &__time {
        color: $color-gray;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        height: 18px;
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 40px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $color-white;
        border-radius: 4px;
        background-color: $color-emerald;
        color: $color-white;
        font-weight: bold;
        font-size: 12px;
        line-height: 125%;
        text-transform: uppercase;
        transition: 0.3s ease-in;

        &:hover:not(&_empty, &_signed) {
          background-color: $color-chateau-green;
        }

        &_empty {
          border-color: $color-alto;
          background-color: $color-white;
          color: $color-gray;
        }

        &_signed {
          border-color: $color-accent;
          background-color: $color-white;
          color: $color-accent;
        }

        &_next {
          border: none;
        }
      }
    }
  }
}

.lp-widget-edit {
  &-content {
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //max-height: calc(100vh - 220px);
    //overflow: auto;
    display: grid;
    grid-template-columns: 50px 1fr;
  }

  &-body {
    z-index: 1;
    user-select: none;
    max-height: calc(100vh - 220px);
    overflow: auto;
    display: grid;
    grid-template-columns: 50px 1fr;

    &-hours {
      margin: 20px 18px 0 0;

      &_mobile {
        margin: 12px 18px 0 0;
      }

      &__item {
        display: flex;
        align-items: flex-start;
        height: 60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.5px;
        color: $color-gray
      }
    }

    &-layout {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin: 30px 0 70px 0;
      }

      &_mobile {
        //grid-template-columns: repeat(1, 1fr);

        &:last-child {
          margin: 22px 0 70px 0;
        }
      }
    }

    &-columns {
      position: sticky;
      top: 0;
      padding: 10px 0;
      z-index: 2;
      background-color: $color-white;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-moon-raker;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;
        z-index: 1;
      }

      &__item {
        position: relative;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-accent;
        z-index: 2;
      }
    }

    &-times {
      position: relative;
      & + & {
        border-left: 1px solid $color-alto;
      }

      &-header {
        display: flex;
        justify-content: flex-end;

        &__body {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 700;
          color: $color-white;
          position: relative;
          padding: 4px 8px 2px;
          height: 32px;
          right: -2px;
          top: -24px;
          width: 56%;
          min-width: 108px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background: linear-gradient(to bottom, $color-green-haze 73%, $color-emerald 50%);
          border-right: 2px solid $color-green-haze;
          white-space: nowrap;
        }
      }

      &__tooltip {
        white-space: nowrap;
        margin-left: -19px;
        top: -34px;
      }

      &-item {
        height: 5px;
        position: relative;
        border-left: 2px solid transparent;

        &:nth-child(1) {
          border-top: 1px solid $color-alto;
        }

        &:nth-child(12n +12) {
          border-bottom: 1px solid $color-alto;
        }

        &__schedule {
          background-color: $color-emerald;
          border-color: $color-emerald;
          color: $color-white;
          font-weight: bold;
          cursor: auto;
          position: relative;

          &_mobile {
            &:nth-child(12n + 18) {
              &:before {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-7px);
                height: 12px;
                width: 12px;
                background: url('~@/assets/images/plus.svg'), no-repeat;
                z-index: 1;
              }
            }
          }

          &-busy {
            background: transparent;
            border-left: none;
          }

          &-booked {
            color: $color-purple;
            border-left: 2px solid $color-purple;
            border-right: 2px solid $color-purple;
            border-bottom: none !important;
            z-index: 2;

            &_first {
              border-top: 2px solid $color-purple;
            }

            &_last {
              border-bottom: 2px solid $color-purple !important;
            }
          }

          &_hover, &_click {
            background-color: $color-gossip !important;
            border-color: $color-gossip !important;
            border-left: 2px solid $color-salem !important;
            border-right: 2px solid $color-salem !important;
            border-bottom: none !important;
            z-index: 10;
            cursor: pointer;

            &_first {
              border-top: 2px solid $color-salem !important;
            }

            &_last {
              border-bottom: 2px solid $color-salem !important;
            }
          }

          &:nth-child(12n +12) {
            border-bottom: 1px solid rgba($color-alto, 0.5);
          }
        }
      }
    }

    &-book {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      color: $color-salem;
      pointer-events: none;

      &__sign {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__icon {
        margin-right: 5px;
      }

      &__text {
        font-size: 16px;
        font-weight: 700;
        text-align: center;

        @media (max-width: 480px) {
          padding: 0 13px;
        }

        @media (max-width: 322px) {
          transform: translateY(1px) !important;
        }

        &_mobile {
          font-size: 14px;
          line-height: 15px;
        }

        svg {
          margin-right: 6px;
          width: 11px;
          height: 12px;
        }
      }

      &_show {
        font-weight: 700;
        display: block;
        bottom: -3px;
      }
    }

    &-lesson {
      display: none;
      position: absolute;
      left: 10px;
      color: $color-salem;
      transform: translateY(calc(-50% - 1px));

      &_show {
        display: flex;
      }

      &_show-odd {
        display: flex;
        transform: translateY(calc(-50% - 3px));
      }
    }
  }

  &-sign {
    z-index: 1;
  }
}

.thin-border {
  border-width: 1px !important;
  font-size: 15px;

  svg {
    height: 10px;
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-widget-edit-body-book__icon  {
  path {
    fill: $color-salem !important;
  }
}

.lp-widget-edit-body-lesson__icon{
  height: 12px;
  width: 10px;
  margin-right: 10px;

  path {
    fill: $color-salem !important;
  }
}
</style>
