<template>
  <div
    class="lp-file-upload-list-header"
    :class="{'lp-file-upload-list-header_mobile': mobile}"
  >
    <div class="lp-file-upload-list-header__title">
      {{ allUploaded ? textAllUploaded : textUploading }}
    </div>
    <div
      class="lp-file-upload-list-header__cross"
      @click.stop="clickByClose"
    >
      <crossIcon />
    </div>
    <FileUploadModal
      v-model:open="openModal"
      @close="$emit('close');"
      :open="openModal"
    />
  </div>
</template>

<script>
import { crossIcon } from '@/constants/icons/files';
import { computed, ref, watch } from 'vue';
import FILE_STATUS from '@/constants/enums/fileStatus';
import declensionOfNum from '@/constants/utils/declensionOfNum';
import FileUploadModal from '@/components/CRM/Files/FilesUploadList/FileUploadModal';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FileUploadListHeader',
  components: {
    FileUploadModal,
    crossIcon
  },
  props: {
    mobile: Boolean,
    files: Array
  },
  emits: ['close', 'update:modal'],
  setup (props, { emit }) {
    const allUploaded = computed(() => props.files.every(({ status }) => {
      const { ERROR, UPLOADED } = FILE_STATUS;
      const errors = status === ERROR;
      const uploaded = status === UPLOADED;
      return errors || uploaded;
    }));

    const { t, tm } = useI18n();

    const textAllUploaded = computed(() => {
      const upload = t('myFiles.uploading');
      const { length } = props.files;
      const files = declensionOfNum(length, tm('myFiles.declensionFiles'));
      const completed = t('myFiles.completed');
      return `${upload} ${length} ${files} ${completed}`;
    });

    const textUploading = computed(() => {
      const upload = t('myFiles.uploading');
      const { length } = props.files.filter(({ status }) => {
        const {
          WAIT,
          UPLOADING,
          PRE_UPLOADED
        } = FILE_STATUS;
        const wait = status === WAIT;
        const uploading = status === UPLOADING;
        const preUploaded = status === PRE_UPLOADED;
        return wait || uploading || preUploaded;
      });
      const files = declensionOfNum(length, tm('myFiles.declensionFiles'));
      return `${upload} ${length} ${files}...`;
    });

    const openModal = ref(false);
    watch(openModal, (val) => {
      emit('update:modal', val);
    });
    const clickByClose = () => {
      if (allUploaded.value) {
        emit('close');
      } else {
        openModal.value = true;
      }
    };

    return {
      allUploaded,
      textAllUploaded,
      textUploading,
      openModal,
      clickByClose
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-upload-list-header {
  display: grid;
  grid-template-columns: 1fr 14px;
  background: $color-mine-shaft;
  padding: 15px 18px;
  border-radius: 4px 4px 0 0;

  &_mobile {
    border-radius: 0;
  }

  &__title {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $color-white;
  }

  &__cross {
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
