<template>
  <div
    class="lp-crm-finance-totals"
    :class="{'lp-crm-finance-totals_mobile': isMobile}"
  >
    <div
      class="lp-crm-finance-totals-card"
      :class="{'lp-crm-finance-totals-card_mobile': isMobile}"
      v-for="total of totalCards"
      :key="total.id"
    >
      <div
        class="lp-crm-finance-totals-card__title"
        :class="{'lp-crm-finance-totals-card__title_mobile': isMobile}"
      >
        {{ total.title }}
      </div>
      <div
        class="lp-crm-finance-totals-card__number"
        :class="{'lp-crm-finance-totals-card__number_mobile': isMobile}"
      >
        <Vue3Autocounter
          :startAmount="0"
          :endAmount="total.number"
          :duration="2"
          :suffix="` ${total.currency}`"
          separator=","
          decimalSeparator="."
          :decimals="2"
        />
      </div>
      <component
        class="lp-crm-finance-totals-card__icon"
        :is="total.icon"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { coinsIcon, coinsDocumentIcon } from '@/constants/icons/crm';
import Vue3Autocounter from 'vue3-autocounter';
import MobileDetect from 'mobile-detect';

export default {
  name: 'FinanceTotals',
  components: {
    Vue3Autocounter,
    coinsIcon,
    coinsDocumentIcon
  },
  props: {
    totals: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const { t } = useI18n();

    const store = useStore();
    const settings = computed(() => store.getters.settings);

    const totalCards = computed(() => ([
      {
        id: 'Complete',
        title: t('crm.finance.totals.all'),
        icon: coinsDocumentIcon,
        ...getTotal('Complete')
      },
      {
        id: 'Pending',
        title: t('crm.finance.totals.expected'),
        icon: coinsIcon,
        ...getTotal('Pending')
      }
    ]));

    const getTotal = (status) => {
      const result = props.totals.find(item => item.incomeStatus === status) || {};
      return {
        currency: result.currency || settings.value.currentCurrency,
        number: result.incomeValue || 0
      };
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      totalCards
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-finance-totals {
  @include global-font;
  display: flex;
  grid-gap: 44px;
  padding: 0 24px 36px;

  &_mobile {
    display: grid;
    padding: 0 12px 36px;
    background-color: $color-white;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(303px, 1fr));
  }

  &-card {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20px 35px;
    grid-gap: 8px;
    justify-content: space-between;
    align-items: center;
    padding: 32px 24px;
    background: $color-white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      letter-spacing: 0.07em;
      color: $color-text;

      &_mobile {
        font-size: 14px;
      }
    }

    &__number {
      font-weight: 800;
      font-size: 28px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $color-text;

      &_mobile {
        font-size: 20px;
      }
    }

    &__icon {
      grid-column: 2;
      grid-row: 1/3;
    }

    &_mobile {
      grid-gap: 0;
    }
  }
}

</style>
