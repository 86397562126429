<template>
  <div class="stp-schedule">
    <div class="stp-schedule-header">
      <span class="stp-schedule-header_title">
        {{ $t('landing.menu.schedule').toUpperCase() }}
      </span>
      <a
        :href="`${FRONTEND_URL}/${teacherId}`"
        class="stp-schedule-header-lesson"
        target="_blank"
      >
        {{ $t('student.calendar.signUp') }}
      </a>
    </div>
    <div
      class="
        stp-schedule-calendar"
    >
      <div class="stp-schedule-calendar-header">
        <div class="stp-schedule-calendar-header__today">
          {{ calendarDateFormat }}
        </div>
        <div class="stp-schedule-calendar-header__controls">
          <DateControls
            v-model:date="calendarDate"
            v-model:tab="activeType"
          />
        </div>
      </div>
      <div class="stp-schedule-calendar-body">
        <component
          className="stp-schedule-calendar-body__calendar"
          :is="getCalendar"
          :lessons="groupLessons"
          :key="calendarDate"
          :date="calendarDate"
          @get-lessons="getLessons"
        />
      </div>
    </div>
    <div class="stp-schedule-switch">
      <CustomToggle
        v-model:value="calendarSwitch.value"
        :value="calendarSwitch.value"
      />
      <span class="stp-schedule-switch_label">
        {{ $t(calendarSwitch.label) }}
      </span>
    </div>
  </div>
</template>

<script>
import { FRONTEND_URL } from '@/constants/domains';
import DateControls from '@/components/CRM/Lessons/Calendar/DateControls/DateControls';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import { fill, get, groupBy, orderBy } from 'lodash';
import { useStore } from 'vuex';
import DateTimetableControls from '@/components/CRM/Lessons/Timetable/DateTimetableControls/DateTimetableControls';
import DateControlsMobile from '@/components/CRM/Lessons/Calendar/DateControls/DateControlsMobile';
import StudentCalendarMonths from '@/components/StudentProfile/StudentCalendar/StudentCalendarMonths';
import StudentCalendarWeeks from '@/components/StudentProfile/StudentCalendar/StudentCalendarWeeks';
import StudentCalendarDays from '@/components/StudentProfile/StudentCalendar/StudentCalendarDays';
import CustomToggle from '@/components/Main/Inputs/CustomToggle';
import StudentProfileAPI from '@/api/CRM/studentProfile';

export default {
  name: 'StudentCalendar',
  components: {
    DateControlsMobile,
    DateTimetableControls,
    DateControls,
    CustomToggle
  },
  props: {
    date: String,
    teacherId: String
  },
  setup (props, { emit }) {
    const calendarSwitch = reactive({
      label: 'crm.lessons.lastLessons',
      value: false
    });

    const calendarDate = ref(moment().format('YYYY-MM-DD'));

    const calendarDateFormat = computed(() => moment(calendarDate.value).format('MMMM YYYY'));

    const store = useStore();
    const lessons = computed(() => store.getters.calendarLessons);
    const setCalendarLessons = (data) => store.dispatch('setCalendarLessons', data);
    const groupLessons = computed(() => {
      const withDate = lessons.value.map((lesson) => {
        lesson.date = moment(lesson.crmLessonStart).format('YYYY-MM-DD');
        return lesson;
      });
      const sortByStartTime = orderBy(withDate, 'crmLessonStart');
      return groupBy(sortByStartTime, 'date');
    });

    const router = useRouter();
    const activeType = ref(get(router, 'currentRoute.value.params.unit', 'month'));
    watch(activeType, () => router.replace({ params: { unit: activeType.value } }));

    const getCalendar = computed(() => {
      switch (activeType.value) {
      case 'month':
        return StudentCalendarMonths;
      case 'week':
        return StudentCalendarWeeks;
      default:
        return StudentCalendarDays;
      }
    });

    const getRangeDate = () => {
      const date = moment(calendarDate.value);
      if (activeType.value === 'month') {
        const week = fill(new Array(7), 1).map((el, i) => {
          const startDateMonth = moment(calendarDate.value).startOf('month');
          return startDateMonth.subtract(i, 'd');
        });
        const start = week.find((date) => date.day() === 1);
        return {
          dateRangeStart: start.utc().format(),
          dateRangeEnd: start.add(35, 'd').utc().format()
        };
      }
      return {
        dateRangeStart: date.utc().format(),
        dateRangeEnd: date.add(1, activeType.value).utc().format()
      };
    };

    const route = useRoute();

    const getLessons = async () => {
      const crmStudentId = route.params.id;
      const { dateRangeStart, dateRangeEnd } = getRangeDate();
      const query = {
        showCompleted: calendarSwitch.value,
        crmStudentId,
        dateRangeStart,
        dateRangeEnd
      };
      try {
        const { data } = await StudentProfileAPI.getLessonsForCalendar(query);
        await setCalendarLessons(data);
      } catch (err) {
        console.error(err);
      }
    };

    watch([calendarDate, activeType, props, calendarSwitch], getLessons);

    watch([calendarDate], () => {
      emit('update:date', calendarDate.value);
    });

    onBeforeMount(() => {
      getLessons();
    });

    const arrowUse = (position) => {
      direction.value = position;
    };

    const direction = ref('left');

    return {
      calendarDate,
      calendarDateFormat,
      activeType,
      getCalendar,
      FRONTEND_URL,
      groupLessons,
      getLessons,
      arrowUse,
      calendarSwitch
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';
@import '~styles/_mixins.scss';

.stp-schedule {
  padding: 40px;
  display: grid;
  grid-gap: 26px;

  &-header {
    display: flex;
    justify-content: space-between;

    &_title {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
    }

    &-lesson {
      background-color: $color-purple;
      color: $color-white;
      padding: 12.5px 23px;
      font-size: 12px;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 0.1em;
      border-radius: 4px;
      box-shadow: $color-shadow-sign-link;
      cursor: pointer;
      transition: background-color .2s ease-in;

      &:hover {
        background-color: $color-purple-dark;
      }
    }
  }

  &-switch {
    display: flex;

    &_label {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 125%;
    }
  }

  &-calendar {
    @include global-font;

    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;
      align-items: center;
      margin-bottom: 12px;
      min-height: 37px;

      &__week-controls {
        grid-column: 1/3;
      }

      &__today {
        color: $color-text;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        user-select: none;
      }
    }

    &-body {
      position: relative;

      &__calendar {
        top: 0;
        left: 0;
        right: 0;
        background-color: $color-white;
      }
    }
  }
}

</style>
