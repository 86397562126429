<template>
  <div
    class="discounts-item"
    :class="{'discounts-item_mobile': isMobile}"
    @click.stop="showTooltip = false"
    @mouseleave.stop="showTooltip = false"
  >
    <div class="discounts-item-header">
      <img
        :src="discount.logo"
        class="discounts-item-header__logo"
        alt="Logo"
      >
      <div class="discounts-item-header-info">
        <div class="discounts-item-header-info__name">
          {{ $t(discount.companyName) }}
        </div>
        <div class="discounts-item-header-info__link">
          <a
            :href="discount.link"
            target="_blank"
          >
            {{ discount.link }}
          </a>
        </div>
      </div>
    </div>
    <div class="discounts-item__title">
      {{ $t(discount.discountName) }}
    </div>
    <div
      v-if="discount.details"
      class="discounts-item__details"
    >
      {{ $t(discount.details) }}
    </div>
    <div
      class="discounts-item-footer"
      :class="{'discounts-item-footer_mobile': isMobile}"
    >
      <div
        class="discounts-item-promo"
        :class="{'discounts-item-promo_mobile': isMobile}"
      >
        <div class="discounts-item-promo__wrapper">
          <div class="discounts-item-promo__header">
            {{ $t('crm.discounts.promo') }}
          </div>
          <div
            class="discounts-item-promo__text"
            :class="{'discounts-item-promo__text_mobile': isMobile}"
            @click.stop="copyPromoCode(discount.promoCode)"
          >
            <div>{{ discount.promoCode }}</div>
            <div
              @mouseover="onCopyHover"
              @click.stop="copyPromoCode(discount.promoCode)"
              class="discounts-item-promo__copy"
            >
              <Tooltip
                :open="showTooltip"
                :text="$t(tooltipText)"
                :topPosition="true"
                :onMobile="true"
                class="item-tooltip"
              />
              <copyIcon />
            </div>
          </div>
        </div>
        <div
          class="discounts-item-promo__discount"
          :class="{'discounts-item-promo__discount_mobile': isMobile}"
        >
          {{ discount.percentage }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { computed, ref } from 'vue';
import { copyIcon } from '@/constants/icons/crm';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import MobileDetect from 'mobile-detect';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

export default {
  name: 'DiscountsItem',
  components: {
    copyIcon,
    Tooltip
  },
  props: {
    discount: Object
  },
  setup () {
    const showTooltip = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const tooltipText = ref('lessonCall.copyLink');

    const onCopyHover = () => {
      showTooltip.value = true;
    };

    const copyPromoCode = (code) => {
      if (isMobile.value) {
        tooltipText.value = 'lessonCall.copied';
        showTooltip.value = true;

        setTimeout(() => {
          showTooltip.value = false;
          tooltipText.value = 'lessonCall.copyLink';
        }, 1200);
      } else {
        navigator.clipboard.writeText(code);
        tooltipText.value = 'lessonCall.copied';

        setTimeout(() => {
          showTooltip.value = false;
          tooltipText.value = 'lessonCall.copyLink';
        }, 1000);
      }
      yandexTracker.copyPromo();
    };

    return {
      copyPromoCode,
      isMobile,
      tooltipText,
      onCopyHover,
      showTooltip
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.discounts-item {
  width: 465px;
  min-height: 270px;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  border: 1px solid $color-alto;
  background: url("~@/assets/images/discounts/discount-item-bg.png") $color-white no-repeat;

  &__title {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 0.07em;
    line-height: 120%;
    color: $color-mine-shaft;
  }

  &__details {
    margin-top: 5px;
    font-size: 14px;
  }

  &_mobile {
    height: fit-content;
    max-width: 510px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 15px;
    min-height: 220px;
  }
}

.discounts-item-header {
  display: flex;
  align-items: center;

  &__logo {
    margin-right: 15px;
    width: 55px;
    height: auto;
  }
}

.discounts-item-header-info {
  &__name {
    font-weight: 600;
  }

  &__link {
    margin-top: 6px;
    color: $color-purple;

    @media (max-width: 400px) {
      max-width: 200px;
      word-break: break-all;
    }
  }
}

.discounts-item-footer {
  height: 107px;

  &_mobile {
    height: auto;
  }
}

.discounts-item-promo {
  position: absolute;
  width: calc(100% - 20px);
  bottom: 20px;
  display: flex;
  justify-content: space-between;

  &_mobile {
    position: initial;
    margin-top: 30px;
  }

  &__wrapper {
    position: relative;
  }

  &__header {
    font-size: 14px;
    font-weight: 700;
    color: $color-mine-shaft;
  }

  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    height: 44px;
    margin-top: 8px;
    padding: 7px 12px;
    font-size: 24px;
    font-weight: 700;
    box-shadow: 0 0 28px rgba($color-black, 0.08);
    color: $color-purple;

    &_mobile {
      min-width: 190px;
      padding: 7px 9px;

      div {
        font-size: 18px;
      }
    }
  }

  &__copy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $color-moon-raker;
    cursor: pointer;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &__discount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 13px;
    bottom: -10px;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    font-size: 26px;
    font-weight: 800;
    color: $color-mine-shaft;
    background-color: $color-supernova;

    &_mobile {
      width: 65px;
      height: 65px;
      bottom: 10px;
      right: 10px;
      font-size: 18px;
    }
  }
}

.item-tooltip {
  bottom: 27px;
}
</style>