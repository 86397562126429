<template>
  <div
    class="lp-delay-settings-end"
    :class="{'lp-delay-settings-end_mobile': isMobile}"
  >
    <img
      class="lp-delay-settings-end__image"
      :src="errorPlaceholder"
      alt="error"
    />
    <div class="lp-delay-settings-end__title">
      {{ $t('delaySettings.stop.title') }}
    </div>
    <div
      class="lp-delay-settings-end-info"
      :class="{'lp-delay-settings-end-info_mobile': isMobile}"
    >
      <errorIcon />
      <span>{{ $t('delaySettings.stop.description') }}</span>
    </div>
    <div class="lp-delay-settings-end__buttons">
      <button
        class="lp-button lp-button_thin"
        @click="$emit('stop')"
      >
        {{ $t('delaySettings.stop.accept') }}
      </button>
      <button
        class="lp-button lp-button_thin"
        :class="{
          'lp-button_thin': !isMobile,
          'lp-button_border': isMobile
        }"
        @click="$emit('repeat')"
      >
        {{ $t('delaySettings.stop.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { errorIcon } from '@/constants/icons/delaySettings';
import errorPlaceholder from '@/assets/images/calibrationPlaceholders/error.svg';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'DelayStop',
  components: {
    errorIcon
  },
  emits: ['stop', 'repeat'],
  setup () {
    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      errorPlaceholder
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-delay-settings-end {
  display: grid;
  align-items: center;
  padding: 0 20px;

  &_mobile {
    max-width: 100vw;
  }

  &_mobile &__title {
    font-size: 17px;
  }

  &_mobile  &__buttons {
    grid-template-columns: 1fr;
  }

  &__image {
    height: 176px;
    width: auto;
    object-fit: contain;
    margin: 40px auto 32px;
  }

  &-info {
    display: grid;
    grid-template-columns: 37px 1fr;
    grid-gap: 17px;
    align-items: start;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    padding: 20px;
    box-shadow: 0 0 28px rgba($color-black, 0.08);
    max-width: 512px;
    margin: 0 auto 24px;

    &_mobile {
      align-items: center;
      font-size: 14px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    margin-bottom: 20px;
  }

  &__buttons {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;

    .lp-button {
      font-size: 12px!important;
      padding: 18px 16px;
    }
  }
}

</style>
