<template>
  <div
    class="lp-crm-expenses"
    :class="{'lp-crm-expenses_mobile': isMobile}"
  >
    <ExpensesHeader
      :fistDate="fistDate"
      :lastDate="lastDate"
      :empty="!hasExpenses"
      v-model:date="selectedDate"
      v-model:openExpense="openExpense"
    />
    <ExpensesTotals
      v-if="totals"
      :totals="totals"
    />
    <ExpensesTable
      :total="totals"
      :pagination="pagination"
      :expenses="expenses"
      @get="getExpensesByDate"
      @edit="editExpense"
      v-model:openExpense="openExpense"
    />
    <ExpensesForm
      @update="updateExpenses"
      v-model:openForm="openExpense"
      :openForm="openExpense"
      :expense="dataEdit"
      @clear="clearDataEdit"
    />
    <ExpensesPlaceholder
      v-if="!totals && placeholderExpenses"
      v-model:openExpense="openExpense"
    />
  </div>
</template>

<script>
import ExpensesHeader from '@/components/CRM/Expenses/ExpensesHeader';
import ExpensesForm from '@/components/CRM/Expenses/ExpensesForm';
import ExpensesTable from '@/components/CRM/Expenses/ExpensesTable';
import ExpensesTotals from '@/components/CRM/Expenses/ExpensesTotals';
import ExpensesPlaceholder from '@/components/CRM/Expenses/ExpensesPlaceholder';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import moment from 'moment';
import ExpensesApi from '@/api/CRM/expenses';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Expenses',
  components: {
    ExpensesPlaceholder,
    ExpensesHeader,
    ExpensesForm,
    ExpensesTable,
    ExpensesTotals
  },
  setup () {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const placeholderExpenses = ref(true);

    const totals = ref(0);
    const pagination = ref({});
    const expenses = ref([]);
    const dataEdit = ref({});

    const scrollUp = () => {
      const ps = document.querySelector('.lp-crm-main');
      if (!ps) return;
      ps.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    const currentPage = ref(1);

    const fistDate = ref('');
    const lastDate = ref('');
    const hasExpenses = ref(false);
    const getFirstDate = async () => {
      placeholderExpenses.value = false;
      await setLoaderRun(true);
      const start = moment().
        subtract(50, 'year').
        startOf('month').
        utc().
        format();
      const end = moment().
        add(50, 'year').
        startOf('month').
        utc().
        format();
      try {
        const query = {
          limit: 1,
          startDate: start,
          endDate: end,
          lost: true,
          start,
          end
        };
        const { data: dataPages } = await ExpensesApi.getExpensesByDate(query);
        const { totalPages } = dataPages;
        const [lastExpense = {}] = dataPages.resource;
        const { data } = await ExpensesApi.getExpensesByDate({ ...query, page: totalPages });
        const [firstExpense = {}] = data.resource;
        if (totalPages) {
          hasExpenses.value = !!dataPages.totalDocs;
        }
        placeholderExpenses.value = true;
        const firstDateExpense = get(firstExpense, 'expenseDate', '');
        const lastDateExpense =  get(lastExpense, 'expenseDate', '');
        fistDate.value = firstDateExpense;
        lastDate.value = lastDateExpense;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
      }
    };

    const getExpensesByDate = async ({ page = 1, stopScroll } = {}) => {
      const [startDate, endDate] = selectedDate.value;
      if (!startDate || !endDate) return;
      await setLoaderRun(true);
      placeholderExpenses.value = false;
      currentPage.value = page;
      try {
        if (!stopScroll) scrollUp();
        const query = {
          page,
          limit: 25,
          startDate: moment(startDate).
            startOf('day').
            utc().
            format(),
          endDate: moment(endDate).
            endOf('day').
            utc().
            format()
        };
        const { data } = await ExpensesApi.getExpensesByDate(query);
        const { resource, expensesTotal, ...paginationInfo } = data;
        pagination.value = paginationInfo;
        totals.value = expensesTotal;
        expenses.value = resource;
        placeholderExpenses.value = true;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        placeholderExpenses.value = true;
        await setLoaderRun(false);
      }
    };

    const selectedDate = ref([]);
    watch(selectedDate, () => getExpensesByDate({}));
    onBeforeMount(async () => {
      await getFirstDate();
    });

    const openExpense = ref(false);
    const updateExpenses = async () => {
      await getFirstDate();
      await getExpensesByDate({ stopScroll: true, page: currentPage.value });
    };

    const editExpense = (payload) => {
      dataEdit.value = payload;
    };

    const clearDataEdit = () => {
      dataEdit.value = {};
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      openExpense,
      totals,
      pagination,
      fistDate,
      lastDate,
      updateExpenses,
      getExpensesByDate,
      editExpense,
      clearDataEdit,
      dataEdit,
      expenses,
      hasExpenses,
      selectedDate,
      placeholderExpenses
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-expenses {
  @include global-font;
  margin: -35px -24px 0;

  &_mobile {
    margin: 0;
    height: 100%;
    background-color: $color-white;
  }
}

</style>