<template>
  <div class="reviews-card__review">
    <p
      class="reviews-card__review__text"
      :class="{
        'reviews-card__review__text_close': isReadMore,
        'reviews-card__review__text_open': openText
      }"
    >
      {{ $t(text) }}
    </p>
    <p
      class="reviews-card__review__open"
      v-if="isReadMore"
      @click="openText = !openText"
    >
      {{
        openText
          ? $t('crm.students.form.lessMore')
          : $t('crm.students.form.readMore')
      }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'ReviewsReadMore',
  props: {
    review: Object
  },
  setup (props) {
    const { t } = useI18n();
    const isReadMore = computed(() => props.review.isReadMore);
    const text = computed(() => props.review.text);
    const openText = ref(false);

    return {
      openText,
      text,
      isReadMore,
      t
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.reviews-card__review {
  width: calc(100% - 5px);
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: start;

  &__text {
    display: block;
    color: $color-text;
    overflow: hidden;
    white-space: pre-line;
    margin-bottom: 6px;
    transition: 0.4s ease;
    transform-origin: top;

    &_close {
      min-height: 105px;
      max-height: 105px;
    }

    &_open {
      max-height: 1000px;
    }
  }

  &__open {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 125%;
    color: $color-accent;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
}
</style>
