import { reactive } from 'vue';
import {
  accompanistMenuIcon,
  calendarIcon,
  chartIcon,
  discountIcon,
  filesMenuIcon,
  hatIcon,
  settingIcon,
  subscriptionsIcon,
  walletIcon,
  tableMoney
} from '@/constants/icons/crm';

export default reactive([
  {
    title: 'crm.sidebar.lessons',
    icon: calendarIcon,
    route: { name: 'CRMLessons', params: { unit: 'month' } }
  },
  {
    title: 'crm.sidebar.students',
    icon: hatIcon,
    route: { name: 'CRMStudents' }
  },
  {
    title: 'crm.sidebar.subscriptions',
    icon: subscriptionsIcon,
    route: { name: 'CRMSubscriptions' }
  },
  {
    title: 'crm.sidebar.finance',
    icon: walletIcon,
    route: { name: 'CRMFinance' }
  },
  {
    title: 'crm.sidebar.expenses',
    icon: tableMoney,
    route: { name: 'CRMExpenses' }
  },
  {
    title: 'crm.sidebar.analytics',
    icon: chartIcon,
    route: { name: 'CRMAnalytics' }
  },
  {
    title: 'crm.sidebar.files',
    icon: filesMenuIcon,
    route: { name: 'MyFiles', params: { folderId: 'home' } }
  },
  {
    title: 'crm.sidebar.accompaniment',
    icon: accompanistMenuIcon,
    route: { name: 'CRMAccompaniment' }
  },
  {
    title: 'crm.sidebar.discounts',
    icon: discountIcon,
    route: { name: 'CRMDiscounts' }
  },
  {
    title: 'crm.sidebar.settings',
    icon: settingIcon,
    route: { name: 'CRMSettings' }
  }
]);
