import {
  folderIcon,
  imageIcon,
  imgMiniIcon,
  midiIcon,
  midiMiniIcon,
  mp3Icon,
  mp3MiniIcon,
  pdfIcon,
  pdfMiniIcon
} from '@/constants/icons/files';

const fileTypes = [
  {
    isFolder: true,
    types: ['folder'],
    icon: folderIcon,
    options: ['rename', 'delete']
  },
  {
    types: ['png', 'jpg', 'jpeg'],
    icon: imageIcon,
    miniIcon: imgMiniIcon,
    options: ['rename', 'download', 'delete'],
    isSharingFiles: true,
    isImage: true
  },
  {
    types: ['midi', 'mid'],
    icon: midiIcon,
    miniIcon: midiMiniIcon,
    options: ['rename', 'download', 'delete'],
    isAudio: true
  },
  {
    types: ['mp3', 'mpeg', 'ogg', 'wav', 'm4a', 'x-m4a'],
    icon: mp3Icon,
    miniIcon: mp3MiniIcon,
    options: ['rename', 'download', 'delete'],
    isAudio: true
  },
  {
    types: ['pdf'],
    icon: pdfIcon,
    miniIcon: pdfMiniIcon,
    options: ['rename', 'download', 'delete'],
    isSharingFiles: true,
    isPdf: true
  },
  {
    types: ['default'],
    icon: pdfIcon,
    miniIcon: pdfMiniIcon,
    options: ['rename', 'download', 'delete']
  }
];

const validTypes = `${fileTypes.filter(({ isFolder }) => !isFolder).map(({ types }) => {
  const formatTypes = types.map((item) => `.${item}`);
  return formatTypes.join(', ');
}).join(', ')},`;

export { fileTypes, validTypes };
