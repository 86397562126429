<template>
  <div
    v-if="countryCode"
    class="mc-container"
  >
    <div
      class="mc-container__body"
      :class="{'mc-container__body_mobile': isMobile}"
      ref="mainScroll"
    >
      <Loader />
      <router-view />
    </div>
  </div>
</template>

<script>

import Loader from '@/components/Main/Loader/Loader';
import { computed, onBeforeMount, watch, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { VueCookieNext } from 'vue-cookie-next';
import AuthApi from '@/api/Auth/api';
import { useRouter } from 'vue-router';
import timeout from '@/constants/utils/timeout';
import hideJivoChat from '@/constants/utils/hideJivoChat';
import { addZoomClass } from '@/constants/utils/addZoomClass';
import SettingsApi from '@/api/CRM/settings';

export default {
  name: 'App',
  components: {
    Loader
  },
  setup () {
    console.log('app');
    const router = useRouter();
    const store = useStore();

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const user = computed(() => store.getters.user);
    const setActiveUser = (data) => store.dispatch('setUser', data);

    const isMobile = computed(() => store.getters.isMobile);
    const setIsMobile = () => store.dispatch('setIsMobile');

    const getUser = async () => {
      await setLoaderRun(true);
      try {
        const { data } = await AuthApi.getUser();
        await setLoaderRun(false);
        await setActiveUser(data);
        const { data: settings } = await SettingsApi.getSettings();
        if (settings?.language) i18n.global.locale = settings?.language;
        if (router.currentRoute.value.name === 'Login') {
          await router.push({ name: 'Main' });
        }
      } catch (err) {
        await setLoaderRun(false);
      }
    };

    const setVhSize = () => {
      const height = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${height}px`);
      setIsMobile();
    };

    const onWindowResize = () => {
      setVhSize();
      addZoomClass();
    };

    const redirect = async () => {
      if (VueCookieNext.getCookie('token')) return;
      await timeout(500);
      const { noRedirect, redirect } = router.currentRoute.value.meta;
      if (noRedirect) return;
      const route = redirect || { name: 'Login' };
      if (route.name !== router.currentRoute.value.name) {
        await router.push(route);
      }
    };
    watch(router.currentRoute, redirect);

    onBeforeMount(() => {
      setVhSize();
      if (!window.location.pathname) {
        redirect();
      }
      onWindowResize();
      if (VueCookieNext.getCookie('token') && !user.value._id) getUser();
      window.addEventListener('resize', onWindowResize, false);
      window.addEventListener('orientationchange', setVhSize);
      if (isMobile.value) {
        hideJivoChat(true);
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onWindowResize, false);
      window.removeEventListener('orientationchange', setVhSize);
    });

    const countryCode = computed(() => store.getters.countryCode);

    return {
      isMobile,
      countryCode
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/style';

.mc-container {
  &__body {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;

    &_mobile {
      min-height: calc(var(--vh, 1vh) * 100);
    }
  }
}

</style>

<style lang="scss">
@import '~@/sass/style';

.mc-container {
  @include global-font();
  background-color: $color-alabaster;

  .lp-main-page {
    width: 100%;
  }
}

.app-zoom {
  zoom: 0.8;

  .vue-slider {
    zoom: 1.25;
  }

  .vue-slider-rail {
    zoom: 0.8;
  }
}

</style>
