<template>
  <div
    class="lp-crm-students-table"
    v-if="!isMobile"
  >
    <StudentsTable
      v-model:openForm="openForm"
      v-model:openCard="viewStudent"
      @edit="openEditStudent"
    />
  </div>
  <div
    class="lp-crm-students-cards"
    :class="{
      'lp-crm-students-cards_hide':
        !isMobile || (isMobile && (openForm || openCard))
    }"
    v-else
  >
    <StudentsList
      v-model:openForm="openForm"
      v-model:openCard="viewStudent"
      @edit="openEditStudent"
    />
  </div>

  <StudentForm
    v-model:open="openForm"
    :student="editableStudent"
    :openForm="openForm"
  />
  <StudentCard
    v-model:open="openCard"
    v-model:editStudent="editableStudent"
    v-model:openEdit="openForm"
    :openForm="openCard"
    :student="currentDisplayStudent"
  />
</template>

<script>
import StudentsTable from '@/components/CRM/Students/StudentsTable';
import StudentsList from '@/components/CRM/Students/StudentsList/StudentsList';
import StudentForm from '@/components/CRM/Students/StudentForm/StudentForm';
import StudentCard from '@/components/CRM/Students/StudentCard/StudentCard';
import { computed, inject, onMounted, ref, watch } from 'vue';
import StudentApi from '@/api/CRM/students';
import MobileDetect from 'mobile-detect';
import { useRouter } from 'vue-router';
import { get } from 'lodash';
import { useStore } from 'vuex';
import LessonsPassTemplateApi from '@/api/CRM/lessonsPassTemplate';

export default {
  name: 'MyStudents',
  components: {
    StudentsTable,
    StudentsList,
    StudentForm,
    StudentCard
  },
  setup () {
    const router = useRouter();
    const openFromRoute = computed(() =>
      get(router, 'currentRoute.value.params.openForm', '')
    );
    const crmStudentIdRoute = computed(() =>
      get(router, 'currentRoute.value.query.crmStudentId', '')
    );
    const store = useStore();
    const students = computed(() => store.getters.students);
    const user = computed(() => store.getters.user);

    const openForm = ref(false);
    const editableStudent = ref(null);

    const openCard = ref(false);
    const viewStudent = ref(null);

    const currentDisplayStudent = ref({});

    const setDisplayStudent = async () => {
      const currentId =
        (viewStudent.value && viewStudent.value._id) || crmStudentIdRoute.value;
      if (currentId) {
        const findElement = students.value.find(
          (item) => item._id === currentId
        );
        if (!findElement) {
          const { data } = await StudentApi.getStudentById(currentId);
          currentDisplayStudent.value = data;
        } else {
          currentDisplayStudent.value = findElement;
        }
      } else {
        currentDisplayStudent.value = {};
      }
    };

    const openFormStudent = () => {
      openForm.value = true;
    };

    const openEditStudent = (student) => {
      editableStudent.value = student;
      openFormStudent();
    };

    watch(viewStudent, (val) => {
      openCard.value = val ? !!val._id : false;
    });

    watch(
      () => [...students.value],
      (currentValue) => {
        setDisplayStudent();
        if (!isMobile.value || !currentValue.length || !crmStudentIdRoute.value)
          return;
        openCard.value = crmStudentIdRoute.value;
      }
    );

    watch(
      () => router.currentRoute.value.query,
      (val) => {
        const { crmStudentId } = val;
        if (!crmStudentId) {
          openCard.value = false;
        }
      }
    );

    watch(openCard, (val) => {
      if (val) {
        if (viewStudent.value) {
          setDisplayStudent();
          router.push({
            name: 'CRMStudents',
            query: { crmStudentId: viewStudent.value._id }
          });
        }
      } else {
        router.push({ name: 'CRMStudents' });
      }

      if (!val) viewStudent.value = null;
    });

    watch(openForm, (val) => {
      if (!val) editableStudent.value = null;
    });

    const isDesktop = inject('isDesktop');
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const getSubscriptions = async () => {
      try {
        const teacherId = user.value._id;
        const { data } = await LessonsPassTemplateApi.getLessonsPassTemplate({
          teacherId
        });
        const { resource = [] } = data;
        store.dispatch('setSubscriptions', resource);
      } catch (err) {
        console.error(err);
      }
    };
    onMounted(() => {
      if (crmStudentIdRoute.value && !isMobile.value) {
        openCard.value = crmStudentIdRoute.value;
      }
      setDisplayStudent();
      if (openFromRoute.value) openFormStudent();
      getSubscriptions();
    });

    return {
      isMobile,
      isDesktop,
      openCard,
      viewStudent,
      openForm,
      editableStudent,
      currentDisplayStudent,
      openFormStudent,
      openEditStudent
    };
  }
};
</script>
