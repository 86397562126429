<template>
  <div class="lp-user-cell">
    <img
      class="lp-user-cell__avatar"
      v-if="photo"
      :src="photo"
      alt="avatar"
    />
    <div
      class="lp-user-cell__avatar lp-user-cell__avatar_placeholder"
      v-else
    >
      <userIcon />
    </div>
    <span
      class="lp-user-cell__username"
      :class="{'lp-user-cell__username_mobile': isMobile}"
    >{{ username }}</span>
  </div>
</template>

<script>
import { userIcon } from '@/constants/icons';
import { computed } from 'vue';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'UserCell',
  components: {
    userIcon
  },
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const photo = computed(() => {
      const photoProps = get(props.column, 'photo', '');
      return  get(props.row, photoProps, '');
    });

    const username = computed(() => {
      return  get(props.row, props.column.prop, '');
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      username,
      photo,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-user-cell {
  display: grid;
  grid-template-columns: 34px auto;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;

  &:hover &__username {
    color: $color-accent;
    text-decoration: underline;
  }

  &__avatar {
    width: 34px;
    height: 34px;
    object-fit: cover;
    border-radius: 50%;

    &_placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__username {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;
    cursor: pointer;
    text-transform: capitalize;
    transition: 0.2s ease-out;
    max-width: 200px;
    word-break: break-word;

    &_mobile {
      font-size: 14px;
    }
  }
}

</style>
