<template>
  <div
    class="lp-webcam-resolution"
    :class="{'lp-webcam-resolution_mobile': isMobile}"
  >
    <div
      class="lp-webcam-resolution-head"
      @click.stop="$emit('update:open', false)"
    >
      <div class="lp-webcam-resolution-head__icon">
        <arrowLeftIcon />
      </div>
      <div class="lp-webcam-resolution-head__title">
        {{ $t('roomSettings.video.quality') }}
      </div>
    </div>
    <div class="lp-webcam-resolution-body">
      <div
        class="lp-webcam-resolution-item"
        :class="{'lp-webcam-resolution-item_active': currentResolution === +value}"
        @click="setResolution(value)"
        v-for="(value) of resolutions"
        :key="value"
      >
        <div
          class="lp-webcam-resolution-item__value"
          :class="{'lp-webcam-resolution-item__value_hd': +value >= 1080}"
        >
          {{ value }}p
        </div>
        <div class="lp-webcam-resolution-item__checked">
          <checkmarkIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { isEqual, get } from 'lodash';
import { arrowLeftIcon, checkmarkIcon } from '@/constants/icons';
import RESOLUTION from '@/constants/enums/resolution';

export default {
  name: 'WebcamResolution',
  components: {
    arrowLeftIcon,
    checkmarkIcon
  },
  props: {
    isMobile: Boolean,
    resolution: Object
  },
  emits: ['update:open', 'update:resolution'],
  setup (props, { emit }) {
    const currentResolution = computed(() => get(props.resolution, 'height.ideal', 480));

    const setResolution = (key) => {
      if (isEqual(RESOLUTION[key], props.resolution)) return;
      emit('update:open', false);
      emit('update:resolution', RESOLUTION[key]);
    };

    const resolutions = computed(() => {
      // const { supportResolutions = [] } = videoSettings.value;
      // supportResolutions.length ? supportResolutions : [480];
      return Object.keys(RESOLUTION).sort((a,b) => b - a);
    });

    return {
      currentResolution,
      resolutions,
      setResolution
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-webcam-resolution {
  position: absolute;
  background-color: $color-white;
  right: -224px;
  bottom: 0;
  width: 214px;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba($color-black, 0.05);
  border-radius: 4px;
  color: $color-text;
  z-index: 10;

  &_mobile {
    position: fixed;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    height: auto;
    width: 100vw;
    z-index: 101;
  }

  &-head {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: start;
    grid-template-columns: auto auto;
    grid-gap: 8px;
    color: $color-text;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    padding: 16px 12px;
    border-bottom: 1px solid $color-alto;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    user-select: none;

    &__icon {
      transform: scale(-1);
    }
  }

  &-body {
    display: grid;
    grid-gap: 8px;
    padding: 8px 6px;
    white-space: nowrap;
  }

  &-item {
    display: grid;
    grid-gap: 21px;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px 12px;
    border-radius: 3px;
    background-color: $color-white;
    white-space: nowrap;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    color: $color-text;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;

    &_active &__checked {
      transform: scale(1);
    }

    &__checked {
      transform: scale(0);
      transition: 0.3s ease-in;
    }

    &:hover {
      background-color: $color-moon-raker;
    }

    &__value {
      font-weight: 500;
      font-size: 13px;
      line-height: 125%;
      color: $color-text;

      &_hd {
        position: relative;

        &:after {
          content: 'HD';
          position: absolute;
          top: 0;
          right: auto;
          color: $color-red;
          font-weight: 500;
          font-size: 8px;
          line-height: 125%;

        }
      }
    }
  }
}

</style>
