<template>
  <div
    class="lp-select"
    :class="{
      'lp-select_large': largeLabel,
      'lp-select_string-style': stringStyle
    }"
    v-click-outside="isMobile ? () => {} : closeDropList"
  >
    <span
      class="lp-label lp-select__label"
      :class="{
        'lp-label_large': largeLabel,
        'lp-label_required': required,
        'lp-label_required_without-label': required && withoutLabel
      }"
      v-if="!stringStyle && !selectNoLabel"
    >
      {{ withoutLabel ? '' : $t(label || 'placeholder.select') }}
    </span>
    <div
      ref="selectHeader"
      class="lp-select-header"
      :class="{
        'lp-input_error': error,
        'lp-input': !stringStyle,
        'lp-select-header_string-style': stringStyle,
        'lp-select-header_status-complete': isLessonComplete,
        'lp-select-header_no-label': selectNoLabel
      }"
      @click="changeOpenDropList(!openDropList)"
    >
      <slot
        :keyProp="keyProp"
        :value="selectedValue"
        name="header"
      >
        {{ translate ? $t(selectedValue[keyProp]) : selectedValue[keyProp] || placeholder }}
      </slot>
      <input
        v-if="(searchable || isGroupLesson) && openDropList && !isMobile"
        :disabled="isMobile"
        @keyup.esc="closeDropList"
        @click.stop
        v-focus="openDropList"
        class="lp-select-header__input"
        :class="{
          'lp-select-header__input_start-search': openDropList,
          'lp-select-header__input_searching': searchItem,
          'lp-select-header__input_start-search_group': isGroupLesson && !isMobile
        }"
        type="text"
        v-model="searchItem"
      >
      <arrowIcon
        v-if="!isLessonComplete && !selectNoLabel"
        class="lp-select-header__icon"
        :class="{'lp-select-header__icon_open': openDropList}"
      />
      <transition name="bounce">
        <span
          class="lp-error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
    <teleport to="#app">
      <transition name="fade">
        <div
          class="lp-select-list__background"
          v-if="isMobile && openDropList"
          @click="closeDropList"
        />
      </transition>
    </teleport>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition :name="isMobile ? 'slide-select-mobile' : 'slide-select'">
        <div
          class="lp-select-list"
          :class="{
            'lp-select-list_mobile': isMobile,
            'lp-select-list_mobile_search': isMobile && searchable,
            'lp-select-list_mobile_height': isMobile && isGroupLesson,
            'lp-select-list_width': widthAuto,
            'lp-select-list_top': topOffset && !isMobile
          }"
          @click.stop
          :options="scrollbarSettings"
          :style="[
            getStyleDropList(),
            {
              'maxHeight': maxHeight,
              'minHeight': minHeight
            }
          ]"
          v-if="openDropList"
        >
          <div
            v-if="!currentOptions.length && keyProp === 'fullName'"
            class="lp-select-list_not-found"
          >
            {{ $t('crm.lessons.form.notFoundStudent') }}
          </div>
          <div
            class="lp-select-header-mobile-search"
            v-if="searchable && openDropList && isMobile"
            @click.stop
          >
            <input
              @keyup.esc="closeDropList"
              @click.stop
              v-focus="!isMobile && openDropList"
              class="lp-select-header-mobile-search__input lp-input"
              type="text"
              :placeholder="$t(placeholder)"
              :value="searchItem"
              @input="onChangeSearchValue($event)"
            >
            <searchIcon class="lp-select-header-mobile-search__icon" />
          </div>
          <div
            class="lp-select-list-item"
            :class="{
              'lp-select-list-item_mobile': isMobile,
              'lp-select-list-item-add': item._id === 'createUser',
              'lp-select-list-item-add_lower': item._id === 'createUser' && isMobile,
              'lp-select-list-item-add_mt': searchItem && isMobile
            }"
            v-for="(item, index) of currentOptions"
            :key="`key-${index}-${item.keyProp}`"
            @click.stop="setSelected(item)"
            :style="item.style"
          >
            <slot
              name="item"
              :keyProp="keyProp"
              :item="item"
            >
              {{ translate ? $t(item[keyProp]) : item[keyProp] }}
            </slot>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch, computed } from 'vue';
import { arrowIcon, dangerIcon } from '@/constants/icons';
import { cloneDeep } from 'lodash';
import MobileDetect from 'mobile-detect';
import { searchIcon } from '@/constants/icons';

export default {
  name: 'CustomSelect',
  components: {
    arrowIcon,
    dangerIcon,
    searchIcon
  },
  props: {
    stringStyle: Boolean,
    translate: Boolean,
    maxHeight: String,
    minHeight: String,
    largeLabel: Boolean,
    keyProp: String,
    lessonStatus: String,
    options: Array,
    label: String,
    modelValue: Object,
    required: Boolean,
    searchable: Boolean,
    error: [Boolean, String],
    withoutLabel: Boolean,
    fixed: Boolean,
    stopFullScreen: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    selectNoLabel: Boolean,
    widthAuto: Boolean,
    isGroupLesson: Boolean,
    topOffset: Boolean,
    notHideList: Boolean,
    showList: Boolean,
    selectedValues: Array
  },
  emits: ['focus', 'handler-change', 'update:modelValue'],
  setup (props, { emit }) {
    const isLessonComplete = computed(() => props.lessonStatus === 'Complete');
    const scrollbarSettings = {
      wheelPropagation: false,
      minScrollbarLength: 25
    };

    const currentOptions = ref(cloneDeep(props.options));
    watch(props, () => {
      currentOptions.value = cloneDeep(props.options);
    });

    const openDropList = ref(false);
    watch(openDropList, (val) => {
      const el = document.querySelector('#app');
      const body = document.querySelector('body');
      if (!el || !body || !isMobile.value) return;
      el.style.overflow = val ? 'hidden' : 'visible';
      body.style.overflow = val ? 'hidden' : 'visible';
      el.style.height = val ? '90vh' : '';
    });
    const changeOpenDropList = (val) => {
      openDropList.value = val;
      emit('focus');
    };
    const closeDropList = () => {
      openDropList.value = false;
    };

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value || !props.fixed || isMobile.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const searchItem = ref('');
    const onChangeSearchValue = (e) => {
      searchItem.value = e.target.value;
    };
    const filterOptions = () => {
      if (!searchItem.value) {
        currentOptions.value = cloneDeep(props.options);
      } else {
        const search = searchItem.value.toLowerCase();
        const result = cloneDeep(props.options).filter((item) => {
          const value = String(item[props.keyProp]).toLowerCase();
          return value.includes(search);
        });
        try {
          const reg = new RegExp(`^${search}`, 'i');
          result.sort((a, b) => reg.test(b[props.keyProp]) - reg.test(a[props.keyProp]));
          currentOptions.value = result;
        } catch (e) {
          currentOptions.value = cloneDeep(props.options);
        }
      }
    };
    watch(searchItem, filterOptions);

    const selectedValue = ref({});
    const setSelected = (item) => {
      if (!item) return;
      const { handlerChange } = item;
      searchItem.value = '';
      if (!props.notHideList) {
        openDropList.value = false;
      }
      const isEmpty = !props.selectedValues || props.selectedValues && !props.selectedValues.length;
      if (props.showList && !isMobile.value && isEmpty) {
        openDropList.value = true;
      }
      if (item._id === 'createUser') {
        openDropList.value = false;
      }
      if (handlerChange) {
        handlerChange(item);
      } else {
        selectedValue.value = item;
        emit('handler-change', item);
        emit('update:modelValue', item);
      }
    };

    onBeforeMount(() => {
      setSelected(props.modelValue);
    });

    watch(() => props.modelValue, () => {
      setSelected(props.modelValue);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => props.stopFullScreen ? false : !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      scrollbarSettings,
      selectedValue,
      openDropList,
      selectHeader,
      searchItem,
      currentOptions,
      getStyleDropList,
      setSelected,
      changeOpenDropList,
      closeDropList,
      isLessonComplete,
      onChangeSearchValue
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-select {
  @include global-font;
  position: relative;
  display: grid;
  grid-gap: 4px;
  color: $color-text;
  user-select: none;

  &_large {
    grid-gap: 12px;
  }

  &-header {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: auto 23px;
    height: 100%;
    min-height: 42px;
    padding: 5px 14px 5px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: color 0.3s ease-in, font-size 0.3s ease-in, padding 0.3s ease-in;

    &_string-style {
      font-size: 14px;
      color: $color-accent;
      padding: 0;
    }

    &_no-label {
      border: none;

      @media (max-width: 990px) {
        background: transparent;
      }
    }

    &_status-complete {
      opacity: 0.7;
      pointer-events: none;
    }

    &:hover &__icon{
      opacity: 0.8;
    }

    &__icon {
      justify-self: end;
      padding: 11px;
      margin: -11px;
      grid-column: 2;
      box-sizing: content-box;
      transform: rotate(180deg);
      transition: transform 0.15s ease-in;

      &_open {
        transform: rotate(0deg);
      }
    }

    &-mobile-search {
      position: sticky;
      top: -4px;
      width: auto;
      padding: 8px 24px 0;
      background-color: $color-white;
      display: flex;
      align-items: center;
      z-index: 11;

      &__input {
        height: 42px;
        padding-left: 32px;

        &_width {
          width: auto;
        }
      }

      &__icon {
        position: absolute;
        left: 36px;
        top: auto;
      }
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      padding: 11px 14px 11px 12px;
      width: 100%;
      height: 100%;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      opacity: 0;
      border: none;

      &_start-search {
        opacity: 0.5;

        &_group {
          position: initial;
          height: 40px;
          margin-top: 10px;
          border: 1px solid $color-gallery;
          border-radius: 4px;
        }
      }

      &_searching {
        opacity: 1;
      }
    }
  }

  &-list {
    @include global-font;
    position: absolute;
    top: 65px;
    left: 0;
    display: grid;
    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
    height: auto;
    padding: 4px 0;
    background: $color-white;
    box-shadow: 0 0 0 1px $color-wild-sand, 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    z-index: 100;
    overflow: auto;
    max-height: 194px;

    &_width {
      width: auto;
    }

    &_mobile {
      position: fixed;
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      z-index: 103;
      border-radius: 0;
      grid-template-rows: repeat(auto-fit, 38px);
      padding: 8px 0;

      &_search {
        grid-template-rows: 50px repeat(auto-fit, 38px);
      }

      &_height {
        min-height: 300px;
      }
    }

    &_not-found {
      padding: 10px 16px;
      font-size: 14px;
    }

    &__background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: rgba($color-black, 0.5);
    }

    &-item {
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      margin: 0 8px;
      background-color: $color-white;
      transition: color 0.3s ease-in, background-color 0.3s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-accent;
        background-color: $color-moon-raker;
      }

      &_mobile {
        padding: 12px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-add {
        position: sticky;
        top: -1px;
        width: auto;
        background-color: $color-white;
        z-index: 10;

        &_lower {
          top: 45px;
        }

        &_mt {
          margin-top: 15px;
        }
      }
    }
  }
}

.lp-multiselect .lp-select {
  &-header {
    display: block;

    &__icon {
      display: none;
    }
  }

  &-list {
    top: calc(100% + 10px);
  }
}

.lp-select-list {
  &_top {
    top: calc(100% + 22px) !important;
  }
}
</style>
