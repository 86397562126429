const strengthscrm = [
  {
    id: 1,
    image: 'strengthscrm-image1',
    name: 'landing.strengthscrm.firstItem.name'
  },
  {
    id: 2,
    image: 'strengthscrm-image2',
    name: 'landing.strengthscrm.secondItem.name'
  },
  {
    id: 3,
    image: 'strengthscrm-image3',
    name: 'landing.strengthscrm.thirdItem.name'
  },
  {
    id: 4,
    image: 'strengthscrm-image4',
    name: 'landing.strengthscrm.fourthItem.name'
  },
  {
    id: 5,
    image: 'strengthscrm-image5',
    name: 'landing.strengthscrm.fifthItem.name'
  },
  {
    id: 6,
    image: 'strengthscrm-image6',
    name: 'landing.strengthscrm.sixItem.name'
  }
];
export default strengthscrm;
