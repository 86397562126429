<template>
  <Modal
    v-model:open="isShowModal"
    :title="'timetable.modal.title'"
    :removeBtnText="'buttons.cancel'"
    :saveBtnText="saveBtnText"
    :removeScrollOnMobile="true"
    @save="onButtonSave"
    @remove="closeModal"
    :editMode="isMobile"
    :mobileVersion="isMobile"
    :customClass="'lp-crm-timetable-item-modal'"
  >
    <div
      class="lp-crm-timetable-item-modal__container"
      :class="{'lp-crm-timetable-item-modal__container_mobile': isMobile}"
    >
      <div class="lp-crm-timetable-item-modal__title">
        {{ $t('timetable.modal.bodyTitle') }}
      </div>
      <div class="lp-crm-timetable-item-modal-time-picker">
        <div
          class="lp-crm-timetable-item-modal-time-picker__item"
          :class="{
            'lp-crm-timetable-item-modal-time-picker_hide':
              dropdownStatusStart === 'closed'
          }"
        >
          <div
            v-if="dropdownStatusStart === 'opened'"
            class="lp-crm-timetable-item-modal-time-picker-buttons"
          >
            <div
              class="lp-crm-timetable-item-modal-time-picker-buttons__item"
              @click="onClose(true)"
            >
              <closeModalIcon />
            </div>
            <div
              class="lp-crm-timetable-item-modal-time-picker-buttons__item"
              @click="onOk(true)"
            >
              <okIcon />
            </div>
          </div>
          <VueTimepicker
            :key="keyInputStart"
            :placeholder="$t('timetable.modal.timePickerStart')"
            :minuteInterval="5"
            :hourRange="hoursRange"
            :minuteRange="minuteRange"
            :hideClearButton="true"
            :hideDropdown="true"
            @open="dropdownStatusStart = 'opened'"
            @close="onClose(true)"
            v-model="startTime"
            hideDisabledHours
            inputWidth="100%"
            fixedDropdownButton
          >
            <template v-slot:dropdownButton>
              <clockIcon />
            </template>
          </VueTimepicker>
        </div>
        <div class="lp-crm-timetable-item-modal__dash" />
        <div
          class="lp-crm-timetable-item-modal-time-picker__item"
          :class="{
            'lp-crm-timetable-item-modal-time-picker_hide':
              dropdownStatusEnd === 'closed'
          }"
        >
          <div
            v-if="dropdownStatusEnd === 'opened'"
            class="lp-crm-timetable-item-modal-time-picker-buttons"
          >
            <div
              class="lp-crm-timetable-item-modal-time-picker-buttons__item"
              @click="onClose(false)"
            >
              <closeModalIcon />
            </div>
            <div
              class="lp-crm-timetable-item-modal-time-picker-buttons__item"
              @click="onOk(false)"
            >
              <okIcon />
            </div>
          </div>
          <VueTimepicker
            :key="keyInputEnd"
            :placeholder="$t('timetable.modal.timePickerEnd')"
            :minuteInterval="5"
            :hourRange="hoursRangeEnd"
            :minuteRange="minuteRangeEnd"
            v-model="endTime"
            @open="dropdownStatusEnd = 'opened'"
            @close="onClose(false)"
            :hideClearButton="true"
            hideDisabledHours
            inputWidth="100%"
            fixedDropdownButton
          >
            <template v-slot:dropdownButton>
              <clockIcon />
            </template>
          </VueTimepicker>
          <transition name="bounce">
            <div
              class="lp-crm-timetable-item-modal__error lp-error"
              v-if="error"
            >
              <dangerIcon />
              {{ $t(error) }}
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="!isUpdate"
        class="lp-crm-timetable-item-modal__repeat"
      >
        <CustomSelect
          :options="options"
          :fixed="true"
          :label="'timetable.modal.timeSelectReminder'"
          :keyProp="'key'"
          maxHeight="150px"
          v-model="reminder"
        >
        </CustomSelect>
      </div>
      <div
        v-if="isUpdate ? false : !isNoRepeat"
        class="lp-crm-timetable-item-modal__date-picker"
      >
        <DatePicker
          :label="'timetable.modal.timeDataPicker'"
          :manualEditableDate="true"
          :hideTime="true"
          :fixed="true"
          :minDate="currentDate"
          :modelValue="selectedDate"
          v-model:modelValue="selectedDate"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';
import VueTimepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import {
  clockIcon,
  closeModalIcon,
  okIcon,
  dangerIcon
} from '@/constants/icons';
import TIME_TABLE_MODAL_REMINDER from '@/constants/enums/timeTable';
import { get, map } from 'lodash';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import DatePicker from '@/components/Main/Inputs/DatePicker/DatePicker';
import moment from 'moment';
import TimetableApi from '@/api/CRM/timetable';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  name: 'TimetableEditableItemModal',
  components: {
    Modal,
    VueTimepicker,
    clockIcon,
    closeModalIcon,
    okIcon,
    dangerIcon,
    CustomSelect,
    DatePicker
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    openModal: Boolean
  },
  emits: ['update:openModal', 'update:item'],
  setup (props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const timetable = computed(() => store.getters.timetable);
    const setTimetable = (data) => store.dispatch('setTimetable', data);

    const isShowModal = ref(false);
    const closeModal = () => {
      isShowModal.value = false;
    };

    watch(isShowModal, (value) => {
      emit('update:openModal', value);
    });
    watch(
      () => props.openModal,
      (value) => {
        isShowModal.value = value;
        setDefaultValue();
      }
    );

    const setDefaultValue = () => {
      const { ruleStartDate, ruleEndDate, time, fullDate } = props.item;
      startTime.value = ruleStartDate || time;
      endTime.value = ruleEndDate || end();
      isUpdate.value = get(props.item, 'id', '');
      currentDate.value = '';
      selectedDate.value.date[0] = moment(fullDate).format(isoDate);
      dropdownStatusStart.value = 'closed';
      dropdownStatusEnd.value = 'closed';
      reminder.value = options[0];
      error.value = '';
    };
    const error = ref('');
    const hoursRange = [
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23
    ];
    const minuteRange = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
    const hoursRangeEnd = ref(hoursRange);
    const minuteRangeEnd = ref(minuteRange);

    const end = () => {
      const { ruleEndDate, fullDate } = props.item;
      const maxEndTime = moment(fullDate).format('HH:mm');
      const time = moment(fullDate).
        add(45, 'minutes').
        utc().
        format();
      return ruleEndDate
        ? ruleEndDate
        : maxEndTime > '23:10'
          ? '23:55'
          : moment(time).format('HH:mm');
    };
    const startTime = ref(props.item.ruleStartDate);
    const endTime = ref(props.item.ruleEndDate);
    const keyInputStart = ref(1);
    const keyInputEnd = ref(1);

    const rerenderTimePicker = (item) => {
      setTimeout(() => {
        item.value = item.value + 1;
      }, 0);
    };

    const onClose = (isStart) => {
      if (isStart) {
        if (dropdownStatusStart.value === 'closed') return;
        dropdownStatusStart.value = 'closed';
        startTime.value = props.item.time;
        rerenderTimePicker(keyInputStart);
      } else {
        if (dropdownStatusEnd.value === 'closed') return;
        dropdownStatusEnd.value = 'closed';
        endTime.value = end();
        rerenderTimePicker(keyInputEnd);
      }
    };

    const onOk = (isStart) => {
      if (isStart) {
        dropdownStatusStart.value = 'closed';
        rerenderTimePicker(keyInputStart);
      } else {
        dropdownStatusEnd.value = 'closed';
        rerenderTimePicker(keyInputEnd);
      }
    };

    const dropdownStatusStart = ref('closed');
    const dropdownStatusEnd = ref('closed');

    const options = map(TIME_TABLE_MODAL_REMINDER, (item) => ({
      key: t(item.title),
      ...item
    }));
    const reminder = ref(options[0]);
    const isNoRepeat = computed(
      () => reminder.value.id === TIME_TABLE_MODAL_REMINDER.NO_REPEAT.id
    );

    const isoDate = 'YYYY-MM-DD';
    const currentDate = computed(() => {
      const date = moment(props.item.fullDate);
      const startDate = date.format(isoDate);
      return startDate;
    });
    const selectedDate = ref({ date: [currentDate.value] });

    const isUpdate = computed(() => get(props.item, 'id', ''));
    const saveBtnText = computed(() =>
      isUpdate.value ? 'buttons.save' : 'buttons.add'
    );

    watch(
      () => endTime.value,
      () => {
        error.value = '';
      }
    );

    const createBody = computed(() => {
      const { fullDate, time } = props.item;
      const day = moment(fullDate).format(isoDate);
      const startTimeDefault =
        dropdownStatusStart.value === 'opened' ? time : startTime.value;
      const body = {
        ruleStartDate: moment(`${day}T${startTimeDefault}`, 'YYYY-MM-DDTHH:mm').
          utc().
          format(),
        ruleEndDate: moment(`${day}T${endTime.value}`, 'YYYY-MM-DDTHH:mm').
          utc().
          format(),
        repeatRate: reminder.value.id,
        repeatEndDate: `${selectedDate.value.date[0]}T00:00:00Z`
      };
      const { ruleStartDate, ruleEndDate } = body;
      const bodyUpdate = { ruleStartDate, ruleEndDate };
      return isUpdate.value ? bodyUpdate : body;
    });

    const onButtonSave = async () => {
      const newStartTime = moment(startTime.value, 'HH:mm').
        add(10, 'minutes').
        format('HH:mm');
      if (
        startTime.value >= endTime.value ||
        (endTime.value > startTime.value && endTime.value <= newStartTime)
      ) {
        error.value = 'errors.correctDate';
        return;
      }
      try {
        await store.dispatch('setLoaderRun', true);
        const name = isUpdate.value ? 'updateTimetable' : 'createTimetable';
        const { data } = await TimetableApi[name](
          createBody.value,
          props.item.id
        );
        if (isUpdate.value) {
          const { updatedRule, deletedRulesIdArray } = data;
          const array = timetable.value.map((el) => {
            if (el._id === props.item.id) {
              return updatedRule;
            }
            return el;
          });
          const newArray = array.filter(
            (el) => !deletedRulesIdArray.includes(el._id)
          );
          await setTimetable(newArray);
        } else {
          const { createdRules, deletedRulesIdArray } = data;
          const newArray = timetable.value.filter(
            (el) => !deletedRulesIdArray.includes(el._id)
          );
          await setTimetable([...newArray, ...createdRules]);
        }
        setDefaultValue();
        closeModal();
        await store.dispatch('setLoaderRun', false);
      } catch (e) {
        console.error(e);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isShowModal,
      isMobile,
      saveBtnText,
      options,
      reminder,
      selectedDate,
      currentDate,
      isNoRepeat,
      dropdownStatusStart,
      dropdownStatusEnd,
      startTime,
      keyInputEnd,
      keyInputStart,
      endTime,
      isUpdate,
      hoursRange,
      hoursRangeEnd,
      minuteRangeEnd,
      minuteRange,
      error,
      onClose,
      onOk,
      closeModal,
      onButtonSave
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-timetable-item-modal {
  &__container {
    height: 100%;
    padding: 40px 20px 0;

    &_mobile {
      padding: 20px 20px 0;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 5px 0;
  }

  &-time-picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 28px 0;
    width: 100%;

    &-buttons {
      background: $color-white;
      height: 36px;
      width: 100%;
      position: absolute;
      top: 226px;
      left: 2px;
      border-top: 1px solid $color-alto;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      z-index: 10;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__item {
      position: relative;
      cursor: pointer;
      width: 47%;
    }
  }

  &__date-picker {
    margin: 28px 0 0 0;
  }

  &__dash {
    width: 8px;
    height: 1px;
    background: $color-mine-shaft;
  }

  &__error {
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-crm-timetable-item-modal {
  .lp-modal-content-body {
    min-width: 468px;
    position: relative;
    z-index: 2;
    overflow: visible;

    &_mobile {
      min-width: auto;
    }
  }

  .vue__time-picker {
    input {
      border-radius: 4px;
      border-color: $color-silver-chalice-dark;
      height: 2.7em;

      &:hover {
        cursor: pointer;
      }
    }

    .has-custom-btn {
      position: relative;
      left: -8px;
    }

    .dropdown {
      left: 1px;
      top: 50px;
      width: 99%;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
      height: 11em;

      .select-list {
        height: 11em;
      }
    }

    .hint {
      display: none;
    }

    .active {
      background: $color-purple !important;

      &:hover {
        background: $color-purple !important;
      }
    }

    .dropdown ul li {
      &:hover {
        background: $color-white !important;
        color: $color-black !important;
      }
    }
    .dropdown ul .active {
      &:hover {
        background: $color-purple !important;
        color: $color-white !important;
      }
    }
  }

  &-time-picker {
    &-buttons__item {
      &:first-child {
        svg path {
          fill: $color-red;
        }
      }
    }

    &_hide {
      .dropdown {
        display: none;
      }
    }
  }
}
</style>
