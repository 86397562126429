<template>
  <div>
    <Modal
      :title="student ? student.fullName : 'crm.students.form.title'"
      :mobileVersion="mobileVersion"
      :removeBtnText="'buttons.cancel'"
      :editMode="true"
      :zIndex="zIndex"
      @save="saveForm"
      @remove="cancelForm"
      v-model:open="openModal"
    >
      <div
        class="lp-crm-student-form"
        :class="{'lp-crm-student-form_mobile': isMobile}"
      >
        <StudentAvatar
          v-model:file="studentAvatar"
          :student="student"
        />
        <Form
          @submit="saveForm"
          :inputs="studentInputs"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import inputs from '@/constants/inputs/studentInputs';
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { computed, inject, ref, watch } from 'vue';
import StudentAvatar from '@/components/CRM/Students/StudentForm/StudentAvatar';
import validateProfile from '@/constants/validatesForm/validateProfile';
import { get, isEmpty, set } from 'lodash';
import moment from 'moment';
import getFileBinary from '@/constants/utils/getFileBinary';
import AvatarsApi from '@/api/CRM/avatars';
import StudentApi from '@/api/CRM/students';
import { getErrorText } from '@/constants/utils/setErrorText';
import uniqid from 'uniqid';
import { FRONTEND_URL } from '@/constants/domains';
import { useStore } from 'vuex';
import getStudentObject from '@/constants/utils/CRM/getStudentObject';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import MobileDetect from 'mobile-detect';
import timeout from '@/constants/utils/timeout';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';
import country from '@/constants/country';

export default {
  name: 'StudentForm',
  components: { StudentAvatar, Modal, Form },
  props: {
    openForm: Boolean,
    student: Object,
    zIndex: Number
  },
  emits: ['update:open'],
  setup (props, { emit }) {
    const store = useStore();
    const teacher = computed(() => store.getters.user);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const students = computed(() => store.getters.students);
    const settings = computed(() => store.getters.settings);

    const mobileVersion = computed(() => checkMobileByScreen());

    const studentAvatar = ref(null);
    const studentInputs = computed(() => {
      const { currentCurrency, defaultCurrency } = settings.value;
      const currency = inputs.find(({ modelName }) => modelName === 'currency');
      if (currency) {
        currency.modelValue = currentCurrency || defaultCurrency;
        currency.defaultValue = currentCurrency || defaultCurrency;
      }
      return inputs;
    });

    const openModal = ref(false);

    watch(() => props.openForm, (value) => {
      hideContainerForMobile(value);
      openModal.value = value;
    });

    watch(openModal, (value) => {
      emit('update:open', value);

      if (value) return;
      studentInputs.value.forEach(input => {
        if (input.type !== 'phone') return;
        if (!input.numberBusy.isInvalid) return;
        input.numberBusy.isInvalid = false;
      });
    });

    const setBirthday = (body) => {
      const birthday = get(body, ['birthday', 'date', 0], '');
      const birthdayMoment = moment(birthday);
      if (birthdayMoment.isValid()) {
        const date = birthdayMoment.format('YYYY-MM-DD') + 'T00:00:00';
        set(body, 'birthDate', date);
      }
    };

    const setNotification = (body) => {
      const input = studentInputs.value.find(item => item.hasOwnProperty('birthdayNotify'));
      const value = get(input, ['birthdayNotify'], true);
      set(body, 'remindAboutBirthday', value);
    };

    const setSocialMedia = (body) => {
      const { socialMedia } = body;
      const socialMediaArray = [];
      socialMedia.forEach((social) => {
        socialMediaArray.push({ ...social, _id: undefined });
      });
      body.socialMedia = socialMediaArray;
    };

    const errorText = ref(false);
    const uploadFile = async (file, body) => {
      if (!file) return;
      const fileBinary = getFileBinary(file);
      try {
        const { data } = await AvatarsApi.uploadFile(fileBinary);
        body.uploadPhoto = data._id;
        return data;
      } catch (err) {
        console.error(err);
        errorText.value = getErrorText(err);
      }
    };

    const countryCode = inject('countryCode');

    const updateInputs = () => {
      studentInputs.value.forEach(input => {
        if (input.type !== 'phone') return;
        input.numberBusy.isInvalid = true;
      });
    };

    const addNewStudent = async (body) => {
      body.virtualClassUrl = `${FRONTEND_URL}/room/${uniqid()}`;
      body.countryCode = countryCode;
      try {
        const { data } = await StudentApi.createStudent(body);
        yandexTracker.addStudent();
        const student = getStudentObject(data);
        students.value.unshift(student);
        emit('update-students-list', student);
        closeForm();
      } catch (err) {
        console.error(err);
        updateInputs();
        errorText.value = getErrorText(err);
      }
    };

    const updateStudent = async (body) => {
      try {
        const { data } = await StudentApi.updateStudent(body);
        const student = getStudentObject(data);
        const index = students.value.findIndex(item => item._id === student._id);
        if (index !== -1) students.value.splice(index, 1, student);
        closeForm();
      } catch (err) {
        console.error(err);
        updateInputs();
        errorText.value = getErrorText(err);
      }
    };

    const saveForm = async () => {
      const body = await validateProfile(studentInputs.value);
      if (!body) return;
      await setLoaderRun(true);
      setBirthday(body);
      setNotification(body);
      setSocialMedia(body);
      if (studentAvatar.value) await uploadFile(studentAvatar.value, body);
      body.teacherId = teacher.value._id;
      body._id = get(props, 'student._id', false);
      body._id ? await updateStudent(body) : await addNewStudent(body);
      await setLoaderRun(false);
      emit('add-student', body);
    };

    const cancelForm = () => {
      closeForm();
    };

    const closeForm = () => {
      openModal.value = false;
      setDefaultValues(true);
    };

    const setDefaultValues = async (clear) => {
      const target = clear ? {} : props.student;
      studentInputs.value.forEach((input) => {
        const value = get(target, input.modelName, input.defaultValue);
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
        if (input.additionalComponent === 'BirthdayNotify') {
          input.birthdayNotify = get(target, 'remindAboutBirthday', input.birthdayNotify);
        } else if (input.type === 'phone') {
          if (isEmpty(input.modelValue)) {
            input.modelValue = {
              number: '',
              country: ''
            };
          } else {
            const { country: code  } = input.modelValue;
            const placeholder = get(country.find((item => item.code === code)), 'mask', '');
            input.modelValue = {
              ...input.modelValue,
              placeholder
            };
          }
        }
      });
      studentAvatar.value = null;
    };

    watch(() => props.student, () => setDefaultValues());

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    const hideContainerForMobile = async (val) => {
      if (!isMobile.value) return;
      const el = document.querySelector('.mc-container');
      if (!el || !isMobile.value) return;
      await timeout(500);
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
    };

    return {
      isMobile,
      studentAvatar,
      openModal,
      studentInputs,
      saveForm,
      cancelForm,
      closeForm,
      mobileVersion
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-student-form {
  padding: 18px 20px 0;
  display: grid;
  grid-gap: 20px;
  min-width: 490px;
  background-color: $color-white;

  &_mobile {
    min-width: 100vw;
    padding: 28px 12px 56px;
  }
}

</style>
