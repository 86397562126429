<template>
  <div class="lp-crm-settings-lang">
    <CustomSelect
      :options="options"
      :fixed="!selectWidthAuto"
      :label="'crm.settings.language'"
      :keyProp="'key'"
      :selectNoLabel="selectNoLabel"
      :widthAuto="selectWidthAuto"
      maxHeight="300px"
      v-model="currency"
    >
      <template #header="{keyProp, value}">
        <div
          class="lp-crm-settings-lang-country"
          :class="{'lp-crm-settings-lang-country_text': !value.icon}"
        >
          <img
            class="lp-crm-settings-lang-country__flag"
            v-if="value.icon"
            :alt="value[keyProp]"
            :src="value.icon"
          />
          {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-crm-settings-lang-country"
          :class="{'lp-crm-settings-lang-country_text': !item.icon}"
        >
          <img
            class="lp-crm-settings-lang-country__flag"
            v-if="item.icon"
            :alt="item[keyProp]"
            :src="item.icon"
          />
          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { map } from 'lodash';
import { onBeforeMount, ref, watch } from 'vue';
import LANGUAGES from '@/constants/enums/languages';
import { useI18n } from 'vue-i18n';

export default {
  name: 'SelectLang',
  components: { CustomSelect },
  props: {
    defaultValue: String,
    stringStyle: Boolean,
    selectNoLabel: Boolean
  },
  emits: ['update:currency'],
  setup (props, { emit }) {
    const { t } = useI18n();

    const selectWidthAuto = ref(true);

    const options = map(LANGUAGES, (item) => ({ key: t(item.title), ...item }));
    const currency = ref(options[0]);

    const emitValue = () => emit('update:currency', currency.value.id);

    const getCurrency = (val) => {
      return options.find(currency => currency.id === val);
    };

    watch(currency, emitValue);

    const setDefault = () => {
      const defaultValue = getCurrency(props.defaultValue);
      if (defaultValue) currency.value = defaultValue;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    return {
      options,
      currency,
      selectWidthAuto
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-lang {
  &-country {
    display: grid;
    grid-template-columns: 22px auto;
    grid-gap: 8px;
    align-items: center;

    &_text {
      grid-template-columns: auto;
    }

    &__flag {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

</style>
