<template>
  <div
    class="lp-student-balance"
    :class="{'lp-student-balance_mobile': isMobile}"
  >
    <div class="lp-student-balance-item">
      <span class="lp-student-balance-item__title">
        {{ $t('crm.students.balance.title') }}
      </span>
      <negativeBalanceIcon
        class="lp-student-balance-item__icon"
        v-if="isNegative"
      />
      <span
        class="lp-student-balance-item__number"
        :class="{'lp-student-balance-item__number_negative': isNegative}"
      >
        {{ balance }}
        <span class="lp-student-balance-item__currency">
          {{ settings.currentCurrency }}
        </span>
      </span>
    </div>
    <div
      class="lp-student-balance-buttons"
      :class="{'lp-student-balance-buttons_mobile': isMobile}"
    >
      <button
        class="lp-button lp-student-balance__button"
        :class="{'lp-student-balance__button_mobile': isMobile}"
        @click="$emit('update:payment', true)"
      >
        {{ $t('crm.students.balance.button') }}
      </button>
      <template v-if="!subscription || subscription.passStatus !== 'Active'">
        <span
          class="lp-student-balance-buttons__or"
          :class="{'lp-student-balance-buttons__or_mobile': isMobile}"
        >
          {{ $t('global.or') }}
        </span>
        <button
          class="lp-button lp-student-balance__button"
          :class="{'lp-student-balance__button_mobile': isMobile}"
          @click="$emit('update:showModalAddSubscription', true)"
        >
          {{ $t('crm.subscriptions.addSubscriptions') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import { negativeBalanceIcon } from '@/constants/icons/crm';
import numeral from 'numeral';
import { useStore } from 'vuex';
import FORMATS from '@/constants/formats';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentBalance',
  components: {
    negativeBalanceIcon
  },
  props: {
    student: Object,
    subscription: Object
  },
  emits: ['update:payment', 'update:showModalAddSubscription'],
  setup (props) {
    const store = useStore();
    const settings = computed(() => store.getters.settings);

    const isNegative = computed(() => {
      return get(props, 'student.balance', 0) < 0;
    });

    const balance = computed(() => {
      const balance = get(props, 'student.balance', 0);
      return balance ? numeral(balance).
        format(FORMATS.FINANCE) : 0;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      settings,
      balance,
      isNegative
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-student-balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  border-top: 1px solid $color-alto;
  border-bottom: 1px solid $color-alto;
  margin-bottom: 26px;

  &_mobile {
    margin: 0 -12px;
    padding: 25px 12px;
  }

  @media (max-width: 481px) {
    flex-wrap: wrap;
    padding: 15px 12px 15px 0;
  }

  &-item {
    display: flex;
    align-items: center;

    @media (max-width: 481px) {
      margin: 10px 12px;
    }

    &__icon {
      margin-right: 8px;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      margin-right: 8px;
      text-transform: uppercase;
      user-select: none;

      &:after {
        content: ':';
      }
    }

    &__number {
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      color: $color-text;

      &_negative {
        color: $color-red;
      }
    }

    &__currency {
      color: $color-text;
      font-size: 16px;
      line-height: 25px;
    }
  }

  &-buttons {
    display: flex;
    align-items: center;

    &__or {
      text-transform: uppercase;
      margin: 0 12px;

      &_mobile {
        font-size: 14px;
      }

      @media (max-width: 375px) {
        margin: 0 2vw;
      }
    }

    &_mobile {
      margin: 10px 0 10px 12px;
    }

    @media (max-width: 340px) {
      display: grid;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      text-align: center;
    }
  }

  &__button {
    white-space: nowrap;

    &_mobile {
      padding: 13px 12px;
      font-weight: bold;
      font-size: 10px;
      line-height: 14px;
    }

    @media (max-width: 375px) {
      flex-grow: 1;
    }
  }
}

</style>
