<template>
  <Modal
    v-model:open="openModal"
    :hideButtons="['remove']"
    :saveBtnText="modalContent.status ? 'buttons.ok' : 'buttons.repeat'"
    @closeModal="closeForm"
    @save="closeForm"
  >
    <div class="lp-payment">
      <div class="lp-payment-content">
        <img
          :src="modalContent.image"
          alt="img"
          class="lp-payment-content__img"
        >
        <h2 class="lp-payment-content__title">
          {{ modalContent.status ? $t('paymentSuccessModal.success') : $t('paymentInsufficient.title') }}
        </h2>
        <div class="lp-payment-content__description">
          {{
            modalContent.status ? $t('paymentSuccessModal.descriptionSecond') : modalContent.text
          }}
          {{ currency }}{{ modalContent.status ? sum : '' }}
          <br>
          {{ modalContent.status && receiptValue ? $t('paymentSuccessModal.sentToEmail') : '' }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { AMOUNT_PER_MONTH_NO_SNG, AMOUNT_PER_MONTH_SNG } from '@/constants/amountPerMonth';
import { computed, ref, watch } from 'vue';
import checkCountries from '@/constants/countries/checkCountries';
import { useStore } from 'vuex';

export default {
  name: 'PaymentSuccess',
  components: {
    Modal
  },
  props: ['sum', 'modalContent', 'currency', 'receiptValue'],
  emits: ['modal-close'],
  setup (props, { emit }) {
    const store = useStore();
    const countryCode = computed(() => store.getters.countryCode);

    const openModal = ref(true);
    const amountPerMonth = checkCountries(countryCode.value) ? AMOUNT_PER_MONTH_SNG : AMOUNT_PER_MONTH_NO_SNG;

    const closeForm = () => {
      openModal.value = false;
      emit('modal-close');
    };

    watch(openModal, () => {
      emit('update:openModal', openModal.value);
      closeForm();
    });

    return {
      closeForm,
      amountPerMonth,
      openModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

@media (max-width: 499px) {
  .lp-payment {
    padding: 0 33px;
  }
}

</style>