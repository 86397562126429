<template>
  <div
    class="section welcome"
    id="welcome"
  >
    <div class="welcome__container">
      <h1
        class=" welcome__title"
        :class="`welcome__title_${locale}`"
      >
        {{ $t('landing.welcomeTitle.firstText') }}
        <div>{{ $t('landing.welcomeTitle.secondText') }}</div>
        <br />
        {{ $t('landing.welcomeTitle.thirdText') }}
      </h1>

      <div class="welcome__description">
        {{ $t('landing.welcomeText') }}
      </div>
      <button
        class="welcome-btn lp-button"
        :class="`welcome-btn_${locale}`"
        @click.stop="onLogin"
      >
        {{ $t('landing.welcomeBtn') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Welcome',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const router = useRouter();
    const { locale } = useI18n({ useScope: 'global' });
    const onLogin = () => {
      router.push({
        name: 'Sign-up'
      });
    };

    return {
      locale,
      onLogin
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.welcome {
  width: calc(100% - 24px);
  max-width: 800px;
  margin: 0 12px;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 66px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-image: url('~@/assets/images/landing/welcome-background-mobile.jpg');

  @media (max-width: 375px) {
    background-size: auto 100%;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 400px;
    padding: 21px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  &__title {
    width: 100%;
    padding-top: 60px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;

    div {
      display: inline-block;
      background-color: $color-purple;
      line-height: 100%;
      padding: 0 6px 2px 7px;
      border-radius: 4px;
    }

    &_en {
      font-size: 27px;
    }
    &_ru {
      font-size: 27px;

      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
  }

  &__description {
    width: 100%;
    max-width: 970px;
    margin-top: 18px;
    margin-bottom: 48px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    @media (max-width: 375px) {
      font-size: 14px;
    }
  }
}

.welcome-btn {
  position: fixed;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  height: 60px;
  padding: 0px;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: 0.04em;
  box-shadow: 0px 4px 33px rgba($color-black, 0.2),
    0px 8px 10px rgba($color-purple, 0.18);

  &_en {
    width: calc(100% - 16px);
  }

  &_ru {
    width: calc(100% - 16px);
  }
}
</style>
