const CATEGORY_EXPENSES = {
  RENT: {
    id: 'RENT',
    title: 'crm.expenses.category.modal.select.rent'
  },
  SALARY: {
    id: 'SALARY',
    title: 'crm.expenses.category.modal.select.wage'
  },
  UTILITIES: {
    id: 'UTILITIES',
    title: 'crm.expenses.category.modal.select.communalPayments'
  },
  ADVERTISING: {
    id: 'ADVERTISING',
    title: 'crm.expenses.category.modal.select.advertising'
  },
  EQUIPMENT: {
    id: 'EQUIPMENT',
    title: 'crm.expenses.category.modal.select.equipment'
  },
  MATERIALS: {
    id: 'MATERIALS',
    title: 'crm.expenses.category.modal.select.educationalMaterials'
  },
  TAXES: {
    id: 'TAXES',
    title: 'crm.expenses.category.modal.select.tax'
  },
  OTHER: {
    id: 'OTHER',
    title: 'crm.expenses.category.modal.select.otherExpenses'
  }
};

export default CATEGORY_EXPENSES;