<template>
  <div
    class="lp-timetable-week-footer"
    :class="{'lp-timetable-week-footer_mobile': isMobile}"
    v-if="template"
  >
    <div
      class="lp-timetable-week-footer-item lp-timetable-week-footer-item_work-time"
      :class="{'lp-timetable-week-footer-item_work-time_mobile': isMobile}"
    >
      <span class="lp-timetable-week-footer-item__legend" />
      <span class="lp-timetable-week-footer-item__text">
        - {{ $t('timetable.template.workTime') }}
      </span>
    </div>
    <div
      class="lp-timetable-week-footer-item lp-timetable-week-footer-item_weekend-time"
      :class="{'lp-timetable-week-footer-item_weekend-time_mobile': isMobile}"
    >
      <span class="lp-timetable-week-footer-item__legend" />
      <span class="lp-timetable-week-footer-item__text">
        - {{ $t('timetable.template.weekendTime') }}
      </span>
    </div>
    <div
      class="lp-timetable-week-footer-item lp-timetable-week-footer-item_info"
      :class="{
        'lp-timetable-week-footer-item_transition': isMobile,
        'lp-timetable-week-footer-item_transition_display': isMobile && !short,
        'lp-timetable-week-footer-item_info_mobile': isMobile
      }"
    >
      <alertIcon />
      <span class="lp-timetable-week-footer-item__text">
        {{ $t('timetable.template.info') }}
      </span>
    </div>
    <transition name="bounce">
      <button
        class="lp-timetable-week-footer-button lp-button"
        v-if="template.length && !isMobile"
        @click="$emit('save')"
      >
        {{ $t('buttons.save') }}
      </button>
    </transition>
  </div>
</template>

<script>
import { alertIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';
import { computed } from 'vue';

export default {
  name: 'TimetableWeekFooter',
  components: {
    alertIcon
  },
  props: {
    short: Boolean,
    template: Array
  },
  emits: ['save'],
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-timetable-week-footer {
  position: sticky;
  bottom: 0;
  background-color: $color-white;
  padding: 10px 0 20px;
  display: grid;
  grid-template-columns: 141px auto 170px;
  grid-template-rows: 18px 16px;
  grid-gap: 16px 20px;
  z-index: 3;

  &_mobile {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    padding: 10px 0 0;
  }

  &-item {
    $this: &;
    display: grid;
    grid-template-columns: 30px auto;
    grid-gap: 6px;
    align-items: center;

    &__legend {
      width: 30px;
      height: 16px;
      background-color: $color-white;
      border-radius: 3px;
      box-sizing: border-box;
    }

    &__text {
      font-weight: normal;
      font-size: 14px;
      line-height: 125%;
      color: $color-sine-shaft;
    }

    &_work-time {
      grid-column: 1;
      grid-row: 1;

      &_mobile {
        grid-row: 2;
      }

      #{$this}__legend {
        background-color: $color-emerald;
      }
    }

    &_weekend-time {
      grid-column: 2;
      grid-row: 1;

      &_mobile {
        grid-row: 2;
      }

      #{$this}__legend {
        border: 1px solid $color-alto;
      }
    }

    &_info {
      grid-template-columns: 15px auto;
      grid-column: 1/3;
      grid-row: 2;

      &_mobile {
        grid-row: 1;
      }

      #{$this}__text {
        font-size: 12px;
      }
    }

    &_transition {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease;

      &_display {
        max-height: 50px;
      }
    }
  }

  &-button {
    grid-column: 3;
    grid-row: 1/3;
  }
}

</style>
