<template>
  <Swiper
    class="lp-hints-tours-mobile"
    :slidesPerView="1"
    :pagination="{
      clickable: true
    }"
    @swiper="onSwiper"
  >
    <SwiperSlide
      class="lp-hints-tours-mobile__swiper"
      v-for="slide in hints"
      :key="slide.id"
    >
      <div class="lp-hints-tours-mobile-top">
        <div class="lp-hints-tours-mobile-top-wrap">
          <img
            class="lp-hints-tours-mobile-top-wrap__img"
            :src="slide.imgMobile"
          />
        </div>
        <img
          class="lp-hints-tours-mobile-top__img"
          src="@/assets/images/hints/bg.png"
          alt=""
        />
      </div>
      <div class="lp-hints-tours-mobile-bottom">
        <div class="lp-hints-tours-mobile-bottom__title">
          {{ $t(slide.title) }}
        </div>
        <div class="lp-hints-tours-mobile-bottom__description">
          {{ $t(slide.descriptionMobile1 || slide.description1) }}
        </div>
        <div class="lp-hints-tours-mobile-bottom__description">
          {{ $t(slide.descriptionMobile2 || slide.description2) }}
        </div>
        <div class="lp-hints-tours-mobile-bottom-buttons">
          <div
            class="lp-hints-tours-mobile-bottom-buttons__item lp-button"
            @click="onNextSlide(slide.id)"
          >
            {{ $t('hints.buttonFurther') }}
          </div>
          <div
            class="lp-hints-tours-mobile-bottom-buttons__item
                   lp-hints-tours-mobile-bottom-buttons__item_last
                   lp-button lp-button_white"
            @click="onButtonClick"
          >
            {{ $t('hints.firstStep.buttonTwo') }}
          </div>
        </div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { ref } from 'vue';
import SwiperCore, { Pagination, Navigation } from 'swiper';
SwiperCore.use([Pagination, Navigation]);

export default {
  name: 'HintsToursMobile',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    hints: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const swiper = ref(null);
    const slider = ref(null);
    const activeIndex = ref(0);

    const onSwiper = (swiper) => {
      slider.value = swiper;
    };

    const onNextSlide = (id) => {
      slider.value.slideNext();
      if (id === props.hints.length) emit('on-button-click');
      activeIndex.value = slider.value.activeIndex;
    };

    const onButtonClick = () => {
      emit('on-button-click', true);
    };

    return {
      swiper,
      onNextSlide,
      onSwiper,
      onButtonClick
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-hints-tours-mobile {
  height: 100%;
  width: 100%;

  &__swiper {
    display: grid;
    grid-template-rows: 313px 1fr;
  }

  &-top {
    height: 247px;

    &-wrap {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 193px;
      background: $color-purple;
      position: relative;

      &__img {
        position: absolute;
        bottom: -14px;
        z-index: 1;
        max-height: 88%;

        img {
          object-fit: contain;
        }
      }
    }

    &__img {
      position: relative;
      top: -1px;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  &-bottom {
    padding: 0 28px 16px 28px;
    text-align: center;

    &__title {
      font-family: $global-font-bold;
      font-size: 22px;
      line-height: 28px;
      margin: 0 0 16px 0;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      padding: 0 11px;
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 60px 0 0 0;
      width: 100%;
      position: absolute;
      bottom: 16px;
      left: 0;
      right: 0;

      &__item {
        height: 50px;
        width: 90%;

        & + & {
          margin: 15px 0 0 0;
        }

        &_last {
          font-size: 12px;
          padding: 16px 0;
          box-shadow: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-hints-tours-mobile {
  .swiper-pagination-bullets {
    bottom: auto;
    top: 261px;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-bullet {
    margin: 0 9px 0 0 !important;
    background: $color-purple !important;

    &:last-child {
      margin: 0 0 0 0 !important;
    }
  }
}
</style>
