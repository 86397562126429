import RoundTripWorklet from '@/constants/_rtc/audioEngine/RoundTripWorklet';

const roundTripTest = (function () {
  let audioCtx;
  let roundTripNode;
  let latency = 0;
  let latencies = [];
  let checksCount = 0;
  let noiseBuffer;
  let bufferSource = null;
  let microphoneNode = null;
  let testStoped = false;
  let testInterval;
  let calibration = false;
  let callbacks = {
    tooLoud: function () {},
    tooSilent: function () {},
    success: function () {},
    setDelay: function () {},
    stoped: function () {}
  };

  function init (_audioCtx, stream) {
    audioCtx = _audioCtx;
    microphoneNode = audioCtx.createMediaStreamSource(stream);
    createNoiseBuffer();
  };

  function setDelayCallback (setDelay) {
    callbacks.setDelay = setDelay;
  };

  function setCallbacks (tooLoud, tooSilent, success, stoped) {
    callbacks.tooLoud = tooLoud;
    callbacks.tooSilent = tooSilent;
    if (success !== undefined) {
      callbacks.success = success;
    }
    if (stoped !== undefined) {
      callbacks.stoped = stoped;
    }
  };

  function stop () {
    testStoped = true;
    if (testInterval) {
      clearInterval(testInterval);
      testInterval = null;
    }
    disconnect();
  };

  function test () {
    if (!testStoped) {
      roundTripNode.port.postMessage({ header: 'start' });
      playSound(0);
    }
  };

  function disconnect () {
    roundTripNode.disconnect();
    microphoneNode.disconnect();
    roundTripNode = null;
  };

  function processLatency (value) {
    checksCount++;
    latency = value;
    latencies.push(latency);
    if (checksCount === 5) {
      latency = 0;
      latencies.forEach(element => {
        latency += element;
      });
      latency /= latencies.length;
      callbacks.setDelay(latency);
      callbacks.success();
      stop();
    }
    stopSound();
  };

  function processRms (value) {
    if (calibration) {
      calibration = false;
      if (value < 0.1) {
        testInterval = setInterval(() => {
          test();
        }, 800);
      } else {
        callbacks.tooLoud();
      }
    }
  };

  function setupMessagePort () {
    roundTripNode.port.onmessage = (e) => {
      console.log(e.data);
      if (e.data.header === 'rmsCurrent') {
      } else if (e.data.header === 'rmsAvg') {
        processRms(e.data.value);
      } else if (e.data.header === 'latency') {
        processLatency(e.data.value);
        return;
      } else if (e.data.header === 'levels') {
        roundTripNode.port.postMessage({ header: 'findLatency' });
      } else if (e.data.header === 'latencies') {
        let val = 128 * indexOfMax(e.data.arr) / e.data.sr;
        processLatency(val);
      }
    };
  };

  function indexOfMax (arr) {
    if (arr.length === 0) {
      return -1;
    }
    let max = arr[0];
    let maxIndex = 0;
    for (var i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        maxIndex = i;
        max = arr[i];
      }
    }
    return maxIndex;
  };

  function checkNoiseFloor () {
    calibration = true;
    roundTripNode.port.postMessage({ header: 'resetRms' });
    setTimeout(() => {
      roundTripNode.port.postMessage({ header: 'getRmsAvg' });
    }, 700);
  };

  async function start (){
    try {
      roundTripNode = new AudioWorkletNode(audioCtx, 'round-trip-worklet');
    } catch (err) {
      await audioCtx.audioWorklet.addModule(RoundTripWorklet);
      roundTripNode = new AudioWorkletNode(audioCtx, 'round-trip-worklet');
    }
    try {
      microphoneNode.connect(roundTripNode);
      roundTripNode.connect(audioCtx.destination);
      latencies = [];
      latency = 0;
      checksCount = 0;
      console.log('trying to test');
      setupMessagePort();
      testStoped = false;
      checkNoiseFloor();
    } catch (e) {
      console.error(e);
    }
  };

  function createNoiseBuffer () {
    noiseBuffer = audioCtx.createBuffer(2, audioCtx.sampleRate * 0.03, audioCtx.sampleRate);
    for (let channel = 0; channel < noiseBuffer.numberOfChannels; channel++) {
      let nowBuffering = noiseBuffer.getChannelData(channel);
      let freq = 8200;
      for (var i = 0; i < noiseBuffer.length; i++) {
        const period = audioCtx.sampleRate / freq;
        freq = (6000 * (1 - i / noiseBuffer.length)) + 2200;
        // nowBuffering[i] = Math.random() * 2 - 1;
        const phase = (i % period) / period;
        nowBuffering[i] = Math.sin(2 * Math.PI * phase) * 0.15;
      }
    }
  };

  function playSound (delay = 0) {
    bufferSource = audioCtx.createBufferSource();
    bufferSource.buffer = noiseBuffer;
    bufferSource.connect(audioCtx.destination);
    bufferSource.start(audioCtx.currentTime + delay, 0);
    bufferSource.onended = function () {
      bufferSource = null;
    };
  };

  function stopSound () {
    if (bufferSource !== null) {
      bufferSource.stop();
      bufferSource = null;
    }
  };

  function setThreshold (newValue) {
    const thresholdParam = roundTripNode.parameters.get('threshold');
    thresholdParam.setValueAtTime(newValue, audioCtx.currentTime);
  };

  function getResult () {
    return latency;
  };

  return {
    init,
    start,
    stop,
    getResult,
    setThreshold,
    setCallbacks,
    setDelayCallback
  };
})();

export default roundTripTest;
