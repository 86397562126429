<template>
  <div
    class="lp-timetable-edit"
  >
    <Loader
      v-if="loading"
      :block="true"
    />
    <div class="lp-timetable-edit-content">
      <div
        class="lp-timetable-edit-body"
        :class="{
          'lp-timetable-edit-body_zoomed': isZoomed,
          'lp-timetable-edit-body_mobile': isMobile
        }"
        :style="isMobile ? {height: bodyHeight} : {}"
      >
        <div
          class="lp-timetable-edit-body-hours"
          :class="{'lp-timetable-edit-body-hours_mobile': isMobile}"
        >
          <div
            v-for="(hour, index) in hoursTable"
            :key="hour"
            class="lp-timetable-edit-body-hours__item"
          >
            {{ hoursName(hour, index) }}
          </div>
        </div>
        <div>
          <div
            v-if="!isMobile"
            class="lp-timetable-edit-body-layout lp-timetable-edit-body-columns"
          >
            <div
              class="lp-timetable-edit-body-columns__item"
              v-for="day of weekDays"
              :key="day.date"
            >
              {{ day.title }}
            </div>
          </div>

          <div
            class="lp-timetable-edit-body-layout"
            :class="{'lp-timetable-edit-body-layout_mobile': isMobile}"
          >
            <div
              class="lp-timetable-edit-body-times"
              v-for="day of weekDays"
              :key="day.date"
            >
              <div
                v-for="(item, index) of day.times"
                :key="item.fullDate + day.date"
                class="lp-timetable-edit-body-times-item lp-timetable-edit-body-times-item_none"
                :class="{
                  'lp-timetable-edit-body-times-item__schedule': item.dateSchedule,
                  'lp-timetable-edit-body-times-item__schedule_last': lastItem(item, index, day.times),
                  'lp-timetable-edit-body-times-item__schedule_first': item.time === item.ruleStartDate,
                  'lp-timetable-edit-body-times-item__schedule_first-none': item.time === item.ruleStartDate &&
                    item.orderedLesson
                }"
              >
                <div
                  v-if="!isMobile && !item.dateSchedule && index !== day.times.length - 1"
                  class="lp-timetable-edit-body-times-item__free-time"
                  @click="!isMobile ? onClickTimetable(item) : null"
                />
                <div
                  v-if="item.time === item.ruleStartDate && !item.orderedLesson"
                  class="lp-timetable-edit-body-times-header"
                >
                  <div class="lp-timetable-edit-body-times-header__body">
                    {{ `${item.ruleStartDate}-${item.ruleEndDate}` }}
                    <div
                      v-if="!item.orderedLesson"
                      class="lp-timetable-edit-body-times-item__icon"
                    >
                      <Toolbar
                        :timetable="true"
                        icon="pencil"
                        :row="item"
                        :isZoomed="isZoomed"
                        :style="item.orderedLesson ? 'color: #7500BA' : 'color: #FFF'"
                        :column="{deleteName: ''}"
                        :hover="'true'"
                        @delete="onDeleteTimetable"
                        @edit="onClickTimetable"
                      />
                    </div>
                  </div>
                </div>
                <TimetableEditableLesson
                  v-if="item.lessonEndFiveMinutes >= item.time"
                  :item="item"
                  :times="day.times"
                  :loading="loading"
                  :isMobile="isMobile"
                  :dayDate="day.date"
                  :firstItem="item.time === item.ruleStartDate"
                />
                <div
                  v-if="isMobile && index === 0"
                  class="lp-timetable-edit-content__plus"
                  @click="onClickTimetable({
                    ruleStartDate: '13:00',
                    ruleEndDate: '13:45',
                    fullDate: currentDate,
                    time: '13:00'
                  })"
                >
                  <addLessonIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TimetableEditableItemModal
      v-model:openModal="showModal"
      v-model:item="currentItem"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue';
import { addLessonIcon } from '@/constants/icons/crm';
import { fill } from 'lodash';
import moment from 'moment';
import { useStore } from 'vuex';
import TimetableApi from '@/api/CRM/timetable';
import Loader from '@/components/Main/Loader/Loader';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';
import MobileDetect from 'mobile-detect';
import TimetableEditableLesson from
  '@/components/CRM/Lessons/Timetable/TimetableEditable/TimetableEditableLesson/TimetableEditableLesson';
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import TimetableEditableItemModal from
  '@/components/CRM/Lessons/Timetable/TimetableEditable/TimetableEditableItemModal/TimetableEditableItemModal';

export default {
  name: 'TimetableEditable',
  components: {
    TimetableEditableItemModal,
    Toolbar,
    Loader,
    TimetableEditableLesson,
    addLessonIcon
  },
  props: {
    currentDate: String,
    save: Boolean
  },
  emits: ['update:change', 'update', 'save'],
  setup (props) {
    const store = useStore();
    const user = computed(() => store.getters.user);

    const timetable = computed(() => store.getters.timetable);
    const lessons = computed(() => store.getters.lessons);
    const setTimetable = (data) => store.dispatch('setTimetable', data);

    const loading = ref(false);
    const setLoaderRun = (data) => loading.value = data;

    const shownTime = ref(false);
    const showModal = ref(false);
    const currentItem = ref({});

    const isoDate = 'YYYY-MM-DD';
    const hoursTable = computed(() => fill(new Array (20), 5));
    const hoursName = (hour, index) => {
      if (index > 4 && (index + 1) !== hoursTable.value.length) {
        return `${hour + index}:00`;
      } else if ((index + 1) === hoursTable.value.length) {
        return '00:00';
      } else {
        return `0${hour + index}:00`;
      }
    };

    const setTimes = (el, i) => {
      return moment().set('minute', 0).set('hour', 5).add(i * 5, 'minutes').format('HH:mm');
    };

    const times = computed(() => fill(new Array(19 * 12), '').map(setTimes));

    const setWeeks = (el, i) => {
      const momentDate = moment(props.currentDate).add(i, 'day');
      const title = momentDate.format('DD ddd');
      const date = momentDate.format(isoDate);
      const time = times.value.map((item, index) => {
        const fullDate = moment(`${date}T${item}`, 'YYYY-MM-DDTHH:mm' ).format();
        return {
          time: item,
          ...teacherTime(fullDate, index),
          ...orderedLessonTime(fullDate),
          fullDate
        };
      });
      return { title, date, times: time };
    };

    const weekDays = computed(() => fill(new Array(isMobile.value ? 1 : 7), '').map(setWeeks));
    const teacherTime = (fullDate, index) => {
      let teacherTime = {};
      timetable.value.forEach((el, i) => {
        const endDate = moment(el.ruleEndDate).subtract(5, 'minutes').toDate();
        if (new Date(fullDate) >= new Date(el.ruleStartDate) && new Date(fullDate) <= new Date(endDate)) {
          teacherTime = {
            dateSchedule: fullDate,
            ruleStartDate: moment(el.ruleStartDate).format('HH:mm'),
            ruleEndDate: moment(el.ruleEndDate).format('HH:mm'),
            endDate: moment(endDate).format('HH:mm'),
            id: el._id,
            lessonIndex: i + 1,
            index
          };
        }
      });
      return teacherTime;
    };
    const orderedLessonTime = (fullDate) => {
      let orderedLessonTime = {};
      lessons.value.forEach((el, i) => {
        const {
          crmStudentDeleted = false, crmLessonEnd, crmLessonStart, crmLessons, crmStudentFullName, uploadPhotoUrl,
          crmStudentId, isTrial = false, color
        } = el;
        const endDate = moment(crmLessonEnd).subtract(5, 'minutes').toDate();
        if (new Date(fullDate) >= new Date(crmLessonStart) && new Date(fullDate) <= new Date(endDate)) {
          orderedLessonTime = {
            color,
            crmLessons,
            crmStudentFullName,
            uploadPhotoUrl,
            crmStudentId,
            crmStudentDeleted,
            isTrial,
            lessonFullDate: fullDate,
            lessonStart: moment(crmLessonStart).format('HH:mm'),
            lessonStartCheckTimetable: moment(crmLessonEnd).add(15, 'minutes').format('HH:mm'),
            lessonEnd: moment(crmLessonEnd).format('HH:mm'),
            lessonEndFiveMinutes: moment(endDate).format('HH:mm'),
            lessonIndex: i + 1
          };
        }
      });
      return orderedLessonTime;
    };

    const getTemplate = async (withoutLoader = false) => {
      await setTimetable([]);
      const teacherId = user.value._id;
      if (!teacherId) return;
      if (!withoutLoader) await setLoaderRun(true);
      const start = moment(props.currentDate).utc().format();
      const end = moment(props.currentDate).add(6, 'day').set('hour', 23).set('minute', 59).utc().format();
      try {
        const { data } = await TimetableApi.getTimetable({ start, end, teacherId });
        await setTimetable(data);
      } catch (e) {
        return e;
      }
      if (!withoutLoader) setLoaderRun(false);
    };

    watch(user, () => getTemplate());
    watch(() => props.currentDate, () => getTemplate());

    onBeforeMount(() => {
      getTemplate();
    });

    const onDeleteTimetable = async ({ id }) => {
      try {
        const { data } = await TimetableApi.deleteTimetableById(id);
        if (data === 200) {
          const newArray = timetable.value.filter(el => el._id !== id);
          setTimetable(newArray);
        }
      } catch (e) {
        console.error(e);
      }
    };
    const onClickTimetable = async (item) => {
      showModal.value = !showModal.value;
      currentItem.value = item;
    };

    const lastItem = ({ orderedLesson, dateSchedule, index }, ind, times) => {
      return ind === index ? !times[ind + 1].dateSchedule : false;
    };
    const isZoomed = detectNeedZoom();

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const bodyHeight = ref('auto');
    const setBodyHeight = () => {
      const vh = window.innerHeight * 0.01;
      const timetableTabsHeader = document.querySelector('#timetableTabsHeader');
      const timetableDateHeader = document.querySelector('#timetableDateHeader');
      let timetableDateControlMobile = 0;
      if (isMobile.value) {
        const marginPadding = 53;
        const element = document.querySelector('#timetableDateControlMobile');
        timetableDateControlMobile = element.offsetHeight + marginPadding;
      }
      const offset = timetableTabsHeader.offsetHeight + timetableDateHeader.offsetHeight + timetableDateControlMobile;
      const fullHeight = vh * 100;
      bodyHeight.value = `${fullHeight - offset}px`;
    };

    onMounted(() => {
      setBodyHeight();
    });

    return {
      bodyHeight,
      isMobile,
      isZoomed,
      loading,
      weekDays,
      times,
      timetable,
      hoursTable,
      showModal,
      currentItem,
      shownTime,
      hoursName,
      lastItem,
      onDeleteTimetable,
      onClickTimetable
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-edit {
  @include global-font;
  position: relative;

  &__loader {
    background-color: rgba($color-white, 0.4);
  }

  &-content {
    background: $color-white;
    border-radius: 4px;
  }

  &-body {
    z-index: 1;
    user-select: none;
    max-height: calc(100vh - 220px);
    overflow: auto;
    display: grid;
    grid-template-columns: 50px 1fr;

    &_mobile {
      max-height: 100%;
      margin: 12px 0 0 0;
    }

    &-hours {
      margin: 52px 18px 0 0;

      &_mobile {
        margin: 12px 18px 0 0;
      }

      &__item {
        display: flex;
        align-items: flex-start;
        height: 60px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.5px;
        color: $color-gray
      }
    }

    &_zoomed {
      max-height: calc(120vh - 220px);
    }

    &-layout {
      display: grid;
      grid-template-columns: repeat(7, minmax(84px, 1fr));
      align-items: center;
      justify-content: center;

      &:last-child {
        margin: 21px 0 70px 0;
      }

      &_mobile {
        grid-template-columns: repeat(1, 1fr);

        &:last-child {
          margin: 21px 0 0 0;
        }
      }
    }

    &-columns {
      position: sticky;
      top: 0;
      padding: 10px 0;
      z-index: 2;
      background-color: $color-white;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-moon-raker;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;
        z-index: 1;
      }

      &__item {
        position: relative;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-accent;
        z-index: 2;
      }
    }

    &-times {
      position: relative;
      & + & {
        border-left: 1px solid $color-alto;
      }

      &-header {
        display: flex;
        justify-content: flex-end;

        &__body {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 700;
          color: $color-white;
          position: relative;
          padding: 4px 8px 2px;
          height: 21px;
          right: -2px;
          top: -23px;
          min-width: 114px;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          background: $color-green-haze;
          border-right: 2px solid $color-green-haze;
          white-space: nowrap;
        }
      }

      &__tooltip {
        white-space: nowrap;
        margin-left: -19px;
        top: -34px;
      }

      &-item {
        height: 5px;
        cursor: pointer;
        position: relative;
        border-left: 2px solid transparent;

        &:nth-child(1) {
          border-top: 1px solid $color-alto;
        }

        &:nth-child(12n +12) {
          border-bottom: 1px solid $color-alto;
        }

        &:hover + .lp-timetable-edit-body-times-item_none {
          border-bottom: 0 !important;
        }

        &__free-time {
          width: 100%;
          border-radius: 4px;
          height: 5px;
          position: relative;
          //z-index: 1;

          &:hover {
            background: $color-purple;
          }
          &:hover:after {
            content: "+";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 18px;
            width: 18px;
            color: #FFFFFF;
            font-size: 19px;
            font-weight: 500;
            top: -3px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(to top, $color-purple 83%, transparent 17%);
          }
        }

        &__schedule {
          background-color: $color-emerald;
          border-color: $color-emerald;
          color: $color-white;
          font-weight: bold;
          cursor: auto;
          border-left: 2px solid $color-green-haze;
          border-right: 2px solid $color-green-haze;
          transition-property: background-color, border;
          transition-duration: .1s, .1s;
          transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);

          &:nth-child(12n +12) {
            border-bottom: 0;
          }

          &_first {
           border-top-left-radius: 8px;
           border-top: 2px solid $color-green-haze !important;
          }

          &_first-none {
            border-top-left-radius: 0;
            border-top: 0 !important;
          }

          &_last {
            border-bottom: 2px solid $color-green-haze;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
          }
        }

        &__icon {
          color: $color-black;
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';

  .lp-timetable-edit-content__plus {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    background-color: $color-accent;
    position: fixed;
    left: auto;
    top: auto;
    right: 16px;
    bottom: 26px;
    z-index: 10;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: $color-white
      }
    }
  }
</style>
