<template>
  <div
    class="lp-timetable-save"
    ref="savePopupEl"
  >
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition :name="isMobile ? 'fade' : 'bounce'">
        <ModalQuestion
          class="lp-timetable-save__modal"
          v-if="openConfirmLesson"
          :position="position"
          :center="true"
          :question="$t('timetable.template.needSave')"
          @accept="acceptConfirm"
          :mobile="isMobile"
          v-model:open="openConfirmLesson"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import MobileDetect from 'mobile-detect';

export default {
  name: 'TimetableSaveModal',
  components: { ModalQuestion },
  props: {
    open: Boolean
  },
  emits: ['save', 'close', 'update:open'],
  setup (props, { emit }) {
    const openConfirmLesson = ref(false);
    watch(() => props.open, (val) => openConfirmLesson.value = val);
    watch(openConfirmLesson, (val) => {
      if (!val) {
        emit('close');
        emit('update:open', val);
      }
    });
    const savePopupEl = ref(null);
    const position = computed(() => savePopupEl.value ? savePopupEl.value.getBoundingClientRect() : {} );

    const acceptConfirm = () => {
      emit('save');
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      openConfirmLesson,
      position,
      savePopupEl,
      acceptConfirm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-save {
  @include global-font;
  position: absolute;
  top: -18px;
  left: -14px;

  &__modal {
    max-width: 200px;
    min-width: 200px;
  }
}

</style>
