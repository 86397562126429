import * as types from '@/constants/mutationTypes';
import { get } from 'lodash';

const state = {
  streams: [],
  localStream: null,
  audioSettings: {
    deviceId: '',
    mute: false,
    microphoneLocalVolume: 1,
    microphoneRemoteVolume: 1,
    musicRemoteVolume: 1,
    playerVolume: 1
  },
  videoSettings: {
    deviceId: '',
    mirror: get(JSON.parse(localStorage.getItem('video_settings')), 'mirror', true),
    mirrorRemote: false,
    muteLocal: false,
    muteRemote: false,
    resolution: {}
  }
};
const getters = {
  streams: (state) => state.streams,
  localStream: (state) => state.localStream,
  audioSettings: (state) => state.audioSettings,
  videoSettings: (state) => state.videoSettings
};
const actions = {
  setStreams ({ commit }, data) {
    commit(types.SET_STREAMS, { data });
  },
  setLocalStream ({ commit }, data) {
    commit(types.SET_LOCAL_STREAM, { data });
  },
  setAudioSettings ({ commit }, data) {
    commit(types.SET_AUDIO_SETTINGS, { data });
  },
  setVideoSettings ({ commit }, data) {
    commit(types.SET_VIDEO_SETTINGS, { data });
  }
};
const mutations = {
  [types.SET_LOCAL_STREAM] (state, { data }) {
    state.localStream = data;
  },
  [types.SET_STREAMS] (state, { data }) {
    state.streams = data;
  },
  [types.SET_AUDIO_SETTINGS] (state, { data }) {
    state.audioSettings = data;
  },
  [types.SET_VIDEO_SETTINGS] (state, { data }) {
    state.videoSettings = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
