<template>
  <div
    class="lp-hints-registration"
    :class="{'lp-hints-registration_mobile': isMobile}"
  >
    <div
      class="lp-hints-registration__title"
      :class="{'lp-hints-registration__title_mobile': isMobile}"
    >
      {{ $t('hints.registration.title') }}
    </div>
    <HintsRegistrationForm
      :isMobile="isMobile"
      @onCloseRegistrationModal="onCloseRegistrationModal"
    />
  </div>
</template>

<script>

import HintsRegistrationForm from '@/components/CRM/Hints/HintsRegistration/HintsRegistrationForm';

export default {
  name: 'HintsRegistration',
  components: { HintsRegistrationForm },
  props: {
    isMobile: Boolean
  },
  emits: ['on-close-registration-modal'],
  setup (props, { emit }) {
    const onCloseRegistrationModal = () => {
      emit('on-close-registration-modal');
    };

    return {
      onCloseRegistrationModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-hints-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 462px;
  height: 504px;
  padding: 40px 20px 20px 20px;
  border-radius: 4px;
  background: $color-white;
  box-shadow: 0 0 15px rgba($color-black, 0.02);
  color: $color-sine-shaft;

  &_mobile {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 48px 12px 40px 12px;
  }

  &__title {
    padding: 0 70px;
    margin: 0 0 32px 0;
    font-family: $global-font-bold;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;

    &_mobile {
      padding: 0 30px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>