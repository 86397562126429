<template>
  <div
    class="lp-free-time"
    :class="{'lp-free-time_mobile': isMobile}"
  >
    <p class="lp-free-time-text">
      {{ $t('lessonCall.freeTime') }}
      <span class="lp-free-time-text__number">
        {{ timeLeftFormat }}
      </span>
    </p>
    <button
      class="lp-free-time__button"
      @click="openModal = true"
    >
      {{ $t(shortText ? 'lessonCall.freeTimeButtonMobile' : 'lessonCall.freeTimeButton') }}
    </button>

    <Modal
      v-model:open="openModal"
      title="lessonCall.freeTimeModalTitle"
      :saveBtnText="'buttons.update'"
      :hideButtons="['remove', 'save']"
      :mobileVersion="isMobile"
      :clickOutsideOff="true"
    >
      <div
        class="lp-free-time-modal"
        :class="{'lp-free-time-modal_mobile': isMobile}"
      >
        <img
          class="lp-free-time-modal__img"
          :src="placeholder"
          alt="close lesson"
        />
        <div class="lp-free-time-modal__text">
          {{ $t('lessonCall.freeTimeModalText') }}
        </div>
        <div
          class="lp-free-time-modal-email"
          :class="{'lp-free-time-modal-email_short': shortText}"
        >
          <a
            class="lp-free-time-modal-email__link"
            :class="{
              'lp-free-time-modal-email__link_copied': copiedLink
            }"
            :href="`mailto:${email}`"
            target="_blank"
          >
            {{ email }}
            <transition name="fade">
              <span
                class="lp-free-time-modal-email__copied"
                v-if="copiedLink"
              >
                {{ $t('lessonCall.copied') }}
              </span>
            </transition>
          </a>
          <LPTooltip
            :text="$t('lessonCall.copyLink')"
            :position="'right'"
            v-if="!isMobile"
          >
            <div
              class="lp-free-time-modal-email__icon"
              :class="{'lp-timetable-link-icon_mobile': isMobile}"
              @click="copyUrl"
            >
              <copyIcon />
            </div>
          </LPTooltip>
          <div
            class="lp-free-time-modal-email__icon"
            :class="{'lp-timetable-link-icon_mobile': isMobile}"
            @click="copyUrl"
            v-else
          >
            <copyIcon />
          </div>
        </div>
        <div class="lp-free-time-modal__button">
          <button
            class="lp-button"
            @click="closeForm"
          >
            {{ $t('buttons.ok') }}
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/components/Main/Modal/Modal';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import placeholder from '@/assets/images/time-left-placeholder.svg';
import { setParticipants } from '@/constants/_rtc/utils/helpers';
import { VueCookieNext } from 'vue-cookie-next';
import { copyIcon } from '@/constants/icons/crm';
import timeout from '@/constants/utils/timeout';
import numeral from 'numeral';
import { useRoute } from 'vue-router';

export default {
  name: 'FreeTime',
  components: {
    Modal,
    copyIcon,
    LPTooltip
  },
  setup () {
    const email = 'maria.kozyrava@musio.io';
    const freeTime = 900000; // is equal 15min

    const store = useStore();
    const route = useRoute();
    const isMobile = computed(() => store.getters.isMobile);
    const connection = computed(() => store.getters.rtcConnect);
    const shortText = computed(() => window.innerWidth <= 380);
    const roomId = computed(() => route.params.roomId);

    const timeStorage = localStorage.getItem('free_time');
    const timeDataStorage = timeStorage ? JSON.parse(timeStorage) : null;

    const timeLeft = ref(0); // seconds;
    const timeStart = ref(roomId.value === timeDataStorage?.roomId ? timeDataStorage?.time || 0 : 0);
    const interval = ref(null);
    const timeLeftFormat = computed(() => msToTime(timeStart.value ? timeLeft.value : freeTime));

    const openModal = ref(false);
    const copiedLink = ref(false);

    const msToTime = (ms) => {
      if (ms <= 0) return '00:00';
      const seconds = Math.floor((ms / 1000) % 60);
      const minutes = Math.floor((ms / (60 * 1000)) % 60);
      return `${numeral(minutes).format('00')}:${numeral(seconds).format('00')}`;
    };

    const handleInterval = () => {
      if (!timeStart.value) {
        const time = Date.now();
        timeStart.value = time;
        localStorage.setItem('free_time', JSON.stringify({ roomId: roomId.value, time }));
      }
      timeLeft.value = freeTime - (Date.now() - timeStart.value);
    };

    const copyUrl = async () => {
      try {
        await navigator.clipboard.writeText(email);
        copiedLink.value = true;
        await timeout(2000);
        copiedLink.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    const closeForm = () => window.location.href = '/main';
    watch(openModal, (val) => {
      if (!val && timeLeft.value <= 0) closeForm();
    });

    watch(timeLeft, (time) => {
      if (time > 0) return;
      if (time <= -60000) {
        timeStart.value = 0;
        handleInterval();
        return;
      }
      clearInterval(interval.value);
      openModal.value = true;

      setParticipants({ store, count: 0 });
      if (connection.value) {
        connection.value.getAllParticipants().forEach((pid) => connection.value.disconnectWith(pid));
        connection.value.closeSocket();
      }

      VueCookieNext.removeCookie('owner_room');
      VueCookieNext.removeCookie('initiator');
    });

    onMounted(() => {
      interval.value = setInterval(handleInterval, 1000);
    });

    return {
      email,
      openModal,
      timeLeftFormat,
      isMobile,
      placeholder,
      shortText,
      copiedLink,
      closeForm,
      copyUrl
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-free-time {
  display: flex;
  align-items: center;
  justify-content: center;

  &_mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-start;
    gap: 8px;
    background-color: $color-supernova;
    padding: 9px 6px;
  }

  &_mobile &__button {
    border-radius: 4px;
    font-weight: 700;
    font-size: 9px;
    height: 33px;
    line-height: 33px;
    background-color: $color-white;
    padding: 2px 10px 0;
  }

  &_mobile &-text {
    font-weight: 700;
    font-size: 13px;
    line-height: 125%;
    margin: 0;
    white-space: pre-line;
    color: $color-text;
  }

  &-text {
    margin-right: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 125%;
    color: $color-white;

    &__number {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 38px;
      max-width: 38px;
    }
  }

  &__button {
    font-family: $global-font-roboto;
    height: 24px;
    padding: 2px 16px 0;
    color: $color-mine-shaft;
    background-color: $color-supernova;
    border-radius: 4px;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 700;
    font-size: 9px;
    line-height: 125%;
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover {
      background-color: darken($color-supernova, 7%);
    }
  }

  &-modal {
    display: grid;
    align-items: center;
    justify-content: center;
    min-width: 560px;
    padding: 40px 0 0;

    &_mobile {
      min-width: 100vw;
    }

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 297px;
      height: auto;
      margin: 0 auto 36px;
      object-fit: cover;
    }

    &__text {
      max-width: 360px;
      margin: 0 auto 10px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
    }

    &-email {
      display: grid;
      grid-template-columns: auto 28px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 40px;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 130%;
      color: $color-text;
      cursor: pointer;
      margin-right: -36px;

      &_short {
        font-size: 20px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__link {
        position: relative;

        &_copied {
          color: transparent;
        }
      }

      &__copied {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        color: $color-chateau-green;
        background-color: $color-white;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
      }

      &__icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 50%;
        background: $color-moon-raker;
        width: 28px;
        height: 28px;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

</style>
