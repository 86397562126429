<template>
  <div>
    <Modal
      v-model:open="openModal"
      :saveBtnText="'buttons.update'"
      :removeBtnText="'buttons.cancel'"
      :hideButtons="(withoutClose || isRoomLimit)
        ? (supportWebrtc && !isRoomLimit ? ['remove'] : ['remove', 'save'])
        : ['remove']"
      :cantClose="withoutClose"
      @remove="closeForm"
      @save="updateForm"
    >
      <div
        class="lp-create-room-device"
        :class="{'lp-create-room-device_mobile': isMobile}"
      >
        <img
          class="lp-create-room-device__img"
          :src="errorPlaceholder"
        />
        <div
          class="lp-create-room-device__text"
          v-if="!isRoomLimit"
        >
          {{ supportWebrtc ? devicesText : notSupport }}.
        </div>
        <div class="lp-create-room-device__text">
          <template v-if="isRoomLimit">
            {{ $t(`deviceModal.${isRoomLimit}`) }}
          </template>
          <template v-else>
            {{ supportWebrtc ? $t('deviceModal.updateDevice') : $t('lessonCall.recommendedBrowser') }}.
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import errorPlaceholder from '@/assets/images/calibrationPlaceholders/error.png';
import { useStore } from 'vuex';

export default {
  name: 'NoMediaDevices',
  components: {
    Modal
  },
  props: {
    supportWebrtc: Boolean,
    withoutClose: Boolean,
    isRoomLimit: String,
    devicesText: {
      type: String,
      default: ''
    }
  },
  emits: ['detect-devices', 'update:openModal'],
  setup (props, { emit }) {
    const openModal = ref(true);

    const closeForm = () => {
      openModal.value = false;
    };

    const updateForm = () => {
      emit('detect-devices');
    };

    watch(openModal, () => {
      emit('update:openModal', openModal.value);
    });

    const { t } = useI18n();
    const notSupport = t('lessonCall.notSupport');

    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      updateForm,
      closeForm,
      openModal,
      notSupport,
      errorPlaceholder
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';
.lp-create-room-device {
  width: 675px;
  padding: 48px 20px;

  &_mobile {
    width: auto;
    max-width: 100vw;
    padding: 48px 5vw;
  }

  &__text {
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    &:first-child {
      margin-bottom: 10px;
    }
  }

  &__img {
    width: 100%;
    height: auto;
    max-width: 300px;
    padding-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

</style>
