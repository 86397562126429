<template>
  <div
    class="lp-toolbar-delete-lesson"
    :class="{
      'lp-toolbar-delete-lesson_mobile': buttons,
      'lp-toolbar-delete-lesson_icon': !buttons
    }"
    ref="deleteIconEl"
    @click.stop="showConfirmDeleteLesson"
    @mouseenter.stop="openTooltip = true"
    @mouseleave.stop="openTooltip = false"
  >
    <trashIcon
      class="lp-toolbar-delete-lesson__icon"
      :class="{'lp-toolbar-delete-lesson__icon_mobile': buttons}"
    />
    <template v-if="buttons">
      {{ $t('crm.lessons.tooltips.delete') }}
    </template>
    <Tooltip
      class="lp-toolbar-delete-lesson__tooltip"
      :text="'crm.lessons.tooltips.delete'"
      :open="openTooltip && !openDeleteLesson"
    />
    <teleport
      to="#app"
      :disabled="!mobile"
    >
      <transition :name="mobile ? 'fade' : 'bounce'">
        <ModalQuestion
          :class="{
            'lp-toolbar-delete-lesson__modal-delete': !mobile
          }"
          v-if="openDeleteLesson"
          @accept="acceptDelete"
          :position="positionIcon"
          :question="deleteText"
          :mobile="mobile"
          v-model:open="openDeleteLesson"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { trashIcon } from '@/constants/icons/crm';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import { computed, ref, watch } from 'vue';
import { get } from 'lodash';
import LessonsApi from '@/api/CRM/lessons';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import timeout from '@/constants/utils/timeout';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import StudentApi from '@/api/CRM/students';

export default {
  name: 'DeleteLesson',
  components: {
    Tooltip,
    ModalQuestion,
    trashIcon
  },
  props: {
    mobile: Boolean,
    buttons: Boolean,
    lesson: Object,
    hover: String
  },
  emits: ['delete'],
  setup (props, { emit }) {
    const { t } = useI18n();

    const store = useStore();
    const lessons = computed(() => store.getters.lessons);

    const isCompleted = computed(() => get(props.lesson, 'crmLessonStatus', '') === 'Complete');
    const studentName = computed(() =>  get(props.lesson, 'crmStudentFullName', ''));

    const openDeleteLesson = ref(false);
    const deleteText = computed(() => `${t('calendar.lesson.deleteLesson')} ${studentName.value}?`);
    watch(openDeleteLesson, () => openTooltip.value = false);

    const getStudentsById = async (id) => {
      const students = store.getters.students;
      try {
        const { data } = await StudentApi.getStudentById(id);
        const index = students.findIndex(item => item._id === data._id);
        if (index !== -1) students.splice(index, 1, data);
      } catch (err) {
        console.error(err);
      }
    };

    const acceptDelete = async () => {
      const lessonId = get(props.lesson, '_id', '');
      const index = lessons.value.findIndex(item => item._id === lessonId);
      const deletedLesson = index !== -1 ? lessons.value.splice(index, 1) : null;
      await timeout(250);
      emit('delete');
      if (!lessonId) return;
      try {
        await LessonsApi.deleteGroupLesson(lessonId);
        const { crmStudents } = props.lesson;
        const studentsList = Object.values(crmStudents);
        // await getStudentsById(crmStudentId);

        await Promise.all(studentsList.map(async (item) => {
          await getStudentsById(item);
        }));
      } catch (err) {
        if (deletedLesson && index !== -1) lessons.value.splice(index, 0 , ...deletedLesson);
        console.error(err);
      }
    };

    const openTooltip = ref(false);
    const deleteIconEl = ref(null);
    const positionIcon = ref( {} );
    const position = computed(() => positionIcon.value);

    const showConfirmDeleteLesson = () => {
      positionIcon.value = deleteIconEl.value ? deleteIconEl.value.getBoundingClientRect() : {};
      openDeleteLesson.value = true;
    };

    // watch(() => props.hover, () => {
    //   console.log('ddd');
    //   openDeleteLesson.value = false;
    // });

    return {
      positionIcon,
      isCompleted,
      openDeleteLesson,
      deleteText,
      acceptDelete,
      openTooltip,
      deleteIconEl,
      position,
      showConfirmDeleteLesson
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-toolbar-delete-lesson {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  &_icon {
    width: 28px;
    height: 28px;
    background-color: $color-moon-raker;
    border-radius: 50%;
  }

  &_mobile {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 12px auto;
    grid-gap: 4px;
    align-items: center;
    justify-content: center;
    padding: 13px 18px;
    border: 1px solid $color-accent;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
  }

  &__icon {
    &_mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
    }
  }

  svg {
    transition: transform 0.2s ease-in;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }

  &__modal-delete {
    top: 0;
    min-width: 272px;
    max-width: 272px;
  }

  &__tooltip {
    top: 38px;
    white-space: nowrap;
  }
}
</style>
