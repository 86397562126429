<template>
  <div
    class="lp-crm-lessons"
    :class="{'lp-crm-lessons_mobile': isMobile}"
  >
    <div
      class="lp-crm-lessons-header"
      :class="{'lp-crm-lessons-header_mobile': isMobile}"
      id="timetableTabsHeader"
    >
      <div
        class="lp-crm-lessons-header-tabs"
        v-touch:swipe="swipeTab"
      >
        <div
          class="lp-crm-lessons-header-tabs__tab"
          :class="{
            'lp-crm-lessons-header-tabs__tab_active': activeTab === tab.id,
            'lp-crm-lessons-header-tabs__tab_mobile': isMobile
          }"
          v-for="tab of tabs"
          :key="tab.id"
          @click="setActiveTab(tab.id)"
        >
          {{ $t(tab.title) }}
        </div>
        <TimetableSaveModal
          @save="needSaveChangedTimetable"
          @close="closeSaveModal"
          :open="openChangeTimetable"
          v-model:open="openChangeTimetable"
        />
      </div>
      <transition
        name="fade"
        mode="out-in"
      >
        <div
          class="lp-crm-lessons-header__last-lessons"
          :class="{'lp-crm-lessons-header__last-lessons_mobile': isMobile}"
          @click.stop.prevent="lastLessons.modelValue = !lastLessons.modelValue"
          v-if="activeTab === 1"
        >
          <span>{{ $t(lastLessons.label) }}</span>
          <CustomToggle
            v-model:value="lastLessons.modelValue"
            :value="lastLessons.modelValue"
          />
        </div>
        <PersonalLink v-else />
      </transition>
    </div>
    <!--    <transition-group-->
    <!--      tag="div"-->
    <!--      class="lp-crm-lessons-body"-->
    <!--      :name="activeTab === 2 ? 'slide-left' : 'slide-right'"-->
    <!--      mode="out-in"-->
    <!--    >-->
    <Calendar
      v-if="activeTab === 1"
      :key="1"
      v-model:date="calendarDate"
      :lastLessons="lastLessons.modelValue"
    />
    <Timetable
      v-else-if="activeTab === 2"
      :key="2"
      v-model:date="calendarDate"
      :save="needSave"
      v-model:saved="savedChange"
      :lastLessons="lastLessons.modelValue"
      :showHints="showHints"
    />
    <!--    </transition-group>-->

    <Hints
      v-if="showHints"
      v-model:value="showHints"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import Calendar from '@/components/CRM/Lessons/Calendar/Calendar';
import Timetable from '@/components/CRM/Lessons/Timetable/Timetable';
import PersonalLink from '@/components/CRM/Lessons/Timetable/PersonalLink/PersonalLink';
import TimetableSaveModal from '@/components/CRM/Lessons/Timetable/TimetableEditable/TimetableSaveModal';
import MobileDetect from 'mobile-detect';
import { useRouter } from 'vue-router';
import CustomToggle from '@/components/Main/Inputs/CustomToggle';
import moment from 'moment';
import Hints from '@/components/CRM/Hints/Hints';
import { useStore } from 'vuex';

export default {
  name: 'Lessons',
  components: { Hints, CustomToggle, TimetableSaveModal, PersonalLink, Timetable, Calendar },
  setup () {
    const store = useStore();

    const activeTab = ref(1);
    const tabs = [
      { id: 1, title: 'crm.pages.lessons', component: Calendar },
      { id: 2, title: 'crm.pages.timetable', component: Timetable  }
    ];
    const currentComponent = computed(() => {
      const active = tabs.find(item => item.id === activeTab.value) || tabs[0];
      return active.component;
    });

    const calendarDate = ref(moment().format('YYYY-MM-DD'));

    const lastLessons = reactive({
      label: 'crm.lessons.lastLessons',
      modelValue: true
    });

    const setActiveTab = (id) => {
      if (savedChange.value && id === 1) {
        activeTab.value = id;
      } else if (!savedChange.value) {
        openChangeTimetable.value = true;
      } else {
        activeTab.value = id;
      }
    };

    const closeSaveModal = () => {
      if (needSave.value) return;
      activeTab.value = 1;
      savedChange.value = true;
    };

    const needSave = ref(false);
    const needSaveChangedTimetable = () => {
      needSave.value = true;
    };
    const saveChangedTimetable = () => {
      needSave.value = false;
      activeTab.value = 1;
    };

    const savedChange = ref(true);
    watch(savedChange, (val) => {
      if (val) needSave.value = false;
    });
    const openChangeTimetable = ref(false);

    const swipeTab = () => {
      activeTab.value = activeTab.value === 1 ? 2 : 1;
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const router = useRouter();
    router.beforeEach((to, from, next) => {
      if (!savedChange.value) {
        openChangeTimetable.value = true;
      } else {
        next();
      }
    });

    const showHints = ref(false);
    const isFirst = computed(() => get(router, 'currentRoute.value.params.isFirst', ''));
    const settings = computed(() => store.getters.settings);

    onBeforeMount(() => {
      if (isFirst.value === 'true' && settings.value._id) activeTab.value = 2;
      if (!settings.value._id) {
        activeTab.value = 2;
        showHints.value = true;
      }
    });

    return {
      isMobile,
      swipeTab,
      activeTab,
      setActiveTab,
      tabs,
      lastLessons,
      currentComponent,
      closeSaveModal,
      needSaveChangedTimetable,
      saveChangedTimetable,
      openChangeTimetable,
      savedChange,
      needSave,
      calendarDate,
      showHints
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-lessons {
  @include global-font;
  margin: -35px -24px -24px;

  &_mobile {
    background-color: $color-white;
    margin: 0;
    min-height: 100%;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 24px 0;
    border-bottom: 1px solid $color-alto;
    user-select: none;

    &_mobile {
      position: sticky;
      top: 0;
      padding: 58px 16px 0;
      background-color: $color-white;
      z-index: 2;
    }

    &-tabs {
      position: relative;
      display: flex;
      align-items: center;

      &__tab {
        position: relative;
        padding-bottom: 10px;
        margin-right: 36px;
        font-weight: 700;
        font-size: 22px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-gray;
        cursor: pointer;

        &_mobile {
          font-size: 16px;
          margin-right: 24px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0;
          background-color: rgba($color-accent, 0.3);
          transition: width 0.2s ease-in-out;
        }

        &:hover:not(&_active) {
          &:after {
            width: 100%;
          }
        }

        &_active {
          color: $color-text;

          &:after {
            width: 100%;
            background-color: $color-accent;
          }
        }
      }
    }

    &__last-lessons {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      grid-gap: 8px;
      padding-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $color-text;
      cursor: pointer;

      &_mobile {
        position: fixed;
        right: 0;
        top: 0;
        padding: 17px 12px;
        background-color: $color-white;
      }
    }
  }

  &-body {
    position: relative;
  }
}

</style>
