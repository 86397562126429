const reviewsList = [
  {
    id: 1,
    isReadMore: false,
    image: 'reviews-image1',
    name: 'landing.reviews.firstItem.name',
    occupation: 'landing.reviews.firstItem.occupation',
    text: 'landing.reviews.firstItem.text'
  },
  {
    id: 2,
    isReadMore: false,
    image: 'reviews-image2',
    name: 'landing.reviews.secondItem.name',
    occupation: 'landing.reviews.secondItem.occupation',
    text: 'landing.reviews.secondItem.text'
  },
  {
    id: 3,
    isReadMore: false,
    image: 'reviews-image3',
    name: 'landing.reviews.thirdItem.name',
    occupation: 'landing.reviews.thirdItem.occupation',
    text: 'landing.reviews.thirdItem.text'
  },
  {
    id: 4,
    isReadMore: false,
    image: 'reviews-image4',
    name: 'landing.reviews.fourthItem.name',
    occupation: 'landing.reviews.fourthItem.occupation',
    text: 'landing.reviews.fourthItem.text'
  },
  {
    id: 5,
    isReadMore: true,
    image: 'reviews-image5',
    name: 'landing.reviews.fifthItem.name',
    occupation: 'landing.reviews.fifthItem.occupation',
    text: 'landing.reviews.fifthItem.text'
  },
  {
    id: 6,
    isReadMore: true,
    image: 'reviews-image6',
    name: 'landing.reviews.sixItem.name',
    occupation: 'landing.reviews.sixItem.occupation',
    text: 'landing.reviews.sixItem.text'
  }
];
export default reviewsList;
