<template>
  <div
    class="lp-calendar-lesson-card-comment"
    :class="{'lp-calendar-lesson-card-comment_mobile': mobile}"
  >
    <div class="lp-calendar-lesson-card-comment-label">
      {{ $t('calendar.lesson.comment') }}
      <LPTooltip
        class="lp-calendar-lesson-card-comment-label__icon"
        :text="$t(`crm.lessons.tooltips.${openEdit ? 'saveComment' : 'editComment'}`)"
        v-if="!mobile"
      >
        <div
          class="lp-calendar-lesson-card-comment-label__edit"
          :class="{
            'lp-calendar-lesson-card-comment-label__edit_save': openEdit
          }"
          @click="setOpenEdit"
        >
          <pencilIcon v-if="!openEdit" />
          <approveIcon v-else />
        </div>
      </LPTooltip>
    </div>
    <textarea
      class="lp-calendar-lesson-card-comment__text"
      :class="{'lp-calendar-lesson-card-comment__text_open': commentEdit || openEdit}"
      :disabled="!openEdit"
      ref="inputEl"
      v-model="commentEdit"
      v-if="!mobile"
    />
    <div
      class="lp-calendar-lesson-card-comment__text lp-calendar-lesson-card-comment__text_mobile"
      v-else
    >
      {{ commentEdit }}
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { pencilIcon, approveIcon } from '@/constants/icons/crm';
import LessonsApi from '@/api/CRM/lessons';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'CommentLessonCard',
  components: {
    LPTooltip,
    pencilIcon,
    approveIcon
  },
  props: {
    mobile: Boolean,
    lesson: Object
  },
  setup (props) {
    const commentEdit = ref('');
    const setCommentEdit = (value) => commentEdit.value = value;
    const comment = computed(() => get(props.lesson, 'comment', ''));
    const openEdit = ref(false);

    const inputEl = ref(null);
    const setOpenEdit = () => {
      if (openEdit.value) updateComment();
      openEdit.value = !openEdit.value;
      commentEdit.value = comment.value;
      if (inputEl.value && openEdit.value) {
        setTimeout(() => {
          inputEl.value.focus();
        }, 500);
      }
    };

    const updateComment = async () => {
      const lesson = props.lesson;
      lesson.comment = commentEdit.value;
      try {
        await LessonsApi.updateGroupLesson(lesson);
      } catch (e) {
        console.error(e);
      }
    };

    watch(comment, setCommentEdit);
    onBeforeMount(() => setCommentEdit(comment.value));

    return {
      setOpenEdit,
      updateComment,
      inputEl,
      openEdit,
      commentEdit
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-lesson-card-comment {
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-gap: 10px;
  height: 100%;

  &_mobile {
    grid-template-rows: 20px auto;
  }

  &-label {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: $color-text;

   &__icon {
     margin-left: 10px !important;
   }

    &__edit {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin-left: 9px;
      background-color: $color-moon-raker;
      border-radius: 50%;
      cursor: pointer;

      &:hover svg {
        path {
          fill: $color-accent;
        }
      }

      svg {
        width: 8px;
        height: 8px;

        path {
          fill: $color-mine-shaft;
        }
      }

      &_save svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__text {
    @include global-font;
    padding: 10px 20px 10px 12px;
    height: 0;
    border: 1px solid rgba($color-black, 0.4);
    box-sizing: border-box;
    background-color: $color-white;
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    color: $color-text;
    resize: none;
    transition: height 0.3s ease-in, border 0.3s ease-in;

    &_open {
      height: 114px;
    }

    &_mobile {
      height: 100%;
    }

    &_mobile,
    &:disabled {
      border: 1px solid rgba($color-black, 0);
      padding: 0 20px 0 0;
    }
  }
}

</style>
