<template>
  <div
    class="lp-multiselect"
  >
    <div
      v-if="modelName === 'crmStudentId' && listIsEmpty"
      class="subscription-modal__error lp-error"
    >
      <dangerIcon />
      {{ $t('crm.lessons.form.studentsError') }}
    </div>
    <div
      v-if="clearAll"
      class="lp-multiselect__clear"
      @click="clearSelect"
    >
      {{ $t('crm.students.form.clearAll') }}
    </div>
    <CustomSelect
      :options="filteredOptions"
      :error="error"
      :label="label"
      :fixed="fixed"
      :required="required"
      :keyProp="keyProp"
      v-model="selected"
      :modelValue="selectedValues"
      @focus="$emit('focus')"
      @handlerChange="$emit('handler-change', $event)"
      :isGroupLesson="true"
      :notHideList="true"
      :lessonStatus="lessonStatus"
      :searchable="true"
      :showList="showList"
      :topOffset="modelName === 'crmStudentId' && selectedValues.length < 1"
      :selectedValues="selectedValues"
    >
      <template #header="{keyProp}">
        <div class="lp-multiselect-selected">
          <div
            class="lp-multiselect-selected-item"
            v-for="item of selectedValues"
            :key="item[keyProp]"
            @mouseleave.stop="showTooltip = null"
            @mouseover="hoverStudentName(item)"
          >
            <div class="lp-select-image-selected__student">
              <img
                class="lp-select-image-selected__photo"
                v-if="getImage(item)"
                :alt="item[keyProp]"
                :src="getImage(item)"
              />
              <div
                class="lp-select-image-selected__photo"
                v-else
              >
                <userIcon />
              </div>
              <div
                class="lp-select-image-selected__name"
                :class="{'lp-select-image-selected__name_sm': item[keyProp].length > 28}"
              >
                {{ item[keyProp] || item.crmStudentFullName }}
              </div>
            </div>

            <div
              v-if="item.crmLessonPass && item.crmLessonPass.availableToCreateLessonsCount || !!lessonWithPass &&
                item.crmLessonPass && isStudentWithPass(item)"
              @mouseover="activeStudent = item._id"
              @mouseout="activeStudent = null"
              class="lp-multiselect-selected-pass"
              :class="{'lp-multiselect-selected-pass_active': activeStudent === item._id}"
            >
              <studentPass />

              <div
                class="lp-multiselect-selected-pass__wrapper"
                :class="{
                  'lp-multiselect-selected-pass__wrapper_show': activeStudent === item._id && !isMobile,
                  'lp-multiselect-selected-pass__wrapper_sm': item[keyProp].length > 28,
                  'lp-multiselect-selected-pass__wrapper_hide': isMobile
                }"
              >
                <div class="lp-multiselect-selected-pass__header">
                  {{ $t('crm.lessons.form.subscriptions.totalSubscriptions') }}
                  {{ item.crmLessonPass.amountOfLessons }}
                  {{ item.crmLessonPass.amountOfLessons > 1 ? $t('crm.lessons.form.subscriptions.lessons') :
                    $t('crm.lessons.form.subscriptions.lesson')
                  }}
                </div>
                <div class="lp-multiselect-selected-pass-info">
                  <div class="lp-multiselect-selected-pass-info__title">
                    {{ $t('crm.lessons.form.subscriptions.leftSubscriptions') }}
                  </div>
                  <div class="lp-multiselect-selected-pass-info__num">
                    {{ +item.crmLessonPass.availableToCreateLessonsCount }}
                  </div>
                </div>
                <div class="lp-multiselect-selected-pass-info">
                  <div class="lp-multiselect-selected-pass-info__title">
                    {{ $t('crm.lessons.form.subscriptions.costSubscriptions') }}
                  </div>
                  <div class="lp-multiselect-selected-pass-info__num">
                    {{ item.crmLessonPass.passCost }} {{ item.crmLessonPass.currency }}
                  </div>
                </div>
              </div>
            </div>
            <closeModalIcon
              class="lp-multiselect-selected-item__remove"
              @click.stop="removeSelectedValues(item)"
            />
            <Tooltip
              v-if="tooltip"
              :open="showTooltip === item._id"
              :text="item[keyProp]"
              :leftPosition="true"
              :topPosition="true"
              :onMobile="true"
              class="name-tooltip"
            />
          </div>
          <div class="lp-multiselect-selected__add">
            <addIcon />
            {{ $t('crm.students.table.addStudent') }}
          </div>
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-select-image-selected lp-select-image-selected_option"
          :class="{'lp-select-image-selected_icon': item.icon}"
        >
          <img
            class="lp-select-image-selected__photo"
            v-if="getImage(item)"
            :alt="item[keyProp]"
            :src="getImage(item)"
          />
          <template v-else>
            <component
              v-if="item.icon"
              :is="item.icon"
            />
            <userIcon
              v-else
              class="lp-select-image-selected__photo"
            />
          </template>

          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import {
  ref, reactive, watch, onBeforeMount, computed } from 'vue';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { userIcon, dangerIcon } from '@/constants/icons';
import { addIcon, studentPass } from '@/constants/icons/crm';
import { closeModalIcon } from '@/constants/icons';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import MobileDetect from 'mobile-detect';
import StudentApi from '@/api/CRM/students';

export default {
  name: 'MultiplySelect',
  components: { CustomSelect, userIcon, Tooltip, closeModalIcon, addIcon, studentPass, dangerIcon },
  props: {
    options: Array,
    keyProp: String,
    modelValue: [Array, Object],
    modelName: String,
    required: Boolean,
    label: String,
    fixed: Boolean,
    tooltip: Boolean,
    error: [Boolean, String],
    lesson: Object,
    keyImg: String,
    lessonStatus: String,
    newStudent: Object,
    clearAll: Boolean,
    notHideList: Boolean,
    openList: Boolean,
    showList: Boolean,
    setDefaultValues: Boolean
  },
  emits: ['update:modelValue', 'show-note', 'handler-change', 'clear', 'update'],
  setup (props, { emit }) {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const selectedValues = reactive([]);
    const selected = ref({});

    const filteredOptions = computed(() => {
      const filteredList = props.options.filter(item => {
        const selected = selectedValues.find(val => item._id === val._id);
        return selected ? item._id !== selected._id : item;
      });
      return filteredList;
    });

    const showTooltip = ref(null);
    const hoverStudentName = (item) => {
      showTooltip.value = item._id;
    };

    const getImage = (val) => {
      let uploadPhoto = val.uploadPhoto ? val.uploadPhoto.url : null;
      let uploadPhotoUrl = val.uploadPhotoUrl ? val.uploadPhoto : null;
      return uploadPhoto || uploadPhotoUrl;
    };

    const activeStudent = ref(null);

    const setSelectedValues = (newVal) => {
      emit('show-note', selectedValues.length);

      if (!newVal || !newVal[props.keyProp]) return;
      const index = selectedValues.findIndex((item) => item._id === newVal._id);
      if (index === -1) selectedValues.push(newVal);
      emit('update:modelValue', selectedValues);
      saveLocalStorage(selectedValues);
      getLocalStorage();
    };

    const saveLocalStorage = (students) => {
      localStorage.setItem('selectedStudentsList', JSON.stringify(students));
    };
    const getLocalStorage = () => {
      return JSON.parse(localStorage.getItem('selectedStudentsList'));
    };

    const removeSelectedValues = (newVal) => {
      const index = selectedValues.findIndex((item) => item === newVal);
      if (index !== -1) selectedValues.splice(index, 1);
      selected.value = {};
    };

    const lessonWithPass = computed(() => {
      const lessonHasPass =  props.lesson && props.lesson.crmLessons ? props.lesson.crmLessons.filter(item =>
        item.crmLessonPassId) : null;
      return lessonHasPass;
    });

    const isStudentWithPass = (student) => {
      return lessonWithPass.value.find(item => item.crmStudentId === student._id);
    };

    const getStudent = async (id) => {
      return await StudentApi.getStudentById(id);
    };

    const clearSelect = () => {
      selectedValues.length = 0;
      selected.value = {};
      emit('clear');
    };

    watch(() => props.newStudent, () => {
      if (props.newStudent) setSelectedValues(props.newStudent);
    });

    watch(selected, setSelectedValues);

    watch(selectedValues, () => {
      emit('update', selectedValues);
      if (selectedValues.length === 0) {
        listIsEmpty.value = true;
      } else {
        listIsEmpty.value = false;
      }
    });

    const listIsEmpty = ref(false);

    onBeforeMount(() => {
      if (props.lesson && props.lesson.crmLessons && props.lesson.crmLessons.length) {
        const studentsList = Object.values(props.lesson.crmLessons);
        const deletedStudents = [];
        studentsList.forEach(async (item) => {
          if (item.crmStudentDeleted) {
            const { data } = await getStudent(item.crmStudentId);
            deletedStudents.push(data);
          }
          const student = props.options.find(option => option._id === item.crmStudentId);
          setSelectedValues(student);
          deletedStudents.forEach(item => setSelectedValues(item));
        });
      } else {
        if (props.newStudent) {
          const studentsList = getLocalStorage();
          studentsList.forEach(item => {
            setSelectedValues(item);
          });
          setSelectedValues(props.newStudent);
        }
        if (props.setDefaultValues && props.modelValue && props.modelValue._id) {
          setSelectedValues(props.modelValue);
        }
        getLocalStorage();
      }
    });

    return {
      selectedValues,
      selected,
      removeSelectedValues,
      getImage,
      showTooltip,
      hoverStudentName,
      activeStudent,
      filteredOptions,
      isMobile,
      lessonWithPass,
      isStudentWithPass,
      clearSelect,
      listIsEmpty
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-multiselect {
  @include global-font;
  position: relative;

  &__clear {
    position: absolute;
    right: 0;
    top: 1px;
    font-size: 12px;
    color: $color-purple;
    cursor: pointer;
    z-index: 1;
  }

  &-selected {
    display: flex;
    flex-direction: column;
    margin: 4px -8px -4px 0;

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 28px 0 0;
      border-radius: 2px;
      margin: 0 8px 8px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: $color-black;
      z-index: auto;

      &__remove {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 4px;
        box-sizing: content-box;
        right: 0;
        top: auto;
        cursor: pointer;
        transition: 0.3s ease-in;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &-pass {
      position: relative;
      margin-left: 12px;
      margin-top: 3px;
      z-index: 5;

      &_active {
        z-index: 6;
      }

      &__wrapper {
        position: absolute;
        top: -40px;
        opacity: 0;
        width: 240px;
        height: 100px;
        padding: 16px;
        border-radius: 8px;
        background-color: $color-white;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);
        font-size: 12px;
        transition: .3s opacity;
        pointer-events: none;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: -6px;
          top: calc(50% - 7px);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6.5px 6px 6.5px 0;
          border-color: transparent $color-white transparent transparent;
        }

        &_show {
          opacity: 1;
        }

        &_hide {
          display: none;
        }

        &_sm {
          max-width: fit-content;
          min-height: 100px;
          height: auto;
          right: -75px;
          top: -100px;

          &:before {
            top: 70%;
          }
        }
      }

      &__header {
        margin-bottom: 12px;
        font-weight: bold;
      }
    }

    &-pass-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &__num {
        display: flex;
        justify-content: center;
        align-self: center;
        min-width: 30px;
        padding: 3px 8px;
        margin-left: 10px;
        border-radius: 5px;
        font-weight: bold;
        color: $color-purple;
        background-color: $color-moon-raker;
      }
    }

    &__add  {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color: $color-purple;

      svg {
        margin-right: 8px;
      }
    }
  }

  .lp-input {
    border: none !important;
  }

  .lp-select-image-selected {
    word-break: break-word;

    &__photo {
      margin-right: 7px;
      position: relative;
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__student {
      display: flex;
      align-items: center;
      z-index: 0;
    }

    &__name {
      &_sm {
        max-width: 300px;
        word-break: break-word;
      }
    }
  }
}

.lp-select-list {
  .lp-select-image-selected__photo {
    margin-right: 7px;
    position: relative;
    width: 24px;
    height: 24px;
  }
}

.name-tooltip {
  bottom: 40px;
  left: 0;
  position: absolute;
}

.lp-select-header__icon {
  display: none !important;
}

.lp-select-image-selected {
  display: grid;
  grid-template-columns: 24px auto;
  grid-gap: 6px;
  align-items: center;
  word-break: break-word;

  &_option {
    margin-left: -12px;
  }

  &__photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.select-top {
  margin-top: 10px;
}

</style>
