<template>
  <div
    class="lp-radio"
    :class="{'lp-radio_disabled': isDisabled}"
  >
    <div class="lp-radio-header">
      <label
        :class="{
          'lp-label_required': required
        }"
        :for="label"
        class="lp-label"
      >
        {{ label ? $t(label) : '' }}
      </label>
      <slot name="header"></slot>
    </div>
    <div class="lp-radio-body">
      <label
        v-for="item of options"
        :key="item.id"
        :class="{'lp-radio-item_checked': item.value}"
        class="lp-radio-item"
      >
        <input
          :checked="item.value"
          :name="item.name"
          :value="item.id"
          class="lp-radio-item__input"
          type="radio"
          @change="setChecked($event, item)"
        >
        <span
          class="lp-radio-item__label"
        >
          {{ $t(item.label) }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, watch } from 'vue';

export default {
  name: 'CustomRadio',
  props: {
    modelValue: String,
    options: Array,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    input: Object
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const isDisabled = computed(() => {
      const radioButton = props.input || {};
      const { disabled = false } = radioButton;
      return disabled;
    });

    const setChecked = (e, item) => {
      item.value = e.target.checked;
      emit('update:modelValue', item.id);
      props.options.forEach((el) => {
        if (item.id === el.id) {
          el.value = e.target.checked;
        } else {
          el.value = false;
        }
        return el;
      });
    };

    const emitDefault = () => {
      setChecked({ target: { checked: true } }, { id: props.modelValue });
    };
    watch(() => props.modelValue, emitDefault);
    onBeforeMount(emitDefault);

    return { setChecked, isDisabled };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-radio {

  &-header {
    margin-bottom: 14px;
  }

  &-body {
    display: flex;
  }

  &-item {
    display: inline-grid;
    grid-template-columns: 16px auto;
    grid-gap: 8px;
    position: relative;
    user-select: none;
    cursor: pointer;
    margin-right: 16px;

    &:hover {
      &:before {
        border-color: $color-accent-hover;
      }
    }

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid $color-accent;
      border-radius: 50%;
      box-sizing: border-box;
      transition: border-color 0.2s ease-in;
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-accent;
      transition: transform 0.2s ease-in;
      transform: scale(0);
    }

    &_checked {
      &:after {
        transform: scale(1);
      }
    }

    &:hover {
      &:after {
        background-color: $color-accent-hover;
      }
    }

    &__input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    &__label {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &_disabled {
    pointer-events: none;
    opacity: .7;
  }
}

</style>
