<template>
  <div class="lp-category-cell">
    {{ $t(expenseCategory.title) }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import categoryExpensesList from '@/constants/crm/expensesCategoryes';

export default {
  name: 'CategoryCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const expenseCategory = computed(() => {
      const value = get(props.row, props.column.prop, '');
      return categoryExpensesList.find((item) => item.id === value);
    });

    return {
      expenseCategory
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-category-cell {
  display: flex;
}
</style>
