<template>
  <div>
    <Modal
      :title="'crm.settings.changeCurrency.title'"
      @save="saveForm"
      @remove="closeForm"
      :saveBtnText="'buttons.save'"
      :removeBtnText="'buttons.cancel'"
      v-model:open="openModal"
      :editMode="true"
      :mobileVersion="isMobile"
    >
      <div class="lp-currency-change">
        <div class="lp-currency-change-body">
          <div class="lp-currency-change-body__currency">
            {{ countRate }} {{ currency.from }}
          </div>
          <div> = </div>
          <div class="lp-currency-change-body__currency">
            <CustomInput
              class="lp-currency-change-body__input"
              v-model="currencyRate"
              :type="'number'"
              :inputmode="'decimal'"
              :noWrap="true"
              :error="errorRate"
              @focus="errorRate = ''"
              @submit="saveForm"
            />
            {{ currency.to }}
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, watch } from 'vue';
import CustomInput from '@/components/Main/Inputs/CustomInput';
import MobileDetect from 'mobile-detect';

export default {
  name: 'CurrencyChangeRate',
  components: { CustomInput, Modal },
  props: {
    currency: Object,
    openForm: Boolean
  },
  emits: ['update:openForm', 'update:rate', 'save'],
  setup (props, { emit }) {
    const currencyRate = ref(1);
    const countRate = ref(1);
    const errorCount = ref('');
    const errorRate = ref('');

    const openModal = ref(false);
    watch(() => props.openForm, (value) => {
      if (value) currencyRate.value = 1;
      openModal.value = value;
    });
    watch(openModal, (value) => {
      emit('update:openForm', value);
    });

    const saveForm = () => {
      if (!currencyRate.value) {
        errorRate.value = 'errors.correctCurrency';
        return;
      }
      if (!countRate.value) {
        errorCount.value = 'errors.correctCurrency';
        return;
      }
      const rate = currencyRate.value / countRate.value;
      emit('update:rate', rate);
      emit('save');
    };

    const closeForm = () => {
      openModal.value = false;
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      countRate,
      openModal,
      closeForm,
      saveForm,
      currencyRate,
      errorCount,
      errorRate
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-currency-change {
  padding: 32px 32px 24px;

  &-body {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;

    &__currency {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__input {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 80px;
      margin-right: 4px;
      text-align: center;
    }
  }
}

</style>
