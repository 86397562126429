import { uniqueId, get } from 'lodash';
import FORMATS from '@/constants/formats';
import { subscriptionsIcon } from '@/constants/icons/crm';

const columns = [
  {
    type: 'date',
    prop: 'paymentDate',
    formatDate: window.innerWidth > 768 ? 'DD MMMM, YYYY' : 'DD MMM, YYYY',
    title: 'crm.students.payment.date',
    width: 'minmax(120px, 1fr)'
  },
  {
    type: 'number',
    prop: 'value',
    format: FORMATS.FINANCE,
    title: 'crm.students.payment.amount',
    width: 'minmax(60px, 1fr)',
    iconProp: {
      showValue: true,
      prop: 'crmLessonPassId',
      icon: subscriptionsIcon,
      tooltipText: 'crm.subscriptions.tooltip',
      style: {
        marginLeft: '15px'
      }
    }
  },
  {
    type: 'text',
    prop: 'currency',
    title: 'crm.students.payment.currency',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: 'minmax(75px, 1fr)'
  },
  {
    type: 'delete',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: 'minmax(20px, 0.5fr)',
    visible: (row) => !get(row, 'crmLessonPassId', false)
  }
];

const paymentsColumns = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default paymentsColumns;
