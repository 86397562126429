<template>
  <div
    class="lp-microphone-settings"
    v-click-outside="closeSettings"
  >
    <LPTooltip :text="$t(`lessonCall.${muteMicrophone ? 'settingMicYes' : 'settingMicNo'}`)">
      <div class="lp-microphone-settings-button">
        <!-- :class="{'lp-microphone-settings-button_mobile': isMobile}" -->
        <div
          class="lp-microphone-settings-button__microphone"
          :class="{
            'lp-microphone-settings-button__microphone_mute': muteMicrophone
          }"
          @click="onMuteMicrophone"
        >
          <microphoneIcon />
        </div>
      </div>
    </LPTooltip>
  </div>
</template>

<script>
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { microphoneIcon } from '@/constants/icons';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'MicrophoneSettings',
  components: { LPTooltip, microphoneIcon },
  setup () {
    const store = useStore();
    const connection = computed(() => store.getters.rtcConnect);
    const audioSettings = computed(() => store.getters.audioSettings);

    const openSettings = ref(false);
    const closeSettings = () => {
      if (isMobile.value) return;
      openSettings.value = false;
    };

    const muteMicrophone = ref(false);
    const onMuteMicrophone = () => {
      // TODO вкл/выкл свой микрофон
      muteMicrophone.value = !muteMicrophone.value;
      const attachStreams = connection.value.attachStreams;
      if (attachStreams && attachStreams.length) {
        attachStreams.forEach(stream => {
          stream.getAudioTracks().forEach(track => {
            track.enabled = !muteMicrophone.value;
          });
        });
      }
      audioSettings.value.mute = muteMicrophone.value;
    };

    watch(() => audioSettings.value.mute, () => {
      muteMicrophone.value = audioSettings.value.mute;
    });

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      openSettings,
      closeSettings,
      muteMicrophone,
      onMuteMicrophone
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-microphone-settings {
  position: relative;

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    &__microphone, &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: $color-outer-space;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: inset 5px 5px 10px rgba($color-black, 0.18), 0 8px 10px rgba($color-cod-gray, 0.18);
      }
    }

    &__microphone {
      position: relative;
      width: 40px;
      border-radius: 8px;
      overflow: hidden;

      &_lite {
        width: 40px;
        border-radius: 8px;
      }

      &:after {
        content: '';
        position: absolute;
        top: auto;
        left: auto;
        width: 3px;
        height: 0;
        background-color: $color-red;
        transform: rotate(45deg);
        transition: 0.3s;
      }

      &_mute {
        &:after {
          height: 55px;
        }
      }
    }

    &_mobile {
      height: 50px;
    }

    &_mobile &__microphone {
      width: 50px;
    }
  }
}

</style>
