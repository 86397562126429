<template>
  <div class="lp-chart-expenses">
    <div
      class="lp-chart-expenses-header"
      :class="{'lp-chart-expenses-header_mobile': isMobile}"
    >
      <div class="lp-chart-expenses-header__title">
        {{ $t('crm.analytics.expenses') }}
      </div>
      <div class="lp-chart-expenses-header__counter">
        <Vue3Autocounter
          :startAmount="0"
          :endAmount="total"
          :duration="2"
          :suffix="` ${settings.currentCurrency}`"
          separator=","
          decimalSeparator="."
          :decimals="2"
        />
      </div>
    </div>
    <div
      class="lp-chart-expenses-body"
      :class="{'lp-chart-expenses-body_mobile': isMobile}"
    >
      <VueApexCharts
        type="donut"
        :height="mobile && expenses.length ? 550 : 340"
        :width="widthChart"
        :options="chartOptions"
        :series="expenses.length ? expenses : [1]"
        :class="!expenses.length ? 'lp-chart-expenses-body_disable' : ''"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import chartExpenses from '@/constants/crm/chartExpenses';
import numberFormat from '@/constants/utils/numberFormat';
import { computed, onBeforeMount } from 'vue';
import Vue3Autocounter from 'vue3-autocounter';
import SettingsApi from '@/api/CRM/settings';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ChartExpenses',
  components: { VueApexCharts, Vue3Autocounter },
  props: {
    mobile: Boolean,
    total: Number,
    dates: Array,
    expenses: {
      type: Array,
      default: () => ([])
    },
    category: {
      type: Array,
      default: () => ([])
    },
    percents: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const { t } = useI18n();
    const oneColumn = computed(() => window.innerWidth < 1800);
    const hasTable = computed(() => window.innerWidth <= 1440);
    const hasMobile = computed(() => window.innerWidth <= 768);
    const MAX_LENGTH_TOTAL = 7;

    const chartOptions = computed(() => ({
      ...chartExpenses,
      tooltip: {
        enabled: false,
        enabledOnSeries: undefined,
        followCursor: false
      },
      plotOptions: {
        pie: {
          offsetY: hasMobile.value ? 12 : 0,
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                show: true,
                color: '#2C2C2C',
                fontSize: hasMobile.value ? '17px' : '21px',
                fontWeight: 700,
                formatter: (actualName) => {
                  const newCategory = props.percents.find(item => item.expenseCategory === actualName);
                  if (newCategory) {
                    const formatTotalValue = numberFormat(newCategory.totalIncomeByCategory, 'en-US', 2);
                    if (newCategory.totalIncomeByCategory <= Math.pow(10, MAX_LENGTH_TOTAL)) {
                      return `${formatTotalValue} ${settings.value.currentCurrency}`;
                    } else {
                      const totalExpense = String(formatTotalValue).substring(0, MAX_LENGTH_TOTAL);
                      return `${totalExpense}... ${settings.value.currentCurrency}`;
                    }
                  } else {
                    if (props.category.length) {
                      return actualName;
                    } else {
                      return '$0';
                    }
                  }
                }
              },
              value: {
                show: true,
                color: '#858585',
                fontSize: hasMobile.value ? '17px' : '21px',
                fontWeight: 400,
                formatter: (actualValue) => {
                  const newPercent = props.percents.find(item => item.totalIncomeByCategory === +actualValue);
                  if (newPercent) return `${newPercent.percentage.toFixed(2)}%`;
                }
              },
              total: {
                show: !!props.category.length,
                formatter: (actualValue) => {
                  if (props.category.length) {
                    const totalValue = actualValue.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    if (totalValue) {
                      const formatTotalValue = numberFormat(totalValue, 'en-US', 2);
                      if (totalValue <= Math.pow(10, MAX_LENGTH_TOTAL)) {
                        return `${formatTotalValue} ${settings.value.currentCurrency}`;
                      } else {
                        const totalExpense = String(formatTotalValue).substring(0, MAX_LENGTH_TOTAL);
                        return `${totalExpense}... ${settings.value.currentCurrency}`;
                      }
                    }
                  } else {
                    return '';
                  }
                },
                fontSize: hasMobile.value ? '17px' : '21px',
                label: t('crm.expenses.totals.total'),
                fontWeight: 700,
                color: '#2C2C2C',
                showAlways: false
              }
            }
          },
          expandOnClick: true,
          height: 500,
          customScale: hasMobile.value ? 0.8 : 0.7
        }
      },
      legend: {
        show: !!props.category.length,
        position: hasMobile.value ? 'bottom' : 'right',
        horizontalAlign: hasMobile.value ? 'left' : 'center',
        fontSize: '14px',
        fontWeight: 500,
        formatter: (seriesName, opts) => {
          const newCategory = props.percents.find(item => item.expenseCategory === seriesName);
          if (newCategory) {
            const formatTotalValue = numberFormat(opts.w.globals.series[opts.seriesIndex], 'en-US', 2);
            if (opts.w.globals.series[opts.seriesIndex] <= Math.pow(10, MAX_LENGTH_TOTAL)) {
              return `${seriesName}: ${formatTotalValue} ${settings.value.currentCurrency}`;
              // return [seriesName,
              //   '<span class="big-space"></span>',
              //   '<strong style="text-align: right">',
              //   `${formatTotalValue} ${settings.value.currentCurrency}`,
              //   '</strong>'
              // ];
            } else {
              return `${seriesName}: ${String(formatTotalValue).substring(0, MAX_LENGTH_TOTAL)}...
                ${settings.value.currentCurrency}`;
              // return [seriesName,
              //   <span class="big-space"></span>,
              //   '<strong style="text-align: right">',
              //   `${String(formatTotalValue).substring(0, MAX_LENGTH_TOTAL)}... ${settings.value.currentCurrency}`,
              //   '</strong>'
              // ];
            }
          }
        },
        labels: {
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: hasMobile.value ? 0 : 6,
          vertical: hasMobile.value ? 5 : 8
        },
        markers: {
          offsetX: -5
        }
      },
      labels: props.category,
      colors: props.category.length ?
        ['#7500BA', '#FFCD00', '#FFA800', '#38C4D8', '#D30026', '#48D16E', '#3275F7', '#858585']
        : ['#E0E0E0']
    }));

    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const setSettings = (data) => store.dispatch('setSettings', data);

    const getSettings = async () => {
      if (settings.value._id) return;
      try {
        const { data } = await SettingsApi.getSettings();
        await setSettings(data);
      } catch (e) {
        console.error(e);
      }
    };

    onBeforeMount(getSettings);

    const heightChartMobile = computed(() => {
      const vh = window.innerHeight * 0.01;
      return ((vh * 100) - 250) / 2;
    });

    const widthChart = computed(() => {
      const vw = window.innerWidth * 0.01;
      if (oneColumn.value && !hasTable.value) {
        return ((vw * 100) - 2 * 226);
      } else if (oneColumn.value && hasTable.value && !hasMobile.value) {
        return ((vw * 100) - 113);
      } else if (oneColumn.value && hasTable.value && hasMobile.value) {
        return ((vw * 100) - 32);
      } else {
        return (((vw * 100) / 2) - 226);
      }
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      widthChart,
      heightChartMobile,
      chartOptions,
      settings
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-chart-expenses {
  width: 50%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;

    &_mobile {
      padding: 0 16px;
    }

    &__title {
      margin-right: 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-mine-shaft-dark;
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 12px;
      border-radius: 5px;
      background-color: $color-moon-raker;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-accent;
    }
  }

  &-body {
    &_mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px 0 6px;
    }

    &_disable {
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1800px) {
    width: auto;
    grid-row: 4;
    margin-bottom: 30px;
  }
}

</style>
