<template>
  <div
    class="stp-calendar-lesson-card"
    @click.stop
  >
    <div class="stp-calendar-lesson-card__date">
      <DateLessonCard
        :lesson="lesson"
        :class="'stp-calendar-lesson-card__date-student'"
      />
      <crossIcon @click.stop="$emit('update:open', false)" />
    </div>
    <div class="stp-calendar-lesson-card-teacher">
      <span class="stp-calendar-lesson-card-teacher-header">
        {{ $t('student.calendar.teacher') }}
      </span>
      <div class="stp-calendar-lesson-card-teacher-info">
        <img
          class="stp-calendar-lesson-card-teacher-info_photo"
          v-if="lesson.teacher.uploadPhotoUrl"
          :src="lesson.teacher.uploadPhotoUrl"
          alt=""
        >
        <user-icon
          class="stp-calendar-lesson-card-teacher-info_photo"
          v-else
        />
        <span class="stp-calendar-lesson-card-teacher-info_name">
          {{ `${lesson.teacher.firstName || ''} ${lesson.teacher.lastName || ''}` }}
        </span>
      </div>
    </div>
    <div
      class="stp-calendar-lesson-card__subscribe"
      v-if="subscription && !isTrialLesson &&
        lesson.crmLessons.length === 1 && lesson.crmLessons[0].crmLessonPassId"
    >
      <StudentSubscription
        :subscription="subscription"
        :lessons="true"
      />
    </div>
    <div
      class="stp-calendar-lesson-card__cost"
      v-else
    >
      <CostLessonCard
        v-if="lesson.crmLessons && lesson.crmLessons.length > 1 ||
          (lesson.crmLessons.length === 1 && !lesson.crmLessons[0].crmLessonPassId)"
        :lesson="lesson"
      />
    </div>
  </div>
</template>

<script>

import DateLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/DateLessonCard';
import CostLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/CostLessonCard';
import { crossIcon, userIcon } from '@/constants/icons';
import { computed, onBeforeMount, watch, ref } from 'vue';
import StudentSubscription from '@/components/CRM/Students/StudentCard/StudentSubscription';
import timeout from '@/constants/utils/timeout';

export default {
  name: 'StudentCalendarLessonCard',
  components: {
    CostLessonCard,
    DateLessonCard,
    StudentSubscription,
    crossIcon,
    userIcon
  },
  props: {
    lesson: Object
  },
  emits: ['update:open', 'update:form', 'delete', 'get-lessons'],
  setup (props, { emit }) {
    const subscription = ref(null);

    const isGroupLesson = computed(() => {
      return props.lesson.crmLessons.length > 1;
    });

    const studentName = computed(() => {
      return !isGroupLesson.value ? props.lesson.crmLessons[0].crmStudentFullName : null;
    });

    const hideElementUnderModal = (val) => {
      const el = document.querySelector('.mc-container');
      if (!el) return;
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
      el.style.height = val ? '0' : '100%';
    };

    const isTrialLesson = ref(false);

    const updateSubscriptionInfo = async () => {
      const { crmLessons = [] } = props.lesson;
      const [currentLesson = {}] = crmLessons;
      const { isTrial } = currentLesson;
      isTrialLesson.value = isTrial;
      const { crmLessonPassId } = currentLesson;
      subscription.value = crmLessonPassId || null;
    };

    watch(() => props.lesson, async () => {
      await updateSubscriptionInfo();
      if (isLessonGroup.value) {
        subscription.value = null;
      }
    });

    onBeforeMount(async () => {
      await updateSubscriptionInfo();
    });

    const closeCalendarCard = () => {
      console.log('closeCalendarCard');
      emit('update:open', false);
    };

    const openLessonForm = async () => {
      console.log('openLessonForm');
      emit('update:form', true);
      await timeout(250);
      closeCalendarCard();
    };

    const closeLessonForm = () => {
      console.log('closeLessonForm');
      emit('update:open', false);
      hideElementUnderModal(false);
    };

    const isLessonGroup = computed(() => props.lesson.crmStudents.length > 1);

    return {
      openLessonForm,
      closeCalendarCard,
      studentName,
      subscription,
      isLessonGroup,
      isTrialLesson,
      isGroupLesson,
      closeLessonForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';
@import '~styles/_mixins.scss';

.stp-calendar-lesson-card {
  @include global-font;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  width: 370px;
  padding: 20px 22px 0;
  cursor: default;
  z-index: 2;

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 52px 1fr 52px;
    align-items: center;
    border-bottom: 1px solid $color-alto;
    background-color: $color-white;
    z-index: 4;

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      word-break: break-word;
      margin: 5px 0;
      text-align: center;
      color: $color-text;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    :first-child {
      font-size: 14px;
      font-weight: 700;
    }

    svg {
      fill: $color-alto;
      transition: fill 0.2s ease-in;
      cursor: pointer;

      &:hover {
        fill: $color-silver;
      }
    }
  }

  &-teacher {
    display: flex;
    flex-direction: column;

    &-header {
      font-size: 14px;
      font-weight: 700;
    }

    &-info {
      display: flex;
      align-items: center;

      &_photo {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        margin: 6px 6px 6px 0 ;
      }

      &_name {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__student {
    margin-bottom: 24px;
  }

  &__link {
    margin-bottom: 15px;
  }

  &__cost {
    margin-bottom: 18px;
  }

  &__comment {
    margin-bottom: 20px;
    height: 100%;

    &_mobile {
      flex-grow: 1;
    }

    &_empty {
      opacity: 0;
    }
  }

  &__toolbar {
    margin-top: auto;
    margin-bottom: 0;

    &_mobile {
      position: fixed;
      left: 12px;
      right: 12px;
      bottom: 0;
      padding-bottom: 12px;
      z-index: 100;
      background-color: $color-white;
    }
  }
}

</style>
