import { reactive } from 'vue';

export default reactive([
  {
    id: 1,
    text: 'landing.header.linkOne',
    transition: 'contingent'
  },
  {
    id: 2,
    text: 'landing.header.linkTwo',
    transition: 'opportunities'
  },
  {
    id: 3,
    text: 'landing.header.linkThree',
    transition: 'strengths'
  },
  {
    id: 4,
    text: 'landing.header.linkFour',
    transition: 'reviews'
  }
]);
