<template>
  <div
    class="lp-audio-track"
    ref="container"
    id="visual-container"
    @touchstart="changingTime = true"
    @mousedown="changingTime = true"
  >
    <div
      class="lp-audio-track-bar"
      :class="{
        'lp-audio-track-bar_mobile': isMobile,
        'lp-audio-track-bar_student': !isTeacher
      }"
      v-for="(data, i) in audioData"
      :key="data"
    >
      <div
        class="lp-audio-track-bar__value"
        :class="{
          'lp-audio-track-bar__value_disabled': !url,
          'lp-audio-track-bar__value_active': expectTime >= (i / audioData.length) * 100,
          'lp-audio-track-bar__value_active_student': expectTime >= (i / audioData.length) * 100 && !isTeacher,
          'lp-audio-track-bar__value_active_pause': expectTime >= (i / audioData.length) * 100 && isPause && !isTeacher
        }"
        :style="{height: `${data * 100}%`}"
      />
    </div>
  </div>
</template>

<script>
import { computed, inject, nextTick, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { debounce, random } from 'lodash';
import { ResizeObserver } from 'resize-observer';
import { useStore } from 'vuex';

export default {
  name: 'AudioVisualization',
  props: {
    url: String,
    currentDur: Number,
    totalDur: Number,
    isTeacher: Boolean,
    isPause: Boolean,
    videoWidth: Number
  },
  setup (props, { emit }) {
    const container = ref(null);
    const audioData = ref([]);
    const audioBufferCache = ref(null);
    const progress = computed(() => (props.currentDur / props.totalDur) * 100);
    const audioEngine = inject('audioEngine');
    const expectTime = ref(0);
    const changingTime = ref(false);
    watch(progress, () => {
      if (changingTime.value) return;
      expectTime.value = progress.value;
    });

    const filterData = audioBuffer => {
      const samples = Math.round((container.value?.offsetWidth || 514) / 4);
      const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
      const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
      const filteredData = [];
      for (let i = 0; i < samples; i++) {
        let blockStart = blockSize * i; // the location of the first sample in the block
        let sum = 0;
        for (let j = 0; j < blockSize; j++) {
          sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
        }
        filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
      }
      const multiplier = Math.pow(Math.max(...filteredData), -1);
      return filteredData.map(n => n * multiplier); // normalize data;
    };

    const createAnalyser = async (url) => {
      try {
        emit('update:loading', true);
        const audioContext = audioEngine.audioCtx;
        fetch(url).
          then(response => response.arrayBuffer()).
          then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer)).
          then(audioBuffer => {
            audioBufferCache.value = audioBuffer;
            audioData.value = Object.values(filterData(audioBuffer));
            emit('update:loading', false);
          });
      } catch (err) {
        console.error(err.name, err.message);
        emit('update:loading', false);
      }
    };

    watch(() => props.url, () => {
      expectTime.value = 0;
      changingTime.value = false;
      audioBufferCache.value = null;
      if (props.url) {
        createAnalyser(props.url);
      }
    });

    const correctAudioData = () => {
      const target = container.value;
      if (!target?.offsetWidth) return;
      audioData.value = [];
      nextTick(() => {
        const newCount = Math.round(target.offsetWidth / 4);
        if (!props.url) {
          audioData.value = Array(newCount).fill(1).map(() => random(0.5, 1));
        } else if (audioBufferCache.value) {
          audioData.value = filterData(audioBufferCache.value);
        }
      });
    };
    const correctAudioDataDebounce = computed(() => debounce(correctAudioData, 500));

    watch(() => props.videoWidth, () => {
      correctAudioDataDebounce.value();
    });

    const resizeObserver = ref(null);
    watch(container, () => {
      const samples = Math.round((container.value?.offsetWidth || 514) / 4);
      audioData.value = Array(samples).fill(1).map(() => random(0.5, 1));

      if (container.value && !resizeObserver.value && !isMobile.value) {
        resizeObserver.value = new ResizeObserver(correctAudioDataDebounce.value);
        resizeObserver.value.observe(container.value);
      }
    });

    const changePosition = (percent) => {
      if (!props.isTeacher) return;
      const seconds = props.totalDur * (percent / 100);
      if (seconds < 0) {
        emit('change', 0);
      } else {
        emit('change', seconds);
      }
    };

    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    const calcPercentByPageX = (pageX) => {
      const { innerWidth } = window;
      let zoomFactor = 1;
      if (innerWidth >= 1921 && innerWidth < 2160) {
        zoomFactor = 1.25;
      } else if (innerWidth >= 2160 && innerWidth < 2560) {
        zoomFactor = 1.5;
      } else if (innerWidth >= 2560) {
        zoomFactor = 2;
      }

      const { left, width } = container.value?.getBoundingClientRect();
      return ((pageX - (left * zoomFactor)) / (width * zoomFactor)) * 100;
    };

    const handleTouchMove = (e) => {
      if (!changingTime.value) return;
      const { pageX } = e.targetTouches[0];
      expectTime.value = calcPercentByPageX(pageX);
    };

    const handleTouchEnd = (e) => {
      if (!changingTime.value) return;
      const { pageX } = e.changedTouches[0];
      const percent = calcPercentByPageX(pageX);
      expectTime.value = percent;
      changePosition(percent);
      changingTime.value = false;
    };

    const handleMouseMove = (e) => {
      if (!changingTime.value) return;
      const { pageX } = e;
      expectTime.value = calcPercentByPageX(pageX);
    };

    const handleMouseUp = (e) => {
      if (!changingTime.value) return;
      const { pageX } = e;
      const percent = calcPercentByPageX(pageX);
      expectTime.value = percent;
      changePosition(percent);
      changingTime.value = false;
    };

    onBeforeMount(() => {
      window.addEventListener('resize', correctAudioDataDebounce.value, false);
      window.addEventListener('mousemove', handleMouseMove, false);
      window.addEventListener('mouseup', handleMouseUp, false);
      window.addEventListener('touchmove', handleTouchMove, false);
      window.addEventListener('touchend', handleTouchEnd, false);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', correctAudioDataDebounce.value, false);
      window.removeEventListener('mousemove', handleMouseMove, false);
      window.removeEventListener('mouseup', handleMouseUp, false);
      window.addEventListener('touchmove', handleTouchMove, false);
      window.addEventListener('touchend', handleTouchEnd, false);
    });

    return {
      expectTime,
      isMobile,
      audioData,
      container,
      progress,
      changingTime,
      changePosition,
      handleTouchMove,
      handleTouchEnd
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-audio-track {
  width: 100%;
  max-width: calc(100vw - 20px);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: $color-shark;
  color: $color-white;

  &-bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 59px;
    height: 100%;
    width: 4px;
    max-width: 4px;
    min-width: 4px;
    background-color: $color-shark;
    padding: 0 1px;
    cursor: pointer;

    &:first-child {
      padding-left: 16px;
      margin-left: -16px;
    }

    &_student {
      cursor: default;
    }

    &_mobile {
      min-height: 29px;
    }

    &__value {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      background-color: $color-outer-space;
      transition: background-color 500ms ease-in-out;

      &_active {
        background-color: $color-white;

        &_student {
          background-color: $color-emerald;
        }

        &_pause {
          background-color: rgba($color-white, 30%)
        }
      }

      &_disabled {
        background: linear-gradient(
            180deg, $color-outer-space 0%,
            rgba($color-outer-space, 0.69) 57.81%,
            rgba($color-outer-space, 0) 100%
        );
      }
    }
  }
}

</style>
