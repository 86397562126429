<template>
  <div
    class="lp-calendar-lesson-card"
    :class="{'lp-calendar-lesson-card_mobile': mobile}"
    @click.stop
  >
    <div
      class="lp-calendar-lesson-card-header"
      v-if="mobile"
    >
      <div
        class="lp-calendar-lesson-card-header__icon"
        @click="closeLessonForm"
      >
        <arrowBackIcon />
      </div>
      <div
        v-if="!isGroupLesson"
        class="lp-calendar-lesson-card-header__title"
      >
        {{ $t('global.lessonWith') }} {{ !isGroupLesson ? studentName : '' }}
      </div>
      <div v-else>
        {{ $t('crm.lessons.groupLesson') }}
      </div>
      <div
        class="lp-calendar-lesson-card-header__icon"
        @click.stop="openLessonForm"
      >
        <pencilIcon />
      </div>
    </div>
    <div class="lp-calendar-lesson-card__date">
      <DateLessonCard :lesson="lesson" />
      <crossIcon
        @click.stop="$emit('update:open', false)"
        v-if="!mobile"
      />
    </div>
    <div class="lp-calendar-lesson-card__student">
      <StudentLessonCard
        :lesson="lesson"
        :isLessonGroup="isLessonGroup"
      />
    </div>
    <div
      v-if="!isLessonGroup"
      class="lp-calendar-lesson-card__link"
    >
      <LinkLessonCard
        :lesson="lesson"
        :mobile="mobile"
      />
    </div>
    <div
      class="lp-calendar-lesson-card__subscribe"
      v-if="subscription && !isTrialLesson &&
        lesson.crmLessons.length === 1 && lesson.crmLessons[0].crmLessonPassId"
    >
      <StudentSubscription
        :subscription="subscription"
        :mobile="mobile"
        :lessons="true"
      />
    </div>
    <div
      class="lp-calendar-lesson-card__cost"
      v-else
    >
      <CostLessonCard
        v-if="lesson.crmLessons && lesson.crmLessons.length > 1 ||
          (lesson.crmLessons.length === 1 && !lesson.crmLessons[0].crmLessonPassId)"
        :lesson="lesson"
        :mobile="mobile"
      />
    </div>
    <div
      class="lp-calendar-lesson-card__comment"
      :class="{
        'lp-calendar-lesson-card__comment_mobile': mobile,
        'lp-calendar-lesson-card__comment_empty': !lesson.comment && mobile
      }"
    >
      <CommentLessonCard
        :lesson="lesson"
        :mobile="mobile"
      />
    </div>
    <div
      class="lp-calendar-lesson-card__toolbar"
      :class="{'lp-calendar-lesson-card__toolbar_mobile': mobile}"
    >
      <ToolbarLessonCard
        :mobile="mobile"
        :buttons="mobile"
        :lesson="lesson"
        @delete="$emit('delete')"
        @get-lessons="$emit('get-lessons')"
        @close-calendar-card="closeCalendarCard"
      />
    </div>
  </div>
</template>

<script>

import DateLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/DateLessonCard';
import StudentLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/StudentLessonCard';
import LinkLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/LinkLessonCard';
import CommentLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/CommentLessonCard';
import ToolbarLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/ToolbarLessonCard';
import CostLessonCard from '@/components/CRM/Lessons/Calendar/CalendarLessonCard/CostLessonCard';
import { crossIcon } from '@/constants/icons';
import { arrowBackIcon, pencilIcon } from '@/constants/icons/crm';
import { computed, onBeforeMount, watch, ref } from 'vue';
import StudentSubscription from '@/components/CRM/Students/StudentCard/StudentSubscription';
import timeout from '@/constants/utils/timeout';
import StudentApi from '@/api/CRM/students';

export default {
  name: 'CalendarLessonCard',
  components: {
    ToolbarLessonCard,
    CommentLessonCard,
    CostLessonCard,
    LinkLessonCard,
    StudentLessonCard,
    DateLessonCard,
    StudentSubscription,
    crossIcon,
    pencilIcon,
    arrowBackIcon
  },
  props: {
    mobile: Boolean,
    lesson: Object
  },
  emits: ['update:open', 'update:form', 'delete', 'get-lessons'],
  setup (props, { emit }) {
    const subscription = ref(null);

    const isGroupLesson = computed(() => {
      return props.lesson.crmLessons.length > 1;
    });

    const studentName = computed(() => {
      return !isGroupLesson.value ? props.lesson.crmLessons[0].crmStudentFullName : null;
    });

    const studentWithPass = async () => {
      if (props.lesson.crmLessons[0]) {
        const student = await StudentApi.getStudentById(props.lesson.crmLessons[0].crmStudentId);
        return student;
      }
    };

    const hideElementUnderModal = (val) => {
      const el = document.querySelector('.mc-container');
      if (!el) return;
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
      el.style.height = val ? '0' : '100%';
    };

    const isTrialLesson = ref(false);

    const updateSubscriptionInfo = async () => {
      if (!isLessonGroup.value) {
        const { crmLessons = [] } = props.lesson;
        const [currentLesson = {}] = crmLessons;
        const { isTrial } = currentLesson;
        isTrialLesson.value = isTrial;
        const { crmLessonPassId } = currentLesson;
        subscription.value = crmLessonPassId || null;
      }
    };

    watch(() => props.lesson, async () => {
      await updateSubscriptionInfo();
      if (isLessonGroup.value) {
        subscription.value = null;
      }
    });

    onBeforeMount(async () => {
      await updateSubscriptionInfo();
      if (props.mobile) {
        window.scroll(0,0);
        hideElementUnderModal(true);
      }
    });

    const closeCalendarCard = () => {
      console.log('closeCalendarCard');
      emit('update:open', false);
    };

    const openLessonForm = async () => {
      console.log('openLessonForm');
      emit('update:form', true);
      await timeout(250);
      closeCalendarCard();
    };

    const closeLessonForm = () => {
      console.log('closeLessonForm');
      emit('update:open', false);
      hideElementUnderModal(false);
    };

    const isLessonGroup = computed(() => props.lesson.crmStudents.length > 1);

    return {
      openLessonForm,
      closeCalendarCard,
      studentName,
      subscription,
      isLessonGroup,
      isTrialLesson,
      isGroupLesson,
      closeLessonForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar-lesson-card {
  @include global-font;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  width: 370px;
  padding: 20px 22px;
  cursor: default;

  &_mobile {
    padding: 80px 12px 20px;
    top: 0;
    left: 0;
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    height: auto;
    max-height: none;
    box-shadow: none;
  }

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-columns: 52px 1fr 52px;
    align-items: center;
    border-bottom: 1px solid $color-alto;
    background-color: $color-white;
    z-index: 4;

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      word-break: break-word;
      margin: 5px 0;
      text-align: center;
      color: $color-text;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      cursor: pointer;
    }
  }

  &__date {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;

    svg {
      fill: $color-alto;
      transition: fill 0.2s ease-in;
      cursor: pointer;

      &:hover {
        fill: $color-silver;
      }
    }
  }

  &__student {
    margin-bottom: 24px;
  }

  &__link {
    margin-bottom: 15px;
  }

  &__cost {
    margin-bottom: 18px;
  }

  &__comment {
    margin-bottom: 20px;
    height: 100%;

    &_mobile {
      flex-grow: 1;
    }

    &_empty {
      opacity: 0;
    }
  }

  &__toolbar {
    margin-top: auto;
    margin-bottom: 0;

    &_mobile {
      position: fixed;
      left: 12px;
      right: 12px;
      bottom: 0;
      padding-bottom: 12px;
      z-index: 100;
      background-color: $color-white;
    }
  }
}

</style>
