<template>
  <div class="custom-time-picker">
    <div class="custom-time-picker__label">
      {{ $t(label) }}
    </div>
    <div class="custom-time-picker__content">
      <div
        class="custom-time-picker__item"
        ref="timePicker"
      >
        <div
          class="custom-time-picker-input lp-input"
          :style="{width: timePickerInputWidth}"
          @click="toggleDropDownList"
        >
          <div class="custom-time-picker-input__value">
            {{ activeHour > 9 ? activeHour : `0${activeHour}` }}
            :
            {{ activeMinute > 9 ? activeMinute : `0${activeMinute}` }}
          </div>
          <div class="custom-time-picker-input__icon">
            <clockIcon />
          </div>
        </div>
        <transition name="fade-fast">
          <div
            v-if="isShowDropDownList"
            class="custom-time-picker-drop-down"
            ref="timePickerDropDown"
            :style="position"
          >
            <div class="custom-time-picker-drop-down-wrap">
              <div
                ref="hoursList"
                class="custom-time-picker-drop-down-list"
              >
                <div
                  class="custom-time-picker-drop-down-list__item"
                  :class="{
                    'custom-time-picker-drop-down-list__item_active':
                      activeHour === hour
                  }"
                  v-for="(hour, i) in timePickerSettings.hoursRange"
                  :key="`hour-${hour}`"
                  :ref="
                    (el) => {
                      if (el) hoursRef[i] = el;
                    }
                  "
                  @click="setActiveHour(hour)"
                >
                  {{ hour > 9 ? hour : `0${hour}` }}
                </div>
              </div>
              <div
                class="custom-time-picker-drop-down-list"
                ref="minutesList"
              >
                <div
                  class="custom-time-picker-drop-down-list__item"
                  :class="{
                    'custom-time-picker-drop-down-list__item_active':
                      activeMinute === minute
                  }"
                  v-for="(minute, i) in timePickerSettings.minuteRangeArr"
                  :key="`minute-${minute}`"
                  @click="setActiveMinute(minute)"
                  :ref="
                    (el) => {
                      if (el) minutesRef[i] = el;
                    }
                  "
                >
                  {{ minute > 9 ? minute : `0${minute}` }}
                </div>
              </div>
            </div>
            <div class="custom-time-picker-drop-down-buttons">
              <div
                class="custom-time-picker-drop-down-buttons__item custom-time-picker-drop-down-buttons__item_close"
                @click="onClose"
              >
                <closeModalIcon />
              </div>
              <div
                class="custom-time-picker-drop-down-buttons__item"
                @click="onOk"
              >
                <okIcon />
              </div>
            </div>
          </div>
        </transition>
        <transition name="bounce">
          <div
            class="subscription-modal__error lp-error"
            :class="{'lp-error_two-lines': $t(error).length > 30 && isMobile}"
            v-if="error"
          >
            <dangerIcon />
            {{ $t(error) }}
          </div>
        </transition>
      </div>
      <div
        v-if="timePickerSettings.showDash"
        class="custom-time-picker__dash"
      />
    </div>
  </div>
</template>

<script>
import { computed, nextTick, onBeforeMount, ref } from 'vue';
import {
  clockIcon,
  closeModalIcon,
  okIcon,
  dangerIcon
} from '@/constants/icons';
import MobileDetect from 'mobile-detect';

export default {
  name: 'CustomTimePicker',
  components: {
    clockIcon,
    closeModalIcon,
    okIcon,
    dangerIcon
  },
  props: {
    timePickerSettings: Object,
    modelValue: String,
    label: String,
    error: [String, Boolean]
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const timePicker = ref(null);
    const defaultValue = ref(props.modelValue);

    const timePickerInputWidth = ref('100%');

    const hoursRef = ref([]);
    const hoursList = ref(null);

    const timePickerDropDown = ref(null);
    const position = ref({
      top: 0,
      left: 0
    });

    const minutesRef = ref([]);
    const minutesList = ref(null);

    const isShowDropDownList = ref(false);

    const clickedOutside = (e) => {
      if (
        isShowDropDownList.value &&
        timePicker.value &&
        !timePicker.value.contains(e.target)
      ) {
        onClose();
      }
    };

    const toggleDropDownList = () => {
      if (!isShowDropDownList.value) {
        isShowDropDownList.value = true;
        document.addEventListener('click', clickedOutside);
        document.activeElement.blur();
        const [modal] = document.getElementsByClassName('lp-modal-content');
        if (modal) {
          modal.addEventListener('click', clickedOutside);
        }
        const {
          x,
          bottom,
          top: inputTop,
          width: inputWidth
        } = timePicker.value.getBoundingClientRect();
        const top = `${bottom + 7}px`;
        const left = `${x}px`;
        const width = `${inputWidth}px`;
        position.value = {
          top,
          left,
          width
        };
        const { hoursRange, minuteRangeArr } = props.timePickerSettings;
        const activeHourIndex = hoursRange.findIndex(
          (hour) => hour === activeHour.value
        );
        const activeMinuteIndex = minuteRangeArr.findIndex(
          (minute) => minute === activeMinute.value
        );
        nextTick(() => {
          const { height } = timePickerDropDown.value.getBoundingClientRect();
          const { innerHeight } = window;
          if (height + bottom > innerHeight) {
            const topNumber = inputTop - height - 7;
            const top = `${topNumber - height < 0 ? 10 : topNumber}px`;
            position.value = {
              ...position.value,
              top
            };
          }
          const { y: yHoursList } = hoursList.value.getBoundingClientRect();
          const activeHourEl = hoursRef.value[activeHourIndex];
          const { y: yActiveHour } = activeHourEl.getBoundingClientRect();
          hoursList.value.scrollTop = yActiveHour - yHoursList;
          const { y: yMinutesList } = minutesList.value.getBoundingClientRect();
          const activeMinuteEl = minutesRef.value[activeMinuteIndex];
          const { y: yActiveMinute } = activeMinuteEl.getBoundingClientRect();
          minutesList.value.scrollTop = yActiveMinute - yMinutesList;
        });
      } else {
        onClose();
      }
    };

    const activeHour = ref(5);
    const setActiveHour = (hour) => {
      activeHour.value = hour;
    };

    const activeMinute = ref(0);
    const setActiveMinute = (minute) => {
      activeMinute.value = minute;
    };

    const onClose = () => {
      if (!isShowDropDownList.value) return;
      isShowDropDownList.value = false;
      document.removeEventListener('click', clickedOutside);
      const [modal] = document.getElementsByClassName('lp-modal-content');
      if (modal) {
        modal.removeEventListener('click', clickedOutside);
      }
      initializationTimeValue();
    };

    const onOk = () => {
      isShowDropDownList.value = false;
      const hour =
        activeHour.value < 10 ? `0${activeHour.value}` : activeHour.value;
      const minute =
        activeMinute.value < 10 ? `0${activeMinute.value}` : activeMinute.value;
      const value = `${hour}:${minute}`;
      defaultValue.value = value;
      emit('update:modelValue', value);
    };

    const initializationTimeValue = () => {
      const time = props.modelValue || '11:00';
      const [hour, minute] = time.split(':');
      setActiveHour(+hour);
      setActiveMinute(+minute);
    };

    onBeforeMount(() => {
      const {
        timePickerSettings: { inputWidth = '100%' },
        modelValue
      } = props;
      defaultValue.value = modelValue;
      timePickerInputWidth.value = inputWidth;
      initializationTimeValue();
    });

    return {
      timePicker,
      timePickerDropDown,
      activeHour,
      activeMinute,
      isShowDropDownList,
      hoursRef,
      hoursList,
      minutesRef,
      minutesList,
      position,
      timePickerInputWidth,
      onClose,
      onOk,
      setActiveHour,
      setActiveMinute,
      toggleDropDownList,
      isMobile
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.custom-time-picker {
  &__label {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 5px 0;
    height: 14px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__item {
    position: relative;
  }

  &__dash {
    width: 8px;
    height: 1px;
    background: $color-mine-shaft;
    position: absolute;
    right: -13px;
  }
}

.custom-time-picker-input {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 18px;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &__icon {
    display: flex;
  }
}

.custom-time-picker-drop-down {
  position: fixed;
  z-index: 25;
  left: 1px;
  top: 50px;
  width: 100%;
  font-weight: 400;
  border-radius: 6px;
  box-shadow: 0 0 15px rgba($color-black, 0.05);
  background: $color-white;
}

.custom-time-picker-drop-down-wrap {
  display: flex;
}

.custom-time-picker-drop-down-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  overflow: auto;
  max-height: 176px;

  &__item {
    text-align: center;
    padding: 5px 0;
    color: $color-mine-shaft;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: $color-white;
      color: $color-purple;
    }

    &_active {
      pointer-events: none;
      background: $color-purple !important;
      color: $color-white !important;
    }
  }
}

.custom-time-picker-drop-down-buttons {
  background: $color-white;
  height: 36px;
  width: 100%;
  border-top: 1px solid $color-alto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.input-error {
  border-color: $color-red;
}
</style>

<style lang="scss">
@import '~@/sass/variables';

.custom-time-picker-drop-down-buttons {
  //&__item {
  //
  //  &_close {
  //    svg path {
  //      fill: $color-red;
  //    }
  //  }
  //}
}
</style>
