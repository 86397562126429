<template>
  <div
    class="stp-calendar-lesson"
    :class="[
      `stp-calendar-lesson_${mode}`,
      {
        'stp-calendar-lesson_past': isComplete,
        'stp-calendar-lesson_student-reason': isFinishedByStudentReason,
        'stp-calendar-lesson_all-students': showAllStudents === lesson._id
      }
    ]"
    ref="lessonEl"
    @click.stop="openLessonCard"
    v-click-outside="closeInfo"
    @mouseenter="handlerHoverDay(lesson._id)"
    @mouseleave="handlerHoverDay('')"
  >
    <div
      class="stp-calendar-lesson__indicator"
      :class="{
        'stp-calendar-lesson__indicator_past': isComplete,
        'stp-calendar-lesson__indicator_trial': isTrialLesson
      }"
      :style="{backgroundColor: lesson.color}"
    />
    <div
      class="stp-calendar-lesson-header"
      :class="[`stp-calendar-lesson-header_${mode}`]"
    >
      <div
        class="stp-calendar-lesson__time"
        :class="[`stp-calendar-lesson__time_${mode}`]"
        :style="{fontSize}"
      >
        <div :class="{'stp-calendar-lesson__time_small': fontSize}">
          {{ startTime }} - {{ endTime }}
        </div>
        <LPTooltip
          class="stp-calendar-lesson__trial-icon"
          :text="$t('crm.lessons.form.trial')"
          v-if="isTrialLesson && mode === 'month'"
        >
          <TrialIconGray v-if="isComplete" />
          <TrialIcon v-else />
        </LPTooltip>
      </div>
    </div>
    <div
      class="stp-calendar-lesson-price-subscribe"
      :class="[`stp-calendar-lesson-price-subscribe_${mode}`]"
      v-if="subscription && mode !== 'month'"
    >
      <div class="stp-calendar-lesson-price-subscribe__icon">
        <LPTooltip :text="$t('crm.subscriptions.tooltip')">
          <subscriptionsIcon :style="{width: fontSize, height: fontSize}" />
        </LPTooltip>
      </div>
      <div
        v-if="mode === 'day'"
        class="stp-calendar-lesson-price-subscribe-list"
        :class="{
          'stp-calendar-lesson-price-subscribe-list_sm': lessonDuration <= 20
        }"
      >
        <div
          class="stp-calendar-lesson-price-subscribe-list__item"
          :class="[`stp-calendar-lesson-price-subscribe-list__item_${mode}`]"
          :style="{width: fontSize, height: fontSize}"
          v-for="item in subscriptionDay"
          :key="item"
        >
          <checkIcon v-if="item <= subscription.finishedLessonsCount" />
          <div
            v-if="subscription.amountOfLessons > 10"
            class="stp-calendar-lesson-price-subscribe-list__modal"
          >
            <div class="stp-calendar-lesson-price-subscribe-list">
              <div
                class="stp-calendar-lesson-price-subscribe-list__item"
                :class="[
                  `stp-calendar-lesson-price-subscribe-list__item_${mode}`
                ]"
                v-for="item in subscription.amountOfLessons"
                :key="item"
              >
                <checkIcon v-if="item <= subscription.finishedLessonsCount" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="stp-calendar-lesson-price-cost_day"
      v-if="mode === 'day' && !subscription && !isTrialLesson"
    >
      <span>{{ `${$t('calendar.lesson.cost')}: ${lesson.crmLessonCost} ${lesson.currency}` }}</span>
    </div>
    <div
      v-else-if="mode === 'week' && !subscription"
      :class="[`stp-calendar-lesson-price-cost_${mode}`]"
    >
      {{ `${lesson.crmLessonCost} ${lesson.currency}` }}
    </div>
    <div
      v-if="isTrialLesson"
      class="stp-calendar-lesson-price"
      :class="[
        `stp-calendar-lesson-price_${mode}`,
        {'stp-calendar-lesson-price_past': isComplete}
      ]"
    >
      <span
        class="stp-calendar-lesson-price__text"
        :class="[`stp-calendar-lesson-price__text_${mode}`]"
        :style="{fontSize}"
      >
        {{
          isGroupLesson
            ? $t('crm.lessons.costForEachStudent')
            : $t('calendar.lesson.cost')
        }}:
      </span>
      <span
        :style="{fontSize}"
        :class="{
          'stp-calendar-lesson-price-trial': isTrialLesson,
          'stp-calendar-lesson-price-trial_xs':
            lessonDuration === 15 && mode === 'day' && isTrialLesson
        }"
      >
        <LPTooltip
          :text="$t('crm.lessons.form.trial')"
          v-if="isTrialLesson"
        >
          <TrialIconGray v-if="isComplete" />
          <TrialIcon v-else />
        </LPTooltip>
        <template v-else>
          {{ price }}
        </template>
      </span>
    </div>
    <transition name="bounce">
      <teleport to="#app">
        <StudentCalendarLessonCard
          :class=" `stp-calendar-lesson__info_${getStyleInfo().className}`"
          :style="getStyleInfo().style"
          v-model:open="openInfo"
          v-model:form="openForm"
          :lesson="lesson"
          v-if="openInfo"
          @get-lessons="$emit('get-lessons')"
        />
      </teleport>
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { get } from 'lodash';
import { checkIcon, subscriptionsIcon, TrialIcon, TrialIconGray } from '@/constants/icons/crm';
import FORMATS from '@/constants/formats';
import numeral from 'numeral';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import StudentCalendarLessonCard from '@/components/StudentProfile/StudentCalendar/StudentCalendarLessonCard';

export default {
  name: 'StudentCalendarLesson',
  components: {
    LPTooltip,
    StudentCalendarLessonCard,
    subscriptionsIcon,
    checkIcon,
    TrialIcon,
    TrialIconGray
  },
  props: {
    lesson: Object,
    mode: String,
    height: Number,
    fontSize: String,
    isZoomed: Boolean
  },
  emits: ['update:open', 'get-lessons'],
  setup (props, { emit }) {
    const showTooltip = ref(null);
    const hoverStudentName = (item) => {
      showTooltip.value = item;
    };

    const startTime = computed(() =>
      moment(props.lesson.crmLessonStart).format('HH:mm')
    );
    const endTime = computed(() =>
      moment(props.lesson.crmLessonEnd).format('HH:mm')
    );
    const price = computed(() => {
      const value = numeral(props.lesson.crmLessonCost).format(FORMATS.FINANCE);
      return `${value} ${props.lesson.currency}`;
    });
    const isTrialLesson = computed(() => {
      const { isTrial } = props.lesson;
      return isTrial;
    });
    const isComplete = computed(
      () => get(props.lesson, 'crmLessonStatus', '') === 'Complete'
    );
    const isFinishedByStudentReason = computed(() =>
      get(props.lesson, 'finishedByStudentReason', false)
    );

    const showAllStudents = ref(null);

    const studentsList = computed(() => {
      return Object.values(props.lesson.crmLessons);
    });

    const limitedSymbols = 23;

    const limitedStudents = 7;

    const studentsListLimited = computed(() => {
      const lessonsLimited = Object.values(studentsList.value).slice(
        0,
        limitedStudents
      );
      return Object.values(studentsList.value).length > limitedStudents
        ? lessonsLimited
        : Object.values(studentsList.value);
    });

    const isPast = computed(() =>
      moment().isAfter(moment(props.lesson.crmLessonStart))
    );

    const isGroupLesson = computed(() => {
      return props.lesson.crmLessons.length > 1;
    });

    const studentName = computed(() => {
      if (!isGroupLesson.value)
        return props.lesson.crmLessons[0].crmStudentFullName;
      else return null;
    });

    const openForm = ref(false);

    const openLessonCard = () => {
      openInfo.value = openInfo.value
        ? false
        : props.mode === 'month' || props.mode === 'week';
      showAllStudents.value = null;
    };

    const openInfo = ref(false);
    const closeInfo = (e) => {
      showAllStudents.value = null;
      if (!openInfo.value) return;
      const path = get(e, 'path', []);
      const pathLength = get(path, 'length', 0);
      if (!pathLength) return;
      const classes = path.map((el) => (el ? el.className : ''));
      const isModal =
          classes.find(
            (item) => typeof item === 'string' && item.includes('stp-modal')
          ) ||
          classes.find(
            (item) =>
              typeof item === 'string' && item.includes('stp-modal-question')
          );
      if (isModal) return;
      openInfo.value = false;
    };
    watch(openInfo, (val) => {
      emit('update:open', val);
    });

    const lessonEl = ref(null);
    const getStyleInfo = () => {
      if (!lessonEl.value) return {};
      const comment = get(props.lesson, 'comment', '');
      const subscribe = get(props.lesson, 'crmLessonPassId.amountOfLessons', 0);
      let infoSizeHeight = 349;
      if (comment) infoSizeHeight += 113;
      if (subscribe) infoSizeHeight += (subscribe / 10) * 25;
      const height = subscription.value ? infoSizeHeight + 165 : infoSizeHeight;
      const infoSize = { width: 327, height };
      const documentWidth = window.innerWidth;
      const documentHeight = window.innerHeight;
      const position = lessonEl.value.getBoundingClientRect();
      const marginLeft = 9;
      const marginTop = 0;
      const top =
          marginTop + position.top <= 400 ? marginTop + position.top : 400;
      const left = position.left + position.width + marginLeft;
      let className = '';
      const style = {
        position: 'fixed',
        top: `${top}px`,
        left: `${left}px`
      };
      if (left + infoSize.width > documentWidth) {
        className = 'right';
        style.left = `${position.left - marginLeft - infoSize.width}px`;
      }

      if (top + infoSize.height + 50 > documentHeight) {
        className += 'bot';
        const positionDelta = documentHeight - top - infoSize.height - 50;
        style.top = `${top + positionDelta}px`;
      }
      return {
        style,
        className
      };
    };

    const subscription = ref(null);

    const subscriptionDay = computed(() => {
      if (!subscription.value) return;
      return subscription.value.amountOfLessons > 10
        ? 10
        : subscription.value.amountOfLessons;
    });

    const lessonDate = computed(() => get(props.lesson, 'crmLessonStart', ''));

    const dayHoverId = ref('');

    const handlerHoverDay = (id) => {
      dayHoverId.value = id;
    };

    const lessonDuration = computed(() => {
      const lessonStart = props.lesson.crmLessonStart
        ? moment(props.lesson.crmLessonStart).valueOf()
        : null;
      const lessonEnd = props.lesson.crmLessonEnd
        ? moment(props.lesson.crmLessonEnd).valueOf()
        : null;
      const timeDiff = lessonEnd - lessonStart;
      return moment.duration(timeDiff)._milliseconds / 60000;
    });

    const styleName = computed(() => {
      return {
        fontSize: props.fontSize,
        webkitLineClamp: props.height > 35 ? (props.height > 70 ? 3 : 2) : 1,
        maxWidth: null
      };
    });
    onBeforeMount(async () => {
      const { crmLessons = [] } = props.lesson;
      if (crmLessons.length === 1) {
        const [currentLesson] = crmLessons;
        const { crmLessonPassId } = currentLesson;
        subscription.value = crmLessonPassId;
      }
    });

    watch(
      () => props.lesson,
      async () => {
        const { crmLessons = [] } = props.lesson;
        if (crmLessons.length === 1) {
          const [currentLesson] = crmLessons;
          const { crmLessonPassId } = currentLesson;
          subscription.value = crmLessonPassId;
        } else {
          subscription.value = null;
        }
      }
    );

    return {
      styleName,
      startTime,
      endTime,
      price,
      lessonEl,
      openInfo,
      openForm,
      isComplete,
      isPast,
      isFinishedByStudentReason,
      closeInfo,
      getStyleInfo,
      subscription,
      lessonDate,
      subscriptionDay,
      dayHoverId,
      handlerHoverDay,
      isTrialLesson,
      showTooltip,
      hoverStudentName,
      studentsList,
      isGroupLesson,
      studentName,
      lessonDuration,
      studentsListLimited,
      limitedSymbols,
      limitedStudents,
      showAllStudents,
      openLessonCard
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';
@import '~styles/_mixins.scss';

.stp-calendar-lesson {
  @include global-font;
  display: grid !important;
  position: relative;
  padding: 3px 16px 3px 14px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 2px;
  color: $color-text;
  text-align: left;
  transition: background-color 0.3s ease-in;
  cursor: pointer;

  &:hover:not(&_past) &__indicator {
    background-color: rgba($color-accent, 0.8);
  }

  &:hover:not(&_past) &__indicator_trial {
    background-color: $color-selective-yellow;
  }

  &_past {
    color: $color-gray;
  }

  &_week {
    display: flex;
    flex-direction: column;
    padding: 10px 16px 10px 20px;
  }

  &_day {
    padding: 0 16px 0 20px;
    align-items: center;
    grid-template-columns:
      min(100px)
      auto
      minmax(260px, 390px)
      minmax(104px, 1fr);
    grid-gap: 40px;
    cursor: default;
    top: -1px;
    z-index: 1;
    color: $color-black;
  }

  &_all-students {
    z-index: 10;
  }

  &__info {
    position: absolute;
    left: calc(100% + 9px);
    padding-bottom: 0;
    top: 0;
    z-index: 3;

    &_right {
      &:before {
        border-right: 6px solid $color-white;
        border-left: 6px solid $color-white;
        left: auto;
        right: -6px;
      }
    }

    &_bot {
      &:before {
        top: auto;
        bottom: 8px;
      }
    }
  }

  &__indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    border-radius: 2px 0 0 2px;
    background-color: $color-accent;
    transition: background-color 0.3s;

    &_trial {
      background-color: $color-supernova;
    }

    &_past {
      background-color: $color-gray !important;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_day div {
      font-size: 14px;
      font-weight: 700;
    }

    &_week {
      margin-bottom: 14px;
    }
  }

  &__approve {
    display: none;

    &_week {
      display: block;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;

    &_small {
      display: flex;
    }

    &_completed {
      margin: 0 0 0 5px;
    }

    &_day {
      line-height: 1;
    }

    &_week {
      font-size: 14px;
    }

    &_month,
    &_week {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__trial-icon {
    display: flex;
    align-items: center;
    align-self: center;
    margin-left: 6px;
    margin-right: 3px;
  }

  &__not-complete,
  &__student-reason {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    margin-left: auto;
    margin-right: 0;

    &_week {
      margin-left: 10px;
      margin-right: auto;
    }

    &_day {
      margin-left: 10px;
      margin-right: auto;
    }
  }

  &__student-reason {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;

    &_day {
      height: 100%;
      min-height: 100%;
    }
  }

  &-price {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-sine-shaft;
    display: none;

    &_past {
      color: $color-gray;
    }

    &_week {
      margin-bottom: 10px;
      display: block;
    }

    &_day {
      display: flex;
      flex-wrap: wrap;
      line-height: 1;
    }

    &__text {
      display: none;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      align-items: center;
      color: $color-gray;

      &_day {
        display: inline-block;
        margin-right: 4px;
        font-weight: 700;
        color: $color-black;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  &-link {
    display: none;

    &_day {
      display: block;
    }
  }

  &-info {
    display: none;
    grid-template-columns: 18px 18px;
    grid-gap: 12px;
    align-items: center;

    &_day {
      display: grid;
    }

    &-comment {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &_disabled {
        filter: invert(77%) sepia(12%) saturate(0%) hue-rotate(180deg)
        brightness(106%) contrast(72%);
        pointer-events: none;
      }

      &:hover:not(&_disabled) &__popup {
        transform: scale(1);
      }

      &__popup {
        position: absolute;
        top: 25px;
        right: 0;
        padding: 16px 18px;
        background: $color-white;
        box-shadow: 0 0 17px rgba($color-black, 0.08);
        border-radius: 4px;
        max-height: 150px;
        font-weight: 500;
        font-size: 14px;
        line-height: 125%;
        color: $color-text;
        z-index: 10;
        overflow: auto;
        transform: scale(0);
        transition: max-content 0.2s ease-in;
        min-width: 250px;

        &_short {
          min-width: max-content;
        }
      }
    }

    &__repeat {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-toolbar {
    display: none;

    &_day {
      display: block;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    padding-left: 17px;

    &_sm {
      max-height: 15px;
    }
  }

  &-user {
    $this: &;
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: -15px;
    background-color: $color-white;
    border: 2px solid $color-white;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    &_md {
      svg,
      img {
        width: 24px;
        height: 24px;
      }
    }

    &_sm {
      font-size: 12px;
      border-top: 0;
      border-bottom: 0;
      margin-left: -12px;

      svg,
      img {
        width: 24px;
        height: 24px;
      }
    }

    &-name {
      margin-left: 6px;
      word-break: break-word;

      &_mt {
        margin-top: 7px;
      }

      &__tooltip {
        bottom: 40px;
      }
    }

    &_last {
      width: 26px;
      height: 26px;
      margin-left: -14px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid $color-white;
      font-size: 10px;
      font-weight: 700;
      background-color: $color-purple;
      color: $color-white;
      z-index: 1;
      cursor: pointer;
      position: relative;

      &_sm {
        width: 15px;
        height: 15px;
        margin-left: -12px;
        font-size: 8px;
      }
    }

    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
    }

    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      word-wrap: break-word;
      width: calc(100% - 40px);
    }
  }

  &-font-size-small {
    font-size: 70%;
    line-height: 100%;
  }
}

.stp-calendar-lesson-price-cost {

  &_day {
    font-size: 14px;
    font-weight: 700;
  }

  &_week {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 3px;
  }
}

.stp-calendar-lesson-price-subscribe {
  display: grid;
  grid-template-columns: 23px auto;
  grid-gap: 15px;
  padding: 5px 0;

  &_day {
    padding: 0;
    grid-gap: 3px;
  }

  &_week {
    padding: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
  }
}

.stp-calendar-lesson-price-subscribe-list {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(10, 25px);
  position: relative;

  &:hover .stp-calendar-lesson-price-subscribe-list__modal {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
    transition: opacity 0.3s ease-in;
  }

  &__modal {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background: $color-white;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 100;
    border-radius: 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.015);
  }

  &__item {
    width: 25px;
    height: 25px;
    border: 1px solid $color-alto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_day {
      border-radius: 0;
    }
  }

  &_sm {
    grid-template-columns: repeat(10, 14px);
  }
}

.stp-calendar-lesson-price-trial {
  position: relative;
  display: inline-flex;

  &_xs {
    svg {
      height: 11px;
    }
  }
}
</style>

<style lang="scss">
.stp-calendar-lesson-price-subscribe-list {
  svg {
    width: 12px;
    height: 12px;
  }
}

.stp-calendar-lesson-font-size-small {
  svg {
    width: 12px;
    height: 12px;
  }
}
</style>
