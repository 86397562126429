<template>
  <div
    class="lp-list-empty"
    :class="{'lp-list-empty_pointer': button}"
  >
    <div class="lp-list-empty__wrapper">
      <img
        class="lp-list-empty__image"
        alt="placeholder"
        :src="imageUrl"
        :style="{
          width: isMobile ? '80vw': `${widthImg}px`
        }"
      />
      <slot></slot>
    </div>
    <div
      class="lp-list-empty__title"
      v-if="title"
    >
      {{ $t(title) }}
    </div>
    <div class="lp-list-empty__text">
      <div v-if="text">
        {{ $t(text) }}
      </div>
      <div v-if="description">
        {{ $t(description) }}
      </div>
    </div>
    <button
      v-if="button"
      class="lp-list-empty__button lp-button"
      @click="$emit('handler-click')"
    >
      {{ $t(button) }}
    </button>
  </div>
</template>

<script>

import MobileDetect from 'mobile-detect';
import { computed } from 'vue';

export default {
  name: 'ListEmpty',
  props: {
    imageUrl: String,
    widthImg: String,
    title: String,
    text: String,
    description: String,
    button: String
  },
  emits: ['handler-click'],
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-list-empty {
  @include global-font;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
  user-select: none;

  &_pointer {
    pointer-events: auto;
  }

  &__image {
    object-fit: cover;
    height: auto;
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;

    &_mb {
      margin-top: 20px;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
  }

  &__button {
    margin-top: 28px;
  }

  &__wrapper {
    position: relative;
    margin-bottom: 28px;
  }
}

</style>
