<template>
  <div
    class="lp-files-header-nav"
    v-if="!modal || !mobile"
    :class="{
      'lp-files-header-nav_mobile': mobile,
      'lp-files-header-nav_mobile-modal': mobile && modal
    }"
  >
    <div @click="changeParentFolder(0)">
      <span
        class="lp-files-header-nav-title"
        :class="{
          'lp-section-title lp-section-title_large': !path.length && !mobile,
          'lp-files-header-nav-item__name': path.length,
          'lp-section-title lp-files-header-nav-item__name_mobile': mobile
        }"
      >
        {{ $t('myFiles.title') }}
      </span>
    </div>
    <template v-if="!mobile">
      <transition-group name="slideX">
        <div
          class="lp-files-header-nav-item"
          v-for="(folder, index) of path"
          :key="folder._id"
          @click="changeParentFolder(index + 1)"
        >
          <div class="lp-files-header-nav-item__arrow">
            <arrowLeftIcon />
          </div>
          <div class="lp-files-header-nav-item__name">
            {{ folder.name }}
          </div>
        </div>
      </transition-group>
    </template>
  </div>
</template>

<script>
import { arrowLeftIcon } from '@/constants/icons/files';

export default {
  name: 'MyFilesHeaderNav',
  components: {
    arrowLeftIcon
  },
  props: {
    modal: Boolean,
    mobile: Boolean,
    path: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:path'],
  setup (props, { emit }) {
    const changeParentFolder = (index) => {
      if (!props.path.length && !index) return;
      emit('update:path', props.path.slice(0, index));
    };

    return { changeParentFolder };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-files-header-nav {
  @include global-font;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  user-select: none;

  &_mobile {
    min-height: 32px;
  }

  &-item {
    display: inline-grid;
    grid-template-columns: 12px auto;
    grid-gap: 6px;
    align-items: center;
    justify-content: center;
    user-select: none;

    &__name {
      padding: 6px 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-accent;
      white-space: nowrap;
      transition: 0.15s ease-out;
      cursor: pointer;
      margin-right: 6px;

      &_mobile {
        color: $color-text;
        padding: 0 8px 0 0;
      }

      &:hover {
        border-radius: 4px;
        background-color: $color-moon-raker;
        color: $color-text;
      }
    }

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      cursor: not-allowed;
    }

    &:last-child &__name {
      pointer-events: none;
    }
  }
}

</style>
