<template>
  <div
    class="lp-widget-signup"
    @click="openModal = !openModal"
  >
    <div
      v-if="!noTitle"
      class="lp-widget-signup-title"
    >
      <plusIcon class="lp-widget-signup-title__plus" />
      {{ $t('timetable.canSingUp') }}
    </div>
    <Modal
      :title="signed || openErrorModal ? '' : 'timetable.canSingUp'"
      @save="saveForm"
      @remove="closeForm"
      :hideButtons="['remove']"
      :saveBtnText="signed || openErrorModal ? 'buttons.ok' : 'buttons.signUp'"
      :mobileVersion="isMobile"
      :editMode="isMobile"
      :minHeight="true"
      :removeScrollOnMobile="removeScrollOnMobile"
      v-model:open="openModal"
    >
      <div
        class="lp-widget-signup-modal"
        :class="{'lp-widget-signup-modal_mobile': isMobile}"
      >
        <div v-if="!signed && !openErrorModal">
          <div
            v-if="!noTitle"
            class="lp-widget-signup-modal__title"
          >
            {{ $t('timetable.lessonDate') }}
            <span class="lp-widget-signup-modal__date">
              {{ date }} {{ $t('global.in') }} {{ time }}
            </span>
          </div>
          <Form
            @submit="saveForm"
            @filled="inputChange"
            :inputs="signUpLessonInputs"
            :item="item"
            :signUp="true"
          />
          <transition name="bounce">
            <div
              class="lp-widget-signup-modal__error lp-error"
              v-if="error"
            >
              <dangerIcon />
              {{ $t(error) }}
            </div>
          </transition>
        </div>
        <div
          class="lp-widget-signup-modal-error"
          v-else-if="openErrorModal"
        >
          <img
            class="lp-widget-signup-modal-error__image"
            :src="errorImg"
            alt="error"
          />
          <div class="lp-widget-signup-modal-error__text">
            {{ $t('timetable.signedUp.notAvailableTime') }}
          </div>
        </div>
        <div
          class="lp-widget-signup-modal-success"
          v-else
        >
          <img
            class="lp-widget-signup-modal-success__image"
            :class="{'lp-widget-signup-modal-success__image_mobile': isMobile}"
            :src="successImg"
            alt="success"
          />
          <div class="lp-widget-signup-modal-success__title">
            {{ $t('timetable.signedUp.title') }}
          </div>
          <div class="lp-widget-signup-modal-success__text">
            {{ $t('timetable.signedUp.text') }}
          </div>
          <div class="lp-widget-signup-modal-success__description">
            {{ $t('timetable.signedUp.description') }}
            {{ date }} {{ $t('global.in') }} {{ time }}.
            <br />
            {{ teacherName }}
            {{ $t('timetable.signedUp.teacher') }}
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { plusIcon, dangerIcon } from '@/constants/icons/crm';
import { computed, ref, watch, onBeforeMount } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import moment from 'moment';
import signUpLessonInputs from '@/constants/inputs/signUpLessonInputs';
import validateProfile from '@/constants/validatesForm/validateProfile';
import LessonsApi from '@/api/CRM/lessons';
import { FRONTEND_URL } from '@/constants/domains';
import uniqid from 'uniqid';
import { get } from 'lodash';
import country from '@/constants/country';
import successImg from '@/assets/images/success.png';
import errorImg from '@/assets/images/schedule-error.png';
import { useStore } from 'vuex';
import { getErrorText } from '@/constants/utils/setErrorText';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import MobileDetect from 'mobile-detect';
import SettingsApi from '@/api/CRM/settings';
import { useRouter } from 'vue-router';

export default {
  name: 'WidgetSignUpB',
  components: {
    Form,
    Modal,
    plusIcon,
    dangerIcon
  },
  props: {
    teacher: Object,
    item: Object,
    startTime: Boolean,
    endTime: Boolean,
    noTitle: Boolean,
    isOpenModal: Boolean,
    openError: Boolean,
    setTime: Object || Array
  },
  emits: ['signed', 'close', 'sent', 'time-changed', 'check-time'],
  setup (props, { emit }) {
    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const removeScrollOnMobile = computed(() => {
      return !isMobile.value;
    });

    const teacherId = computed(() => get(router, 'currentRoute.value.params.id', ''));
    const router = useRouter();

    const openErrorModal = ref(false);
    const openModal = ref(false);
    watch(openModal, () => setDefaultValues());
    watch(openModal, (val) => {
      if (!val) {
        openErrorModal.value = false;
        error.value = false;
        emit('close');
      }
    });

    const showInvalidTime = () => {
      const timePickerInput = document.querySelector('.custom-time-picker-input');
      if (timePickerInput) {
        timePickerInput.classList.add('input-error');
      }
    };
    const hideInvalidTime = () => {
      const timePickerInput = document.querySelector('.custom-time-picker-input');
      if (timePickerInput && timePickerInput.classList.contains('input-error')) {
        timePickerInput.classList.remove('input-error');
      }
    };

    const lessonDuration = ref('');
    const date = ref('');
    const time = ref('');
    const teacherName = computed(() => {
      const firstName = get(props.teacher, 'firstName', '');
      const lastName = get(props.teacher, 'lastName', '');
      return `${firstName} ${lastName}`;
    });
    watch(() => props.isOpenModal, (val) => {
      openModal.value = val;
    });

    const getPhoneString = (obj) => {
      const phoneObj = get(obj, 'phone', {});
      const countryCode = get(country.find(item => item.code === phoneObj.country), 'phone', '');
      return `+${countryCode}${phoneObj.number}`;
    };

    const signed = ref(false);
    const error = ref(false);

    const saveLocalStorage = (body) => {
      localStorage.setItem('widget_data', JSON.stringify(body));
    };
    const getLocalStorage = () => {
      return JSON.parse(localStorage.getItem('widget_data'));
    };

    const setModelValue = ref(null);

    const isTimeCorrect = ref(true);
    const inputChange = (inputs) => {
      inputs.forEach(item => {
        if (item.type === 'timePicker') {
          emit('time-changed', item.modelValue);

          setModelValue.value = item.modelValue;

          const setTimesList = Object.values(props.setTime);
          const startTimeVal = setTimesList.find(item => item.startTime === setModelValue.value);
          const startTimeValCheck = startTimeVal ? startTimeVal.startTime : null;
          const checkStart = setTimesList.find(item => item.time === startTimeValCheck);

          if (checkStart) {
            !checkStart.isTimeFree ? showInvalidTime() : hideInvalidTime();
            isTimeCorrect.value = true;

            const endTime = checkStart.time;
            const endTimeValCheck = setTimesList.find(item => item.startTime === endTime);
            const fiveMinutesToEnd = moment(endTimeValCheck.endTime, 'HH:mm').subtract(5, 'minutes').
              format('HH:mm');
            const endTimeVal = setTimesList.find(item => item.time === fiveMinutesToEnd);

            if (endTimeVal) {
              !endTimeVal.isTimeFree || !checkStart.isTimeFree ? showInvalidTime() : hideInvalidTime();
            }
          } else {
            isTimeCorrect.value = false;
            showInvalidTime();
          }
          emit('check-time', isTimeCorrect.value);
        }
      });
    };
    const saveForm = async () => {
      if (openErrorModal.value && props.openError) {
        openErrorModal.value = false;
        openModal.value = false;
        return;
      } else if (openErrorModal.value) {
        openErrorModal.value = false;
        error.value = false;
        return;
      }
      emit('signed', props.item.date);

      if (signed.value) {
        closeForm();
        return;
      }

      console.log(signUpLessonInputs);

      const body = await validateProfile(signUpLessonInputs);

      if (!body) return;
      body.teacherId = props.teacher._id;
      body.virtualClassUrl = `${FRONTEND_URL}/room/${uniqid()}`;
      console.log(body);
      body.phoneString = getPhoneString(body);

      time.value = body.time;
      date.value = moment( body.dates.date[0]).format('DD MMMM');

      const lessonDate = moment(body.dates.date[0]).format('YYYY-MM-DD');
      const lessonStartTime = moment(lessonDate + ' ' + body.time, 'YYYY-MM-DD HH:mm');
      const lessonStartTimeUTC = moment(lessonStartTime).utc().format();

      body.crmLessonStart = lessonStartTimeUTC;
      body.crmLessonEnd = moment(lessonStartTimeUTC).add(lessonDuration.value, 'minutes').utc().format();

      if (moment(body.crmLessonStart).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
          moment(body.time, 'hh:mm').hours() === moment().hours() &&
          moment(body.time, 'hh:mm').minutes() < moment().minutes()) {
        openErrorModal.value = true;
        return;
      } else if (moment(body.crmLessonStart).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') &&
          moment(body.time, 'hh:mm').hours() < moment().hours()) {
        openErrorModal.value = true;
        return;
      }

      saveLocalStorage(body.time);

      await setLoaderRun(true);
      try {
        const { data } = await LessonsApi.signUpLesson(body);
        body.studentId = data.crmStudentId;
        saveLocalStorage(body);

        yandexTracker.signUpLesson();
        await setLoaderRun(false);
        signed.value = true;

        emit('sent', body);
      } catch (err) {
        console.error(err);
        error.value = getErrorText(err);
        await setLoaderRun(false);
        openErrorModal.value = true;
      }
    };
    const closeForm = () => {
      openModal.value = false;
    };

    const setDefaultValues = async () => {
      if (props.openError) {
        openErrorModal.value = true;
        return;
      };
      const defaultData = getLocalStorage();
      signed.value = false;
      signUpLessonInputs.forEach((input) => {
        const value = get(defaultData, input.modelName, input.defaultValue);
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;

        if (input.modelName === 'dates') {
          const date = moment(props.item.fullDate || '').format('YYYY-MM-DD');
          input.modelValue = { date: [date], time: [] };
        }

        if (input.modelName === 'time') {
          const crmLessonStart = props.item.startTime;
          input.modelValue = crmLessonStart ? crmLessonStart : '13:00';
        }
      });
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const getSettings = async () => {
      try {
        const { data } = await SettingsApi.getSettingsUnauthorized(teacherId.value);
        lessonDuration.value = data.defaultLessonLength || 45;
      } catch (e) {
        return e;
      }
    };

    onBeforeMount(() => {
      getSettings();
    });

    return {
      error,
      teacherName,
      successImg,
      errorImg,
      signed,
      date,
      time,
      saveForm,
      closeForm,
      openModal,
      isMobile,
      signUpLessonInputs,
      openErrorModal,
      inputChange,
      removeScrollOnMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-widget-signup {
  @include global-font;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  user-select: none;

  &-title {
    display: grid;
    grid-template-columns: 12px auto;
    grid-gap: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: $color-white;
  }

  &-modal {
    position: relative;
    padding: 24px 20px 0;
    min-width: 675px;

    &_mobile {
      min-width: 100vw;
    }

    &__title {
      margin-bottom: 28px;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      color: $color-black;
    }

    &__date {
      color: $color-accent;
    }

    &-success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 125%;
      color: $color-text;

      &__image {
        max-width: 424px;
        height: auto;
        object-fit: cover;

        &_mobile {
          max-width: 100vw;
        }
      }

      &__title {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 20px;
      }

      &__text {
        margin-bottom: 16px;
        font-weight: bold;
        font-size: 16px;
      }

      &__description {
        font-weight: 500;
        font-size: 14px;
      }
    }

    &-error {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__text {
        max-width: 400px;
        margin-top: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: $color-mine-shaft-dark;
      }
    }

    &__error {
      top: auto;
      bottom: -18px;
      left: 20px;
    }
  }
}

</style>
