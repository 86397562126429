<template>
  <div
    class="custom-color-picker"
  >
    <div
      class="custom-color-picker__label lp-label"
      v-if="label"
    >
      {{ $t(label) }}
    </div>
    <div
      class="custom-color-picker-wrap"
      ref="customColorPicker"
      @click.stop="toggleIsShowSelectColorList"
    >
      <div
        class="custom-color-picker-wrap__item"
        :style="{background: modelValue}"
      />
      <div
        class="custom-color-picker-wrap__icon"
        :class="{'custom-color-picker-wrap__icon_open': isShowSelectColorList}"
      >
        <arrowIcon />
      </div>
    </div>
  </div>

  <transition name="fade-fast">
    <div
      class="custom-color-picker-container"
      :class="`custom-color-picker-container_${modeDevice}`"
      ref="colorPickerList"
      v-if="isShowSelectColorList"
      :style="positionSelectColorPickerList"
    >
      <div
        v-if="modeDevice === MODE_MOBILE"
        class="custom-color-picker-container-header"
      >
        <div
          @click="closeColorPicker"
          class="custom-color-picker-container-header__icon"
        >
          <closeModalIcon />
        </div>
        <div class="custom-color-picker-container-header__title">
          {{ $t('colorPicker.title') }}
        </div>
        <div
          @click="saveLessonColor"
          class="custom-color-picker-container-header__icon custom-color-picker-container-header__icon_save"
        >
          <checkIcon />
        </div>
      </div>
      <div
        class="custom-color-picker-list-wrap"
        :class="`custom-color-picker-list-wrap_${modeDevice}`"
      >
        <transition name="fade-fast">
          <div
            v-if="isShowCustomColorSelection"
            class="custom-color-picker-custom-selection"
          >
            <!--            :style="{top: customSelectionTopPosition}"-->
            <div
              v-if="modeDevice !== MODE_MOBILE"
              class="custom-color-picker-custom-selection-header"
            >
              <div
                @click="closeColorPicker"
                class="custom-color-picker-custom-selection-header__icon"
              >
                <closeModalIcon />
              </div>
            </div>
            <ChromeColorPicker
              v-model:color="color"
              :disableAlpha="true"
              :disableHistory="true"
              :buttonText="$t('buttons.save')"
              :showSaveButton="true"
              :zoom="zoomColorPicker"
              :modalContent="'lp-modal-content'"
              @changeColor="changeValueColor"
            />
          </div>
        </transition>
        <div
          class="custom-color-picker-list"
          :class="`custom-color-picker-list_${modeDevice}`"
        >
          <template v-for="item in colorsList">
            <div
              v-if="modeDevice !== MODE_MOBILE || item.color"
              :key="item.id"
              class="custom-color-picker-list-item"
              :style="{background: item.color}"
              @click="changeColor(item.color)"
            >
              <div
                v-if="!item.color || item.color.toLowerCase() === modelValue.toLowerCase()"
                class="custom-color-picker-list-item__icon"
                :class="{'custom-color-picker-list-item__icon_custom': !item.color}"
              >
                <ColorPickerIcon v-if="!item.color" />
                <checkIcon v-else />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';
import { ChromeColorPicker } from 'musio-colorpicker';
import 'musio-colorpicker/dist/style.css';
import { arrowIcon, ColorPickerIcon, checkIcon } from '@/constants/icons/crm';
import colorsList from '@/constants/crm/colorsList';
import { closeModalIcon } from '@/constants/icons';
import { MODE_MOBILE, MODE_PC, MODE_TABLET } from '@/constants/deviceMode/deviceMode';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';

export default {
  name: 'ColorPicker',
  components: {
    arrowIcon,
    ColorPickerIcon,
    checkIcon,
    ChromeColorPicker,
    closeModalIcon
  },
  props: {
    modelValue: String,
    label: String,
    error: [String, Boolean]
  },
  setup (props, { emit }) {
    const md = new MobileDetect(window.navigator.userAgent);
    const modeDevice = computed(() => {
      const isPc = !(!!md.mobile() || !!md.tablet());
      const isMobile = !!md.mobile();
      const mode = isMobile && !!md.tablet() ? MODE_TABLET : MODE_MOBILE;
      return isPc ? MODE_PC : mode;
    });

    const color = computed({
      get () {
        return props.modelValue;
      },
      set (data) {
        const { hex } = data;
        emit('update:modelValue', hex);
        closeColorPicker();
      }
    });

    const isCustomColor = ref(true);
    const customColorPicker = ref(null);

    const isShowSelectColorList = ref(false);

    const positionSelectColorPickerList = ref({});
    const customSelectionTopPosition = ref(0);

    const positionCalculation = () => {
      if (customColorPicker.value) {
        const { y, x, bottom } = customColorPicker.value.getBoundingClientRect();
        const { innerWidth, innerHeight } = window;
        const isPc = modeDevice.value === 'pc';
        const containerWidth = isPc ? 270 : 368;
        const containerHeight = isPc ? 70 : 110;
        const top = bottom +  containerHeight + 17 > innerHeight ? y - containerHeight : bottom + 3;
        const left = x + containerWidth + 10 > innerWidth ? x - containerWidth : x;

        positionSelectColorPickerList.value = {
          top: `${top}px`,
          left: `${left}px`
        };
        customSelectionTopPosition.value = top - 383 < (isPc ? 10 : 65) ? 0 : -381;
      }
    };

    const colorPickerList = ref(null);

    watch(isShowSelectColorList, (newVal) => {
      const [modalContent] = document.getElementsByClassName('lp-modal-content');
      const isModal = !!(modalContent && modalContent.contains(customColorPicker.value));
      // if (modeDevice.value !== MODE_MOBILE) {
      if (newVal) {
        if (isModal) {
          modalContent.addEventListener('click', clickOutside);
        } else {
          document.addEventListener('click', clickOutside);
        }
      } else {
        isShowCustomColorSelection.value = false;
        if (isModal) {
          modalContent.removeEventListener('click', clickOutside);
        } else {
          document.removeEventListener('click', clickOutside);
        }
      }
      // }
    });

    const clickOutside = ({ target }) => {
      if ((colorPickerList.value && !colorPickerList.value.contains(target)) || colorPickerList.value === target) {
        isShowSelectColorList.value = false;
      }
    };

    const toggleIsShowSelectColorList = () => {
      isShowSelectColorList.value = !isShowSelectColorList.value;
      if (isShowSelectColorList.value) {
        if (modeDevice.value === 'mobile') {
          isShowCustomColorSelection.value = true;
        } else {
          positionCalculation();
        }
      }
    };

    const isShowCustomColorSelection = ref(false);

    const valueColor = ref('');

    const changeValueColor = (color) => {
      const { hex } = color;
      valueColor.value = hex;
    };

    watch(() => props.modelValue, (newVal) => {
      valueColor.value = newVal;
    });

    onBeforeMount(() => {
      valueColor.value = props.modelValue;
    });

    const changeColor = (value) => {
      if (value) {
        emit('update:modelValue', value);
        isShowSelectColorList.value = false;
      } else {
        if (!isShowCustomColorSelection.value) {
          isShowCustomColorSelection.value = true;
          const { top: topString, left } = positionSelectColorPickerList.value;
          const top = `${+topString.replace('px', '') + customSelectionTopPosition.value}px`;
          positionSelectColorPickerList.value = {
            left,
            top
          };
        }
      }
    };

    const closeColorPicker = () => {
      isShowCustomColorSelection.value = false;
      isShowSelectColorList.value = false;
    };

    const saveLessonColor = () => {
      changeColor(valueColor.value);
      closeColorPicker();
    };

    const zoomColorPicker = computed(() => detectNeedZoom() ? 0.8 : 1);
    return {
      color,
      colorsList,
      isCustomColor,
      isShowSelectColorList,
      positionSelectColorPickerList,
      customColorPicker,
      colorPickerList,
      customSelectionTopPosition,
      isShowCustomColorSelection,
      modeDevice,
      MODE_MOBILE,
      zoomColorPicker,
      changeColor,
      changeValueColor,
      toggleIsShowSelectColorList,
      closeColorPicker,
      saveLessonColor
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.custom-color-picker {

  &__label {
    margin-bottom: 4px;
  }

}

.custom-color-picker-wrap {
  display: inline-grid;
  grid-template-columns: 18px 12px;
  grid-gap: 17px;
  padding: 12px;
  background: $color-white;
  border: 1px solid $color-alto;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    .custom-color-picker__icon {
      color: $color-accent-hover;
    }
  }

  &__item {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: background .15s ease-in;
  }

  &__icon {
    display: flex;
    align-items: center;
    transition: transform .2s ease-in, color .2s ease-in;
    transform: rotate(180deg);
    color: $color-accent;

    &_open {
      transform: rotate(0);
    }

  }

 }

.custom-color-picker-container {
  position: fixed;
  z-index: 15;
  top: 150px;
  left: 800px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.11);
  border-radius: 4px;
  overflow: hidden;

  &_mobile {
    width: 100%;
    height: 100%;
    top: 0!important;
    left: 0!important;
    box-shadow: none;
    border-radius: 0;
  }
}

.custom-color-picker-container-header {
  display: grid;
  grid-gap: 15px;
  padding: 16px 17px;
  align-items: center;
  grid-template-columns: 20px 1fr 20px;
  border-bottom: 1px solid $color-gallery;

  &__icon {
    display: flex;
    cursor: pointer;
    transition: color .2s ease-in;
    color: $color-alto;

    &:hover {
      color: $color-silver-chalice;
    }

    &_save {
      color: $color-emerald;

      &:hover {
        color: $color-salem;
      }
    }

  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: $color-mine-shaft-dark;
  }

}

.custom-color-picker-list-wrap {

  &_mobile {
    max-width: 375px;
    margin: 0 auto;
  }

}

.custom-color-picker-list {
  display: grid;
  grid-template-columns: repeat(9, 18px);
  grid-gap: 7px 9px;
  padding: 16px;

  &_tablet, &_mobile {
    grid-template-columns: repeat(7, 32px);
    grid-gap: 16px 20px;

    .custom-color-picker-list-item {
      height: 32px;
      width: 32px;
    }

    @media (max-width: 374px) {
      grid-template-columns: repeat(7, 24px);

      .custom-color-picker-list-item {
        height: 24px;
        width: 24px;
      }

    }

  }

}

.custom-color-picker-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: transparent;
  transition: transform .2s ease-in;
  cursor: pointer;

  &__icon {
    display: flex;
    width: 8px;
    height: 8px;
    color: $color-white;

    &_custom {
      width: 10px;
      height: 10px;
      color: $color-mine-shaft;
    }

  }

  &:hover {
    transform: scale(1.2);
  }

}

.custom-color-picker-custom-selection {
  //position: absolute;
  //left: 0;
  width: 100%;
  background: $color-white;

  &__body {

  }

}

.custom-color-picker-custom-selection-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  border-bottom: 1px solid $color-gallery;

  &_mobile {
    display: grid;
    grid-gap: 15px;
    padding: 16px 17px;
    align-items: center;
    grid-template-columns: 20px 1fr 20px;
  }

  &__icon {
    display: flex;
    cursor: pointer;
    transition: color .2s ease-in;
    color: $color-alto;

    &:hover {
      color: $color-silver-chalice;
    }

    &_save {
      color: $color-emerald;

      &:hover {
        color: $color-salem;
      }
    }

  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: $color-mine-shaft-dark;
  }

}
</style>

<style lang="scss">
.custom-color-picker-wrap__icon, .custom-color-picker-list-item__icon {
  path {
    fill: currentColor;
  }
}

.custom-color-picker-list-item__icon {
  svg {
    width: 100%;
    height: 100%;
  }
}

.custom-color-picker-custom-selection {
  .bee-chrome-colorPicker {
    width: 100%;
    box-shadow: none;
  }
}

.custom-color-picker-custom-selection-header__icon {
  path {
    fill: currentColor;
  }
}

.custom-color-picker-list-wrap_mobile {
  .bee-saturation {
    height: 257px;
    margin-bottom: 16px;

    @media (max-height: 640px){
      height: 188px;
    }

  }

  .bee-hue-colorPicker {
    height: 28px;
  }

  .bee-chrome-colorPicker-body {
    grid-gap: 24px;
  }

  .bee-hue-colorPicker__inner-handle {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  .bee-saturation__cursor div {
    width: 15px;
    height: 15px;
  }
}
</style>