<template>
  <div class="lp-custom-range">
    <div class="lp-custom-range-header">
      <label
        class="lp-label"
        :class="{
          'lp-label_required': required
        }"
        :for="label"
      >
        {{ label ? $t(label) : '' }}
      </label>
      <div class="lp-custom-range-header__value">
        {{ result }}
      </div>
    </div>
    <div class="lp-custom-range-filed">
      <VueSlider
        v-model="result"
        :min="minRange"
        :max="minMax"
        :marks="marks"
        :dotSize="16"
        :height="3"
        :tooltip="tooltip"
        @change="onChange"
      />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'CustomInput',
  components: {
    VueSlider
  },
  props: {
    modelValue: Number,
    label: String,
    settings: Object,
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const result = ref(0);
    const setDefault = () => {
      if (props.modelValue) result.value = props.modelValue;
    };
    const onChange = (value) => {
      emit('update:modelValue', value);
    };

    const minRange = props.settings.min || 0;
    const minMax = props.settings.max || 20;
    const marks = props.settings.marks || [];
    const tooltip = props.settings.tooltip || true;
    onBeforeMount(setDefault);

    return {
      result,
      onChange,
      minRange,
      minMax,
      marks,
      tooltip
    };
  }
};
</script>

<style lang="scss" >
@import '~@/sass/variables';
@import '~@/sass/mixins';
@import '~@/sass/components/vue-slider';

.lp-custom-range {
  @include global-font;
  display: grid;
  grid-template-rows: auto 42px;
  grid-gap: 20px;
  align-items: start;

}

.lp-custom-range-header {
  display: grid;
  grid-template-columns: auto min(40px);
  justify-content: start;
  align-items: center;
  grid-gap: 35px;

  &__value {
    min-width: 40px;
    font-size: 16px;
    padding: 3px 5px;
    background: $color-moon-raker;
    color: $color-accent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }

}

</style>