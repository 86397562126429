<template>
  <div
    class="lp-crm-accompaniment"
    :class="{'lp-crm-accompaniment_mobile': isMobile}"
  >
    <div class="lp-crm-accompaniment__content">
      <div
        class="lp-crm-accompaniment__header"
        :class="{'lp-crm-accompaniment__header_mobile': isMobile}"
      >
        <h1
          v-if="isMobile"
          class="lp-crm-accompaniment__title lp-crm-accompaniment__title_mobile"
        >
          {{ $t('titlePages.accompaniment') }}
        </h1>
      </div>
      <h1
        class="lp-crm-accompaniment__title"
        :class="{'lp-crm-accompaniment__title_mobile-header': isMobile}"
      >
        {{ $t('crm.pages.accompaniment') }}
      </h1>
      <div
        class="lp-crm-accompaniment__description"
        :class="{'lp-crm-accompaniment__description_mobile': isMobile}"
      >
        {{ $t('descriptionPages.accompaniment') }} <br>
        {{ $t('descriptionPages.accompanimentDetails') }}
      </div>
      <div
        class="lp-crm-accompaniment__button"
        @click="orderAccompaniment"
      >
        <div class="lp-button">
          {{ $t('buttons.orderAccompaniment') }}
        </div>
      </div>
      <div
        class="lp-crm-accompaniment__info"
        :class="{'lp-crm-accompaniment__info_mobile': isMobile}"
      >
        <div class="lp-crm-accompaniment__info-icon">
          <purpleStarIcon
            :class="{'lp-crm-accompaniment__info-icon_svg-mobile': isMobile}"
          ></purpleStarIcon>
        </div>
        {{ $t('crm.common.crmImprovements') }}
      </div>
      <div class="lp-crm-accompaniment__pianists">
        <div
          class="lp-crm-accompaniment__title"
          :class="{'lp-crm-accompaniment__title_mobile-sm': isMobile}"
        >
          {{ $t('titlePages.pianists') }}
        </div>
        <div
          class="lp-crm-accompaniment__pianists-wrapper"
          :class="{'lp-crm-accompaniment__pianists-wrapper_mobile': isMobile}"
        >
          <div
            v-for="pianist of pianists"
            :key="pianist.id"
            class="lp-crm-accompaniment__pianists-item"
          >
            <TeacherCard
              :teacher="pianist"
            />
          </div>
        </div>
      </div>
      <div
        class="lp-crm-accompaniment__button"
        @click="orderAccompaniment"
      >
        <div class="lp-button">
          {{ $t('buttons.orderAccompaniment') }}
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-model:open="openOrderForm"
    :removeBtnText="$t('crm.lessons.form.cancel')"
    :saveBtnText="$t('crm.lessons.form.leaveRequest')"
    :title="$t('buttons.orderAccompaniment')"
    :fullOnMobile="true"
    @save="sendRequest"
    @remove="closeModal"
  >
    <div class="lp-crm-accompaniment__form">
      <div
        class="lp-crm-accompaniment__form-description"
        :class="{'lp-crm-accompaniment__form-description_mobile': isMobile}"
      >
        {{ $t('crm.accompaniment.modal.description') }}
        {{ $t('crm.accompaniment.modal.descriptionDetails') }}
      </div>
      <div class="lp-crm-accompaniment__form-textarea">
        <CustomTextarea
          v-if="true"
          v-model:modelValue="description"
          :autofocus="true"
          :label="$t('crm.accompaniment.modal.taskDescription')"
          :error="textareaError"
          :customHeight="true"
          @focus="textareaError = null"
        ></CustomTextarea>
      </div>
      <div class="lp-crm-accompaniment__form-radio">
        <CustomRadio
          v-model:modelValue="notesRequired"
          :options="areNotesRequired"
          :label="$t('crm.accompaniment.modal.notesLabel')"
        >
        </CustomRadio>
      </div>

      <UploadInput
        v-if="notesRequired"
        v-model:file="attachedFile"
        v-model:limit="fileErrors.limitExceeded"
        :multiple="true"
        :maxSizeMB="10"
        @add-file="onAddFile"
      >
        <div class="lp-crm-accompaniment__form__attachment">
          <div
            v-if="!attachedFile"
            class="lp-crm-accompaniment__form-file"
          >
            <attachFileIcon />
            {{ $t('crm.accompaniment.modal.attachFile') }}
          </div>
          <div
            v-if="attachedFile"
            class="attachment"
            :class="{'attachment__uploading': status !== 1}"
          >
            <div
              class="attachment__file"
            >
              <filesIcon />
              {{ fileName }}
            </div>
            <div class="attachment__size">
              {{ convertToMb }} MB
              <closeModalIcon
                class="lp-crm-accompaniment__form-cancel lp-crm-accompaniment__form-cancel-sm"
                v-if="status === 1"
                @click.stop.prevent="removeFile"
              />
            </div>
          </div>
          <div
            v-if="status !== 1 && attachedFile"
            class="lp-crm-accompaniment__form-status"
          >
            <FileUploadStatus
              :file="attachedFile"
              :modal="true"
              :status="status"
            />
            <closeModalIcon
              class="lp-crm-accompaniment__form-cancel"
              @click.stop.prevent="attachedFile = null"
            />
          </div>
        </div>
      </UploadInput>
      <div class="lp-crm-accompaniment__form-info">
        <div class="lp-crm-accompaniment__form-wrapper">
          <alertIcon class="lp-crm-accompaniment__form-alert"></alertIcon>
          {{ $t('crm.accompaniment.modal.maxTime') }}
        </div>
        <div class="lp-crm-accompaniment__form-wrapper">
          <alertIcon class="lp-crm-accompaniment__form-alert"></alertIcon>
          {{ $t('crm.accompaniment.modal.serviceDetails') }}
        </div>
      </div>
    </div>
  </Modal>
  <Modal
    v-model:open="requestIsSuccessful"
    @save="requestIsSuccessful = false"
    :hideButtons="['remove']"
    :saveBtnText="$t('buttons.ok')"
  >
    <div
      class="lp-crm-accompaniment__success"
      :class="{'lp-crm-accompaniment__success_mobile': isMobile}"
    >
      <img
        :src="require(`@/assets/images/success-apply.png`)"
        alt="success"
      >
      <div class="lp-crm-accompaniment__success-thanks">
        {{ $t('landing.thanks') }}!
      </div>
      <div class="lp-crm-accompaniment__success-description">
        {{ $t('landing.apply.firstText') }} <br>
        {{ $t('landing.apply.secondTextVersion1') }}
      </div>
      <div class="lp-crm-accompaniment__success-details">
        <alertIcon></alertIcon>
        {{ $t('landing.apply.requestsInfo') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';
import TeacherCard from '@/components/CRM/Accompaniment/TeacherCard';
import pianists from '@/components/CRM/Accompaniment/pianists/pianists';
import Modal from '@/components/Main/Modal/Modal';
import CustomTextarea from '@/components/Main/Inputs/CustomTextarea';
import CustomRadio from '@/components/Main/Inputs/CustomRadio';
import { attachFileIcon, closeModalIcon, filesIcon, purpleStarIcon } from '@/constants/icons';
import { alertIcon } from '@/constants/icons/crm';
import UploadInput from '@/components/Main/Inputs/UploadInput';
import FileUploadStatus from '@/components/CRM/Files/FilesUploadList/FileUploadStatus';
import getFileBinary from '@/constants/utils/getFileBinary';
import EmailApi from '@/api/CRM/email';
import FILE_STATUS from '@/constants/enums/fileStatus';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { find, get } from 'lodash';
import country from '@/constants/country';

export default {
  name: 'Accompaniment',
  components: {
    TeacherCard,
    Modal,
    CustomTextarea,
    CustomRadio,
    purpleStarIcon,
    attachFileIcon,
    alertIcon,
    filesIcon,
    closeModalIcon,
    UploadInput,
    FileUploadStatus
  },

  setup () {
    const store = useStore();
    const { t } = useI18n();
    const openOrderForm = ref(false);
    const description = ref('');
    const notesRequired = ref(false);
    const attachedFile = ref(null);
    const requestIsSuccessful = ref(false);
    const textareaError = ref(null);
    const dragIn = ref('');
    const status = ref(1);
    const resp = ref('');

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    const fileName = computed(() => {
      return attachedFile.value[0].name.length <= 35 ?
        attachedFile.value[0].name : `${attachedFile.value[0].name.slice(0, 35)}...`;
    });

    const user = computed(() => store.getters.user);
    const convertToMb = computed(() => {
      return (attachedFile.value[0].size / 1000000).toFixed(1);
    });

    const fileErrors = reactive({
      limitExceeded: false,
      notImage: false
    });

    const areNotesRequired = reactive([
      { id: true, value: false, name: 'notes', label: t('crm.accompaniment.modal.hasNotes') },
      { id: false, value: true, name: 'notes', label: t('global.noCapital') }
    ]);

    const orderAccompaniment = () => {
      openOrderForm.value = true;
      yandexTracker.orderAccompaniment();
    };

    const closeModal = () => {
      openOrderForm.value = false;
      clearInputs();
    };

    const onAddFile = () => {
      status.value = 2;
      setTimeout(() => {
        status.value = 5;
        setTimeout(() => status.value = 1, 1300);
      }, 500);
    };

    const getDisplay = (model) => {
      const modelCountry = get(model, 'country', '');
      if (!modelCountry) return '';
      const modelNumber = get(model, 'number', '');
      const countryItem = find(country, { code: modelCountry }) || {};
      return countryItem.phone && modelNumber ? `+${countryItem.phone} ${modelNumber.replace(')', ') ')}` : '';
    };

    const sendRequest = async () => {
      if (!description.value) {
        textareaError.value = t('errors.emptyField');
      } else {
        const file = attachedFile.value ? attachedFile.value[0] : null;

        if (file && attachedFile.value) {
          const fileBinary = getFileBinary(file);

          try {
            resp.value = await EmailApi.postNotes(fileBinary);
          } catch (e) {
            console.error(e);
          }
        }

        getDisplay(user.value.phone);

        const phone = getDisplay(user.value.phone);
        const descriptionForEmail = description.value;
        const attachedFileForEmail = file ? resp.value.data.url : '';
        const { firstName: name, lastName: surname, email } = user.value;
        const body = {
          name,
          surname,
          phone,
          email,
          description: descriptionForEmail
        };
        body.notes = file ? attachedFileForEmail : 'Нет';

        try {
          await EmailApi.sendEmail({
            subject: 'Заказ аккомпанемента',
            text: generateTextMail(body),
            html: generateHtmlMail(body)
          });
          yandexTracker.leaveRequestForAccompaniment();
        } catch (e) {
          console.error(e);
        }
        ;

        openOrderForm.value = false;
        requestIsSuccessful.value = true;

        clearInputs();
      }
    };

    const generateTextMail = ({ name, surname, phone, email = '-', description, notes }) => {
      const fields = [`Имя: ${name}`, `Фамилия: ${surname}`, `Телефон: ${phone}`, `Email: ${email}`,
        `Описание: ${description}`, `Ноты: ${notes}`];
      return fields.join(';\n');
    };

    const generateHtmlMail = ({ name, surname, phone, email = '-', description, notes }) => {
      const fields = [`<p>Имя: ${name}</p>`, `<p>Фамилия: ${surname}</p>`, `<p>Телефон: ${phone}</p>`,
        `<p>Email: ${email}</p>`, `<p>Описание: ${description}</p>`, `Ноты: ${notes}`];
      return fields.join('');
    };

    const clearInputs = () => {
      description.value = '';
      textareaError.value = null;
      attachedFile.value = null;
      notesRequired.value = false;
    };

    const removeFile = () => {
      attachedFile.value = null;
      notesRequired.value = false;
    };

    onMounted(() => {
      yandexTracker.openOrderAccompaniment();
    });

    watch(openOrderForm, () => {
      if (!openOrderForm.value) {
        clearInputs();
      }
    });

    return {
      isMobile,
      pianists,
      openOrderForm,
      orderAccompaniment,
      description,
      notesRequired,
      areNotesRequired,
      attachedFile,
      sendRequest,
      requestIsSuccessful,
      fileErrors,
      closeModal,
      convertToMb,
      textareaError,
      dragIn,
      status,
      onAddFile,
      FILE_STATUS,
      fileName,
      removeFile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-accompaniment {
  padding-top: 20px;
  padding-bottom: 70px;
  color: $color-sine-shaft;

  &_mobile {
    padding-top: 0;
  }

  &__info {
    margin: 40px auto 0 auto;
    max-width: 475px;
    padding: 24px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    background: $color-white;
    box-shadow: $color-shadow;

    &_mobile {
      max-width: 343px;
      margin: 40px auto 0 auto;
      font-size: 12px;
    }

    &-icon {
      margin-right: 20px;

      svg {
        width: 33px;
        height: 33px;

        &_svg-mobile {
          width: 28px;
          height: auto;
        }
      }
    }
  }

  &__form {
    padding: 24px 20px 0 20px;

    &-status {
      margin-top: 8px;
      margin-right: 30px;
      display: flex;
    }

    &-description {
      max-width: 610px;
      padding: 0 70px;
      font-weight: 700;
      text-align: center;

      &_mobile {
        padding: 0 10px;
      }
    }

    &-textarea {
      margin-top: 28px;
    }

    &-info {
      margin-top: 20px;
      font-size: 12px;
    }

    &-alert {
      margin-right: 6px;
      flex: none;
      width: 16px;
      height: 16px;
    }

    &-file {
      margin-top: 24px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: $color-purple;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &-radio {
      margin-top: 28px;
    }

    &-cancel {
      margin-left: 8px;
      width: 12px;
      height: auto;
      cursor: pointer;

      &-sm {
        width: 8px;
      }
    }

  }

  &__content {
    @media (max-width: 1199px) {
      padding: 0 60px;
    }

    @media (max-width: 499px) {
      padding: 0;
    }
  }

  &__header {
    margin-bottom: 20px;

    &_mobile {
      position: sticky;
      top: 0;
      right: 0;
      left: 0;
      background: $color-white;
      width: 100vw;
      margin: 0 -60px 30px -60px;
      z-index: 10;
    }
  }

  &__button {
    max-width: 306px;
    margin: 60px auto 0 auto;
  }

  &__title {
    text-align: center;
    font-weight: bold;
    font-size: 36px;

    &_mobile {
      padding: 15px 20px 15px 50px;
      max-width: 900px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }

    &_mobile-header {
      font-size: 24px;
      padding: 0 16px;
      text-align: left;
    }

    &_mobile-sm {
      max-width: 900px;
      margin: 0 auto;
      padding: 0 16px;
      text-align: left;
      font-size: 22px;
    }
  }

  &__description {
    max-width: 900px;
    text-align: center;
    margin: 40px auto 0 auto;

    &_mobile {
      padding: 0 16px;
      text-align: left;
      line-height: 24px;
    }
  }

  &__pianists {
    margin-top: 118px;

    &-wrapper {
      max-width: 1060px;
      margin: 40px auto 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 1199px) {
        flex-direction: column;
        align-items: center;
      }

      &_mobile {
        margin-top: 20px;
      }
    }
  }

  &__success {
    width: 536px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &_mobile {
      max-width: 335px;
      padding: 0 35px;
    }

    &-thanks {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 700;
    }

    &-description {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 700;
    }

    &-details {
      display: flex;
      align-items: flex-start;
      margin-top: 16px;
      font-size: 12px;

      svg {
        margin-right: 8px;
      }
    }
  }
}

.attachment {
  margin-top: 32px;

  &__uploading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__file {
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 700;

    svg {
      margin-right: 8px;
    }
  }

  &__size {
    margin-top: 6px;
    font-size: 12px;
    color: $color-gray;
  }
}

body .carousel .carousel__pagination {
  display: none !important;
}
</style>
