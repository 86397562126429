import { uniqBy } from 'lodash';
import * as types from '@/constants/mutationTypes';

const state = {
  students: []
};
const getters = {
  students: (state) => state.students
};
const actions = {
  setStudentsTable ({ commit }, data) {
    commit(types.SET_STUDENTS, { data });
  },
  setStudentsList ({ commit }, data) {
    commit(types.SET_STUDENTS, {
      data: uniqBy(state.students.concat(data), '_id')
    });
  }
};
const mutations = {
  [types.SET_STUDENTS] (state, { data }) {
    state.students = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
