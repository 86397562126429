import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  createGroupLesson (lesson) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/group-lessons/create', lesson);
  },
  getGroupLessonById (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`crm/group-lessons/${id}`);
  },
  getGroupLessonsByDate ({ start, end, lost = false }) {
    const obj = {
      dateRangeStart: start,
      dateRangeEnd: end,
      showCompleted: lost
    };
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/group-lessons/getall/filterbydate', obj);
  },
  getGroupLessonsUnauthorized ({ start, end, lost = false }, teacherId) {
    const obj = {
      dateRangeStart: start,
      dateRangeEnd: end,
      showCompleted: lost
    };
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post(`crm/group-lessons/getall/filterbydate/unauthorized?teacherId=${teacherId}`, obj);
  },
  updateGroupLesson (lesson) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`crm/group-lessons/${lesson._id}`, lesson);
  },
  deleteGroupLesson (lessonId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`crm/group-lessons/${lessonId}`);
  },
  getLessonsByStudent ({ studentId, status, sort = { 'crmLessonStart': -1 }  }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`crmLessonStatus=${status}`, `sort=${JSON.stringify(sort)}`];
    return instCred.get(`crm/lessons/getall/status/${studentId}?${query.join('&')}`);
  },
  signUpLesson (lesson) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/group-lessons/signup/unauthorized', lesson);
  }
};
