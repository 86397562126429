<template>
  <div class="lp-crm-settings-currency">
    <CustomSelect
      :options="options"
      :fixed="true"
      :label="'crm.settings.currency'"
      :keyProp="'key'"
      maxHeight="150px"
      v-model="currency"
    >
    </customselect>
  </div>
</template>

<script>
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import CURRENCY from '@/constants/enums/currency';
import { map } from 'lodash';
import { onBeforeMount, ref, watch } from 'vue';

export default {
  name: 'Currency',
  components: { CustomSelect },
  props: {
    defaultValue: String
  },
  emits: ['update:currency'],
  setup (props, { emit }) {
    const options = map(CURRENCY, (item, key) => ({ key, item }));
    const currency = ref(options[0]);

    const emitValue = () => emit('update:currency', currency.value.item);

    const getCurrency = (val) => {
      return options.find(currency => currency.item === val);
    };

    watch(currency, emitValue);

    const setDefault = () => {
      const defaultValue = getCurrency(props.defaultValue);
      if (defaultValue) currency.value = defaultValue;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    return {
      options,
      currency
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-currency {

}

</style>
