<template>
  <div class="lp-file-dropped">
    <div class="lp-file-dropped__icon">
      <ButtonNewFile />
    </div>
    <div class="lp-file-dropped-folder">
      <span>{{ $t('myFiles.uploadTo') }}</span>
      <transition
        name="slideY"
        mode="out-in"
      >
        <span :key="folder._id">{{ folder.name || $t('myFiles.title') }}</span>
      </transition>
    </div>
  </div>
</template>

<script>
import ButtonNewFile from '@/components/CRM/Files/Buttons/ButtonNewFile';

export default {
  name: 'FileDroppedPopup',
  components: { ButtonNewFile },
  props: {
    folder: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-dropped {
  @include global-font;
  position: absolute;
  display: grid;
  grid-gap: 10px;
  align-items: center;
  justify-content: center;
  width: calc(100% - 80px);
  left: auto;
  bottom: 40px;
  pointer-events: none;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shakeY 0.7s ease-in infinite;
  }

  &-folder {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 4px;
    padding: 10px 20px;
    background-color: $color-accent;
    color: $color-white;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    border-radius: 4px;

    &__name {
      background-color: $color-accent;
    }
  }

  @keyframes shakeY {
    from {
      transform: translateY(0)
    }
    50% {
      transform: translateY(3px)
    }
    to {
      transform: translateY(0)
    }
  }
}

</style>
