<template>
  <div
    class="lp-widget-profile"
    :class="{'lp-widget-profile_mobile': isMobile}"
    v-if="teacher"
  >
    <div
      class="lp-widget-profile__avatar"
      :class="{'lp-widget-profile__avatar_mobile': isMobile}"
    >
      <img
        v-if="avatar"
        :src="avatar"
        alt="avatar"
      >
      <userIcon v-else />
    </div>
    <div
      class="lp-widget-profile__info"
      :class="{'lp-widget-profile__info_mobile': isMobile}"
    >
      <div
        class="lp-widget-profile__name"
        :class="{'lp-widget-profile__name_mobile': isMobile}"
      >
        {{ name }}
      </div>
      <div
        class="lp-widget-profile__country"
        :class="{'lp-widget-profile__country_mobile': isMobile}"
      >
        <img
          v-if="countryTeacher.icon"
          :src="countryTeacher.icon"
          alt="flag"
        >
        {{ $t(countryTeacher.name || '') }} {{ countryTeacher.city }}
      </div>
      <div
        class="lp-widget-profile-phone"
        v-if="phone"
      >
        <div class="lp-widget-profile-phone__title">
          {{ $t('crm.students.form.phone') }}
        </div>
        <div class="lp-widget-profile-phone__value">
          {{ phone }}
        </div>
      </div>
      <div
        class="lp-widget-profile-phone"
        v-if="email"
      >
        <div class="lp-widget-profile-phone__title">
          {{ $t('crm.students.form.email') }}
        </div>
        <div class="lp-widget-profile-phone__value">
          {{ email }}
        </div>
      </div>
      <div
        class="lp-widget-profile-phone lp-widget-profile-phone_line"
      >
        <div class="lp-widget-profile-phone__title">
          {{ $t('crm.students.form.lessonDuration') }}
        </div>
        <div class="lp-widget-profile-phone__value">
          {{ lessonDuration }} {{ $t('profile.minutes') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed, ref, onBeforeUnmount, onMounted } from 'vue';
import { userIcon } from '@/constants/icons';
import { getCountry } from '@/constants/utils/getValueById';
import getPhoneString from '@/constants/utils/getPhoneString';
import SettingsApi from '@/api/CRM/settings';
import MobileDetect from 'mobile-detect';
import hideJivoChat from '@/constants/utils/hideJivoChat';
import { useRouter } from 'vue-router';

export default {
  name: 'WidgetProfile',
  components: {
    userIcon
  },
  props: {
    teacher: Object
  },
  setup (props) {
    const avatar = computed(() => get(props.teacher, 'uploadFile.url', ''));
    const name = computed(() => {
      const firstName = get(props.teacher, 'firstName', '');
      const lastName = get(props.teacher, 'lastName', '');
      return `${firstName} ${lastName}`;
    });

    const router = useRouter();

    const countryTeacher = computed(() => {
      const countryId = get(props.teacher, 'country', '');
      const city = get(props.teacher, 'city', '');
      const currentCountry = getCountry(countryId);
      return { ...currentCountry, city: city && currentCountry.id ? `, ${city}` : city };
    });
    const teacherId = computed(() => get(router, 'currentRoute.value.params.id', ''));

    const phone = computed(() => {
      const phoneObj = get(props.teacher, 'phone', {});
      return getPhoneString(phoneObj);
    });
    const email = computed(() => get(props.teacher, 'email', ''));

    const lessonDuration = ref();

    const getSettings = async () => {
      try {
        const { data } = await SettingsApi.getSettingsUnauthorized(teacherId.value);
        lessonDuration.value = data.defaultLessonLength;
      } catch (e) {
        return e;
      }
    };

    onMounted(() => {
      hideJivoChat(true);
      getSettings();
      document.body.classList.add('hide-chat');
    });

    onBeforeUnmount(() => {
      if (!isMobile.value) hideJivoChat(false);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      avatar,
      name,
      countryTeacher,
      phone,
      email,
      lessonDuration
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-widget-profile {
  @include global-font;
  position: sticky;
  top: 28px;
  padding: 0 0 32px;

  &_mobile {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 6px solid $color-white;
    width: 160px;
    height: 160px;
    margin: 0 auto 19px;
    overflow: hidden;

    &_mobile {
      width: 120px;
      height: 120px;
      margin: 0 0 19px;
    }

    img, svg {
      top: -6px;
      left: -6px;
      position: absolute;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      object-fit: cover;
    }
  }

  &__info {
    &_mobile {
      margin: 0 28px;
    }
  }

  &__name {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    text-transform: uppercase;
    color: $color-black;
    margin-bottom: 20px;

    &_mobile {
      text-align: left;
      margin-bottom: 8px;
    }
  }

  &__country {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-mine-shaft-dark;
    margin-bottom: 24px;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin-right: 8px;
    }

    &_mobile {
      margin-bottom: 18px;
    }
  }

  &-phone {
    font-size: 14px;
    line-height: 125%;
    color: $color-mine-shaft-dark;
    margin-bottom: 16px;

    &__title {
      margin-bottom: 8px;
      margin-right: 8px;
      font-weight: bold;

      &:after {
        content: ':';
      }
    }

    &__value {
      font-weight: 500;
    }

    &_line {
      display: flex;
    }
  }
}

</style>
