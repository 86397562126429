<template>
  <a
    @click="openDelaySetting"
    href="/room-calibration"
  >
    {{ $t('delaySettings.open') }}
  </a>
</template>

<script>
import 'vue-slider-component/theme/default.css';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  name: 'MicrophoneOpenCalibration',
  setup () {
    const store = useStore();

    const route = useRoute();
    const openDelaySetting = () => {
      store.dispatch('setShowDelaySetting', true);
      localStorage.setItem('calibration_room_id', route.params.roomId);
    };

    return {
      openDelaySetting
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-microphone-settings-popup {
  box-sizing: border-box;
  color: $color-text;

  &-body {
    display: grid;
    grid-gap: 8px;
    justify-content: end;
    white-space: nowrap;

    &_mobile {
      justify-content: stretch;
    }

    &_mobile &-item {
      grid-template-columns: 1fr auto;
    }

    &-item {
      display: grid;
      grid-gap: 4px 16px;
      grid-template-columns: 145px auto;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;
      margin: 8px 0 0;
      border-radius: 3px;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $color-oslo-gray;
      transition: 0.3s ease-in-out;
      user-select: none;
      border-bottom: 1px solid $color-alto;

      &_volume {
        padding-bottom: 0;
        border-bottom: none;

        &:last-child {
          padding-bottom: 16px;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &__title {
        &_mobile {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
        }
      }

      &__range {
        grid-row: 2;
        grid-column: 1;
      }

      &__value {
        grid-row: 2;
        grid-column: 2;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        min-width: 30px;

        &_mobile {
          padding-top: 8px;
          font-size: 14px;
          line-height: 19px;
        }
      }

      &__open-delay {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 4px 0 0;
        color: $color-white;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        transition: opacity 300ms ease-in-out;

        &_mobile {
          font-size: 14px;
          line-height: 19px;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &-select {
        position: relative;
        display: grid;
        grid-template-columns: auto 20px;
        align-items: center;
        cursor: pointer;
        margin-right: -6px;

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px;
          width: 20px;
          height: 20px;
          transform: rotate(180deg);
          transition: transform 0.2s ease-in-out;

          &_open {
            transform: rotate(0);
          }
        }
      }
    }
  }

  &__background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-microphone-settings-popup-body-item__range {
  .vue-slider-dot-handle {
    background-color: $color-outer-space;
    box-shadow: none;
    margin: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
}

</style>
