import io from 'socket.io-client';
import { SOCKET_SERVER_MUSIO } from '@/constants/domains';

const config = {
  connection: SOCKET_SERVER_MUSIO,
  debug: false,
  options: {
    transports: ['websocket']
  }
};

const installSocket = (app) => {
  const { connection, debug, options } = config;
  localStorage.setItem('debug', debug ? '*' : '');
  const socket = io(connection, options);
  app.config.globalProperties.$socket = socket;
  app.provide('socket', socket);
};

export default installSocket;
