<template>
  <div
    class="section reviews"
    id="reviews"
  >
    <div class="reviews__wrapper">
      <div class="reviews__title">
        {{ $t('landing.reviewsTitle.firstItem') }} <br />
        {{ $t('landing.reviewsTitle.secondItem') }}
      </div>
      <div class="reviews-slider">
        <Carousel :settings="settingsSlider">
          <Slide
            v-for="review in reviewsList"
            :key="review.id"
          >
            <div class="reviews-card">
              <div class="reviews-card__image">
                <img
                  :src="
                    require(`@/assets/images/landing/reviews/${review.image}.jpg`)
                  "
                  alt="user"
                />
              </div>

              <div class="reviews-card__content">
                <div class="reviews-card__review">
                  {{ $t(review.text) }}
                </div>
                <div class="reviews-card__info">
                  <div class="reviews-card__title">
                    {{ $t(review.name) }}
                  </div>
                  <div class="reviews-card__occupation">
                    {{ $t(review.occupation) }}
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import reviewsList from '@/constants/landing/reviewsList';

export default {
  name: 'Reviews',
  components: {
    Carousel,
    Slide,
    Navigation
  },
  setup () {
    const settingsSlider = reactive({
      itemsToShow: 1,
      snapAlign: 'center',
      wrapAround: true
    });

    return {
      reviewsList,
      settingsSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.reviews {
  margin-top: 180px;
  height: 1016px;
  width: 100%;
  display: flex;
  justify-content: center;

  &__wrapper {
    width: 100%;
    max-width: 1656px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
  }

  &__title {
    padding: 0 5px;
    line-height: 57.6px;
    width: 800px;
    padding: 0 30px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 120%;
    text-align: start;
  }
}

.reviews-slider {
  width: 100%;
  height: 780px;
  margin-bottom: 120px;
}

.reviews-card {
  height: 686px;
  width: calc(100% - 56px);
  max-width: 1600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 100px;
  padding: 50px;
  box-shadow: 0 0 28px rgba($color-black, 0.04);
  border-radius: 8px;
  background-color: $color-white;

  &__image {
    display: flex;
    width: 100%;
    max-width: 586px;

    img {
      border-radius: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 800px;
    padding-left: 70px;
    width: 100%;
    justify-content: space-between;
  }

  &__info {
    text-align: left;
    font-size: 24px;
    line-height: 180%;
    color: $color-mine-shaft;
  }

  &__review {
    width: 100%;
    font-family: $global-font-manrope;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 160%;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
  }

  &__occupation {
    margin-top: 4px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 125%;
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';
.reviews {
  .carousel {
    width: 100%;

    &__prev,
    &__next {
      position: absolute;
      width: 70px;
      height: 70px;
      background: $color-white;
      box-shadow: 0 0 20px rgba($color-black, 0.1);
      border-radius: 14px;
      top: -60px;

      &:hover {
        svg {
          fill: $color-purple-dark;
        }
      }

      svg {
        fill: $color-purple;
        font-size: 32px;
      }
    }

    &__prev {
      right: 85px !important;
      left: auto !important;
    }

    &__next {
      right: 65px !important;
      left: auto !important;
    }
  }
}
</style>
