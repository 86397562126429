<template>
  <div class="lp-mobile-date-controls">
    <div
      class="lp-mobile-date-controls__arrow"
      @click="setWeek(-1)"
    >
      <arrowIcon />
    </div>
    <div
      class="lp-mobile-date-controls-item"
      :class="{'lp-mobile-date-controls-item_selected': item.date === selectedDate}"
      v-for="item of dates"
      :key="item.date"
      @click="$emit('update:date', item.date)"
    >
      <div class="lp-mobile-date-controls-item__weekday">
        {{ item.weekday }}
      </div>
      <div class="lp-mobile-date-controls-item__day">
        {{ item.day }}
      </div>
    </div>
    <div
      class="lp-mobile-date-controls__arrow lp-mobile-date-controls__arrow_next"
      @click="setWeek(1)"
    >
      <arrowIcon />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { fill } from 'lodash';
import moment from 'moment';
import { arrowIcon } from '@/constants/icons/crm';

export default {
  name: 'DateControlsMobile',
  components: {
    arrowIcon
  },
  props: {
    date: String
  },
  emits: ['update:date', 'arrow'],
  setup (props, { emit }) {
    const selectedDate = computed(() => moment(props.date).format());

    const dates = computed(() => {
      return fill(new Array(7), props.date).map((date, i) => {
        const dateStartWeek = moment(props.date).startOf('week').add(i, 'day');
        return {
          date: dateStartWeek.format(),
          weekday: dateStartWeek.format('ddd'),
          day: dateStartWeek.format('D')
        };
      });
    });

    const setWeek = (val) => {
      const date = moment(props.date);
      if (val > 0) {
        date.add(1, 'week');
        emit('arrow', 'right');
      } else {
        date.subtract(1, 'week');
        emit('arrow', 'left');
      }
      emit('update:date', date.format());
    };

    return {
      setWeek,
      selectedDate,
      dates
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-mobile-date-controls {
  @include global-font;
  display: grid;
  grid-template-columns: repeat(9, minmax(42px, 1fr));
  align-items: center;
  justify-content: center;

  &-item {
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 7px 18px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $color-gray;
    letter-spacing: 0.07em;
    line-height: 1;
    text-align: center;
    font-weight: 700;
    transition: 0.3s ease-in;

    &_selected {
      background-color: $color-moon-raker;
      border-color: $color-alto;
      color: $color-accent;
    }

    &__weekday {
      font-size: 10px;
      text-transform: uppercase;
    }

    &__day {
      font-size: 14px;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);

    &_next {
      transform: rotate(90deg);
    }
  }
}

</style>
