<template>
  <div
    class="stp-lessons"
    :class="{'stp-lessons__mobile' : isMobile}"
  >
    <div
      class="stp-lessons-header"
      :class="{'stp-lessons-header__mobile' : isMobile}"
    >
      {{ $t('student.soon.title') }}
    </div>
    <div
      class="stp-lessons-list"
      :class="{'stp-lessons-list__mobile' : isMobile}"
    >
      <div
        class="stp-lessons-list-item"
        v-for="lesson in pendingLessons"
        :key="lesson"
      >
        <div class="stp-lessons-list-item-container">
          <div
            class="stp-lessons-list-item-container_color"
            :style="{backgroundColor: lesson.color || '#7500BA'}"
          />
          <div class="stp-lessons-list-item-container-date">
            {{ `${lesson.date}, ${lesson.timePeriod}` }}
          </div>
          <div class="stp-lessons-list-item-container_cost">
            <span class="stp-lessons-list-item-container-cost_summ">
              <LPTooltip
                :text="$t('crm.lessons.form.trial')"
                v-if="lesson.isTrial"
              >
                <TrialIcon />
              </LPTooltip>
              <template v-else-if="lesson.cost">
                <span class="stp-lessons-list-item-container-cost_label">
                  {{ `${$t('student.soon.cost')}: ` }}
                </span>
                {{ `${lesson.cost} ${lesson.currency}` }}
              </template>
              <LPTooltip
                v-else
                :text="$t('crm.subscriptions.tooltip')"
              >
                <subscriptionsIcon />
              </LPTooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { subscriptionsIcon, TrialIcon } from '@/constants/icons/crm';

export default {
  name: 'PendingLessons',
  components: { LPTooltip, subscriptionsIcon, TrialIcon },
  props: {
    lang: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    pendingLessons: {
      type: Array,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/_variables.scss";

.stp-lessons {
  padding: 40px 5px 30px 20px;

  &__mobile {
    padding: 24px 0;
  }

  &-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding-left: 20px;

    &__mobile {
      padding-left: 12px;
    }
  }

  &-list {
    padding: 0 32px 20px 20px;
    max-height: 298px;
    overflow: scroll;
    margin-top: 10px;

    &__mobile {
      padding: 0 7px 8px 12px;
    }

    &-item {
      position: relative;
      width: 100%;
      margin-top: 12px !important;
      padding: 14px 20px 14px 14px;
      border-radius: 4px;
      box-shadow: $color-shadow-grey-container;

      &-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &_color {
          position: absolute;
          height: 100%;
          width: 4px;
          left: 0;
          top: 0;
          border-radius: 4px 0 0 4px;
        }

        &-date {
          font-size: 14px;
          font-weight: 700;
          line-height: 17.5px;
        }

        &-cost {

          &_label {
            font-weight: 700;
            font-size: 14px;
            line-height: 17.5px;
          }

          &_summ {
            font-size: 14px;
            line-height: 17.5px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

</style>
