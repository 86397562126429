<template>
  <div
    class="lp-date-picker"
    v-click-outside="closeDropList"
  >
    <span
      class="lp-label lp-date-picker__label"
      v-if="label"
      :class="{
        'lp-label_required': required
      }"
    >
      {{ $t(label) }}
    </span>
    <div
      class="lp-date-picker-input lp-input"
      :class="{
        'lp-input_disabled lp-date-picker-input_disabled': disabled,
        'lp-input_focus': isFocus,
        'lp-input_error': error,
        'lp-date-picker-input_without-text': hideInput
      }"
      ref="selectHeader"
      @click="changeOpenDropList(!openDropList)"
    >
      <p
        class="lp-date-picker-input__date"
        v-if="!hideInput"
      >
        <span
          class="lp-date-picker-input__range"
          :class="{'lp-date-picker-input__range_mobile': isMobile}"
          v-if="rangePicker"
        >
          {{ range[0] && range[1] ? formatRange : placeholder }}
        </span>
        <template v-else>
          <input
            class="lp-date-picker-input__manual"
            v-if="manualEditableDate && isDesktop"
            v-model="manualDate"
            v-maska="'##.##.####'"
            @change="changeManualDate"
            @keyup.esc="setManualDate"
            @keyup.enter="setManualDate"
          />
          <span class="lp-date-picker-input__value">
            {{ range[0] ? formatOne : placeholder }}
          </span>
        </template>
        <span
          class="lp-date-picker-input__time"
          v-if="!hideTime && time[0] !== time[1]"
        >
          , {{ time.join(' - ') }}
        </span>
      </p>
      <div class="lp-date-picker-input__icon">
        <calendarIcon />
      </div>
    </div>
    <transition name="fade">
      <teleport
        to="#app"
        v-if="!isDesktop && openDropList"
      >
        <div
          class="lp-date-picker-dropdown__background"
          @click="openDropList = false"
        />
      </teleport>
    </transition>
    <teleport
      to="#app"
      :disabled="isDesktop"
    >
      <transition :name="isDesktop ? 'slide-select' : 'mobile-calendar'">
        <div
          class="lp-date-picker-dropdown"
          :class="{
            'lp-date-picker-dropdown_hide-label': !label,
            'lp-date-picker-dropdown_mobile': !isDesktop
          }"
          v-if="openDropList"
          :style="fixed && isDesktop ? getStyleDropList() : {}"
          @click.stop
        >
          <DatePickerHeader
            :year="currentYear"
            :month="currentMonth"
            :yearMode="yearMode"
            @changeMonth="changeMonth"
            v-model:mode="yearMode"
          />
          <DatePickerBody
            :year="currentYear"
            :month="currentMonth"
            :currentDate="currentDate"
            :range="range"
            :maxDate="maxDate"
            :minDate="minDate"
            :rangePicker="rangePicker"
            :yearMode="yearMode"
            @changeYear="changeYear"
            v-model:open="openDropList"
            v-model:dates="range"
          />
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { calendarIcon } from '@/constants/icons';
import {
  ref,
  computed,
  onBeforeMount,
  watch
} from 'vue';
import moment from 'moment';
import DatePickerHeader from '@/components/Main/Inputs/DatePicker/DatePickerHeader';
import DatePickerBody from '@/components/Main/Inputs/DatePicker/DatePickerBody';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'DatePicker',
  components: {
    DatePickerHeader,
    DatePickerBody,
    calendarIcon
  },
  props: {
    manualEditableDate: Boolean,
    hideReset: Boolean,
    hideTime: Boolean,
    hideInput: Boolean,
    placeholder: String,
    error: [Boolean, String],
    modelValue: Object,
    required: Boolean,
    label: String,
    fixed: Boolean,
    format: String,
    formatStart: String,
    formatEnd: String,
    rangePicker: Boolean,
    minDate: [String, Date],
    maxDate: [String, Date],
    disabled: Boolean
  },
  emits: ['update:modelValue', 'handle-data-change', 'focus'],
  setup (props, { emit }) {
    const yearMode = ref(false);
    const formatISO = 'YYYY-MM-DD';
    const formatDate = props.format || 'DD MMM, YYYY';

    const upperLimit = computed(() => {
      const max = new Date(props.maxDate || '5950');
      return moment(max).format(formatISO);
    });

    const lowerLimit = computed(() => {
      const min = new Date(props.minDate || '1950');
      return moment(min).format(formatISO);
    });

    const manualDate = ref('');
    const setManualDate = (e) => {
      changeManualDate();
      openDropList.value = false;
      e.target.blur();
    };
    const changeManualDate = () => {
      const date = manualDate.value;
      const day = date.slice(0, 2);
      const month = date.slice(3, 5);
      const year = date.slice(6, 10);
      const momentDate = moment(`${year}-${month}-${day}`);
      const isLower = moment(lowerLimit.value).isAfter(momentDate);
      const isUpper = moment(upperLimit.value).isBefore(momentDate);
      if (momentDate.isValid() && !isLower && !isUpper) {
        range.value[0] = momentDate.format(formatISO);
      } else if (isLower) {
        range.value[0] = moment(lowerLimit.value);
      } else if (isUpper) {
        range.value[0] = moment(upperLimit.value);
      } else {
        manualDate.value = range.value[0] ? moment(range.value[0]).format('DD.MM.YYYY') : '';
      }
    };

    const isFocus = computed(() => {
      return openDropList.value;
    });

    const today = moment();
    const range = ref([]);
    const formatRange = computed(() => {
      const start = moment(range.value[0]).format(props.formatStart || formatDate);
      const end = moment(range.value[1]).format(props.formatEnd || formatDate);
      return `${start} - ${end}`;
    });
    const formatOne = computed(() => {
      const start = moment(range.value[0]).format(formatDate);
      return `${start}`;
    });

    const time = ref(['00:00', '00:00']);

    const result = computed(() => ({ time: time.value, date: range.value }));

    watch(range, () => {
      manualDate.value = range.value[0] ? moment(range.value[0]).format('DD.MM.YYYY') : '';
      emit('handle-data-change', result.value);
      emit('update:modelValue', result.value);
    });

    const currentDate = ref(today.format(formatISO));
    const currentMonth = computed(() => moment(currentDate.value).format('MMMM'));
    const currentYear = computed(() => moment(currentDate.value).format('YYYY'));

    const openDropList = ref(false);
    const changeOpenDropList = (val) => {
      if (props.disabled) return;
      openDropList.value = val;
      emit('focus');
    };
    const closeDropList = () => {
      if (!isDesktop.value) return;
      openDropList.value = false;
    };

    const changeMonth = (val) => {
      const unit = yearMode.value ? 'year' : 'month';
      const count = yearMode.value ? 10 : 1;
      const date = moment(currentDate.value);
      const resultData = val ? date.add(count, unit) : date.subtract(count, unit);
      currentDate.value = resultData.format(formatISO);
    };

    const changeYear = (val) => {
      const date = moment(currentDate.value);
      const resultData = date.set('year', val);
      currentDate.value = resultData.format(formatISO);
      yearMode.value = false;
    };

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      const heightCalendar = 317;
      const allHeight = position.height + heightCalendar + position.top;
      const top = window.innerHeight > allHeight
        ? 4 + position.top + position.height
        : position.top - heightCalendar - position.height;
      return {
        position: 'fixed',
        top: `${top}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const setDefaultRange = () => {
      range.value = props.modelValue.date || [];
      time.value = props.modelValue.time || [];
    };

    const resetDates = (e) => {
      e.preventDefault();
      range.value = [];
      time.value = ['00:00', '00:00'];
      closeDropList();
    };

    watch(() => props.modelValue, (newVal) => {
      const newStart = get(newVal, ['date', 0], '');
      const oldStart = get(range.value, [0], '');
      if (newStart === oldStart) return;
      setDefaultRange();
    });
    onBeforeMount(setDefaultRange);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    const isDesktop = computed(() => !isMobile.value);

    return {
      isMobile,
      isDesktop,
      isFocus,
      manualDate,
      changeManualDate,
      setManualDate,
      range,
      time,
      formatRange,
      formatOne,
      currentDate,
      currentMonth,
      currentYear,
      openDropList,
      changeOpenDropList,
      closeDropList,
      selectHeader,
      getStyleDropList,
      changeMonth,
      changeYear,
      resetDates,
      yearMode
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-date-picker {
  @include global-font;
  position: relative;
  display: grid;
  grid-gap: 4px;
  color: $color-text;
  user-select: none;

  &-input {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 18px;
    height: 100%;
    min-height: 42px;
    cursor: pointer;
    width: 100%;
    color: $color-text;
    padding: 0 11px 0 0;
    box-sizing: border-box;
    transition: 0.3s ease;
    background-color: transparent;

    &_disabled {
      cursor: default;
      color: $color-gray;
    }

    &__value {
      position: absolute;
      left: 11px;
      top: 10px
    }

    &__manual {
      @include global-font;
      position: relative;
      border: none;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding: 10px 11px;
      opacity: 0;
      border-radius: 4px;
      box-sizing: border-box;
      background: $color-white;
      z-index: 1;
      width: 100%;

      &:focus {
        opacity: 1;
        z-index: 2;
      }
    }

    &_without-text {
      justify-content: center;
      padding: 3px 3px;
      min-height: 36px;
      width: 100%;
      min-width: 36px;
      grid-template-columns: 0 16px;
    }

    &__date {
      &::first-letter {
        text-transform: uppercase;
      }
    }

    &__range {
      padding: 10px 11px;
      text-transform: capitalize;

      &_mobile {
        font-size: 14px;
      }
    }

    &__time {
      margin-left: -3px;
    }

    &__icon {
      grid-column: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        fill: $color-mine-shaft;
      }
    }
  }

  &-dropdown {
    @include global-font;
    position: absolute;
    top: 65px;
    right: 0;
    display: grid;
    min-width: 317px;
    max-width: 317px;
    height: auto;
    padding: 28px 20px 32px;
    background: $color-white;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    border: 1px solid $color-wild-sand;
    z-index: 101;

    &__background {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.5);
      z-index: 100;
    }

    &_hide-label {
      top: 50px;
    }

    &_mobile {
      position: fixed;
      right: auto;
      bottom: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__time-title {
      margin: 26px 0 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
    }

    &__reset {
      margin-top: 20px;
    }
  }
}

</style>
