<template>
  <teleport to="#app">
    <transition
      :name="mobileVersion ? 'fade-mobile' : 'fade-fast'"
    >
      <div
        v-if="open"
        :id="modalId"
        :class="[
          customClass,
          {
            'lp-modal_mobile': mobileVersion,
            'lp-modal_full-width': fullOnMobile && isMobile
          }
        ]"
        class="lp-modal"
        :style="{zIndex}"
      >
        <!--        v-click-outside="closeModalOutside"-->
        <div
          v-click-outside="closeModalOutside"
          :class="{
            'lp-modal-content_mobile': mobileVersion,
            'lp-modal-content_height': isMobile,
            'lp-modal-content_min-height': minHeight && !isMobile,
            'lp-modal-content_full-mobile': fullOnMobile && isMobile,
            'lp-modal-content_slider': slider
          }"
          class="lp-modal-content"
          @click.stop
        >
          <div
            :class="{
              'lp-modal-content-header_mobile': mobileVersion,
              'lp-modal-content-header_slider': slider
            }"
            class="lp-modal-content-header"
          >
            <span class="lp-modal-content-header__title">{{ title ? $t(title) : null }}</span>
            <span
              v-if="!cantClose"
              class="lp-modal-content-header__icon lp-cross-button"
              :class="{'lp-cross-button_left': fullOnMobile && isMobile}"
              @click.stop="closeModal"
            >
              <closeModalIcon />
            </span>
            <span
              v-if="mobileVersion && (!hideButtons || !hideButtons.includes('save'))"
              :class="{'lp-edit-button_save': editMode}"
              class="lp-modal-content-header__edit lp-edit-button"
              @click.stop="$emit('save')"
            >
              <slot name="edit-icon">
                <checkIcon v-if="editMode" />
                <pencilIcon v-else />
              </slot>
            </span>
            <span
              v-if="fullOnMobile && isMobile"
              class="lp-modal-content-header__agree"
              @click.stop="$emit('save')"
            >
              <checkIcon />
            </span>
          </div>
          <div
            :class="{
              'lp-modal-content-body_mobile': mobileVersion,
              'lp-modal-content-body_full-height': removeScroll
            }"
            class="lp-modal-content-body"
          >
            <slot></slot>
          </div>
          <div
            v-if="!hideFooter && !mobileVersion"
            class="lp-modal-content-footer"
            :class="{
              'lp-modal-content-footer_mobile': isMobile,
              'lp-modal-content-footer_hide': fullOnMobile && isMobile
            }"
          >
            <slot name="footer">
              <button
                v-if="!hideButtons || !hideButtons.includes('remove')"
                class="lp-button lp-button_thin lp-modal-content-footer__button"
                @click="$emit('remove')"
              >
                {{ $t(removeBtnText || 'buttons.remove') }}
              </button>
              <button
                v-if="!hideButtons || !hideButtons.includes('save')"
                class="lp-button lp-modal-content-footer__button"
                @click.stop="$emit('save')"
              >
                {{ $t(saveBtnText || 'buttons.save') }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { closeModalIcon } from '@/constants/icons';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { checkIcon, pencilIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Modal',
  components: {
    closeModalIcon,
    pencilIcon,
    checkIcon
  },
  props: {
    editMode: Boolean,
    title: String,
    open: [Boolean, Number, String],
    hideButtons: Array,
    clickOutsideOff: {
      type: Boolean,
      default: false
    },
    cantClose: Boolean,
    hideFooter: {
      type: Boolean,
      default: () => false
    },
    mobileVersion: Boolean,
    saveBtnText: String,
    customClass: String,
    removeBtnText: String,
    slider: Boolean,
    fullOnMobile: Boolean,
    removeScrollOnMobile: Boolean,
    minHeight: Boolean,
    zIndex: {
      type: Number,
      default: 100
    },
    removeScroll: Boolean,
    forceClose: Boolean
  },
  emits: ['save', 'remove', 'update:open', 'close-form'],
  setup (props, { emit }) {
    const store = useStore();
    const modalIds = computed(() => store.getters.modalIds);
    const setModalIds = (data) => store.dispatch('setModalIds', data);

    const modalId = ref(null);
    watch(modalId, (newId, oldId) => {
      if (newId) {
        modalIds.value.push(newId);
      } else {
        setModalIds(modalIds.value.filter((item) => item !== oldId));
      }
    });
    watch(() => props.open, (newVal) => {
      toggleJivo(newVal);
      modalId.value = newVal ? Date.now() : null;
      if (!props.removeScrollOnMobile) {
        togglePointEvents(newVal);
        bodyScrollUp();
      }
    });

    const bodyScrollUp = () => {
      window.scroll(0, 0);
    };

    const checkIndex = () => {
      if (props.forceClose) return;
      const maxId = Math.max(...modalIds.value);
      return maxId !== modalId.value;
    };

    const closeModal = () => {
      if (props.cantClose || checkIndex()) return;
      emit('update:open', false);
    };

    const closeModalOutside = () => {
      if (props.clickOutsideOff || props.cantClose || checkIndex() || props.mobileVersion) return;
      emit('update:open', false);
    };

    const toggleJivo = (val) => {
      const el = document.querySelector('jdiv');
      if (!el) return;
      el.style.display = val ? 'none' : 'inline';
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const hideElementUnderModal = (el, val) => {
      if (!el || !isMobile.value) return;
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
      el.style.height = val ? 0 : '100%';
    };

    const togglePointEvents = (val) => {
      const modals = document.querySelectorAll('.lp-modal');
      const isFirstModal = !(val ? modals.length : modals.length - 1);
      if (isFirstModal) {
        const el = document.querySelector('.mc-container');
        hideElementUnderModal(el, val);
      } else {
        const ids = modalIds.value.filter(id => id !== modalId.value);
        ids.forEach(id => {
          const el = document.getElementById(id);
          hideElementUnderModal(el, val);
        });
      }
    };

    const onKeyUp = (e) => {
      if (e.keyCode === 27) closeModal();
    };
    onBeforeMount(() => {
      modalId.value = props.open ? Date.now() : null;
      if (props.open) togglePointEvents(props.open);
      document.addEventListener('keyup', onKeyUp, false);
    });
    onBeforeUnmount(() => {
      togglePointEvents(false);
      document.removeEventListener('keyup', onKeyUp, false);
    });

    return {
      isMobile,
      modalId,
      closeModal,
      closeModalOutside
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-modal {
  @include global-font;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color-black, 0.5);
  z-index: 100;

  &_mobile {
    position: absolute;
    height: auto;
    background-color: $color-white;
  }

  &_full-width {
    position: inherit;
  }

  &-content {
    display: flex;
    flex-direction: column;
    max-height: 95vh;
    background-color: $color-white;
    border-radius: 4px;

    &_min-height {
      min-height: 575px;
    }

    &_slider {
      background-color: transparent;
    }

    @media (max-width: 1024px) {
      max-height: 100vh;
    }

    &_height {
      max-height: none;
    }

    @media (max-width: 499px) {
      width: 335px;
    }

    &_mobile {
      align-self: start;
      max-height: none;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      width: 100vw;
    }

    &_full-mobile {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    &-header {
      $this: &;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $color-gallery;

      &_slider {
        width: 80vw;
        padding: 0;
        border-color: transparent;
      }

      &_mobile {
        position: sticky;
        top: 0;
        display: grid;
        grid-template-columns: 22px 1fr 22px;
        padding: 16px;
        z-index: 10;
        background-color: $color-white;

        #{$this}__title {
          grid-row: 1;
          grid-column: 2;
          text-align: center;
          word-break: break-word;
          padding: 0 10px;
        }

        #{$this}__icon {
          grid-row: 1;
          grid-column: 1;
        }
      }

      &__title {
        margin-right: 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        user-select: none;
        max-width: 400px;
        word-break: break-word;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }

    &-body {
      flex-grow: 1;
      overflow: auto;
      margin-bottom: 20px;

      &_mobile {
        overflow: visible;
        -webkit-overflow-scrolling: touch;
        min-height: 100%;
      }

      &_full-height {
        overflow: initial;
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 20px 20px 18px;

      &_mobile {
        flex-wrap: wrap;
      }

      &_hide {
        display: none;
      }

      @media (max-width: 510px) {
        &__button {
          width: 100%;
        }
      }

      &__button {
        margin-left: 4px;
      }
    }
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-cross-button {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover path {
    fill: $color-silver-chalice;
  }

  path {
    transition: fill .15s ease-in;
  }

  &_left {
    order: -1;
  }
}

.lp-edit-button {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  path {
    fill: $color-accent;
    transition: fill .15s ease-in;
  }

  &_save {
    path {
      fill: $color-emerald;
    }
  }
}
</style>
