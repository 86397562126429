<template>
  <div
    class="lp-status-cell"
    :style="status.style"
  >
    {{ $t(status.title) }}
  </div>
</template>

<script>
import { computed } from 'vue';
import { get } from 'lodash';
import statusStudentList from '@/constants/crm/studentStatuses';

export default {
  name: 'StatusCell',
  props: {
    column: Object,
    row: Object
  },
  setup (props) {
    const status = computed(() => {
      const value = get(props.row, props.column.prop, '');
      return statusStudentList.find(item => item.id === value);
    });

    return {
      status
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-status-cell {
  padding: 5px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 125%;
  color: $color-white;
  border-radius: 4px;
  user-select: none;
}

</style>
