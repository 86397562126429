<template>
  <div
    class="section welcome"
    id="welcome"
  >
    <div class="welcome__container">
      <h1
        class="welcome__title"
        :class="`welcome__title_${locale}`"
      >
        {{ $t('landing.welcomeTitle.firstText') }}
        <div>{{ $t('landing.welcomeTitle.secondText') }}</div>
        <br />
        {{ $t('landing.welcomeTitle.thirdText') }}
      </h1>

      <div class="welcome__description">
        {{ $t('landing.welcomeText') }}
      </div>
      <button
        class="welcome-btn lp-button"
        :class="`welcome-btn_${locale}`"
        @click.stop="onLogin"
      >
        {{ $t('landing.welcomeBtn') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Welcome',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const router = useRouter();
    const { locale } = useI18n({ useScope: 'global' });
    const onLogin = () => {
      router.push({
        name: 'Sign-up'
      });
    };

    return {
      locale,
      onLogin
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.welcome {
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 104px;
  height: 860px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('~@/assets/images//landing/welcome-background.jpg');

  &__container {
    position: relative;
    width: 100%;
    max-width: 1270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-white;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    text-align: center;

    div {
      display: inline-block;
      background-color: $color-purple;
      line-height: 100%;
      padding: 7px 16px 7px 15px;
      border-radius: 4px;
    }

    &_en {
      font-size: 48px;
    }
    &_ru {
      font-size: 48px;
    }
  }

  &__description {
    width: 100%;
    max-width: 970px;
    margin-top: 40px;
    margin-bottom: 48px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.02em;
    text-align: center;
  }
}

.welcome-btn {
  height: 72px;
  padding: 0 30px;
  font-family: $global-font-manrope;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: 0.04em;

  &_en {
    padding: 0 60px;
  }

  &_ru {
    padding: 0 30px;
  }
}
</style>
