<template>
  <div
    class="lp-select-image"
  >
    <CustomSelect
      :options="options"
      :searchable="searchable"
      :modelValue=" selectedItem || modelValue"
      :error="error"
      :fixed="fixed"
      v-model="selectedItem"
      :keyProp="keyProp"
      :lessonStatus="lessonStatus"
      :required="required"
      :label="label"
      :placeholder="'placeholders.student'"
      @focus="$emit('focus')"
      :minHeight="isMobile ? '290px' : 'auto'"
      @handlerChange="$emit('handler-change', $event)"
    >
      <template #header="{keyProp, value}">
        <div class="lp-select-image-selected">
          <img
            class="lp-select-image-selected__photo"
            v-if="getImage(value)"
            :alt="value[keyProp]"
            :src="getImage(value)"
          />
          <div
            class="lp-select-image-selected__photo"
            v-else
          >
            <userIcon />
          </div>
          {{ value[keyProp] }}
        </div>
      </template>
      <template #item="{keyProp, item}">
        <div
          class="lp-select-image-selected lp-select-image-selected_option"
          :class="{'lp-select-image-selected_icon': item.icon}"
        >
          <img
            class="lp-select-image-selected__photo"
            v-if="getImage(item)"
            :alt="item[keyProp]"
            :src="getImage(item)"
          />
          <template v-else>
            <component
              v-if="item.icon"
              :is="item.icon"
            />
            <userIcon
              v-else
              class="lp-select-image-selected__photo"
            />
          </template>

          {{ item[keyProp] }}
        </div>
      </template>
    </CustomSelect>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed, onBeforeMount, ref, watch } from 'vue';
import CustomSelect from '@/components/Main/Inputs/CustomSelect';
import { userIcon } from '@/constants/icons';
import MobileDetect from 'mobile-detect';
import { useStore } from 'vuex';
import StudentApi from '@/api/CRM/students';

export default {
  name: 'CountrySelect',
  components: { CustomSelect, userIcon },
  props: {
    keyProp: String,
    lessonStatus: String,
    keyImg: String,
    options: Array,
    label: String,
    modelValue: Object,
    required: Boolean,
    searchable: Boolean,
    error: [Boolean, String],
    fixed: Boolean,
    lesson: [Object, Array],
    newStudent: Object
  },
  emits: ['update:modelValue', 'handler-change', 'set-student'],
  setup (props, { emit }) {
    const selectedItem = ref({});

    watch(selectedItem, () => {
      emit('update:modelValue', selectedItem.value);
    });

    watch(() => props.newStudent, () => {
      if (props.newStudent) {
        selectedItem.value = props.newStudent;
      }
    });

    const store = useStore();
    const lessons = computed(() => store.getters.lessons);

    const setSelectedValues = async () => {
      if (props.lesson && props.lesson.crmLessons) {
        const studentsList = Object.values(props.lesson.crmLessons);
        if (props.lesson.crmLessonStatus === 'Complete') {
          selectedItem.value = lessons.value.find(item => item._id === props.lesson._id);
          const { data } = await StudentApi.getStudentById(selectedItem.value.crmStudents[0]);
          selectedItem.value = data;
        } else {
          const student = props.options.find(option => option._id === studentsList[0].crmStudentId);
          emit('update:modelValue', student);
          selectedItem.value = student || props.modelValue;
        }
      } else {
        selectedItem.value = props.newStudent || props.modelValue;
      }
    };

    const getImage = (val) => get(val, props.keyImg, '');

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    onBeforeMount(() => {
      setSelectedValues();
      emit('set-student', selectedItem.value);
    });

    return { selectedItem, getImage, isMobile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-select-image {
  @include global-font;

  &-selected {
    display: grid;
    grid-template-columns: 24px auto;
    grid-gap: 6px;
    align-items: center;
    word-break: break-word;

    &_icon {
      display: flex;
    }

    &_option {
      margin-left: -12px;
      word-break: break-word;
    }

    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

</style>
