<template>
  <div
    class="lp-audio-controls"
    :class="{'lp-audio-controls_mobile': isMobile}"
  >
    <div
      class="lp-audio-controls-header__name-placeholder"
      :class="{'lp-audio-controls-header__name-placeholder_mobile': isMobile}"
      v-if="!isTeacher && !filename"
    >
      {{ $t('lessonCall.placeholderPlayerStudent') }}
    </div>
    <div
      class="lp-audio-controls-header"
      :class="{
        'lp-audio-controls-header_mobile': isMobile,
        'lp-audio-controls-header_student': !isTeacher,
        'lp-audio-controls-header_disabled': !mp3File?.file
      }"
    >
      <div
        class="lp-audio-controls-header-buttons"
        v-if="isTeacher"
      >
        <MiniLoader v-if="loading || loadingAnalyzer" />
        <playIcon
          v-else-if="!playing"
          @click.stop="play()"
        />
        <pauseIcon
          v-else
          @click.stop="pause()"
        />
      </div>
      <div class="lp-audio-controls-header-name">
        <template v-if="isTeacher || filename">
          {{ loading || loadingAnalyzer ? $t('myFiles.uploading') : filename || $t('lessonCall.placeholderPlayer') }}
        </template>
      </div>
    </div>
    <div
      class="lp-audio-controls-body"
      :class="{'lp-audio-controls-body_mobile': isMobile}"
    >
      <transition name="bounce">
        <div class="lp-audio-controls-body__timeline">
          <AudioVisualization
            :url="mp3File.file?.url"
            :currentDur="timeTrackModel"
            :totalDur="audioDuration"
            :isTeacher="isTeacher"
            :isPause="!playing"
            :videoWidth="videoWidth"
            @change="resumePositionUpdate"
            v-model:loading="loadingAnalyzer"
          />
        </div>
      </transition>
      <div
        class="lp-audio-controls-body__time lp-audio-controls-body__time_current"
        v-if="mp3File.file"
      >
        {{ formatTimeTrack }}
      </div>
      <div
        class="lp-audio-controls-body__time lp-audio-controls-body__time_end"
        v-if="mp3File.file"
      >
        {{ formatAudioDuration }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { get } from 'lodash';
import { playIcon, pauseIcon } from '@/constants/icons';
import MiniLoader from '@/components/VideoCall/VideoCallTools/AudioPlayer/MiniLoader';
import 'vue-slider-component/theme/default.css';
import moment from 'moment';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import AudioVisualization from '@/components/VideoCall/VideoCallTools/AudioPlayer/AudioVisualization';
import ROLE_TYPES from '@/constants/enums/roles';

export default {
  name: 'AudioPlayerControls',
  components: {
    AudioVisualization,
    playIcon,
    pauseIcon,
    MiniLoader
  },
  props: {
    videoWidth: Number,
    loading: Boolean
  },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);
    const singingStudent = computed(() => store.getters.singingStudent);
    const mp3File = computed(() => store.getters.mp3File);
    const audioEngine = inject('audioEngine');
    const rtcDataConnection = inject('rtcDataConnection');
    const audioSettings = computed(() => store.getters.audioSettings);
    const loadingAnalyzer = ref(false);

    const filename = computed(() => get(mp3File.value, 'file.originalName', ''));
    const playing = computed(() => get(mp3File.value, 'playing', ''));

    // for position (time track)
    let posUpdateInterval;
    const timeTrackModel = ref(0);
    const formatTimeTrack = computed(() => {
      return moment().startOf('day').seconds(timeTrackModel.value).format('m:ss');
    });
    const audioDuration = computed(() => {
      const maxVal = get(mp3File.value, 'duration', 0);
      return Math.round(maxVal);
    });
    const formatAudioDuration = computed(() => {
      return moment().startOf('day').seconds(audioDuration.value).format('m:ss');
    });

    const play = () => {
      // TODO запуск/плей аудио файла
      if (singingStudent.value) {
        audioEngine.mp3Player.mute();
        audioEngine.mp3Player.muteStream();
        rtcDataConnection.participant.mp3Player.unmute();
      } else {
        audioEngine.mp3Player.unmute();
        audioEngine.mp3Player.unmuteStream();
        rtcDataConnection.participant.mp3Player.mute();
      }
      startPositionUpdate();
      rtcDataConnection.participant.mp3Player.play();
      audioEngine.mp3Player.play();
      yandexTracker.playerPlay();
    };
    const pause = () => {
      // TODO поставить аудио на паузу
      stopPositionUpdate();
      rtcDataConnection.participant.mp3Player.pause();
      audioEngine.mp3Player.pause();
      yandexTracker.playerPause();
    };
    const stop = () => {
      // TODO остановить/сбросить аудио
      stopPositionUpdate();
      rtcDataConnection.participant.mp3Player.stop();
      audioEngine.mp3Player.stop();
      //stopPositionUpdate();
      mp3File.value.file = null;
    };
    const setPosition = (val) => {
      // TODO перемотать аудио на val секунду
      timeTrackModel.value = val;
      rtcDataConnection.participant.mp3Player.setPosition(val);
      audioEngine.mp3Player.setPosition(val);
      yandexTracker.playerRewind();
    };

    const resetToStart = () => {
      pause();
      setPosition(0);
      if (!isTeacher.value) startPositionUpdate();
    };

    const getPosition = () => {
      let timeVal;
      timeVal = audioEngine.mp3Player.getPosition();
      // if (singingStudent.value) {
      //   timeVal = rtcDataConnection.participant.mp3Player.getPosition();
      // } else {
      //   timeVal = audioEngine.mp3Player.getPosition();
      // }
      if (timeVal === -1) {
        stopPositionUpdate();
        timeTrackModel.value = 0;
        if (isTeacher.value) {
          rtcDataConnection.participant.mp3Player.stop();
        }
        // if (singingStudent.value) {
        //   stopPositionUpdate();
        //   audioEngine.mp3Player.stop();
        // } else {
        //   stopPositionUpdate();
        // }
      } else {
        timeTrackModel.value = timeVal;
      }
    };

    const startPositionUpdate = () => {
      console.log('start pos update');
      if (posUpdateInterval) clearInterval(posUpdateInterval);
      posUpdateInterval = setInterval(() => {
        getPosition();
      }, 100);
    };
    const stopPositionUpdate = () => {
      console.log('stop pos update');
      if (!isTeacher.value) return;
      if (posUpdateInterval) {
        clearInterval(posUpdateInterval);
        posUpdateInterval = null;
      }
    };
    const resumePositionUpdate = (val) => {
      const wasPlaying = playing.value;
      stopPositionUpdate();
      console.log('resume pos update', val);
      setPosition(val);
      if (wasPlaying) {
        startPositionUpdate();
      }
    };
    rtcDataConnection.setStudentPlayCallback(startPositionUpdate);
    rtcDataConnection.setStudentStopCallback(stopPositionUpdate);

    watch(() => mp3File.value.file, () => {
      resetToStart();
    });

    const isMobile = computed(() => store.getters.isMobile);

    return {
      mp3File,
      filename,
      play,
      pause,
      stop,
      playing,
      timeTrackModel,
      formatTimeTrack,
      resumePositionUpdate,
      stopPositionUpdate,
      audioDuration,
      formatAudioDuration,
      setPosition,
      audioSettings,
      isMobile,
      loadingAnalyzer,
      isTeacher
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-audio-controls {
  position: relative;
  user-select: none;

  &_mobile {

  }

  &-header {
    display: grid;
    grid-template-columns: 30px auto;
    grid-gap: 12px;
    align-items: center;
    width: calc(100% - 163px);
    cursor: default;

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &_mobile {
      min-height: 42px;
      width: calc(100% - 50px);
    }

    &_student {
      grid-template-columns: auto;
    }

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $color-white
    }

    &-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: $color-white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 19px;
    }

    &__name-placeholder {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translate(0, -50%);
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      opacity: 1;
      z-index: 3;
      text-align: center;

      &_mobile {
        font-size: 12px;
        max-width: calc(100% - 50px);
        //text-align: left;
        top: 0;
        transform: none;
        min-height: 42px;
        display: flex;
        align-items: center;
        opacity: 0.5;
        line-height: 20px;
      }
    }
  }

  &-body {
    position: relative;
    margin-top: 16px;
    display: grid;
    width: 100%;
    height: 59px;

    &_mobile {
      height: 29px;
    }

    &__timeline {
      height: 100%;
    }

    &__time {
      position: absolute;
      bottom: -6px;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 2px;
      background: $color-shark;
      border-radius: 1px;
      z-index: 4;

      &_current {
        color: $color-white;
        left: -4px;
      }

      &_end {
        color: $color-oslo-gray;
        right: -4px;
      }
    }
  }
}

</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-audio-controls {
  .vue-slider-dot-handle {
    background-color: $color-accent;
    box-shadow: none;
    margin: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
}

</style>
