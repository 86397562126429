import { find, get, isEmpty } from 'lodash';

const validateProfile = (inputs, isGroup) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input, index) => {
    const hasValidator = typeof input.isValid === 'function';
    const hasValidatorArray = typeof input.isValidArray === 'function';
    const overValue = get(find(inputs, { modelName: input.isOver }), 'modelValue', '');
    const emptyValue = !input.modelValue || (typeof input.modelValue === 'object' && isEmpty(input.modelValue));
    if (input.required && emptyValue) {
      input.error = input.emptyError;
      errors += 1;
    } else if (hasValidator && !input.isValid(input.modelValue)) {
      input.error = input.validError;
      errors += 1;
    } else if (hasValidatorArray && input.isValidArray(input.modelValue).length) {
      input.error = input.validError;
      input.errorArray = input.isValidArray(input.modelValue);
      errors += 1;
    } else if (input.isOver && input.modelValue && (overValue > input.modelValue)) {
      input.error = input.overError;
      errors += 1;
    } else if (input.modelName === 'crmLessonEnd' && input.checkDateValid(input.modelValue, inputs)) {
      input.error = input.validError;
      errors += 1;
    } else {
      input.error = false;
      const hasGetter = typeof input.getValue === 'function';
      body[input.modelName] = hasGetter ? input.getValue(input.modelValue) : input.modelValue;
    }
  });
  return errors === 0 ? body : false;
};

export default validateProfile;