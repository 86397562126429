<template>
  <div
    v-if="!isMobile"
    class="lp-crm-settings-price"
  >
    <div
      class="lp-crm-settings-price-header"
      :class="{'lp-crm-settings-price-header_mobile': isMobile}"
      v-if="editable"
    >
      <div>
        <div
          class="lp-crm-settings-price-header__icon"
          v-if="settingsStore._id && !noProfile"
          @click="closeEditable"
        >
          <closeModalIcon />
        </div>
      </div>
      <div
        class="lp-crm-settings-price-header__title"
        :class="{'lp-crm-settings-price-header__title_alone': !settingsStore._id || noProfile}"
      >
        {{ $t(noProfile || noProfile ? 'profile.mainInfoRequired' : 'crm.settings.title') }}
      </div>
      <div
        class="lp-crm-settings-price-header__icon"
        @click="checkSaveSettings"
      >
        <checkIcon />
      </div>
    </div>
    <div
      class="lp-crm-settings-price-inputs lp-crm-settings-price-inputs_edit"
      :class="{
        'lp-crm-settings-price-inputs_mobile': isMobile,
        'lp-crm-settings-price-inputs_edit_mobile': isMobile
      }"
      v-if="editable"
    >
      <SelectLang
        v-model:currency="settings.language"
        :defaultValue="settings.language"
      />
      <div class="lp-crm-settings-price-inputs_costBlock">
        <LessonCost
          v-model:cost="settings.defaultLessonCost"
          :defaultValue="settings.defaultLessonCost"
          v-model:error="errorsSettings.defaultLessonCost"
          :error="errorsSettings.defaultLessonCost"
          :currency="settings.currentCurrency"
          @submit="checkSaveSettings"
        />
        <Currency
          v-model:currency="settings.currentCurrency"
          :defaultValue="settings.currentCurrency"
        />
      </div>
      <SelectTimeZones
        v-model:timezone="settings.timezone"
        :defaultValue="settings.timezone"
      />
      <DefaultTime
        v-model:time="settings.defaultLessonLength"
        :defaultValue="settings.defaultLessonLength"
        v-model:error="errorsSettings.defaultLessonLength"
        :error="errorsSettings.defaultLessonLength"
        :postfix="$t('crm.settings.minutes')"
      />
    </div>
    <div
      class="lp-crm-settings-price-inputs"
      :class="{
        'lp-crm-settings-price-inputs_mobile': isMobile
      }"
      v-else
    >
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.currency') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.currentCurrency }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.cost') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.defaultLessonCost }}
          {{ settingsStore.currentCurrency }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
        v-if="language.id"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.language') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          <img
            class="lp-crm-settings-price-inputs-item__icon"
            :src="language.icon"
            alt="flag"
          />
          {{ $t(language.title) }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.time') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.defaultLessonLength }}
          {{ $t('crm.settings.minutes') }}
        </div>
      </div>
      <div
        v-if="settingsStore.timezone"
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.timeZones') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.timezone }}
        </div>
      </div>
    </div>
    <div
      class="lp-crm-settings-price__footer"
      v-if="editable && !isMobile"
    >
      <button
        class="lp-button lp-button_thin"
        v-if="settingsStore._id && !noProfile"
        @click="closeEditable"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        class="lp-button lp-crm-settings-price__button-save"
        @click="checkSaveSettings"
      >
        {{ $t('buttons.save') }}
      </button>
    </div>
    <CurrencyChangeRate
      v-model:openForm="openForm"
      v-model:rate="settings.currencyExchangeRate"
      @save="saveSettings"
      :openForm="openForm"
      :currency="{from: settingsStore.defaultCurrency, to: settings.currentCurrency}"
    />
  </div>

  <div
    v-else
    class="lp-crm-settings-price"
  >
    <div
      class="lp-crm-settings-price-header"
      :class="{'lp-crm-settings-price-header_mobile': isMobile}"
      v-if="editable"
    >
      <div>
        <div
          class="lp-crm-settings-price-header__icon"
          v-if="settingsStore._id && !noProfile"
          @click="closeEditable"
        >
          <closeModalIcon />
        </div>
      </div>
      <div
        class="lp-crm-settings-price-header__title"
        :class="{'lp-crm-settings-price-header__title_alone': !settingsStore._id || noProfile}"
      >
        {{ $t(noProfile || noProfile ? 'profile.mainInfoRequired' : 'crm.settings.title') }}
      </div>
      <div
        class="lp-crm-settings-price-header__icon"
        @click="checkSaveSettings"
      >
        <checkIcon />
      </div>
    </div>
    <div
      class="lp-crm-settings-price-inputs lp-crm-settings-price-inputs_edit"
      :class="{
        'lp-crm-settings-price-inputs_mobile': isMobile,
        'lp-crm-settings-price-inputs_edit_mobile': isMobile
      }"
      v-if="editable"
    >
      <SelectLang
        v-model:currency="settings.language"
        :defaultValue="settings.language"
      />
      <SelectTimeZones
        v-model:timezone="settings.timezone"
        :defaultValue="settings.timezone"
      />
      <div class="lp-crm-settings-price-inputs_costBlock">
        <LessonCost
          v-model:cost="settings.defaultLessonCost"
          :defaultValue="settings.defaultLessonCost"
          v-model:error="errorsSettings.defaultLessonCost"
          :error="errorsSettings.defaultLessonCost"
          :currency="settings.currentCurrency"
          @submit="checkSaveSettings"
        />
        <Currency
          v-model:currency="settings.currentCurrency"
          :defaultValue="settings.currentCurrency"
        />
      </div>
      <DefaultTime
        v-model:time="settings.defaultLessonLength"
        :defaultValue="settings.defaultLessonLength"
        v-model:error="errorsSettings.defaultLessonLength"
        :error="errorsSettings.defaultLessonLength"
        :postfix="$t('crm.settings.minutes')"
      />
    </div>
    <div
      class="lp-crm-settings-price-inputs"
      :class="{
        'lp-crm-settings-price-inputs_mobile': isMobile
      }"
      v-else
    >
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
        v-if="language.id"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.language') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          <img
            class="lp-crm-settings-price-inputs-item__icon"
            :src="language.icon"
            alt="flag"
          />
          {{ $t(language.title) }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          v-if="settingsStore.timezone"
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.timeZones') }}
        </div>
        <div
          v-if="settingsStore.timezone"
          class="lp-crm-settings-price-inputs-item__value"
        >
          {{ settingsStore.timezone }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.currency') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.currentCurrency }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.cost') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.defaultLessonCost }}
          {{ settingsStore.currentCurrency }}
        </div>
      </div>
      <div
        class="lp-crm-settings-price-inputs-item"
        :class="{
          'lp-crm-settings-price-inputs-item_mobile': isMobile
        }"
      >
        <div
          class="lp-crm-settings-price-inputs-item__label"
          :class="{
            'lp-crm-settings-price-inputs-item__label_mobile': isMobile
          }"
        >
          {{ $t('crm.settings.time') }}
        </div>
        <div class="lp-crm-settings-price-inputs-item__value">
          {{ settingsStore.defaultLessonLength }}
          {{ $t('crm.settings.minutes') }}
        </div>
      </div>
    </div>
    <div
      class="lp-crm-settings-price__footer"
      v-if="editable && !isMobile"
    >
      <button
        class="lp-button lp-button_thin"
        v-if="settingsStore._id && !noProfile"
        @click="closeEditable"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        class="lp-button lp-crm-settings-price__button-save"
        @click="checkSaveSettings"
      >
        {{ $t('buttons.save') }}
      </button>
    </div>
    <CurrencyChangeRate
      v-model:openForm="openForm"
      v-model:rate="settings.currencyExchangeRate"
      @save="saveSettings"
      :openForm="openForm"
      :currency="{from: settingsStore.defaultCurrency, to: settings.currentCurrency}"
    />
  </div>
</template>

<script>
import { computed, inject, onBeforeMount, reactive, ref } from 'vue';
import Currency from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/Currency';
import LessonCost from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/LessonCost';
import CurrencyChangeRate from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/CurrencyChangeRate';
import SettingsApi from '@/api/CRM/settings';
import { map, get, find } from 'lodash';
import timeout from '@/constants/utils/timeout';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import country from '@/constants/country';
import CURRENCY from '@/constants/enums/currency';
import MobileDetect from 'mobile-detect';
import { closeModalIcon } from '@/constants/icons';
import { checkIcon } from '@/constants/icons/crm';
import LANGUAGES from '@/constants/enums/languages';
import checkCountries from '@/constants/countries/checkCountries';
import SelectLang from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/SelectLang';
import DefaultTime from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/DefaultTime';
import SelectTimeZones from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/SelectTimeZones';

export default {
  name: 'SettingsPrice',
  components: {
    DefaultTime,
    SelectLang,
    SelectTimeZones,
    CurrencyChangeRate,
    LessonCost,
    Currency,
    checkIcon,
    closeModalIcon
  },
  props: {
    noProfile: Boolean,
    editable: Boolean
  },
  emits: ['save', 'close', 'open-edit', 'update:settings'],
  setup (props, { emit }) {
    const loading = ref(false);
    const saved = ref(false);
    const countryCode = inject('countryCode');

    const store = useStore();
    const settingsStore = computed(() => store.getters.settings);

    const setSettingsStore = (data) => {
      if (!data.defaultCurrency) data.defaultCurrency = '';
      store.dispatch('setSettings', data);
    };

    const language = computed(() => {
      const defaultLang = checkCountries(countryCode) ? 'ru' : 'en';
      return find(LANGUAGES, item => item.id === settingsStore.value.language) || defaultLang;
    });

    const errorsSettings = reactive({
      defaultLessonLength: '',
      defaultLessonCost: '',
      currentCurrency: ''
    });
    const validateSettings = () => {
      const { defaultLessonCost: cost } = settings;
      if (+cost <= 0 || isNaN(+cost)) {
        errorsSettings.defaultLessonCost = 'errors.correctAmount';
        return false;
      }
      return true;
    };

    const settings = reactive({
      defaultLessonLength: settingsStore.value.defaultLessonLength,
      defaultLessonCost: '',
      currentCurrency: '',
      currencyExchangeRate: 1,
      language: language.value.id,
      timezone: settingsStore.value?.timezone,
      countryCode
    });

    const openForm = ref(false);
    const currencyChanged = computed(() => {
      const hasDefault = settingsStore.value.defaultCurrency;
      const isNotDefault = settings.currentCurrency !== settingsStore.value.defaultCurrency;
      const isNotCurrency = settings.currentCurrency !== settingsStore.value.currentCurrency;
      return hasDefault && isNotCurrency && isNotDefault;
    });

    const settingsChanged = computed(() => {
      const values = map(settings, (value, key) => {
        if (key === 'countryCode' || key === 'currencyExchangeRate') return true;
        return value === settingsStore.value[key];
      });
      return values.every(item => item);
    });

    const setSettings = (data) => {
      const currencyUser = get(country.find((item => item.code === countryCode)), 'currency', CURRENCY.USD);
      const { defaultLessonCost = null, currentCurrency = currencyUser , defaultLessonLength = 60 } = data;
      settings.defaultLessonLength = defaultLessonLength;
      settings.defaultLessonCost = defaultLessonCost;
      settings.currentCurrency = currentCurrency;
      settings.countryCode = countryCode;
      loading.value = false;
    };

    const checkSaveSettings = () => {
      const validate = validateSettings();
      if (!validate) return;
      if (currencyChanged.value) {
        openForm.value = true;
      } else {
        saveSettings();
      }
    };

    const changeLanguageCurrent = inject('changeLanguageCurrent');

    const saveSettings = async () => {
      openForm.value = false;
      if (saved.value) return;
      emit('save', props.noProfile);
      try {
        if (settings.currentCurrency === settingsStore.value.defaultCurrency) settings.currencyExchangeRate = 1;
        const { data } = await SettingsApi.saveSettings(settings, settingsStore.value._id);
        if (!settingsStore.value._id) yandexTracker.settings();
        saved.value = true;
        await setSettingsStore(data);
        setSettings(settingsStore.value);
        changeLanguageCurrent(settingsStore.value.language);
        await timeout(2000);
        saved.value = false;
        localStorage.setItem('lang', settingsStore.value.language);
      } catch (e) {
        console.error(e);
      }
    };
    const getSettings = async () => {
      if (settingsStore.value._id) {
        setSettings(settingsStore.value);
        return;
      }
      try {
        const { data } = await SettingsApi.getSettings();
        emit('update:settings', !data._id);
        if (!data._id) {
          emit('open-edit', true);
        }
        await setSettingsStore(data);
        setSettings(settingsStore.value);
      } catch (e) {
        return e;
      }
    };

    const closeEditable = () => {
      emit('close');
      getSettings();
    };

    onBeforeMount(getSettings);

    const openTooltip = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      language,
      openTooltip,
      saved,
      loading,
      saveSettings,
      checkSaveSettings,
      settings,
      errorsSettings,
      settingsChanged,
      currencyChanged,
      openForm,
      settingsStore,
      closeEditable,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-price {
  display: grid;
  grid-template-columns: 1fr;

  &-header {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    background-color: $color-white;
    border-bottom: 1px solid $color-alto;
    grid-template-columns: auto 1fr auto;
    z-index: 14;

    &_mobile {
      display: grid;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-text;
      text-align: center;

      &_alone {
        padding-left: 54px;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 54px;
      padding: 17px;
    }
  }

  &-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 34px;

    &_mobile {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }

    &_edit {
      grid-gap: 28px 18px;

      &_mobile {
        display: flex;
        flex-direction: column;
      }
    }
    &_costBlock {
      display: flex;
      column-gap: 10px;

      @media (max-width: 768px) {
        justify-content: space-between;
      }
    }

    &-item {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 14px;
      align-items: center;
      font-size: 14px;
      line-height: 125%;
      color: $color-text;

      &_mobile {
        align-items: start;
        grid-template-columns: 125px 1fr;
      }

      &__label {
        font-weight: 800;
        letter-spacing: 0.07em;
        white-space: nowrap;

        &_mobile {
          white-space: pre-wrap;
        }

        &:after {
          content: ':';
        }
      }

      &__value {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      &__icon {
        width: 18px;
        object-fit: cover;
        margin-right: 8px;
      }
    }
  }

  &__footer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 170px);
    grid-gap: 0 4px;
    align-items: center;
    justify-content: end;
    margin-top: 38px;
  }

  &__button-save {
    grid-column: 2;
  }

}

</style>
