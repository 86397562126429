<template>
  <div
    class="lp-remote-video"
    :style="{width: !isMobile && !preview && videoWidth && `${videoWidth}px`}"
    :class="{
      'lp-remote-video_mute': videoSettings.muteRemote,
      'lp-remote-video_mobile': isMobile,
      'lp-remote-video_preview': preview,
      'lp-remote-video_preview-mobile': isMobile && preview,
      'lp-remote-video_sharing-file': sharingFile && !isMobile && !preview,
      'lp-remote-video_sharing-file-teacher': sharingFile && !isMobile && !preview && isTeacher
    }"
    v-touch:swipe="onSwipe"
    @contextmenu.stop.prevent
  >
    <DisplaySharingFile
      v-if="!!sharingFile && !isMobile && !preview && !mobileParticipant"
      :file="sharingFile"
      :videoWidth="videoWidth"
      :localVideoStreamId="localVideoStream?.streamid"
      :remoteVideoStreamId="`video_${remoteVideoStream?.streamid}`"
    />
    <div
      class="lp-remote-video__output lp-remote-video__output_mute"
      v-if="(hasRemoteStream && videoSettings.muteRemote)
        || (!hasRemoteStream && hasLocalStream && videoSettings.muteLocal)"
    />
    <video
      v-if="hasRemoteStream"
      v-show="!videoSettings.muteRemote"
      class="lp-remote-video__output"
      :class="{
        'lp-remote-video__output_preview': preview,
        'lp-remote-video__output_mobile': isMobile,
        'lp-remote-video__output_student': !isTeacher,
        'lp-remote-video__output_mirror': mirror,
        'lp-remote-video__output_vertical': isVerticalVideo && !isMobile && !preview
      }"
      ref="remoteVideoEl"
      autoplay
      playsinline
      :srcObject="remoteVideoStream"
      :id="`video_${remoteVideoStream.streamid}`"
    />
    <video
      v-else-if="hasLocalStream"
      class="lp-remote-video__output"
      :class="{
        'lp-remote-video__output_preview': preview,
        'lp-remote-video__output_mobile': isMobile,
        'lp-remote-video__output_mobile_preview': isMobile && preview,
        'lp-remote-video__output_student': !isTeacher,
        'lp-remote-video__output_mirror': videoSettings.mirror,
        'lp-remote-video__output_hide': !participants && sharingFile
      }"
      ref="localVideoEl"
      autoplay
      playsinline
      :srcObject="localVideoStream"
      :muted="true"
      :id="`video_${localVideoStream.streamid}`"
    />
    <div
      class="lp-remote-video__output lp-remote-video__output_gag"
      v-else-if="displayLoader"
    >
      <Loader
        :block="true"
      />
    </div>
    <VideoCallToolbar
      v-if="!preview && videoWidth"
      :openMobileSlider="openMobileSlider"
      v-model:openMobileSlider="openMobileSlider"
      :preview="preview"
      :videoWidth="videoWidth"
    />
    <div
      class="lp-remote-video-end-room"
      :class="{
        'lp-remote-video-end-room_mobile': isMobile,
        'lp-remote-video-end-room_mobile-teacher': isMobile && isTeacher
      }"
      v-if="!preview && (isTeacher || isMobile)"
    >
      <div
        class="lp-remote-video-end-room__info"
        v-if="false"
      >
        <InfoIcon />
      </div>
      <FreeTime v-if="isTeacher" />
      <VideoCallEnd v-if="!preview && isMobile" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import Loader from '@/components/Main/Loader/Loader';
import { debounce, find } from 'lodash';
import VideoCallToolbar from '@/components/VideoCall/VideoCallToolbar';
import VideoCallEnd from '@/components/VideoCall/VideoCallTools/VideoCallEnd/VideoCallEnd';
import { InfoIcon } from '@/constants/icons/crm';
import DisplaySharingFile from '@/components/VideoCall/VideoCallTools/SharingFiles/DisplaySharingFile';
import FreeTime from '@/components/VideoCall/VideoCallTools/FreeTime/FreeTime';

export default {
  name: 'VideoRemoteStream',
  components: { FreeTime, DisplaySharingFile, VideoCallToolbar, Loader, VideoCallEnd, InfoIcon },
  props: {
    switchVideo: Boolean,
    mirror: Boolean,
    preview: Boolean,
    isTeacher: Boolean,
    displayLoader: Boolean
  },
  setup (props, { emit }) {
    const store = useStore();
    const streams = computed(() => store.getters.streams);
    const participants = computed(() => store.getters.participants);
    const sharingFile = computed(() => store.getters.sharingFile);
    const mobileParticipant = computed(() => store.getters.mobileParticipant);

    const localVideoStream = computed(() => store.getters.localStream);
    const remoteVideoStream = computed(() => {
      const targetStream = find(streams.value, stream => {
        return stream.type === 'remote' && stream.srcObject.active;
      }) || {};
      return targetStream.srcObject;
    });

    const videoSettings = computed(() => store.getters.videoSettings);
    const hasRemoteStream = computed(() => props.switchVideo ? false : remoteVideoStream.value);
    const hasLocalStream = computed(() => (props.switchVideo || !participants.value) && localVideoStream.value);

    const remoteVideoEl = ref(null);
    const localVideoEl = ref(null);

    watch(remoteVideoEl, (video) => {
      if (!video) return;
      video.onpause = () => {
        video.play();
      };
    });

    const isMobile = computed(() => store.getters.isMobile);

    const openMobileSlider = ref(false);
    watch(openMobileSlider, () => {
      emit('update:openMobileSlider', openMobileSlider.value);
    });

    const onSwipe = (position) => {
      if (position === 'top') {
        openMobileSlider.value = true;
      } else if (position === 'bottom') {
        openMobileSlider.value = false;
      }
    };

    const isVerticalVideo = ref(false);
    const detectVerticalVideo = () => {
      let video = document.createElement('video');
      video.muted = true;

      video.onloadedmetadata = () => {
        isVerticalVideo.value = video.videoWidth < video.videoHeight;
        video = null;
      };
      video.srcObject = remoteVideoStream.value;
    };
    watch(remoteVideoStream, () => {
      if (!remoteVideoStream.value) return;
      detectVerticalVideo();
    });

    const videoWidth = ref(0);
    const calcVideoWidth = () => {
      const videoEl = remoteVideoEl.value || localVideoEl.value;
      const stream = videoEl?.srcObject;
      if (!stream) return;
      let video = document.createElement('video');
      video.onloadedmetadata = () => {
        const value = (video.videoWidth * window.innerHeight / video.videoHeight);
        videoWidth.value = value > window.innerWidth ? window.innerWidth : value;
        video = null;
      };
      video.srcObject = stream;
    };
    const calcVideoWidthDebounce = computed(() => debounce(calcVideoWidth, 500));

    watch([remoteVideoEl, localVideoEl], calcVideoWidth);

    onBeforeMount(() => {
      detectVerticalVideo();
      window.addEventListener('resize', calcVideoWidthDebounce.value, false);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', calcVideoWidthDebounce.value, false);
    });

    return {
      isVerticalVideo,
      isMobile,
      streams,
      remoteVideoStream,
      localVideoStream,
      participants,
      videoSettings,
      hasRemoteStream,
      hasLocalStream,
      remoteVideoEl,
      localVideoEl,
      onSwipe,
      openMobileSlider,
      videoWidth,
      sharingFile,
      mobileParticipant
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-remote-video {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  max-height: calc(100vh);
  max-height: calc((var(--vh, 1vh) * 100));
  background-color: $background-video-call;
  min-width: 1170px;

  &_sharing-file {
    display: grid;
    grid-template-columns: 1fr auto;
    height: calc(100vh);
    height: calc((var(--vh, 1vh) * 100));
  }

  &_mute {
    height: calc(100vh);
    height: calc((var(--vh, 1vh) * 100));
  }

  &_mobile {
    align-items: flex-start;
    overflow: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    min-width: 100vw;
  }

  &__stat {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    background: $color-white;
    padding: 12px;
  }

  &_preview {
    max-width: 998px;
    width: auto;
    max-height: calc(100vh - 262px);
    max-height: calc((var(--vh, 1vh) * 100) - 262px);
    height: 100%;
    min-height: auto;
    min-width: auto;
  }

  &_preview-mobile {
    max-height: none;
    max-width: 100%;
    min-width: calc(100vw - 24px);
    height: 100%;
    border-radius: 4px;
    background-color: $background-video-call;
  }

  &_sharing-file &__output {
    position: absolute;
    top: 0;
    right: 0;
    max-height: calc(50vh - 180px);
    min-height: auto;
    max-width: 325px;
    box-shadow: 0 0 15px rgba($color-black, 0.05);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $background-video-call;
    object-position: left;

    @media (max-width: 1143px) {
      max-width: 33.333vw;
      max-height: 80%;
      min-width: 100px;
    }
  }

  &_sharing-file-teacher &__output {
    top: 40px;
  }

  &__output {
    width: 100%;
    height: auto;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
    object-fit: contain;
    object-position: center top;
    pointer-events: none;
    margin-bottom: 129px;

    &_hide {
      opacity: 0;
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }

    &_vertical {
      object-fit: contain;
      width: auto;
      max-width: 1143px;
      max-height: calc(100vh - 129px);
      max-height: calc((var(--vh, 1vh) * 100) - 129px);
      min-height: calc(100vh - 129px);
      min-height: calc((var(--vh, 1vh) * 100) - 129px);
    }

    &_preview {
      margin-bottom: 0;
    }

    &_mobile {
      object-fit: cover;
      max-height: calc(100vh - 108px);
      max-height: calc((var(--vh, 1vh) * 100) - 92px);
      margin-top: 46px;
      height: 100%;

      @media (orientation: landscape) {
        max-height: calc(100vh - 46px);
        max-height: calc((var(--vh, 1vh) * 100) - 46px);
      }

      &_preview {
        margin: 0;
      }
    }

    &_mute {
      position: absolute;
      background-color: $color-black;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &_mirror {
      transform: scaleX(-1);
    }

    &_gag {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      height: -webkit-fill-available;
      background-color: $color-white;
      z-index: 100;
    }
  }

  &_mini {
    width: 200px;
    height: 200px;
  }

  &__audio {
    position: fixed;
    top: -100vh;
    left: -100vw;
    opacity: 0;
    z-index: -1;
  }

  &-end-room {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 9px;
    background: $color-shark;
    z-index: 2;

    &_mobile {
      position: fixed;
      transform: none;
      top: 0;
      left: 0;
      padding: 5px;
      height: auto;
      border-radius: 0;
      background-color: $color-shark;
      width: 100%;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: flex-end;
      z-index: 4;
    }

    &_mobile-teacher {
      top: 51px;
    }

    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
      padding: 10px;
      cursor: pointer;
      max-height: 36px;
      max-width: 36px;
      transform: scale(0);
    }
  }
}

</style>
