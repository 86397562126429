<template>
  <div
    v-if="!!tabs.length"
    class="tabs"
    :class="{mobileTabs: isMobile}"
  >
    <div
      v-for="tab in tabs"
      :key="tab.id"
      @click="$emit('checked', tab.value)"
      class="tabs__tab"
      :class="{active: tab.value === activeTab, mobileTab: isMobile}"
    >
      {{ tab.title.toUpperCase() }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppTab',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean
    }
  },
  emits: ['checked']
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';
  .tabs{
    display: flex;
    column-gap: 36px;

    &.mobileTabs {
      column-gap: 20px;
    }

    &__tab {
      font-weight: 800;
      padding: 8px 0;
      letter-spacing: 3px;
      line-height: 27.5px;
      font-size: $tab-font-size;
      color: $color-gray;
      cursor: pointer;
      position: relative;

      &.active {
        color: $color-mine-shaft;
      }

      &.active::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $color-purple;
      }

      &.mobileTab {
        line-height: 20px;
        font-size: $tab-mobile-font-size;
        padding: 0 0 8px 0;
      }
    }
  }
</style>
