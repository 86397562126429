<template>
  <div
    class="lp-crm-settings-default-time"
    :class="{'lp-crm-settings-default-time_mobile': isMobile}"
    ref="inputRef"
  >
    <CustomInput
      class="lp-crm-settings-default-time__input"
      v-model="defaultTime"
      :debounceTime="Number(450)"
      :minValueNumber="15"
      :label="'crm.settings.time'"
      :type="'number'"
      :inputmode="'decimal'"
      :noWrap="true"
      :error="error"
      :required="required"
      :postfix="postfix"
      @focus="$emit('update:error', '')"
      @submit="$emit('submit')"
    >
    </CustomInput>
  </div>
</template>

<script>
import CustomInput from '@/components/Main/Inputs/CustomInput';
import { computed, onBeforeMount, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'DefaultTime',
  components: { CustomInput },
  props: {
    error: String,
    defaultValue: [Number, String],
    postfix: String,
    required: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:time', 'update:error', 'submit'],
  setup (props, { emit }) {
    const defaultTime = ref(null);

    const emitValue = () => {
      emit('update:time', defaultTime.value);
    };

    watch(defaultTime, (val) => {
      if (val > 181) {
        defaultTime.value = 0;
      } else if (val <= 15) {
        defaultTime.value = 15;
      } else {
        defaultTime.value = Math.round(val / 5) * 5;
      }
      emitValue();
    });

    const setDefault = () => {
      if (props.defaultValue) defaultTime.value = props.defaultValue;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    const inputRef = ref(null);
    watch(() => props.error, (val) => {
      if (!val || !inputRef.value) return;
      inputRef.value.scrollIntoView({ block: 'center' });
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      defaultTime,
      inputRef
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-default-time {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  grid-gap: 10px;
  color: $color-text;

  &_mobile {
    grid-template-columns: 160px auto;
  }

  &__input {
    width: 180px;
  }
}

</style>
