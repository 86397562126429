export const SOCKET_SERVER_TOOL = window.location.hostname === 'localhost'
  ? 'http://localhost:3000/'
  : 'https://socket.musio.io/';

export const SOCKET_SERVER_MUSIO = window.location.hostname === 'localhost'
  ? 'http://localhost:3001/'
  : `https://${process.env.VUE_APP_API_HOST}/`;

export const FRONTEND_URL = window.location.hostname === 'localhost'
  ? 'http://localhost:8080'
  : `${window.location.protocol}//${window.location.hostname}`;

export const MUSIO_SERVER = window.location.hostname === 'localhost'
  ? 'http://localhost:3001/api/v1/'
  : `https://${process.env.VUE_APP_API_HOST}/api/v1/`;

export const MUSIO_SERVER_PROD = `https://${process.env.VUE_APP_API_HOST || 'stg-go.musio.io'}/api/v1/`;

/** domains to STG */
// export const SOCKET_SERVER_TOOL = 'https://socket.musio.io/';
// export const SOCKET_SERVER_MUSIO = 'https://stg-go.musio.io/';
// export const FRONTEND_URL = 'http://localhost:8080';
// export const MUSIO_SERVER = 'https://stg-go.musio.io/api/v1/';
// export const MUSIO_SERVER_PROD = `https://${process.env.VUE_APP_API_HOST || 'stg-go.musio.io'}/api/v1/`;
