<template>
  <div class="lp-hints-tours-desctope">
    <div
      v-if="activeIndex !== 0"
      class="lp-hints-tours-desctope__arrow lp-hints-tours-desctope__arrow_left"
      @click="onPrevSlide"
    >
      <arrowBackIcon />
    </div>
    <Swiper
      ref="swiper"
      :slidesPerView="1"
      :spaceBetween="50"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <SwiperSlide
        class="lp-hints-tours-desctope-slide"
        v-for="slide in hints"
        :key="slide.id"
      >
        <div class="lp-hints-tours-desctope-slide-left">
          <div class="lp-hints-tours-desctope-slide-left__counter">
            <span class="lp-hints-tours-desctope-slide-left__counter_active">
              {{ slide.id }}
            </span>
            <span>/</span>
            <span>{{ hints.length }}</span>
          </div>
          <div class="lp-hints-tours-desctope-slide-left__title">
            {{ $t(slide.title) }}
          </div>
          <div class="lp-hints-tours-desctope-slide-left__description">
            {{ $t(slide.description1) }}
          </div>
          <div class="lp-hints-tours-desctope-slide-left__description">
            {{ $t(slide.description2) }}
          </div>
          <div class="lp-hints-tours-desctope-slide-left-buttons">
            <div
              class="lp-hints-tours-desctope-slide-left-buttons__item
              lp-hints-tours-desctope-slide-left-buttons__item_first
              lp-button lp-button_white"
              @click="onButtonClick"
            >
              {{ $t('hints.firstStep.buttonTwo') }}
            </div>
            <div
              class="lp-hints-tours-desctope-slide-left-buttons__item lp-button"
              @click="onNextSlide(slide.id)"
            >
              {{ $t('hints.buttonFurther') }}
            </div>
          </div>
        </div>
        <div class="lp-hints-tours-desctope-slide-right">
          <img
            :src="slide.imgDesctope"
            alt=""
          />
        </div>
      </SwiperSlide>
    </Swiper>
    <div
      v-if="activeIndex !== hints.length - 1"
      class="lp-hints-tours-desctope__arrow lp-hints-tours-desctope__arrow_right"
      @click="onNextSlide()"
    >
      <arrowBackIcon />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { arrowBackIcon } from '@/constants/icons/crm';

export default {
  name: 'HintsToursDesctope',
  components: {
    Swiper,
    SwiperSlide,
    arrowBackIcon
  },
  props: {
    hints: {
      type: Array,
      default: () => []
    }
  },
  emits: ['on-button-click'],
  setup (props, { emit }) {
    const swiper = ref(null);
    const slider = ref(null);
    const activeIndex = ref(0);

    const onPrevSlide = () => {
      slider.value.slidePrev();
      activeIndex.value = slider.value.activeIndex;
    };

    const onNextSlide = (id) => {
      slider.value.slideNext();
      if (id === props.hints.length) emit('on-button-click');
      activeIndex.value = slider.value.activeIndex;
    };

    const onSwiper = (swiper) => {
      slider.value = swiper;
    };

    const onSlideChange = (swiper) => {
      activeIndex.value = swiper.activeIndex;
    };

    const onButtonClick = () => {
      emit('on-button-click');
    };

    return {
      activeIndex,
      swiper,
      onButtonClick,
      onPrevSlide,
      onNextSlide,
      onSwiper,
      onSlideChange
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-hints-tours-desctope {
  width: 1140px;
  border-radius: 4px;
  height: 560px;
  background: $color-white;
  position: relative;

  &-slide {
    height: 100%;
    display: grid;
    grid-template-columns: 540px 600px;
    color: $color-sine-shaft;

    &-left {
      padding: 60px 55px 60px 70px;
      position: relative;

      &__counter {
        line-height: 20px;
        font-size: 16px;
        font-family: $global-font-bold;

        &_active {
          line-height: 25px;
          font-size: 20px;
          color: $color-purple;
        }
      }

      &__title {
        margin: 92px 0 24px 0;
        font-family: $global-font-bold;
        font-size: 28px;
        line-height: 35px;
      }

      &__description {
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
      }

      &-buttons {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 90px;
        right: 76px;

        &__item {
          width: 132px;
          height: 50px;
          padding: 16px 10px;

          &_first {
            font-size: 12px;
            padding: 16px 0;
            box-shadow: none;
          }

          & + & {
            margin: 0 0 0 21px;
          }
        }
      }
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius: 50%;
    background: $color-white;
    box-shadow: 0 0 27px rgba($color-black, 0.09);
    top: 50%;
    transform: translate(0, -50%);
    z-index: 100;
    cursor: pointer;
    transition: 0.15s;

    &_left {
      right: auto;
      left: -30px;
    }

    &_right {
      right: -30px;
      left: auto;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
</style>

<style lang="scss">
@import '~@/sass/variables';

.lp-hints-tours-desctope__arrow {
  svg path {
    fill: $color-purple;
  }
}
</style>
