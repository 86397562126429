<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__left">
        <div class="header__logo">
          <router-link :to="{name: 'Landing'}">
            <newLogoIcon />
          </router-link>
        </div>
        <ul class="header__list">
          <template v-for="item in landingHeader">
            <li
              v-if="!item.showLang || item.showLang === locale"
              :key="item.id"
              class="header__list-item"
              :class="{
                'header__list-active-item': navActive === item.transition
              }"
              @click="handleClickMenu(item.transition)"
            >
              {{ $t(item.text) }}
            </li>
          </template>
        </ul>
      </div>
      <div class="header__right">
        <div class="header-icons">
          <a
            href="https://www.youtube.com/channel/UC1X3lRIe5H2N3HU6EIFoZRw"
            class="header-icons__icon"
            target="_blank"
          >
            <youtubeIcon />
          </a>
          <a
            href="https://t.me/joinchat/yV7IcfisXiEwMjMy"
            class="header-icons__icon"
            target="_blank"
          >
            <telegramIcon />
          </a>
          <a
            href="https://instagram.com/musio.io?utm_medium=copy_link"
            class="header-icons__icon"
            target="_blank"
          >
            <instaIcon />
          </a>
        </div>
        <SelectLang
          v-model:currency="locale"
          :defaultValue="locale"
          :selectNoLabel="selectNoLabel"
          class="header__lang"
        />
        <button
          @click="goToPage('Sign-up')"
          class="header__btn"
        >
          {{ $t('signUp.button') }}
        </button>
        <button
          @click="goToPage('Login')"
          class="header__btn header__btn_sign-up"
        >
          {{ $t('login.button') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  newLogoIcon,
  youtubeIcon,
  instaIcon,
  telegramIcon
} from '@/constants/icons/landing';
import landingHeader from '@/constants/landing/landingHeader';
import scrollTo from '@/constants/utils/scrollTo';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import SelectLang from '@/components/Landing/LandingDesktop//SelectLang';

export default {
  name: 'Header',
  components: {
    SelectLang,
    newLogoIcon,
    youtubeIcon,
    instaIcon,
    telegramIcon
  },
  props: {
    languageEn: Boolean
  },
  emits: ['open-menu'],
  setup () {
    const { locale } = useI18n({ useScope: 'global' });

    const router = useRouter();

    const selectNoLabel = ref(true);

    const goToPage = (name) => {
      router.push({
        name
      });
      if (name === 'Sign-up') return;
      yandexTracker.goToSignUp();
    };

    const navActive = ref('');
    const getActiveNav = () => {
      const els = document.querySelectorAll('.section');
      els.forEach((el) => {
        const { top } = el.getBoundingClientRect();
        if (top <= 250) {
          navActive.value = el.id;
        }
      });
    };

    onBeforeMount(() => {
      window.addEventListener('scroll', getActiveNav);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', getActiveNav);
    });

    watch(locale, () => {
      localStorage.setItem('lang', locale.value);
    });

    const handleClickMenu = (id) => {
      const el = document.getElementById(id);
      const { top } = el.getBoundingClientRect();
      const scaleEl = document.getElementsByClassName('app-zoom');
      const scale = scaleEl.length ? 0.8 : 1;
      scrollTo(window, top * scale + window.scrollY - 80, 500, 'clear');
    };

    return {
      selectNoLabel,
      locale,
      landingHeader,
      navActive,
      handleClickMenu,
      goToPage
    };
  }
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.04);

  &__wrapper {
    width: 100%;
    max-width: 1600px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
  }

  &__left {
    width: 100%;
    max-width: 872px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__list {
    display: flex;
    width: 100%;
    max-width: 647px;
    margin-left: 100px;
    justify-content: space-between;
  }

  &__lang {
    margin-left: 15px;

    &:hover {
      color: $color-purple-dark;
    }

    &_mr {
      margin-right: 55px !important;
    }
  }

  &__btn {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 125%;
    color: $color-white;
    background-color: $color-purple;
    box-shadow: 0 8px 10px rgba($color-purple, 0.18);
    border-radius: 4px;
    padding: 16px 38px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    user-select: none;

    &:hover {
      background-color: $color-purple-dark;
    }

    &_sign-up {
      background-color: transparent;
      box-shadow: none;
      color: $color-accent;

      &:hover {
        background-color: transparent;
        color: $color-accent;
      }
    }
  }
}

.header-icons {
  display: flex;
  width: 116px;
  justify-content: space-between;

  &__icon {
    color: $color-purple;
    width: 28px;
    height: 29px;

    &:hover {
      color: $color-purple-dark;
    }
  }
}

.header__list-item {
  list-style-type: none;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    color: $color-purple;
  }
}

.header__list-active-item {
  color: $color-purple;
}
</style>