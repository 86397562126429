import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';
import { isEmail } from '@/constants/utils/validationRegex';

const inputs = [
  {
    autofocus: true,
    required: true,
    type: 'text',
    label: 'improveForm.name',
    modelName: 'fullName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyNameSecond',
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'date',
    inputmode: 'date',
    required: true,
    label: 'crm.students.lessons.lessonDate',
    modelName: 'dates',
    modelValue: {},
    style: {
      gridColumn: '1/3'
    }
  },
  {
    type: 'timePicker',
    inputmode: 'time',
    required: true,
    label: 'crm.students.lessons.startTime',
    modelName: 'time',
    modelValue: '13:00',
    timePickerSettings: {
      hoursRange: [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      minuteRangeArr: [0,5,10,15,20,25,30,35,40,45,50,55],
      minuteInterval: 5,
      inputWidth: '100%',
      showDash: false
    },
    validError: 'errors.emptyTime',
    style: {
      gridColumn: '3/5'
    }
  },
  {
    type: 'phone',
    inputmode: 'tel',
    required: true,
    label: 'crm.students.form.phone',
    modelName: 'phone',
    modelValue: {
      number: '',
      country: ''
    },
    isValid: (val) => val && typeof val === 'object' && val.number && (val.number.length === val.placeholder.length),
    validError: 'errors.emptyPhone',
    fixed: true,
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'email',
    inputmode: 'email',
    label: 'improveForm.email',
    placeholder: 'crm.students.form.emailPlaceholder',
    modelName: 'email',
    modelValue: '',
    validError: 'errors.emptyEmail',
    isValid: (val) => !val || isEmail(val),
    emptyError: 'errors.emptyEmail',
    style: {
      gridColumn: '1/5'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('sign-up-lesson-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
