<template>
  <div
    class="lp-crm-analytics-header"
    :class="{'lp-crm-analytics-header_mobile': isMobile}"
  >
    <h1
      class="lp-page-title lp-crm-analytics-header__title"
      :class="{'lp-crm-analytics-header__title_mobile': isMobile}"
    >
      {{ $t('crm.pages.analytics') }}
    </h1>
    <DateSelect
      class="lp-crm-analytics-header__picker"
      :class="{'lp-crm-analytics-header__picker_mobile': isMobile}"
      :stringStyle="isMobile"
      v-model:modelValue="selectedDate"
    />
  </div>
</template>

<script>
import DateSelect from '@/components/Main/Inputs/DateSelect/DateSelect';
import { computed, onMounted, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'AnalyticsHeader',
  components: { DateSelect },
  emits: ['update:date'],
  setup (props, { emit }) {
    const selectedDate = ref([]);
    watch(selectedDate, (val) => {
      emit('update:date', val);
    });

    onMounted(() => {
      emit('update:date', selectedDate.value);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return { selectedDate, isMobile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-analytics-header {
  position: sticky;
  top: -35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 28px 30px;
  background-color: $color-white;
  z-index: 2;

  &_mobile {
    padding: 52px 16px 8px;
    top: 0
  }

  &__title {
    &_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;
      background-color: $color-white;
      padding: 16px 56px;
    }
  }

  &__picker {
    flex-basis: 208px;

    &_mobile {
      flex-basis: auto;
    }
  }
}

</style>
