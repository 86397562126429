import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  createLessonPassForStudent ({ crmLessonPassTemplateId , crmStudentId }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post(
      `crm/lesson-pass?crmStudentId=${crmStudentId}&crmLessonPassTemplateId=${crmLessonPassTemplateId}`
    );
  },
  removeLessonPassForStudent (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(
      `crm/lesson-pass/${id}`
    );
  }
};
