import { get, isNaN } from 'lodash';

export default ({ path = [] }, { el }, config) => {
  if (config.seriesIndex === -1) return;
  if (get(config, 'config.chart.type', '') === 'line') return;
  const tooltip = el.querySelector('.apexcharts-tooltip');
  const elements = [].slice.call(path, 0);
  const [bar] = elements;
  if (!bar || !tooltip) return;
  const { width, top, left } = bar.getBoundingClientRect();
  const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();
  const offsetLeft = (Math.abs(tooltipWidth - width)) / 2;
  tooltip.style.position = 'fixed';
  tooltip.style.top = top - tooltipHeight - 16 + 'px';
  tooltip.style.left = left - (isNaN(offsetLeft) ? 0 : offsetLeft) + 'px';
};
