<template>
  <button
    class="lp-audio-player-upload-button"
    :class="{
      'lp-audio-player-upload-button_mobile': isMobile
    }"
    :disabled="uploadingFile"
    @click.stop="openModalFiles"
  >
    {{ $t(`lessonCall.${isMobile ? 'loadFileMobile' : 'loadFile'}`) }}
  </button>
  <Modal
    v-model:open="openFiles"
    :removeBtnText="$t('buttons.cancel')"
    :saveBtnText="$t('buttons.play')"
    :mobileVersion="isMobile"
    :editMode="isMobile"
    @remove="openFiles = false"
    @save="loadAudioFile"
    title="myFiles.title"
  >
    <MyFiles
      :modal="true"
      :filter="'audio'"
      @save="loadAudioFile"
      v-model:file="selectedFile"
    />
  </Modal>
</template>

<script>
import MyFiles from '@/components/CRM/Files/MyFiles';
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, inject, watch } from 'vue';
import { useStore } from 'vuex';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

export default {
  name: 'AudioPlayer',
  components: { Modal, MyFiles },
  setup (props, { emit }) {
    const openFiles = ref(false);
    const selectedFile = ref({});

    const uploadingFile = ref(false);
    watch(uploadingFile, () => {
      emit('update:loading', uploadingFile.value);
    });
    const openModalFiles = () => {
      if (uploadingFile.value) return;
      openFiles.value = true;
    };
    const audioEngine = inject('audioEngine');
    const rtcDataConnection = inject('rtcDataConnection');
    const store = useStore();
    const mp3File = computed(() => store.getters.mp3File);

    const uploaded = ref({
      local: false,
      remote: false
    });
    const studentLoadedCallback = (file) => {
      console.log('checkUploadStatus', 'localUploaded', uploaded.value);
      console.log('Student loaded mp3 callback');
      uploaded.value.remote = true;
      checkUploadStatus(file);
    };
    const studentConnectedCallback = () => {
      console.log('Student connected callback');
      if (mp3File.value.file) {
        rtcDataConnection.participant.mp3Player.loadFile(mp3File.value.file);
        uploaded.value.local = true;
        // uploadingFile.value = true;
      }
    };
    rtcDataConnection.setLoadedCallback(studentLoadedCallback);
    rtcDataConnection.setConnectedCallback(studentConnectedCallback);

    const checkUploadStatus = (file) => {
      if (rtcDataConnection.isStudentConnected()) {
        console.log('checkUploadStatus', 'localUploaded', uploaded.value);
        if (uploaded.value.remote && uploaded.value.local) {
          uploadingFile.value = false;
          mp3File.value.file = file;
          console.log('mp3 loaded with student');
        }
      } else {
        console.log('mp3 loaded without student');
        uploadingFile.value = false;
        mp3File.value.file = file;
      }
    };

    const loadAudioFile = async () => {
      // TODO загрузка аудио файла
      const file = selectedFile.value;
      const { url, _id } = file;
      if (!url || !_id) return;
      uploadingFile.value = true;
      uploaded.value.local = false;
      uploaded.value.remote = false;
      openFiles.value = false;
      try {
        let success = false;
        rtcDataConnection.participant.mp3Player.loadFile(file);
        const timeoutPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            if (!success) {
              uploadingFile.value = false;
              uploaded.value.local = false;
              mp3File.value.file = null;
            }
          }, 8000);
        });
        const loadPromise = new Promise((resolve, reject) => {
          audioEngine.mp3Player.loadFile(file);
          success = true;
        });
        Promise.race([timeoutPromise, loadPromise]);
        uploaded.value.local = true;
        checkUploadStatus(file);
      } catch (e) {
        uploadingFile.value = false;
        console.error(e);
      } finally {
        yandexTracker.fileSelect();
      }
    };

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      openFiles,
      selectedFile,
      uploadingFile,
      openModalFiles,
      loadAudioFile,
      mp3File
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-audio-player-upload-button {
  white-space: nowrap;
  font-weight: 700;
  font-size: 9px;
  line-height: 1;
  padding: 9px 14px;
  border-radius: 4px;
  background-color: $color-white;
  color: $color-outer-space;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: rgba($color-white, 0.8);
  }

  &_mobile {
    min-height: 42px;
    font-size: 11px;
    border-radius: 8px;
  }
}

</style>
