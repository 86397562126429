<template>
  <div
    class="stp-completed"
    :class="{'stp-completed__mobile' : isMobile}"
  >
    <span
      class="stp-completed-header"
      :class="{'stp-completed-header__mobile' : isMobile}"
    >
      {{ $t('student.completed.title') }}
    </span>
    <div class="stp-completed-table">
      <div
        class="stp-completed-table-header"
        :class="{'stp-completed-table-header__mobile' : isMobile}"
      >
        <span>{{ $t('student.completed.date') }}</span>
        <span>{{ $t('student.completed.time') }}</span>
        <span>
          {{ $t('student.completed.cost') }}
        </span>
        <span class="stp-completed-table-header_last">
          {{ $t('student.completed.currency') }}
        </span>
      </div>
      <div
        class="stp-completed-table-content"
        :class="{'stp-completed-table-content__mobile' : isMobile}"
      >
        <div
          v-for="lesson in completedLessons"
          :key="lesson"
          class="stp-completed-table-content-item"
          :class="{'stp-completed-table-content-item__mobile' : isMobile}"
        >
          <span>{{ lesson.date }}</span>
          <span>{{ lesson.timePeriod }}</span>
          <span>
            <LPTooltip
              :class="'stp-completed-table-content-item_cost__tooltip'"
              :text="$t('crm.lessons.form.trial')"
              v-if="lesson.isTrial"
            >
              <TrialIconGray />
            </LPTooltip>
            <template v-else-if="lesson.cost">
              {{ lesson.cost }}
            </template>
            <LPTooltip
              v-else
              :class="'stp-completed-table-content-item_last__tooltip'"
              :text="$t('crm.subscriptions.tooltip')"
            >
              <subscriptionsIcon />
            </LPTooltip>
          </span>
          <span class="stp-completed-table-content-item_last">{{ lesson.currency }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { subscriptionsIcon, TrialIconGray } from '@/constants/icons/crm';

export default {
  name: 'CompletedLessons',
  components: { LPTooltip,subscriptionsIcon, TrialIconGray },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    },
    lang: {
      type: String,
      required: true
    },
    completedLessons: {
      type: Array,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/_variables.scss";

.stp-completed {
  width: 100%;
  padding: 40px 5px 0 40px;

  &__mobile {
    padding: 24px 0;
  }

  &-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

    &__mobile {
      padding-left: 12px;
    }
  }

  &-table {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-header {
      width: calc(100% - 35px);
      display: grid;
      grid-template-columns: 2fr 3fr 2fr 1fr;
      background-color: $color-moon-raker;
      color: $color-purple;
      align-items: center;
      font-size: 13px;
      font-weight: 700;
      padding: 12px 24px;

      &__mobile {
        width: 100%;
        padding: 12px;
        grid-template-columns: 5fr 5.5fr 3fr 4fr;
      }

      &_last {
        text-align: right;
      }
    }

    &-content {
      width: 100%;
      overflow: scroll;
      padding-right: 30px;
      max-height: 310px;

      &__mobile {
        padding-right: unset;
      }

      &-item {
        width: 100%;
        display: grid;
        padding: 28px 24px;
        grid-template-columns: 2fr 3fr 2fr 1fr;
        align-items: center;
        border-bottom: 1px solid $color-alto;

        &__mobile {
          padding: 28px 12px;
          grid-template-columns: 5fr 5.5fr 3fr 4fr;
        }

        &:last-child {
          border-bottom: unset;
        }

        &_last {
          text-align: right;

          &__tooltip {
            display: inline-block;
          }
        }
      }
    }
  }
}

</style>
