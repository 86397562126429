<template>
  <teleport
    to="#app"
    :disabled="!isMobile"
  >
    <div
      class="lp-webcam-settings-popup"
      :class="{
        'lp-webcam-settings-popup_preview': preview,
        'lp-webcam-settings-popup_mobile': mobile,
        'lp-webcam-settings-popup_mobile_preview': mobile && preview
      }"
    >
      <div
        class="lp-webcam-settings-popup-header"
      >
        <span>{{ $t('roomSettings.video.title') }}</span>
        <span
          class="lp-webcam-settings-popup-header__close"
          v-if="mobile"
          @click="$emit('update:open', false)"
        >
          <closeModalIcon />
        </span>
      </div>
      <teleport
        to="#app"
        v-if="isMobile"
      >
        <div
          class="lp-webcam-settings-popup__background"
          @click="showResolution = false, showDevices = false"
          v-if="showResolution || showDevices"
        />
      </teleport>
      <teleport
        to="#app"
        :disabled="!isMobile"
      >
        <transition name="bounce">
          <WebcamResolution
            v-model:open="showResolution"
            v-model:resolution="qualityVideo"
            :isMobile="isMobile"
            v-if="showResolution"
          />
        </transition>
      </teleport>
      <teleport
        to="#app"
        :disabled="!isMobile"
      >
        <transition name="bounce">
          <WebcamDevices
            v-model:open="showDevices"
            v-model:device="currentCamera"
            :devices="videoDevices"
            :isMobile="isMobile"
            v-if="showDevices"
          />
        </transition>
      </teleport>
      <div
        class="lp-webcam-settings-popup-body"
        :class="{'lp-webcam-settings-popup-body_mobile': isMobile}"
      >
        <!-- device video -->
        <!--
      <div
        class="lp-webcam-settings-popup-body-item"
        @click="showDevices = !showDevices, showResolution = false"
      >
        <span class="lp-webcam-settings-popup-body-item__title">
          {{ $t('roomSettings.video.camera') }}
        </span>
        <div
          class="lp-webcam-settings-popup-body-item__menu"
          v-if="currentCamera"
        >
          <div class="lp-webcam-settings-popup-body-item__value">
            {{ currentCamera.label }}
          </div>
          <arrowLeftIcon class="lp-webcam-settings-popup-body-item__icon" />
        </div>
      </div>
      -->
        <!-- mirror video -->
        <div
          class="lp-webcam-settings-popup-body-item"
          @click="setMirrorVideo"
        >
          <span class="lp-webcam-settings-popup-body-item__title">
            {{ $t('roomSettings.video.mirror') }}
          </span>
          <div class="lp-webcam-settings-popup-body-item__toggle">
            <CustomToggle
              v-model:value="mirrorVideo"
              :value="mirrorVideo"
              theme="dark"
            />
          </div>
        </div>
      <!-- quality video -->
      <!--
      <div
        class="lp-webcam-settings-popup-body-item"
        @click="showResolution = !showResolution, showDevices = false"
      >
        <span class="lp-webcam-settings-popup-body-item__title">
          {{ $t('roomSettings.video.quality') }}
        </span>
        <div class="lp-webcam-settings-popup-body-item__menu">
          <div
            class="lp-webcam-settings-popup-body-item__value"
            :class="{'lp-webcam-settings-popup-body-item__value_hd': +qualityVideoTitle >= 1080}"
          >
            {{ qualityVideoTitle }}
          </div>
          <arrowLeftIcon class="lp-webcam-settings-popup-body-item__icon" />
        </div>
      </div>
      -->
      </div>
    </div>
  </teleport>
</template>

<script>
import CustomToggle from '@/components/Main/Inputs/CustomToggle';
import { computed, onBeforeMount, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { isEqual, get } from 'lodash';
import WebcamResolution from '@/components/VideoCall/VideoCallSettings/WebcamSettings/WebcamResolution';
import RESOLUTION from '@/constants/enums/resolution';
import { arrowLeftIcon, closeModalIcon } from '@/constants/icons';
import WebcamDevices from '@/components/VideoCall/VideoCallSettings/WebcamSettings/WebcamDevices';
import SOCKET_EVENTS from '@/constants/enums/events';
import { emitRtcEvent } from '@/constants/_rtc/utils/helpers';

export default {
  name: 'WebcamSettingsPopup',
  components: { WebcamDevices, WebcamResolution, CustomToggle, closeModalIcon },
  props: {
    preview: Boolean,
    mobile: Boolean,
    open: Boolean
  },
  emits: ['update:open'],
  setup (props) {
    const store = useStore();
    const videoSettings = computed(() => store.getters.videoSettings);
    const connection = computed(() => store.getters.rtcConnect);

    // for device
    const showDevices = ref(false);
    watch(props, () => {
      if (!props.open) {
        showDevices.value = false;
        showResolution.value = false;
      }
    });

    const videoDevices = ref([]);
    const currentCamera = ref(null);
    const setDefaultCamera = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      videoDevices.value = devices.filter(d => d.kind === 'videoinput');
      currentCamera.value = videoDevices.value[0];
    };
    onBeforeMount(() => {
      navigator.mediaDevices.addEventListener('devicechange', setDefaultCamera);
      setDefaultCamera();
    });
    const setDevice = () => {
      if (!currentCamera.value) return;
      // TODO выбор камеры (изменить устройство)
      videoSettings.value.deviceId = currentCamera.value.deviceId;
    };
    watchEffect(setDevice);

    // for resolution
    const showResolution = ref(false);
    const qualityVideo = ref(videoSettings.value.resolution);
    const qualityVideoTitle = computed(() => {
      return Object.keys(RESOLUTION).find(key => isEqual(RESOLUTION[key], qualityVideo.value));
    });
    const setResolution = () => {
      videoSettings.value.resolution = qualityVideo.value;
      // TODO установка качества изображения видео
    };
    watchEffect(setResolution);

    // for mirror
    const mirrorVideo = ref(videoSettings.value.mirror);
    const setMirrorVideo = () => {
      mirrorVideo.value = !mirrorVideo.value;
      videoSettings.value.mirror = mirrorVideo.value;
      const event = SOCKET_EVENTS.VIDEO_MIRROR;
      if (!get(connection.value, 'socket.emit', false)) return;
      emitRtcEvent({
        store,
        event,
        value: videoSettings.value.mirror
      });
    };

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      showResolution,
      setMirrorVideo,
      mirrorVideo,
      qualityVideo,
      qualityVideoTitle,
      currentCamera,
      showDevices,
      videoDevices
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-webcam-settings-popup {
  position: absolute;
  top: 52px;
  right: 0;
  background: $color-shark;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba($color-black, 0.05);
  border-radius: 4px;
  color: $color-white;
  min-width: 240px;
  z-index: 6;

  &_preview {
    top: auto;
    bottom: 53px;
  }

  &_mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
    overflow: auto;
    z-index: 199;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 16px 16px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    user-select: none;
    letter-spacing: 0.07em;

    &__close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
    }
  }

  &-body {
    display: grid;
    grid-gap: 8px;
    padding-bottom: 16px;
    white-space: nowrap;

    &_mobile {
      grid-gap: 16px;
    }

    &-item {
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr auto;
      justify-content: space-between;
      align-items: center;
      padding: 9px 16px;
      border-radius: 3px;
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      color: $color-white;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      user-select: none;

      &__toggle {
        pointer-events: none;
      }

      &__menu {
        display: flex;
        align-items: center;
      }

      &__value {
        &_hd {
          position: relative;
          padding-right: 11px;

          &:after {
            content: 'HD';
            position: absolute;
            top: 0;
            right: auto;
            color: $color-red;
            font-weight: 500;
            font-size: 8px;
            line-height: 125%;

          }
        }
      }

      &__icon {
        margin-left: 6px;
      }
    }
  }

  &__background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
  }
}

</style>
