<template>
  <div
    class="lp-toolbar-confirm-lesson"
    :class="{
      'lp-toolbar-confirm-lesson_mobile': buttons,
      'lp-toolbar-confirm-lesson_icon': !buttons
    }"
    ref="confirmIconEl"
    v-if="!isCompleted"
    @click.stop="openConfirmLessonDropDown = !openConfirmLessonDropDown"
    v-click-outside="closeConfirmLessonDropDown"
    @mouseenter.stop="handlerConfirmLessonHover(true)"
    @mouseleave.stop="handlerConfirmLessonHover(false)"
  >
    <checkmarkIcon />
    <template v-if="buttons">
      {{ $t('crm.lessons.tooltips.confirm') }}
    </template>
    <downIcon v-if="!buttons" />
    <Tooltip
      class="lp-toolbar-confirm-lesson__tooltip"
      :text="'crm.lessons.tooltips.confirm'"
      :rightPosition="true"
      :open="openTooltip && !openConfirmLesson"
    />
    <teleport
      to="#app"
      v-if="mobile && openConfirmLessonDropDown"
    >
      <div class="lp-toolbar-confirm-lesson-dropdown__background" />
    </teleport>
    <teleport
      to="#app"
      :disabled="!mobile"
    >
      <transition :name="mobile ? 'slide-select-mobile' : 'bounce'">
        <div
          ref="dropDownEl"
          class="lp-toolbar-confirm-lesson-dropdown"
          :class="{
            'lp-toolbar-confirm-lesson-dropdown_mobile': mobile
          }"
          v-if="openConfirmLessonDropDown"
          :style="checkedPosition"
        >
          <div
            class="lp-toolbar-confirm-lesson-dropdown__item"
            :class="{'lp-toolbar-confirm-lesson-dropdown__item_mobile': isMobile}"
            @click="completeLesson(false)"
          >
            {{ $t('crm.lessons.confirmLessonDropDown.confirm') }}
          </div>
          <div
            class="lp-toolbar-confirm-lesson-dropdown__item"
            :class="{'lp-toolbar-confirm-lesson-dropdown__item_mobile': isMobile}"
            @click="completeLesson(true)"
          >
            {{ $t('crm.lessons.confirmLessonDropDown.studentReason') }}
          </div>
        </div>
      </transition>
    </teleport>
    <teleport
      to="#app"
      :disabled="false"
    >
      <transition :name="mobile ? 'fade' : 'bounce'">
        <ModalQuestion
          :class="{
            'lp-toolbar-confirm-lesson__modal-confirm': !mobile
          }"
          v-if="openConfirmLesson"
          :position="position"
          :mobile="mobile"
          :question="confirmText"
          @accept="acceptConfirm"
          v-model:open="openConfirmLesson"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { checkmarkIcon, downIcon } from '@/constants/icons/crm';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import { computed, ref, watch } from 'vue';
import { get } from 'lodash';
import LessonsApi from '@/api/CRM/lessons';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ConfirmLesson',
  components: {
    Tooltip,
    ModalQuestion,
    checkmarkIcon,
    downIcon
  },
  props: {
    buttons: Boolean,
    mobile: Boolean,
    hover: String,
    lesson: Object,
    mode: String
  },
  setup (props, { emit }) {
    const { t } = useI18n();

    const isFuture = computed(() => {
      const date = get(props.lesson, 'crmLessonEnd', '');
      return moment(date).isAfter(moment());
    });
    const isCompleted = computed(() => get(props.lesson, 'crmLessonStatus', '') === 'Complete');

    const openConfirmLessonDropDown = ref(false);
    const closeConfirmLessonDropDown = () => {
      openConfirmLessonDropDown.value = false;
    };

    watch(() => props.hover, () => {
      closeConfirmLessonDropDown();
    });

    const dropDownEl = ref(null);
    const checkedPosition = computed(() => {
      if (props.mobile || !confirmIconEl.value || !dropDownEl.value) return {};
      const {
        offsetHeight,
        offsetWidth
      } = dropDownEl.value;
      const {
        left,
        bottom,
        right
      } = confirmIconEl.value.getBoundingClientRect();
      const top = bottom + 6;
      const {
        innerWidth,
        innerHeight
      } = window;
      const style = {
        top: `${top}px`,
        left: `${left}px`
      };
      if (innerWidth < (left + offsetWidth)) {
        style.left = `${right - offsetWidth}px`;
      }
      if (innerHeight < (top + offsetHeight)) {
        style.top = `${top - offsetHeight}px`;
      }
      return style;
    });

    const openConfirmLesson = ref(false);
    const confirmText = computed(() => {
      if (props.lesson.crmStudents.length === 1) {
        const lesson = props.lesson.crmLessons[0];
        const confirmLessonText =
            lesson.finishedByStudentReason
              ? 'calendar.lesson.confirmLessonByStudentReason'
              : 'calendar.lesson.confirmLesson';
        return `${t(confirmLessonText)} ${lesson.crmStudentFullName}?`;
      } else {
        return `${t('calendar.lesson.confirmGroupLesson')}?`;
      }
    });
    watch(openConfirmLesson, () => openTooltip.value = false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());
    watch(openConfirmLessonDropDown, (val) => {
      openTooltip.value = false;
      const el = document.querySelector('.mc-container');
      if (!el || !isMobile.value) return;
      el.style.overflow = val ? 'hidden' : 'visible';
      el.style.pointerEvents = val ? 'none' : 'auto';
    });

    const finishedByStudentReason = ref(false);
    const completeLesson = (value) => {
      finishedByStudentReason.value = value;
      positionIcon.value = confirmIconEl.value ? confirmIconEl.value.getBoundingClientRect() : {};
      openConfirmLesson.value = true;
    };

    const acceptConfirm = async () => {
      const lesson = {
        ...props.lesson,
        finishedByStudentReason: finishedByStudentReason.value
      };
      lesson.crmLessonStatus = 'Complete';
      try {
        await LessonsApi.updateGroupLesson(lesson);
        openConfirmLesson.value  = false;
        yandexTracker.endLesson();
        emit('get-lessons');
        emit('close-calendar-card');
      } catch (err) {
        console.error(err);
      }
    };

    const openTooltip = ref(false);
    const handlerConfirmLessonHover = (value) => {
      openTooltip.value = openConfirmLessonDropDown.value ? false : value;
    };
    const confirmIconEl = ref(null);
    const positionIcon = ref( {} );
    const position = computed(() => positionIcon.value);

    return {
      isMobile,
      isCompleted,
      isFuture,
      openConfirmLessonDropDown,
      closeConfirmLessonDropDown,
      completeLesson,
      openConfirmLesson,
      confirmText,
      acceptConfirm,
      openTooltip,
      confirmIconEl,
      position,
      handlerConfirmLessonHover,
      dropDownEl,
      checkedPosition
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-toolbar-confirm-lesson {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &_icon {
    width: 36px;
    height: 28px;
    padding: 0 8px;
    background-color: $color-moon-raker;
    border-radius: 4px;
  }

  &_mobile {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 12px auto;
    grid-gap: 4px;
    align-items: center;
    justify-content: center;
    padding: 13px 18px;
    border: 1px solid $color-accent;
    box-sizing: border-box;
    border-radius: 4px;
    text-transform: uppercase;
    color: $color-accent;
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
  }

  svg {
    transition: transform 0.2s ease-in;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }

  &__modal-confirm {
    top: 0;
    min-width: 193px;
    max-width: 193px;
  }

  &__tooltip {
    top: 38px;
    right: -14px;
    left: auto;
    white-space: nowrap;
  }
}

.lp-toolbar-confirm-lesson-dropdown {
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 226px;
  display: grid;
  grid-gap: 8px;
  background: $color-white;
  border: 1px solid $color-wild-sand;
  padding: 8px;
  box-shadow: 0 0 15px rgba($color-black, 0.05);
  border-radius: 4px;

  &__background {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
  }

  &_mobile {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    z-index: 101;
    border-radius: 0;
  }

  &__item {
    padding: 8px 16px;
    border-radius: 3px;
    transition: color .2s ease, background-color .2s ease;

    &:hover {
      background: $color-moon-raker;
      color: $color-accent;
    }

    &_mobile {
      padding: 12px 16px;
    }
  }

}
</style>
