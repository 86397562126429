import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';
import { get } from 'lodash';

const axios = require('axios');

export default {
  updateUser (user) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const roleUrl = `${user.role.toLowerCase()}s`;
    const file = get(user, 'uploadFile._id', null);
    if (typeof user.uploadFile === 'object') user.uploadFile = file;
    return instCred.patch(`users/${roleUrl}/${user._id}`, user);
  },
  updateTeachersAccountStatus (status) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post(`users/teachers/accountstatus?accountStatus=${status}` );
  },
  getUserById (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get(`users/teachers/${id}`);
  }
};
