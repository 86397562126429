/* eslint-disable max-len*/
import { createRouter, createWebHistory } from 'vue-router';
import Landing from '@/components/Landing/Landing';
// const Landing = () => import(/* webpackChunkName: "Landing" */'@/components/Landing/Landing');
// const LandingOld = () => import('@/components/LandingOld/LandingOld');
// const LoginPage = () => import(/* webpackChunkName: "LoginPage" */'@/components/LoginPage/LoginPage');
// const RegistrationForm = () => import(/* webpackChunkName: "RegistrationForm" */'@/components/LoginPage/RegistrationForm');
// const ForgotPasswordForm = () => import(/* webpackChunkName: "ForgotPasswordForm" */'@/components/LoginPage/ForgotPasswordForm');
// const MyFiles = () => import(/* webpackChunkName: "Files" */'@/components/CRM/Files/MyFiles');
// const CRM = () => import(/* webpackChunkName: "CRM" */'@/components/CRM/CRM');
// const Lessons = () => import(/* webpackChunkName: "Lessons" */'@/components/CRM/Lessons/Lessons');
// const Widget = () => import(/* webpackChunkName: "Widget" */'@/components/CRM/Lessons/TimetableWidget/Widget');
// const Finance = () => import(/* webpackChunkName: "Finance" */'@/components/CRM/Finance/Finance');
// const Expenses = () => import(/* webpackChunkName: "Expenses" */'@/components/CRM/Expenses/Expenses');
// const Analytics = () => import(/* webpackChunkName: "Analytics" */'@/components/CRM/Analytics/Analytics');
// const Settings = () => import(/* webpackChunkName: "Settings" */'@/components/CRM/Settings/Settings');
// const Students = () => import(/* webpackChunkName: "Students" */'@/components/CRM/Students/Students');
// const Discounts = () => import(/* webpackChunkName: "Discounts" */'@/components/CRM/Discounts/Discounts');
// const StudentProfile = () => import(/* webpackChunkName: "StudentAccount" */'@/components/StudentProfile/StudentProfile');
// const Subscriptions = () => import(/* webpackChunkName: "Subscriptions" */ '@/components/CRM/Subscriptions/Subscriptions');
// const Accompaniment = () => import(/* webpackChunkName: "Accompaniment" */'@/components/CRM/Accompaniment/Accompaniment');
// const StripePaymentForm = () => import(/* webpackChunkName: "StripePaymentForm" */'@/components/StripePayment/StripePaymentForm');
// const VideoRoom = () => import(/* webpackChunkName: "VideoCall" */'@/components/VideoCall/VideoCallPage');
// const DelaySetting = () => import(/* webpackChunkName: "DelaySetting" */'@/components/VideoCall/VideoCallTools/DelaySetting/DelaySetting');

import LoginPage from '@/components/LoginPage/LoginPage';
import RegistrationForm from '@/components/LoginPage/RegistrationForm';
import ForgotPasswordForm from '@/components/LoginPage/ForgotPasswordForm';
import MyFiles from '@/components/CRM/Files/MyFiles';
import CRM from '@/components/CRM/CRM';
import Lessons from '@/components/CRM/Lessons/Lessons';
import Widget from '@/components/CRM/Lessons/TimetableWidget/Widget';
import Finance from '@/components/CRM/Finance/Finance';
import Expenses from '@/components/CRM/Expenses/Expenses';
import Analytics from '@/components/CRM/Analytics/Analytics';
import Settings from '@/components/CRM/Settings/Settings';
import Students from '@/components/CRM/Students/Students';
import Discounts from '@/components/CRM/Discounts/Discounts';
import Subscriptions from '@/components/CRM/Subscriptions/Subscriptions';
import Accompaniment from '@/components/CRM/Accompaniment/Accompaniment';
import StripePaymentForm from '@/components/StripePayment/StripePaymentForm';
import VideoRoom from '@/components/VideoCall/VideoCallPage';
import DelayPage from '@/components/VideoCall/VideCallDelayPage/DelayPage';
import StudentProfile from '@/components/StudentProfile/StudentProfile';

export default {
  createRouters (i18n) {
    const routes = [
      {
        path: '/',
        name: 'Landing',
        component: Landing,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.landing'),
          metaTags: [
            {
              name: 'description',
              content: i18n.global.t('descriptionPages.landing')
            },
            {
              name: 'keywords',
              content: i18n.global.t('keywordsPages.landing')
            }
          ]
        }
      },
      {
        path: '/invoice/',
        name: 'Payment',
        component: StripePaymentForm,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.pay')
        }
      },
      {
        path: '/main',
        name: 'Main',
        component: CRM,
        meta: {
          title: i18n.global.t('titlePages.main')
        }
      },
      {
        path: '/:id',
        name: 'Widget',
        component: Widget,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.widget')
        }
      },
      {
        path: '/student/:id',
        name: 'StudentAccount',
        component: StudentProfile,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.student')
        }
      },
      {
        path: '/crm',
        name: 'CRM',
        component: CRM,
        meta: {
          title: i18n.global.t('titlePages.crm')
        },
        children: [
          {
            path: 'lessons/:unit',
            name: 'CRMLessons',
            component: Lessons,
            meta: {
              title: i18n.global.t('titlePages.timetable')
            }
          },
          {
            path: 'students',
            name: 'CRMStudents',
            component: Students,
            meta: {
              title: i18n.global.t('titlePages.students')
            }
          },
          {
            path: 'finance',
            name: 'CRMFinance',
            component: Finance,
            meta: {
              title: i18n.global.t('titlePages.finance')
            }
          },
          {
            path: 'analytics',
            name: 'CRMAnalytics',
            component: Analytics,
            meta: {
              title: i18n.global.t('titlePages.analytics')
            }
          },
          {
            path: 'settings',
            name: 'CRMSettings',
            component: Settings,
            meta: {
              title: i18n.global.t('titlePages.settings')
            }
          },
          {
            path: '/files/:folderId',
            name: 'MyFiles',
            component: MyFiles,
            meta: {
              title: i18n.global.t('titlePages.files')
            }
          },
          {
            path: '/files/',
            redirect: { name: 'MyFiles', params: { folderId: 'home' } }
          },
          {
            path: 'subscriptions',
            name: 'CRMSubscriptions',
            component: Subscriptions,
            meta: {
              title: i18n.global.t('titlePages.subscriptions')
            }
          },
          {
            path: 'accompaniment',
            name: 'CRMAccompaniment',
            component: Accompaniment,
            meta: {
              title: i18n.global.t('titlePages.accompaniment')
            }
          },
          {
            path: 'discounts',
            name: 'CRMDiscounts',
            component: Discounts,
            meta: {
              title: i18n.global.t('titlePages.discounts')
            }
          },
          {
            path: 'expenses',
            name: 'CRMExpenses',
            component: Expenses,
            meta: {
              title: i18n.global.t('titlePages.expenses')
            }
          }
        ]
      },
      {
        path: '/login/',
        name: 'Login',
        component: LoginPage,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.login')
        }
      },
      {
        path: '/sign-up/',
        name: 'Sign-up',
        component: RegistrationForm,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.signUp')
        }
      },
      {
        path: '/forgot-password/',
        name: 'ForgotPassword',
        component: ForgotPasswordForm,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.forgotPassword')
        }
      },
      {
        path: '/room/:roomId',
        name: 'VideoRoom',
        component: VideoRoom,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.call')
        }
      },
      {
        path: '/room-calibration',
        name: 'VideoRoomCalibration',
        component: DelayPage,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.call')
        }
      },
      {
        path: '/:catchAll(.*)',
        component: LoginPage,
        meta: {
          noRedirect: true,
          title: i18n.global.t('titlePages.login')
        }
      }
    ];

    const router = createRouter({
      history: createWebHistory(),
      routes
    });

    router.beforeEach((to, from, next) => {
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
      const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
      if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
      if (!nearestWithMeta) return next();
      nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
      }).forEach(tag => document.head.appendChild(tag));
      next();
    });
    return router;
  }
};
