<template>
  <label
    class="lp-toggle"
    :class="{
      [`lp-toggle_${theme}`]: theme
    }"
  >
    <input
      class="lp-toggle__input"
      type="checkbox"
      v-model="defaultValue"
      @change="setValue"
    >
    <span class="lp-toggle__slider"></span>
  </label>
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue';

export default {
  name: 'CustomToggle',
  props: {
    value: Boolean,
    theme: String
  },
  emits: ['update:value'],
  setup (props, { emit }) {
    const defaultValue = ref(false);
    watch(() => props.value, (newVal) => defaultValue.value = newVal);

    const setValue = ({ target }) => {
      emit('update:value', target.checked);
    };

    onBeforeMount(() => defaultValue.value = props.value);

    return {
      defaultValue,
      setValue
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-toggle {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span {
      background-color: $color-accent;
    }

    &:checked + span:before {
      transform: translateX(12px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-gray;
    transition: .4s;
    border-radius: 11px;

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 3px;
      bottom: 2px;
      border-radius: 50%;
      background-color: $color-white;
      transition: .4s;
    }
  }

  &_dark &__input {
    &:checked + span {
      background-color: $color-outer-space;
    }
  }

  &_dark &__slider {
    background-color: $color-dove-gray-dark;
  }
}

</style>
