import STUDENT_STATUS from '@/constants/enums/studentStatus';

export default [
  {
    id: STUDENT_STATUS.ACTIVE,
    title: 'crm.students.status.active',
    style: {
      backgroundColor: '#48D16E',
      color: '#FFFFFF'
    }
  },
  {
    id: STUDENT_STATUS.NOT_PAID,
    title: 'crm.students.status.notPaid',
    style: {
      backgroundColor: '#FF0000',
      color: '#FFFFFF'
    }
  },
  {
    id: STUDENT_STATUS.INACTIVE,
    title: 'crm.students.status.notActive',
    style: {
      backgroundColor: '#858585',
      color: '#FFFFFF'
    }
  }
];
