import afghanistan from '@/assets/images/countryFlags/afghanistan.png';
import alandIslands from '@/assets/images/countryFlags/aland-islands.png';
import albania from '@/assets/images/countryFlags/albania.png';
import algeria from '@/assets/images/countryFlags/algeria.png';
import americanSamoa from '@/assets/images/countryFlags/american-samoa.png';
import andorra from '@/assets/images/countryFlags/andorra.png';
import angola from '@/assets/images/countryFlags/angola.png';
import antiguaAndBarbuda from '@/assets/images/countryFlags/antigua-and-barbuda.png';
import argentina from '@/assets/images/countryFlags/argentina.png';
import armenia from '@/assets/images/countryFlags/armenia.png';
import aruba from '@/assets/images/countryFlags/aruba.png';
import australia from '@/assets/images/countryFlags/australia.png';
import austria from '@/assets/images/countryFlags/austria.png';
import azerbaijan from '@/assets/images/countryFlags/azerbaijan.png';
import bahamas from '@/assets/images/countryFlags/bahamas.png';
import bahrain from '@/assets/images/countryFlags/bahrain.png';
import bangladesh from '@/assets/images/countryFlags/bangladesh.png';
import barbados from '@/assets/images/countryFlags/barbados.png';
import belarus from '@/assets/images/countryFlags/belarus.png';
import belgium from '@/assets/images/countryFlags/belgium.png';
import belize from '@/assets/images/countryFlags/belize.png';
import benin from '@/assets/images/countryFlags/benin.png';
import bermuda from '@/assets/images/countryFlags/bermuda.png';
import bhutan from '@/assets/images/countryFlags/bhutan.png';
import bolivia from '@/assets/images/countryFlags/bolivia.png';
import bosniaAndHerzegovina from '@/assets/images/countryFlags/bosnia-and-herzegovina.png';
import botswana from '@/assets/images/countryFlags/botswana.png';
import brazil from '@/assets/images/countryFlags/brazil.png';
import bulgaria from '@/assets/images/countryFlags/bulgaria.png';
import burkinaFaso from '@/assets/images/countryFlags/burkina-faso.png';
import burundi from '@/assets/images/countryFlags/burundi.png';
import cambodia from '@/assets/images/countryFlags/cambodia.png';
import cameroon from '@/assets/images/countryFlags/cameroon.png';
import canada from '@/assets/images/countryFlags/canada.png';
import chad from '@/assets/images/countryFlags/chad.png';
import chile from '@/assets/images/countryFlags/chile.png';
import china from '@/assets/images/countryFlags/china.png';
import colombia from '@/assets/images/countryFlags/colombia.png';
import costaRica from '@/assets/images/countryFlags/costa-rica.png';
import croatia from '@/assets/images/countryFlags/croatia.png';
import cuba from '@/assets/images/countryFlags/cuba.png';
import curacao from '@/assets/images/countryFlags/curacao.png';
import cyprus from '@/assets/images/countryFlags/cyprus.png';
import czechRepublic from '@/assets/images/countryFlags/czech-republic.png';
import democraticRepublicOfCongo from '@/assets/images/countryFlags/democratic-republic-of-congo.png';
import denmark from '@/assets/images/countryFlags/denmark.png';
import djibouti from '@/assets/images/countryFlags/djibouti.png';
import dominica from '@/assets/images/countryFlags/dominica.png';
import dominicanRepublic from '@/assets/images/countryFlags/dominican-republic.png';
import eastTimor from '@/assets/images/countryFlags/east-timor.png';
import ecuador from '@/assets/images/countryFlags/ecuador.png';
import egypt from '@/assets/images/countryFlags/egypt.png';
import equatorialGuinea from '@/assets/images/countryFlags/equatorial-guinea.png';
import eritrea from '@/assets/images/countryFlags/eritrea.png';
import estonia from '@/assets/images/countryFlags/estonia.png';
import ethiopia from '@/assets/images/countryFlags/ethiopia.png';
import fiji from '@/assets/images/countryFlags/fiji.png';
import finland from '@/assets/images/countryFlags/finland.png';
import france from '@/assets/images/countryFlags/france.png';
import gabon from '@/assets/images/countryFlags/gabon.png';
import gambia from '@/assets/images/countryFlags/gambia.png';
import georgia from '@/assets/images/countryFlags/georgia.png';
import germany from '@/assets/images/countryFlags/germany.png';
import ghana from '@/assets/images/countryFlags/ghana.png';
import greece from '@/assets/images/countryFlags/greece.png';
import greenland from '@/assets/images/countryFlags/greenland.png';
import grenada from '@/assets/images/countryFlags/grenada.png';
import guam from '@/assets/images/countryFlags/guam.png';
import guatemala from '@/assets/images/countryFlags/guatemala.png';
import guernsey from '@/assets/images/countryFlags/guernsey.png';
import guinea from '@/assets/images/countryFlags/guinea.png';
import guineaBissau from '@/assets/images/countryFlags/guinea-bissau.png';
import guyana from '@/assets/images/countryFlags/guyana.png';
import haiti from '@/assets/images/countryFlags/haiti.png';
import honduras from '@/assets/images/countryFlags/honduras.png';
import hungary from '@/assets/images/countryFlags/hungary.png';
import iceland from '@/assets/images/countryFlags/iceland.png';
import india from '@/assets/images/countryFlags/india.png';
import indonesia from '@/assets/images/countryFlags/indonesia.png';
import iran from '@/assets/images/countryFlags/iran.png';
import iraq from '@/assets/images/countryFlags/iraq.png';
import ireland from '@/assets/images/countryFlags/ireland.png';
import isleOfMan from '@/assets/images/countryFlags/isle-of-man.png';
import israel from '@/assets/images/countryFlags/israel.png';
import italy from '@/assets/images/countryFlags/italy.png';
import ivoryCoast from '@/assets/images/countryFlags/ivory-coast.png';
import jamaica from '@/assets/images/countryFlags/jamaica.png';
import japan from '@/assets/images/countryFlags/japan.png';
import jordan from '@/assets/images/countryFlags/jordan.png';
import kazakhstan from '@/assets/images/countryFlags/kazakhstan.png';
import kenya from '@/assets/images/countryFlags/kenya.png';
import kiribati from '@/assets/images/countryFlags/kiribati.png';
import kosovo from '@/assets/images/countryFlags/kosovo.png';
import kuwait from '@/assets/images/countryFlags/kuwait.png';
import kyrgyzstan from '@/assets/images/countryFlags/kyrgyzstan.png';
import laos from '@/assets/images/countryFlags/laos.png';
import latvia from '@/assets/images/countryFlags/latvia.png';
import lebanon from '@/assets/images/countryFlags/lebanon.png';
import lesotho from '@/assets/images/countryFlags/lesotho.png';
import liberia from '@/assets/images/countryFlags/liberia.png';
import libya from '@/assets/images/countryFlags/libya.png';
import liechtenstein from '@/assets/images/countryFlags/liechtenstein.png';
import lithuania from '@/assets/images/countryFlags/lithuania.png';
import luxembourg from '@/assets/images/countryFlags/luxembourg.png';
import madagascar from '@/assets/images/countryFlags/madagascar.png';
import malawi from '@/assets/images/countryFlags/malawi.png';
import malaysia from '@/assets/images/countryFlags/malaysia.png';
import maldives from '@/assets/images/countryFlags/maldives.png';
import mali from '@/assets/images/countryFlags/mali.png';
import malta from '@/assets/images/countryFlags/malta.png';
import marshallIsland from '@/assets/images/countryFlags/marshall-island.png';
import mauritania from '@/assets/images/countryFlags/mauritania.png';
import mauritius from '@/assets/images/countryFlags/mauritius.png';
import mexico from '@/assets/images/countryFlags/mexico.png';
import micronesia from '@/assets/images/countryFlags/micronesia.png';
import moldova from '@/assets/images/countryFlags/moldova.png';
import monaco from '@/assets/images/countryFlags/monaco.png';
import mongolia from '@/assets/images/countryFlags/mongolia.png';
import montenegro from '@/assets/images/countryFlags/montenegro.png';
import morocco from '@/assets/images/countryFlags/morocco.png';
import mozambique from '@/assets/images/countryFlags/mozambique.png';
import myanmar from '@/assets/images/countryFlags/myanmar.png';
import namibia from '@/assets/images/countryFlags/namibia.png';
import nauru from '@/assets/images/countryFlags/nauru.png';
import nepal from '@/assets/images/countryFlags/nepal.png';
import netherlands from '@/assets/images/countryFlags/netherlands.png';
import newZealand from '@/assets/images/countryFlags/new-zealand.png';
import nicaragua from '@/assets/images/countryFlags/nicaragua.png';
import niger from '@/assets/images/countryFlags/niger.png';
import nigeria from '@/assets/images/countryFlags/nigeria.png';
import niue from '@/assets/images/countryFlags/niue.png';
import northKorea from '@/assets/images/countryFlags/north-korea.png';
import norway from '@/assets/images/countryFlags/norway.png';
import oman from '@/assets/images/countryFlags/oman.png';
import pakistan from '@/assets/images/countryFlags/pakistan.png';
import palau from '@/assets/images/countryFlags/palau.png';
import palestine from '@/assets/images/countryFlags/palestine.png';
import panama from '@/assets/images/countryFlags/panama.png';
import papuaNewGuinea from '@/assets/images/countryFlags/papua-new-guinea.png';
import paraguay from '@/assets/images/countryFlags/paraguay.png';
import peru from '@/assets/images/countryFlags/peru.png';
import philippines from '@/assets/images/countryFlags/philippines.png';
import portugal from '@/assets/images/countryFlags/portugal.png';
import puertoRico from '@/assets/images/countryFlags/puerto-rico.png';
import qatar from '@/assets/images/countryFlags/qatar.png';
import republicOfMacedonia from '@/assets/images/countryFlags/republic-of-macedonia.png';
import republicOfPoland from '@/assets/images/countryFlags/republic-of-poland.png';
import republicOfTheCongo from '@/assets/images/countryFlags/republic-of-the-congo.png';
import romania from '@/assets/images/countryFlags/romania.png';
import russia from '@/assets/images/countryFlags/russia.png';
import rwanda from '@/assets/images/countryFlags/rwanda.png';
import saintKittsAndNevis from '@/assets/images/countryFlags/saint-kitts-and-nevis.png';
import salvador from '@/assets/images/countryFlags/salvador.png';
import samoa from '@/assets/images/countryFlags/samoa.png';
import sanMarino from '@/assets/images/countryFlags/san-marino.png';
import saoTomeAndPrincipe from '@/assets/images/countryFlags/sao-tome-and-principe.png';
import saudiArabia from '@/assets/images/countryFlags/saudi-arabia.png';
import senegal from '@/assets/images/countryFlags/senegal.png';
import serbia from '@/assets/images/countryFlags/serbia.png';
import seychelles from '@/assets/images/countryFlags/seychelles.png';
import sierraLeone from '@/assets/images/countryFlags/sierra-leone.png';
import singapore from '@/assets/images/countryFlags/singapore.png';
import sintMaarten from '@/assets/images/countryFlags/sint-maarten.png';
import slovakia from '@/assets/images/countryFlags/slovakia.png';
import slovenia from '@/assets/images/countryFlags/slovenia.png';
import solomonIslands from '@/assets/images/countryFlags/solomon-islands.png';
import somalia from '@/assets/images/countryFlags/somalia.png';
import southAfrica from '@/assets/images/countryFlags/south-africa.png';
import southKorea from '@/assets/images/countryFlags/south-korea.png';
import southSudan from '@/assets/images/countryFlags/south-sudan.png';
import spain from '@/assets/images/countryFlags/spain.png';
import sriLanka from '@/assets/images/countryFlags/sri-lanka.png';
import stLucia from '@/assets/images/countryFlags/st-lucia.png';
import stVincentAndTheGrenadines from '@/assets/images/countryFlags/st-vincent-and-the-grenadines.png';
import sudan from '@/assets/images/countryFlags/sudan.png';
import suriname from '@/assets/images/countryFlags/suriname.png';
import swaziland from '@/assets/images/countryFlags/swaziland.png';
import sweden from '@/assets/images/countryFlags/sweden.png';
import switzerland from '@/assets/images/countryFlags/switzerland.png';
import syria from '@/assets/images/countryFlags/syria.png';
import taiwan from '@/assets/images/countryFlags/taiwan.png';
import tajikistan from '@/assets/images/countryFlags/tajikistan.png';
import tanzania from '@/assets/images/countryFlags/tanzania.png';
import thailand from '@/assets/images/countryFlags/thailand.png';
import togo from '@/assets/images/countryFlags/togo.png';
import tonga from '@/assets/images/countryFlags/tonga.png';
import trinidadAndTobago from '@/assets/images/countryFlags/trinidad-and-tobago.png';
import tunisia from '@/assets/images/countryFlags/tunisia.png';
import turkey from '@/assets/images/countryFlags/turkey.png';
import turkmenistan from '@/assets/images/countryFlags/turkmenistan.png';
import tuvalu from '@/assets/images/countryFlags/tuvalu.png';
import uganda from '@/assets/images/countryFlags/uganda.png';
import ukraine from '@/assets/images/countryFlags/ukraine.png';
import unitedArabEmirates from '@/assets/images/countryFlags/united-arab-emirates.png';
import unitedKingdom from '@/assets/images/countryFlags/united-kingdom.png';
import unitedStatesOfAmerica from '@/assets/images/countryFlags/united-states-of-america.png';
import uruguay from '@/assets/images/countryFlags/uruguay.png';
import uzbekistn from '@/assets/images/countryFlags/uzbekistn.png';
import vanuatu from '@/assets/images/countryFlags/vanuatu.png';
import vaticanCity from '@/assets/images/countryFlags/vatican-city.png';
import venezuela from '@/assets/images/countryFlags/venezuela.png';
import vietnam from '@/assets/images/countryFlags/vietnam.png';
import yemen from '@/assets/images/countryFlags/yemen.png';
import zambia from '@/assets/images/countryFlags/zambia.png';

import { sortBy } from 'lodash';
import CURRENCY from '@/constants/enums/currency';

const country = [{
  name: 'country.afghanistan',
  icon: afghanistan,
  id: 'afghanistan',
  code: 'AF',
  phone: '93',
  mask: '## ### ####'
}, {
  name: 'country.aland-islands',
  icon: alandIslands,
  id: 'alandIslands',
  code: 'AX',
  phone: '358',
  mask: '(###) ### ## ##'
}, {
  name: 'country.albania',
  icon: albania,
  id: 'albania',
  code: 'AL',
  phone: '355',
  mask: '(###) ### ###'
}, {
  name: 'country.algeria',
  icon: algeria,
  id: 'algeria',
  code: 'DZ',
  phone: '213',
  mask: '## ### ####'
}, {
  name: 'country.american-samoa',
  icon: americanSamoa,
  id: 'americanSamoa',
  code: 'AS',
  phone: '1684',
  mask: '(684) ### ####'
}, {
  name: 'country.andorra',
  icon: andorra,
  id: 'andorra',
  code: 'AD',
  phone: '376',
  mask: '### ###'
}, {
  name: 'country.angola',
  icon: angola,
  id: 'angola',
  code: 'AO',
  phone: '244',
  mask: '(###) ### ###'
}, {
  name: 'country.antigua-and-barbuda',
  icon: antiguaAndBarbuda,
  id: 'antiguaAndBarbuda',
  code: 'AG',
  phone: '1268',
  mask: '(268) ### ####'
}, {
  name: 'country.argentina',
  icon: argentina,
  id: 'argentina',
  code: 'AR',
  phone: '54',
  mask: '(###) ### ####'
}, {
  name: 'country.armenia',
  icon: armenia,
  id: 'armenia',
  code: 'AM',
  phone: '374',
  mask: '## ### ###'
}, {
  name: 'country.aruba',
  icon: aruba,
  id: 'aruba',
  code: 'AW',
  phone: '297',
  mask: '### ####'
}, {
  name: 'country.australia',
  icon: australia,
  id: 'australia',
  code: 'AU',
  phone: '61',
  mask: '# #### ####'
}, {
  name: 'country.austria',
  icon: austria,
  id: 'austria',
  code: 'AT',
  phone: '43',
  mask: '(###) ### ####'
}, {
  name: 'country.azerbaijan',
  icon: azerbaijan,
  id: 'azerbaijan',
  code: 'AZ',
  phone: '994',
  mask: '## ### ## ##'
}, {
  name: 'country.bahamas',
  icon: bahamas,
  id: 'bahamas',
  code: 'BS',
  phone: '1242',
  mask: '(242) ### ####'
}, {
  name: 'country.bahrain',
  icon: bahrain,
  id: 'bahrain',
  code: 'BH',
  phone: '973',
  mask: '#### ####'
}, {
  name: 'country.bangladesh',
  icon: bangladesh,
  id: 'bangladesh',
  code: 'BD',
  phone: '880',
  mask: '## ### ###'
}, {
  name: 'country.barbados',
  icon: barbados,
  id: 'barbados',
  code: 'BB',
  phone: '1246',
  mask: '(246) ### ####'
}, {
  name: 'country.belarus',
  icon: belarus,
  id: 'belarus',
  code: 'BY',
  phone: '375',
  mask: '(##) ### ## ##',
  currency: CURRENCY.BYN
}, {
  name: 'country.belgium',
  icon: belgium,
  id: 'belgium',
  code: 'BE',
  phone: '32',
  mask: '(###) ### ###'
}, {
  name: 'country.belize',
  icon: belize,
  id: 'belize',
  code: 'BZ',
  phone: '501',
  mask: '### ####'
}, {
  name: 'country.benin',
  icon: benin,
  id: 'benin',
  code: 'BJ',
  phone: '229',
  mask: '## ## ####'
}, {
  name: 'country.bermuda',
  icon: bermuda,
  id: 'bermuda',
  code: 'BM',
  phone: '1441',
  mask: '(441) ### ####'
}, {
  name: 'country.bhutan',
  icon: bhutan,
  id: 'bhutan',
  code: 'BT',
  phone: '975',
  mask: '17 ### ###'
}, {
  name: 'country.bolivia',
  icon: bolivia,
  id: 'bolivia',
  code: 'BO',
  phone: '591',
  mask: '# ### ####'
}, {
  name: 'country.bosnia-and-herzegovina',
  icon: bosniaAndHerzegovina,
  id: 'bosniaAndHerzegovina',
  code: 'BA',
  phone: '387',
  mask: '## ####'
}, {
  name: 'country.botswana',
  icon: botswana,
  id: 'botswana',
  code: 'BW',
  phone: '267',
  mask: '## ### ###'
}, {
  name: 'country.brazil',
  icon: brazil,
  id: 'brazil',
  code: 'BR',
  phone: '55',
  mask: '(##) #### ####'
}, {
  name: 'country.bulgaria',
  icon: bulgaria,
  id: 'bulgaria',
  code: 'BG',
  phone: '359',
  mask: '(###) ### ###'
}, {
  name: 'country.burkina-faso',
  icon: burkinaFaso,
  id: 'burkinaFaso',
  code: 'BF',
  phone: '226',
  mask: '## ## ####'
}, {
  name: 'country.burundi',
  icon: burundi,
  id: 'burundi',
  code: 'BI',
  phone: '257',
  mask: '## ## ####'
}, {
  name: 'country.cambodia',
  icon: cambodia,
  id: 'cambodia',
  code: 'KH',
  phone: '855',
  mask: '## ### ###'
}, {
  name: 'country.cameroon',
  icon: cameroon,
  id: 'cameroon',
  code: 'CM',
  phone: '237',
  mask: '#### ####'
}, {
  name: 'country.canada',
  icon: canada,
  id: 'canada',
  code: 'CA',
  phone: '1',
  mask: '(###) ### ####'
}, {
  name: 'country.chad',
  icon: chad,
  id: 'chad',
  code: 'TD',
  phone: '235',
  mask: '## ## ## ##'
}, {
  name: 'country.chile',
  icon: chile,
  id: 'chile',
  code: 'CL',
  phone: '56',
  mask: '# #### ####'
}, {
  name: 'country.china',
  icon: china,
  id: 'china',
  code: 'CN',
  phone: '86',
  mask: '(###) #### ###'
}, {
  name: 'country.colombia',
  icon: colombia,
  id: 'colombia',
  code: 'CO',
  phone: '57',
  mask: '(###) ### ####'
}, {
  name: 'country.costa-rica',
  icon: costaRica,
  id: 'costaRica',
  code: 'CR',
  phone: '506',
  mask: '#### ####'
}, {
  name: 'country.croatia',
  icon: croatia,
  id: 'croatia',
  code: 'HR',
  phone: '385',
  mask: '## ### ###'
}, {
  name: 'country.cuba',
  icon: cuba,
  id: 'cuba',
  code: 'CU',
  phone: '53',
  mask: '# ### ####'
}, {
  name: 'country.curacao',
  icon: curacao,
  id: 'curacao',
  code: 'CW',
  phone: '5999',
  mask: ''
}, {
  name: 'country.cyprus',
  icon: cyprus,
  id: 'cyprus',
  code: 'CY',
  phone: '357',
  mask: '## ### ###'
}, {
  name: 'country.czech-republic',
  icon: czechRepublic,
  id: 'czechRepublic',
  code: 'CZ',
  phone: '420',
  mask: '(###) ### ###'
}, {
  name: 'country.democratic-republic-of-congo',
  icon: democraticRepublicOfCongo,
  id: 'democraticRepublicOfCongo',
  code: 'CD',
  phone: '243',
  mask: '(###) ### ###'
}, {
  name: 'country.denmark',
  icon: denmark,
  id: 'denmark',
  code: 'DK',
  phone: '45',
  mask: '## ## ## ##'
}, {
  name: 'country.djibouti',
  icon: djibouti,
  id: 'djibouti',
  code: 'DJ',
  phone: '253',
  mask: '## ## ## ##'
}, {
  name: 'country.dominica',
  icon: dominica,
  id: 'dominica',
  code: 'DM',
  phone: '1767',
  mask: '(767) ### ####'
}, {
  name: 'country.dominican-republic',
  icon: dominicanRepublic,
  id: 'dominicanRepublic',
  code: 'DO',
  phone: '1809,1829,1849',
  mask: '(809) ### ####'
}, {
  name: 'country.east-timor',
  icon: eastTimor,
  id: 'eastTimor',
  code: 'TL',
  phone: '670',
  mask: '### ####'
}, {
  name: 'country.ecuador',
  icon: ecuador,
  id: 'ecuador',
  code: 'EC',
  phone: '593',
  mask: '# ### ####'
}, {
  name: 'country.egypt',
  icon: egypt,
  id: 'egypt',
  code: 'EG',
  phone: '20',
  mask: '(###) ### ####'
}, {
  name: 'country.equatorial-guinea',
  icon: equatorialGuinea,
  id: 'equatorialGuinea',
  code: 'GQ',
  phone: '240',
  mask: '## ### ####'
}, {
  name: 'country.eritrea',
  icon: eritrea,
  id: 'eritrea',
  code: 'ER',
  phone: '291',
  mask: '# ### ###'
}, {
  name: 'country.estonia',
  icon: estonia,
  id: 'estonia',
  code: 'EE',
  phone: '372',
  mask: '## ######'
}, {
  name: 'country.ethiopia',
  icon: ethiopia,
  id: 'ethiopia',
  code: 'ET',
  phone: '251',
  mask: '## ### ####'
}, {
  name: 'country.fiji',
  icon: fiji,
  id: 'fiji',
  code: 'FJ',
  phone: '679',
  mask: '## #####'
}, {
  name: 'country.finland',
  icon: finland,
  id: 'finland',
  code: 'FI',
  phone: '358',
  mask: '(###) ### ## ##'
}, {
  name: 'country.france',
  icon: france,
  id: 'france',
  code: 'FR',
  phone: '33',
  mask: '(###) ### ###'
}, {
  name: 'country.gabon',
  icon: gabon,
  id: 'gabon',
  code: 'GA',
  phone: '241',
  mask: '# ## ## ##'
}, {
  name: 'country.gambia',
  icon: gambia,
  id: 'gambia',
  code: 'GM',
  phone: '220',
  mask: '(###) ## ##'
}, {
  name: 'country.georgia',
  icon: georgia,
  id: 'georgia',
  code: 'GE',
  phone: '995',
  mask: '(###) ### ###'
}, {
  name: 'country.germany',
  icon: germany,
  id: 'germany',
  code: 'DE',
  phone: '49',
  mask: '### ###'
}, {
  name: 'country.ghana',
  icon: ghana,
  id: 'ghana',
  code: 'GH',
  phone: '233',
  mask: '(###) ### ###'
}, {
  name: 'country.greece',
  icon: greece,
  id: 'greece',
  code: 'GR',
  phone: '30',
  mask: '(###) ### ####'
}, {
  name: 'country.greenland',
  icon: greenland,
  id: 'greenland',
  code: 'GL',
  phone: '299',
  mask: '## ## ##'
}, {
  name: 'country.grenada',
  icon: grenada,
  id: 'grenada',
  code: 'GD',
  phone: '1473',
  mask: '(473) ### ####'
}, {
  name: 'country.guam',
  icon: guam,
  id: 'guam',
  code: 'GU',
  phone: '1671',
  mask: '(671) ### ####'
}, {
  name: 'country.guatemala',
  icon: guatemala,
  id: 'guatemala',
  code: 'GT',
  phone: '502',
  mask: '# ### ####'
}, {
  name: 'country.guernsey',
  icon: guernsey,
  id: 'guernsey',
  code: 'GG',
  phone: '44',
  mask: '## #### ####'
}, {
  name: 'country.guinea',
  icon: guinea,
  id: 'guinea',
  code: 'GN',
  phone: '224',
  mask: '## ### ###'
}, {
  name: 'country.guinea-bissau',
  icon: guineaBissau,
  id: 'guineaBissau',
  code: 'GW',
  phone: '245',
  mask: '# ######'
}, {
  name: 'country.guyana',
  icon: guyana,
  id: 'guyana',
  code: 'GY',
  phone: '592',
  mask: '### ####'
}, {
  name: 'country.haiti',
  icon: haiti,
  id: 'haiti',
  code: 'HT',
  phone: '509',
  mask: '## ## ####'
}, {
  name: 'country.honduras',
  icon: honduras,
  id: 'honduras',
  code: 'HN',
  phone: '504',
  mask: '#### ####'
}, {
  name: 'country.hungary',
  icon: hungary,
  id: 'hungary',
  code: 'HU',
  phone: '36',
  mask: '(###) ### ###'
}, {
  name: 'country.iceland',
  icon: iceland,
  id: 'iceland',
  code: 'IS',
  phone: '354',
  mask: '### ####'
}, {
  name: 'country.india',
  icon: india,
  id: 'india',
  code: 'IN',
  phone: '91',
  mask: '(####) ### ###'
}, {
  name: 'country.indonesia',
  icon: indonesia,
  id: 'indonesia',
  code: 'ID',
  phone: '62',
  mask: '## ### ##'
}, {
  name: 'country.iran',
  icon: iran,
  id: 'iran',
  code: 'IR',
  phone: '98',
  mask: '(###) ### ####'
}, {
  name: 'country.iraq',
  icon: iraq,
  id: 'iraq',
  code: 'IQ',
  phone: '964',
  mask: '(###) ### ####'
}, {
  name: 'country.ireland',
  icon: ireland,
  id: 'ireland',
  code: 'IE',
  phone: '353',
  mask: '(###) ### ###'
}, {
  name: 'country.isle-of-man',
  icon: isleOfMan,
  id: 'isleOfMan',
  code: 'IM',
  phone: '44',
  mask: '## #### ####'
}, {
  name: 'country.israel',
  icon: israel,
  id: 'israel',
  code: 'IL',
  phone: '972',
  mask: '## ######'
}, {
  name: 'country.italy',
  icon: italy,
  id: 'italy',
  code: 'IT',
  phone: '39',
  mask: '(###) #### ###'
}, {
  name: 'country.ivory-coast',
  icon: ivoryCoast,
  id: 'ivoryCoast',
  code: 'CI',
  phone: '225',
  mask: '## ### ###'
}, {
  name: 'country.jamaica',
  icon: jamaica,
  id: 'jamaica',
  code: 'JM',
  phone: '1876',
  mask: '(876) ### ####'
}, {
  name: 'country.japan',
  icon: japan,
  id: 'japan',
  code: 'JP',
  phone: '81',
  mask: '(###) ### ###'
}, {
  name: 'country.jordan',
  icon: jordan,
  id: 'jordan',
  code: 'JO',
  phone: '962',
  mask: '# #### ####'
}, {
  name: 'country.kazakhstan',
  icon: kazakhstan,
  id: 'kazakhstan',
  code: 'KZ',
  phone: '7',
  mask: '(7##) ### ## ##'
}, {
  name: 'country.kenya',
  icon: kenya,
  id: 'kenya',
  code: 'KE',
  phone: '254',
  mask: '### ######'
}, {
  name: 'country.kiribati',
  icon: kiribati,
  id: 'kiribati',
  code: 'KI',
  phone: '686',
  mask: '## ###'
}, {
  name: 'country.kosovo',
  icon: kosovo,
  id: 'kosovo',
  code: 'XK',
  phone: '377,381,383,386',
  mask: '## ### ###'
}, {
  name: 'country.kuwait',
  icon: kuwait,
  id: 'kuwait',
  code: 'KW',
  phone: '965',
  mask: '#### ####'
}, {
  name: 'country.kyrgyzstan',
  icon: kyrgyzstan,
  id: 'kyrgyzstan',
  code: 'KG',
  phone: '996',
  mask: '(###) ### ###'
}, {
  name: 'country.laos',
  icon: laos,
  id: 'laos',
  code: 'LA',
  phone: '856',
  mask: '## ### ###'
}, {
  name: 'country.latvia',
  icon: latvia,
  id: 'latvia',
  code: 'LV',
  phone: '371',
  mask: '## ### ###'
}, {
  name: 'country.lebanon',
  icon: lebanon,
  id: 'lebanon',
  code: 'LB',
  phone: '961',
  mask: '# ### ###'
}, {
  name: 'country.lesotho',
  icon: lesotho,
  id: 'lesotho',
  code: 'LS',
  phone: '266',
  mask: '# ### ####'
}, {
  name: 'country.liberia',
  icon: liberia,
  id: 'liberia',
  code: 'LR',
  phone: '231',
  mask: '## ### ###'
}, {
  name: 'country.libya',
  icon: libya,
  id: 'libya',
  code: 'LY',
  phone: '218',
  mask: '## ### ###'
}, {
  name: 'country.liechtenstein',
  icon: liechtenstein,
  id: 'liechtenstein',
  code: 'LI',
  phone: '423',
  mask: '(###) ### ####'
}, {
  name: 'country.lithuania',
  icon: lithuania,
  id: 'lithuania',
  code: 'LT',
  phone: '370',
  mask: '(###) ## ###'
}, {
  name: 'country.luxembourg',
  icon: luxembourg,
  id: 'luxembourg',
  code: 'LU',
  phone: '352',
  mask: '(###) ### ###'
}, {
  name: 'country.madagascar',
  icon: madagascar,
  id: 'madagascar',
  code: 'MG',
  phone: '261',
  mask: '## ## #####'
}, {
  name: 'country.malawi',
  icon: malawi,
  id: 'malawi',
  code: 'MW',
  phone: '265',
  mask: '1 ### ###'
}, {
  name: 'country.malaysia',
  icon: malaysia,
  id: 'malaysia',
  code: 'MY',
  phone: '60',
  mask: '# ### ###'
}, {
  name: 'country.maldives',
  icon: maldives,
  id: 'maldives',
  code: 'MV',
  phone: '960',
  mask: '### ####'
}, {
  name: 'country.mali',
  icon: mali,
  id: 'mali',
  code: 'ML',
  phone: '223',
  mask: '## ## ####'
}, {
  name: 'country.malta',
  icon: malta,
  id: 'malta',
  code: 'MT',
  phone: '356',
  mask: '#### ####'
}, {
  name: 'country.marshall-island',
  icon: marshallIsland,
  id: 'marshallIsland',
  code: 'MH',
  phone: '692',
  mask: '### ####'
}, {
  name: 'country.mauritania',
  icon: mauritania,
  id: 'mauritania',
  code: 'MR',
  phone: '222',
  mask: '## ## ####'
}, {
  name: 'country.mauritius',
  icon: mauritius,
  id: 'mauritius',
  code: 'MU',
  phone: '230',
  mask: '### ####'
}, {
  name: 'country.mexico',
  icon: mexico,
  id: 'mexico',
  code: 'MX',
  phone: '52',
  mask: '## ## ####'
}, {
  name: 'country.micronesia',
  icon: micronesia,
  id: 'micronesia',
  code: 'FM',
  phone: '691',
  mask: '### ####'
}, {
  name: 'country.moldova',
  icon: moldova,
  id: 'moldova',
  code: 'MD',
  phone: '373',
  mask: '#### ####'
}, {
  name: 'country.monaco',
  icon: monaco,
  id: 'monaco',
  code: 'MC',
  phone: '377',
  mask: '## ### ###'
}, {
  name: 'country.mongolia',
  icon: mongolia,
  id: 'mongolia',
  code: 'MN',
  phone: '976',
  mask: '## ## ####'
}, {
  name: 'country.montenegro',
  icon: montenegro,
  id: 'montenegro',
  code: 'ME',
  phone: '382',
  mask: '## ### ###'
}, {
  name: 'country.morocco',
  icon: morocco,
  id: 'morocco',
  code: 'MA',
  phone: '212',
  mask: '## #### ###'
}, {
  name: 'country.mozambique',
  icon: mozambique,
  id: 'mozambique',
  code: 'MZ',
  phone: '258',
  mask: '## ### ###'
}, {
  name: 'country.myanmar',
  icon: myanmar,
  id: 'myanmar',
  code: 'MM',
  phone: '95',
  mask: '### ###'
}, {
  name: 'country.namibia',
  icon: namibia,
  id: 'namibia',
  code: 'NA',
  phone: '264',
  mask: '## ### ####'
}, {
  name: 'country.nauru',
  icon: nauru,
  id: 'nauru',
  code: 'NR',
  phone: '674',
  mask: '### ####'
}, {
  name: 'country.nepal',
  icon: nepal,
  id: 'nepal',
  code: 'NP',
  phone: '977',
  mask: '## ### ###'
}, {
  name: 'country.netherlands',
  icon: netherlands,
  id: 'netherlands',
  code: 'NL',
  phone: '31',
  mask: '## ### ####'
}, {
  name: 'country.new-zealand',
  icon: newZealand,
  id: 'newZealand',
  code: 'NZ',
  phone: '64',
  mask: '# ### ###'
}, {
  name: 'country.nicaragua',
  icon: nicaragua,
  id: 'nicaragua',
  code: 'NI',
  phone: '505',
  mask: '#### ####'
}, {
  name: 'country.niger',
  icon: niger,
  id: 'niger',
  code: 'NE',
  phone: '227',
  mask: '## ## ####'
}, {
  name: 'country.nigeria',
  icon: nigeria,
  id: 'nigeria',
  code: 'NG',
  phone: '234',
  mask: '## ### ##'
}, {
  name: 'country.niue',
  icon: niue,
  id: 'niue',
  code: 'NU',
  phone: '683',
  mask: '####'
}, {
  name: 'country.north-korea',
  icon: northKorea,
  id: 'northKorea',
  code: 'KP',
  phone: '850',
  mask: '### ###'
}, {
  name: 'country.norway',
  icon: norway,
  id: 'norway',
  code: 'NO',
  phone: '47',
  mask: '(###) ## ###'
}, {
  name: 'country.oman',
  icon: oman,
  id: 'oman',
  code: 'OM',
  phone: '968',
  mask: '## ### ###'
}, {
  name: 'country.pakistan',
  icon: pakistan,
  id: 'pakistan',
  code: 'PK',
  phone: '92',
  mask: '(###) ### ####'
}, {
  name: 'country.palau',
  icon: palau,
  id: 'palau',
  code: 'PW',
  phone: '680',
  mask: '### ####'
}, {
  name: 'country.palestine',
  icon: palestine,
  id: 'palestine',
  code: 'PS',
  phone: '970',
  mask: '## ### ####'
}, {
  name: 'country.panama',
  icon: panama,
  id: 'panama',
  code: 'PA',
  phone: '507',
  mask: '### ####'
}, {
  name: 'country.papua-new-guinea',
  icon: papuaNewGuinea,
  id: 'papuaNewGuinea',
  code: 'PG',
  phone: '675',
  mask: '(###) ## ###'
}, {
  name: 'country.paraguay',
  icon: paraguay,
  id: 'paraguay',
  code: 'PY',
  phone: '595',
  mask: '(###) ### ###'
}, {
  name: 'country.peru',
  icon: peru,
  id: 'peru',
  code: 'PE',
  phone: '51',
  mask: '(###) ### ###'
}, {
  name: 'country.philippines',
  icon: philippines,
  id: 'philippines',
  code: 'PH',
  phone: '63',
  mask: '(###) ### ####'
}, {
  name: 'country.portugal',
  icon: portugal,
  id: 'portugal',
  code: 'PT',
  phone: '351',
  mask: '## ### ####'
}, {
  name: 'country.puerto-rico',
  icon: puertoRico,
  id: 'puertoRico',
  code: 'PR',
  phone: '1787,1939',
  mask: '(787) ### ####'
}, {
  name: 'country.qatar',
  icon: qatar,
  id: 'qatar',
  code: 'QA',
  phone: '974',
  mask: '#### ####'
}, {
  name: 'country.republic-of-macedonia',
  icon: republicOfMacedonia,
  id: 'republicOfMacedonia',
  code: 'MK',
  phone: '389',
  mask: '## ### ###'
}, {
  name: 'country.republic-of-poland',
  icon: republicOfPoland,
  id: 'republicOfPoland',
  code: 'PL',
  phone: '48',
  mask: '(###) ### ###'
}, {
  name: 'country.republic-of-the-congo',
  icon: republicOfTheCongo,
  id: 'republicOfTheCongo',
  code: 'CG',
  phone: '242',
  mask: '## #####'
}, {
  name: 'country.romania',
  icon: romania,
  id: 'romania',
  code: 'RO',
  phone: '40',
  mask: '## ### ####'
}, {
  name: 'country.russia',
  icon: russia,
  id: 'russia',
  code: 'RU',
  phone: '7',
  mask: '(###) ### ## ##',
  currency: CURRENCY.RUB
}, {
  name: 'country.rwanda',
  icon: rwanda,
  id: 'rwanda',
  code: 'RW',
  phone: '250',
  mask: '(###) ### ###'
}, {
  name: 'country.saint-kitts-and-nevis',
  icon: saintKittsAndNevis,
  id: 'saintKittsAndNevis',
  code: 'KN',
  phone: '1869',
  mask: '(869) ### ####'
}, {
  name: 'country.salvador',
  icon: salvador,
  id: 'salvador',
  code: 'SV',
  phone: '503',
  mask: '## ## ####'
}, {
  name: 'country.samoa',
  icon: samoa,
  id: 'samoa',
  code: 'WS',
  phone: '685',
  mask: '## ####'
}, {
  name: 'country.san-marino',
  icon: sanMarino,
  id: 'sanMarino',
  code: 'SM',
  phone: '378',
  mask: '#### ######'
}, {
  name: 'country.sao-tome-and-principe',
  icon: saoTomeAndPrincipe,
  id: 'saoTomeAndPrincipe',
  code: 'ST',
  phone: '239',
  mask: '## #####'
}, {
  name: 'country.saudi-arabia',
  icon: saudiArabia,
  id: 'saudiArabia',
  code: 'SA',
  phone: '966',
  mask: '# ### ####'
}, {
  name: 'country.senegal',
  icon: senegal,
  id: 'senegal',
  code: 'SN',
  phone: '221',
  mask: '## ### ####'
}, {
  name: 'country.serbia',
  icon: serbia,
  id: 'serbia',
  code: 'RS',
  phone: '381',
  mask: '## ### ####'
}, {
  name: 'country.seychelles',
  icon: seychelles,
  id: 'seychelles',
  code: 'SC',
  phone: '248',
  mask: '# ### ###'
}, {
  name: 'country.sierra-leone',
  icon: sierraLeone,
  id: 'sierraLeone',
  code: 'SL',
  phone: '232',
  mask: '## ######'
}, {
  name: 'country.singapore',
  icon: singapore,
  id: 'singapore',
  code: 'SG',
  phone: '65',
  mask: '#### ####'
}, {
  name: 'country.sint-maarten',
  icon: sintMaarten,
  id: 'sintMaarten',
  code: 'SX',
  phone: '1721',
  mask: '(721) ### ####'
}, {
  name: 'country.slovakia',
  icon: slovakia,
  id: 'slovakia',
  code: 'SK',
  phone: '421',
  mask: '(###) ### ###'
}, {
  name: 'country.slovenia',
  icon: slovenia,
  id: 'slovenia',
  code: 'SI',
  phone: '386',
  mask: '## ### ###'
}, {
  name: 'country.solomon-islands',
  icon: solomonIslands,
  id: 'solomonIslands',
  code: 'SB',
  phone: '677',
  mask: '#####'
}, {
  name: 'country.somalia',
  icon: somalia,
  id: 'somalia',
  code: 'SO',
  phone: '252',
  mask: '# ### ###'
}, {
  name: 'country.south-africa',
  icon: southAfrica,
  id: 'southAfrica',
  code: 'ZA',
  phone: '27',
  mask: '## ### ####'
}, {
  name: 'country.south-korea',
  icon: southKorea,
  id: 'southKorea',
  code: 'KR',
  phone: '82',
  mask: '## ### ####'
}, {
  name: 'country.south-sudan',
  icon: southSudan,
  id: 'southSudan',
  code: 'SS',
  phone: '211',
  mask: '## ### ####'
}, {
  name: 'country.spain',
  icon: spain,
  id: 'spain',
  code: 'ES',
  phone: '34',
  mask: '(###) ### ###'
}, {
  name: 'country.sri-lanka',
  icon: sriLanka,
  id: 'sriLanka',
  code: 'LK',
  phone: '94',
  mask: '## ### ####'
}, {
  name: 'country.st-lucia',
  icon: stLucia,
  id: 'stLucia',
  code: 'LC',
  phone: '1758',
  mask: '(758) ### ####'
}, {
  name: 'country.st-vincent-and-the-grenadines',
  icon: stVincentAndTheGrenadines,
  id: 'stVincentAndTheGrenadines',
  code: 'VC',
  phone: '1784',
  mask: '(784) ### ####'
}, {
  name: 'country.sudan',
  icon: sudan,
  id: 'sudan',
  code: 'SD',
  phone: '249',
  mask: '## ### ####'
}, {
  name: 'country.suriname',
  icon: suriname,
  id: 'suriname',
  code: 'SR',
  phone: '597',
  mask: '### ###'
}, {
  name: 'country.swaziland',
  icon: swaziland,
  id: 'swaziland',
  code: 'SZ',
  phone: '268',
  mask: '## ## ####'
}, {
  name: 'country.sweden',
  icon: sweden,
  id: 'sweden',
  code: 'SE',
  phone: '46',
  mask: '## ### ####'
}, {
  name: 'country.switzerland',
  icon: switzerland,
  id: 'switzerland',
  code: 'CH',
  phone: '41',
  mask: '## ### ####'
}, {
  name: 'country.syria',
  icon: syria,
  id: 'syria',
  code: 'SY',
  phone: '963',
  mask: '## #### ###'
}, {
  name: 'country.taiwan',
  icon: taiwan,
  id: 'taiwan',
  code: 'TW',
  phone: '886',
  mask: '#### ####'
}, {
  name: 'country.tajikistan',
  icon: tajikistan,
  id: 'tajikistan',
  code: 'TJ',
  phone: '992',
  mask: '## ### ####'
}, {
  name: 'country.tanzania',
  icon: tanzania,
  id: 'tanzania',
  code: 'TZ',
  phone: '255',
  mask: '## ### ####'
}, {
  name: 'country.thailand',
  icon: thailand,
  id: 'thailand',
  code: 'TH',
  phone: '66',
  mask: '## ### ###'
}, {
  name: 'country.togo',
  icon: togo,
  id: 'togo',
  code: 'TG',
  phone: '228',
  mask: '## ### ###'
}, {
  name: 'country.tonga',
  icon: tonga,
  id: 'tonga',
  code: 'TO',
  phone: '676',
  mask: '#####'
}, {
  name: 'country.trinidad-and-tobago',
  icon: trinidadAndTobago,
  id: 'trinidadAndTobago',
  code: 'TT',
  phone: '1868',
  mask: '(868) ### ####'
}, {
  name: 'country.tunisia',
  icon: tunisia,
  id: 'tunisia',
  code: 'TN',
  phone: '216',
  mask: '## ### ###'
}, {
  name: 'country.turkey',
  icon: turkey,
  id: 'turkey',
  code: 'TR',
  phone: '90',
  mask: '(###) ### ####'
}, {
  name: 'country.turkmenistan',
  icon: turkmenistan,
  id: 'turkmenistan',
  code: 'TM',
  phone: '993',
  mask: '# ### ####'
}, {
  name: 'country.tuvalu',
  icon: tuvalu,
  id: 'tuvalu',
  code: 'TV',
  phone: '688',
  mask: '2####'
}, {
  name: 'country.uganda',
  icon: uganda,
  id: 'uganda',
  code: 'UG',
  phone: '256',
  mask: '(###) ### ###'
}, {
  name: 'country.ukraine',
  icon: ukraine,
  id: 'ukraine',
  code: 'UA',
  phone: '380',
  mask: '(##) ### ## ##',
  currency: CURRENCY.UAH
}, {
  name: 'country.united-arab-emirates',
  icon: unitedArabEmirates,
  id: 'unitedArabEmirates',
  code: 'AE',
  phone: '971',
  mask: '## ### ####',
  currency: CURRENCY.AED
}, {
  name: 'country.united-kingdom',
  icon: unitedKingdom,
  id: 'unitedKingdom',
  code: 'GB',
  phone: '44',
  mask: '## #### ####'
}, {
  name: 'country.united-states-of-america',
  icon: unitedStatesOfAmerica,
  id: 'unitedStatesOfAmerica',
  code: 'US',
  phone: '1',
  mask: '(###) ### ####'
}, {
  name: 'country.uruguay',
  icon: uruguay,
  id: 'uruguay',
  code: 'UY',
  phone: '598',
  mask: '# ### ## ##'
}, {
  name: 'country.uzbekistn',
  icon: uzbekistn,
  id: 'uzbekistn',
  code: 'UZ',
  phone: '998',
  mask: '## ### ####'
}, {
  name: 'country.vanuatu',
  icon: vanuatu,
  id: 'vanuatu',
  code: 'VU',
  phone: '678',
  mask: '#####'
}, {
  name: 'country.vatican-city',
  icon: vaticanCity,
  id: 'vaticanCity',
  code: 'VA',
  phone: '379',
  mask: '06 698#####'
}, {
  name: 'country.venezuela',
  icon: venezuela,
  id: 'venezuela',
  code: 'VE',
  phone: '58',
  mask: '(###) ### ####'
}, {
  name: 'country.vietnam',
  icon: vietnam,
  id: 'vietnam',
  code: 'VN',
  phone: '84',
  mask: '## #### ###'
}, {
  name: 'country.yemen',
  icon: yemen,
  id: 'yemen',
  code: 'YE',
  phone: '967',
  mask: '# ### ###'
}, {
  name: 'country.zambia',
  icon: zambia,
  id: 'zambia',
  code: 'ZM',
  phone: '260',
  mask: '## ### ####'
}];

export default sortBy(country, 'name');
