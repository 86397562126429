<template>
  <div
    class="lp-calendar"
    :class="{'lp-calendar_mobile': isMobile}"
  >
    <div
      class="lp-calendar-header"
      :class="{'lp-calendar-header_mobile': isMobile}"
    >
      <div
        class="lp-calendar-header__today"
        :class="{'lp-calendar-header__today_mobile': isMobile}"
      >
        {{ calendarDateFormat }}
      </div>
      <div class="lp-calendar-header__controls">
        <DateControls
          v-if="!isMobile"
          v-model:date="calendarDateDesktop"
          v-model:tab="activeType"
        />
        <DateTimetableControls
          v-else
          :mini="isMobile"
          v-model:date="calendarDateMobile"
        />
      </div>
      <DateControlsMobile
        v-if="isMobile"
        class="lp-calendar-header__week-controls"
        :date="calendarDateMobile"
        v-model:date="calendarDateMobile"
        @arrow="arrowUse"
      />
    </div>
    <div
      class="lp-calendar-body"
      :class="{'llp-calendar-body_mobile': isMobile}"
    >
      <transition :name="getTransitionCalendar()">
        <component
          class="lp-calendar-body__calendar"
          :class="{
            'lp-calendar-body__calendar_mobile': isMobile
          }"
          :is="getCalendar"
          :lessons="groupLessons"
          :key="calendarDate"
          :date="calendarDate"
          :mobile="isMobile"
          @get-lessons="getLessons"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import DateControls from '@/components/CRM/Lessons/Calendar/DateControls/DateControls';
import { watch, ref, computed, onBeforeMount, inject } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import CalendarMonths from '@/components/CRM/Lessons/Calendar/CalendarTypes/CalendarMonths';
import CalendarWeeks from '@/components/CRM/Lessons/Calendar/CalendarTypes/CalendarWeeks';
import CalendarDays from '@/components/CRM/Lessons/Calendar/CalendarTypes/CalendarDays';
import { fill, get, groupBy, orderBy } from 'lodash';
import LessonsApi from '@/api/CRM/lessons';
import { useStore } from 'vuex';
import DateTimetableControls from '@/components/CRM/Lessons/Timetable/DateTimetableControls/DateTimetableControls';
import DateControlsMobile from '@/components/CRM/Lessons/Calendar/DateControls/DateControlsMobile';
import StudentApi from '@/api/CRM/students';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Calendar',
  components: {
    DateControlsMobile,
    DateTimetableControls,
    DateControls
  },
  props: {
    lastLessons: Boolean,
    date: String
  },
  setup (props, { emit }) {
    const isDesktop = inject('isDesktop');
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const calendarDateDesktop = ref(moment().format('YYYY-MM-DD'));
    const calendarDateMobile = computed({
      get () {
        return props.date;
      },
      set (data) {
        emit('update:date', data);
      }
    });
    const calendarDate = computed(() => isMobile.value ? calendarDateMobile.value : calendarDateDesktop.value);

    const formatDateTittle = computed(() => {
      return isDesktop ? 'MMMM YYYY' : 'ddd, DD MMMM YYYY';
    });
    const calendarDateFormat = computed(() => moment(calendarDate.value).format(formatDateTittle.value));

    const store = useStore();
    const lessons = computed(() => store.getters.lessons);
    const setLessons = (data) => store.dispatch('setLessons', data);
    const groupLessons = computed(() => {
      const withDate = lessons.value.map((lesson) => {
        lesson.date = moment(lesson.crmLessonStart).format('YYYY-MM-DD');
        return lesson;
      });
      const sortByStartTime = orderBy(withDate, 'crmLessonStart');
      return groupBy(sortByStartTime, 'date');
    });

    const router = useRouter();
    const activeType = ref(get(router, 'currentRoute.value.params.unit', 'month'));
    watch(activeType, () => router.replace({ params: { unit: activeType.value } }));

    const getCalendar =  computed(() => {
      if (!isDesktop) return CalendarDays;
      switch (activeType.value) {
      case 'month':
        return CalendarMonths;
      case 'week':
        return CalendarWeeks;
      default:
        return CalendarDays;
      }
    });

    const getRangeDate = () => {
      const date = moment(calendarDate.value);
      if (isMobile.value) {
        return {
          startDate: date.utc().format(),
          endDate: date.add(1, 'day').utc().format()
        };
      }
      if (activeType.value === 'month') {
        const week = fill(new Array(7), 1).map((el, i) => {
          const startDateMonth = moment(calendarDate.value).startOf('month');
          return startDateMonth.subtract(i, 'd');
        });
        const start = week.find((date) => date.day() === 1);
        return {
          startDate: start.utc().format(),
          endDate: start.add(35, 'd').utc().format()
        };
      }
      return {
        startDate: date.utc().format(),
        endDate: date.add(1, activeType.value).utc().format()
      };
    };

    const bodyScrollUp = () => {
      window.scroll(0,0);
    };

    const getLessons = async () => {
      if (!isDesktop) bodyScrollUp();
      const { startDate, endDate } = getRangeDate();
      const query = {
        lost: props.lastLessons,
        start: startDate,
        end: endDate
      };
      // await setLessons([]);
      try {
        const { data } = await LessonsApi.getGroupLessonsByDate(query);
        await setLessons(data);
      } catch (err) {
        console.error(err);
      }
    };
    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsTable', data);

    const getStudentsList = async () => {
      try {
        const { data: { resource = [] } } = await StudentApi.getStudents({});
        await setStudents(resource);
      } catch (err) {
        console.error(err);
      }
    };

    watch([calendarDate, activeType, props], getLessons);
    onBeforeMount(() => {
      getStudentsList();
      getLessons();
    });

    const arrowUse = (position) => {
      direction.value = position;
    };

    const direction = ref('left');
    const getTransitionCalendar = () => {
      if (isDesktop) return 'slide-calendar';
      return '';
    };

    return {
      isMobile,
      getTransitionCalendar,
      isDesktop,
      calendarDate,
      calendarDateDesktop,
      calendarDateMobile,
      calendarDateFormat,
      activeType,
      getCalendar,
      groupLessons,
      getLessons,
      students,
      arrowUse
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-calendar {
  @include global-font;
  padding: 20px 24px 24px;

  &_mobile {
    background-color: $color-white;
    padding: 8px 8px 0;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
    margin-bottom: 12px;
    min-height: 37px;

    &_mobile {
      position: sticky;
      top: 89px;
      margin-bottom: 0;
      padding-bottom: 24px;
      padding-top: 17px;
      grid-gap: 17px;
      background-color: $color-white;
      z-index: 2;
    }

    &__week-controls {
      grid-column: 1/3;
    }

    &__today {
      color: $color-text;
      font-weight: bold;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      user-select: none;

      &_mobile {
        font-size: 16px;
      }
    }
  }

  &-body {
    position: relative;

    &_mobile {
      padding-bottom: 32px;
    }

    &__calendar {
      top: 0;
      left: 0;
      right: 0;
      background-color: $color-white;

      &_mobile {
        background-color: transparent;
      }
    }
  }
}
</style>
