const opportunities = [
  {
    id: 1,
    text: 'landing.opportunities.firstItem.name'
  },
  {
    id: 2,
    text: 'landing.opportunities.secondItem.name'
  },
  {
    id: 3,
    text: 'landing.opportunities.thirdItem.name'
  }
];
export default opportunities;
