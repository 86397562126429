<template>
  <div
    class="lp-delete"
    :class="{
      'lp-delete_fixed': position,
      'lp-delete_center': center
    }"
    :style="checkedPosition"
    ref="menuEl"
    v-click-outside="closeMenu"
  >
    <teleport to="#app">
      <div
        class="lp-delete__background"
        v-if="background"
      />
    </teleport>
    <div class="lp-delete-body">
      <span>{{ $t(question) }}</span>
      <span v-if="name">
        {{ ` ${name}?` }}
      </span>
    </div>
    <div class="lp-delete-footer">
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="closeMenu"
      >
        {{ $t('global.no') }}
      </button>
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="acceptDelete"
      >
        {{ $t('global.yes') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'AcceptDelete',
  props: {
    background: Boolean,
    isZoomed: Boolean,
    center: Boolean,
    position: Object,
    name: String,
    item: {
      type: Object,
      default: () => ({})
    },
    question: {
      type: String,
      default: 'crm.students.table.toolbar.deleteQuestion'
    }
  },
  emits: ['accept', 'update:status'],
  setup (props, { emit }) {
    const closeMenu = () => {
      emit('update:status', false);
    };

    const acceptDelete = () => {
      closeMenu();
      emit('accept');
    };

    const menuEl = ref(null);
    const checkedPosition = computed(() => {
      if (!props.position || !menuEl.value || props.center) return {};
      const {
        offsetHeight,
        offsetWidth
      } = menuEl.value;
      const {
        left,
        top
      } = props.position;
      const {
        innerWidth,
        innerHeight
      } = window;
      const style = {
        top: `${top}px`,
        left: `${left}px`,
        right: 'auto'
      };
      const zoom = props.isZoomed ? 1.2 : 1;
      if (innerWidth * zoom < (left + offsetWidth)) {
        style.left = `${left - offsetWidth}px`;
      }
      if (innerHeight * zoom < (top + offsetHeight)) {
        style.top = `${top - offsetHeight}px`;
      }
      return style;
    });

    let body = null;
    onBeforeMount(() => {
      body = document.querySelector('body');
      if (body) body.style.overflow = 'hidden';
    });
    onUnmounted(() => {
      if (body) body.style.overflow = 'visible';
    });

    return {
      closeMenu,
      acceptDelete,
      menuEl,
      checkedPosition
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-delete {
  @include global-font;
  position: absolute;
  right: 0;
  display: grid;
  grid-gap: 16px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  padding: 16px 18px;
  min-width: 252px;
  z-index: 10;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;

  &_center {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: calc(100vw - 32px);
    z-index: 102;
  }

  &_fixed {
    position: fixed;
  }

  &__background {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background-color: rgba($color-black, 0.5);
    z-index: 100;
  }

  &-body {
    text-align: center;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

</style>
