<template>
  <div>
    <div v-if="student">
      <Modal
        :mobileVersion="mobileVersion"
        :title="student.fullName"
        @save="openEditStudent"
        @remove="closeForm"
        :hideButtons="onlyView ? ['remove', 'save'] : ['remove']"
        :saveBtnText="'crm.students.table.toolbar.edit'"
        v-model:open="openModal"
        :customClass="allLoaded ? 'lp-student-card__modal' : 'lp-student-card__modal_hide'"
      >
        <div
          class="lp-student-card"
          :class="{'lp-student-card_mobile': isMobile}"
          ref="studentCard"
        >
          <StudentProfile
            :mobileVersion="mobileVersion"
            :student="student"
            @generatePdf="generatePdf"
          />
          <StudentBalance
            :student="student"
            :subscription="subscription"
            v-model:payment="openPayments"
            v-model:showModalAddSubscription="showModalAddSubscription"
          />
          <StudentSubscription
            v-if="subscription && subscription.passStatus === 'Active'"
            :subscription="subscription"
            :student="student"
            @removeSubscription="removeSubscription"
          />
          <StudentUpcomingLessons
            :student="student"
            v-model:loading="loadingUpcomingLessons"
          />
          <StudentPastLessons
            :student="student"
            v-model:loading="loadingPastLessons"
          />
          <StudentPayments
            :student="student"
            v-model:loading="loadingPayments"
          />
        </div>
        <PaymentForm
          :openForm="openPayments"
          :student="student"
          v-model:openForm="openPayments"
        />
        <StudentAddedSubscription
          v-model:openModal="showModalAddSubscription"
          :student="student"
        />
      </Modal>
    </div>
    <transition name="fade">
      <teleport
        to="#app"
        v-if="!allLoaded || loadingPdf"
      >
        <div class="lp-student-card__placeholder">
          <Loader :block="true" />
        </div>
      </teleport>
    </transition>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import { computed, ref, watch } from 'vue';
import StudentProfile from '@/components/CRM/Students/StudentCard/StudentProfile';
import StudentBalance from '@/components/CRM/Students/StudentCard/StudentBalance';
import StudentPayments from '@/components/CRM/Students/StudentCard/StudentPayments';
import PaymentForm from '@/components/CRM/Students/StudentCard/PaymentForm';
import StudentUpcomingLessons from '@/components/CRM/Students/StudentCard/StudentUpcomingLessons';
import StudentPastLessons from '@/components/CRM/Students/StudentCard/StudentPastLessons';
import Loader from '@/components/Main/Loader/Loader';
import JsPdf from 'jspdf';
import { zoomLevel } from 'zoom-level';
import '@/constants/fontsFromPdf/ArialRegular-normal';
import '@/constants/fontsFromPdf/ArialBold-bold';
import StudentSubscription from '@/components/CRM/Students/StudentCard/StudentSubscription';
import StudentAddedSubscription from '@/components/CRM/Students/StudentCard/StudentAddedSubscription';
import LessonsPassApi from '@/api/CRM/lessonsPass';
import { useStore } from 'vuex';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentCard',
  components: {
    Loader,
    StudentPastLessons,
    StudentUpcomingLessons,
    PaymentForm,
    StudentPayments,
    StudentBalance,
    StudentProfile,
    StudentSubscription,
    StudentAddedSubscription,
    Modal
  },
  props: {
    onlyView: Boolean,
    openForm: [Boolean, String],
    student: Object
  },
  emits: ['update:open', 'update:editStudent', 'update:openEdit'],
  setup (props, { emit }) {
    const mobileVersion = computed(() => checkMobileByScreen());

    const openModal = ref(false);
    const openPayments = ref(false);
    const showModalAddSubscription = ref(false);

    const loadingPdf = ref(false);
    const loadingUpcomingLessons = ref(false);
    const loadingPastLessons = ref(false);
    const loadingPayments = ref(false);
    const allLoaded = computed(() => {
      return !loadingUpcomingLessons.value && !loadingPayments.value && !loadingPastLessons.value;
    });

    const closeForm = () => {
      openModal.value = false;
    };

    const openEditStudent = () => {
      closeForm();
      emit('update:editStudent', props.student);
      emit('update:openEdit', true);
    };

    watch(() => props.openForm, (value) => {
      openModal.value = value;
    });
    watch(openModal, (value) => {
      emit('update:open', value);
    });

    const subscription = computed(() => props.student.crmLessonPass);

    const studentCard = ref(null);
    const calcMaxHeightA4 = () => {
      const dpi = document.createElement('div');
      dpi.style.width = '1cm';
      dpi.style.height = '1cm';
      dpi.style.position = 'fixed';
      dpi.style.top = '-100%';
      dpi.style.left = '-100%';
      dpi.style.zIndex = '-1';
      document.body.appendChild(dpi);
      const result = 27 * dpi.clientHeight;
      dpi.remove();
      return result;
    };
    const prepareHandoverNode = () => {
      const contentHtml = studentCard.value.cloneNode(true);
      contentHtml.classList.add('lp-student-card_pdf');
      contentHtml.style.position = 'fixed';
      contentHtml.style.top = '0';
      contentHtml.style.left = '0';
      contentHtml.style.padding = '1.5cm';
      contentHtml.style.width = '21cm';
      contentHtml.style.zIndex = -1;
      contentHtml.style.backgroundColor = '#FFFFFF';
      document.body.appendChild(contentHtml);
      return contentHtml;
    };
    const generatePdf = async () => {
      loadingPdf.value = true;
      const contentHtml = await prepareHandoverNode();
      const fileName = `${props.student.fullName}.pdf`;
      const realWidth = contentHtml.clientWidth;
      const realHeight = calcMaxHeightA4();
      const zoom = zoomLevel();
      const doc = new JsPdf({
        orientation: 'p',
        unit: 'pt',
        format: [realWidth * zoom, realHeight * zoom],
        putOnlyUsedFonts: true,
        compress: true,
        filters: ['ASCIIHexEncode']
      });

      doc.html(contentHtml, {
        callback: (pdf) => {
          pdf.save(fileName);
          contentHtml.remove();
          loadingPdf.value = false;
        }
      });
    };

    const store = useStore();
    const students = computed(() => store.getters.students);

    const removeSubscription = async () => {
      const { _id: id } = subscription.value;
      try {
        await LessonsPassApi.removeLessonPassForStudent(id);
        const student = {
          ...props.student,
          crmLessonPass: null
        };
        const indexCurrentStudent = students.value.findIndex(({ _id }) => _id === student._id);
        if (indexCurrentStudent !== -1) {
          students.value.splice(indexCurrentStudent, 1, student);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      mobileVersion,
      openPayments,
      openModal,
      openEditStudent,
      closeForm,
      loadingUpcomingLessons,
      loadingPastLessons,
      loadingPayments,
      loadingPdf,
      allLoaded,
      generatePdf,
      studentCard,
      subscription,
      showModalAddSubscription,
      removeSubscription
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-student-card {
  @include global-font;
  padding: 18px 20px;
  min-width: 900px;
  max-width: 900px;
  position: relative;
  background-color: $color-white;

  @media (max-width: 1024px) {
    min-width: auto;
    max-width: 100%;
  }

  &_mobile {
    width: 100%;
    padding: 28px 12px 56px;
  }

  &__placeholder {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: rgba($color-white, 0.2);
    z-index: 105;
  }
}

</style>

<style lang="scss">

.lp-student-card {
  &__modal {
    opacity: 1;
    transition: opacity 0.3s ease-in;

    &_hide {
      opacity: 0;
    }
  }

  &_pdf {
    &, * {
      font-family: 'Arial' !important;
    }

    svg {
      display: none;
    }

    .lp-student-balance-item__icon,
    .lp-student-profile-info-item__icon, .lp-delete-cell, .lp-button,
    .lp-student-profile-photo_placeholder, .lp-student-profile-info-item_virtual-class, .lp-lesson-not-complete,
    .lp-student-profile-info-item_social-media, .lp-student-profile-note, .lp-student-balance-item__icon,
    .student-subscription-list, .lp-student-balance-buttons {
      display: none;
    }

    .lp-student-upcoming-lessons__table, .lp-student-payments__table, .lp-student-past-lessons__table {
      max-height: none;
    }

    .lp-student-profile-info-item__value_status {
      line-height: 1!important;
      padding-bottom: 7px!important;
      padding-top: 2px!important;
    }

    .lp-table-column-item__icon-text {
      display: inline;
    }

  }
}

</style>
