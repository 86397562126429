<template>
  <div class="lp-hints-registration-form">
    <CustomInput
      v-model="settings.firstName"
      :label="$t('hints.registration.fields.name')"
      :error="settings.nameError"
      :autofocus="true"
    />
    <div
      class="lp-hints-registration-form__wrap"
      :class="{'lp-hints-registration-form__wrap_mobile': isMobile}"
    >
      <CustomInput
        class="lp-hints-registration-form__input"
        v-model="settings.defaultLessonCost"
        :label="'crm.settings.cost'"
        :error="settings.lessonCostError"
        :type="'number'"
        :inputmode="'decimal'"
        :noWrap="true"
        @focus="$emit('update:error', '')"
        @submit="$emit('submit')"
      />
      <Currency
        v-model:currency="settings.currentCurrency"
        :defaultValue="settings.currentCurrency"
      />
    </div>
    <div class="lp-hints-registration-form__wrap">
      <DefaultTime
        class="lp-hints-registration-form__time"
        v-model:time="settings.defaultLessonLength"
        :defaultValue="settings.defaultLessonLength"
        :postfix="$t('crm.settings.minutes')"
        :required="false"
      />
    </div>
    <div
      class="lp-hints-registration-form__button"
      :class="{'lp-hints-registration-form__button_mobile': isMobile}"
    >
      <div
        class="lp-button"
        @click="onSaveForm"
      >
        {{ $t('hints.registration.button') }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import CustomInput from '@/components/Main/Inputs/CustomInput';
import Currency from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/Currency';
import country from '@/constants/country';
import DefaultTime from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/DefaultTime';
import SettingsApi from '@/api/CRM/settings';
import UserApi from '@/api/User/api';
import { useStore } from 'vuex';
import checkCountries from '@/constants/countries/checkCountries';
import { get } from 'lodash';

export default {
  name: 'HintsRegistrationForm',
  components: {
    CustomInput,
    Currency,
    DefaultTime
  },
  props: {
    isMobile: Boolean
  },
  emits: ['on-close-registration-modal'],
  setup (props, { emit }) {
    const store = useStore();
    const countryCode = computed(() => store.getters.countryCode);
    const user = computed(() => store.getters.user);

    const setUser = (data) => store.dispatch('setUser', data);
    const setSettings = (data) => store.dispatch('setSettings', data);

    const language = computed(() => {
      return checkCountries(countryCode.value) ? 'ru' : 'en';
    });

    const formValue = {
      firstName: '',
      nameError: '',
      defaultLessonCost: '',
      lessonCostError: '',
      currentCurrency: '',
      defaultLessonLength: 60,
      currencyExchangeRate: 1,
      countryCode,
      language
    };

    const settings = ref(formValue);
    const isError = ref(false);

    watch(settings.value, () => {
      if (!isError.value) return;
      validationField();
    });

    const validationField = () => {
      const { firstName, defaultLessonCost } = settings.value;
      settings.value.nameError = firstName ? '' : 'errors.emptyName';
      settings.value.lessonCostError = defaultLessonCost
        ? ''
        : 'errors.correctAmount';
      return firstName && defaultLessonCost;
    };

    const saveSetting = async () => {
      const { nameError, lessonCostError, ...all } = settings.value;
      try {
        const { data } = await SettingsApi.saveSettings(all);
        await setSettings(data);
        isError.value = false;
      } catch (err) {
        console.error(err);
      }
    };

    const updateUser = async () => {
      const { role, _id } = user.value;
      const { firstName, countryCode } = settings.value;
      const body = {
        firstName: firstName,
        role,
        country: get(
          country.find((item) => item.code === countryCode),
          'id',
          ''
        ),
        _id
      };
      try {
        const { data } = await UserApi.updateUser(body);
        await setUser(data);
      } catch (err) {
        console.error(err);
      }
    };

    const onSaveForm = () => {
      isError.value = true;
      if (!validationField()) return;
      saveSetting();
      updateUser();
      emit('on-close-registration-modal');
    };

    return {
      settings,
      onSaveForm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-hints-registration-form {
  width: 100%;
  height: 100%;

  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin: 32px 0 0 0;

    &_mobile {
      grid-column-gap: 24px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 68px 0 0 0;

    &_mobile {
      justify-content: center;
    }
  }

  .lp-crm-settings-currency {
    text-align: left;
  }
}
</style>

<style lang="scss">
.lp-hints-registration-form {
  &__time {
    .lp-crm-settings-default-time__input {
      width: 175px;
    }
  }
}
</style>
