import SOCKET_EVENTS from '@/constants/enums/events';
import { endVideoStream } from '@/constants/_rtc/utils/helpers';
import { orderBy } from 'lodash';
import soundOnConnected from '@/assets/sounds/connected.mp3';

export const customEventName = 'musiovideocall';

const connectionEvents = (function () {
  return (() => {
    let store;
    let connection;
    let videoSettings;
    let audio;

    const setVideoMirror = ({ value }) => videoSettings.mirrorRemote = value;

    const setVideoMute = ({ value }) => videoSettings.muteRemote = value;

    const setSingingStudent = ({ value }) => store.dispatch('setSingingStudent', value);

    const setLiteVersion = ({ value }) => store.dispatch('setLiteVersion', value);

    const setMobileParticipant = ({ value }) => store.dispatch('setMobileParticipant', value);

    const runSoundOnConnected = ({ value: user }) => {
      console.log('%c%s', 'color: #fff; font-weight: bold; background: red; padding: 10px', 'sound on connected');
      const audio = document.getElementById('sound-effect-connected');
      if (!audio) return;
      audio.src = soundOnConnected;
      audio.volume = 0.5;
      audio.oncanplaythrough = () => audio.play();
    };

    const getLimitText = ({ limitByStudents, limitByTeachers }) => {
      if (limitByStudents) {
        return 'limitStudent';
      } else if (limitByTeachers) {
        return 'limitTeacher';
      }
      return 'limitAll';
    };

    const changeParticipants = () => {
      const me = { ...connection.extra, id: connection.userid };
      const list = connection.getAllParticipants() || [];
      const listExtra = list.map(u => ({ ...connection.getExtraData(u), id: u })).concat([me]);
      if (listExtra.length < 2) return;
      const sortListExtra = orderBy(listExtra, 'joinedAt', 'desc');
      const last = sortListExtra[0];
      if (!sortListExtra[1] || !last) return;
      const limitByAll = listExtra.length > 2;
      const limitByStudents = !last.isTeacher && !sortListExtra[1].isTeacher;
      const limitByTeachers = last.isTeacher && sortListExtra[1].isTeacher;
      const limit = limitByAll || limitByStudents || limitByTeachers;

      if (last?.id === connection?.userid && limit) {
        connection?.leave && connection.leave();
        store.dispatch('setLimitRoom', getLimitText({ limitByTeachers, limitByStudents }));
      } else {
        store.dispatch('setCheckedLimit', true);
      }
    };

    const onCustomEvent = (data) => {
      const { event, socketId } = data;
      if (connection.socketMessageEvent !== socketId) return;
      const {
        CHANGE_PARTICIPANTS,
        SINGING_STUDENT,
        CLOSE_ROOM,
        VIDEO_MIRROR,
        VIDEO_MUTE,
        SET_LITE_CALL,
        SET_MOBILE,
        ON_CONNECTED
      } = SOCKET_EVENTS;
      switch (event) {
      case VIDEO_MIRROR:
        setVideoMirror(data);
        break;
      case VIDEO_MUTE:
        setVideoMute(data);
        break;
      case SINGING_STUDENT:
        setSingingStudent(data);
        break;
      case CLOSE_ROOM:
        endVideoStream(store);
        break;
      case SET_LITE_CALL:
        setLiteVersion(data);
        break;
      case CHANGE_PARTICIPANTS:
        changeParticipants(data);
        break;
      case SET_MOBILE:
        console.log('setMobileParticipant', data);
        setMobileParticipant(data);
        break;
      case ON_CONNECTED:
        runSoundOnConnected(data);
        break;
      }
    };

    const setCustomEvents = (storeObj) => {
      store = storeObj;
      videoSettings = store.getters.videoSettings;
      connection = store.getters.rtcConnect;

      if (!connection.socket) return;
      connection.setCustomSocketEvent(customEventName);
      connection.socket.on(customEventName, onCustomEvent);
    };

    return {
      setCustomEvents
    };
  })();
}());

export default connectionEvents;
