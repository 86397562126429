<template>
  <Modal
    v-model:open="isShowModal"
    :title="modalTitle"
    :removeBtnText="'buttons.cancel'"
    :saveBtnText="saveBtnText"
    :hideButtons="students.length ? [] : ['remove']"
    @save="subscriptionApply"
    @remove="closeForm"
    :editMode="isMobile"
    :mobileVersion="isMobile"
    :customClass="'lp-crm-subscription-apply-modal'"
    :removeScroll="!!error"
  >
    <div
      class="lp-crm-subscription-apply"
      v-if="students.length"
    >
      <Form
        @submit="subscriptionApply"
        :inputs="inputs"
        @handlerChange="handlerSelectStudent"
      />
      <transition name="bounce">
        <div
          class="lp-crm-subscription-apply__error lp-error"
          v-if="error"
        >
          <dangerIcon />
          {{ $t(error) }}
        </div>
      </transition>
    </div>
    <div
      class="lp-crm-subscription-apply-empty"
      v-else
    >
      <img
        class="lp-crm-subscription-apply-empty__image"
        :src="imgPlaceholder"
        alt="students"
      >
      <p class="lp-crm-subscription-apply-empty__title">
        {{ $t('calendar.empty.emptyStudents') }}
      </p>
      <p class="lp-crm-subscription-apply-empty__description">
        {{ $t('crm.subscriptions.empty.emptyInfo') }}
      </p>
      <button
        class="lp-button lp-crm-subscription-apply-empty__button"
        @click="subscriptionApply"
        v-if="isMobile"
      >
        {{ $t('crm.students.table.addStudent') }}
      </button>
    </div>
  </Modal>
  <transition name="fade">
    <teleport
      to="#app"
      v-if="isLoader"
    >
      <div class="lp-student-card__placeholder">
        <Loader :block="true" />
      </div>
    </teleport>
  </transition>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Loader from '@/components/Main/Loader/Loader';
import Form from '@/components/Main/Form/Form';
import inputs from '@/constants/inputs/subscriptionApplyModal';
import { computed, onBeforeMount, ref, watch } from 'vue';
import StudentApi from '@/api/CRM/students';
import { useStore } from 'vuex';
import { get, sortBy } from 'lodash';
import STUDENT_STATUS from '@/constants/enums/studentStatus';
import validateProfile from '@/constants/validatesForm/validateProfile';
import LessonsPassApi from '@/api/CRM/lessonsPass';
import { getErrorText } from '@/constants/utils/setErrorText';
import { dangerIcon } from '@/constants/icons/crm';
import imgPlaceholder from '@/assets/images/tablePlaceholder/students.png';
import { useRouter } from 'vue-router';
import MobileDetect from 'mobile-detect';

export default {
  name: 'SubscriptionApplyModal',
  components: {
    Modal,
    Loader,
    Form,
    dangerIcon
  },
  props: {
    openModal: Boolean,
    subscription: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:openModal', 'update:item', 'subscription-applied'],
  setup (props, { emit }) {
    const store = useStore();
    const isLoader = ref(false);
    const error = ref('');

    const isShowModal = ref(false);
    const closeForm = () => {
      isShowModal.value = false;
    };

    const modalTitle = computed(() => {
      if (!students.value.length) return '';
      return 'crm.subscriptions.modal.applySubscriptionStudent';
    });

    const saveBtnText = computed(() => {
      return students.value.length ? 'buttons.apply' : 'buttons.add';
    });

    const setLoaderRun = (data) => isLoader.value = data;

    const totalStudents = ref(0);
    const checkStudentList = async () => {
      try {
        const { data } = await StudentApi.getStudents({ page: 1, limit: 1 });
        totalStudents.value = data.totalDocs;
      } catch (err) {
        console.error(err);
      }
    };

    const setDefaultValues = async () => {
      emit('update:item', {});
      inputs.forEach((input) => {
        const value = input.defaultValue;
        input.modelValue = value;
      });
    };

    const setInputs = async () => {
      const defaultStudent = get(props.subscription, 'crmStudentId', '');
      const studentsSort = sortBy(students.value || [], 'fullName');
      inputs.forEach((input) => {
        if (input.modelName === 'crmStudentId') {
          input.options = studentsSort.filter(item => item.status !== STUDENT_STATUS.INACTIVE );
          input.modelValue = input.options.find(item => item._id === defaultStudent) || input.options[0] || {};
        }
      });
      await setLoaderRun(false);
    };

    const students = computed(() => store.getters.students);
    const setStudents = (data) => store.dispatch('setStudentsTable', data);

    const getStudentsList = async () => {
      await setLoaderRun(true);
      try {
        const { data } = await StudentApi.getStudents({ });
        const { resource } = data;
        await setStudents(resource);
        await setInputs();
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
      }
      if (!isShowModal.value || totalStudents.value) {
        await setInputs();
        return;
      }
      await checkStudentList();
      if (totalStudents.value && (totalStudents.value === students.value.length)) {
        await setInputs();
        return;
      }
    };

    const createLessonPassForStudent = async (body) => {
      const { subscription } = props;
      const { _id: crmLessonPassTemplateId } = subscription;
      const { crmStudentId } = body;
      try {
        const { data } = await LessonsPassApi.createLessonPassForStudent({ crmLessonPassTemplateId, crmStudentId });
        const { amountOfLessons } = data;
        const crmLessonPass = {
          ...data,
          availableToCreateLessonsCount: amountOfLessons
        };
        const currentStudent = students.value.find(({ _id: id }) => id === crmStudentId);
        const student = {
          ...currentStudent,
          crmLessonPass
        };
        const indexCurrentStudent = students.value.findIndex(({ _id }) => _id === crmStudentId);
        students.value.splice(indexCurrentStudent, 1, student);
        closeForm();
        if (!error.value) {
          emit('subscription-applied');
        }
      } catch (err) {
        console.error(err);
        error.value = getErrorText(err);
      }
    };
    const router = useRouter();

    const subscriptionApply = async () => {
      if (!students.value.length) {
        router.push({ name: 'CRMStudents', params: { openForm: true } });
        return;
      }
      const body = await validateProfile(inputs);
      createLessonPassForStudent(body);
    };

    watch(isShowModal, (value) => {
      emit('update:openModal', value);
    });
    watch(() => props.openModal, (value) => {
      error.value = '';
      isShowModal.value = value;
      !value ? setDefaultValues() : setInputs();
    });

    const handlerSelectStudent = () => {
      error.value = '';
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    onBeforeMount(getStudentsList);

    return {
      isShowModal,
      isLoader,
      inputs,
      subscriptionApply,
      error,
      handlerSelectStudent,
      closeForm,
      students,
      imgPlaceholder,
      isMobile,
      modalTitle,
      saveBtnText
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-crm-subscription-apply {
  position: relative;
  padding: 28px 18px;

  &__error {
    top: auto;
    left: 20px;
    bottom: 0;
  }

}

.lp-crm-subscription-apply-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 20px;
  width: 100%;
  height: 100%;
  color: $color-text;
  text-align: center;
  user-select: none;

  &__image {
    width: 385px;
    height: auto;
    object-fit: cover;
    margin-bottom: 30px;

    @media (max-width: 480px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 8px;
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    max-width: 290px;
    margin-bottom: 28px;
  }
}
</style>

<!-- Created by zas on 06.07.2021  -->
