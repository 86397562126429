<template>
  <div
    class="lp-custom-input"
    :class="[
      customClass,
      {
        'lp-custom-input_large': largeLabel,
        'lp-custom-input_row': hideLabel
      }
    ]"
  >
    <div
      class="lp-custom-input-header"
      :class="{'lp-custom-input-header_hide': hideLabel}"
    >
      <label
        v-if="
          isGroupLesson &&
            input.modelName &&
            input.modelName === 'crmLessonCost'
        "
        :class="{
          'lp-label_large': largeLabel,
          'lp-label_required': required
        }"
        :for="label"
        class="lp-label lp-custom-input_label"
      >
        {{ $t('crm.lessons.form.groupLesson') }}
      </label>

      <label
        v-else
        :class="{
          'lp-label_large': largeLabel,
          'lp-label_required': required
        }"
        :for="label"
        class="lp-label lp-custom-input_label"
      >
        {{ label ? $t(label) : '' }}
      </label>
      <slot name="header"></slot>
    </div>
    <div
      class="lp-custom-input-filed"
      :class="{'lp-custom-input-filed_postfix': postfix}"
    >
      <div
        class="lp-input lp-input_currency lp-input_currency_disabled"
        v-if="type === 'currency'"
      ></div>
      <input
        v-else
        :id="label"
        :key="label"
        v-focus="autofocus"
        v-maska="type === 'number' ? numberMaska : ''"
        :autocomplete="autocomplete || 'on'"
        :class="{
          'lp-input__custom-border': customBorder,
          'lp-input_error': (error && typeof error === 'string') || customError,
          'lp-input_currency': type === 'currency',
          'lp-input_pl': hasIcon
        }"
        :placeholder="$t(placeholder || '')"
        :disabled="disabled"
        :inputmode="inputmode || 'text'"
        :pattern="type === 'number' ? '[0-9]*' : ''"
        :type="
          !type || displayPassword ? 'text' : type === 'number' ? 'text' : type
        "
        :value="modelValue"
        class="lp-input"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @input="onInput"
      />
      <div
        class="lp-input lp-input_currency lp-input_currency_disabled"
        v-if="postfix"
      >
        {{ postfix }}
      </div>
      <span
        class="lp-custom-input-filed__show-pass"
        v-if="type === 'password' && canShowPass"
        @click.stop="displayPassword = !displayPassword"
      >
        <showPassIcon v-if="!displayPassword" />
        <hidePassIcon v-else />
      </span>
      <transition name="bounce">
        <span
          class="lp-error"
          :class="{'lp-error_nowrap': noWrap}"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
      <transition name="bounce">
        <span
          class="lp-error"
          :class="{'lp-error_nowrap': noWrap}"
          v-if="customError"
        >
          <dangerIcon />
          {{ customError }}
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { dangerIcon, hidePassIcon, showPassIcon } from '@/constants/icons';
import { trimStart, upperFirst, debounce } from 'lodash';
import numeral from 'numeral';

export default {
  name: 'CustomInput',
  components: {
    dangerIcon,
    showPassIcon,
    hidePassIcon
  },
  props: {
    noWrap: Boolean,
    largeLabel: Boolean,
    modelValue: [Number, String],
    type: String,
    customClass: String,
    label: String,
    autofocus: Boolean,
    customError: [Boolean, String],
    error: [Boolean, String],
    required: {
      type: Boolean,
      default: false
    },
    upperFirst: Boolean,
    format: String,
    autocomplete: String,
    canShowPass: Boolean,
    disabled: Boolean,
    postfix: String,
    inputmode: String,
    customBorder: Boolean,
    placeholder: String,
    debounceTime: {
      type: Number,
      default: 0
    },
    hideLabel: Boolean,
    hasIcon: Boolean,
    isGroupLesson: Boolean,
    input: [Object, Array],
    minValueNumber: Number
  },
  emits: ['update:modelValue', 'focus', 'blur', 'submit'],
  setup (props, { emit }) {
    const displayPassword = ref(false);
    const timoutId = ref(-1);

    const inputValue = ({ target }) => {
      const expectNumber = props.type === 'number';
      if (!expectNumber) {
        let value = trimStart(target.value);
        if (props.upperFirst) value = upperFirst(value);
        emit('update:modelValue', value);
      } else {
        if (target.value.indexOf('.') !== -1) {
          target.value = target.value.substring(
            0,
            target.value.indexOf('.') + 3
          );
        }
        if (+target.value < props.minValueNumber) {
          timoutId.value = setTimeout(() => {
            target.value = props.minValueNumber;
          }, 600);
        } else {
          clearTimeout(timoutId.value);
        }
        emit(
          'update:modelValue',
          target.value ? numeral(target.value).value() : ''
        );
      }
    };

    const onInput = debounce(inputValue, props.debounceTime);

    const numberMaska = {
      mask: '#*.#*',
      tokens: { Z: { pattern: /[0-9]/ } }
    };

    return {
      inputValue,
      onInput,
      displayPassword,
      numberMaska
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-custom-input {
  @include global-font;
  display: grid;
  grid-template-rows: auto 56px;
  grid-gap: 4px;
  align-items: start;

  &_large {
    grid-gap: 12px;
  }

  &_row {
    grid-template-rows: auto;
  }

  &_label {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
  }

  input {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    padding: 16px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &_offset {
    margin-bottom: 28px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_hide {
      display: none;
    }
  }

  &-filed {
    position: relative;
    min-height: 56px;
    max-height: 56px;

    input {
      height: 56px;
    }

    &_postfix {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 8px;
    }

    &__show-pass {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      padding: 18px 18px;
      box-sizing: content-box;
      cursor: pointer;
      transition: opacity 0.2s ease-in;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
