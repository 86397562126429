<template>
  <div class="lp-crm-analytics">
    <AnalyticsHeader v-model:date="selectedDate" />
    <div
      class="lp-crm-analytics-body"
      :class="{'lp-crm-analytics-body_mobile': isMobile}"
    >
      <ChartLessons
        :mobile="isMobile"
        :total="totalLessons"
        :lessons="currentDataLessons"
        :dates="dateArray"
      />
      <ChartExpenses
        :mobile="isMobile"
        :total="totalExpenses"
        :expenses="currentDataExpenses"
        :category="expenseCategory"
        :percents="percentageExpenses"
        :dates="dateArray"
      />
      <ChartIncome
        :mobile="isMobile"
        :total="totalIncome"
        :lessons="currentDataIncome"
        :dates="dateArray"
      />
      <ChartProfit
        :mobile="isMobile"
        :total="totalProfit"
        :profit="currentDataProfit"
        :dates="dateArray"
      />
    </div>
  </div>
</template>

<script>
import AnalyticsHeader from '@/components/CRM/Analytics/AnalyticsHeader';
import { computed, onBeforeMount, ref, watch } from 'vue';
import ChartLessons from '@/components/CRM/Analytics/Charts/ChartLessons';
import ChartIncome from '@/components/CRM/Analytics/Charts/ChartIncome';
import ChartExpenses from '@/components/CRM/Analytics/Charts/ChartExpenses';
import ChartProfit from '@/components/CRM/Analytics/Charts/ChartProfit';
import AnalyticsApi from '@/api/CRM/analytics';
import ExpensesApi from '@/api/CRM/expenses';
import { last, get, fill, groupBy } from 'lodash';
import moment from 'moment';
import { useStore } from 'vuex';
import timeout from '@/constants/utils/timeout';
import CATEGORY_EXPENSES from '@/constants/enums/categoryExpenses';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Analytics',
  components: { ChartIncome, ChartLessons, ChartExpenses, ChartProfit, AnalyticsHeader },
  setup () {
    const { t } = useI18n();
    const isoDate = 'YYYY-MM-DD';

    const store = useStore();
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const selectedDate = ref([]);
    watch(selectedDate, () => getTotals());
    onBeforeMount(() => getTotals());

    const generateWeek = () => {
      const [start] = selectedDate.value;
      const array = fill(new Array(7), start);
      return array.map((date, index) => {
        const momentDate = moment(date).add(index, 'day');
        return {
          id: momentDate.format('ddd'),
          date: momentDate.format(isoDate)
        };
      });
    };

    const generateMonth = () => {
      const [start] = selectedDate.value;
      const array = fill(new Array(diffDays.value), start);
      return array.map((date, index) => {
        const momentDate = moment(date).add(index, 'day');
        return {
          id: momentDate.format('DD'),
          date: momentDate.format(isoDate)
        };
      });
    };

    const generateYear = () => {
      const array = fill(new Array(12), moment().startOf('year').format());
      return array.map((date, index) => {
        const momentDate = moment(date).add(index, 'month');
        return {
          id: momentDate.format('MMM'),
          date: momentDate.format('MM')
        };
      });
    };

    const generateAll = (counter, lessons) => {
      const tooSmallRange = counter < 6;
      const years = tooSmallRange ? 6 : counter;
      const [start] = Object.keys(lessons);
      const startAlt = moment(start).subtract(years - 2, 'year').format();
      const array = fill(new Array(years), tooSmallRange ? startAlt : start);
      return array.map((date, index) => {
        const momentDate = moment(date).add(index, 'year');
        return {
          id: momentDate.format('YYYY'),
          date: momentDate.format('YYYY')
        };
      });
    };

    const diffDays = computed(() => {
      const [start, end] = selectedDate.value;
      const startDate = moment(start).startOf('day');
      const endDate = moment(end).startOf('day');
      return endDate.diff(startDate, 'days') + 1;
    });

    const dateArray = ref([]);

    const totalLessons = ref(0);
    const totalIncome = ref(0);
    const totalExpenses = ref(0);
    const totalProfit = ref(0);
    const currentDataLessons = ref([]);
    const currentDataIncome = ref([]);
    const currentDataExpenses = ref([]);
    const currentDataProfit = ref([]);
    const expenseCategory = ref([]);
    const percentageExpenses = ref([]);

    const sortByDate = (a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    };

    const getLessonsDate = (lessons, dateParam = 'crmLessonStart') => {
      return lessons.map(lesson => {
        const date = moment(lesson[dateParam]);
        lesson.date = date.format(isoDate);
        lesson.month = date.format('MM');
        lesson.year = date.format('YYYY');
        return lesson;
      }).sort(sortByDate);
    };

    const setLessonsInfo = (dates, lessons, costParams = 'crmLessonCost') => {
      const result = dates.map(item => {
        const lessonsByDate = get(lessons, item.date, []);
        const counter = lessonsByDate.length;
        const income = counter ? lessonsByDate.reduce((sum, el) => sum + (el[costParams] || 0), 0) : 0;
        return { income, counter };
      });
      if (costParams === 'crmLessonCost') {
        currentDataLessons.value = result.map(item => item.counter);
        currentDataIncome.value = result.map(item => item.income);
      } else {
        const incomes = Object.values(currentDataIncome.value);
        currentDataProfit.value = result.map((item, idx) => incomes[idx] - item.income);
      }
    };

    const setLessonsByWeek = (lessons, expenses) => {
      const week = generateWeek();
      dateArray.value = week.map(item => item.id);
      lessons ? setLessonsInfo(week, lessons, 'crmLessonCost') : setLessonsInfo(week, expenses, 'amount');
    };

    const setLessonsByMonth = (lessons, expenses) => {
      const month = generateMonth();
      dateArray.value = month.map(item => item.id);
      lessons ? setLessonsInfo(month, lessons, 'crmLessonCost') : setLessonsInfo(month, expenses, 'amount');
    };

    const setLessonsByYear = (lessons, expenses) => {
      const year = generateYear();
      dateArray.value = year.map(item => item.id);
      lessons ? setLessonsInfo(year, lessons, 'crmLessonCost') : setLessonsInfo(year, expenses, 'amount');
    };

    const setLessonsAll = (lessons, counter, expenses) => {
      const all = generateAll(counter, lessons);
      dateArray.value = all.map(item => item.id);
      lessons ? setLessonsInfo(all, lessons, 'crmLessonCost') : setLessonsInfo(all, expenses, 'amount');
    };

    const calcMonths = (lessons) => {
      const dates = Object.keys(lessons);
      const [start] = dates;
      const end = last(dates);
      const startDate = moment(start).startOf('month');
      const endDate = moment(end).startOf('month');
      return endDate.diff(startDate, 'months') + 1;
    };

    const calcYears = (lessons) => {
      const dates = Object.keys(lessons);
      const [start] = dates;
      const end = last(dates);
      const startDate = moment(start).startOf('years');
      const endDate = moment(end).startOf('years');
      return endDate.diff(startDate, 'years') + 1;
    };

    const setLessons = (lessons) => {
      const lessonsDate = getLessonsDate(lessons, 'crmLessonStart');
      const groupByDate = groupBy(lessonsDate, 'date');
      const diffMonths = calcMonths(groupByDate);
      const diffYears = calcYears(groupByDate);
      if (diffDays.value <= 7) {
        setLessonsByWeek(groupByDate);
      } else if (diffDays.value > 7 && diffDays.value <= 31) {
        setLessonsByMonth(groupByDate);
      } else if (diffMonths <= 12) {
        const groupByMonth = groupBy(lessonsDate, 'month');
        setLessonsByYear(groupByMonth);
      } else {
        const groupByYears = groupBy(lessonsDate, 'year');
        setLessonsAll(groupByYears, diffYears);
      }
    };

    const setNetProfit = (expenses) => {
      const expensesDate = getLessonsDate(expenses, 'expenseDate');
      const groupByDate = groupBy(expensesDate, 'date');
      const diffMonths = calcMonths(groupByDate);
      const diffYears = calcYears(groupByDate);
      if (diffDays.value <= 7) {
        setLessonsByWeek(false, groupByDate);
      } else if (diffDays.value > 7 && diffDays.value <= 31) {
        setLessonsByMonth(false, groupByDate);
      } else if (diffMonths <= 12) {
        const groupByMonth = groupBy(expensesDate, 'month');
        setLessonsByYear(false, groupByMonth);
      } else {
        const groupByYears = groupBy(expensesDate, 'year');
        setLessonsAll(false, diffYears, groupByYears);
      }
    };

    const getTotals = async () => {
      await setLoaderRun(true);
      try {
        const { data } = await AnalyticsApi.getAnalytics(selectedDate.value);
        const { data: dataTotalsByCategory } = await ExpensesApi.getTotalsByCategory(selectedDate.value);
        const { data: dataExpensesAnalytics } = await ExpensesApi.getAnalytics(selectedDate.value);

        const { totalCompleteLessons = 0, totalEarned = 0, lessons = [] } = data;
        const { expensesTotal = 0, groupedCRMExpenses = [] } = dataTotalsByCategory;
        const { totalExpenses: tExpenses = 0, expenses = [] } = dataExpensesAnalytics;

        totalLessons.value = totalCompleteLessons;
        totalIncome.value = totalEarned;
        totalExpenses.value = expensesTotal;
        totalProfit.value = totalEarned - tExpenses;

        currentDataExpenses.value = groupedCRMExpenses.map(item => item.totalIncomeByCategory);
        expenseCategory.value = groupedCRMExpenses.map(item => t(CATEGORY_EXPENSES[item.expenseCategory].title));
        percentageExpenses.value = groupedCRMExpenses.map(item => ({
          ...item,
          expenseCategory: t(CATEGORY_EXPENSES[item.expenseCategory].title)
        }));

        setLessons(lessons);
        setNetProfit(expenses);
        await timeout(1500);
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      totalLessons,
      totalIncome,
      totalExpenses,
      totalProfit,
      selectedDate,
      dateArray,
      currentDataLessons,
      currentDataIncome,
      currentDataExpenses,
      currentDataProfit,
      expenseCategory,
      percentageExpenses
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-analytics {

  &-body {
    padding: 0 28px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media screen and (max-width: 1800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    &_mobile {
      background-color: $color-white;
      padding: 0 0;
    }
  }
}

</style>
