<template>
  <div
    class="lp-crm-students"
    :class="{
      'lp-crm-students_mobile': isMobile && activeTab === 1,
      'lp-crm-students_mobile-group': isMobile && activeTab === 2
    }"
  >
    <div
      class="lp-crm-students-header"
      :class="{'lp-crm-students-header_mobile': isMobile}"
    >
      <div
        class="lp-crm-students-header-tabs"
        v-touch:swipe="swipeTab"
      >
        <div
          class="lp-crm-students-header-tabs__tab"
          :class="{
            'lp-crm-students-header-tabs__tab_active': activeTab === tab.id,
            'lp-crm-students-header-tabs__tab_mobile': isMobile
          }"
          v-for="tab of tabs"
          :key="tab.id"
          @click="setActiveTab(tab.id)"
        >
          {{ $t(tab.title) }}
        </div>
      </div>
      <button
        class="lp-button"
        v-if="!isMobile && activeTab === 1"
        @click.stop="openFormStudent"
      >
        {{ $t('crm.students.table.addStudent') }}
      </button>
      <button
        class="lp-crm-students-header__button"
        v-else-if="isMobile"
        @click.stop="openFormStudent"
      >
        <plusIcon />
      </button>
      <button
        v-if="!isMobile && activeTab === 2"
        class="lp-button lp-crm-groups__button"
        @click="openAddGroupForm"
      >
        {{ $t('crm.students.table.addGroup') }}
      </button>
    </div>

    <div
      v-if="activeTab === 1"
      :key="1"
    >
      <MyStudents />
      <StudentForm
        v-model:open="openForm"
        :student="editableStudent"
        :openForm="openForm"
      />
    </div>

    <MyGroups
      v-else-if="activeTab === 2"
      :key="2"
      :openFormProp="openForm"
      @close-form="closeForm"
      @set-group="setGroup"
      @open-student-card="openStudentCard"
      :currentGroupProp="currentGroup"
      :isMobile="isMobile"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { plusIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';
import MyStudents from '@/components/CRM/Students/MyStudents';
import MyGroups from '@/components/CRM/Groups/MyGroups';
import StudentForm from '@/components/CRM/Students/StudentForm/StudentForm';
import { useRouter } from 'vue-router';

export default {
  name: 'Students',
  components: { plusIcon, MyStudents, MyGroups, StudentForm },
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const openForm = ref(false);
    const openFormStudent = () => {
      openForm.value = true;
    };
    const closeForm = (val) => {
      openForm.value = val;
    };

    const editableStudent = ref(null);

    const openAddGroupForm = () => {
      openForm.value = true;
    };
    watch(openForm, (val) => {
      if (!val) editableStudent.value = null;
    });

    const activeTab = ref(1);
    const savedChange = ref(true);
    const openChangeTimetable = ref(false);

    const tabs = [
      { id: 1, title: 'crm.pages.students', component: MyStudents },
      { id: 2, title: 'crm.pages.groups', component: MyGroups  }
    ];
    const setActiveTab = (id) => {
      if (savedChange.value && id === 1) {
        activeTab.value = id;
      } else if (!savedChange.value) {
        openChangeTimetable.value = true;
      } else {
        activeTab.value = id;
      }
    };

    const swipeTab = () => {
      activeTab.value = activeTab.value === 1 ? 2 : 1;
    };

    const currentGroup = reactive({});

    const setGroup = (data) => {
      currentGroup.value = data;
    };

    const router = useRouter();
    const openStudentCard = async (id) => {
      await router.push({ name: 'CRMStudents', query: { crmStudentId: id } });
      activeTab.value = 1;
    };

    return {
      isMobile,
      openForm,
      editableStudent,
      openFormStudent,
      tabs,
      setActiveTab,
      activeTab,
      swipeTab,
      openAddGroupForm,
      closeForm,
      setGroup,
      currentGroup,
      openStudentCard
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-students {

  &-header {
    position: sticky;
    top: -35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0 20px;
    margin: -35px 0 0;
    background: $color-white;
    z-index: 6;

    &-tabs {
      position: relative;
      display: flex;
      align-items: center;

      &__tab {
        position: relative;
        padding-bottom: 10px;
        margin-right: 36px;
        font-weight: 700;
        font-size: 22px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-gray;
        cursor: pointer;

        &_mobile {
          font-size: 16px;
          font-weight: 800;
          margin-right: 22px;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 2px;
          width: 0;
          background-color: rgba($color-accent, 0.3);
          transition: width 0.2s ease-in-out;
        }

        &:hover:not(&_active) {
          &:after {
            width: 100%;
          }
        }

        &_active {
          color: $color-text;

          &:after {
            width: 100%;
            background-color: $color-accent;
          }
        }
      }
    }

    &_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;
      padding: 16px 17px 16px 56px;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 26px;
      right: 16px;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      padding: 0;
      background-color: $color-accent;
      transition: bottom 0.3s;
      cursor: pointer;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &-cards {
   margin-top: 52px;
   padding-bottom: 106px;

    &_hide {
      opacity: 0;
      transform: scale(0);
      position: absolute;
    }
  }

  &_mobile {
    padding-top: 100px;
  }

  &_mobile-group {
    padding-top: 80px;
  }
}

</style>
