import { emitRtcEvent, sendVideoSettings, setParticipants } from '@/constants/_rtc/utils/helpers';
import SOCKET_EVENTS from '@/constants/enums/events';
import ROLE_TYPES from '@/constants/enums/roles';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

const connectionEvents = (function () {
  return (() => {
    let store;
    let connection;
    let newParticipantCallback = () => {};
    let disconnectCallback = () => {};

    /** This event is fired as soon as someone tries to join you.
     * You can either reject his request or set preferences. */
    const onNewParticipant = (participantId, userPreferences) => {
      setParticipants({ store });

      /** Needed for a 1 to 1 connection. Have problems for iOS */
      // if (participants.value > 1) return;

      userPreferences.localPeerSdpConstraints.OfferToReceiveAudio = true;
      userPreferences.localPeerSdpConstraints.OfferToReceiveVideo = true;
      userPreferences.dontAttachStream = false;
      userPreferences.dontGetRemoteStream = false;
      connection.acceptParticipationRequest(participantId, userPreferences);

      sendVideoSettings(store);
      setTimeout(() => {
        newParticipantCallback();
      }, 3000);
      const liteVersion = store.getters.liteVersion;
      const isTeacher = store.getters.user ? store.getters.user.role === ROLE_TYPES.TEACHER : false;
      if (isTeacher) {
        emitRtcEvent({ event: SOCKET_EVENTS.SET_LITE_CALL, value: liteVersion, store });
        yandexTracker.callStudentJoin();
      }
    };

    /** This event is fired as soon as any of the users leave. */
    const onLeave = ({ userid }) => {
      console.log(userid + ' has left. onleave event fired');
      const arrayStream = store.getters.streams || [];

      const streamByUser = arrayStream.filter(item => item.userId === userid);
      streamByUser.forEach(({ srcObject: stream }) => {
        connection.removeStream(stream.id);
      });

      const newStreams = arrayStream.filter(item => item.userId !== userid);
      store.dispatch('setStreams', newStreams);

      const participantsCount = connection.getAllParticipants().filter(user => user !== userid).length;
      setParticipants({ store, count: participantsCount });
      disconnectCallback();
    };

    /** Use this event to receive all local and remote media streams. */
    const onStream = ({ streamid, stream, type, userid }) => {
      store.dispatch('setStreams', [
        ...store.getters.streams,
        { type, id: streamid, srcObject: stream, userId: userid }
      ]);

      if (type === 'remote') {
        setParticipants({ store });

        // TODO вероятно нужно переделать/убрать playoutDelayHint
        const peer = connection.peers[userid].peer;
        const [audioReceiver, videoReceiver] = peer.getReceivers();
        audioReceiver.playoutDelayHint = 0.5;
        videoReceiver.playoutDelayHint = 0.5;
      }
    };

    /** Use this event to remove inactive (stopped) videos. */
    const onStreamEnded = ({ streamid, stream, userid }) => {
      connection.removeStream(streamid, userid);

      const newStreams = (store.getters.streams || []).filter(item => item.id !== streamid);
      store.dispatch('setStreams', newStreams);
    };

    const onPeerStateChanged = (state) => {
      console.log(state.iceConnectionState);
      if (state.iceConnectionState.search(/closed|failed/gi) !== -1) {
        console.error('Disconnected with: ', state.userid, state.extra, 'state:', state.iceConnectionState);
      }
    };

    const setConnectionEvents = (storeObj, messageHandler) => {
      store = storeObj;
      connection = store.getters.rtcConnect;

      connection.onleave = onLeave;
      connection.onstream = onStream;
      connection.onstreamended = onStreamEnded;
      connection.onNewParticipant = onNewParticipant;
      connection.onmessage = messageHandler;
      connection.onPeerStateChanged = onPeerStateChanged;
      connection.onUserStatusChanged = (event) => {
        console.log('User status has been changed:');
        console.log(event);
      };
    };

    const setNewParticipantCallback = (callback) => {
      newParticipantCallback = callback;
    };
    const setDisconnectCallback = (callback) => {
      disconnectCallback = callback;
    };

    return {
      setConnectionEvents,
      setNewParticipantCallback,
      setDisconnectCallback
    };
  })();
}());

export default connectionEvents;
