<template>
  <div>
    <LPTooltip :text="$t('timetable.notComplete')">
      <div
        class="lp-lesson-not-complete"
      >
        <dangerIcon
          :style="style"
          class="lp-lesson-not-complete__icon"
        />
      </div>
    </LPTooltip>
  </div>
</template>

<script>
import { dangerIcon } from '@/constants/icons/crm';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'LessonNotComplete',
  components: {
    LPTooltip,
    dangerIcon
  },
  props: {
    style: Object
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-lesson-not-complete {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__icon {
    width: 100%;
    height: 100%;
  }
}

</style>
