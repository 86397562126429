import UAParser from 'ua-parser-js';

const parser = new UAParser();
const browser = parser.getBrowser();
const device = parser.getDevice();
const os = parser.getOS();

console.table({
  os: os.name,
  browser: browser.name,
  vendor: device.vendor,
  type: device.type
});

const isMacOS = os.name === 'Mac OS';
const isIOS = device.vendor === 'Apple' && (device.type === 'tablet' || device.type === 'mobile');
const isOtherMobile = device.vendor !== 'Apple' && (device.type === 'tablet' || device.type === 'mobile');
const isSafari = (browser.name === 'Safari') || (browser.name === 'Mobile Safari');
const isChrome = (browser.name === 'Chrome') || (browser.name === 'Chrome WebView');

const unsupportedMacOs = isMacOS && !isSafari && !isChrome;
const unsupportedIOS = isIOS && !isSafari;
const unsupportedMobile = isOtherMobile && !isChrome;
const unsupportedOther = !isOtherMobile && !isIOS && !isMacOS && !isChrome;

export default () => {
  return unsupportedIOS || unsupportedMobile || unsupportedMacOs || unsupportedOther;
};

export { unsupportedIOS, unsupportedMobile, unsupportedMacOs, unsupportedOther };
