<template>
  <div
    class="section contingent"
    id="contingent"
  >
    <div class="contingent__container">
      <div class="contingent__title">
        {{ $t('landing.contingent.name.first') }}
        <span> {{ $t('landing.contingent.name.second') }} </span>
      </div>
      <div class="contingent__items">
        <div
          class="contingent-item"
          v-for="(item, index) of contingent"
          :key="item.id"
        >
          <div class="contingent-item__image">
            <img
              :src="
                require(`@/assets/images/landing/contingent/${item.image}.jpg`)
              "
              alt="user"
            />
          </div>
          <div
            class="contingent-item__text"
            :class="{
              'contingent-item__last-text': index === contingent.length - 1
            }"
          >
            {{ $t(item.name) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contingent from '@/constants/landing/contingent';

export default {
  name: 'Contingent',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    return {
      contingent
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.contingent {
  width: 100%;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: calc(100% - 10px);
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    text-align: center;

    span {
      color: $color-purple;
      line-height: 100%;
      text-justify: center;
    }
  }

  &__content {
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__items {
    margin-top: 50px;
    position: relative;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 28px;
  }
}

.contingent-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.04);
  border-radius: 8px;

  &__image {
    padding: 0;

    img {
      border-radius: 8px;
    }
  }
  &__text {
    position: absolute;
    bottom: 16px;
    left: 16px;
    color: $color-white;
    background-color: $color-purple;
    padding: 0 10px;
    border-radius: 4px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
  }

  &__last-text {
    position: absolute;
    width: 100%;
    bottom: 45px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-black;
    background-color: transparent;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }
}
</style>
