import * as types from '@/constants/mutationTypes';

const state = {
  countryCode: ''
};
const getters = {
  countryCode: (state) => state.countryCode
};
const actions = {
  setCountryCode ({ commit }, data) {
    commit(types.SET_COUNTRY_CODE, { data });
  }
};
const mutations = {
  [types.SET_COUNTRY_CODE] (state, { data }) {
    state.countryCode = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
