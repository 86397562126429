<template>
  <div
    class="lp-file-delete"
    :class="{
      'lp-file-delete_fixed': position && !mobile,
      'lp-file-delete_mobile': mobile
    }"
    :style="mobile ? {} : checkedPosition"
    ref="menuEl"
    v-click-outside="closeMenu"
  >
    <div class="lp-file-delete-body">
      <p>{{ question }}</p>
      <p>{{ `«${file.name || file.originalName}»?` }}</p>
    </div>
    <div class="lp-file-delete-footer">
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="closeMenu"
      >
        {{ $t('global.no') }}
      </button>
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="acceptDelete"
      >
        {{ $t('global.yes') }}
      </button>
    </div>
    <teleport
      to="#app"
      v-if="mobile"
    >
      <transition name="fade">
        <div
          class="lp-file-delete__background"
          @click="closeMenu"
        />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FileAcceptDelete',
  props: {
    mobile: Boolean,
    position: Object,
    file: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['accept', 'update:status'],
  setup (props, { emit }) {
    const closeMenu = () => {
      emit('update:status', false);
    };

    const acceptDelete = () => {
      closeMenu();
      emit('accept');
    };

    const menuEl = ref(null);
    const checkedPosition = computed(() => {
      if (!props.position || !menuEl.value) return {};
      const {
        offsetHeight,
        offsetWidth
      } = menuEl.value;
      const {
        left,
        top
      } = props.position;
      const {
        innerWidth,
        innerHeight
      } = window;
      const style = {
        top: `${top}px`,
        left: `${left}px`
      };
      if (innerWidth < (left + offsetWidth)) {
        style.left = `${left - offsetWidth}px`;
      }
      if (innerHeight < (top + offsetHeight)) {
        style.top = `${top - offsetHeight}px`;
      }
      return style;
    });

    const { t } = useI18n();

    const question = computed(() => {
      const firstText = t('myFiles.deleteQuestion');
      const typeFile = props.file.mimetype ? 'deleteFile' : 'deleteFolder';
      return `${firstText} ${t(`myFiles.${typeFile}`)}`;
    });

    return {
      closeMenu,
      acceptDelete,
      menuEl,
      checkedPosition,
      question
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-file-delete {
  @include global-font;
  position: absolute;
  display: grid;
  grid-gap: 16px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  padding: 16px 18px;
  z-index: 10;

  &_fixed {
    position: fixed;
  }

  &_mobile {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: calc(100vw - 32px);
    z-index: 102;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color-black, 0.5);
  }

  &-body {
    display: grid;
    grid-gap: 4px;
    align-items: center;
    text-align: center;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

</style>
