<template>
  <div
    class="lp-group-select"
    :class="{
      'lp-group-select_large': largeLabel,
      'lp-group-select_string-style': stringStyle
    }"
    v-click-outside="isMobile ? () => {} : closeDropList"
  >
    <span
      class="lp-label lp-group-select__label"
      :class="{
        'lp-label_large': largeLabel,
        'lp-label_required': required,
        'lp-label_required_without-label': required && withoutLabel
      }"
      v-if="!stringStyle && !selectNoLabel"
    >
      {{ withoutLabel ? '' : $t(label || 'placeholder.select') }}
    </span>
    <div
      class="lp-group-select__title"
      @click="changeOpenDropList(!openDropList)"
    >
      <div class="lp-group-select__icon">
        <groupIcon />
      </div>
      <div v-if="selectedValue[keyProp] || searchItem">
        {{ translate ? $t(selectedValue[keyProp]) : selectedValue[keyProp] || placeholder }}
      </div>
      <div
        v-else
        class="lp-group-select__title"
      >
        {{ isListEmpty && currentOptions && currentOptions.length ? $t('crm.students.table.needToAddGroup') :
          $t('crm.students.table.emptyGroupsList') }}
      </div>
      <closeModalIcon
        @click="$emit('clear-group-input')"
        class="lp-group-select__cross"
      />
    </div>
    <input
      v-if="searchable && openDropList && !isMobile || (currentOptions && currentOptions.length && searchItem)"
      :disabled="isMobile"
      @keyup.esc="closeDropList"
      @click.stop
      v-focus="openDropList"
      class="lp-group-select-header__input"
      :class="{
        'lp-group-select-header__input_start-search': openDropList,
        'lp-group-select-header__input_searching': searchItem,
        'lp-group-select-header__input_start-search_group': !isMobile
      }"
      type="text"
      v-model="searchItem"
    >
    <div
      v-if="selectedValue[keyProp]"
      ref="selectHeader"
      class="lp-group-select-header"
      :class="{
        'lp-input_error': error,
        'lp-input': !stringStyle,
        'lp-group-select-header_string-style': stringStyle,
        'lp-group-select-header_no-label': selectNoLabel
      }"
      @click="changeOpenDropList(!openDropList)"
    >
      <slot
        :keyProp="keyProp"
        :value="selectedValue"
        name="header"
      >
        <div
          v-for="element in groupElementsList"
          :key="element._id"
          class="lp-group-select-header__item"
        >
          <div class="lp-group-select-header__image">
            <img
              class="lp-group-select-header__photo"
              v-if="element.uploadPhotoUrl"
              :alt="element.uploadPhotoUrl"
              :src="element.uploadPhotoUrl"
            />
            <userIcon
              v-else
              class="lp-group-select-header__photo"
            />
          </div>
          <div class="lp-group-select-header__name">
            {{ element.fullName }}
          </div>
        </div>
        <div
          v-if="selectedValue && selectedValue.crmStudents && selectedValue.crmStudents.length > 4"
          @click.stop="showAll = !showAll"
          class="lp-group-select__rest"
        >
          <div v-if="!showAll">
            {{ $t('crm.lessons.form.moreStudents') }} {{ restElementsCount }}
          </div>
          <div
            v-else
            class="lp-group-select__hide"
          >
            <hideIcon />
            {{ $t('crm.lessons.form.lessStudents') }}
          </div>
        </div>
      </slot>
      <transition name="bounce">
        <span
          class="lp-error"
          v-if="error && typeof error === 'string'"
        >
          <dangerIcon />
          {{ $t(error) }}
        </span>
      </transition>
    </div>
    <teleport to="#app">
      <transition name="fade">
        <div
          class="lp-group-select-list__background"
          v-if="isMobile && openDropList"
          @click="closeDropList"
        />
      </transition>
    </teleport>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition :name="isMobile ? 'slide-select-mobile' : 'slide-select'">
        <div
          v-if="openDropList && currentOptions && currentOptions.length"
          class="lp-group-select-list"
          :class="{
            'lp-group-select-list_mobile': isMobile,
            'lp-group-select-list_mobile_search': isMobile && searchable,
            'lp-group-select-list_width': widthAuto,
            'lp-group-select-list_top': topOffset && !isMobile
          }"
          @click.stop
          :options="scrollbarSettings"
          :style="[
            getStyleDropList(),
            {
              'maxHeight': maxHeight,
              'minHeight': minHeight
            }
          ]"
        >
          <div
            v-if="!currentOptions.length && keyProp === 'fullName'"
            class="lp-group-select-list_not-found"
          >
            {{ $t('crm.lessons.form.notFoundStudent') }}
          </div>
          <div
            class="lp-group-select-header-mobile-search"
            v-if="searchable && openDropList && isMobile"
            @click.stop
          >
            <input
              @keyup.esc="closeDropList"
              @click.stop
              v-focus="!isMobile && openDropList"
              class="lp-group-select-header-mobile-search__input lp-input"
              type="text"
              :placeholder="$t(placeholder)"
              v-model="searchItem"
            >
            <searchIcon class="lp-group-select-header-mobile-search__icon" />
          </div>
          <div
            class="lp-group-select-list-item"
            :class="{
              'lp-group-select-list-item_mobile': isMobile,
              'lp-group-select-list-item-add': item._id === 'createUser',
              'lp-group-select-list-item-add_lower': item._id === 'createUser' && isMobile,
              'lp-group-select-list-item-add_mt': searchItem && isMobile
            }"
            v-for="(item, index) of currentOptions"
            :key="`key-${index}-${item.keyProp}`"
            @click.stop="setSelected(item)"
            :style="item.style"
          >
            <slot
              name="item"
              :keyProp="keyProp"
              :item="item"
            >
              {{ translate ? $t(item[keyProp]) : item[keyProp] }}
            </slot>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { ref, onBeforeMount, watch, computed } from 'vue';
import { dangerIcon, hideIcon } from '@/constants/icons';
import { cloneDeep } from 'lodash';
import MobileDetect from 'mobile-detect';
import { searchIcon, userIcon, groupIcon, closeModalIcon } from '@/constants/icons';

export default {
  name: 'CustomGroupSelect',
  components: {
    dangerIcon,
    searchIcon,
    userIcon,
    groupIcon,
    closeModalIcon,
    hideIcon
  },
  props: {
    stringStyle: Boolean,
    translate: Boolean,
    maxHeight: String,
    minHeight: String,
    largeLabel: Boolean,
    keyProp: String,
    options: Array,
    label: String,
    modelValue: Object,
    required: Boolean,
    searchable: Boolean,
    error: [Boolean, String],
    withoutLabel: Boolean,
    fixed: Boolean,
    stopFullScreen: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    selectNoLabel: Boolean,
    widthAuto: Boolean,
    topOffset: Boolean,
    notHideList: Boolean,
    showList: Boolean
  },
  emits: ['focus', 'handler-change', 'clear-group-input', 'update'],
  setup (props, { emit }) {
    const scrollbarSettings = {
      wheelPropagation: false,
      minScrollbarLength: 25
    };

    const currentOptions = ref(cloneDeep(props.options));
    watch(props, () => {
      currentOptions.value = cloneDeep(props.options);
    });

    const openDropList = ref(false);
    watch(openDropList, (val) => {
      const el = document.querySelector('#app');
      const body = document.querySelector('body');
      if (!el || !body || !isMobile.value) return;
      el.style.overflow = val ? 'hidden' : 'visible';
      body.style.overflow = val ? 'hidden' : 'visible';
      el.style.height = val ? '90vh' : '';
    });
    const changeOpenDropList = (val) => {
      openDropList.value = val;
      emit('focus');
    };
    const closeDropList = () => {
      openDropList.value = false;
    };

    const selectHeader = ref(null);
    const getStyleDropList = () => {
      if (!selectHeader.value || !props.fixed || isMobile.value) return {};
      const position = selectHeader.value.getBoundingClientRect();
      return {
        position: 'fixed',
        top: `${4 + position.top + position.height}px`,
        left: `${position.left}px`,
        width: `${position.width}px`
      };
    };

    const searchItem = ref('');
    const filterOptions = () => {
      if (!searchItem.value) {
        currentOptions.value = cloneDeep(props.options);
      } else {
        const search = searchItem.value.toLowerCase();
        const result = cloneDeep(props.options).filter((item) => {
          const value = String(item[props.keyProp]).toLowerCase();
          return value.includes(search);
        });
        try {
          const reg = new RegExp(`^${search}`, 'i');
          result.sort((a, b) => reg.test(b[props.keyProp]) - reg.test(a[props.keyProp]));
          currentOptions.value = result;
        } catch (e) {
          currentOptions.value = cloneDeep(props.options);
        }
      }
    };
    watch(searchItem, filterOptions);

    const selectedValue = ref({});
    const setSelected = (item) => {
      openDropList.value = false;
      if (!item) return;
      const { handlerChange } = item;
      searchItem.value = '';
      if (!props.notHideList) {
        openDropList.value = false;
      }
      if (handlerChange) {
        handlerChange(item);
      } else {
        selectedValue.value = item;
        emit('handler-change', item.crmStudents);
        emit('update', item.crmStudents);
      }
    };

    const showAll = ref(false);
    const groupLimitedNumber = ref(4);
    const groupElementsList = computed(() => {
      const hasSelectedValue = selectedValue.value && selectedValue.value.crmStudents &&
          selectedValue.value.crmStudents.length && selectedValue.value.crmStudents.length;
      return hasSelectedValue && !showAll.value ? selectedValue.value.crmStudents.slice(0, groupLimitedNumber.value) :
        selectedValue.value.crmStudents;
    });
    const restElementsCount = computed(() => {
      return selectedValue.value && selectedValue.value.crmStudents && groupElementsList.value ?
        selectedValue.value.crmStudents.length - groupElementsList.value.length : null;
    });

    const showListByDefault = computed(() => {
      return props.showList && currentOptions.value;
    });

    const isListEmpty = computed(() => {
      return !(selectedValue.value && selectedValue.value._id);
    });

    watch(showListByDefault, () => {
      openDropList.value = true;
    });

    onBeforeMount( () => {
      setSelected(props.modelValue);
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => props.stopFullScreen ? false : !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      scrollbarSettings,
      selectedValue,
      openDropList,
      selectHeader,
      searchItem,
      currentOptions,
      getStyleDropList,
      setSelected,
      changeOpenDropList,
      closeDropList,
      groupElementsList,
      restElementsCount,
      groupLimitedNumber,
      showAll,
      isListEmpty
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-group-select {
  @include global-font;
  position: relative;
  display: grid;
  grid-gap: 4px;
  color: $color-text;
  user-select: none;

  &_large {
    grid-gap: 12px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 8px 0;
    cursor: pointer;
    position: relative;
    word-break: break-word;
  }

  &__title {
    padding-right: 15px;
  }

  &__cross {
    position: absolute;
    right: 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-moon-raker;
  }

  &__rest {
    font-size: 14px;
    color: $color-gray;
    cursor: pointer;
  }

  &__hide {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
    }
  }

  &-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    min-height: 42px;
    padding: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    transition: color 0.3s ease-in, font-size 0.3s ease-in, padding 0.3s ease-in;
    border: 1px dashed $color-mauve;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &__photo {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    &__image {
      img {
        border-radius: 50%;
      }
    }

    &__name {
      word-break: break-word;
      line-height: 20px;
    }

    &_string-style {
      font-size: 14px;
      color: $color-accent;
      padding: 0;
    }

    &_no-label {
      border: none;

      @media (max-width: 990px) {
        background: transparent;
      }
    }

    &_status-complete {
      opacity: 0.7;
      pointer-events: none;
    }

    &:hover &__icon{
      opacity: 0.8;
    }

    &__icon {
      justify-self: end;
      padding: 11px;
      margin: -11px;
      grid-column: 2;
      box-sizing: content-box;
      transform: rotate(180deg);
      transition: transform 0.15s ease-in;

      &_open {
        transform: rotate(0deg);
      }
    }

    &-mobile-search {
      position: sticky;
      top: -4px;
      width: auto;
      padding: 8px 24px 0;
      background-color: $color-white;
      display: flex;
      align-items: center;
      z-index: 11;

      &__input {
        height: 42px;
        padding-left: 32px;

        &_width {
          width: auto;
        }
      }

      &__icon {
        position: absolute;
        left: 36px;
        top: auto;
      }
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      padding: 11px 14px 11px 12px;
      width: 100%;
      height: 100%;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      opacity: 0;
      border: none;

      &_start-search {
        opacity: 0.5;

        &_group {
          position: initial;
          height: 40px;
          border: 1px solid $color-gallery;
          border-radius: 4px;
        }
      }

      &_searching {
        opacity: 1;
      }
    }
  }

  &-list {
    @include global-font;
    position: absolute;
    top: 115px;
    left: 0;
    display: grid;
    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
    height: auto;
    padding-bottom: 4px;
    background: $color-white;
    box-shadow: 0 0 0 1px $color-wild-sand, 0 0 15px rgba($color-black, 0.05);
    border-radius: 6px;
    z-index: 100;
    overflow: auto;
    max-height: 194px;

    &_width {
      width: auto;
    }

    &_mobile {
      position: fixed;
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      z-index: 103;
      border-radius: 0;
      grid-template-rows: repeat(auto-fit, 38px);

      &_search {
        grid-template-rows: 50px repeat(auto-fit, 38px);
      }

      &_height {
        min-height: 300px;
      }
    }

    &_not-found {
      padding: 10px 16px;
      font-size: 14px;
    }

    &__background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: rgba($color-black, 0.5);
    }

    &-item {
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      padding: 8px 16px;
      box-sizing: border-box;
      border-radius: 3px;
      margin: 0 8px;
      background-color: $color-white;
      transition: color 0.3s ease-in, background-color 0.3s ease-in;
      cursor: pointer;
      word-break: break-word;

      &:hover {
        color: $color-accent;
        background-color: $color-moon-raker;
      }

      &_mobile {
        padding: 12px 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-add {
        position: sticky;
        top: -1px;
        width: auto;
        background-color: $color-white;
        z-index: 10;

        &_lower {
          top: 45px;
        }

        &_mt {
          margin-top: 15px;
        }
      }
    }
  }
}

.lp-multiselect .lp-group-select {
  &-header {
    display: block;

    &__icon {
      display: none;
    }
  }

  &-list {
    top: calc(100% + 10px);
  }
}

.lp-group-select-list {
  &_top {
    top: calc(100% + 22px) !important;
  }
}
</style>
