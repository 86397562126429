<template>
  <SupportBrowser v-if="unsupportedBrowser" />
  <NoMediaDevices
    v-else-if="isRoomLimit || (isVisibleDeviceModalError && devicesText)"
    v-model:openModal="isVisibleDeviceModalError"
    v-model:devicesText="devicesText"
    @detectDevices="getLocalStream({})"
    :supportWebrtc="supportWebrtc"
    :withoutClose="true"
    :isRoomLimit="isRoomLimit"
  />
  <VideoCallSelectType
    v-else-if="!selectedType && isTeacher"
    :room="roomId"
    v-model:select="selectedType"
  />
  <WaitingRoom
    v-if="!isRoomLimit && previewRoom && localStream && detectedDelay"
    :selectedType="selectedType"
    :isTeacher="isTeacher"
    v-model:preview="previewRoom"
  />
  <Loader
    v-if="!detectedDelay && !unsupportedBrowser && !isRoomLimit && !isVisibleDeviceModalError"
    :block="true"
  />
  <div
    class="lp-video"
    :class="{'lp-video_student': !isTeacher}"
    v-if="!previewRoom && !isRoomLimit && isRoomLimitChecked"
  >
    <div
      class="lp-video__left"
      v-if="!closedRoom"
    >
      <div class="lp-video-room-container">
        <VideoRemoteStream
          :isTeacher="isTeacher"
          :mirror="videoSettings.mirrorRemote"
          :displayLoader="!devicesText"
          :switchVideo="switchVideo"
          v-model:openMobileSlider="openMobileSlider"
        />
        <VideoLocalStream
          :openMobileSlider="openMobileSlider"
          :isTeacher="isTeacher"
          :switchVideo="switchVideo"
          v-model:switchVideo="switchVideo"
        />
      </div>
    </div>
    <VideoCallClosed
      v-if="!isTeacher"
      v-model:open="closedRoom"
      :image="closeLessonImg"
      :open="closedRoom"
    />
  </div>
  <audio
    id="sound-effect-connected"
    :src="silence"
  />
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, onMounted, onBeforeUnmount, watch, inject, provide, reactive } from 'vue';
import { VueCookieNext } from 'vue-cookie-next';
import { useStore } from 'vuex';
import ROLE_TYPES from '@/constants/enums/roles';
import VideoLocalStream from '@/components/VideoCall/VideoCallRender/VideoLocalStream';
import VideoRemoteStream from '@/components/VideoCall/VideoCallRender/VideoRemoteStream';
import { FRONTEND_URL } from '@/constants/domains';
import SOCKET_EVENTS from '@/constants/enums/events';
import EventsApi from '@/api/VideoCall/api';
import VideoCallClosed from '@/components/VideoCall/VideoCallModals/VideoCallClosed';
import closeLessonImg from '@/assets/images/close_lesson.png';
import {
  emitRtcEvent,
  endVideoStream,
  getDeviceError,
  sendVideoSettings,
  setVideoSettings
} from '@/constants/_rtc/utils/helpers';
import { videoConstraints, audioConstraints } from '@/constants/_rtc/utils/initConnection';
import NoMediaDevices from '@/components/VideoCall/VideoCallModals/NoMediaDevices';
import { useI18n } from 'vue-i18n';
import WaitingRoom from '@/components/VideoCall/WaitingRoom/WaitingRoom';
import checkWebrtcSupport from '@/constants/_rtc/utils/checkWebrtcSupport';
import connectionEvents from '@/constants/_rtc/utils/connectionEvents';
import connectionCustomEvents from '@/constants/_rtc/utils/connectionCustomEvents';
import hideJivoChat from '@/constants/utils/hideJivoChat';
import { addZoomClass, removeZoomClass } from '@/constants/utils/addZoomClass';
import VideoCallSelectType from '@/components/VideoCall/VideoCallSelectType/VideoCallSelectType';
import CALL_TYPES from '@/constants/enums/callTypes';
import SupportBrowser from '@/components/VideoCall/VideoCallModals/SupportBrowser';
import isUnsupportedBrowser from '@/constants/_rtc/utils/isUnsupportedBrowser';
import rtcDataConnection from '@/constants/_rtc/utils/rtcDataConnection';
import audioEngine from '@/constants/_rtc/audioEngine/audioEngine';
import Loader from '@/components/Main/Loader/Loader';
import silence from '@/assets/sounds/silence.mp3';

export default {
  name: 'VideoCallPage',
  components: {
    SupportBrowser,
    VideoCallSelectType,
    WaitingRoom,
    NoMediaDevices,
    VideoCallClosed,
    VideoRemoteStream,
    VideoLocalStream,
    Loader
  },
  setup () {
    const route = useRoute();
    const router = useRouter();
    const socket = inject('socket');
    const switchVideo = ref(false);

    provide('audioEngine', audioEngine);
    provide('rtcDataConnection', rtcDataConnection);

    const roomId = computed(() => route.params.roomId);
    let roomIdDatabase = '';
    const roomLink = computed(() => `${FRONTEND_URL}${route.path}`);

    const store = useStore();

    const isRoomLimit = computed(() => store.getters.limitRoom);
    const isRoomLimitChecked = computed(() => store.getters.checkedLimit);
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);

    const connection = computed(() => store.getters.rtcConnect);
    rtcDataConnection.init(audioEngine);

    watch([connection], () => {
      connectionEvents.setConnectionEvents(store, rtcDataConnection.handleMessage);
      connectionEvents.setNewParticipantCallback(newParticipant);
      connectionEvents.setDisconnectCallback(rtcDataConnection.studentDisconnected);
    });

    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);

    const participants = computed(() => store.getters.participants);
    watch(participants, () => {
      console.log('Participants: ');
      console.log(participants.value);
      emitRtcEvent({
        store,
        event: SOCKET_EVENTS.CHANGE_PARTICIPANTS,
        value: {}
      });
      emitRtcEvent({ event: SOCKET_EVENTS.SET_MOBILE, value: isMobile.value, store });
      sendVideoSettings(store);
      resetCamera();
    });

    const endVideoCall = async () => {
      await endVideoStream(store);
    };

    const closedRoom = computed(() => store.getters.closedRoom);
    const setLiteVersion = (data) => store.dispatch('setLiteVersion', data);

    socket.on('sendcalltyperequest', ({ roomId: id, callType }) => {
      if (id !== roomId.value || isTeacher.value) return;
      selectedType.value = true;
      setLiteVersion(callType === CALL_TYPES.BASIC);
    });

    socket.on('sendcalltyperesponse', ({ roomId: id }) => {
      if (id !== roomId.value || !isTeacher.value || !selectedType.value) return;
      socket.emit('requestcalltype', {
        roomId: roomId.value,
        callType: liteVersion.value ? CALL_TYPES.BASIC : CALL_TYPES.PREMIUM
      });
    });

    socket.on('roomcheck', (id) => {
      if (id !== roomIdDatabase) return;
      /** empty room check */
      socket.emit('roomscheckanswer', id);
    });

    socket.on('closetoolroom', () => {
      /** Free time is up.
       * @link endVideoCall - Call for stop streaming */
    });

    const videoSettings = computed(() => store.getters.videoSettings);

    const joinRoomCallback = (afterWatch) => {
      const { userid, isInitiator } = connection.value;
      store.dispatch('setConnectUserId', userid);
      setLoaderRun(false);
      connectionCustomEvents.setCustomEvents(store);
      if (isInitiator === true) {
        VueCookieNext.setCookie('owner_room', userid);
        VueCookieNext.setCookie('initiator', user.value._id);
        VueCookieNext.setCookie('owner_room_id', String(roomId.value));
        store.dispatch('setCheckedLimit', true);
      }
      emitRtcEvent({ event: SOCKET_EVENTS.SET_MOBILE, value: isMobile.value, store });
      sendVideoSettings(store);
      setLoaderRun(false);

      if (!afterWatch) {
        emitRtcEvent({ event: SOCKET_EVENTS.ON_CONNECTED, value: user.value, store });
      }
    };

    const newParticipant = () => {
      console.log('New Participant connected');
      if (isTeacher.value) {
        rtcDataConnection.studentConnected();
      }
    };

    // document.addEventListener('visibilitychange', function () {
    //   // console.log(document.hidden, document.visibilityState);
    //   if (!document.hidden) {
    //     // getLocalStream({ audioId: audioSettings.value.deviceId, videoId: videoSettings.value.deviceId });
    //     // if (isMobile.value) {
    //     //   resetLocalStream();
    //     // }
    //   }
    // }, false);
    const resetCamera = () => {
      connection.value.attachStreams.forEach(stream => {
        stream.getVideoTracks().forEach(track => {
          track.enabled = false;
        });
      });
      connection.value.attachStreams.forEach(stream => {
        stream.getVideoTracks().forEach(track => {
          track.enabled = true;
        });
      });
      setTimeout(() => {
        connection.value.attachStreams.forEach(stream => {
          stream.getVideoTracks().forEach(track => {
            track.enabled = false;
          });
        });
        connection.value.attachStreams.forEach(stream => {
          stream.getVideoTracks().forEach(track => {
            track.enabled = true;
          });
        });
      }, 5000);
    };

    const connectUserId = computed(() => store.getters.connectUserId);
    watch(() => connectUserId.value, (newId, oldId) => {
      if (newId && newId === oldId) return;
      joinRoomCallback(true);
    });

    const openOrJoin = async () => {
      if (!isTeacher.value) {
        await joinRoomCallback();
      } else {
        try {
          const { data: { _id: id } } = await EventsApi.openRoom();
          await joinRoomCallback();
          localStorage.setItem('active_room', id);
          roomIdDatabase = id;
        } catch (err) {
          await setLoaderRun(false);
          return err;
        }
      }
    };

    const joinRoom = async () => {
      audioEngine.start();
      if (VueCookieNext.getCookie('token') && !user.value._id) return;
      await setLoaderRun(true);
      isTeacher.value = user.value ? user.value.role === ROLE_TYPES.TEACHER : false;
      connection.value.socketMessageEvent = roomId.value;
      connection.value.addStream(localStream.value);
      connection.value.extra = {
        joinedAt: Date.now(),
        isTeacher: isTeacher.value
      };
      connection.value.openOrJoin(roomId.value, openOrJoin);
      if (isTeacher.value) {
        emitRtcEvent({ event: SOCKET_EVENTS.SET_LITE_CALL, value: liteVersion.value, store });
      }
    };

    const windowUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
      endVideoCall();
    };

    const unsupportedBrowser = isUnsupportedBrowser();
    const supportWebrtc = computed(() => !!checkWebrtcSupport());
    const devices = reactive({ microphone: false, webcam: false });
    const isVisibleDeviceModalError = ref(false);

    const { t } = useI18n();
    const devicesText = computed(() => {
      const error = getDeviceError(devices);
      return error ? t(error) : error;
    });
    watch(devicesText, (val) => isVisibleDeviceModalError.value = !!val);

    const localStream = computed(() => store.getters.localStream);
    const setLocalStream = (data) => store.dispatch('setLocalStream', data);

    const audioSettings = computed(() => store.getters.audioSettings);

    // watch(() => audioSettings.value.deviceId, (newDevice, oldDevice) => {
    //   if (newDevice && oldDevice && newDevice !== oldDevice) {
    //     console.log('New audio Device (watch)');
    //     // getLocalStream({ audioId: newDevice, videoId: videoSettings.value.deviceId });
    //   }
    // });

    watch(() => videoSettings.value.deviceId, (newDevice, oldDevice) => {
      if (newDevice && oldDevice && newDevice !== oldDevice) {
        console.log('New video Device (watch)');
        // getLocalStream({ videoId: newDevice, audioId: audioSettings.value.deviceId });
      }
    });

    const getLocalStream = async ({ audioId, videoId }) => {
      await setLoaderRun(true);
      if (localStream.value && connection?.value?.attachStreams) {
        connection.value.attachStreams.forEach(function (localStream) {
          localStream.stop();
        });
        connection.value.removeStream({
          video: true,
          audio: true
        });
      }
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          // audio: audioId ? { ...audioConstraints, deviceId: audioId } : audioConstraints,
          // video: videoId ? { ...videoConstraints, deviceId: videoId } : videoConstraints
          audio: audioConstraints,
          video: videoConstraints
        });
        console.log('Getting User Media');
        console.log(stream);
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log(devices);
        const [{ deviceId: audioDeviceId }] = devices.filter(d => d.kind === 'audioinput');
        const [{ deviceId: videoDeviceId }] = devices.filter(d => d.kind === 'videoinput');
        audioSettings.value.deviceId = audioId || audioDeviceId;
        videoSettings.value.deviceId = videoId || videoDeviceId;
        devices.microphone = !!stream.getAudioTracks().length;
        devices.webcam = !!stream.getVideoTracks().length;
        isVisibleDeviceModalError.value = !devices.microphone || !devices.webcam;
        audioEngine.setInputStream(stream);
        const audioStreamOut = audioEngine.streamAudioMixer.createAudioGraph(stream);
        const [audioStream] = audioStreamOut.getAudioTracks();
        const [videoStream] = stream.getVideoTracks();
        const newLocalStream = new MediaStream([videoStream, audioStream]);
        await setLocalStream(newLocalStream);
        if (!previewRoom.value) connection.value.addStream(newLocalStream);
        await setLoaderRun(false);
        checkValueDelaySetting();
      } catch (err) {
        isVisibleDeviceModalError.value = !devices.microphone || !devices.webcam;
        // devices.microphone = false;
        // devices.webcam = false;
        await setLoaderRun(false);
        console.error(err);
      }
    };

    // let clonedStream = null;
    // const resetLocalStream = async () => {
    //   console.log('Resetting local stream');
    //   await setLoaderRun(true);
    //   if (clonedStream) connection.value.removeStream(clonedStream.id);
    //   if (!localStream.value) {
    //     await setLoaderRun(false);
    //     return;
    //   }
    //   await timeout(500);
    //   clonedStream = await localStream.value.clone();
    //   if (!previewRoom.value) {
    //     connection.value.removeStream(localStream.value.id);
    //     connection.value.addStream(clonedStream);
    //   }
    //   await setLoaderRun(false);
    // };

    // const visibilityChange = getVisibilityDocument();
    // const visibilityDocumentChange = async () => {
    //   console.log('visibilityChange old');
    //   console.log(!document[visibilityChange.hidden]);
    //   // if (!document[visibilityChange.hidden]) {
    //   //   await resetLocalStream();
    //   // }
    // };

    const devicesList = ref(null);
    // watch(devicesList, async (newList, oldList) => {
    //   if (!newList || !oldList) return;
    //   // if (newList.length !== oldList.length) {
    //   //   await resetLocalStream();
    //   // }
    // });

    const setWatchDevices = async () => {
      devicesList.value = await navigator.mediaDevices.enumerateDevices();
      navigator.mediaDevices.addEventListener('devicechange', async () => {
        devicesList.value = await navigator.mediaDevices.enumerateDevices();
      });
    };

    const isMobile = computed(() => store.getters.isMobile);

    const startLocalStream = async () => {
      await setVideoSettings;
      await getLocalStream({});
      await setWatchDevices();
    };

    const eventsPrevent = ['touchmove'];
    const preventDefaultEvent = (e) => {
      const classList = (e.path || []).map(node => node.className).flat().join(' ');
      if (classList.includes('lp-modal')) return;
      e.preventDefault();
    };

    const onClicked = ref(false);
    const addSoundEffect = () => {
      const audio = document.getElementById('sound-effect-connected');
      if (!audio || onClicked.value) return;
      audio.muted = false;
      audio.autoplay = true;
      audio.play();
      onClicked.value = true;
    };

    onMounted(async () => {
      localStorage.removeItem('calibration_room_id');
      if (unsupportedBrowser) return;
      socket.emit('returncalltype', { roomId: roomId.value });
      if (!supportWebrtc.value) {
        isVisibleDeviceModalError.value = true;
        return;
      }
      removeZoomClass();
      await startLocalStream();
      window.addEventListener('unload', windowUnload);
      window.addEventListener('resize', removeZoomClass);
      window.addEventListener('touchstart', addSoundEffect);
      window.addEventListener('click', addSoundEffect);
      hideJivoChat(true);
      // if (isMobile.value) document.addEventListener(visibilityChange.event, visibilityDocumentChange);
      eventsPrevent.forEach(event => {
        window.addEventListener(event, preventDefaultEvent, { passive: false });
      });
    });

    onBeforeUnmount(() => {
      endVideoCall();
      addZoomClass(true);
      if (!isMobile.value) hideJivoChat(false);
      window.removeEventListener('unload', windowUnload);
      window.removeEventListener('resize', removeZoomClass);
      window.removeEventListener('touchstart', addSoundEffect);
      window.removeEventListener('click', addSoundEffect);

      // document.removeEventListener(visibilityChange.event, visibilityDocumentChange);
      eventsPrevent.forEach(event => {
        window.removeEventListener(event, preventDefaultEvent, { passive: false });
      });
    });

    const previewRoom = ref(true);
    watch(previewRoom, (value) => {
      if (value) return;
      joinRoom();
    });

    const detectedDelay = ref(false);
    const displayDelaySetting = computed(() => store.getters.showDelaySetting);
    const setDisplayDelaySetting = (data) => store.dispatch('setShowDelaySetting', data);
    const checkValueDelaySetting = () => {
      const withoutCalibration = localStorage.getItem('without_calibration');
      const devId = String(audioSettings.value.deviceId);
      const latencyObj = JSON.parse(localStorage.getItem('audio_latency'));
      if (devId && latencyObj && latencyObj.hasOwnProperty(devId)) {
        audioEngine.streamAudioMixer.setDelay(latencyObj[devId]);
        detectedDelay.value = true;
      } else if (!liteVersion.value && !withoutCalibration) {
        setDisplayDelaySetting(true);
        localStorage.setItem('calibration_room_id', roomId.value);
        window.location.href = '/room-calibration';
      } else {
        detectedDelay.value = true;
      }
      localStorage.removeItem('without_calibration');
    };

    const liteVersion = computed(() => store.getters.liteVersion);

    const selectedType = ref(false);

    const openMobileSlider = ref(false);

    return {
      openMobileSlider,
      selectedType,
      supportWebrtc,
      previewRoom,
      closeLessonImg,
      participants,
      closedRoom,
      roomLink,
      isTeacher,
      isVisibleDeviceModalError,
      devicesText,
      localStream,
      getLocalStream,
      videoSettings,
      displayDelaySetting,
      liteVersion,
      roomId,
      unsupportedBrowser,
      isRoomLimit,
      isRoomLimitChecked,
      detectedDelay,
      silence,
      switchVideo
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-video {
  position: relative;
  display: grid;
  grid-gap: 30px;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  background-color: $background-video-call;
  touch-action: none;

  @media (max-width: 1143px) {
    padding: 0;
  }

  &__left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  &__right {
    height: 100%;
  }
}

.lp-video-room {
  @include global-font;
  display: grid;
  align-items: center;
  grid-gap: 50px;

  &-link {
    display: grid;
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;

    &__url {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  &-container {
    position: relative;
    margin-top: auto;
    margin-bottom: 0;
    display: grid;
    align-items: flex-end;
    justify-content: center;
    width: auto;
  }
}
</style>

<style lang="scss">

.lp-video {
  overflow: hidden;
  .lp-tooltip {
    &__text {
      @media (min-width: 1930px) {
        display: none!important;
      }
    }
  }

  // zoom used in AudioVisualization.vue -> calcPercentByPageX()
  @media (min-width: 1921px) {
    .lp-local-video,
    .lp-room-toolbar {
      zoom: 125%;
    }

    .lp-remote-video_sharing-file {
      .lp-remote-video__output {
        zoom: 125%;
      }
    }
  }

  @media (min-width: 2160px) {
    .lp-local-video,
    .lp-room-toolbar {
      zoom: 150%;
    }

    .lp-remote-video_sharing-file {
      .lp-remote-video__output {
        zoom: 150%;
      }
    }
  }

  @media (min-width: 2560px) {
    .lp-local-video,
    .lp-room-toolbar {
      zoom: 200%;
    }

    .lp-remote-video_sharing-file {
      .lp-remote-video__output {
        zoom: 200%;
      }
    }
  }
}
</style>
