const validateForgotPassword = (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    const hasValidator = typeof input.isValid === 'function';
    if (!input.modelValue) {
      input.error = input.emptyError;
      errors += 1;
    } else if (hasValidator && !input.isValid(input.modelValue)) {
      input.error = input.validError || input.emptyError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.modelValue;
    }
  });
  return errors === 0 ? body : false;
};

export default validateForgotPassword;
