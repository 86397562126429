import { uniqueId } from 'lodash';
import FORMATS from '@/constants/formats';
import { subscriptionsIcon } from '@/constants/icons/crm';

const columns = [
  {
    type: 'date',
    prop: 'crmLessonStart',
    formatDate: window.innerWidth > 768 ? 'DD MMMM, YYYY' : 'DD MMM, YYYY',
    title: 'crm.students.lessons.date',
    width: 'minmax(115px, 215px)',
    checkDateLesson: true,
    studentReasonProp: 'finishedByStudentReason'
  },
  {
    type: 'date',
    modify: 'range',
    prop: 'crmLessonEnd',
    propRange: { start: 'crmLessonStart', end: 'crmLessonEnd' },
    formatTime: 'HH:mm',
    title: 'crm.students.lessons.time',
    width: 'minmax(90px, 1fr)'
  },
  {
    type: 'number',
    prop: 'crmLessonCost',
    postfixCell: 'currency',
    iconProp: {
      prop: 'crmLessonPassId',
      icon: subscriptionsIcon,
      tooltipText: 'crm.subscriptions.tooltip'
    },
    format: FORMATS.FINANCE,
    title: 'crm.students.lessons.amount',
    titleMobile: 'crm.students.lessons.amount',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: 'minmax(85px, 1fr)'
  }
];

const paymentsColumns = columns.map((item) => {
  item.id = uniqueId();
  return item;
});

export default paymentsColumns;
