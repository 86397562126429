<template>
  <div
    class="lp-hints"
    :class="{'lp-hints_mobile': isMobile}"
  >
    <HintsFirstStep
      v-if="firstStep"
      :isMobile="isMobile"
      @onButtonClick="onButtonClick"
    />

    <template v-else>
      <HintsTours
        v-if="isTours"
        :isMobile="isMobile"
        @onOpenRegistration="onOpenRegistration"
      />
      <transition
        :name="isMobile ? 'fade' : 'bounce'"
        appear
      >
        <HintsRegistration
          v-if="isRegistration"
          :isMobile="isMobile"
          @onCloseRegistrationModal="onCloseRegistrationModal"
        />
      </transition>
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import HintsFirstStep from '@/components/CRM/Hints/HintsFirstStep/HintsFirstStep';
import HintsTours from '@/components/CRM/Hints/HintsTours/HintsTours';
import HintsRegistration from '@/components/CRM/Hints/HintsRegistration/HintsRegistration';
import MobileDetect from 'mobile-detect';
import { useRouter } from 'vue-router';

export default {
  name: 'Hints',
  components: { HintsTours, HintsFirstStep, HintsRegistration },
  emits: ['update:value'],
  setup (props, { emit }) {
    const router = useRouter();
    const firstStep = ref(true);
    const isTours = ref(false);
    const isRegistration = ref(false);

    const onButtonClick = (status) => {
      firstStep.value = false;
      if (status) isTours.value = status;
      if (!status) isRegistration.value = !status;
    };

    const onCloseRegistrationModal = () => {
      isRegistration.value = false;
      firstStep.value = false;
      emit('update:value', false);
    };

    const onOpenRegistration = () => {
      isRegistration.value = true;
      isTours.value = false;
      firstStep.value = false;
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      firstStep,
      isTours,
      isRegistration,
      isMobile,
      onButtonClick,
      onCloseRegistrationModal,
      onOpenRegistration
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

  .lp-hints {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color-black, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &_mobile {
      background: $color-white;
    }
  }
</style>