<template>
  <div class="stp-calendar-weeks">
    <div
      class="stp-calendar-weeks-table"
      :class="{'stp-calendar-weeks-table_zoomed': isZoomed}"
    >
      <div
        class="stp-calendar-weeks-table-column"
        :class="{
          'stp-calendar-weeks-table-column_first': index === 0,
          'stp-calendar-weeks-table-column_last': index === 6,
          'stp-calendar-weeks-table-column_today': today === day.date,
          'stp-calendar-weeks-table-column_past': day.isPast
        }"
        :ref="index === 0 ? 'column' : ''"
        v-for="(day, index) of week"
        :key="day.date"
      >
        <div
          class="stp-calendar-weeks-table-column__head"
          :class="{
            'stp-calendar-weeks-table-column__head_past': day.isPast,
            'stp-calendar-weeks-table-column__head_first': index === 0,
            'stp-calendar-weeks-table-column__head_last': index === 6
          }"
        >
          <!--       <span class="stp-calendar-weeks-table-column__title stp-calendar-weeks-table-column__title_full">
            {{ day.title }}
          </span>-->
          <span class="stp-calendar-weeks-table-column__title stp-calendar-weeks-table-column__title_short">
            {{ day.shortTitle }}
          </span>
        </div>
        <div class="stp-calendar-weeks-table-column-lessons">
          <StudentCalendarLesson
            v-for="(lesson) of lessons[day.date]"
            mode="week"
            :key="lesson._id"
            :lesson="lesson"
            @get-lessons="$emit('get-lessons')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fill } from 'lodash';
import moment from 'moment';
import { computed } from 'vue';
import detectNeedZoom from '@/constants/utils/detectNeedZoom';
import StudentCalendarLesson
  from '@/components/StudentProfile/StudentCalendar/StudentCalendarLesson';

export default {
  name: 'StudentCalendarWeeks',
  components: { StudentCalendarLesson },
  props: {
    lessons: Object,
    date: String
  },
  emits: ['get-lessons'],
  setup (props) {
    const today = moment().format('YYYY-MM-DD');

    const startWeek = computed(() => moment(props.date));

    const week = computed(() => fill(new Array(7), startWeek.value).map((el, i) => {
      const date = moment(el).add(i, 'day');
      return {
        // title: date.format('DD ddd'),
        shortTitle: date.format('DD ddd'),
        date: date.format('YYYY-MM-DD'),
        isPast: moment().subtract(1, 'day').isAfter(date)
      };
    }));

    const isZoomed = detectNeedZoom();

    return { week, today, isZoomed };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';
@import '~styles/_mixins.scss';

.stp-calendar-weeks {
  @include global-font;

  &-table {
    display: grid;
    grid-template-columns: repeat(7, minmax(145px, 1fr));
    border-radius: 4px;
    border-bottom: 1px solid $color-alto;
    width: 100%;
    padding-top: 1px;
    overflow: auto;
    height: calc(100vh - 207px);

    &_zoomed {
      height: calc(120vh - 207px);
    }

    &-column {
      text-align: center;
      border-right: 1px solid $color-alto;
      background-color: $color-white;
      transition: opacity 0.3s ease-in-out;

      &_today {
        background-color: $color-moon-raker;
      }

      &_first {
        border-radius: 4px 0 0 4px ;
        border-left: 1px solid $color-alto;
      }

      &_last {
        border-radius: 0 4px 4px 0;
      }

      &__title {
        user-select: none;

         /*&_full {
           @media (max-width: 1300px) {
             display: none;
           }
         }*/

        &_short {
          display: block;
          /*@media (max-width: 1300px) {
            display: block;
          }*/
        }
      }

      &__head {
        position: sticky;
        top: 0;
        padding: 10px 0;
        background: $color-moon-raker;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-accent;
        box-shadow:0 0 0 1px $color-alto;
        z-index: 1;
        margin-bottom: 8px;

        &_past {
          background: $color-alabaster;
          color: $color-gray;
        }

        &_first {
          border-radius: 4px 0 0 0;
        }

        &_last {
          border-radius: 0 4px 0 0;
        }
      }

      &-lessons {
        display: flex;
        flex-direction: column;
        padding: 4px 3px 0 0;
      }
    }
  }
}

</style>
