<template>
  <transition name="bounce">
    <div
      class="lp-tooltip"
      :class="{
        'lp-tooltip_right': rightPosition,
        'lp-tooltip_left': leftPosition,
        'lp-tooltip_top': topPosition,
        'lp-tooltip_width': customWidth
      }"
      v-if="(open && !isMobile) || (open && onMobile)"
    >
      <div class="lp-tooltip-content">
        <slot>
          {{ $t(text) }}
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Tooltip',
  props: {
    text: String,
    open: Boolean,
    topPosition: Boolean,
    rightPosition: Boolean,
    leftPosition: Boolean,
    onMobile: Boolean,
    customWidth: Boolean
  },
  setup () {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-tooltip {
  @include global-font;
  position: absolute;
  top: auto;
  left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  background: rgba($color-mine-shaft, 0.9);
  border-radius: 2px;
  pointer-events: none;
  user-select: none;
  z-index: 10;

  &:after {
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-top: 4px solid transparent;
    border-bottom:4px solid rgba($color-mine-shaft, 0.9);
    left: auto;
    top: -8px;
  }

  &_right {
    &:after {
      right: 23px;
    }
  }

  &_top {
    &:after {
      top: auto;
      bottom: -8px;
      border-bottom: 4px solid transparent;
      border-top:4px solid rgba($color-mine-shaft, 0.9);
    }
  }

  &_left {
    &:after {
      left: 9px;
    }
  }

  &-content {
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: $color-white;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &_width {
    width: 100px;
    word-break: break-word;
  }
}

</style>
