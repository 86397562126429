<template>
  <div class="lp-crm-discounts">
    <div
      class="lp-crm-discounts-header"
      :class="{'lp-crm-discounts-header_mobile': isMobile}"
    >
      <div
        class="lp-page-title heavy-bold"
        :class="{'lp-page-title_mobile': isMobile}"
      >
        {{ $t('titlePages.discounts') }}
      </div>
      <div
        class="lp-discounts-hint"
        :class="{'lp-discounts-hint_mobile': isMobile}"
      >
        <div class="lp-discounts-hint__text">
          {{ $t('crm.discounts.notFound') }}
        </div>
        <div
          class="lp-discounts-hint__button"
          @click.prevent.stop="openForm"
        >
          <div class="lp-button">
            {{ $t('buttons.write') }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="lp-crm-discounts__wrapper"
      :class="{'lp-crm-discounts__wrapper_mobile': isMobile}"
    >
      <DiscountItem
        v-for="discount in discountsList"
        :key="discount.id"
        :discount="discount"
      />
    </div>
    <div
      class="lp-discounts-hint-mb"
      :class="{'lp-discounts-hint-mb_mobile': isMobile}"
    >
      <div class="lp-discounts-hint__text">
        {{ $t('crm.discounts.notFound') }}
      </div>
      <div
        class="lp-discounts-hint__button"
        @click.prevent.stop="openForm"
      >
        <div class="lp-button">
          {{ $t('buttons.write') }}
        </div>
      </div>
    </div>
  </div>
  <ImproveServiceForm
    :openForm="openModal"
    v-model:openForm="openModal"
  />
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import DiscountItem from '@/components/CRM/Discounts/DiscountItem';
import ImproveServiceForm from '@/components/CRM/Sidebar/ImproveServiceForm/ImproveServiceForm';
import MobileDetect from 'mobile-detect';
import discountsList from '@/constants/crm/discountsList';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';

export default {
  name: 'Discounts',
  components: {
    DiscountItem,
    ImproveServiceForm
  },
  setup () {
    const openModal = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const openForm = () => {
      openModal.value = true;
    };

    onMounted(() => {
      yandexTracker.openDiscounts();
    });

    return {
      discountsList,
      openModal,
      openForm,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-page-title {
  &_mobile {
    padding: 15px 10px 15px 50px;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
}

.lp-crm-discounts {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    gap: 20px;

    @media (min-width: 2000px) {
      justify-content: center;
      gap: 60px;
    }

    @media (max-width: 1820px) {
      justify-content: center;
      gap: 60px;
    }

    &_mobile {
      padding: 0 20px;
      justify-content: center;
      gap: 0;
    }
  }
}

.lp-crm-discounts-header {
  display: flex;
  justify-content: space-between;

  &_mobile {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: $color-white;
  }
}

.lp-discounts-hint {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 20px;
    font-weight: 700;
  }

  &_mobile {
    display: none;
  }
}

.lp-discounts-hint-mb {
  display: none;
  padding: 20px 20px 40px 20px;
  max-width: 500px;
  margin: 0 auto;

  .lp-discounts-hint__text {
    margin-bottom: 20px;
  }

  &_mobile {
    display: block;
  }
}

.heavy-bold {
  font-weight: 800;
}
</style>