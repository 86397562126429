<template>
  <div class="lp-chart-income">
    <div
      class="lp-chart-income-header"
      :class="{'lp-chart-income-header_mobile': isMobile}"
    >
      <div class="lp-chart-income-header__title">
        {{ $t('crm.analytics.income') }}
      </div>
      <div class="lp-chart-income-header__counter">
        <Vue3Autocounter
          :startAmount="0"
          :endAmount="total"
          :duration="2"
          :suffix="` ${settings.currentCurrency}`"
          separator=","
          decimalSeparator="."
          :decimals="2"
        />
      </div>
    </div>
    <div
      class="lp-chart-income-body"
      :class="{'lp-chart-income-body_mobile': isMobile}"
    >
      <VueApexCharts
        type="bar"
        :height="mobile ? heightChartMobile : 340"
        :width="widthChart"
        :options="chartOptions"
        :series="[{name: 'lessons', data: lessons}]"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import chartIncome from '@/constants/crm/chartIncome';
import { computed, onBeforeMount } from 'vue';
import Vue3Autocounter from 'vue3-autocounter';
import SettingsApi from '@/api/CRM/settings';
import { useStore } from 'vuex';
import chartTooltip from '@/constants/utils/CRM/chartTooltip';
import FORMATS from '@/constants/formats';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ChartIncome',
  components: { VueApexCharts, Vue3Autocounter },
  props: {
    mobile: Boolean,
    total: Number,
    dates: Array,
    lessons: {
      type: Array,
      default: () => ([])
    }
  },
  setup (props) {
    const oneColumn = computed(() => window.innerWidth < 1800);
    const hasTable = computed(() => window.innerWidth <= 1440);
    const hasMobile = computed(() => window.innerWidth <= 768);
    const columnWidth = computed(() => {
      if (props.dates.length < 7) return '30%';
      if (props.dates.length >= 28) return '80%';
      return '40%';
    });

    const chartOptions = computed(() => ({
      ...chartIncome,
      xaxis: {
        categories: props.dates,
        labels: {
          style: { cssClass: props.dates.length > 7 ? 'chart-label_short' : 'chart-label' },
          rotate: props.mobile && props.dates.length > 7 ? -45 : 0,
          rotateAlways: props.mobile && props.dates.length > 7
        },
        tooltip: { enabled: false },
        crosshairs: { show: false }
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        followCursor: false,
        custom: ({
          series,
          seriesIndex,
          dataPointIndex
        }) => (chartTooltip({
          series,
          seriesIndex,
          dataPointIndex
        }, settings.value.currentCurrency, FORMATS.FINANCE)),
        theme: false,
        x: { show: false },
        marker: { show: false }
      },

      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: columnWidth.value
        }
      }
    }));

    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const setSettings = (data) => store.dispatch('setSettings', data);

    const getSettings = async () => {
      if (settings.value._id) return;
      try {
        const { data } = await SettingsApi.getSettings();
        await setSettings(data);
      } catch (e) {
        console.error(e);
      }
    };

    onBeforeMount(getSettings);

    const heightChartMobile = computed(() => {
      const vh = window.innerHeight * 0.01;
      return ((vh * 100) - 250) / 2;
    });

    const widthChart = computed(() => {
      const vw = window.innerWidth * 0.01;
      if (oneColumn.value && !hasTable.value && !hasMobile.value) {
        return ((vw * 100) - 2 * 226);
      } else if (oneColumn.value && hasTable.value && !hasMobile.value) {
        return ((vw * 100) - 113);
      } else if (oneColumn.value && hasTable.value && hasMobile.value) {
        return ((vw * 100) - 32);
      } else {
        return (((vw * 100) / 2) - 226);
      }
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      widthChart,
      heightChartMobile,
      chartOptions,
      settings
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-chart-income {
  width: 50%;

  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;

    &_mobile {
      padding: 0 16px;
    }

    &__title {
      margin-right: 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-mine-shaft-dark;
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 12px;
      border-radius: 5px;
      background-color: $color-moon-raker;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      color: $color-accent;
    }
  }

  &-body {
    &_mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px 0 6px;
    }
  }

  @media screen and (max-width: 1800px) {
    margin-bottom: 30px;
    width: auto;
    grid-row: 2;
  }
}

</style>
