<template>
  <div
    class="strengthscrm"
    id="strengthscrm"
  >
    <div class="strengthscrm__container">
      <div class="strengthscrm__title">
        {{ $t('landing.strengthscrm.name.first') }}
        <span> {{ $t('landing.strengthscrm.name.second') }} </span>
      </div>
      <div class="strengthscrm__content">
        <div class="strengthscrm__list">
          <div
            class="strengthscrm-item"
            v-for="strength of strengthscrm"
            :key="strength.id"
          >
            <div class="strengthscrm-item__icon">
              <img
                :src="
                  require(`@/assets/images/landing/strengthscrm/${strength.image}.svg`)
                "
                alt="user"
              />
            </div>
            <div class="strengthscrm-item__text">
              {{ $t(strength.name) }}
            </div>
          </div>
        </div>
        <div class="strengthscrm__image">
          <img
            :src="
              require(`@/assets/images/landing/strengthscrm/strengthscrm.jpg`)
            "
            alt="user"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import strengthscrm from '@/constants/landing/strengthscrm';

export default {
  name: 'StrengthsCRM',
  props: {
    currentLanguage: Boolean
  },
  setup () {
    return {
      strengthscrm
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.strengthscrm {
  width: 100%;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: calc(100% - 10px);
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $color-white;
    padding: 80px 50px;
    box-shadow: 0 0 28px rgba($color-black, 0.04);
    border-radius: 8px;
  }

  &__title {
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    text-align: center;

    span {
      color: $color-purple;
      line-height: 100%;
      text-justify: center;
    }
  }

  &__content {
    position: relative;
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    position: relative;
    display: grid;
    grid-template-rows: repeat(3, 50px);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 140px;
  }
}

.strengthscrm-item {
  position: relative;
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    width: 48px;
  }

  &__text {
    margin-left: 16px;
    width: 235px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
  }
}
</style>
