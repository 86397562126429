import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  createPaymentIntent (body) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('stripe/create-payment-intent', body);
  }
};
