import { createStore } from 'vuex';
import modalIds from '@/store/ModalIds/index';
import rtcConnect from '@/store/RtcConnect/index';
import loaderRun from '@/store/LoaderRun/index';
import streams from '@/store/Streams/index';
import user from '@/store/User/index';
import player from '@/store/MidiPlayer/index';
import country from '@/store/Country/index';
import files from '@/store/Files/index';
import students from '@/store/CRM/students';
import settings from '@/store/CRM/settings';
import lessons from '@/store/CRM/lessons';
import template from '@/store/CRM/template';
import subscriptions from '@/store/CRM/subscriptions';
import audioCtx from '@/store/AudioCtx/index';
import mp3Player from '@/store/Mp3Player/index';
import student from '@/store/Student/index';

export default createStore({
  modules: {
    modalIds,
    rtcConnect,
    loaderRun,
    streams,
    user,
    player,
    country,
    files,
    students,
    settings,
    lessons,
    template,
    subscriptions,
    audioCtx,
    mp3Player,
    student
  }
});
