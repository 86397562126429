<template>
  <div
    class="lp-crm-finance"
    :class="{'lp-crm-finance_mobile': isMobile}"
  >
    <FinanceHeader
      :fistDate="fistDate"
      :lastDate="lastDate"
      :empty="!hasPayments"
      v-model:date="selectedDate"
      v-model:openPayment="openPayment"
    />
    <FinanceTotals
      v-if="totals.length"
      :totals="totals"
    />
    <FinanceTable
      :total="totals.length"
      :loading="!uploadedData"
      :pagination="pagination"
      :payments="payments"
      @get="getPaymentsByDate"
      v-model:openPayment="openPayment"
      v-model:studentId="viewStudentId"
    />
    <StudentCard
      v-model:open="openCard"
      :openForm="openCard"
      :student="viewStudent"
      :onlyView="true"
    />
    <PaymentForm
      :openForm="openPayment"
      @update="updatePayments"
      v-model:openForm="openPayment"
    />
  </div>
</template>

<script>
import FinanceTotals from '@/components/CRM/Finance/FinanceTotals';
import FinanceHeader from '@/components/CRM/Finance/FinanceHeader';
import { computed, onBeforeMount, ref, watch } from 'vue';
import FinanceTable from '@/components/CRM/Finance/FinanceTable';
import StudentCard from '@/components/CRM/Students/StudentCard/StudentCard';
import { useStore } from 'vuex';
import moment from 'moment';
import { last, get } from 'lodash';
import PaymentsApi from '@/api/CRM/payments';
import StudentApi from '@/api/CRM/students';
import LessonsApi from '@/api/CRM/lessons';
import PaymentForm from '@/components/CRM/Students/StudentCard/PaymentForm';
import MobileDetect from 'mobile-detect';

export default {
  name: 'Finance',
  components: { PaymentForm, StudentCard, FinanceTable, FinanceHeader, FinanceTotals },
  setup () {
    const store = useStore();
    const students = computed(() => store.getters.students);
    const setLoaderRun = (data) => store.dispatch('setLoaderRun', data);
    const uploadedData = ref(false);

    const totals = ref([]);
    const pagination = ref({});
    const payments = ref([]);

    const scrollUp = () => {
      const ps = document.querySelector('.lp-crm-main');
      if (!ps) return;
      ps.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    const currentPage = ref(1);

    const fistDate = ref('');
    const lastDate = ref('');
    const hasPayments = ref(false);
    const getFirstDate =  async () => {
      await setLoaderRun(true);
      const start = moment().subtract(50, 'year').startOf('month').utc().format();
      const end = moment().add(50, 'year').startOf('month').utc().format();
      try {
        const query = { limit: 1, startDate: start, endDate: end, lost: true, start, end };
        const { data: dataPages } = await PaymentsApi.getPaymentsByDate(query);
        const [lastPayment = {}] = dataPages.resource;
        const { totalPages } = dataPages;
        const { data } = await PaymentsApi.getPaymentsByDate({ ...query, page: totalPages });
        const [fistPayment = {}] = data.resource;

        const { data: lessons } = await LessonsApi.getGroupLessonsByDate(query);
        const [lastLesson] = lessons;
        const firstLesson = last(lessons);
        const firstDatePayment =  get(fistPayment, 'paymentDate', '');
        const lastDatePayment =  get(lastPayment, 'paymentDate', '');
        const firstDateLesson = get(firstLesson, 'crmLessonStart', '');
        const lastDateLesson = get(lastLesson, 'crmLessonStart', '');
        fistDate.value = moment(firstDatePayment).isAfter(firstDateLesson) ? firstDateLesson : firstDatePayment;
        lastDate.value = moment(lastDatePayment).isBefore(lastDateLesson) ? lastDateLesson : lastDatePayment;

        hasPayments.value = !!dataPages.totalDocs || dataPages.totalIncome.length;
        if (!lessons.length && !dataPages.totalDocs) {
          await setLoaderRun(false);
          uploadedData.value = true;
        }
      } catch (err) {
        console.error(err);
      }
    };

    const getPaymentsByDate = async ({ page = 1, stopScroll } = {}) => {
      const [startDate, endDate] = selectedDate.value;
      if (!startDate || !endDate) return;
      await setLoaderRun(true);
      uploadedData.value = false;
      currentPage.value = page;
      try {
        if (!stopScroll) scrollUp();
        const query = {
          page,
          limit: 25,
          startDate: moment(startDate).startOf('day').utc().format(),
          endDate: moment(endDate).endOf('day').utc().format()
        };
        const { data } = await PaymentsApi.getPaymentsByDate(query);
        const { resource, totalIncome, ...paginationInfo } = data;
        pagination.value = paginationInfo;
        totals.value = totalIncome;
        payments.value = resource;
        uploadedData.value = true;
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        uploadedData.value = true;
        await setLoaderRun(false);
      }
    };

    const selectedDate = ref([]);
    watch(selectedDate, () => getPaymentsByDate({}));
    onBeforeMount(async () => {
      await getFirstDate();
    });

    const viewStudentId = ref('');
    const viewStudent = ref({});
    watch(viewStudentId, (id) => id ? getStudent(id) : openCard.value = false );

    const openCard = ref(false);
    watch(openCard, (open) => {
      if (!open) {
        viewStudentId.value = '';
        getPaymentsByDate({ page: currentPage.value, stopScroll: true });
      }
    });

    const getStudent = async (id) => {
      const student = students.value.find(item => item._id === id) || {};
      if (student._id) {
        viewStudent.value = student;
        openCard.value = true;
        return;
      }
      await setLoaderRun(true);
      try {
        const { data } = await StudentApi.getStudentById(id);
        viewStudent.value = data;
        openCard.value = true;
        students.value.push(data);
      } catch (e) {
        console.error(e);
      }
      await setLoaderRun(false);
    };

    const openPayment = ref(false);
    const updatePayments = async () => {
      await getFirstDate();
      await getPaymentsByDate({ stopScroll: true, page: currentPage.value });
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      uploadedData,
      currentPage,
      openPayment,
      selectedDate,
      totals,
      pagination,
      payments,
      getPaymentsByDate,
      viewStudentId,
      openCard,
      viewStudent,
      fistDate,
      lastDate,
      updatePayments,
      hasPayments
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-finance {
  @include global-font;
  margin: -35px -24px 0;

  &_mobile {
    margin: 0;
    height: 100%;
    background-color: $color-white;
  }
}

</style>
