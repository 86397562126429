<template>
  <Modal
    :title="''"
    @save="closeForm"
    @remove="closeForm"
    :saveBtnText="'buttons.ok'"
    :hideButtons="['remove']"
    v-model:open="openModal"
    :editMode="true"
    :mobileVersion="isMobile"
  >
    <div
      class="lp-forgot-password-modal"
      :class="{'lp-forgot-password-modal_mobile': isMobile}"
    >
      <img
        class="lp-forgot-password-modal__img"
        :class="{'lp-forgot-password-modal__img_mobile': isMobile}"
        :src="registrationPlaceholder"
        alt="placeholder"
      />
      <p class="lp-forgot-password-modal__title">
        {{ $t('forgotPassword.modal.title') }}
      </p>
      <p
        class="lp-forgot-password-modal__info lp-forgot-password-modal__firstInfo"
      >
        {{ $t('forgotPassword.modal.infoText1') }}
      </p>
      <p
        class="lp-forgot-password-modal__info lp-forgot-password-modal__secondInfo"
      >
        {{ $t('forgotPassword.modal.infoText2') }}
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import registrationPlaceholder from '@/assets/images/CRM-registration.png';
import { computed, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ForgotPasswordInfo',
  components: { Modal },
  props: {
    open: Boolean
  },
  emits: ['close'],
  setup (props, { emit }) {
    const openModal = ref(false);
    watch(
      () => props.open,
      (val) => (openModal.value = val)
    );

    const closeForm = () => {
      emit('close');
    };
    watch(openModal, (val) => {
      if (!val) closeForm();
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      closeForm,
      registrationPlaceholder,
      openModal
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-forgot-password-modal {
  @include global-font;
  max-width: 690px;
  padding: 50px 90px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-text;

  &_mobile {
    padding: 0 20px;
    margin: calc(50vh - 200px) 0 0;
  }

  &__img {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-width: 309px;
    margin-bottom: 32px;

    &_mobile {
      max-width: 309px;
      margin: 0;
    }
  }

  &__title {
    margin-top: 32px;
    max-width: 367px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 125%;
  }

  &__info {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  &__firstInfo {
    margin-top: 27px;
    max-width: 495px;

    @media (max-width: 767px) {
      margin-top: 20px;
      max-width: 339px;
    }
  }

  &__secondInfo {
    margin-top: 19px;
    max-width: 385px;

    @media (max-width: 767px) {
      margin-top: 16px;
      max-width: 339px;
    }
  }
}
</style>
