<template>
  <div class="lp-footer">
    <div class="lp-footer__wrapper">
      <div class="lp-footer__left-container">
        <router-link
          class="lp-footer-logo"
          :to="{name: 'Landing'}"
        >
          <musioStarIcon />
        </router-link>
      </div>
      <div class="lp-footer__copyright">
        © {{ new Date().getFullYear() }} Musio.io
        {{ $t('landing.footerCopyright') }}
      </div>
    </div>
  </div>
</template>

<script>
import { musioStarIcon } from '@/constants/icons/landing';

export default {
  name: 'Footer',
  components: {
    musioStarIcon
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-footer {
  height: 182px;
  width: calc(100% - 24px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  background-color: $color-purple;
  border-radius: 12px;
  margin: 12px;

  &__wrapper {
    z-index: 3;
    width: 100%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    margin: 0 15px;
  }

  &__left-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__copyright {
    margin-top: 28px;
    text-align: center;
  }
}
</style>
