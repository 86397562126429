const RESOLUTION = {
  1080: {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    frameRate: 30
  },
  720: {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    frameRate: 22
  },
  480: {
    width: { ideal: 640 },
    height: { ideal: 480 },
    frameRate: 15
  }
};

export default RESOLUTION;
