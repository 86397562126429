const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const LOGIN_REGEXP = /^[a-z0-9_-]{3,16}$/;

export const isEmail = (mail) => EMAIL_REGEXP.test(mail);

export const isLogin = (login) => LOGIN_REGEXP.test(login);

export const isUrl = (url) => {
  const protocols = /http:\/\/|https:\/\//gmi;
  let checkedUrl = url;
  let string;
  const domen = /\.([^\n]{2,})/;
  const hasDomen = domen.test(url);
  const hasProtocols = protocols.test(url);
  if (!hasProtocols && hasDomen) checkedUrl = `https://${url}`;

  try {
    string = new URL(checkedUrl);
  } catch (_) {
    return false;
  }

  return string.protocol === 'http:' || string.protocol === 'https:';
};
