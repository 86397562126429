import { uniqueId } from 'lodash';
import teacher1Img from '@/assets/images/pianists/pianist1.jpg';
import achievement5 from '@/assets/images/pianists/achievements/achievement5.jpg';
import achievement4 from '@/assets/images/pianists/achievements/achievement4.jpg';
import achievement3 from '@/assets/images/pianists/achievements/achievement3.jpg';

export default {
  id: uniqueId,
  image: teacher1Img,
  name: 'crm.teachers.pianist1.name',
  about: 'crm.teachers.pianist1.about'
  ,
  works: [
    {
      date: '2010 - по настоящее время',
      position: 'концертмейстер и педагог',
      id: uniqueId()
    }
  ],
  achievements: [
    { item: achievement5, id: uniqueId() },
    { item: achievement4, id: uniqueId() },
    { item: achievement3, id: uniqueId() }
  ],
  workText: 'crm.teachers.pianist1.experience',
  hideNext: true
};
