<template>
  <transition
    name="fade"
    appear
  >
    <HintsToursMobile
      v-if="isMobile"
      :hints="hints"
      @onButtonClick="onOpenRegistration"
    />
  </transition>
  <transition
    name="bounce"
    appear
  >
    <HintsToursDesctope
      v-if="!isMobile"
      :hints="hints"
      @onButtonClick="onOpenRegistration"
    />
  </transition>
</template>

<script>
import { useI18n } from 'vue-i18n';
import HintsToursDesctope from '@/components/CRM/Hints/HintsTours/HintsToursDesctope';
import imgDesctope1 from '@/assets/images/hints/hint-one.jpg';
import imgDesctopeEng1 from '@/assets/images/hints/hint-one-eng.jpg';
import imgDesctope2 from '@/assets/images/hints/hint-two.jpg';
import imgDesctopeEng2 from '@/assets/images/hints/hint-two-eng.jpg';
import imgDesctope3 from '@/assets/images/hints/hint-three.jpg';
import imgDesctopeEng3 from '@/assets/images/hints/hint-three-eng.jpg';
import imgDesctope4 from '@/assets/images/hints/hint-four.jpg';
import imgDesctopeEng4 from '@/assets/images/hints/hint-four-eng.jpg';
import imgDesctope5 from '@/assets/images/hints/hint-five.jpg';
import imgDesctopeEng5 from '@/assets/images/hints/hint-five-eng.jpg';
import imgMobile1 from '@/assets/images/hints/hint-one-mobile.png';
import imgMobileEng1 from '@/assets/images/hints/hint-one-mobile-eng.png';
import imgMobile2 from '@/assets/images/hints/hint-two-mobile.png';
import imgMobile3 from '@/assets/images/hints/hint-three-mobile.png';
import imgMobileEng3 from '@/assets/images/hints/hint-three-mobile-eng.png';
import imgMobile4 from '@/assets/images/hints/hint-four-mobile.png';
import imgMobileEng4 from '@/assets/images/hints/hint-four-mobile-eng.png';
import imgMobile5 from '@/assets/images/hints/hint-five-mobile.png';
import HintsToursMobile from '@/components/CRM/Hints/HintsTours/HintsToursMobile';

export default {
  name: 'HintsTours',
  components: { HintsToursMobile, HintsToursDesctope },
  props: {
    isMobile: Boolean
  },
  emits: ['on-open-registration'],
  setup (props, { emit }) {
    const { locale } = useI18n({ useScope: 'global' });
    const isRus = locale.value === 'ru';

    const hints = [
      {
        id: 1,
        title: 'hints.stepOne.title',
        description1: 'hints.stepOne.description1',
        description2: 'hints.stepOne.description2',
        imgDesctope: isRus ? imgDesctope1 : imgDesctopeEng1,
        imgMobile: isRus ? imgMobile1 : imgMobileEng1
      },
      {
        id: 2,
        title: 'hints.stepTwo.title',
        description1: 'hints.stepTwo.description1',
        description2: 'hints.stepTwo.description2',
        imgDesctope: isRus ? imgDesctope2 : imgDesctopeEng2,
        imgMobile: imgMobile2
      },
      {
        id: 3,
        title: 'hints.stepThree.title',
        description1: 'hints.stepThree.description1',
        description2: 'hints.stepThree.description2',
        descriptionMobile1: 'hints.stepThree.descriptionMobile1',
        descriptionMobile2: 'hints.stepThree.descriptionMobile2',
        imgDesctope: isRus ? imgDesctope3 : imgDesctopeEng3,
        imgMobile: isRus ? imgMobile3 : imgMobileEng3
      },
      {
        id: 4,
        title: 'hints.stepFour.title',
        description1: 'hints.stepFour.description1',
        description2: 'hints.stepFour.description2',
        imgDesctope: isRus ? imgDesctope4 : imgDesctopeEng4,
        imgMobile: isRus ? imgMobile4 : imgMobileEng4
      },
      {
        id: 5,
        title: 'hints.stepFive.title',
        description1: 'hints.stepFive.description1',
        description2: 'hints.stepFive.description2',
        imgDesctope: isRus ? imgDesctope5 : imgDesctopeEng5,
        imgMobile: imgMobile5
      }
    ];

    const onOpenRegistration = () => {
      emit('on-open-registration');
    };

    return {
      hints,
      onOpenRegistration
    };
  }
};
</script>
