<template>
  <div
    class="lp-table"
    v-if="(columns.length && rows.length)"
  >
    <div
      class="lp-table-row lp-table-row_header"
      :class="{'lp-table-row_mobile': isMobile}"
      :style="{
        'grid-template-columns': columnsGrid,
        'top': `${sticky}px`,
        'grid-gap': columnsGap.gap
      }"
    >
      <div
        class="lp-table-column__title"
        :class="{'lp-table-row__pointer': column.pointer}"
        v-for="column in columns"
        :key="column.id"
        :style="{
          justifyContent: column.justifyContent || 'flex-start',
          textAlign: column.textAlign || 'left'
        }"
      >
        <template v-if="isMobile && column.titleMobile">
          {{ column.titleMobile ? $t(column.titleMobile) : '' }}{{ column.postfix ? `, ${column.postfix}` : '' }}
        </template>
        <template v-else>
          {{ column.title ? $t(column.title) : '' }}{{ column.postfix ? `, ${column.postfix}` : '' }}
        </template>
      </div>
    </div>
    <div :style="{maxHeight: maxHeight}">
      <div
        class="lp-table-row"
        :class="{'lp-table-row_mobile': isMobile}"
        :style="{
          'grid-template-columns': columnsGrid,
          'grid-gap': columnsGap.gap
        }"
        v-for="(row, index) in rows"
        :key="row.id"
        @mouseleave="hoverRow = ''"
        @mouseenter="hoverRow = row._id"
      >
        <div
          class="lp-table-column"
          :class="{
            'lp-table-column_toolbar': column.toolbar && !isMobile,
            'lp-table-column_toolbar lp-table-column_toolbar_mobile': column.toolbar && isMobile
          }"
          v-for="column in columns"
          :key="column.id"
          @click.stop="$emit('click-cell', {row, column})"
          :style="{
            justifyContent: column.justifyContent || 'flex-start',
            textAlign: column.textAlign || 'left',
            overflow: column.overflow || 'visible',
            height: column.height || 'auto'
          }"
        >
          <component
            v-if="!column.toolbar"
            :is="getComponentByType(column)"
            :row="row"
            :column="column"
            @delete="$emit('delete', $event)"
          />
          <Toolbar
            v-else
            :row="row"
            :column="column"
            :hover="hoverRow"
            :customClass="!isMobile && column.toolbar && column.customClass && index > 0 ? column.customClass : ''"
            @delete="$emit('delete', $event)"
            @edit="$emit('edit', $event)"
          />
        </div>
      </div>
    </div>
  </div>
  <slot
    class="lp-table-empty"
    name="empty"
    v-else-if="!loading"
  />
</template>

<script>
import { get } from 'lodash';
import { computed, ref } from 'vue';
import TextCell from '@/components/Main/Table/TextCell/TextCell';
import StatusCell from '@/components/Main/Table/StatusCell/StatusCell';
import UserCell from '@/components/Main/Table/UserCell/UserCell';
import Toolbar from '@/components/Main/Table/Toolbar/Toolbar';
import DateCell from '@/components/Main/Table/DateCell/DateCell';
import DeleteCell from '@/components/Main/Table/DeleteCell/DeleteCell';
import CategoryCell from '@/components/Main/Table/CategoryCell/CategoryCell';
import MobileDetect from 'mobile-detect';

export default {
  name: 'LPTable',
  components: { Toolbar },
  props: {
    sticky: {
      type: Number,
      default: 0
    },
    loading: Boolean,
    rows: {
      type: Array,
      default: () => ([])
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    maxHeight: {
      type: String,
      default: 'auto'
    },
    limit: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    totalPages: {
      type: Number,
      default: 0
    }
  },
  emits: ['delete', 'edit', 'click-cell'],
  setup (props) {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const hoverRow = ref('');

    const columnsGrid = computed(() => props.columns.map((item) => item.width || '1fr').join(' '));
    const columnsGap = computed(() => props.columns.find((item) => {
      if (item.gap) return item.gap;
      return { gap: '20px' };
    }));

    const getComponentByType = (column) => {
      const type = get(column, 'type', '');
      switch (type) {
      case 'status':
        return StatusCell;
      case 'user':
        return UserCell;
      case 'date':
        return DateCell;
      case 'delete':
        return DeleteCell;
      case 'category':
        return CategoryCell;
      default:
        return TextCell;
      }
    };
    return {
      isMobile,
      getComponentByType,
      columnsGrid,
      columnsGap,
      hoverRow
    };
  }
};

</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-table {
  display: grid;

  &-row {
    display: grid;
    grid-gap: 20px;
    border-top: 1px solid $color-alto;
    box-shadow: 0 0 0 rgba($color-black, 0);
    padding: 0 20px;
    transition: 0.3s ease-in-out;

    &_mobile {
      padding: 0 16px;
    }

    &:hover:not(&_header):not(&_mobile) {
      background: $color-white;
      box-shadow: 0 0 16px rgba($color-black, 0.08);
      border-radius: 5px;
      border-top: 1px solid transparent;
    }

    &:hover:not(&_header):not(&_mobile) + div {
      border-top: 1px solid transparent;
    }

    &:first-child {
      border-top: 1px solid transparent;
    }

    &:hover .lp-table-column_toolbar {
      transform: scale(1);

      &_mobile {
        transform: rotate(90deg) translate(3px, -20%);
        padding: 0;
      }
    }

    &_header {
      position: sticky;
      top: 0;
      background-color: $color-moon-raker;
      color: $color-accent;
      border-radius: 4px 4px 0 0;
      margin-bottom: 6px;
      align-items: center;
      z-index: 5;
      border-bottom: none;
      box-shadow: 0 0 20px rgba($color-black, .08);
    }

    &__pointer {
      cursor: pointer;
      transition: 0.3s;
    }
  }

  &-column {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: $color-text;

    @media (max-width: 481px) {
      font-size: 14px;
    }

    &__title {
      font-weight: bold;
      font-size: 13px;
      line-height: 125%;
      display: flex;
      align-items: center;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      padding: 12px 0;
      user-select: none;

      @media (max-width: 481px) {
        font-size: 12px;
      }
    }

    &_toolbar {
      transform: scale(0);
      transition: 0.2s ease-in;
      z-index: 2;

      &_mobile {
        transform: rotate(90deg) translate(3px, -20%);
        padding: 0;
      }
    }
  }

  &-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
}

</style>
