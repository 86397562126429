<template>
  <div
    class="lp-modal-question"
    :class="{
      [`lp-modal-question_${theme}`]: theme,
      'lp-modal-question_fixed': position,
      'lp-modal-question_mobile': mobile
    }"
    :style="checkedPosition"
    ref="menuEl"
    v-click-outside="cancel"
  >
    <teleport
      to="#app"
      v-if="mobile"
    >
      <div class="lp-modal-question__background" />
    </teleport>
    <div class="lp-modal-question-body">
      <span>{{ question }}</span>
    </div>
    <div
      class="lp-modal-question-footer"
      :class="{'lp-modal-question-footer_center': center}"
    >
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="cancel"
      >
        {{ textCancel || $t('global.no') }}
      </button>
      <button
        class="lp-button lp-button_small lp-button_thin"
        @click.stop="accept"
      >
        {{ textAccept || $t('global.yes') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  name: 'ModalQuestion',
  props: {
    mobile: Boolean,
    center: Boolean,
    question: String,
    position: Object,
    theme: String,
    textCancel: String,
    textAccept: String
  },
  emits: ['accept', 'update:open'],
  setup (props, { emit }) {
    const cancel = () => {
      emit('update:open', false);
    };

    const accept = () => {
      cancel();
      emit('accept');
    };

    const menuEl = ref(null);
    const checkedPosition = computed(() => {
      if (props.mobile || !props.position || !menuEl.value) return {};
      const offsetWidth = 272;
      const offsetHeight = 160;
      const {
        left,
        top
      } = props.position;
      const {
        innerWidth,
        innerHeight
      } = window;
      const style = {
        top: `${top}px`,
        left: `${left}px`
      };
      if (innerWidth < (left + offsetWidth)) {
        style.left = `${left - offsetWidth}px`;
      }
      if (innerHeight < (top + offsetHeight)) {
        style.top = `${top - (offsetHeight - 50)}px`;
      }
      return style;
    });

    return {
      cancel,
      accept,
      menuEl,
      checkedPosition
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-modal-question {
  @include global-font;
  position: absolute;
  right: 0;
  display: grid;
  grid-gap: 16px;
  background: $color-white;
  box-shadow: 0 0 20px rgba($color-black, 0.09);
  border-radius: 4px;
  padding: 16px 18px;
  min-width: 252px;
  z-index: 10;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: $color-text;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color-black, 0.5);
  }

  &_fixed {
    position: fixed;
    z-index: 9999999999;
  }

  &_mobile {
    position: fixed;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }

  &-body {
    text-align: center;

    span {
      word-break: break-word;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_center {
      justify-content: center;
    }
  }

  &_dark {
    background: $color-shark;
    color: $color-white;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.07em;
    grid-gap: 20px;
    padding: 20px;
    box-shadow: 0 0 19px rgba($color-black, 0.51), 0 0 23px rgba($color-black, 0.23);
  }

  &_dark &-footer {
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: 8px;

    button {
      color: $color-white;

      &:hover {
        background: $color-outer-space;
      }
    }

    button:nth-child(2) {
      background: $color-crimson;
    }
  }
}

</style>
