<template>
  <div class="lp-invite-student">
    <div
      class="lp-invite-student__button"
      :class="{
        'lp-invite-student__button_short': short,
        'lp-invite-student__button_mobile': isMobile
      }"
      :style="{width: `${short ? size : ''}px`, height: `${size}px`}"
      @click="openInvite()"
    >
      <inviteIcon />
      <span v-if="!short">{{ $t('lessonCall.inviteStudent') }}</span>
    </div>
    <teleport
      to="#app"
      :disabled="!isMobile"
    >
      <transition name="bounce">
        <div
          class="lp-invite-student-modal"
          :class="{'lp-invite-student-modal_mobile': isMobile}"
          v-if="showInvite"
          v-click-outside="closeInvite"
        >
          <div class="lp-invite-student-modal-header">
            <span>{{ $t('lessonCall.inviteStudentLink') }}</span>
            <span
              class="lp-invite-student-modal-header__close"
              v-if="isMobile"
              @click="closeInvite"
            >
              <closeModalIcon />
            </span>
          </div>
          <div class="lp-invite-student-modal-body">
            <input
              type="input"
              class="lp-input lp-invite-student-modal-body__input"
              v-model="roomLink"
              readonly="readonly"
              ref="linkNode"
            />
            <button
              @click="copyLink()"
              class="lp-button lp-button_small lp-invite-student-modal-body__button"
              :class="{'lp-invite-student-modal-body__button_copied': copied}"
            >
              {{ copyText }}
            </button>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { inviteIcon, closeModalIcon } from '@/constants/icons';
import { computed, ref } from 'vue';
import { FRONTEND_URL } from '@/constants/domains';
import { useRoute } from 'vue-router';
import selectText from '@/constants/utils/selectText';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'InviteStudentButton',
  components: { inviteIcon, closeModalIcon },
  props: {
    short: Boolean,
    size: Number
  },
  setup () {
    const { t } = useI18n();

    const route = useRoute();
    const roomLink = computed(() => `${FRONTEND_URL}${route.path}`);

    const showInvite = ref(false);
    const linkNode = ref(null);
    const copyText = ref(t('lessonCall.copyLink'));
    const copied = ref(false);
    const copyLink = () => {
      selectText(linkNode.value);
      copyText.value = t('lessonCall.copied');
      copied.value = true;
      yandexTracker.copyRoomLink();
      setTimeout(() => {
        showInvite.value = false;
        copyText.value = t('lessonCall.copyLink');
        copied.value = false;
      },1000);
    };

    const openInvite = () => {
      if (showInvite.value) return;
      yandexTracker.callInvite();
      showInvite.value = true;
    };

    const closeInvite = () => showInvite.value = false;

    const store = useStore();
    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      showInvite,
      roomLink,
      linkNode,
      copyText,
      copied,
      copyLink,
      openInvite,
      closeInvite
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-invite-student {
  position: relative;

  &__button {
    display: grid;
    grid-template-columns: 12px auto;
    gap: 6px;
    align-items: center;
    justify-content: center;
    background-color: $color-outer-space;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.1em;
    color: $color-white;
    white-space: nowrap;

    &:hover {
      opacity: 0.9;
    }

    svg {
      width: 100%;
      height: auto;
    }

    &_mobile {
      min-height: 40px;
      font-size: 11px;
      padding: 12px 11px;
      grid-template-columns: 14px auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &_short {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 40px;
      height: 40px;

      svg {
        width: 16px;
        height: auto;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-modal {
    position: absolute;
    right: 0;
    bottom: 53px;
    min-width: 410px;
    box-shadow: 0 0 19px rgba($color-black, 0.51);
    background: $color-shark;
    border-radius: 4px;
    z-index: 101;
    color: $color-white;
    padding: 16px;

    &_mobile {
      position: fixed;
      top: 50%;
      left: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
      min-width: calc(100vw - 24px);
      max-width: calc(100vw - 24px);
    }

    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      user-select: none;
      letter-spacing: 0.07em;

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 14px;
      }
    }

    &-body {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 12px;
      align-items: center;
      font-size: 14px;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 155px;
        height: 100%;
        font-weight: 700;
        font-size: 9px;
        padding: 14px 0;
        background: $color-white;
        border-radius: 8px;
        color: $color-outer-space;
        box-shadow: none;

        &:hover {
          background: rgba($color-white, 0.7);
        }

        &_copied {
          background: $color-outer-space;
          color: $color-white;
          pointer-events: none;
        }
      }

      &__input {
        padding: 8px 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $color-white;
        background: transparent;
        border-color: $color-outer-space;

        &:focus {
          border-color: $color-outer-space;
        }
      }
    }
  }
}

</style>
