<template>
  <Modal
    v-model:open="isShowModal"
    :title="modalTitle"
    :removeBtnText="'buttons.cancel'"
    :saveBtnText="saveBtnText"
    @save="addedSubscription"
    :hideButtons="subscriptions.length ? [] : (isMobile ? ['save', 'remove'] : ['remove'])"
    :mobileVersion="isMobile"
    :editMode="isMobile"
    @remove="closeForm"
  >
    <div
      class="subscription-modal"
      :class="{
        'subscription-modal_mobile': isMobile
      }"
      v-if="subscriptions.length"
    >
      <div
        class="subscription-modal__title"
        v-if="!isMobile"
      >
        {{ $t('titlePages.subscriptions') }}
      </div>

      <div
        class="subscription-list"
        :class="{
          'subscription-list_mobile': isMobile
        }"
      >
        <SubscriptionItem
          v-for="item in subscriptions"
          :item="item"
          :key="item.id"
          :studentCard="true"
          v-model:selectedSubscription="selectedSubscription"
        />
      </div>
      <transition name="bounce">
        <div
          class="subscription-modal__error lp-error"
          v-if="error"
        >
          <dangerIcon />
          {{ $t(error) }}
        </div>
      </transition>
    </div>
    <div
      class="lp-crm-subscription-apply-empty"
      v-else
    >
      <img
        class="lp-crm-subscription-apply-empty__image"
        :src="imgPlaceholder"
        alt="students"
      >
      <p class="lp-crm-subscription-apply-empty__title">
        {{ $t('crm.subscriptions.empty.title') }}
      </p>
      <p class="lp-crm-subscription-apply-empty__description">
        {{ $t('crm.subscriptions.empty.text') }}
      </p>
      <button
        class="lp-button lp-crm-subscription-apply-empty__button"
        @click="addedSubscription"
        v-if="isMobile"
      >
        {{ $t('crm.subscriptions.addSubscriptions') }}
      </button>
    </div>
  </Modal>
</template>

<script>
import { computed, onBeforeMount, ref, watch } from 'vue';
import Modal from '@/components/Main/Modal/Modal';
import { useStore } from 'vuex';
import LessonsPassTemplateApi from '@/api/CRM/lessonsPassTemplate';
import SubscriptionItem from '@/components/CRM/Subscriptions/SubscriptionItem';
import LessonsPassApi from '@/api/CRM/lessonsPass';
import { getErrorText } from '@/constants/utils/setErrorText';
import { dangerIcon } from '@/constants/icons/crm';
import imgPlaceholder from '@/assets/images/empty-subscriptions.svg';
import { useRouter } from 'vue-router';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentAddedSubscription',
  components: {
    Modal,
    SubscriptionItem,
    dangerIcon
  },
  props: {
    student: Object,
    openModal: Boolean
  },
  emits: ['update:openModal'],
  setup (props, { emit }) {
    const store = useStore();
    const isShowModal = ref(false);
    const subscriptions = computed(() => store.getters.subscriptions);
    const user = computed(() => store.getters.user);
    const selectedSubscription = ref('');
    const error = ref('');

    const modalTitle = computed(() => {
      if (!subscriptions.value.length) return '';
      return 'crm.subscriptions.modal.createTitle';
    });

    const saveBtnText = computed(() => {
      return subscriptions.value.length ? 'buttons.apply' : 'buttons.add';
    });

    const getSubscriptions = async () => {
      try {
        const teacherId = user.value._id;
        const { data } = await LessonsPassTemplateApi.getLessonsPassTemplate({ teacherId });
        const { resource = [] } = data;
        const [subscription = {}] = resource;
        selectedSubscription.value = subscription._id;
        store.dispatch('setSubscriptions', resource);
      } catch (err) {
        console.error(err);
      }
    };

    const closeForm = () => {
      isShowModal.value = false;
    };

    const students = computed(() => store.getters.students);

    const createLessonPassFromStudent = async () => {
      const { _id: crmStudentId } = props.student;
      const crmLessonPassTemplateId = selectedSubscription.value;
      try {
        const { data } =
             await LessonsPassApi.createLessonPassForStudent({ crmLessonPassTemplateId, crmStudentId });
        const { amountOfLessons } = data;
        const crmLessonPass = {
          ...data,
          availableToCreateLessonsCount: amountOfLessons
        };
        const student = {
          ...props.student,
          crmLessonPass
        };
        const indexCurrentStudent = students.value.findIndex(({ _id }) => _id === crmStudentId);
        students.value.splice(indexCurrentStudent, 1, student);
        closeForm();
      } catch (err) {
        console.error(err);
        error.value = getErrorText(err);
      }
    };

    const router = useRouter();

    const addedSubscription = () => {
      if (!subscriptions.value.length) {
        router.push({ name: 'CRMSubscriptions', params: { openForm: true } });
        return;
      }
      if (selectedSubscription.value) {
        createLessonPassFromStudent();
      } else {
        error.value = 'errors.selectedSubscribe';
      }
    };

    watch(isShowModal, (value) => {
      emit('update:openModal', value);
    });
    watch(() => props.openModal, (value) => {
      isShowModal.value = value;
    });

    onBeforeMount(() => {
      if (!subscriptions.value.length) {
        getSubscriptions();
      } else {
        if (!selectedSubscription.value) {
          const [subscription = {}] = subscriptions.value;
          selectedSubscription.value = subscription._id;
        }
      }
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      isShowModal,
      subscriptions,
      selectedSubscription,
      addedSubscription,
      error,
      closeForm,
      imgPlaceholder,
      modalTitle,
      saveBtnText
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.subscription-modal {
  position: relative;
  margin: 28px 20px 0;
  padding: 17px 20px 42px;
  border: 1px solid $color-alto;
  border-radius: 4px;

  &_mobile {
    margin: 0;
    padding: 28px 16px;
    border: none;
  }

  &__title {
    margin-bottom: 17px;
    @include global-font;
    font-size: 18px;
    font-weight: 800;
    line-height: 1.25;
    color: $color-mine-shaft-dark;
    letter-spacing: 0.07em;
    text-transform: uppercase;

  }

  &__error {
    top: auto;
    bottom: 12px;
    right: 20px;
    left: auto;
  }

}

.subscription-list {
  display: grid;
  grid-template-columns: repeat(3, 216px);
  grid-gap: 18px;

  &_mobile {
    grid-template-columns: repeat(auto-fill, minmax(216px, 1fr));
    grid-gap: 16px;
  }
}

.lp-crm-subscription-apply-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 40px 20px;
  width: 100%;
  height: 100%;
  color: $color-text;
  text-align: center;
  user-select: none;

  &__image {
    width: 385px;
    height: auto;
    object-fit: cover;
    margin-bottom: 30px;

    @media (max-width: 480px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 8px;
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    max-width: 350px;
    margin-bottom: 28px;
  }
}

</style>
<!-- Created by zas on 06.07.2021  -->
