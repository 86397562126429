import { ref } from 'vue';

const setTimeoutId = ref(null);

const scrollTo = (element, to, duration, clear) => {
  if (element === undefined) {
    element = window;
  }
  if (duration === 0) {
    return;
  }
  if (to < 0) to = 0;
  const difference = to - element.scrollY;
  const perTick = difference / duration * 10;
  if (clear) {
    clearTimeout(setTimeoutId);
  }
  setTimeoutId.value = setTimeout(() => {
    let newTop = element.scrollY + perTick;
    if (newTop < 0) newTop = 0;
    element.scrollTo({
      top: newTop
    });
    if (newTop === 0) return false;
    if (element.scrollY === to) return;
    scrollTo(element, to, duration - 10);
  }, 1);
};

export default scrollTo;