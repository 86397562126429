<template>
  <div
    class="student-subscription"
    :class="{
      'student-subscription_lessons': lessons,
      'student-subscription_mobile': isMobile
    }"
  >
    <div
      class="student-subscription__icon"
      v-if="!lessons && subscription.availableToCreateLessonsCount === subscription.amountOfLessons"
      @click="showConfirmDeleteSubscription"
      @mouseenter="openTooltip = true"
      @mouseleave="openTooltip = true"
    >
      <LPTooltip :text="$t('buttons.remove')">
        <closeModalIcon />
      </LPTooltip>
      <teleport
        to="#app"
        :disabled="!isMobile"
      >
        <transition :name="mobile ? 'fade' : 'bounce'">
          <ModalQuestion
            :class="{
              'student-subscription__modal-delete': !isMobile
            }"
            v-if="openDeleteSubscription"
            @accept="$emit('remove-subscription')"
            :question="deleteText"
            :mobile="isMobile"
            v-model:open="openDeleteSubscription"
          />
        </transition>
      </teleport>
    </div>

    <div
      class="student-subscription__title"
      :class="{
        'student-subscription__title_lessons': lessons,
        'student-subscription__title_mobile': isMobile
      }"
    >
      {{ subscription.title }}
    </div>
    <div
      class="student-subscription-row"
      :class="{
        'student-subscription-row_lessons': lessons,
        'student-subscription-row_lessons_mobile': lessons && isMobile,
        'student-subscription-row_mobile': isMobile
      }"
    >
      <div
        class="student-subscription-content"
        :class="{
          'student-subscription-content_mobile': isMobile
        }"
      >
        <div
          class="student-subscription-content-item"
          :class="{
            'student-subscription-content-item_lessons': lessons,
            'student-subscription-content-item_mobile': isMobile
          }"
        >
          <div
            class="student-subscription-content-item__label"
            :class="{
              'student-subscription-content-item__label_mobile': isMobile
            }"
          >
            {{ $t('crm.subscriptions.form.numberLessons') }}:
          </div>
          <div class="student-subscription-content-item__value">
            {{ subscription.amountOfLessons }}
          </div>
        </div>
        <div
          class="student-subscription-content-item"
          :class="{
            'student-subscription-content-item_lessons': lessons,
            'student-subscription-content-item_mobile': isMobile
          }"
        >
          <div
            class="student-subscription-content-item__label"
            :class="{
              'student-subscription-content-item__label_mobile': isMobile
            }"
          >
            {{ $t('crm.subscriptions.form.price') }}:
          </div>
          <div class="student-subscription-content-item__value">
            {{ passCost }} {{ subscription.currency }}
          </div>
        </div>
      </div>
      <div
        class="student-subscription-list"
        :class="{
          'student-subscription-list_lessons': lessons,
          'student-subscription-list_lessons_mobile': lessons && isMobile,
          'student-subscription-list_mobile': isMobile
        }"
      >
        <div
          v-for="item in subscription.amountOfLessons"
          :key="item"
          class="student-subscription-list__item"
          :class="{
            'student-subscription-list__item_finished': item < subscription.finishedLessonsCount,
            'student-subscription-list__item_lessons': lessons,
            'student-subscription-list__item_mobile': isMobile
          }"
        >
          <checkIcon
            v-if="item <= subscription.finishedLessonsCount"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { checkIcon } from '@/constants/icons/crm';
import { closeModalIcon } from '@/constants/icons';
import numeral from 'numeral';
import FORMATS from '@/constants/formats';
import { computed, ref } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import ModalQuestion from '@/components/Main/ModalQuestion/ModalQuestion';
import { useI18n } from 'vue-i18n';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentSubscription',
  components: {
    checkIcon,
    closeModalIcon,
    LPTooltip,
    ModalQuestion
  },
  props: {
    mobile: Boolean,
    subscription: Object,
    student: Object,
    lessons: {
      type: Boolean,
      default: false
    }
  },
  emits: ['remove-subscription'],
  setup (props) {
    const passCost = computed(() => {
      const passCost = props.subscription.passCost;
      return passCost ? numeral(passCost).format(FORMATS.FINANCE) : 0;
    });

    const openTooltip = ref(false);
    const openDeleteSubscription = ref(false);

    const showConfirmDeleteSubscription = () => {
      openDeleteSubscription.value = true;
    };

    const { t } = useI18n();
    const deleteText = computed(() => `${t('crm.subscriptions.deleteSubscription')} ${props.student.fullName}?`);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      passCost,
      openTooltip,
      showConfirmDeleteSubscription,
      openDeleteSubscription,
      deleteText,
      isMobile
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.student-subscription {
  position: relative;
  display: grid;
  grid-gap: 20px;
  color: $color-text;
  padding: 25px 0;
  margin-top: -25px;
  border-bottom: 1px solid $color-alto;
  margin-bottom: 26px;

  &_lessons {
    margin-top: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    border: none;
    grid-gap: 11px;
  }

  &_mobile {
    margin-top: 0;
  }

    &__icon {
    position: absolute;
    top: 26px;
    right: 0;
    cursor: pointer;
  }

  &__modal-delete {
    position: absolute;
    top: 0;
    right: 0!important;
    left: auto;
    min-width: 272px;
    max-width: 272px;
  }

  &__title {
    @include global-font;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    text-transform: uppercase;
    word-break: break-word;

    &_mobile {
      max-width: calc(100vw - 38px);
      word-break: break-word;
      padding-right: 15px;
    }

    &_lessons {
      font-size: 14px;
    }

  }
}

.student-subscription-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_mobile {
    flex-direction: column;
    align-items: flex-start;
    min-width: 100%;
  }

  &_lessons {
    display: grid;
    grid-gap: 18px;

    &_mobile {
      display: flex;
    }
  }

}

.student-subscription-list {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(10, 32px);

  &_mobile {
    width: 100%;
    grid-template-columns: repeat(auto-fill, 28px);
    margin-top: 18px;
  }

    &_lessons {
    grid-template-columns: repeat(10, 25px);

      &_mobile {
        margin-top: 0;
        justify-content: space-between;
      }
  }

  &__item {
    width: 32px;
    height: 32px;
    border: 1px solid $color-alto;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_mobile {
      width: 28px;
      height: 28px;
    }

    &_lessons {
      width: 25px;
      height: 25px;
    }

  }

}

.student-subscription-content {
  display: grid;
  grid-gap: 4px;

  &_mobile {
    min-width: 100%;
  }

}

.student-subscription-content-item {
  @include global-font;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;

  &_lessons {
    display: grid;
    grid-template-columns: 1fr auto;
    min-width: 100%;
    justify-content: space-between;
  }

  &_mobile {
    font-size: 12px;
  }

  &__label {
    width: 190px;
    min-width: 190px;
    margin-right: 7px;

   &_mobile {
      width: auto;
      min-width: auto;
      flex-grow: 1;
    }
  }

  &__value {
    padding: 3px 12px;
    background: $color-moon-raker;
    border-radius: 5px;
    color: $color-accent;
    white-space: nowrap;
  }

}

</style>

<style lang="scss">
@import '~@/sass/variables';

.student-subscription-list__item {
  svg {
    width: 16px;
    height: 16px;
  }
}

.student-subscription__icon {
  path {
    transition: fill 0.15s ease-in;
  }

  &:hover {
    path {
      fill: $color-silver-chalice;
    }
  }

}

</style>

<!-- Created by zas on 06.07.2021  -->
