const systemcrm = [
  {
    id: 1,
    text: 'landing.systemcrm.firstItem.name'
  },
  {
    id: 2,
    text: 'landing.systemcrm.secondItem.name'
  },
  {
    id: 3,
    text: 'landing.systemcrm.thirdItem.name'
  }
];
export default systemcrm;
