<template>
  <div>
    <SidebarCRM />
    <div class="lp-timetable-empty">
      <h1 class="lp-page-title lp-timetable-empty__page-title">
        {{ $t('crm.pages.lessons') }}
      </h1>
      <div class="lp-timetable-empty__image">
        <img
          :src="mobileCalendar"
          alt="placeholder"
        />
      </div>
      <div class="lp-timetable-empty__title">
        {{ $t('timetable.empty.title') }}
      </div>
      <div class="lp-timetable-empty__text">
        {{ $t('timetable.empty.text') }}
      </div>
      <button
        class="lp-button"
        @click="$emit('update:open', false)"
      >
        {{ $t('timetable.empty.button') }}
      </button>
    </div>
  </div>
</template>

<script>
import mobileCalendar from '@/assets/images/mobile-CRM-calendar.png';
import SidebarCRM from '@/components/CRM/Sidebar/SidebarCRM';

export default {
  name: 'TimetableWeekPlaceholder',
  components: {
    SidebarCRM
  },
  emits: ['update:open'],
  setup () {
    return {
      mobileCalendar
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-timetable-empty {
  @include global-font;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding: 0 20px;
  text-align: center;
  color: $color-text;

  &__page-title {
    position: fixed;
    top: 16px;
    left: 52px;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 35px;
    width: 100%;
    margin-bottom: 28px;
  }

  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 8px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    margin-bottom: 28px;
  }
}

</style>
