<template>
  <div
    class="lp-cropper"
    @click.stop
    v-click-outside="closeCropper"
  >
    <div
      class="lp-cropper-content"
      :class="{'lp-cropper-content_mobile': isMobile}"
    >
      <div
        class="lp-cropper-content-header"
        :class="{'lp-cropper-content-header_mobile': isMobile}"
      >
        <span
          class="lp-cropper-content-header__icon lp-cropper-content-header__icon_approve"
          :class="{
            'lp-cropper-content-header__icon_mobile': isMobile,
            'lp-cropper-content-header__icon_mobile-approve': isMobile
          }"
          @click.stop="saveImage"
        >
          <checkIcon
            class="lp-cropper-content-header__check"
            :class="{'lp-cropper-content-header__check_mobile': isMobile}"
          />
        </span>
        <span class="lp-cropper-content-header__title">{{ $t(title) }}</span>
        <span
          class="lp-cropper-content-header__icon lp-cross-button"
          :class="{'lp-cropper-content-header__icon_mobile': isMobile}"
          @click="closeCropper"
        >
          <closeModalIcon />
        </span>
      </div>
      <Cropper
        v-if="image"
        class="lp-cropper-content__resizer"
        :class="{'lp-cropper-content__resizer_mobile': isMobile}"
        :src="image"
        :minHeight="100"
        :stencilProps="{aspectRatio: 1}"
        :stencilComponent="CircleStencil"
        imageRestriction="fill-area"
        @change="changeCropper"
      />
      <div
        class="lp-cropper-content-footer"
        :class="{'lp-cropper-content-footer_mobile': isMobile}"
      >
        <button
          class="lp-button lp-cropper-content-footer__button"
          @click.stop="saveImage"
        >
          {{ $t('buttons.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import { closeModalIcon } from '@/constants/icons';
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useStore } from 'vuex';
import { checkIcon } from '@/constants/icons/crm';
import MobileDetect from 'mobile-detect';

export default {
  name: 'CropperImage',
  components: {
    checkIcon,
    Cropper,
    closeModalIcon
  },
  props: {
    image: String,
    file: Object,
    temporaryBlob: String
  },
  emits: ['update:file', 'update:open', 'update:temporaryBlob'],
  setup (props, { emit }) {
    const store = useStore();
    const title = 'cropper.cutImage';
    const imageResize = ref(null);

    const user = computed(() => store.getters.user);

    const closeCropper = () => {
      emit('update:file', null);
      emit('update:open', false);
    };

    const changeCropper = ({ canvas }) => {
      if (canvas) {
        imageResize.value = canvas.toDataURL();
      } else {
        console.error('Not found "canvas"');
      }
    };

    const saveImage = async () => {
      user.value.uploadFile = props.temporaryBlob;
      const blob = await fetch(imageResize.value).then((r) => r.blob());
      const blobType = blob.type.replace(/.+\//g, '');
      blob.originalname = props.file.name.replace(/([a-z|A-Z]+)$/g, blobType);
      emit('update:file', blob);
      emit('update:temporaryBlob', imageResize.value);
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const hideBodyMobile = (val) => {
      const el = document.querySelector('.mc-container');
      if (!el || !isMobile.value) return;
      el.style.opacity = val ? 0 : 1;
      val ? el.style.transform = 'scale(0)' : el.style.removeProperty('transform');
      el.style.pointerEvents = val ? 'none' : 'auto';
      el.style.height = val ? 0 : '100%';
    };

    onBeforeMount(() => {
      hideBodyMobile(true);
    });

    onBeforeUnmount(() => {
      hideBodyMobile(false);
    });

    return {
      isMobile,
      CircleStencil,
      title,
      changeCropper,
      closeCropper,
      saveImage
    };
  }
};
</script>

<style lang="scss">
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-cropper {
  @include global-font;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color-black, 0.5);
  z-index: 100;

  &-content {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 95vh;
    background-color: $color-white;
    border-radius: 4px;
    width: 400px;

    &_mobile {
      max-height: none;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
      min-width: 100vw;
      justify-content: center;
      width: 100%;
    }

    &-header {
      display: grid;
      grid-template-columns: 20px 1fr 20px;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      border-bottom: 1px solid $color-gallery;

      &_mobile {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        padding: 16px;
        background-color: $color-white;
      }

      &__title {
        grid-column: 2;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.07em;
        user-select: none;
      }

      &__check {
        display: none;

        &_mobile {
          display: block;
        }
      }

      &__icon {
        grid-column: 3;
        grid-row: 1;
        width: 22px;
        height: 22px;
        margin-left: 0;

        &_approve {
          grid-column: 1;
        }

        &_mobile {
          grid-column: 1;
        }

        &_mobile-approve {
          grid-column: 3;
        }
      }
    }

    &__resizer {
      position: relative;
      margin: 24px 25px 0;
      height: 350px;
      width: 350px;
      border-radius: 4px;
      overflow: hidden;

      &_mobile {
        margin: 93px 0 41px;
        width: 100%;
        min-width: 100vw;
        height: calc(100vh - 145px);
      }
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      padding: 30px 20px 18px;

      &_mobile {
        display: none;
      }
    }
  }
}

</style>
