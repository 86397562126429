<template>
  <h2
    v-if="!isMobile"
    class="lp-profile-base-header__title"
    :class="{
      'lp-profile-base-header__title_mobile': isMobile
    }"
  >
    {{ $t('profile.settings') }}
  </h2>

  <SettingsPrice
    @save="$emit('save')"
    @close="$emit('close')"
    @openEdit="$emit('update:editable', true)"
    :noProfile="noProfile"
    :editable="editable"
  />
</template>

<script>
import SettingsPrice from '@/components/CRM/Settings/ProfileSettings/SettingsPrice/SettingsPrice';

export default {
  name: 'EditSettings',
  components: {
    SettingsPrice
  },
  props: {
    profileBasicInputs: Array,
    isMobile: Boolean,
    noProfile: Boolean,
    editable: Boolean
  },
  emits: ['save', 'close']
};
</script>

<style lang="scss" scoped>
.lp-profile-base-header__title {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
