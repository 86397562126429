<template>
  <div
    class="lp-crm-settings-cost"
    :class="{'lp-crm-settings-cost_mobile': isMobile}"
    ref="inputRef"
  >
    <CustomInput
      class="lp-crm-settings-cost__input"
      v-model="lessonCost"
      :label="'crm.settings.cost'"
      :type="'number'"
      :inputmode="'decimal'"
      :noWrap="true"
      :error="error"
      :required="true"
      :postfix="currency"
      @focus="$emit('update:error', '')"
      @submit="$emit('submit')"
    >
    </CustomInput>
  </div>
</template>

<script>
import CustomInput from '@/components/Main/Inputs/CustomInput';
import { computed, onBeforeMount, ref, watch } from 'vue';
import MobileDetect from 'mobile-detect';
export default {
  name: 'LessonCost',
  components: { CustomInput },
  props: {
    error: String,
    defaultValue: [Number, String],
    currency: String
  },
  emits: ['update:cost', 'update:error', 'submit'],
  setup (props, { emit }) {
    const lessonCost = ref(null);

    const emitValue = () => emit('update:cost', lessonCost.value);

    watch(lessonCost, emitValue);

    const setDefault = () => {
      if (props.defaultValue) lessonCost.value = props.defaultValue;
      emitValue();
    };

    watch(() => props.defaultValue, setDefault);

    onBeforeMount(setDefault);

    const inputRef = ref(null);
    watch(() => props.error, (val) => {
      if (!val || !inputRef.value) return;
      inputRef.value.scrollIntoView({ block: 'center' });
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      lessonCost,
      inputRef
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~styles/_variables.scss';

.lp-crm-settings-cost {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
  grid-gap: 10px;
  color: $color-text;

  &_mobile {
    grid-template-columns: 160px auto;

    @media (max-width: 768px) {
      grid-template-columns: 100% auto;
    }
  }

  &__input {
    width: 180px;
  }
}

</style>
