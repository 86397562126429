import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';
import statusStudentList from '@/constants/crm/studentStatuses';
import { isEmail, isUrl } from '@/constants/utils/validationRegex';

const genders = [
  { label: 'global.male', id: 'Male', value: false, name: 'gender' },
  { label: 'global.female', id: 'Female', value: true, name: 'gender' }
];

const inputs = [
  {
    autofocus: true,
    required: true,
    type: 'text',
    label: 'crm.students.form.name',
    modelName: 'fullName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyName',
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'number',
    inputmode: 'decimal',
    label: 'crm.students.form.individualLessonPrice',
    modelName: 'individualLessonPrice',
    modelValue: '',
    emptyError: 'errors.correctAmount',
    noWrap: true,
    validError: 'errors.correctAmount',
    isValid: (val) => !val || !isNaN(+val),
    getValue: (val) => val ? +val : null,
    getFullObject: (val) => val ? +val : null,
    style: {
      gridColumn: '1/2'
    }
  },
  {
    type: 'currency',
    disabled: true,
    modelName: 'currency',
    modelValue: '',
    style: {
      maxWidth: '106px',
      gridColumn: '2'
    }
  },
  {
    type: 'selectStatus',
    label: 'crm.students.form.status',
    mode: 'status',
    modelName: 'status',
    modelValue: statusStudentList[0],
    getValue: (val) => val.id,
    getFullObject: (val) => statusStudentList.find(item => item.id === val) || statusStudentList[0],
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'date',
    label: 'crm.students.form.birthday',
    modelName: 'birthday',
    modelValue: {},
    fixed: true,
    format: 'DD MMMM, YYYY',
    additionalComponent: 'BirthdayNotify',
    birthdayNotify: true,
    maxDate: new Date(),
    manualEditableDate: true,
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'radio',
    label: 'crm.students.form.gender',
    options: genders,
    modelName: 'gender',
    modelValue: 'Female',
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'phone',
    inputmode: 'tel',
    label: 'crm.students.form.phone',
    modelName: 'phone',
    modelValue: {
      number: '',
      country: ''
    },
    numberBusy: {
      message: 'crm.students.form.numberBusy',
      isInvalid: false
    },
    fixed: true,
    isValid: (val = {}) => val.number && (val.number.length === val.placeholder.length),
    validError: 'errors.emptyPhone',
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'email',
    inputmode: 'email',
    label: 'crm.students.form.email',
    placeholder: 'crm.students.form.emailPlaceholder',
    modelName: 'email',
    modelValue: '',
    emptyError: 'errors.emptyEmail',
    validError: 'errors.emptyEmail',
    isValid: (val) => !val || isEmail(val),
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'socialNetworks',
    label: 'crm.students.form.socialNetworks.label',
    modelName: 'socialMedia',
    modelValue: [],
    emptyError: 'errors.emptyUrl',
    validError: 'errors.emptyUrl',
    isValidArray: (val) => {
      const errorArray = [];
      val.forEach((field, i) =>  {
        if (!field.socialMediaLink || !isUrl(field.socialMediaLink)) {
          errorArray.push(i);
        }
      });
      return errorArray;
    },
    getValue: (val) => {
      val.forEach((item) =>  {
        const url = item.socialMediaLink;
        const protocols = /http:\/\/|https:\/\//gmi;
        const hasProtocols = protocols.test(url);
        item.socialMediaLink = !hasProtocols ? `https://${url}` : url;
      });
      return val;
    },
    style: {
      gridColumn: '1/5'
    }
  },
  {
    type: 'note',
    label: 'crm.students.form.note',
    modelName: 'note',
    modelValue: '',
    style: {
      gridColumn: '1/5'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('student-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
