<template>
  <div
    class="stp-payments"
    :class="{'stp-payments__mobile' : isMobile}"
  >
    <div
      class="stp-payments-header"
      :class="{'stp-payments-header__mobile' : isMobile}"
    >
      <span class="stp-payments-header_title">
        {{ $t('student.payments.title') }}
      </span>
      <span>
        <ColoredLabel
          class="stp-payments-header_label"
          :text="`${totalPaymentValue} ${currency || ''}`"
        />
      </span>
    </div>
    <div class="stp-payments-grid">
      <div
        class="stp-payments-grid-header"
        :class="{'stp-payments-grid-header__mobile' : isMobile}"
      >
        <div class="stp-payments-grid-header_first">
          {{ $t('student.payments.date') }}
        </div>
        <div class="stp-payments-grid-header_cost">
          {{ $t('student.payments.cost') }}
        </div>
        <div> {{ $t('student.payments.currency') }}</div>
      </div>
      <div
        class="stp-payments-grid-container"
        :class="{'stp-payments-grid-container__mobile' : isMobile}"
      >
        <div
          v-for="payment in payments"
          :key="payment"
          class="stp-payments-grid-container-item"
          :class="{'stp-payments-grid-container-item__mobile' : isMobile}"
        >
          <div class="stp-payments-grid-container-item_first">
            {{ payment.date }}
          </div>
          <div
            class="stp-payments-grid-container-item_second"
            :class="{'stp-payments-grid-container-item_subscription' : payment.isSubscription}"
          >
            {{ payment.value }}
            <LPTooltip
              v-if="payment.isSubscription"
              :class="'stp-completed-table-content-item_last__tooltip'"
              :text="$t('crm.subscriptions.tooltip')"
            >
              <subscriptionsIcon />
            </LPTooltip>
          </div>
          <div>{{ payment.currency }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColoredLabel from '@/components/StudentProfile/ColoredLabel';
import { computed } from 'vue';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { subscriptionsIcon } from '@/constants/icons/crm';

export default {
  name: 'PaymentHistory',
  components: { ColoredLabel, LPTooltip, subscriptionsIcon },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    },
    lang: {
      type: String,
      required: true
    },
    payments: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const totalPaymentValue = computed(() =>  props.payments.
      reduce((acc,{ value }) => acc + value, 0).toString());

    return {
      totalPaymentValue
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~styles/_variables.scss";

.stp-payments {
  padding: 40px 5px 30px 40px;
  max-height: 406px;

  &__mobile {
    padding: 27px 0;
  }

  &-header {
    display: flex;

    &__mobile {
      margin-left: 12px;
    }

    &_title {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }

    &_label {
      margin-left: 20px;
    }
  }

  &-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    &-header {
      width: calc(100% - 32px);
      align-self: flex-start;
      display: grid;
      grid-template-columns: 4fr 1.5fr 3.5fr;
      text-align: right;
      padding: 12px 20px;
      align-items: center;
      background-color: $color-moon-raker;
      color: $color-purple;
      font-weight: 700;
      font-size: 13px;
      line-height: 16.25px;

      &__mobile {
        width: 100%;
        padding: 12px;
      }

      &_first {
        text-align: left;
      }

      &_cost {
        text-align: left;
      }
    }

    &-container {
      padding-right: 28px;
      width: 100%;
      max-height: 230px;
      overflow: scroll;

      &__mobile {
        padding-right: unset;
      }

      &-item {
        display: grid;
        grid-template-columns: 4fr 1.5fr 3.5fr;
        padding: 27px 24px;
        border-bottom: 1px solid $color-alto;
        text-align: right;

        &:last-child {
          border-bottom: unset;
        }

        &_first {
          text-align: left;
        }

        &_second {
          text-align: left;
        }

        &_subscription {
          display: flex;
          grid-gap: 5px;
        }

        &__mobile {
          padding: 27px 12px;
        }
      }
    }
  }
}

</style>
