<template>
  <div
    class="lp-date-controls"
    :class="{'lp-date-controls_widget': widget}"
  >
    <div class="lp-date-controls-day">
      <LPTooltip :text="prevText">
        <div
          class="lp-date-controls-day-navigate lp-date-controls-day-navigate_prev"
          :class="{'lp-date-controls-day-navigate_disabled': minDate === date}"
          @click="addDate(-1)"
        >
          <arrowCalendarIcon class="lp-date-controls-day-navigate__icon" />
        </div>
      </LPTooltip>
      <div
        class="lp-date-controls-day__today"
        :class="{'lp-date-controls-day__today_disabled': isToday}"
        @click="addDate(0)"
      >
        {{ $t('calendar.today') }}
      </div>
      <LPTooltip :text="nextText">
        <div
          class="lp-date-controls-day-navigate lp-date-controls-day-navigate_next"
          @click="addDate(1)"
        >
          <arrowCalendarIcon class="lp-date-controls-day-navigate__icon" />
        </div>
      </LPTooltip>
    </div>
    <div
      class="lp-date-controls-tabs"
      v-if="!widget"
    >
      <button
        class="lp-date-controls-tabs__item"
        :class="{'lp-date-controls-tabs__item_active': activeTab === tab.id}"
        @click="setActiveTab(tab.id)"
        v-for="tab of tabs"
        :key="tab.id"
      >
        {{ tab.title }}
      </button>
    </div>
  </div>
</template>

<script>
import { arrowCalendarIcon } from '@/constants/icons';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { capitalize, get } from 'lodash';
import moment from 'moment';
import { useRouter } from 'vue-router';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'DateControls',
  components: {
    LPTooltip,
    arrowCalendarIcon
  },
  props: {
    widget: Boolean,
    date: String,
    unit: String
  },
  emits: ['update:tab', 'update:date'],
  setup (props, { emit }) {
    const minDate = computed(() => props.widget ? moment().format(isoDate) : '');

    const { t, tm } = useI18n();
    const isoDate = 'YYYY-MM-DD';

    const router = useRouter();
    const activeTab = ref(get(router, 'currentRoute.value.params.unit', props.unit || 'month'));
    const setActiveTab = (id) => {
      activeTab.value = id;
      emit('update:tab', id);
    };
    const tabs = [
      { id: 'day', title: t('calendar.day') },
      { id: 'week', title: t('calendar.week') },
      { id: 'month', title: t('calendar.month') }
    ];

    const isToday = computed(() => {
      const today = moment().format(isoDate);
      const currentDay = moment(props.date).format(isoDate);
      return today === currentDay;
    });

    const prevText = computed(() => {
      const prev = tm('calendar.prev')[activeTab.value === 'week' ? 1 : 0];
      const unit = tabs.find((item) => item.id === activeTab.value);
      return `${capitalize(prev)} ${unit.title.toLowerCase()}`;
    });

    const nextText = computed(() => {
      const next = tm('calendar.next')[activeTab.value === 'week' ? 1 : 0];
      const unit = tabs.find((item) => item.id === activeTab.value);
      return `${capitalize(next)} ${unit.title.toLowerCase()}`;
    });

    const addDate = (val) => {
      if (minDate.value === props.date && val < 0) return;
      const timeUnit = activeTab.value || 'month';
      const momentDate = val ? moment(props.date ) : moment();
      if (val < 0) momentDate.subtract(1, timeUnit);
      if (val > 0) momentDate.add(1, timeUnit);
      emit('update:date', momentDate.format(isoDate));
    };

    return {
      minDate,
      isToday,
      prevText,
      nextText,
      activeTab,
      tabs,
      addDate,
      setActiveTab
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-date-controls {
  @include global-font;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px;
  align-items: center;
  justify-content: end;

  &_widget {
    grid-template-columns: auto;
  }

  &-day {
    display: grid;
    grid-template-columns: 28px auto 28px;
    align-items: center;
    padding: 7px 3px;
    border: 1px solid $color-border-input;
    box-sizing: border-box;
    border-radius: 4px;
    user-select: none;

    &__today {
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: $color-text;
      transition: color 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        color: $color-accent;
      }

      &_disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
          color: $color-text;
        }
      }
    }

    &-navigate {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 20px;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;

      &_next &__icon {
        transform: scale(-1);
      }

      &__icon {
        width: 12px;
        height: 12px;
        fill: $color-accent;
      }

      &:hover &__icon {
        fill: $color-accent-hover;
      }

      &_disabled {
        cursor: not-allowed;

        &:hover &__icon {
          fill: $color-silver;
        }
      }

      &_disabled &__icon {
        fill: $color-silver;
      }
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      position: relative;
      padding: 7px 12px;
      border: 1px solid $color-border-input;
      background-color: transparent;
      color: $color-text;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      margin-right: -1px;
      transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;
      cursor: pointer;
      z-index: 1;
      user-select: none;

      &_active,
      &:hover {
        border-color: $color-accent;
        color: $color-accent;
        z-index: 2;
      }

      &_active {
        background-color: $color-moon-raker;
        pointer-events: none;
      }

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

</style>
