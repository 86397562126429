<template>
  <div>
    <Modal
      :title="'improveForm.titleModal'"
      @save="saveForm"
      @remove="closeForm"
      :hideButtons="['remove']"
      :saveBtnText="sent ? 'buttons.ok' : 'buttons.send'"
      v-model:open="openModal"
      :editMode="true"
      :mobileVersion="isMobile"
    >
      <transition
        name="bounce"
        mode="out-in"
      >
        <div
          class="lp-improve-sent"
          :class="{'lp-improve-sent_mobile': isMobile}"
          v-if="sent"
        >
          <img
            class="lp-improve-sent__img"
            :class="{'lp-improve-sent__img_mobile': isMobile}"
            :src="successImg"
            alt="placeholder"
          />
          <p class="lp-improve-sent__title">
            {{ $t('improveForm.sentTitle') }}
          </p>
          <p class="lp-improve-sent__info">
            {{ $t('improveForm.sentInfo') }}
          </p>
        </div>
        <div
          class="lp-improve-form"
          :class="{'lp-improve-form_mobile': isMobile}"
          v-else
        >
          <h1
            class="lp-improve-form__title"
            :class="{'lp-improve-form__title_mobile': isMobile}"
          >
            {{ $t('improveForm.title') }}
          </h1>
          <Form
            @submit="saveForm"
            :inputs="improveInputs"
          />
        </div>
      </transition>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Main/Modal/Modal';
import Form from '@/components/Main/Form/Form';
import { computed, ref, watch } from 'vue';
import improveInputs from '@/constants/inputs/improveInputs';
import validateProfile from '@/constants/validatesForm/validateProfile';
import EmailApi from '@/api/CRM/email';
import yandexTracker from '@/constants/yandexTracker/yandexTracker';
import successImg from '@/assets/images/success.png';
import { useStore } from 'vuex';
import { set, get } from 'lodash';
import MobileDetect from 'mobile-detect';

export default {
  name: 'ImproveServiceForm',
  components: { Modal, Form },
  props: {
    openForm: Boolean
  },
  emits: ['update:openForm'],
  setup (props, { emit }) {
    const store = useStore();
    const user = computed(() => store.getters.user);

    const error = ref('');

    const openModal = ref(false);
    watch(openModal, (value) => {
      sent.value = false;
      emit('update:openForm', value);
    });
    watch(() => props.openForm, (value) => {
      sent.value = false;
      error.value = '';
      openModal.value = value;
      setDefaultValues();
    });

    const setDefaultValues = () => {
      improveInputs.forEach((input) => {
        const value = input.defaultValue;
        input.error = false;
        input.modelValue = input.getFullObject ? input.getFullObject(value) : value;
      });
    };

    const generateTextMail = ({ name, wish, email = '-' }) => {
      const fields = [`Имя: ${name}`, `Email: ${email}`, `Пожелание: ${wish}`];
      return fields.join(';\n');
    };

    const generateHtmlMail = ({ name, wish, email = '-' }) => {
      const fields = [`<p>Имя: ${name}</p>`, `<p>Email: ${email}</p>`, `<p>Пожелание: ${wish}</p>`];
      return fields.join('');
    };

    const saveForm = async () => {
      if (sent.value) {
        closeForm();
        return;
      }
      const body = validateProfile(improveInputs);
      if (!body) return;
      set(body, 'email', get(user.value, 'email', 'n/a'));
      set(body, 'name', `${get(user.value, 'firstName', '')} ${get(user.value, 'lastName', '')}`);
      sent.value = true;
      try {
        await EmailApi.sendEmail({
          subject: 'Улучшить сервис',
          text: generateTextMail(body),
          html: generateHtmlMail(body)
        });
        yandexTracker.improve();
      } catch (e) {
        console.error(e);
      }
    };

    const closeForm = () => {
      openModal.value = false;
    };

    const sent = ref(false);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      improveInputs,
      openModal,
      saveForm,
      closeForm,
      sent,
      successImg
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-improve-form {
  padding: 24px 20px 0;
  min-width: 635px;

  &_mobile {
    min-width: auto;
  }

  &__title {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    text-align: center;
    white-space: pre-line;

    &_mobile {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.lp-improve-sent {
  padding: 52px 142px 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $color-text;

  &_mobile {
    padding: 52px 22px 30px;
  }

  &__img {
    margin-bottom: 32px;
    max-width: 424px;
    height: auto;
    object-fit: cover;

    &_mobile {
      max-width: calc(100vw - 40px);
    }
  }

  &__title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
  }

  &__info {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
  }
}

</style>
