<template>
  <div
    class="strengthscrm"
    id="strengthscrm"
  >
    <div class="strengthscrm__container">
      <div class="strengthscrm__title">
        {{ $t('landing.strengthscrm.name.first') }}
        <span> {{ $t('landing.strengthscrm.name.second') }} </span>
      </div>
      <div class="strengthscrm__list">
        <Carousel :settings="settingsSlider">
          <Slide
            v-for="item in [0, 1]"
            :key="item.id"
          >
            <div
              class="strengthscrm-item"
              :class="{
                'strengthscrm-first-item': item === 0,
                'strengthscrm-last-item': item === 1
              }"
            >
              <template v-for="(strength, index) of strengthscrm">
                <div
                  class="strengthscrm-card"
                  v-if="
                    (item * (strengthscrm.length - 1)) / 2 <= index &&
                      index < strengthscrm.length / (2 - item)
                  "
                  :key="strength.id"
                >
                  <div class="strengthscrm-card__icon">
                    <img
                      :src="
                        require(`@/assets/images/landing/strengthscrm/${strength.image}.svg`)
                      "
                      alt="user"
                    />
                  </div>
                  <div class="strengthscrm-card__text">
                    {{ $t(strength.name) }}
                  </div>
                </div>
              </template>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import strengthscrm from '@/constants/landing/strengthscrm';

export default {
  name: 'StrengthsCRM',
  components: {
    Carousel,
    Slide
  },
  props: {
    currentLanguage: Boolean
  },
  setup () {
    const settingsSlider = reactive({
      itemsToShow: 1.07,
      snapAlign: 'center',
      wrapAround: false
    });
    return {
      strengthscrm,
      settingsSlider
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.strengthscrm {
  width: 100%;
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;

  &__container {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 12px;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 28px rgba($color-black, 0.08);
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    background-image: url('~@/assets/images/landing/strengthscrm/strengthscrm-mobile.jpg');

    @media (max-width: 375px) {
      background-size: auto 100%;
    }
  }

  &__title {
    width: 100%;
    max-width: 320px;
    margin-top: 32px;
    padding-left: 24px;
    align-self: flex-start;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    text-align: start;
    color: $color-white;
  }

  &__list {
    position: relative;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
  }
}

.strengthscrm-item {
  width: calc(100% - 36px);
  height: 216px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.strengthscrm-card {
  width: 100%;
  height: 60px;
  margin-bottom: 16px;
  padding: 12px;
  position: relative;
  display: flex;
  align-items: center;
  background: $color-white;
  box-shadow: 0 0 28px rgba($color-black, 0.08);
  border-radius: 8px;

  &__icon {
    display: flex;
    width: 36px;
    min-width: 36px;
    align-items: center;

    img {
      width: 36px;
    }
  }

  &__text {
    margin-left: 16px;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    text-align: start;
  }
}

.strengthscrm-first-item {
  margin-left: 24px;
  margin-right: 5px;
}

.strengthscrm-last-item {
  margin-right: 24px;
  margin-left: 5px;
}
</style>
