<template>
  <button
    class="btn"
    :class="{active: !isDisabled}"
    :disabled="isDisabled"
    @click="$emit('callback')"
  >
    {{ text.toUpperCase() }}
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    text: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['callback']
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

.btn{
  display: inline-block;
  padding: 16px 38px;
  background-color: $color-purple;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 17.5px;
  font-size: $button-font-size;
  border-radius: 4px;
  color: rgba($color-white, 0.4);

  &.active {
    color: $color-white;
    cursor: pointer;
  }
}
</style>
