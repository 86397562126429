<template>
  <div>
    <div class="lp-calendar-lesson-card-cost">
      {{ $t('calendar.lesson.cost') }}:
      <span
        class="lp-calendar-lesson-card-cost__value"
        :class="{
          'lp-calendar-lesson-card-cost__value_trial': isTrialLesson,
          'lp-calendar-lesson-card-cost__value_trial-mobile': isTrialLesson && mobile

        }"
      >
        <template v-if="isTrialLesson">
          <template v-if="mobile">
            {{ $t('calendar.lesson.trial') }}
          </template>
          <LPTooltip
            :text="$t('crm.lessons.form.trial')"
            v-else
          >
            <TrialIcon />
          </LPTooltip>
        </template>
        <template v-else>
          {{ cost }} {{ currency }}
        </template>
      </span>
    </div>
    <div
      v-if="lesson.crmStudents.length > 1 && !isTrialLesson"
      class="lp-calendar-lesson-card-cost__note"
    >
      *{{ !isLessonWithPass ? $t('crm.lessons.form.groupLesson') :
        $t('crm.lessons.form.groupLesson') + ' ' + $t('crm.lessons.form.withoutPass')
      }}
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { computed, onBeforeMount } from 'vue';
import SettingsApi from '@/api/CRM/settings';
import { useStore } from 'vuex';
import FORMATS from '@/constants/formats';
import numeral from 'numeral';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { TrialIcon } from '@/constants/icons/crm';

export default {
  name: 'CostLessonCard',
  components: {
    LPTooltip,
    TrialIcon
  },
  props: {
    lesson: Object,
    mobile: Boolean
  },
  setup (props) {
    const cost = computed(() => {
      const value = get(props.lesson, 'crmLessonCost', 0);
      return numeral(value).format(FORMATS.FINANCE);
    });
    const currency = computed(() => get(settings.value, 'currentCurrency', ''));

    const isTrialLesson = computed(() => {
      const { isTrial } = props.lesson;
      return isTrial;
    });

    const isLessonWithPass = computed(() => {
      const lessonHasPass = props.lesson.crmLessons.find(item => {
        return item.crmLessonPassId;
      });
      return !!lessonHasPass;
    });

    const store = useStore();
    const settings = computed(() => store.getters.settings);
    const setSettings = (data) => store.dispatch('setSettings', data);
    const getSettings = async () => {
      if (settings.value._id) return;
      try {
        const { data } = await SettingsApi.getSettings();
        await setSettings(data);
      } catch (e) {
        console.error(e);
      }
    };

    onBeforeMount(getSettings);

    return {
      cost,
      currency,
      isTrialLesson,
      isLessonWithPass
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-calendar-lesson-card-cost {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 125%;
  color: $color-text;

  &__value {
    font-weight: 700;
    margin-left: 4px;

    &_trial {
      display: inline-flex;
    }

    &_trial-mobile {
      margin-left: 5px;
      padding: 4px 8px;
      border-radius: 3px;
      background: $color-supernova;
      font-size: 12px;
    }

  }

  &__note {
    display: block;
    margin-top: 5px;
    font-size: 12px;
    color: $color-gray;
  }
}
</style>
