<template>
  <div
    class="lp-webcam-settings"
    v-click-outside="closeSettings"
  >
    <LPTooltip :text="$t('roomSettings.video.title')">
      <div class="lp-webcam-settings-button">
        <!--:class="{'lp-webcam-settings-button_mobile': isMobile}"-->
        <div
          class="lp-webcam-settings-button__camera"
          :class="{
            'lp-webcam-settings-button__camera_mute': muteCamera
          }"
          @click="onMuteCamera"
        >
          <cameraIcon />
        </div>
        <div
          class="lp-webcam-settings-button__more"
          @click="openSettings = !openSettings"
        >
          <arrowMoreIcon />
        </div>
      </div>
    </LPTooltip>
    <transition :name="isMobile ? 'fade-fast' : 'bounce'">
      <WebcamSettingsPopup
        v-if="openSettings"
        v-model:open="openSettings"
        :open="openSettings"
        :mobile="isMobile"
        :preview="preview"
      />
    </transition>
  </div>
</template>

<script>
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';
import { cameraIcon, arrowMoreIcon } from '@/constants/icons';
import { computed, ref, watch } from 'vue';
import WebcamSettingsPopup from '@/components/VideoCall/VideoCallSettings/WebcamSettings/WebcamSettingsPopup';
import { useStore } from 'vuex';
import { emitRtcEvent } from '@/constants/_rtc/utils/helpers';
import SOCKET_EVENTS from '@/constants/enums/events';

export default {
  name: 'WebcamSettings',
  components: { WebcamSettingsPopup, LPTooltip, cameraIcon, arrowMoreIcon },
  props: {
    preview: Boolean
  },
  setup () {
    const store = useStore();
    const connection = computed(() => store.getters.rtcConnect);
    const videoSettings = computed(() => store.getters.videoSettings);

    const openSettings = ref(false);
    const closeSettings = () => {
      if (isMobile.value) return;
      openSettings.value = false;
    };

    const muteCamera = ref(false);
    const onMuteCamera = () => {
      // TODO вкл/выкл свою камеру
      muteCamera.value = !muteCamera.value;
      connection.value.attachStreams.forEach(stream => {
        stream.getVideoTracks().forEach(track => {
          track.enabled = !muteCamera.value;
        });
      });
      videoSettings.value.muteLocal = muteCamera.value;
      emitRtcEvent({ event: SOCKET_EVENTS.VIDEO_MUTE, value: muteCamera.value, store });
    };

    watch(() => videoSettings.value.muteLocal, () => {
      muteCamera.value = videoSettings.value.muteLocal;
    } );

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      openSettings,
      closeSettings,
      muteCamera,
      onMuteCamera
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-webcam-settings {
  position: relative;

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    &__camera, &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: $color-outer-space;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: inset 5px 5px 10px rgba($color-black, 0.18), 0 8px 10px rgba($color-cod-gray, 0.18);
      }
    }

    &__camera {
      position: relative;
      width: 36px;
      border-radius: 8px 0 0 8px;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        top: auto;
        left: auto;
        width: 3px;
        height: 0;
        background-color: $color-red;
        transform: rotate(45deg);
        transition: 0.3s;
      }

      &_mute {
        &:after {
          height: 55px;
        }
      }
    }

    &__more {
      width: 26px;
      border-radius: 0 8px 8px 0;
      border-left: 1px solid rgba($color-white, 0.2);
    }

    &_mobile {
      height: 50px;
    }

    &_mobile &__camera {
      width: 50px;
    }

    &_mobile &__more {
      width: 38px;
    }
  }
}

</style>
