import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getTimetable ({ start, end, teacherId }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`startDate=${start}`, `endDate=${end}`];
    return instCred.get(`crm/schedule/all/${teacherId}?${query.join('&')}`);
  },
  deleteTimetableById (id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`crm/schedule/${id}`);
  },
  createTimetable (body) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/schedule/create-rules', body);
  },
  updateTimetable (body, id) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.patch(`crm/schedule/${id}`, body);
  },
  getCount () {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.get('crm/schedule/count');
  }
  // getTemplate (teacherId) {
  //   const instCred = axios.create({
  //     baseURL: MUSIO_SERVER,
  //     headers: {
  //       Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
  //     }
  //   });
  //   return instCred.get(`crm/schedule/template/${teacherId}`);
  // },
  // createTemplate (body) {
  //   const instCred = axios.create({
  //     baseURL: MUSIO_SERVER,
  //     headers: {
  //       Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
  //     }
  //   });
  //   return instCred.post('crm/schedule/template/create', body);
  // },
  // deleteTimetable (body) {
  //   const instCred = axios.create({
  //     baseURL: MUSIO_SERVER,
  //     headers: {
  //       Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
  //     }
  //   });
  //   return instCred.post('crm/schedule/delete', body);
  // }
};
