import { cloneDeep, find, uniqueId, get, set } from 'lodash';
import { reactive } from 'vue';
import { getCountry } from '@/constants/utils/getValueById';
import { isEmail } from '@/constants/utils/validationRegex';
import country from '@/constants/country';
import checkMobileByScreen from '@/constants/utils/checkMobileByScreen';

const inputs = [
  {
    autofocus: true,
    required: true,
    type: 'text',
    label: 'inputLabels.firstName',
    modelName: 'firstName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyName'
  },
  {
    type: 'text',
    label: 'inputLabels.lastName',
    modelName: 'lastName',
    modelValue: '',
    upperFirst: true,
    emptyError: 'errors.emptyLastName',
    style: {
      gridColumn: '2'
    }
  },
  {
    type: 'countrySelect',
    label: 'inputLabels.country',
    modelName: 'country',
    modelValue: {},
    emptyError: 'errors.emptyCountry',
    getValue: (model) => model.id,
    getFullObject: getCountry
  },
  {
    type: 'text',
    label: 'inputLabels.city',
    modelName: 'city',
    modelValue: '',
    upperFirst: true,
    style: {
      gridColumn: '2'
    }
  },
  {
    type: 'phone',
    inputmode: 'tel',
    label: 'inputLabels.phone',
    modelName: 'phone',
    modelValue: {
      number: '',
      country: ''
    },
    getDisplay: (model) => {
      const modelCountry = get(model, 'country', '');
      if (!modelCountry) return '';
      const modelNumber = get(model, 'number', '');
      const countryItem = find(country, { code: modelCountry }) || {};
      return countryItem.phone && modelNumber ? `+${countryItem.phone} ${modelNumber.replace(')', ') ')}` : '';
    },
    isValid: (val) =>  val && typeof val === 'object' && (
      !val.number || (val.number.length === val.placeholder.length)
    ),
    validError: 'errors.emptyPhone',
    fixed: true
  },
  {
    type: 'email',
    inputmode: 'email',
    label: 'inputLabels.email',
    modelName: 'email',
    modelValue: '',
    emptyError: 'errors.emptyEmail',
    validError: 'errors.emptyEmail',
    disabled: true,
    isValid: (val) => !val || isEmail(val)
  }
];

const isMobile = checkMobileByScreen();
export default reactive(inputs.map((item) => {
  item.id = uniqueId('profile-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  if (isMobile) set(item, 'style.gridColumn', '1' );
  return item;
}));
