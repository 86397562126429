const validateRegistration = (inputs) => {
  const body = {};
  let errors = 0;
  inputs.forEach((input) => {
    const hasValidator = typeof input.isValid === 'function';
    if (!input.model) {
      input.error = input.emptyError;
      errors += 1;
    } else if (hasValidator && !input.isValid(input.model)) {
      input.error = input.validError || input.emptyError;
      errors += 1;
    } else {
      input.error = false;
      body[input.modelName] = input.model;
    }
  });
  return errors === 0 ? body : false;
};

export default validateRegistration;
