<template>
  <div
    class="lp-finance-table"
    :class="{
      'lp-finance-table_full': total,
      'lp-finance-table_mobile': isMobile
    }"
  >
    <h1
      class="lp-finance-table__title lp-page-title"
      :class="{
        'lp-finance-table__title_mobile': isMobile
      }"
      v-if="total"
    >
      {{ $t('crm.pages.payments') }}
    </h1>
    <LPTable
      :sticky="isMobile ? 52 : 65"
      :loading="loading"
      :columns="columns"
      :rows="payments"
      @click-cell="onClickCell"
    >
      <template #empty>
        <div
          class="lp-finance-table-empty"
          :class="{
            'lp-finance-table-empty_mobile': isMobile
          }"
        >
          <img
            class="lp-finance-table-empty__image"
            :src="imgPlaceholder"
            alt="students"
          >
          <p class="lp-finance-table-empty__title">
            {{ $t('crm.finance.table.emptyList') }}
          </p>
          <p class="lp-finance-table-empty__description">
            {{ $t('crm.finance.table.emptyListInfo') }}
          </p>
          <button
            class="lp-button"
            @click="$emit('update:openPayment', true)"
          >
            {{ $t('crm.students.balance.button') }}
          </button>
        </div>
      </template>
    </LPTable>
    <div
      class="lp-finance-table-pagination"
      v-if="pagination.totalPages > 1"
    >
      <Pagination
        v-bind="pagination"
        @change="$emit('get', $event)"
      />
    </div>
  </div>
</template>

<script>
import LPTable from '@/components/Main/Table/LPTable';
import imgPlaceholder from '@/assets/images/tablePlaceholder/expenses.png';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { get } from 'lodash';
import Pagination from '@/components/Main/Table/Pagination';
import financeColumns from '@/constants/tables/financeColumns';
import MobileDetect from 'mobile-detect';

export default {
  name: 'FinanceTable',
  components: { Pagination, LPTable },
  props: {
    loading: Boolean,
    total: Number,
    pagination: {
      type: Object,
      default: () => ({})
    },
    payments: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['update:studentId', 'get', 'update:openPayment'],
  setup (props, { emit }) {
    const store = useStore();
    const settings = computed(() => store.getters.settings);

    const columns = computed(() => financeColumns.map(item => {
      if (item.needCurrency && settings.value) {
        const currency = get(props.payments, [0, 'currency'], '');
        item.postfix = settings.value.currentCurrency || currency;
      }
      return item;
    }));

    const onClickCell = ({ row, column }) => {
      if (column.prop === 'crmStudentFullName') openCardStudent(row);
    };

    const openCardStudent = (row) => {
      emit('update:studentId', get(row, 'crmStudentId', ''));
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      imgPlaceholder,
      columns,
      onClickCell
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-finance-table {
  @include global-font;
  padding: 0 24px;
  height: 100%;

  &_mobile {
    background-color: $color-white;
    padding: 0 0 70px;
    height: auto;
  }

  &_full {
    height: auto;
  }

  &__title {
    padding-bottom: 28px;
    background-color: $color-white;

    &_mobile {
      padding: 0 12px 8px;
    }
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: $color-text;
    text-align: center;
    user-select: none;

    &_mobile {
      height: calc(100vh - 168px);
      height: calc((var(--vh, 1vh) * 100) - 168px);
    }

    &__image {
      width: 303px;
      height: auto;
      object-fit: cover;
      margin-bottom: 30px;

      @media (max-width: 480px) {
        width: calc(100vw - 30px);
        max-width: 200px;
      }
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      line-height: 125%;
      margin-bottom: 8px;
    }

    &__description {
      font-weight: 500;
      font-size: 14px;
      line-height: 125%;
      max-width: 290px;
      margin-bottom: 28px;
    }
  }

  &-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    padding: 8px 0 16px;
    background-color: $color-white;
  }
}

</style>
