<template>
  <div class="lp-sign-up">
    <div class="lp-sign-up__images">
      <img
        class="lp-sign-up__image"
        :src="require(`@/assets/images/login-image.jpg`)"
        alt="login-image"
      />
      <router-link :to="{name: 'Landing'}">
        <loginLogoIcon />
      </router-link>
    </div>
    <div class="lp-sign-up__container">
      <div class="lp-sign-up__title">
        <router-link
          class="lp-sign-up__title-link"
          :to="toLogin()"
        >
          <backIcon />
        </router-link>
        <h2
          class="lp-sign-up__title__text"
          :class="`lp-sign-up__title__text_${locale}`"
        >
          {{ $t('forgotPassword.title') }}
        </h2>
      </div>
      <div class="lp-sign-up-form">
        <CustomInput
          v-for="input of inputs"
          :key="input.label"
          v-bind="input"
          @submit="forgotPassword()"
          @focus="input.error = false"
          v-model="input.modelValue"
        />
      </div>
      <button
        class="lp-button lp-sign-up__enter"
        @click="forgotPassword()"
      >
        {{ $t('forgotPassword.button') }}
      </button>
    </div>
    <ForgotPasswordInfo
      :open="showForgotInfoModal"
      @close="closeForgotInfoModal"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { VueCookieNext } from 'vue-cookie-next';
import { useRouter } from 'vue-router';
import { watch, computed, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import checkCountries from '@/constants/countries/checkCountries';
import ForgotPasswordInfo from '@/components/LoginPage/ForgotPasswordInfo';
import validatePassword from '@/constants/utils/validatePassword';
import { backIcon } from '@/constants/icons/landing';
import setErrorText from '@/constants/utils/setErrorText';
import CustomInput from '@/components/LoginPage/CustomInput';
import AuthApi from '@/api/Auth/api';
import {
  forgotPasswordInputs,
  forgotResetPasswordInputs
} from '@/constants/inputs/forgotPassword';
import validateForgotPassword from '@/constants/validatesForm/validateForgotPassword';
import { loginLogoIcon } from '@/constants/icons/landing';

export default {
  name: 'ForgotPasswordForm',
  components: { backIcon, ForgotPasswordInfo, CustomInput, loginLogoIcon },
  setup () {
    const store = useStore();
    const currentCountryCode = computed(() => store.getters.countryCode);
    const currentLanguage = checkCountries(currentCountryCode.value)
      ? 'ru'
      : 'en';

    const resetToken = ref('');

    const setActiveUser = (data) => store.dispatch('setUser', data);
    const setLoaderRun = (data) => {
      store.dispatch('setLoaderRun', data);
    };

    const router = useRouter();

    const toLogin = () => ({
      name: 'Login'
    });

    const showForgotInfoModal = ref(false);
    const closeForgotInfoModal = () => {
      router.push({ name: 'Landing' });
    };

    const { locale } = useI18n({ useScope: 'global' });

    watch(locale, () => {
      localStorage.setItem('lang', locale.value);
    });

    const forgotPassword = async () => {
      if (!resetToken.value) {
        showForgotInfoModal.value = true;
      }
      const validateBody = resetToken.value
        ? validatePassword(inputs.value)
        : validateForgotPassword(inputs.value);
      if (!validateBody) return;
      try {
        await setLoaderRun(true);
        const body = resetToken.value
          ? {
            ...validateBody,
            token: resetToken.value,
            newPasswordConfirm: undefined
          }
          : { ...validateBody };
        if (resetToken.value) {
          const { data } = await AuthApi.resetForgotPassword(
            body,
            currentLanguage
          );
          const {
            token: { accessToken }
          } = data;
          VueCookieNext.setCookie('token', accessToken, { expire: '1m' });
          const resp = await AuthApi.getUser();
          await setActiveUser(resp.data);
          router.push({ name: 'CRM' });
        } else {
          await AuthApi.forgotPassword(body, currentLanguage);
          showForgotInfoModal.value = true;
        }
        await setLoaderRun(false);
      } catch (err) {
        console.error(err);
        await setLoaderRun(false);
        setErrorText({
          err,
          target: inputs.value,
          index: 0,
          all: true,
          locale: locale.value
        });
      }
    };

    onBeforeMount(() => {
      const { query = {} } = router.currentRoute.value;
      const { token = '' } = query;
      resetToken.value = token;
    });

    const inputs = computed(() => {
      return resetToken.value
        ? forgotResetPasswordInputs
        : forgotPasswordInputs;
    });

    return {
      forgotPassword,
      toLogin,
      inputs,
      showForgotInfoModal,
      closeForgotInfoModal,
      resetToken,
      locale
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-sign-up {
  @include global-font;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background: $color-white;

  @media (max-width: 767px) {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__container {
    display: flex;
    width: 100%;
    max-width: 528px;
    padding: 60px;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
    background: $color-white;

    @media (max-width: 767px) {
      max-width: 400px;
      margin: 0;
      padding: 40px 24px 0 24px;
    }
  }

  &__images {
    display: flex;
    height: 100%;

    a {
      position: absolute;
      margin: 20px;
      color: $color-white;
    }

    @media (max-width: 767px) {
      height: auto;
      padding-top: 55px;

      a {
        position: relative;
        margin: 0px;
        color: $color-purple;
      }
    }
  }

  &__image {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 704px;
    height: inherit;
    border-radius: 8px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__text {
      display: flex;
      margin: 0 auto 0 45px;
      justify-self: center;
      font-family: $global-font-manrope;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 125%;
      color: $color-black;

      @media (max-width: 767px) {
        font-size: 24px;

        &_en {
          margin-left: 10px;
        }
        &_ru {
          margin-left: 32px;
        }
      }
    }
  }

  &__subtitle {
    margin-top: 23px;
    width: 100%;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    @media (max-width: 767px) {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
    }
  }

  &__enter {
    margin-top: 40px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    font-family: $global-font-manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 125%;

    @media (max-width: 767px) {
      margin-top: 32px;
    }
  }
}

.lp-sign-up-form {
  margin-top: 25px;
  display: grid;
  grid-gap: 16px;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 32px;
  }
}

.lp-sign-up__title-link {
  display: flex;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
  box-shadow: 0 0 9px rgba($color-black, 0.11);
  border-radius: 8px;

  svg {
    width: 7px;
    height: 12px;
  }

  &:hover {
    color: $color-purple-dark;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    width: 36px;
    min-width: 36px;
    height: 36px;

    svg {
      width: 5px;
      height: 10px;
    }
  }
}
</style>

<style lang="scss">
.app-zoom {
  .lp-sign-up {
    height: 125vh;
  }
}
</style>
