const ym = window.ym;

export default {
  /** NOTE MICROPHONE CALIBRATION **/
  // начал калибровать http://joxi.ru/DmBlZBXfJaDpnA
  calibrationStart: () => ym(72798763, 'reachGoal', ' musio_call_kalibr'),
  // калибровка прошла успешно (внутри функции)
  calibrationSuccess: () => ym(72798763, 'reachGoal', 'musio_call_kalibryes'),
  // калибровка не прошла по не известной причине (внутри функции)
  calibrationError: () => ym(72798763, 'reachGoal', 'musio_call_kalibrno'),
  // калибровка не прошла по причине шума
  calibrationErrorNoise: () => ym(72798763, 'reachGoal', 'musio_call_noise'),
  // калибровка не прошла по причине не достаточной громкости или не близко динамик с микро
  calibrationErrorVolume: () => ym(72798763, 'reachGoal', 'musio_call_volumekalibr'),

  /** VIDEO CALL **/
  // пользователь выбрал проф версию http://joxi.ru/gmv8lBRIqW3KEA
  callSelectPro: () => ym(72798763, 'reachGoal', 'musio_call_pro'),
  // пользователь выбрал обычную версию http://joxi.ru/VrwVzvof7EM00A
  callSelectStd: () => ym(72798763, 'reachGoal', 'musio_call_standart'),
  // нажал кнопку “Присоединиться” http://joxi.ru/8AnxbkXszpeEBm
  callConnection: () => ym(72798763, 'reachGoal', 'musio_call_join'),
  // пригласил ученика http://joxi.ru/V2VD39nhdPQRyr
  callInvite: () => ym(72798763, 'reachGoal', 'musio_call_invite'),
  // завершил урок http://joxi.ru/krDLRBpIKYlq8r
  callEnd: () => ym(72798763, 'reachGoal', 'musio_call_endlesson'),
  // включил режим Ученика
  callStudentOn: () => ym(72798763, 'reachGoal', 'musio_call_studenton'),
  // выключил режим Ученика
  callStudentOff: () => ym(72798763, 'reachGoal', 'musio_call_studentoff'),
  // ученик подключился к уроку
  callStudentJoin: () => ym(72798763, 'reachGoal', 'musio_call_joinstudent'),

  /** DEVICES **/
  // не распознало камеру
  deviceNoCam: () => ym(72798763, 'reachGoal', 'musio_call_camerror'),
  // не распознало микрофон
  deviceNoMicro: () => ym(72798763, 'reachGoal', 'musio_call_micerror'),

  /** PLAYER **/
  // нажал Плей http://joxi.ru/ZrJQk9VFwPG8oA
  playerPlay: () => ym(72798763, 'reachGoal', 'musio_call_play'),
  // нажал Паузу http://joxi.ru/E2pzpyvh7LQDEr
  playerPause: () => ym(72798763, 'reachGoal', 'musio_call_pause'),
  // перемотал трек
  playerRewind: () => ym(72798763, 'reachGoal', 'musio_call_rewind'),

  /** FILES **/
  // выбрал аудио файл http://joxi.ru/4Akyln3hopgDYm
  fileSelect: () => ym(72798763, 'reachGoal', 'musio_call_file'),
  // загрузил файл http://joxi.ru/Dr8dPBKIoa09Wr
  fileUpload: () => ym(72798763, 'reachGoal', 'musio_call_upload'),

  /** OTHERS **/
  // Новый пользователь
  register: () => ym(89636073, 'reachGoal', 'reg'),
  // Настроил профиль
  settings: () => ym(72798763, 'reachGoal', 'crm_profile'),
  // Сформировал расписание
  timetable: () => ym(72798763, 'reachGoal', 'crm_schedule'),
  // Добавил урок
  addLesson: () => ym(72798763, 'reachGoal', 'crm_lesson'),
  // Завершил урок
  endLesson: () => ym(72798763, 'reachGoal', 'crm_lessonend'),
  // Добавил ученика
  addStudent: () => ym(72798763, 'reachGoal', 'crm_newstudent'),
  // Внес деньги на ученика
  addPayment: () => ym(72798763, 'reachGoal', 'crm_studentpay'),
  // Скопировал ссылку на расписание
  copyTimetable: () => ym(72798763, 'reachGoal', 'crm_schedulecopy'),
  // Ученик записался на урок
  signUpLesson: () => ym(72798763, 'reachGoal', 'crm_lessonbook'),
  // Создал папку
  addFolder: () => ym(72798763, 'reachGoal', 'crm_folder'),
  // Загрузил файл
  addFile: () => ym(72798763, 'reachGoal', 'crm_uploadfile'),
  // Улучшил сервис
  improve: () => ym(72798763, 'reachGoal', 'crm_improve'),
  // Создал виртуальный класс
  createRoom: () => ym(72798763, 'reachGoal', 'crm_newclass'),
  // Скопировал ссылку на виртуальный класс
  copyRoomLink: () => ym(72798763, 'reachGoal', 'crm_copylinkclass'),
  // Открыл аккомпанемент
  openOrderAccompaniment: () => ym(72798763, 'reachGoal', 'crm_minus-01'),
  // Заказал аккомпанемент
  orderAccompaniment: () => ym(72798763, 'reachGoal', 'crm_minus-02'),
  // Оставил заявку
  leaveRequestForAccompaniment: () => ym(72798763, 'reachGoal', 'crm_minus-03'),
  // Скопировал промокод
  copyPromo: () => ym(72798763, 'reachGoal', 'crm_discountcopy'),
  // Открыл скидки
  openDiscounts: () => ym(72798763, 'reachGoal', 'crm_discountmenu'),

  /** OLD **/
  // TODO самые первые цели, не уверен, что они актуальные
  // Заявка от учителя
  sendTeacherForm: () => ym(72798763, 'reachGoal', 'musio_sent_request'),
  // Присоеднинился к чату
  joinTelegram: () => ym(72798763, 'reachGoal', 'musio_telegram'),
  // Перешел на регистрацию
  goToSignUp: () => ym(72798763, 'reachGoal', 'musio_enter'),
  // Загрузили аудио файл для стрима
  uploadStreamMp3: () => ym(72798763, 'reachGoal', 'musio_audiofile'),
  // Запустили миди
  midiPlay: () => ym(72798763, 'reachGoal', 'musio_midifile'),
  // Поиск миди
  midiSearch: () => ym(72798763, 'reachGoal', 'musio_search'),
  // Подключили миди пианино
  initMidiDevice: () => ym(72798763, 'reachGoal', 'musio_midikeyboard'),
  // pay
  startPay: () => ym(72798763, 'reachGoal', 'musio_startpay'),
  payed: () => ym(72798763, 'reachGoal', 'musio_payed')
};
