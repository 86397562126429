import * as types from '@/constants/mutationTypes';

const state = {
  modalIds: []
};
const getters = {
  modalIds: (state) => state.modalIds
};
const actions = {
  setModalIds ({ commit }, data) {
    commit(types.SET_MODAL_IDS, { data });
  }
};
const mutations = {
  [types.SET_MODAL_IDS] (state, { data }) {
    state.modalIds = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
