export const FacebookIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 10C20 4.47656 15.5234 0 10 0C4.47656 0 0 4.47656 0 10C0 14.9922 3.65625 19.1289 8.4375 19.8789V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29102 9.92969 3.90625 12.2148 3.90625C13.3086 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1914C11.9492 6.5625 11.5625 7.33398 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8789C16.3438 19.1289 20 14.9922 20 10Z" fill="currentColor"/>
    </svg>
)

export const PinterestIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0082 0C4.48104 0 0 4.47368 0 9.99178C0 14.227 2.63591 17.8454 6.35913 19.301C6.26852 18.5115 6.19438 17.2944 6.39208 16.4309C6.5733 15.6497 7.56176 11.4638 7.56176 11.4638C7.56176 11.4638 7.26522 10.8635 7.26522 9.98355C7.26522 8.59375 8.07247 7.55757 9.07741 7.55757C9.93408 7.55757 10.3459 8.19901 10.3459 8.96382C10.3459 9.81908 9.80228 11.102 9.51398 12.2944C9.2751 13.2895 10.0165 14.1036 10.9967 14.1036C12.7759 14.1036 14.1433 12.2286 14.1433 9.53125C14.1433 7.13816 12.4217 5.46875 9.95879 5.46875C7.10872 5.46875 5.43656 7.59868 5.43656 9.80263C5.43656 10.6579 5.76605 11.5789 6.17791 12.0806C6.26028 12.1793 6.26852 12.2697 6.24381 12.3684C6.16967 12.6809 5.99669 13.3635 5.96374 13.5033C5.92256 13.6842 5.81547 13.7253 5.62602 13.6349C4.37396 13.051 3.59143 11.2336 3.59143 9.76151C3.59143 6.61184 5.88137 3.71711 10.2059 3.71711C13.6738 3.71711 16.3756 6.18421 16.3756 9.49013C16.3756 12.9359 14.201 15.7072 11.1861 15.7072C10.173 15.7072 9.21744 15.1809 8.89619 14.5559C8.89619 14.5559 8.39372 16.4638 8.27016 16.9326C8.04776 17.8043 7.4382 18.8898 7.02634 19.5559C7.96539 19.8437 8.95385 20 9.99174 20C15.5189 20 20 15.5263 20 10.0082C20.0164 4.47368 15.5354 0 10.0082 0Z" fill="currentColor"/>
    </svg>
)

export const TwitterIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM9.72148 8.47418L9.7005 8.12816C9.63755 7.23133 10.1901 6.41217 11.0645 6.0944C11.3862 5.98141 11.9318 5.96729 12.2886 6.06615C12.4285 6.10852 12.6943 6.24975 12.8831 6.37686L13.2259 6.6099L13.6036 6.48985C13.8134 6.4263 14.0932 6.32037 14.2191 6.24975C14.338 6.1862 14.443 6.15089 14.443 6.17207C14.443 6.29212 14.1842 6.7017 13.9673 6.92768C13.6735 7.24545 13.7575 7.2737 14.352 7.06185C14.7088 6.9418 14.7158 6.9418 14.6458 7.07597C14.6038 7.14659 14.387 7.39375 14.1562 7.61972C13.7645 8.00811 13.7435 8.05048 13.7435 8.37532C13.7435 8.8767 13.5057 9.92183 13.2678 10.4938C12.8272 11.5672 11.8829 12.6759 10.9386 13.2338C9.60957 14.0176 7.83989 14.2153 6.35 13.7563C5.85336 13.601 5 13.2055 5 13.1349C5 13.1137 5.25881 13.0855 5.57357 13.0784C6.23108 13.0643 6.88859 12.8807 7.44818 12.5558L7.8259 12.3299L7.39222 12.1816C6.77668 11.9697 6.22409 11.4825 6.08419 11.0235C6.04222 10.8752 6.05621 10.8681 6.44792 10.8681L6.85362 10.861L6.51088 10.6986C6.10518 10.4938 5.73445 10.1478 5.55259 9.79472C5.41969 9.5405 5.25181 8.89789 5.30078 8.84845C5.31477 8.82727 5.46166 8.86964 5.62953 8.92613C6.11217 9.10267 6.17513 9.0603 5.89533 8.76371C5.37072 8.22703 5.20984 7.42905 5.46166 6.67345L5.58057 6.33449L6.04222 6.7935C6.98652 7.71858 8.09869 8.26939 9.37175 8.43181L9.72148 8.47418Z" fill="currentColor"/>
    </svg>
)

export const YouTubeIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM14.1673 6.56225C14.6262 6.68819 14.9877 7.05928 15.1104 7.53054C15.3333 8.38463 15.3333 10.1667 15.3333 10.1667C15.3333 10.1667 15.3333 11.9487 15.1104 12.8029C14.9877 13.2741 14.6262 13.6452 14.1673 13.7712C13.3355 14 9.99993 14 9.99993 14C9.99993 14 6.66435 14 5.83253 13.7712C5.37356 13.6452 5.01211 13.2741 4.88944 12.8029C4.66659 11.9487 4.66659 10.1667 4.66659 10.1667C4.66659 10.1667 4.66659 8.38463 4.88944 7.53054C5.01211 7.05928 5.37356 6.68819 5.83253 6.56225C6.66435 6.33336 9.99993 6.33336 9.99993 6.33336C9.99993 6.33336 13.3355 6.33336 14.1673 6.56225Z" fill="currentColor"/>
        <path d="M9 12.0001V8.66675L11.6667 10.3335L9 12.0001Z" fill="currentColor"/>
    </svg>
)

export const InstagramIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM7.80129 4.69892C8.37019 4.67303 8.55197 4.6667 10.0004 4.6667H9.99876C11.4477 4.6667 11.6288 4.67303 12.1977 4.69892C12.7655 4.72492 13.1532 4.81481 13.4932 4.9467C13.8444 5.08281 14.141 5.26503 14.4377 5.5617C14.7344 5.85815 14.9166 6.15571 15.0533 6.50649C15.1844 6.84561 15.2744 7.23317 15.301 7.80095C15.3266 8.36984 15.3333 8.55162 15.3333 10.0001C15.3333 11.4485 15.3266 11.6299 15.301 12.1988C15.2744 12.7663 15.1844 13.154 15.0533 13.4932C14.9166 13.8439 14.7344 14.1415 14.4377 14.4379C14.1414 14.7346 13.8442 14.9172 13.4936 15.0535C13.1542 15.1854 12.7662 15.2752 12.1985 15.3012C11.6296 15.3271 11.4483 15.3335 9.99976 15.3335C8.55141 15.3335 8.36974 15.3271 7.80085 15.3012C7.23318 15.2752 6.8455 15.1854 6.50617 15.0535C6.15561 14.9172 5.85805 14.7346 5.56171 14.4379C5.26515 14.1415 5.08293 13.8439 4.9466 13.4931C4.81482 13.154 4.72493 12.7664 4.69882 12.1987C4.67304 11.6298 4.66659 11.4485 4.66659 10.0001C4.66659 8.55162 4.67326 8.36973 4.6987 7.80084C4.72426 7.23328 4.81426 6.84561 4.94649 6.50638C5.08315 6.15571 5.26538 5.85815 5.56205 5.5617C5.85849 5.26515 6.15605 5.08292 6.50683 4.9467C6.84595 4.81481 7.23351 4.72492 7.80129 4.69892Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.52199 5.6278C9.61489 5.62765 9.71483 5.6277 9.82268 5.62775L10.0004 5.6278C11.4245 5.6278 11.5932 5.63291 12.1556 5.65847C12.6756 5.68224 12.9578 5.76913 13.1458 5.84213C13.3947 5.9388 13.5721 6.05436 13.7587 6.24103C13.9454 6.4277 14.0609 6.60548 14.1578 6.85437C14.2308 7.04215 14.3178 7.32437 14.3415 7.84438C14.367 8.40661 14.3726 8.5755 14.3726 9.99884C14.3726 11.4222 14.367 11.5911 14.3415 12.1533C14.3177 12.6733 14.2308 12.9555 14.1578 13.1433C14.0611 13.3922 13.9454 13.5694 13.7587 13.756C13.572 13.9427 13.3948 14.0582 13.1458 14.1549C12.958 14.2282 12.6756 14.3149 12.1556 14.3387C11.5933 14.3642 11.4245 14.3698 10.0004 14.3698C8.57632 14.3698 8.40754 14.3642 7.84531 14.3387C7.3253 14.3147 7.04308 14.2278 6.85496 14.1548C6.60607 14.0581 6.42829 13.9426 6.24162 13.7559C6.05496 13.5692 5.9394 13.3919 5.84251 13.1429C5.76951 12.9551 5.68251 12.6729 5.65884 12.1529C5.63328 11.5906 5.62817 11.4217 5.62817 9.99751C5.62817 8.57327 5.63328 8.40527 5.65884 7.84304C5.68262 7.32304 5.76951 7.04081 5.84251 6.85281C5.93918 6.60392 6.05496 6.42614 6.24162 6.23947C6.42829 6.0528 6.60607 5.93725 6.85496 5.84036C7.04297 5.76702 7.3253 5.68035 7.84531 5.65647C8.33731 5.63424 8.52798 5.62758 9.52199 5.62646V5.6278ZM12.8474 6.5134C12.494 6.5134 12.2073 6.79973 12.2073 7.15318C12.2073 7.50652 12.494 7.79319 12.8474 7.79319C13.2007 7.79319 13.4874 7.50652 13.4874 7.15318C13.4874 6.79984 13.2007 6.51317 12.8474 6.51317V6.5134ZM7.26159 10C7.26159 8.48749 8.48788 7.26117 10.0004 7.26111C11.513 7.26111 12.739 8.48746 12.739 10C12.739 11.5126 11.5131 12.7384 10.0005 12.7384C8.48794 12.7384 7.26159 11.5126 7.26159 10Z" fill="currentColor"/>
        <path d="M10.0005 8.22217C10.9822 8.22217 11.7782 9.01807 11.7782 9.99996C11.7782 10.9818 10.9822 11.7778 10.0005 11.7778C9.01855 11.7778 8.22266 10.9818 8.22266 9.99996C8.22266 9.01807 9.01855 8.22217 10.0005 8.22217Z" fill="currentColor"/>
    </svg>
)

export const LinkedInIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM4.80071 8.28241H7.06693V15.0915H4.80071V8.28241ZM7.21608 6.17612C7.20137 5.5085 6.72395 5 5.94869 5C5.17343 5 4.66659 5.5085 4.66659 6.17612C4.66659 6.82992 5.15845 7.35306 5.91927 7.35306H5.93375C6.72395 7.35306 7.21608 6.82992 7.21608 6.17612ZM12.6304 8.12253C14.1217 8.12253 15.2397 9.09592 15.2397 11.1874L15.2396 15.0915H12.9735V11.4486C12.9735 10.5336 12.6455 9.90925 11.8252 9.90925C11.1991 9.90925 10.8261 10.3302 10.6624 10.7367C10.6024 10.8824 10.5877 11.0854 10.5877 11.2889V15.0916H8.32124C8.32124 15.0916 8.35111 8.92153 8.32124 8.2826H10.5877V9.24705C10.8885 8.78342 11.4272 8.12253 12.6304 8.12253Z" fill="currentColor"/>
    </svg>
)

export const VkIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM17.6833 8.65964C18.1246 8.65964 18.2242 8.8874 18.1246 9.20056C17.969 9.91828 16.6777 11.8221 16.2777 12.4118C16.2017 12.5239 16.1579 12.5885 16.1601 12.5885C16.0036 12.8447 15.9466 12.9586 16.1601 13.2433C16.2373 13.3485 16.401 13.5091 16.5881 13.6927C16.7805 13.8815 16.9976 14.0946 17.1708 14.2966C17.7972 15.0084 18.2811 15.6063 18.4093 16.0191C18.5231 16.4319 18.3238 16.6454 17.8968 16.6454H16.4306C16.042 16.6454 15.8418 16.4223 15.4149 15.9467C15.2319 15.7428 15.0072 15.4925 14.7082 15.1934C13.8399 14.3536 13.4555 14.2397 13.242 14.2397C12.9431 14.2397 12.8577 14.3109 12.8577 14.7379V16.0618C12.8577 16.4176 12.7438 16.6312 11.8043 16.6312C10.2527 16.6312 8.53025 15.6917 7.32029 13.9408C5.49822 11.3785 5 9.44256 5 9.05822C5 8.84469 5.07117 8.6454 5.49822 8.6454H6.97865C7.34875 8.6454 7.4911 8.80199 7.63345 9.2148C8.35943 11.3073 9.5694 13.1436 10.0676 13.1436C10.2527 13.1436 10.3381 13.0582 10.3381 12.5885V10.4248C10.3029 9.80975 10.0888 9.5418 9.93009 9.3431C9.83163 9.21986 9.75445 9.12327 9.75445 8.98704C9.75445 8.81622 9.8968 8.6454 10.1246 8.6454H12.4306C12.7438 8.6454 12.8577 8.81622 12.8577 9.18633V12.1045C12.8577 12.4176 12.9858 12.5315 13.0854 12.5315C13.2705 12.5315 13.427 12.4176 13.7687 12.076C14.8221 10.8945 15.5765 9.07245 15.5765 9.07245C15.6762 8.85893 15.847 8.65964 16.2171 8.65964H17.6833Z" fill="currentColor"/>
    </svg>
)

export const OkIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9.99315 10.1605C11.4678 10.1605 12.6665 9.00331 12.6665 7.58066C12.6665 6.15754 11.4678 5 9.99315 5C8.51886 5 7.31985 6.15754 7.31985 7.58066C7.31985 9.00331 8.51886 10.1605 9.99315 10.1605ZM12.6341 11.6463C12.1507 11.9393 11.6259 12.1471 11.0817 12.2668L12.5762 13.7095C12.8823 14.0044 12.8823 14.4834 12.5762 14.7786C12.2703 15.0738 11.7746 15.0738 11.4691 14.7786L9.9998 13.3609L8.53175 14.7786C8.37867 14.926 8.17809 14.9999 7.97751 14.9999C7.77725 14.9999 7.57698 14.926 7.42391 14.7786C7.11808 14.4834 7.11808 14.0047 7.42359 13.7095L8.91819 12.2668C8.37403 12.1471 7.84922 11.939 7.36585 11.6463C7.00003 11.4236 6.89014 10.9572 7.12064 10.6037C7.35049 10.2499 7.83387 10.1433 8.20032 10.366C9.29472 11.0305 10.7046 11.0307 11.7996 10.366C12.166 10.1433 12.6493 10.2499 12.8796 10.6037C13.1101 10.9569 12.9999 11.4236 12.6341 11.6463Z" fill="currentColor"/>
        <path d="M9.99335 6.51245C10.6037 6.51245 11.1002 6.99156 11.1002 7.58075C11.1002 8.16948 10.6037 8.6489 9.99335 8.6489C9.38345 8.6489 8.88647 8.16948 8.88647 7.58075C8.88647 6.99156 9.38345 6.51245 9.99335 6.51245Z" fill="currentColor"/>
    </svg>
)