import { MUSIO_SERVER } from '@/constants/domains';
import { VueCookieNext } from 'vue-cookie-next';

const axios = require('axios');

export default {
  getPayments ({ page = 1, limit = 999, studentId }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`page=${page}`, `limit=${limit}`];
    return instCred.get(`crm/payments/student/${studentId}?${query.join('&')}`);
  },
  getPaymentsByDate ({ page = 1, limit = 999, startDate, endDate }) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    const query = [`page=${page}`, `limit=${limit}`, `periodStart=${startDate}`, `periodEnd=${endDate}`];
    return instCred.get(`crm/payments/finance/filterbydaterange/?${query.join('&')}`);
  },
  createPayment (payment) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.post('crm/payments', payment);
  },
  deletePayment (paymentId) {
    const instCred = axios.create({
      baseURL: MUSIO_SERVER,
      headers: {
        Authorization: `Bearer ${VueCookieNext.getCookie('token')}`
      }
    });
    return instCred.delete(`crm/payments/${paymentId}`);
  }
};
