<template>
  <div
    class="lp-student-payments"
    :class="{'lp-student-payments_mobile': isMobile}"
    v-if="columns.length && payments.length"
  >
    <div
      class="lp-student-payments__title"
      :class="{'lp-student-payments__title_mobile': isMobile}"
    >
      {{ $t('crm.students.form.payments') }}
    </div>
    <div
      class="lp-student-payments__table"
      :class="{'lp-student-payments__table_mobile': isMobile}"
    >
      <LPTable
        :sticky="0"
        :columns="columns"
        :rows="payments"
        @delete="deletePayment"
      />
    </div>
  </div>
</template>

<script>
import LPTable from '@/components/Main/Table/LPTable';
import { computed, onBeforeMount, ref, watch } from 'vue';
import paymentsColumns from '@/constants/tables/paymentsColumns';
import PaymentsApi from '@/api/CRM/payments';
import StudentApi from '@/api/CRM/students';
import { get } from 'lodash';
import { useStore } from 'vuex';
import MobileDetect from 'mobile-detect';

export default {
  name: 'StudentPayments',
  components: { LPTable },
  props: {
    student: Object
  },
  emits: ['update:loading'],
  setup (props) {
    const columns = computed(() => paymentsColumns);
    const payments = ref([]);
    const studentId = computed(() => get(props, 'student._id', ''));

    const sortByDate = (a, b) => {
      const dateA = new Date(a.paymentDate);
      const dateB = new Date(b.paymentDate);
      return dateB - dateA;
    };

    const getPayments = async () => {
      if (!studentId.value) return;
      try {
        const { data } = await PaymentsApi.getPayments({ studentId: studentId.value });
        payments.value = data.resource.sort(sortByDate);
      } catch (err) {
        console.error(err);
      }
    };

    watch(() => props.student.balance, getPayments);
    watch(() => props.student, getPayments);

    onBeforeMount(getPayments);

    const store = useStore();
    const students = computed(() => store.getters.students);
    const getBalance = async () => {
      const id = get(props, 'student._id', '');
      try {
        const { data } = await StudentApi.getBalance(id);
        return data.balance || 0;
      } catch (err) {
        console.error(err);
      }
    };

    const deletePayment = async (row = {}) => {
      const index = payments.value.findIndex(item => item._id === row._id);
      const deletedPayment = index !== -1 ? payments.value.splice(index, 1) : null;
      const student = students.value.find(item => item._id === props.student._id);
      try {
        await PaymentsApi.deletePayment(row._id);
        if (student) student.balance = await getBalance();
      } catch (err) {
        if (deletedPayment && index !== -1) payments.value.splice(index, 0 , ...deletedPayment);
        console.error(err);
      }
    };

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      deletePayment,
      payments,
      columns
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-student-payments {
  &_mobile {
    margin: 0 -12px;
  }

  &__title {
    position: sticky;
    top: 0;
    background-color: $color-white;
    padding-top: 26px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 125%;
    color: $color-black;
    text-transform: uppercase;
    z-index: 2;
    user-select: none;

    &_mobile {
      top: 55px;
      padding: 25px 12px 8px;
      z-index: 6;
    }
  }

  &__table {
    max-height: 188px;
    overflow: auto;

    &_mobile {
      max-height: 100%;
    }
  }
}

</style>
