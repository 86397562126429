<template>
  <div class="lp-crm-subscriptions">
    <div
      class="lp-crm-subscriptions__header"
      :class="{'lp-crm-subscriptions__header_mobile': isMobile}"
    >
      <h1 class="lp-page-title">
        {{ $t('crm.pages.subscriptions') }}
      </h1>
    </div>
    <ListEmpty
      class="lp-crm-subscriptions__empty"
      :class="{'lp-crm-subscriptions__empty_mobile': isMobile}"
      v-if="subscriptionsEmpty"
      :imageUrl="subscriptionsPlaceholder"
      widthImg="356"
      title="crm.subscriptions.empty.title"
      text="crm.subscriptions.empty.text"
      button="crm.subscriptions.addSubscriptions"
      @handlerClick="addedSubscriptions"
    />
    <div
      v-if="subscriptions.length"
      class="lp-crm-subscriptions-list"
      :class="{'lp-crm-subscriptions-list_mobile': isMobile}"
    >
      <div
        class="lp-crm-subscriptions-item lp-crm-subscriptions-item_add-subscription"
        @click="addedSubscriptions"
      >
        <div class="lp-crm-subscriptions-item__icon">
          <addIcon />
        </div>
        <div>{{ $t('crm.subscriptions.addSubscriptions') }}</div>
      </div>

      <SubscriptionItem
        v-for="item in subscriptions"
        :item="item"
        :key="item.id"
        :subscriptionHoverId="subscriptionHoverId"
        @delete="deleteSubscription"
        @edit="editSubscription"
        @mouseenter="handlerHoverSubscription(item._id)"
        @mouseleave="handlerHoverSubscription('')"
        @subscriptionApply="subscriptionApply"
      />
    </div>
  </div>
  <SubscriptionModal
    v-model:openSubscription="isOpenSubscription"
    v-model:item="subscription"
  />
  <SubscriptionApplyModal
    v-model:openModal="isOpenSubscriptionApply"
    v-model:subscription="subscriptionApplyItem"
    @subscription-applied="subscriptionIsApplied"
  />
  <Modal
    v-model:open="showSuccessModal"
    :hideButtons="['remove', 'save']"
  >
    <div
      class="lp-crm-subscriptions-success"
      :class="{'lp-crm-subscriptions-success_mobile': isMobile}"
    >
      <div class="lp-crm-subscriptions-success__image">
        <img
          :src="require(`@/assets/images/success-apply.png`)"
          alt="success"
        >
      </div>
      <div class="lp-crm-subscriptions-success__text">
        {{ $t('crm.subscriptions.modal.subscriptionApplied') }}
      </div>
    </div>
  </Modal>
</template>

<script>
import subscriptionsPlaceholder from '@/assets/images/empty-subscriptions.svg';
import ListEmpty from '@/components/Main/ListEmpty/ListEmpty';
import { addIcon } from '@/constants/icons/files';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import SubscriptionModal from '@/components/CRM/Subscriptions/SubscriptionModal';
import { useStore } from 'vuex';
import LessonsPassTemplateApi from '@/api/CRM/lessonsPassTemplate';
import SubscriptionItem from '@/components/CRM/Subscriptions/SubscriptionItem';
import SubscriptionApplyModal from '@/components/CRM/Subscriptions/SubscriptionApplyModal';
import { useRouter } from 'vue-router';
import { get } from 'lodash';
import MobileDetect from 'mobile-detect';
import Modal from '@/components/Main/Modal/Modal';

export default {
  name: 'Subscriptions',
  components: {
    addIcon,
    ListEmpty,
    SubscriptionModal,
    SubscriptionItem,
    SubscriptionApplyModal,
    Modal
  },
  setup () {
    const store = useStore();
    const subscriptions = computed(() => store.getters.subscriptions);
    const user = computed(() => store.getters.user);

    const listReceived = ref(false);

    const subscriptionsEmpty = computed(() => listReceived.value && !subscriptions.value.length);
    const isOpenSubscription = ref(false);

    const subscription = ref({});

    const addedSubscriptions = () => {
      isOpenSubscription.value = true;
    };

    const getSubscriptions = async () => {
      try {
        const teacherId = user.value._id;
        const { data } = await LessonsPassTemplateApi.getLessonsPassTemplate({ teacherId });
        const { resource = [] } = data;
        listReceived.value = true;
        store.dispatch('setSubscriptions', resource);
      } catch (err) {
        console.error(err);
      }
    };

    const deleteSubscription = ({ _id: id }) => {
      const index = subscriptions.value.findIndex(item => item._id === id);
      if (index !== -1) subscriptions.value.splice(index, 1);
      LessonsPassTemplateApi.deleteLessonPassTemplateById(id);
    };
    const editSubscription = (item) => {
      isOpenSubscription.value = true;
      subscription.value = item;
    };

    const subscriptionHoverId = ref('');
    const handlerHoverSubscription = (id) => {
      subscriptionHoverId.value = id;
    };

    const isOpenSubscriptionApply = ref(false);
    const subscriptionApplyItem = ref({});

    const subscriptionApply = (data) => {
      subscriptionApplyItem.value = data;
      isOpenSubscriptionApply.value = true;
    };

    const showSuccessModal = ref(false);

    const subscriptionIsApplied = () => {
      showSuccessModal.value = true;
      setTimeout(() => {
        showSuccessModal.value = false;
      }, 1400);
    };

    const router = useRouter();
    const openFromRoute = computed(() => get(router, 'currentRoute.value.params.openForm', ''));
    onMounted(() => {
      if (openFromRoute.value) addedSubscriptions();
    });

    onBeforeMount(getSubscriptions);

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    return {
      isMobile,
      subscriptionsEmpty,
      subscriptionsPlaceholder,
      subscriptions,
      isOpenSubscription,
      addedSubscriptions,
      deleteSubscription,
      editSubscription,
      subscription,
      subscriptionHoverId,
      handlerHoverSubscription,
      isOpenSubscriptionApply,
      subscriptionApply,
      subscriptionApplyItem,
      showSuccessModal,
      subscriptionIsApplied
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-crm-subscriptions {
  &__header {
    margin-bottom: 20px;

    &_mobile {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin: 0;
      padding: 16px 56px;
      background-color: $color-white;
      z-index: 9;
    }
  }

  &__empty {
    max-width: 356px;
    margin: 56px auto 0;
    user-select: none;

    &_mobile {
      margin: 0 auto;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &-success {
    padding: 20px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_mobile {
      padding: 20px 40px;
    }

    &__text {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
    }
  }

}

.lp-crm-subscriptions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 306px);
  grid-template-rows: repeat(auto-fit, 189px);
  grid-gap: 12px;

  &_mobile {
    grid-template-columns: repeat(auto-fill, minmax(306px, 1fr));
    padding: 60px 16px 30px;
  }
}

.lp-crm-subscriptions-item {
  @include global-font;
  padding: 28px 18px 18px;
  border: 1px solid $color-alto;
  box-sizing: border-box;
  border-radius: 4px;
  background: url('~@/assets/images/subscriptions-item-bg.svg');
  font-size: 14px;
  line-height: 1.2;
  color: $color-text;
  user-select: none;

  &_add-subscription {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 18px;
    background: none;
    border: 1px dashed $color-accent;
    transition: color .2s ease, border-color .2s ease;
    cursor: pointer;
    color: $color-accent;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      color: $color-accent-hover;
      border-color: $color-accent-hover;
    }

  }

  &__icon {
    width: 28px;
    height: 28px;
    margin-bottom: 3px;
  }

  &__title {
    font-weight: 800;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

}

</style>

<style lang="scss">
.lp-crm-subscriptions-item__icon {
  svg {
    width: 100%;
    height: 100%;
  }
  path {
    fill: currentColor;
  }
}
</style>
<!-- Created by zas on 05.07.2021  -->
