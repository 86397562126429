import { cloneDeep, uniqueId, get, set } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    label: 'crm.students.form.groupName',
    modelName: 'crmGroup',
    modelValue: '',
    lessonStatus: '',
    customBorder: true,
    emptyError: 'errors.emptyGroupName',
    autofocus: true
  },
  {
    type: 'studentsList',
    modelName: 'allStudentsList',
    modelValue: '',
    lessonStatus: '',
    keyImg: 'uploadPhoto.url',
    searchable: true,
    hideLabel: true,
    emptyError: 'errors.emptyGroupList',
    getValue: (val) => val._id
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('lesson-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  const gridColumn = get(item, 'style.gridColumn', '');
  if (!gridColumn) set(item, 'style.gridColumn', '1/4');
  return item;
}));
