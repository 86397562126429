import * as types from '@/constants/mutationTypes';

const state = {
  lessons: []
};
const getters = {
  lessons: (state) => state.lessons
};
const actions = {
  setLessons ({ commit }, data) {
    commit(types.SET_LESSONS, { data });
  }
};
const mutations = {
  [types.SET_LESSONS] (state, { data }) {
    state.lessons = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
