<template>
  <div class="lp-calendar-lesson-card-student">
    <div class="lp-calendar-lesson-card-student__label">
      {{ isLessonGroup ? $t('crm.sidebar.students') : $t('calendar.lesson.student') }}
    </div>

    <div
      class="lp-calendar-lesson-card-wrapper"
      :class="{'lp-calendar-lesson-card-wrapper__hidden': !showAllStudents && isMobile}"
    >
      <div
        v-for="student in studentsList"
        :key="student.crmStudentId"
        class="lp-calendar-lesson-card-student-content"
        :class="{'lp-calendar-lesson-card-student-content_deleted': student.crmStudentDeleted}"
        @click="() => student.crmStudentDeleted ? false : goToCardStudent(student)"
        @mouseleave.stop="showTooltip = null"
        @mouseover="hoverStudentName(student.crmStudentFullName)"
      >
        <div class="lp-calendar-lesson-card-student-content-wrap">
          <div class="lp-calendar-lesson-card-student-content__image">
            <img
              v-if="student.uploadPhotoUrl"
              :src="student.uploadPhotoUrl"
              alt="avatar"
            >
            <userIcon v-else />
          </div>
          <div
            class="lp-calendar-lesson-card-student-content__name"
          >
            {{ student.crmStudentFullName }}
          </div>
          <Tooltip
            v-if="false"
            :open="showTooltip === student.crmStudentFullName"
            :text="student.crmStudentFullName"
            :topPosition="true"
            :onMobile="true"
            class="name-tooltip"
          />
        </div>
        <div
          v-if="isLessonGroup && showSubscribeIcon(student)"
          class="lp-calendar-lesson-card-student-content__subscribe"
        >
          <LPTooltip :text="$t('crm.subscriptions.tooltip')">
            <subscriptionsIcon />
          </LPTooltip>
        </div>
      </div>
      <div
        v-if="isMobile && lesson.crmLessons.length > 4"
        @click="showAllStudents = !showAllStudents"
      >
        <div
          class="lp-calendar-lesson-card__more-students"
          v-if="!showAllStudents"
        >
          {{ $t('crm.lessons.form.moreStudents') }} {{ restStudents }}
        </div>
        <div
          v-else
          class="lp-calendar-lesson-card__more-students"
        >
          <hideIcon />
          {{ $t('crm.lessons.form.lessStudents') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userIcon, hideIcon } from '@/constants/icons';
import { computed, ref } from 'vue';
import { get } from 'lodash';
import { useRouter } from 'vue-router';
import Tooltip from '@/components/Main/Tooltip/Tooltip';
import MobileDetect from 'mobile-detect';
import { subscriptionsIcon } from '@/constants/icons/crm';
import LPTooltip from '@/components/Main/Tooltip/LPTooltip';

export default {
  name: 'StudentLessonCard',
  components: {
    userIcon,
    Tooltip,
    hideIcon,
    subscriptionsIcon,
    LPTooltip
  },
  props: {
    lesson: Object,
    isLessonGroup: Boolean
  },
  setup (props) {
    const router = useRouter();

    const showTooltip = ref(null);
    const hoverStudentName = (item) => {
      showTooltip.value = item;
    };

    const showAllStudents = ref(false);
    const restStudents = computed(() => {
      return props.lesson.crmLessons.length - studentsList.value.length;
    });

    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = computed(() => !!md.mobile() || !!md.tablet());

    const studentsList = computed(() => {
      const { crmLessons } = props.lesson;
      return !isMobile.value || showAllStudents.value
        ? Object.values(crmLessons)
        : Object.values(crmLessons).slice(0, 4);
    });

    const isDeletedStudent = computed(() => get(props.lesson, 'crmStudentDeleted', false));

    const goToCardStudent = (student) => {
      router.push({ name: 'CRMStudents', query: { crmStudentId: student.crmStudentId } });
    };

    const showSubscribeIcon = (student) => {
      const { crmLessonPassId } = student;
      return !!crmLessonPassId;
    };

    return {
      showTooltip,
      hoverStudentName,
      isDeletedStudent,
      goToCardStudent,
      studentsList,
      isMobile,
      showAllStudents,
      restStudents,
      showSubscribeIcon
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-calendar-lesson-card-student {
  display: grid;
  grid-gap: 8px;

  &__label {
    font-weight: bold;
    font-size: 12px;
    line-height: 125%;
    color: $color-text
  }

}

.lp-calendar-lesson-card-student-content {
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;

  &_deleted {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    svg {
      width: 32px;
      height: 32px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    margin-left: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    text-transform: capitalize;
    color: $color-text;
    word-break: break-word;
  }

}

.lp-calendar-lesson-card-student-content-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 10px;
  border: 2px solid $color-white;
  border-radius: 50%;
  background-color: $color-white;
}

.lp-calendar-lesson-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 180px;
  overflow: auto;

  &__hidden {
    overflow: initial;
  }
}

.lp-calendar-lesson-card {
  &__more-students {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 14px;
    color: $color-gray;

    svg {
      margin-right: 6px;
    }
  }
}

.name-tooltip {
  bottom: 40px;
}

</style>
