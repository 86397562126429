<template>
  <div class="lp-teacher-card">
    <div class="lp-teacher-card__image">
      <img
        :src="teacher.image"
        alt="teacher photo"
      />
    </div>
    <div class="lp-teacher-card-info">
      <div class="lp-teacher-card-info__name">
        {{ t(teacher.name) }}
      </div>
      <div class="lp-teacher-card-info-options">
        <div
          class="lp-teacher-card-info-options__item"
          v-for="item of options"
          :key="item.id"
          :class="{
            'lp-teacher-card-info-options__item_active': activeOption === item.id,
            'lp-teacher-card-info-options__item_hide': !item.show()
          }"
          @click="activeOption = item.id"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="lp-teacher-card-info-content">
        <transition
          mode="out-in"
          :name="currentOption.order > oldOption.order ? 'slide-left' : 'slide-right'"
        >
          <component
            :is="currentOption.component"
            :id="currentOption.id"
            :hideNext="currentOption.hideNext"
            :teacher="teacher"
            :key="currentOption.id"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, shallowRef, watch } from 'vue';
import TeacherCardAbout from '@/components/CRM/Accompaniment/TeacherCardAbout';
import TeacherCardWork from '@/components/CRM/Accompaniment/TeacherCardWork';
import TeacherCardAchievements from '@/components/CRM/Accompaniment/TeacherCardAchievements';
import { useI18n } from 'vue-i18n';

export default {
  name: 'TeacherCard',
  components: {
    TeacherCardAbout,
    TeacherCardWork,
    TeacherCardAchievements
  },
  props: {
    teacher: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const { t } = useI18n();
    const options = reactive([
      {
        id: 'about',
        title: t('crm.teachers.about'),
        component: shallowRef(TeacherCardAbout),
        order: 1,
        show: () => props.teacher.about
      },
      {
        id: 'work',
        title: t('crm.teachers.experience'),
        component: shallowRef(TeacherCardAbout),
        order: 2,
        show: () => props.teacher.workText
      },
      {
        id: 'achievements',
        title: t('crm.teachers.achievements'),
        component: shallowRef(TeacherCardAchievements),
        order: 3,
        show: () => props.teacher.achievements
      }
    ]);
    const activeOption = ref('about');
    const currentOption = computed(() => options.find((item) => item.id === activeOption.value));
    const oldOption = ref({});
    watch(currentOption, (newVal, oldVal) => {
      oldOption.value = oldVal || {};
    });

    return {
      currentOption,
      oldOption,
      options,
      activeOption,
      t
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-teacher-card {
  @include global-font;
  display: grid;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  background-color: $color-white;
  box-shadow: 0 0 17px rgba($color-black, 0.08);
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 100vw;
  }

  &__image {
    max-width: 500px;
    margin: 0 auto;
    height: 385px;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    padding: 28px 0 48px;

    &__name {
      margin-bottom: 32px;
      font-weight: bold;
      font-size: 24px;
      line-height: 125%;
      color: $color-text;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    &-options {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $color-alto;
      padding: 0 60px;
      user-select: none;

      @media (max-width: 767px) {
        padding: 0 16px;
      }

      &__item {
        position: relative;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 125%;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        color: $color-text;
        padding-bottom: 12px;
        cursor: pointer;
        margin-right: 40px;

        @media (max-width: 767px) {
          text-align: center;
          font-size: 12px;
          padding-bottom: 10px;
          margin-right: 35px;
        }

        &:last-child {
          margin-right: 0;
        }

        &_hide {
          position: relative;
          width: 0;
          z-index: -1;
          pointer-events: none;
          margin-left: -80px;

          @media (max-width: 767px) {
            margin-left: -35px;
          }
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 2px;
          background-color: $color-accent;
          transition: 0.2s ease-in-out;
          opacity: 0.4;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }

        &_active {
          &:after {
            opacity: 1;
            width: 100%;
          }
        }
      }
    }

    &-content {
      display: grid;
      align-items: start;
      position: relative;
      min-height: 186px;
      overflow: hidden;
    }
  }
}

</style>
