<template>
  <div
    class="subscribe-info"
    v-if="isFirstWithPass || modelValue && modelValue.passStatus"
  >
    <div class="subscribe-info__title">
      {{ isFirstWithPass ? firstStudent.crmLessonPass.title : modelValue.title }}
    </div>
    <div class="subscribe-info-content">
      <div class="subscribe-info-content-item">
        <div class="subscribe-info-content-item__label">
          {{ $t('crm.lessons.form.availableToCreateLessonsCount') }}:
        </div>
        <div class="subscribe-info-content-item__value">
          {{ isFirstWithPass ? firstStudent.crmLessonPass.availableToCreateLessonsCount :
            modelValue.availableToCreateLessonsCount }}
        </div>
      </div>
      <div class="subscribe-info-content-item">
        <div class="subscribe-info-content-item__label">
          <div class="subscribe-info-content-item__label">
            {{ $t('crm.lessons.form.priceSubscribe') }}:
          </div>
        </div>
        <div class="subscribe-info-content-item__value">
          {{ isFirstWithPass ? firstStudent.crmLessonPass.passCost : passCost }}
          {{ isFirstWithPass ? firstStudent.crmLessonPass.currency : modelValue.currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import numeral from 'numeral';
import FORMATS from '@/constants/formats';

export default {
  name: 'SubscribeInfo',
  props: {
    modelValue: Object,
    firstStudent: Object
  },
  setup (props) {
    const passCost = computed(() => {
      const passCost = props.modelValue.passCost;
      return passCost ? numeral(passCost).format(FORMATS.FINANCE) : 0;
    });

    const isFirstWithPass = ref(false);

    watch(() => props.modelValue, () => {
      isFirstWithPass.value = false;
    });

    onMounted(() => {
      if (!props.modelValue || !props.modelValue.passStatus) {
        isFirstWithPass.value = props.firstStudent && props.firstStudent.crmLessonPass &&
            props.firstStudent.crmLessonPass.passStatus === 'Active';
      }
    });

    return {
      passCost,
      isFirstWithPass
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.subscribe-info {
  padding: 24px 0;
  border-top: 1px solid $color-alto;
  border-bottom: 1px solid $color-alto;
  @include global-font;

  &__title {
    text-transform: uppercase;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 1.25;
    font-weight: 800;
    word-break: break-word;
  }
}

.subscribe-info-content {
  display: grid;
  grid-gap: 4px;
}

.subscribe-info-content-item {
  display: flex;

  &__label {
    width: 190px;
    min-width: 190px;
    margin-right: 7px;
  }

  &__value {
    padding: 3px 12px;
    background: $color-moon-raker;
    border-radius: 5px;
    color: $color-accent;
  }

}

</style>
<!-- Created by zas on 07.07.2021  -->