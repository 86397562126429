<template>
  <div
    class="lp-wait-video"
    :class="{'lp-wait-video_mobile': isMobile}"
  >
    <div class="lp-wait-video-content">
      <div class="lp-wait-video-content__video">
        <VideoRemoteStream
          :isTeacher="false"
          :preview="true"
          :mirror="videoMirror"
        />
      </div>
      <div class="lp-wait-video-content__footer">
        <WaitingRoomConnectButton
          @connect="connectRoom"
          :waitingTeacher="!selectedType && !isTeacher"
          :mobile="isMobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VideoRemoteStream from '@/components/VideoCall/VideoCallRender/VideoRemoteStream';
import WaitingRoomConnectButton from '@/components/VideoCall/WaitingRoom/WaitingRoomConnectButton';
import initConnection from '@/constants/_rtc/utils/initConnection';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name: 'WaitingRoom',
  components: { WaitingRoomConnectButton, VideoRemoteStream },
  props: {
    selectedType: Boolean,
    isTeacher: Boolean
  },
  emits: ['update:preview'],
  setup (props, { emit }) {
    const store = useStore();

    const videoSettings = computed(() => store.getters.videoSettings);
    const videoMirror = computed(() => videoSettings.value.mirror);

    const rtcSettings = computed(() => store.getters.rtcSettings);
    const setConnection = () => store.dispatch('setRtcConnect', initConnection(rtcSettings.value));
    const connectRoom = async () => {
      // TODO запуск аудио контекста (по кнопке)
      /**
       * После этого мы подключаемся к webrtc.
       * В VideoCallPage.vue - запускается функция joinRoom()
       * */
      await setConnection();
      emit('update:preview', false);
    };

    const isMobile = computed(() => store.getters.isMobile);

    return {
      isMobile,
      connectRoom,
      videoMirror
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-wait-video {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  background: rgba($color-black, 0.5);

  &_mobile {
    position: relative;
    height: calc(100vh);
    height: calc((var(--vh, 1vh) * 100));
    background: $color-white;

    .lp-wait-video-content {
      grid-template-rows: auto 103px;
      height: 100%;
      padding: 12px 12px 36px;
      border-radius: 0;

      @media (orientation: landscape) {
        grid-template-rows: auto;
        padding: 0;
      }

      &__video {
        height: calc(100vh - 205px);
        height: calc((var(--vh, 1vh) * 100) - 205px);

        @media (orientation: landscape) {
          width: 100vw;
          height: calc(100vh);
          height: calc((var(--vh, 1vh) * 100));
          max-height: calc(100vh);
          max-height: calc((var(--vh, 1vh) * 100));
        }
      }

      &__footer {
        @media (orientation: landscape) {
          position: fixed;
          right: 10px;
          bottom: 10px;
          z-index: 100;
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-gap: 28px;
    background: $color-white;
    border-radius: 4px;
    padding: 58px 20px 28px;

    &__video {
      max-height: calc(100vh - 205px);
      max-height: calc((var(--vh, 1vh) * 100) - 205px);
      overflow: hidden;
    }
  }
}

</style>
