import { cloneDeep, uniqueId } from 'lodash';
import { reactive } from 'vue';

const inputs = [
  {
    type: 'selectImage',
    label: 'crm.lessons.form.student',
    modelName: 'crmStudentId',
    modelValue: {},
    keyProp: 'fullName',
    keyImg: 'uploadPhoto.url',
    options: [],
    required: true,
    searchable: true,
    getValue: (val) => val._id,
    style: {
      gridColumn: '1/3'
    }
  },
  {
    type: 'date',
    label: 'crm.students.payment.date',
    modelName: 'paymentDate',
    modelValue: { date: [new Date()] },
    fixed: true,
    maxDate: new Date(),
    format: 'DD MMMM, YYYY',
    style: {
      gridColumn: '1/3'
    }
  },
  {
    autofocus: true,
    required: true,
    type: 'number',
    inputmode: 'decimal',
    label: 'crm.students.payment.amount',
    modelName: 'value',
    modelValue: '',
    emptyError: 'errors.correctAmount',
    style: {
      gridColumn: '1'
    }
  },
  {
    type: 'currency',
    disabled: true,
    modelName: 'currency',
    modelValue: '',
    style: {
      gridColumn: '2'
    }
  }
];

export default reactive(inputs.map((item) => {
  item.id = uniqueId('student-input-');
  item.autocomplete = 'off';
  item.defaultValue = cloneDeep(item.modelValue);
  return item;
}));
