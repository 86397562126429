import chartTooltip from '@/constants/utils/CRM/chartTooltip';
import { reactive } from 'vue';
import numeral from 'numeral';
import chartTooltipPosition from '@/constants/utils/CRM/chartTooltipPosition';

export default reactive({
  chart: {
    id: 'chart-lessons',
    toolbar: { show: false },
    zoom: { enabled: false },
    events: {
      mouseMove: chartTooltipPosition
    }
  },
  yaxis: {
    labels: {
      style: { cssClass: 'chart-label' },
      formatter: (value = 0) => {
        return value !== Infinity && value !== -Infinity ? numeral(value).format('0') : 1;
      }
    },
    max: (val = 0) => val + 1,
    min: 0,
    tooltip: { enabled: false }
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    colors: ['#7500BA']
  },
  fill: {
    type: 'solid',
    opacity: 1
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    followCursor: false,
    shared: true,
    custom: chartTooltip,
    theme: false,
    x: { show: false },
    marker: { show: false }
  },
  markers: {
    size: 0,
    colors: '#FFFFFF',
    strokeColors: '#7500BA',
    strokeWidth: 4,
    strokeOpacity: 1,
    strokeDashArray: 14,
    fillOpacity: 1,
    discrete: [],
    hover: {
      sizeOffset: 5
    }
  }
});
