<template>
  <div
    class="lp-toolbar-edit-lesson"
    @click.stop="openForm = true"
    @mouseenter="openTooltip = true"
    @mouseleave="openTooltip = false"
  >
    <editIcon />
    <Tooltip
      class="lp-toolbar-edit-lesson__tooltip"
      :text="'crm.lessons.tooltips.edit'"
      :rightPosition="true"
      :open="openTooltip && !openForm"
    />
    <LessonForm
      :openForm="openForm"
      :date="date"
      :lesson="lesson"
      v-model:openForm="openForm"
    />
  </div>
</template>

<script>
import { editIcon } from '@/constants/icons/crm';
import { computed, ref, watch } from 'vue';
import { get } from 'lodash';
import LessonForm from '@/components/CRM/Lessons/Calendar/CalendarLessonForm/LessonForm';
import Tooltip from '@/components/Main/Tooltip/Tooltip';

export default {
  name: 'UpdateLesson',
  components: { Tooltip, LessonForm, editIcon },
  props: {
    lesson: Object
  },
  setup (props) {
    const openForm = ref(false);
    watch(openForm, () => openTooltip.value = false);

    const date = computed(() => get(props.lesson, 'crmLessonStart', ''));

    const openTooltip = ref(false);

    return {
      openForm,
      date,
      openTooltip
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';

.lp-toolbar-edit-lesson {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: $color-moon-raker;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;

  svg {
    transition: transform 0.2s ease-in;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }

  &__tooltip {
    top: 38px;
    left: auto;
    right: -14px;
    white-space: nowrap;
  }
}
</style>
