<template>
  <label
    class="lp-upload"
    for="upload"
  >
    <input
      class="lp-upload__input"
      :accept="accept ? `${accept}/*` : '*'"
      type="file"
      :multiple="multiple"
      @change="inputFile"
      id="upload"
    >
    <slot></slot>
  </label>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'UploadInput',
  props: {
    accept: String,
    maxSizeMB: Number,
    multiple: Boolean
  },
  emits: ['update:format', 'update:limit', 'update:file'],
  setup (props, { emit }) {
    const maxSizeBytes = computed(() => props.maxSizeMB * 1e+6);

    const checkOneFile = (file) => {
      if (!file) return;
      const {
        size,
        type
      } = file;
      if (props.accept === 'image' && type.includes('svg')) {
        emit('update:format', true);
      } else if (!type.includes(props.accept)) {
        emit('update:format', true);
      } else if (size > maxSizeBytes.value) {
        emit('update:limit', true);
      } else {
        emit('update:file', file);
      }
    };

    const checkManyFiles = (files) => {
      emit('update:file', files);
    };

    const inputFile = (e) => {
      const { files } = e.target;
      emit('update:limit', false);
      emit('update:format', false);
      emit('add-file');

      if (props.multiple) {
        checkManyFiles(files);
      } else {
        checkOneFile(files[0]);
      }
    };

    return { inputFile };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-upload {
  @include global-font;
  position: relative;
  display: inline-block;

  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

</style>
