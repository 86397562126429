<template>
  <div
    v-if="!isMobile"
    class="students-list"
  >
    <div class="students-list__students">
      <div class="students-list-search">
        <CustomInput
          :customBorder="true"
          :hideLabel="true"
          v-model="searchingStudent"
          :placeholder="$t('global.search')"
          :hasIcon="true"
        />
        <searchIcon
          class="students-list-search-icon"
        />
      </div>
      <div class="students-list__label">
        {{ $t('crm.students.form.allStudents') }}
      </div>
      <div class="students-list__wrapper">
        <draggable
          :list="studentsList"
          group="people"
          @start="drag=true"
          @end="drag=false"
          itemKey="_id"
        >
          <template #item="{element}">
            <div class="students-list-item">
              <div class="students-list-item__image">
                <img
                  class="students-list-item__photo"
                  v-if="element.uploadPhoto && element.uploadPhoto.url"
                  :alt="element.uploadPhoto.url"
                  :src="element.uploadPhoto.url"
                />
                <div
                  class="students-list-item__icon"
                  v-else
                >
                  <userIcon />
                </div>
              </div>

              <div class="students-list-item__name">
                {{ element.fullName }}
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <div class="students-list__list">
      <div class="students-list__label">
        {{ $t('crm.students.form.groupMembers') }}
      </div>
      <div
        v-if="isListEmpty"
        class="students-list__label_error"
      >
        <dangerIcon />
        <div>{{ $t('errors.emptyGroupList') }}</div>
      </div>

      <div
        class="students-list__draggable"
        :class="{'students-list__draggable_error': isListEmpty}"
      >
        <div
          v-if="!studentsListSelected.length && !drag"
          class="students-list__placeholder"
        >
          <img
            :src="require('@/assets/images/group-placeholder.png')"
            alt="Group"
          >
          {{ $t('crm.students.form.dropStudents') }}
        </div>

        <draggable
          :list="studentsListSelected"
          group="people"
          @start="drag=true"
          @end="drag=false"
          @drop="dropStudent"
          itemKey="_id"
          class="drop-area"
          :style="{'min-height': `${dropAreaHeight}px`}"
        >
          <template #item="{element}">
            <div class="students-list-item__selected">
              <div class="students-list-item__student">
                <div class="students-list-item__image">
                  <img
                    class="students-list-item__photo"
                    v-if="getStudentPhoto(element)"
                    :alt="getStudentPhoto(element)"
                    :src="getStudentPhoto(element)"
                  />
                  <div
                    class="students-list-item__icon"
                    v-else
                  >
                    <userIcon />
                  </div>
                </div>

                <div class="students-list-item__name">
                  {{ element.fullName }}
                </div>
              </div>

              <closeModalIcon
                @click="removeStudent(element)"
                class="students-list-item__cross"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
  <div
    class="students-list-mobile"
    v-else
  >
    <div class="students-list-mobile__label">
      {{ $t('crm.students.groups.studentsCapital') }}
    </div>
    <div
      class="students-list-mobile__add"
      @click.stop="openAddStudentsForm"
    >
      <addIcon />
      <div>
        {{ $t('crm.students.table.addStudent') }}
      </div>
    </div>
    <div
      v-if="isListEmpty"
      class="students-list__label_error"
    >
      <dangerIcon />
      <div>{{ $t('errors.emptyGroupList') }}</div>
    </div>
    <div class="students-list-mobile__list">
      <div
        v-for="student in studentsListSelected"
        :key="student._id"
        class="students-list-mobile__student"
      >
        <div class="students-list-item__image">
          <img
            class="students-list-item__photo"
            :class="{'students-list-item__photo_mobile': isMobile}"
            v-if="getStudentPhoto(student)"
            :alt="getStudentPhoto(student)"
            :src="getStudentPhoto(student)"
          />
          <div
            class="students-list-item__icon"
            :class="{'students-list-item__icon_mobile': isMobile}"
            v-else
          >
            <userIcon />
          </div>
        </div>

        <div class="students-list-item__name">
          {{ student.fullName }}
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="openStudentsList"
    :title="'crm.students.table.addStudent'"
    :hideFooter="true"
    :mobileVersion="isMobile"
    :editMode="true"
    v-model:open="openStudentsList"
    @save="closeForm"
  >
    <div class="students-list-checkbox">
      <div class="students-list-search">
        <CustomInput
          :customBorder="true"
          :hideLabel="true"
          v-model="searchingStudent"
          :placeholder="$t('global.search')"
          :hasIcon="true"
        />
        <searchIcon
          class="students-list-search-icon"
        />
      </div>
      <div
        v-for="student in studentsList"
        :key="student._id"
        class="students-list-mobile__student students-list-mobile__student_checkbox"
        @click="toggleStudentSelect(student)"
      >
        <div class="students-list-item__info">
          <div class="students-list-item__image">
            <img
              class="students-list-item__photo"
              :class="{'students-list-item__photo_mobile': isMobile}"
              v-if="getStudentPhoto(student)"
              :alt="getStudentPhoto(student)"
              :src="getStudentPhoto(student)"
            />
            <div
              class="students-list-item__icon"
              :class="{'students-list-item__icon_mobile': isMobile}"
              v-else
            >
              <userIcon />
            </div>
          </div>

          <div class="students-list-item__name">
            {{ student.fullName }}
          </div>
        </div>
        <div
          class="students-list-item__checkbox"
          :class="{'students-list-item__checkbox_selected': isStudentSelected(student)}"
        >
          <checkIcon />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import CustomInput from '@/components/Main/Inputs/CustomInput';
import { computed, onBeforeMount, ref, watch } from 'vue';
import StudentApi from '@/api/CRM/students';
import { userIcon } from '@/constants/icons';
import draggable from 'vuedraggable';
import { closeModalIcon, searchIcon, dangerIcon } from '@/constants/icons';
import { addIcon, checkIcon } from '@/constants/icons/crm';
import Modal from '@/components/Main/Modal/Modal';

export default {
  name: 'StudentsList',
  components: {
    CustomInput,
    userIcon,
    draggable,
    closeModalIcon,
    searchIcon,
    dangerIcon,
    addIcon,
    checkIcon,
    Modal
  },
  props: {
    inputs: Array,
    crmStudents: Array,
    isMobile: Boolean
  },
  emits: ['set-group'],
  setup (props, { emit }) {
    const studentsList = ref([]);
    const studentsListSelected = ref([]);

    watch(() => studentsListSelected.value, (val) => {
      setGroup(val);
      isListEmpty.value = !val;
    }, { deep: true });

    const searchingStudent = ref('');
    watch(searchingStudent, async (newVal, oldVal) => {
      if (newVal.length < oldVal.length) {
        await getStudentsList();
      }

      const filteredStudentsList = studentsList.value.filter(item => {
        return item.fullName.toLowerCase().includes(searchingStudent.value.toLowerCase());
      });
      if (searchingStudent.value === '') {
        getStudentsList();
      } else {
        studentsList.value = filteredStudentsList;
      }
    });

    const setGroup = (data) => {
      emit('set-group', data);
    };

    const drag = ref(false);

    const getStudentsList = async () => {
      try {
        const { data } = await StudentApi.getStudents({ });
        const students = data.resource;
        const filteredStudents = students.filter(item => {
          const selected = studentsListSelected.value.find(val => val._id === item._id);
          return selected ? item._id !== selected._id : item;
        });

        studentsList.value = studentsListSelected.value && studentsListSelected.value.length && !props.isMobile ?
          filteredStudents : students;
      } catch (err) {
        console.error(err);
      }
    };

    const setValuesFromGroup = () => {
      studentsListSelected.value = props.crmStudents;
      isListEmpty.value = !!(props.crmStudents[0] && props.crmStudents[0]._id);
    };

    const removeStudent = (student) => {
      studentsListSelected.value = studentsListSelected.value.filter(item => {
        if (item._id !== student._id) {
          studentsList.value.push(item);
        }
        return item._id !== student._id;
      });
      getStudentsList();
    };

    watch(props.inputs, () => {
      const students = props.inputs.find(item => item.modelName === 'allStudentsList');
      const hasError = !!students.error;
      const isEmpty = !studentsListSelected.value || studentsListSelected.value && !studentsListSelected.value.length;
      isListEmpty.value = hasError && isEmpty;
    });

    const isListEmpty = ref(false);

    const studentsFromGroup = computed(() => {
      return props.crmStudents;
    });

    const filterStudentsList = () => {
      const restStudents = studentsList.value.filter(item => {
        const selected = studentsListSelected.value.find(student => item._id === student._id);
        return !selected;
      });
      studentsList.value = restStudents;
    };

    const dropAreaHeight = computed(() => {
      return drag.value || (studentsListSelected.value && studentsListSelected.value.length > 4) ? 400 : 200;
    });

    const getStudentPhoto = (student) => {
      return student.uploadPhoto ? student.uploadPhoto.url : student.uploadPhotoUrl;
    };

    const openStudentsList = ref(false);
    const openAddStudentsForm = () => {
      openStudentsList.value = true;
    };

    const toggleStudentSelect = (student) => {
      if (!isStudentSelected(student)) {
        const { fullName, teacherId, uploadPhoto, _id  } = student;
        const newStudent = {
          fullName: fullName,
          teacherId: teacherId,
          _id: _id
        };
        if (uploadPhoto.url) {
          newStudent.uploadPhotoUrl = uploadPhoto.url;
        }
        studentsListSelected.value.push(newStudent);
      } else {
        removeStudent(student);
      }
      if (studentsListSelected.value && studentsListSelected.value.length) {
        isListEmpty.value = false;
      }
    };

    const isStudentSelected = (student) => {
      return studentsListSelected.value.find(item => item._id === student._id);
    };

    const dropStudent = () => {
    };

    const closeForm = () => {
      openStudentsList.value = false;
    };

    onBeforeMount(async () => {
      await getStudentsList();
      if (studentsFromGroup.value) {
        setValuesFromGroup();
        if (!props.isMobile) {
          filterStudentsList();
        }
      }
    });

    return {
      studentsList,
      drag,
      studentsListSelected,
      removeStudent,
      searchingStudent,
      isListEmpty,
      dropAreaHeight,
      getStudentPhoto,
      openStudentsList,
      openAddStudentsForm,
      toggleStudentSelect,
      isStudentSelected,
      closeForm,
      dropStudent
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/sass/style";

.students-list {
  display: flex;
  justify-content: space-between;

  &__list {
    min-width: 283px;
  }

  &__wrapper {
    max-height: 400px;
    overflow: auto;
  }

  &__students {
    width: 264px;
    margin-right: 20px;
  }

  &-search {
    margin-bottom: 20px;
    position: relative;
  }

  &__label {
    margin-bottom: 18px;
    color: $color-mine-shaft;
    font-weight: bold;
    font-size: 12px;

    &_error {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      margin-top: -10px;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #FF0000;

      div {
        margin-left: 6px;
      }
    }
  }

  &__draggable {
    min-height: 450px;
    padding: 16px 5px 16px 16px;
    border: 1px dashed $color-purple;
    border-radius: 4px;

    &_error {
      border: 1px solid $color-red;
    }
  }

  &__placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 125px;
    color: $color-purple;

    img {
      width: 130px;
      height: auto;
    }
  }
}

.students-list-mobile {
  &__add {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: bold;
    color: $color-purple;
    cursor: pointer;

    div {
      margin-left: 8px;
    }
  }

  &__student {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &_checkbox {
      justify-content: space-between;
    }
  }

  &__label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    color: $color-mine-shaft;
  }
}

.students-list-checkbox {
  padding: 16px 12px;
}

.students-list-search-icon {
  position: absolute;
  top: 15px;
  left: 12px;
}

.students-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;

  &__selected {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:active {
      cursor: grabbing;
    }
  }

  &__photo, &__icon svg {
    border-radius: 50%;
    width: 24px;
    height: 24px;

    &_mobile {
      width: 32px;
      height: 32px;
    }
  }

  &__icon {
    &_mobile {
      width: 32px;
      height: 32px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__name {
    margin-left: 6px;
    font-size: 14px;
    word-break: break-word;
  }

  &__student {
    display: flex;
    align-items: center;
    max-width: 300px;
  }

  &__cross {
    margin-left: 10px;
    cursor: pointer;
    flex-shrink: 0;
  }

  &__info {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $color-purple;

    &_selected {
      background-color: $color-purple;
      border: none;
    }
  }
}

.drop-area {
  min-height: 200px;
  max-height: 400px;
  padding-right: 5px;
  overflow: auto;
  width: 100%;
}
</style>

<style lang="scss">
@import "src/sass/style";
.students-list-item {
  &__checkbox {
    svg {
      width: 12px;
      height: 12px;
      path {
        fill: $color-white;
      }
    }
  }
}

</style>