<template>
  <div
    class="lp-room-toolbar"
    :class="{
      'lp-room-toolbar_close': !openMobileSlider && isMobile,
      'lp-room-toolbar_close_student': !openMobileSlider && !isTeacher && isMobile,
      'lp-room-toolbar_close_with-file': !openMobileSlider && isMobile && mp3File.file,
      'lp-room-toolbar_left': isMobile && preview,
      'lp-room-toolbar_mobile': isMobile && !preview && !liteVersion,
      'lp-room-toolbar_tablet': isTablet
    }"
    id="video-call-toolbar"
  >
    <div
      class="lp-room-toolbar-player-mobile"
      ref="toolbarRef"
      v-if="isMobile"
    >
      <template v-if="!preview && !liteVersion">
        <AudioPlayerControls
          v-show="openMobileSlider || mp3File.file || uploadingFile || !isTeacher"
          v-if="mp3File.file || uploadingFile || !isTeacher"
          :loading="uploadingFile"
          :videoWidth="videoWidth"
        />
        <div
          class="lp-room-toolbar-player-mobile__short"
          v-show="!mp3File.file && !uploadingFile"
          v-if="isTeacher"
        >
          <InviteStudentButton
            :short="shortInvite"
            :size="42"
          />
          <AudioPlayerUploadFile v-model:loading="uploadingFile" />
        </div>
      </template>
      <div
        class="lp-room-toolbar-player-mobile__hide-content"
        :class="{
          'lp-room-toolbar-player-mobile__hide-content_open': openMobileSlider
        }"
        @click="$emit('update:openMobileSlider', !openMobileSlider)"
      >
        <arrowIconColor />
      </div>
    </div>

    <!-- sing mode -->
    <div
      class="lp-room-toolbar__mode"
      :class="{
        'lp-room-toolbar__mode_mobile': isMobile
      }"
    >
      <SwitchAudioMode
        v-if="!preview && !liteVersion"
        :isTeacher="isTeacher"
      />
    </div>

    <div
      class="lp-room-toolbar-settings"
      :class="{
        'lp-room-toolbar-settings_left': isMobile && preview,
        'lp-room-toolbar-settings_mobile': isMobile && !preview && isTeacher
      }"
    >
      <div
        class="lp-room-toolbar-settings-player"
        v-if="!isMobile && !preview"
      >
        <AudioPlayerControls
          :loading="uploadingFile"
          :videoWidth="videoWidth"
        />
        <div class="lp-room-toolbar-settings-player__upload">
          <AudioPlayerUploadFile
            v-if="isTeacher && !liteVersion"
            v-model:loading="uploadingFile"
          />
        </div>
      </div>
      <div class="lp-room-toolbar-settings__volume">
        <MicrophoneSettingsPopup :mobile="isMobile" />
      </div>
    </div>

    <div
      class="lp-room-toolbar__controls"
      :class="{
        'lp-room-toolbar__controls_mobile': isMobile
      }"
    >
      <!-- upload file -->
      <AudioPlayerUploadFile
        v-if="isTeacher && !preview && !liteVersion && isMobile && mp3File?.file"
        v-model:loading="uploadingFile"
      />

      <!-- invite link -->
      <InviteStudentButton
        v-if="isTeacher && !preview"
        :short="isMobile && mp3File?.file"
      />

      <!-- sharing files -->
      <SharingFiles
        v-if="isTeacher && !preview && !isMobile"
        :short="isMobile && mp3File?.file"
      />

      <!-- open calibration -->
      <div
        class="lp-room-toolbar__exit"
        v-if="!isTeacher"
      >
        <MicrophoneOpenCalibration />
      </div>

      <!-- close room -->
      <VideoCallEnd v-if="!preview && !isMobile" />

      <!-- camera -->
      <WebcamSettings
        v-if="isMobile"
        :preview="preview"
      />

      <!-- microphone -->
      <MicrophoneSettings v-if="isMobile" />
    </div>
  </div>
</template>

<script>
import VideoCallEnd from '@/components/VideoCall/VideoCallTools/VideoCallEnd/VideoCallEnd';
import InviteStudentButton from '@/components/VideoCall/VideoCallTools/InviteStudentButton/InviteStudentButton';
import WebcamSettings from '@/components/VideoCall/VideoCallSettings/WebcamSettings/WebcamSettings';
import MicrophoneSettings from '@/components/VideoCall/VideoCallSettings/MicrophoneSettings/MicrophoneSettings';
import SwitchAudioMode from '@/components/VideoCall/VideoCallTools/SwitchAudioMode/SwitchAudioMode';
import AudioPlayerUploadFile from '@/components/VideoCall/VideoCallTools/AudioPlayer/AudioPlayerUploadFile';
import AudioPlayerControls from '@/components/VideoCall/VideoCallTools/AudioPlayer/AudioPlayerControls';
import { useStore } from 'vuex';
import { computed, ref, watch } from 'vue';
import ROLE_TYPES from '@/constants/enums/roles';
import MicrophoneSettingsPopup
  from '@/components/VideoCall/VideoCallSettings/MicrophoneSettings/MicrophoneSettingsPopup';
import { arrowIconColor } from '@/constants/icons';
import MicrophoneOpenCalibration
  from '@/components/VideoCall/VideoCallSettings/MicrophoneSettings/MicrophoneOpenCalibration';
import UAParser from 'ua-parser-js';
import SharingFiles from '@/components/VideoCall/VideoCallTools/SharingFiles/SharingFiles';

export default {
  name: 'VideoCallToolbar',
  components: {
    SharingFiles,
    MicrophoneOpenCalibration,
    AudioPlayerControls,
    WebcamSettings,
    MicrophoneSettings,
    MicrophoneSettingsPopup,
    AudioPlayerUploadFile,
    SwitchAudioMode,
    InviteStudentButton,
    VideoCallEnd,
    arrowIconColor
  },
  props: {
    preview: Boolean,
    openMobileSlider: Boolean,
    videoWidth: Number
  },
  setup () {
    const store = useStore();
    const user = computed(() => store.getters.user);
    const isTeacher = computed(() => user.value.role === ROLE_TYPES.TEACHER);
    const mp3File = computed(() => store.getters.mp3File);
    const uploadingFile = ref(false);

    const liteVersion = computed(() => store.getters.liteVersion);
    const hideSettingsDevices = true;

    const isMobile = computed(() => store.getters.isMobile);
    const isTablet = computed(() => new UAParser().getDevice().type === 'tablet' && !isMobile.value);

    const shortInvite = computed(() => window.innerWidth <= 393);

    const toolbarRef = ref(null);

    watch(toolbarRef, () => {
      const eventsPrevent = ['touchmove', 'touchstart'];
      eventsPrevent.forEach(event => {
        window.addEventListener(event, (e) => {
          if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;
          e.preventDefault();
        });
      });
    });

    return {
      shortInvite,
      hideSettingsDevices,
      isMobile,
      isTablet,
      liteVersion,
      isTeacher,
      mp3File,
      uploadingFile,
      toolbarRef
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/sass/variables';
@import '~@/sass/mixins';

.lp-room-toolbar {
  @include global-font;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  display: grid;
  grid-template-columns: auto minmax(453px, 825px) auto;
  align-items: center;
  justify-content: center;
  z-index: 100;
  color: $color-accent;
  background: $color-shark;
  transition: bottom 400ms ease-in-out;
  touch-action: none;
  overscroll-behavior-x: none;

  &_tablet {
    padding-bottom: 20px;
  }

  &_mobile {
    align-items: start;
    padding-top: 2px;
    border-radius: 16px 16px 0 0;
    grid-template-columns: 1fr;

    @media (orientation: landscape) and (max-height: 430px) {
      display: none;
    }
  }

  &_close {
    bottom: -319px;

    &_student {
      bottom: -292px;
    }
  }

  &_left {
    @media (orientation: landscape) {
      grid-template-columns: auto auto 1fr;
      justify-content: flex-start;
    }
  }

  &-player-mobile {
    padding: 10px;
    min-height: 62px;
    position: relative;

    &__short {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 8px;
      margin-right: 50px;
    }

    &__hide-content {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background-color: $color-outer-space;
      border-radius: 8px;
      color: $color-white;

      svg {
        transition: transform 200ms ease-in-out;
      }

      &_open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__mode {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
    padding: 12px 17px 12px 16px;
    border-right: 1px solid $color-outer-space;

    &_mobile {
      padding: 10px;
      border-right: none;
    }
  }

  &-settings {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;

    &-player {
      position: relative;
      min-height: 105px;

      &__upload {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &__volume {
    }

    &_left {
      @media (orientation: landscape) {
        justify-content: flex-start;
      }
    }

    div + div {
      margin-left: 16px;
    }

    &_mobile {
      grid-template-columns: 1fr;
      padding: 10px;

      div + div {
        margin-left: 10px;
      }
    }
  }

  &__controls {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: stretch;
    justify-content: center;
    padding: 10px 16px;
    border-left: 1px solid $color-outer-space;
    height: 100%;
    width: 100%;

    &_mobile {
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr auto auto auto;
      padding: 10px;
      min-height: 60px;

      &>div:first-child {
        border-left: none;
      }
    }
  }

  &__exit {
    width: 100%;
    color: $color-white;
    font-weight: 700;
    font-size: 10px;
    line-height: 40px;
    letter-spacing: 0.02em;
    text-align: center;
    padding: 0 10px;
    height: 40px;
    border-radius: 8px;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: $color-outer-space;
  }
}

</style>
