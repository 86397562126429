import mp3Player from '@/constants/_rtc/audioEngine/mp3Player';
import streamAudioMixer from '@/constants/_rtc/audioEngine/streamAudioMixer';

const audioEngine = (function () {
  const audioCtx = new AudioContext();
  let inputStream;
  mp3Player.init(audioCtx);
  streamAudioMixer.init(audioCtx, mp3Player);

  function start () {
    if (audioCtx.state === 'suspended') {
      audioCtx.resume();
    }
  }

  function setInputStream (_inputStream) {
    inputStream = _inputStream;
    streamAudioMixer.setInputStream(inputStream);
  }

  return {
    start,
    setInputStream,
    mp3Player,
    streamAudioMixer,
    audioCtx
  };
})();

export default audioEngine;
